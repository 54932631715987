import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { SwPush } from '@angular/service-worker';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { forkJoin, fromEvent, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { DeleteAlertComponent } from 'src/app/shared/global_components/delete-alert/delete-alert.component';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { TimeFormatPipe } from 'src/app/shared/pipes/timeFormat';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { ConnectedAppsService } from 'src/app/shared/services/rest/connected-apps.service';
import { PushNotificationsService } from 'src/app/shared/services/rest/push-notifications.service';
import { environment } from 'src/environments/environment';
import { ConnectedAppChannelsComponent } from './dialogs/connected-app-channels/connected-app-channels.component';
import { EditChannelAccountComponent } from './dialogs/edit-channel-account/edit-channel-account.component';
import { ytLangs } from 'src/app/shared/consts/ytLangs';
import { ChannelEditDescriptionComponent } from './dialogs/channel-edit-description/channel-edit-description.component';

@Component({
  selector: 'app-connected-apps',
  templateUrl: './connected-apps.component.html',
  styleUrls: ['./connected-apps.component.scss']
})
export class ConnectedAppsComponent extends BaseClass implements OnInit, OnDestroy {
  throttle = 100;
  scrollUpDistance = 3.5;
  public isExpanded: boolean = true;
  public company: any;
  public company_id: any;
  public activeTab: any;
  public subsctiptions: any;
  public profiles: any;
  public user: any;
  public channels: any;
  public is_active_yt: number = 1;
  public isLoad: boolean = false;
  public is_mobile: boolean = false;
  public tikTokChannels: any;
  public activeLang: any;
  public metaChannels: any;
  public snapChannels: any;
  public showFilter: boolean = !!this.sm.localStorageGetItem('showFilter')
  public videoWithotWorkspaceID: boolean = !!this.sm.localStorageGetItem('videoWithotWorkspaceID')
  public defaultPlayer: boolean = !!this.sm.localStorageGetItem('defaultPlayer')
  public md5hash: boolean = !!this.sm.localStorageGetItem('md5hash')
  public shortcuts: boolean = !!this.sm.localStorageGetItem('shortcuts')
  public is_delete_without_alert: boolean = !!this.sm.localStorageGetItem('is_delete_without_alert')
  public isCardV2: boolean = !!this.sm.localStorageGetItem('isCardV2')
  public user_id: any;
  
  public ytPagination: any;
  public ytPage: number = 1;

  public ytLangs: any = ytLangs;
  public imgRoute: string = '';
  public host: any = environment.host;
  public menuItems: any[] = [
    {
      icon: 'settings',
      name: 'Settings',
      tab: 'settings'
    },
    {
      icon: 'youtube',
      name: 'YouTube Channels',
      tab: 'youtube'
    },
    {
      icon: 'tiktok',
      name: 'TikTok Channels',
      tab: 'tiktok'
    },
    {
      icon: 'meta',
      name: 'Meta',
      tab: 'meta'
    },
    {
      icon: 'snapchat',
      name: 'Snapchat',
      tab: 'snapchat'
    },
    {
      icon: 'push',
      name: 'Notifications',
      tab: 'web_push'
    }
  ]
  constructor(
    private companyService: CompanyService,
    private activatedRoute: ActivatedRoute,
    private connectedAppsService: ConnectedAppsService,
    private bottomSheet: MatBottomSheet,
    private auth: AuthService,
    private layoutService: LayoutService,
    private languageService: LanguageService,
    private pushNotificationsService: PushNotificationsService,
    private sm: StorageManagerService,
    private dialog: MatDialog,
    private router: Router,
    private swPush: SwPush,
    
  ) { super() }

  ngOnInit(): void {
    // this.company_id = this.activatedRoute.snapshot.queryParamMap.get('company_id')
    // this.getCompany();
    // this.getProfiles();
    this.getImgRoute();
    this.getCsrf();
    this.getUser();
    this.getLangData();
    this.checkIsMobile();

    this.activatedRoute.queryParams.subscribe(resp => {
      this.activeTab = this.activatedRoute.snapshot.queryParamMap.get('tab');
      
      switch (this.activeTab) {
        case 'youtube':
          console.log('Youtube')
          this.getChannelAccounts(this.ytPage);
          break;
        case 'tiktok':
          console.log('TikTok')
          this.getTikTokChannelAccounts();
          break;
        case 'meta':
          console.log('meta')
          this.getMetaChannelAccounts();
          break;
        case 'snapchat':
          console.log('snapchat')
          this.getSnapChannelAccounts();
          break;
        case 'web_push':
          console.log('web_push')
          this.getSubscriptions();
          break;
        case 'users':
          // this.getRootUsers()
          break;
      
        default:
          break;
      }
      console.log(resp)
    })
  }

  getSubscriptions() {
    this.attachSubscriptions(
      this.pushNotificationsService.getSubscriptions().subscribe(resp => {
        console.log("pushNotificationsService", resp);
        this.subsctiptions = resp
      })
    )
  }

  checkIsMobile() {
    if (window.innerWidth <= 769) {
      this.is_mobile = true;
    } else {
      this.is_mobile = false;
    }
    this.onResize();
  }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this.activeLang = resp.active;
      })
    )
  }
  
  changeItemVal(e, item) {
    console.log(e, item)
    this.attachSubscriptions(
      this.pushNotificationsService.editSubscription(item.id, {is_active: !!item.is_active ? 1 : 0}).subscribe(resp => {
        console.log(resp)
      })
    )
  }

  openAccount(channel, key) {
    console.log(channel, key)
    const dialogRef = this.dialog.open(ConnectedAppChannelsComponent, {
      disableClose: true,
      data: {
        channel: channel,
        user: this.user,
        activeLang: this.activeLang,
        key: key
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!!result && result.event == "add") {

      }
    })
  }

  onResize() {
    this.attachSubscriptions(
      fromEvent(window, "resize").pipe(
        map(() => window.innerWidth)
      ).subscribe((wWidth) => {
          if (wWidth <= 769) {
            this.is_mobile = true;
          } else {
            this.is_mobile = false;
          }
        }
      )
    )
  }

  goToMenuItem(item) {
    this.router.navigate(['user/connected-apps'], { queryParams: {tab: item.tab}});
  }

  playerChange(e) {
    console.log(e, this.defaultPlayer);
    if (this.defaultPlayer) {
      this.sm.localStorageSetItem("defaultPlayer", '1')
    } else {
      localStorage.removeItem("defaultPlayer")
    }
  }
  
  md5Change(e) {
    console.log(e, this.md5hash);
    if (this.md5hash) {
      this.sm.localStorageSetItem("md5hash", '1')
    } else {
      localStorage.removeItem("md5hash")
    }
  }
  
  barItemsChange(e) {
    console.log(e, this.shortcuts);
    if (this.shortcuts) {
      this.sm.localStorageSetItem("shortcuts", '1')
    } else {
      localStorage.removeItem("shortcuts")
    }
  }
  
  isDeleteWithoutAlertChange(e) {
    console.log(e, this.shortcuts);
    if (this.is_delete_without_alert) {
      this.sm.localStorageSetItem("is_delete_without_alert", '1')
    } else {
      localStorage.removeItem("is_delete_without_alert")
    }
  }
  
  changeIsCardV2(e) {
    console.log(e, this.shortcuts);
    if (this.isCardV2) {
      this.sm.localStorageSetItem("isCardV2", '1')
    } else {
      localStorage.removeItem("isCardV2")
    }
  }

  updateVideosInChannelAccount(item) {
    if (item.is_active == 0) {
      this.layoutService.showSnackBar({name: ''}, marker("You need to authorize your profile. Click on the refresh icon."), SnackBarItem)
      return
    }
    console.log(item);
    this.attachSubscriptions(
      this.connectedAppsService.editChannelAccount(item.id, {is_update_data: 1}).subscribe(resp => {
        item.is_update_data = resp.is_update_data;
        console.log(resp)
      }, error => {
        let msg = error;
        if (error == "You can update data once per day, please wait.") {
          msg = msg + " Remaining: " + new TimeFormatPipe().transform(item.can_update_at)
        }
        this.layoutService.showSnackBar({name: ''}, msg, SnackBarItem)
      })
    )
  }

  // dontShowPushModal() {
  //   this.sm.localStorageSetItem("showPushModal", '1');
  // }

  // getSubscription() {
  //   console.log(Notification.permission);

  //   if (!("Notification" in window)) {
  //     this.dontShowPushModal();
  //   }
  //   if (Notification.permission === 'default') {
  //     console.log("default");
  //     Notification.requestPermission().then(() => {
  //       this.requestSubscription();
  //     }).catch(() => {
  //       // show permission denied error
  //       console.log("catch");
  //     });
  //   } else if (Notification.permission === 'denied') {
  //     console.log("denied");
  //     this.dontShowPushModal();
  //     // show permission is denied, please allow it error
  //   } else {
  //     this.requestSubscription();
  //   }
  // }

  // async requestSubscription() {
  //   try {
  //     console.log("requestSubscription object ", 'try');
  //     const sub = await this.swPush.requestSubscription({ serverPublicKey: this.user.web_push_public_key});
  //     console.log("requestSubscription object ", sub);
  //     // this.companyService.sendSubs({ subscription: sub }).subscribe(resp => {
  //     //   this.sm.localStorageSetItem("showPushModal", '1')
  //     // }, error => {
  //     //   if (error == 'Subscription already exists.') {
  //     //     this.sm.localStorageSetItem("showPushModal", '1');
  //     //   } else {
  //     //     this.sm.localStorageSetItem("showPushModal", '0');
  //     //   }
  //     // })
  //   } catch (e) {
  //     console.log("requestSubscription catch ", e);
  //     this.sm.localStorageSetItem("showPushModal", '0');
  //   }
  // }

  // subscribePlatform() {
  //   console.log("subscribePlatform");

  //   this.getSubscription();
  // }

  workspaceIdChange(e) {
    console.log(e, this.videoWithotWorkspaceID);
    if (this.videoWithotWorkspaceID) {
      this.sm.localStorageSetItem("videoWithotWorkspaceID", '1')
    } else {
      localStorage.removeItem("videoWithotWorkspaceID")
    }
  }

  filterPanelChange(e) {
    console.log(e, this.showFilter);
    if (this.showFilter) {
      this.sm.localStorageSetItem("showFilter", '1')
    } else {
      localStorage.removeItem("showFilter")
    }
  }

  // getProfiles() {
  //   this.attachSubscriptions(
  //     this.companyService.getProfiles(this.company_id, '1').pipe(
  //       map(el => el.body)
  //     ).subscribe(resp => {
  //       console.log("getProfiles", resp)
  //       this.profiles = resp;
  //     })
  //   )
  // }

  reconectChannelAccountAuthUrl(item) {
    item.isRefreshed = true;
    this.attachSubscriptions(
      this.connectedAppsService.getChannelAccountAuthUrl("youtube", window.location.href, item.id).subscribe(resp => {
        console.log(resp);
        item.isRefreshed = false;
        window.open(this.host + resp, "_self")
      })
    )
  }

  getChannelAccountAuthUrl() {
    this.attachSubscriptions(
      this.connectedAppsService.getChannelAccountAuthUrl("youtube", window.location.href).subscribe(resp => {
        console.log(resp);
        console.log(this.host + resp);
        window.open(this.host + resp, "_self")
      })
    )
  }

  reconectTikTokChannelAccountAuthUrl(item) {
    item.isRefreshed = true;
    this.attachSubscriptions(
      this.connectedAppsService.getChannelAccountAuthUrl("tiktok", window.location.href, item.id).subscribe(resp => {
        console.log(resp);
        item.isRefreshed = false;
        window.open(this.host + resp, "_self")
      })
    )
  }

  reconectMetaChannelAccountAuthUrl(item) {
    item.isRefreshed = true;
    this.attachSubscriptions(
      this.connectedAppsService.getChannelAccountAuthUrl("facebook", window.location.href, item.id).subscribe(resp => {
        console.log(resp);
        item.isRefreshed = false;
        window.open(this.host + resp, "_self")
      })
    )
  }

  reconectSnapChannelAccountAuthUrl(item) {
    item.isRefreshed = true;
    this.attachSubscriptions(
      this.connectedAppsService.getChannelAccountAuthUrl("snapchat", window.location.href, item.id).subscribe(resp => {
        console.log(resp);
        item.isRefreshed = false;
        window.open(this.host + resp, "_self")
      })
    )
  }

  getTikTokChannelAccountAuthUrl() {
    this.attachSubscriptions(
      this.connectedAppsService.getChannelAccountAuthUrl("tiktok", window.location.href).subscribe(resp => {
        console.log(resp);
        window.open(this.host + resp, "_self")
      })
    )
  }

  getMetaChannelAccountAuthUrl() {
    this.attachSubscriptions(
      this.connectedAppsService.getChannelAccountAuthUrl("facebook", window.location.href).subscribe(resp => {
        console.log(resp);
        window.open(this.host + resp, "_self")
      })
    )
  }
  getSnapChannelAccountAuthUrl() {
    this.attachSubscriptions(
      this.connectedAppsService.getChannelAccountAuthUrl("snapchat", window.location.href).subscribe(resp => {
        console.log(resp);
        window.open(this.host + resp, "_self")
      })
    )
  }

  editChannelAccountDescription(item) {
    console.log(item);
    const dialogRef = this.dialog.open(ChannelEditDescriptionComponent, {
      disableClose: true,
      data: {
        channel: item,
        exChannel: item.exChannels[0],
        user: this.user,
        activeLang: this.activeLang
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!!result && result.event == "add") {

      }
    })
  }

  onDownConApps(e) {
    console.log("SCROLL DOWN", e);
    switch (this.activeTab) {
      case 'youtube':
        console.log('onDownConApps Youtube')
        if (this.ytPagination['pageCount'] >= this.ytPage) {
          this.getChannelAccounts(this.ytPage);
        }
        break;
      case 'tiktok':
        console.log('onDownConApps TikTok')
        
        break;
      case 'meta':
        console.log('onDownConApps meta')
        break;
      case 'web_push':
        console.log('onDownConApps web_push')
        break;
      case 'users':
        // this.getRootUsers()
        break;
    
      default:
        break;
    }
  }

  changeFilterYt(val) {
    if (this.is_active_yt == val) {
      return
    }
    this.is_active_yt = val;
    this.ytPage = 1;
    this.channels = [];
    this.getChannelAccounts(this.ytPage)
  }

  getChannelAccounts(page) {
    this.isLoad = true;
    this.attachSubscriptions(
      this.connectedAppsService.getChannelAccountsScrollFilter(page, 'youtube', {is_active: this.is_active_yt}).pipe(
        tap(el => {
          if (page == 1) {
            this.ytPagination = {
              'pageCount': el.headers.get('x-pagination-page-count'),
              'perPage': el.headers.get('x-pagination-per-page'),
              'totalCount': el.headers.get('x-pagination-total-count'),
              'currentPage': el.headers.get('x-pagination-current-page'),
            }
          }
        }),
        map((el) => el.body),
        switchMap(res => {
          if (res && res.length) {
            return forkJoin(res.map(x => this.connectedAppsService.getChannelAccountsChannels(null, x.id, x.type, null).pipe(
              tap(u => {
                u.map(b => b.avatarFile = b.imageFile)
                x.exChannels = u;
              })
            ))).pipe(
              map(() => res)
            )
          } else {
            return of(res)
          }
        })
      ).subscribe(resp => {
        if (page == 1) {
          this.channels = resp
        } else {
          this.channels.push(...resp)
        }
        console.log("getYT-" + this.ytPage, this.channels);
        this.ytPage++;
        this.isLoad = false;
      })
    )
  }

  getTikTokChannelAccounts() {
    this.attachSubscriptions(
      this.connectedAppsService.getChannelAccounts('tiktok').subscribe(resp => {
        console.log("getTikTokChannelAccounts", resp)
        this.tikTokChannels = resp
      })
    )
  }
  
  getMetaChannelAccounts() {
    this.attachSubscriptions(
      this.connectedAppsService.getChannelAccounts('facebook').subscribe(resp => {
        console.log("getMetaChannelAccounts", resp)
        this.metaChannels = resp
      })
    )
  }

  getSnapChannelAccounts() {
    this.attachSubscriptions(
      this.connectedAppsService.getChannelAccounts('snapchat').subscribe(resp => {
        console.log("getMetaChannelAccounts", resp)
        this.snapChannels = resp
      })
    )
  }


  editChannelAccount(channel) {
    const dialogRef = this.dialog.open(EditChannelAccountComponent, {
      disableClose: true,
      data: channel
    });
  }


  deleteChannelAccount(id, i) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: {},
        target: marker("authorization")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.connectedAppsService.deleteChannelAccount(id).subscribe(resp => {
            this.channels.splice(i,1)
          })
        )
      }
    });
  }

  // getCompany() {
  //   this.attachSubscriptions(
  //     this.companyService.getCompany(this.company_id).subscribe(resp => {
  //       this.companyService.company$.next(resp[0]);
  //       this.company = resp[0];
  //     })
  //   )
  // }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  getCsrf() {
    this.attachSubscriptions(
      this.auth.$userToken.subscribe(resp => {
        this.user_id = resp.user_id;
        this.sm.localStorageSetItem("csrf_param", resp.csrf_param)
        this.sm.localStorageSetItem("csrf_token", resp.csrf_token)
      })
    )
  }

  getUser() {
    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        this.user = resp;
      })
    )
  }

  // getGroupProfiles(company_id) {
  //   this.attachSubscriptions(
  //     this.companyService.getGroupProfiles(company_id, this.group_id).subscribe(resp => {
  //       this.profiles = resp;
  //     })
  //   )
  // }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
