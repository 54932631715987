import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { BaseClass } from 'src/app/shared/models/base-class';
import { prioritys } from 'src/app/shared/consts/prioritys';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-work-edit-priority',
  templateUrl: './work-edit-priority.component.html',
  styleUrls: ['./work-edit-priority.component.scss']
})
export class WorkEditPriorityComponent extends BaseClass implements OnInit, OnDestroy {
  public host: any = environment.host;
  public imgRoute: any = '';
  public user_id: number;
  public isFormChange: boolean = false;
  public form: FormGroup;
  public company_id: any;
  public task_id: any;
  public work_id: any;
  public prioritys: any = prioritys;
  public isSubmit: boolean = false;
  public submited: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<WorkEditPriorityComponent>,
    private sm: StorageManagerService,
    private auth: AuthService,
    private fb: FormBuilder,
    private taskService: TaskService,
    private layoutService: LayoutService,
  ) { super() }

  ngOnInit(): void {
    window.scroll(0,0);

    console.log(this.data);

    this.company_id = this.data.company.id;
    this.task_id = this.data.work.task_id;
    this.work_id = this.data.work.id;

    this.getImgRoute();
    this.getCsrf();

    this.form = this.fb.group({
      priority: !!this.data.work.priority ? this.data.work.priority : 0
    })
  }

  selectPrioritys = (priority: any) => {
    this.form.patchValue({
      priority: priority.id
    })
  }
  

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  getCsrf() {
    this.attachSubscriptions(
      this.auth.$userToken.subscribe(resp => {
        this.user_id = resp.user_id;
        this.sm.localStorageSetItem("csrf_param", resp.csrf_param)
        this.sm.localStorageSetItem("csrf_token", resp.csrf_token)
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  onSubmit() {
    if (!this.form.valid) {
      this.submited = true;
      this.layoutService.showSnackBar({name: ''}, marker("You need to fill in all required fields"), SnackBarItem)
      return
    }

    this.submited = false;
    this.isSubmit = true;
    let data = this.form.value;

    console.log("DATA", data)

    this.attachSubscriptions(
      this.taskService.editWork(this.work_id, data, this.company_id).subscribe(resp => {
        this.data.work.priority = resp.priority;
        this.isSubmit = false;
        this.dialogRef.close({event: "update", data: resp})
      })
    )
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
