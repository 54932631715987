import { Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ReplaySubject, Subscription, concat, forkJoin, from, fromEvent, of, scheduled } from 'rxjs';
import { catchError, concatMap, debounceTime, distinctUntilChanged, filter, finalize, last, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { BaseClass } from 'src/app/shared/models/base-class';
import { FileService } from 'src/app/shared/services/rest/file.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { LoadingService } from 'src/app/shared/services/rest/loading.service';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { MatBottomSheet } from '@angular/material/bottom-sheet';

import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as moment from 'moment';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { GlobalDataService } from 'src/app/shared/services/common/global-data.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { RefreshService } from 'src/app/shared/services/rest/refresh.service';
import { DeleteAlertComponent } from 'src/app/shared/global_components/delete-alert/delete-alert.component';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { SelectionModel } from '@angular/cdk/collections';
import { BackJobToPrevStatusComponent } from 'src/app/shared/global_components/back-job-to-prev-status/back-job-to-prev-status.component';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { ParametersService } from 'src/app/shared/services/rest/parameters.service';
import { CompilationsWizardComponent } from 'src/app/shared/global_components/compilations-wizard/compilations-wizard.component';
import { OpenTaskComponent } from '../open-task/open-task.component';
import { Router } from '@angular/router';
import { ViewSchemeComponent } from './view-scheme/view-scheme.component';
import { prioritys } from 'src/app/shared/consts/prioritys';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { WorkEditPriorityComponent } from 'src/app/components/atTasksDialog/work-edit-priority/work-edit-priority.component';
import { AssistantPresetComponent } from './assistant-preset/assistant-preset.component';
import { TargetParametersComponent } from 'src/app/components/atTasksDialog/target-parameters/target-parameters.component';
import { MobChangePersonalStatusComponent } from '../open-task/dialogs/mob-change-personal-status/mob-change-personal-status.component';
import { environment } from 'src/environments/environment';
import { casesModel } from 'src/app/shared/functions/casesModel';
export const MY_FORMATS = {
  parse: {
    dateInput: 'LLLLL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
export const MY_NEW_FORMATS = {
  parse: {
    dateInput: 'MM/DD/YYYY',
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'MM/DD/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-compilation-assistant',
  templateUrl: './compilation-assistant.component.html',
  styleUrls: ['./compilation-assistant.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_NEW_FORMATS},
  ]
})
export class CompilationAssistantComponent extends BaseClass implements OnInit, OnDestroy {
  public progress:number = 0;

  public totalTasksCount: number = 0;
  public page: number = 1;
  public hasMore: boolean = true;
  public pagination: any;
  public host: any = environment.host;
  public viewersCtrl: FormControl = new FormControl(0);
  public symbolCtrl: FormControl = new FormControl('>');

  public selectedPreset: any;
  public scheme: any;
  public bookmarks: any;
  public is_scheme_editing: boolean = false;
  public haveErrorTags: boolean = false;
  public haveErrorGroup: boolean = false;
  public submited: boolean = false;

  public allJobs: FormControl = new FormControl(false);
  public allHideJobs: FormControl = new FormControl(false);
  public allJobsStatus: any = [];

  public taskCollection: any = new SelectionModel(
    true,
    []
  );

  public prioritys = prioritys;
  public tdContextSub: Subscription;
  overlayRefTd: OverlayRef | null;
  @ViewChild('tdContext') tdContext: TemplateRef<any>;

  public sortValue = 'updated_desc';

  public isLoad: boolean = false;
  public assistantTasksSub: Subscription;
  public assistantTaskDataSub: Subscription;
  public assistantTaskDataSchemeSub: Subscription;

  public today = moment().set({hour:0,minute:0,second:0}).unix();
  public todayEnd = moment().endOf('day').unix();

  public tasks: any = [];
  public filteredTasks: any = [];
  

  public isEmptyReqs: any = {
    taskTags: [],
    taskGroup: []
  }
  
  public filters: any = [
    {
      group_id: 'ignore',
      task_parameter_value_id: [],
      task_operation_operation_id: '',
      operation_status_id: 'ignore',
      task_operation_parameter_value_id: 'ignore'
    }
  ];

  // public filters: any = [
  //   {
  //     "task_operation_operation_id": 12,
  //     "group_id": 3,
  //     "operation_status_id": 1,
  //     "task_operation_parameter_value_id": 29
  //   },
  //   {
  //     "task_operation_operation_id": 25,
  //     "group_id": 50,
  //     "operation_status_id": 1,
  //     "task_operation_parameter_value_id": 'ignore'
  //   },
  //   {
  //     "task_operation_operation_id": 9,
  //     "group_id": 50,
  //     "operation_status_id": 1,
  //     "task_operation_parameter_value_id": 'no'
  //   }
  // ]

  public stage = 'Waiting your filtered cards'

  public operationStatuses: any;

  public jobTypes: any;
  public jobTypesControl: FormControl = new FormControl();
  public jobTypes$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public groups: any;
  public groupMoreControl: FormControl = new FormControl();
  public groups$: ReplaySubject<any> = new ReplaySubject<any>(1);


  public allValues: any;
  public allValuesControl: FormControl = new FormControl();
  public allValues$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private taskService: TaskService,
    private router: Router,
    private globalDataService: GlobalDataService,
    private layoutService: LayoutService,
    private companyService: CompanyService,
    public viewContainerRef: ViewContainerRef,
    public overlay: Overlay,
    public fb: FormBuilder,
    private bottomSheet: MatBottomSheet,
    private _adapter: DateAdapter<any>,
    private parametersService: ParametersService,
    private sm: StorageManagerService,
    private fileService: FileService,
    public dialogRef: MatDialogRef<CompilationAssistantComponent>,
    private dialog: MatDialog,
  ) { super() }

  ngOnInit(): void {
    console.log("CompilationAssistantComponent this.data", this.data)
    this._adapter.setLocale(this.data.activeLang);

    this.getBookmarks()
    this.getJobTypes();
    this.getOperationsStatus();
    this.getGroupsCompany();
    this.getAllApiParameterValues();

    this.attachSubscriptions(
      this.jobTypesControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchJobTypes(resp))
    )

    this.attachSubscriptions(
      this.groupMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchGroups(resp))
    )

    this.attachSubscriptions(
      this.allValuesControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchValues(resp))
    )

    this.attachSubscriptions(
      this.viewersCtrl.valueChanges.subscribe(resp => {
        if (!this.allJobs.value && !this.allHideJobs.value) {
          return
        }
        console.log("allJobs", resp);
        console.log("allJobs tasks", this.tasks);
        console.log("allJobsStatus tasks", this.allJobsStatus);
        console.log("allJobs scheme", this.scheme);
        this.filteredTasks = [];
        this.tasks.forEach(task => {
          if (!!task.schemeTags) {
            if (this.allHideJobs.value) {
              if (this.allJobsStatus.length != 0 && task.schemeTags.filter(k => k.job && this.allJobsStatus.includes(k.job.status_id)).length != task.schemeTags.length) {
                if (+this.viewersCtrl.value > 0) {
                  if (this.symbolCtrl.value == '>') {
                    if (task.totalViews > +this.viewersCtrl.value) {
                      return
                    }
                  } else {
                    if (task.totalViews < +this.viewersCtrl.value) {
                      return
                    }
                  }
                }
                this.filteredTasks.push(task);
              }
            } else {
              if (this.allJobsStatus.length == 0 || task.schemeTags.filter(k => k.job && this.allJobsStatus.includes(k.job.status_id)).length == task.schemeTags.length) {
                if (+this.viewersCtrl.value > 0) {
                  if (this.symbolCtrl.value == '>') {
                    if (task.totalViews <= +this.viewersCtrl.value) {
                      return
                    }
                  } else {
                    if (task.totalViews >= +this.viewersCtrl.value) {
                      return
                    }
                  }
                }
                this.filteredTasks.push(task);
              }
            }
          }
        });
      })
    )

    this.attachSubscriptions(
      this.symbolCtrl.valueChanges.subscribe(resp => {
        if (!this.allJobs.value && !this.allHideJobs.value) {
          return
        }
        console.log("allJobs", resp);
        console.log("allJobs tasks", this.tasks);
        console.log("allJobsStatus tasks", this.allJobsStatus);
        console.log("allJobs scheme", this.scheme);
        this.filteredTasks = [];
        this.tasks.forEach(task => {
          if (!!task.schemeTags) {
            if (this.allHideJobs.value) {
              if (this.allJobsStatus.length != 0 && task.schemeTags.filter(k => k.job && this.allJobsStatus.includes(k.job.status_id)).length != task.schemeTags.length) {
                if (+this.viewersCtrl.value > 0) {
                  if (this.symbolCtrl.value == '>') {
                    if (task.totalViews > +this.viewersCtrl.value) {
                      return
                    }
                  } else {
                    if (task.totalViews < +this.viewersCtrl.value) {
                      return
                    }
                  }
                }
                this.filteredTasks.push(task);
              }
            } else {
              if (this.allJobsStatus.length == 0 || task.schemeTags.filter(k => k.job && this.allJobsStatus.includes(k.job.status_id)).length == task.schemeTags.length) {
                if (+this.viewersCtrl.value > 0) {
                  if (this.symbolCtrl.value == '>') {
                    if (task.totalViews <= +this.viewersCtrl.value) {
                      return
                    }
                  } else {
                    if (task.totalViews >= +this.viewersCtrl.value) {
                      return
                    }
                  }
                }
                this.filteredTasks.push(task);
              }
            }
          }
        });
      })
    )

    this.attachSubscriptions(
      this.allHideJobs.valueChanges.subscribe(resp => {
        console.log("allHideJobs", resp);
        console.log("allHideJobs tasks", this.tasks);
        console.log("allJobsStatus tasks", this.allJobsStatus);
        console.log("allHideJobs scheme", this.scheme);
        this.filteredTasks = [];
        this.tasks.forEach(task => {
          if (!!task.schemeTags) {
            if (this.allHideJobs.value) {
              if (+this.viewersCtrl.value > 0) {
                if (this.symbolCtrl.value == '>') {
                  if (task.totalViews > +this.viewersCtrl.value) {
                    return
                  }
                } else {
                  if (task.totalViews < +this.viewersCtrl.value) {
                    return
                  }
                }
              }
              if (this.allJobsStatus.length == 0 || task.schemeTags.filter(k => k.job && this.allJobsStatus.includes(k.job.status_id)).length != task.schemeTags.length) {
                this.filteredTasks.push(task);
              }
            } else {
              if (this.allJobsStatus.length == 0 || task.schemeTags.filter(k => k.job && this.allJobsStatus.includes(k.job.status_id)).length == task.schemeTags.length) {
                if (+this.viewersCtrl.value > 0) {
                  if (this.symbolCtrl.value == '>') {
                    if (task.totalViews <= +this.viewersCtrl.value) {
                      return
                    }
                  } else {
                    if (task.totalViews >= +this.viewersCtrl.value) {
                      return
                    }
                  }
                }
                this.filteredTasks.push(task);
              }
            }
          }
        });
      })
    )

    this.attachSubscriptions(
      this.allJobs.valueChanges.subscribe(resp => {
        console.log("allJobs", resp);
        console.log("allJobs tasks", this.tasks);
        console.log("allJobsStatus tasks", this.allJobsStatus);
        console.log("allJobs scheme", this.scheme);
        this.filteredTasks = [];
        this.tasks.forEach(task => {
          if (!!task.schemeTags) {
            if (this.allHideJobs.value) {
              if (this.allJobsStatus.length != 0 && task.schemeTags.filter(k => k.job && this.allJobsStatus.includes(k.job.status_id)).length != task.schemeTags.length) {
                if (+this.viewersCtrl.value > 0) {
                  if (this.symbolCtrl.value == '>') {
                    if (task.totalViews > +this.viewersCtrl.value) {
                      return
                    }
                  } else {
                    if (task.totalViews < +this.viewersCtrl.value) {
                      return
                    }
                  }
                }
                this.filteredTasks.push(task);
              }
            } else {
              if (this.allJobsStatus.length == 0 || task.schemeTags.filter(k => k.job && this.allJobsStatus.includes(k.job.status_id)).length == task.schemeTags.length) {
                if (+this.viewersCtrl.value > 0) {
                  if (this.symbolCtrl.value == '>') {
                    if (task.totalViews <= +this.viewersCtrl.value) {
                      return
                    }
                  } else {
                    if (task.totalViews >= +this.viewersCtrl.value) {
                      return
                    }
                  }
                }
                this.filteredTasks.push(task);
              }
            }
          }
        });
      })
    )
  }

  

  toggleAllJobs() {
    console.log("this.allJobsStatus.length", this.allJobsStatus.length)
    console.log("this.viewersCtrl.value", this.viewersCtrl.value)
    if (!this.allJobs.value) {
      if (!this.allJobsStatus.length && +this.viewersCtrl.value <= 0) {
        this.layoutService.showSnackBar({name: ''}, "Specify the total number of views or select the status of the job", SnackBarItem);
        return
      }
    }
    this.allJobs.patchValue(!this.allJobs.value);
    this.allHideJobs.patchValue(false, {onlySelf: true, emitEvent: false});
  }

  toggleAllHideJobs() {
    console.log("this.allJobsStatus.length", this.allJobsStatus.length)
    console.log("this.viewersCtrl.value", this.viewersCtrl.value)
    if (!this.allHideJobs.value) {
      if (!this.allJobsStatus.length && +this.viewersCtrl.value <= 0) {
        this.layoutService.showSnackBar({name: ''}, "Specify the total number of views or select the status of the job", SnackBarItem);
        return
      }
    }
    this.allHideJobs.patchValue(!this.allHideJobs.value);
    this.allJobs.patchValue(false, {onlySelf: true, emitEvent: false});
  }

  changeJobStatus(e) {
    if (!this.allJobs.value && !this.allHideJobs.value) {
      return
    }
    console.log("changeJobStatus", e);
    console.log("changeJobStatus tasks", this.tasks);
    console.log("changeJobStatus scheme", this.scheme);
    this.filteredTasks = [];
    this.tasks.forEach(task => {
      if (!!task.schemeTags) {
        if (this.allHideJobs.value) {
          if (this.allJobsStatus.length != 0 && task.schemeTags.filter(k => k.job && this.allJobsStatus.includes(k.job.status_id)).length != task.schemeTags.length) {
            if (+this.viewersCtrl.value > 0) {
              if (this.symbolCtrl.value == '>') {
                if (task.totalViews > +this.viewersCtrl.value) {
                  return
                }
              } else {
                if (task.totalViews < +this.viewersCtrl.value) {
                  return
                }
              }
            }
            this.filteredTasks.push(task);
          }
        } else {
          if (this.allJobsStatus.length == 0 || task.schemeTags.filter(k => k.job && this.allJobsStatus.includes(k.job.status_id)).length == task.schemeTags.length) {
            if (+this.viewersCtrl.value > 0) {
              if (this.symbolCtrl.value == '>') {
                if (task.totalViews <= +this.viewersCtrl.value) {
                  return
                }
              } else {
                if (task.totalViews >= +this.viewersCtrl.value) {
                  return
                }
              }
            }
            this.filteredTasks.push(task);
          }
        }
      }
    });
  }

  selectPreset(item) {
    console.log("selectPreset", item)
    if (item.settings.devScheme) {
      this.selectedPreset = item;
      this.filters = item.settings.devScheme
    }
  }

    openTargetValues(info, element) {
      let initData:any = {
        company: this.data.company,
        task: element
      }
  
      initData.target = {
        task_id: info[0],
        task_operation_id: info[1],
        discussion_id: info[2]
      }

      if (element && element.company_id != this.data.company.id) {
        initData.target.partner_company_id = element.company_id
      }
  
      if (this.taskCollection.selected.length > 0) {
        initData.selectedCards = [...this.taskCollection.selected]
      }
  
      const dialogRef = this.dialog.open(TargetParametersComponent, {
        backdropClass: ['parameters_modal_backdrop'],
        panelClass: ['without_paddings_modal', 'parameters_modal'],
        data: initData
      });
  
      // this.attachSubscriptions(
      //   dialogRef.afterClosed().subscribe(result => {
      //     this.refreshService.refreshTask$.next({company_id: this.company_id, task_id: element.id})
      //   })
      // )
    }

  toggleTask(task): void {
    this.taskCollection.toggle(task);
    console.log(this.taskCollection);
  }

  checkboxLabel(row?): string {
    if (!row) {
      return `${this.isAllSelected() ? 'Deselect' : 'Select'} all`;
    }
    return `${this.taskCollection.isSelected(row) ? 'deselect' : 'select'} card ${row.name}`;
  }

  isAllSelected() {
    const numSelected = this.taskCollection.selected.length;
    const numRows = this.tasks.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
        this.taskCollection.clear() :
        this.tasks.forEach(row => this.taskCollection.select(row));
  }

  toggleSchemeEditing(e) {
    e.preventDefault();
    e.stopPropagation();
    this.is_scheme_editing = !this.is_scheme_editing;
  }

  editPreset(e, trigger:MatMenuTrigger, scheme, task) {
    e.preventDefault();
    e.stopPropagation();

    console.log("trigger", trigger)
    
    trigger.closeMenu();

    console.log("createNewOpenCardClipboard", task)

    const dialogRef = this.dialog.open(AssistantPresetComponent, {
      backdropClass: ['backdrop_under_header'],
      // panelClass: !this.is_mobile ? ['show_header'] : '',
      hasBackdrop: true,
      data: {
        company: this.data.company,
        company_id: this.data.company_id,
        task: task,
        scheme: scheme,
        groups: this.groups,
        allValues: this.allValues,
        jobTypes: this.jobTypes,
        operationStatuses: this.operationStatuses,
        filters: this.selectedPreset && scheme.id == this.selectedPreset.id ? this.filters : undefined
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        
        if (!!result && result.event == 'edit') {
          this.getBookmarks();
        }
      })
    )
  }

  deletePreset(e, trigger:MatMenuTrigger, scheme) {
    e.preventDefault();
    e.stopPropagation();

    trigger.closeMenu();
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: scheme,
        target: marker("Custom filter preset")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.companyService.deleteBookmark(scheme.id).subscribe(res => {
            this.bookmarks.splice(this.bookmarks.findIndex(p => p.id == scheme.id), 1);
            console.log("deleteScheme", this.bookmarks);
          }, error => {
            this.layoutService.showSnackBar({name: ''}, error, SnackBarItem);
          })
        )
      }
    });
  }

  createNewPreset(e) {
    e.preventDefault();

    console.log("createNewPreset", e)

    const dialogRef = this.dialog.open(AssistantPresetComponent, {
      backdropClass: ['backdrop_under_header'],
      // panelClass: !this.is_mobile ? ['show_header'] : '',
      hasBackdrop: true,
      data: {
        company: this.data.company,
        company_id: this.data.company_id,
        filters: this.filters,
        groups: this.groups,
        allValues: this.allValues,
        jobTypes: this.jobTypes,
        operationStatuses: this.operationStatuses
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        
        if (!!result && result.event == 'save') {
          this.getBookmarks();
        }
      })
    )
  }

  checkIsReady(arr) {
    return arr.filter(k => !!k.isReadyScheme).length
  }

  settingsData() {
    console.log("settingsData", this.tasks)
    const dialogRef = this.dialog.open(ViewSchemeComponent, {
      autoFocus: false,
      data: {
        company_id: this.data.company_id,
        company: this.data.company,
        tasks: this.tasks,
        jobTypes: this.jobTypes,
        allValues: this.allValues,
        operationStatuses: this.operationStatuses,
        is_mobile: this.data.is_mobile,
        imgRoute: this.data.imgRoute,
        user: this.data.user,
        activeLang: this.data.activeLang,
        scheme: this.scheme,
        groups: this.groups
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (result.event == 'default') {
          this.scheme = undefined;
        } else if (result.event == 'ok') {
          console.log("settingsData closed", result.data);
          this.scheme = result.data;
          let tags = this.scheme.categoryTags.filter(k => this.scheme.tags.includes(k.id))
          this.scheme.tags = tags;
          this.tasks.forEach(card => {
            card.isReadyScheme = false;
            card.schemeTags = JSON.parse(JSON.stringify(this.scheme.tags, this.getCircularReplacer()));
          });
          this.taskService.assistantTaskDataScheme$.next(this.tasks);
        } else {

        }
        console.log("settingsData closed", result);
      })
    )
  }

  getTag(arr, id) {
    return arr.find(k => k.id == id)
  }

  getCircularReplacer() {
    const ancestors:any = [];
    return function (key, value) {
      if (typeof value !== "object" || value === null) {
        return value;
      }
      // `this` is the object that value is contained in,
      // i.e., its direct parent.
      while (ancestors.length > 0 && ancestors.at(-1) !== this) {
        ancestors.pop();
      }
      if (ancestors.includes(value)) {
        return "[Circular]";
      }
      ancestors.push(value);
      return value;
    };
  }

  compilations() {
    const dialogRef = this.dialog.open(CompilationsWizardComponent, {
      backdropClass: 'backdrop_under_header',
      panelClass: !this.data.is_mobile ? ['open_task_dialog', 'wizard_dialog', 'show_header'] : ['open_task_dialog', 'wizard_dialog'],
      autoFocus: false,
      data: {
        company_id: this.data.company_id,
        company: this.data.company,
        tasks: this.tasks,
        is_mobile: this.data.is_mobile,
        imgRoute: this.data.imgRoute,
        user: this.data.user,
        activeLang: this.data.activeLang,
        from_assistant: true
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log("compilations closed", result);
      })
    )
  }

  goToCards(e) {
    e.preventDefault();
    this.router.navigate([`/tasks`], { queryParams: {company_id: this.data.company_id, count: 1, id: this.getTasksIds()}});
    this.close();
  }

  goToFilCards(e) {
    e.preventDefault();
    this.router.navigate([`/tasks`], { queryParams: {company_id: this.data.company_id, count: 1, id: this.getFilTasksIds()}});
    this.close();
  }

  getTasksIds() {
    return this.tasks.map(x => x.id).join(',')
  }

  getFilTasksIds() {
    return this.filteredTasks.map(x => x.id).join(',')
  }

  getStatusById(id) {
    return this.operationStatuses && this.operationStatuses.find(x => x.id == id)
  }
  
  toggleSymbol() {
    if (this.symbolCtrl.value == '>') {
      this.symbolCtrl.patchValue('<')
    } else {
      this.symbolCtrl.patchValue('>')
    }
  }

  openTdContext({ x, y }: MouseEvent, val, task) {
    this.closeTdContext();
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo({ x, y })
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        }
      ]);

    this.overlayRefTd = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    console.log("val", val)
    this.overlayRefTd.attach(new TemplatePortal(this.tdContext, this.viewContainerRef, {
      $implicit: {...val, ...{task: task}}
    }));
    
    this.tdContextSub = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter(event => {
          const clickTarget = event.target as HTMLElement;
          return !!this.overlayRefTd && !this.overlayRefTd.overlayElement.contains(clickTarget);
        }),
        take(1)
      ).subscribe(() => this.closeTdContext())

  }

  closeTdContext() {
    this.tdContextSub && this.tdContextSub.unsubscribe();
    if (this.overlayRefTd) {
      this.overlayRefTd.dispose();
      this.overlayRefTd = null;
    }
  }

  neededDataForScheme(card) {
    if (!!this.scheme.jobType && card.operations.filter(k => !k.tags && k.operation_id == this.scheme.jobType).length) {
      if (this.scheme.is_two) {
        return this.taskService.getLiteUrlsWithTags('1', this.data.company.id, {task_id: card.id, platform_id: this.scheme.platform_id}, '200').pipe(
          map(u => u.body),
          tap(res => {
            card.posts = res
          }),
          switchMap(x => {
            return from(card.operations.filter(k => !k.tags && k.operation_id == this.scheme.jobType)).pipe(
              mergeMap((x:any) => this.parametersService.getTargetParameters(this.data.company.id, {
                  task_id: card.id,
                  task_operation_id: x.id,
                  discussion_id: 0,
                  file_id: 0
                }).pipe(
                  catchError(error => {
                    return of([]);
                  }),
                  tap(values => {
                    x.tags = values; 
                  })
                ),
                20
              ),
              finalize(() => {
                console.log("finalize get Tags", card)
                let views = 0;
                card.schemeTags.forEach(tag => {
                  if (card.operations.filter(p => p.operation_id == this.scheme.jobType).find(k => !!k.tags.filter(p => p.parameter_value_id == tag.id).length)) {
                    tag.job = card.operations.filter(p => p.operation_id == this.scheme.jobType).find(k => !!k.tags.filter(p => p.parameter_value_id == tag.id).length)
                    if (this.scheme.is_two) {
                      tag.post = card.posts.find(element => element.channel && element.channel.parameterValuesToChannel && element.channel.parameterValuesToChannel.filter(k => k.parameter_value_id == tag.id).length)
                    }

                    if (tag.post) {
                      views += tag.post.content_views_count;
                    }
                  }
                });
                card.totalViews = views
                card.isReadyScheme = true;

                this.sortCards(this.sortValue)
              })
            )
          })
        )
      } else {
        return from(card.operations.filter(k => !k.tags && k.operation_id == this.scheme.jobType)).pipe(
          mergeMap((x:any) => this.parametersService.getTargetParameters(this.data.company.id, {
              task_id: card.id,
              task_operation_id: x.id,
              discussion_id: 0,
              file_id: 0
            }).pipe(
              catchError(error => {
                return of([]);
              }),
              tap(values => {
                x.tags = values; 
              })
            ),
            20
          ),
          finalize(() => {
            console.log("finalize", card)
            let views = 0;
            card.schemeTags.forEach(tag => {
              if (card.operations.filter(p => p.operation_id == this.scheme.jobType).find(k => !!k.tags.filter(p => p.parameter_value_id == tag.id).length)) {
                tag.job = card.operations.filter(p => p.operation_id == this.scheme.jobType).find(k => !!k.tags.filter(p => p.parameter_value_id == tag.id).length)
                if (this.scheme.is_two) {
                  tag.post = card.posts.find(element => element.channel && element.channel.parameterValuesToChannel && element.channel.parameterValuesToChannel.filter(k => k.parameter_value_id == tag.id).length)
                }
                
                if (tag.post) {
                  views += tag.post.content_views_count;
                }
              }
            });
            card.totalViews = views;
            card.isReadyScheme = true;

            this.sortCards(this.sortValue)
          })
        )
      }

    } else {
      return of(null)
    }
  }

  sortCards(val) {
    this.sortValue = val;

    if (this.sortValue == 'views_desc') {
      this.tasks.sort((a, b) => b.totalViews - a.totalViews);
      if (this.filteredTasks) {
        this.filteredTasks.sort((a, b) => b.totalViews - a.totalViews);
      }
    }

    if (this.sortValue == 'views') {
      this.tasks.sort((a, b) => a.totalViews - b.totalViews);
      if (this.filteredTasks) {
        this.filteredTasks.sort((a, b) => a.totalViews - b.totalViews);
      }
    }

    if (this.sortValue == 'updated_desc') {
      this.tasks.sort((a, b) => b.updated_at - a.updated_at);
      if (this.filteredTasks) {
        this.filteredTasks.sort((a, b) => b.updated_at - a.updated_at);
      }
    }

    if (this.sortValue == 'updated') {
      this.tasks.sort((a, b) => a.updated_at - b.updated_at);
      if (this.filteredTasks) {
        this.filteredTasks.sort((a, b) => a.updated_at - b.updated_at);
      }
    }
  }

  checkCCol3(card) {
    return card.schemeTags.filter(k => k.job && this.scheme.reflectJobStatus.includes(k.job.status_id)).length
  }

  checkCCol2(card) {
    let views = 0;
    card.schemeTags.forEach(tag => {
      if (tag.post) {
        views += tag.post.content_views_count;
      }
    });

    return views
  }

  checkCCol1(card) {
    let tags = [];
    card.schemeTags.forEach(tag => {
      if (tag.job) {
        if (this.scheme.jobStatus.includes(tag.job.status_id)) {
          tags.push(tag.value.slice(0, 4));
        }
      }
    });
    return tags.join(', ') + (!!tags.length ? '.' : '');
  }

  neededData(card) {
    console.log('neededData card', card);
    let arr = [];
  
    this.filters.filter(k => k.group_id == card.group_id || k.group_id == 'ignore').forEach(fil => {
      card.operations.filter(job => job.operation_id == fil.task_operation_operation_id 
        && job.status_id == fil.operation_status_id 
        && fil.task_operation_parameter_value_id != 'ignore'
      ).forEach(t => {
        arr.push(t);
      })
    });
  
    console.log('neededData arr', arr);
  
    if (arr && arr.length) {
      return from(arr).pipe(
        mergeMap(x => this.parametersService.getTargetParameters(this.data.company.id, {
            task_id: card.id,
            task_operation_id: x.id,
            discussion_id: 0,
            file_id: 0
          }).pipe(
            catchError(error => {
              return of([]);
            }),
            tap(values => {
              card.operations.find(k => k.id == x.id).tags = values;
              x.tags = values; 
            })
          ),
          20
        ),
        finalize(() => {
          console.log("finalize")
          card.is_ok_tags = true;
          this.filters.filter(k => (k.group_id == card.group_id || k.group_id == 'ignore') && k.task_operation_parameter_value_id != 'ignore').forEach(fil => {
            if (fil.task_operation_parameter_value_id == 'no') {
              if (card.operations.find(job => job.tags && job.tags.length == 0 && !job.is_checked_tags)) {
                card.operations.find(job => job.tags && job.tags.length == 0 && !job.is_checked_tags).is_checked_tags = true;
              } else {
                card.is_ok_tags = false;
              }
            } else {
              if (card.operations.find(job => job.tags && !!job.tags.filter(tag => tag.parameter_value_id == fil.task_operation_parameter_value_id).length && !job.is_checked_tags)) {
                card.operations.find(job => job.tags && !!job.tags.filter(tag => tag.parameter_value_id == fil.task_operation_parameter_value_id).length && !job.is_checked_tags).is_checked_tags = true;
              } else {
                card.is_ok_tags = false;
              }
            }
          });
  
          if (!card.is_ok_tags) {
            console.log('splice');
            this.totalTasksCount--;
            this.tasks.splice(this.tasks.findIndex(l => l.id == card.id), 1);
            console.log('spliced');
          } else {
            card.neededEnd = true;
            if (this.tasks.filter(t => !!t.neededEnd).length == this.tasks.length) {
              this.stage = 'To Compilation wizard';
            }
          }
        })
      );
    } else {
      card.neededEnd = true;
      if (this.tasks.filter(t => !!t.neededEnd).length == this.tasks.length) {
        this.stage = 'To Compilation wizard';
      }
      return of(card); // Return the card if no valid operations
    }
  }

  // neededDataOld(card) {
  //   console.log('neededData card', card);
  //   let arr = [];

  //   this.filters.filter(k => k.group_id == card.group_id).forEach(fil => {
  //     card.operations.filter(job => job.operation_id == fil.task_operation_operation_id && job.status_id == fil.operation_status_id && fil.task_operation_parameter_value_id != 'ignore').forEach(t => {
  //       arr.push(t);
  //     })
  //   });

  //   console.log('neededData arr', arr);

  //   if (arr && arr.length) {
  //     return concat(arr.map(x => this.parametersService.getTargetParameters(this.data.company.id, {
  //         task_id: card.id,
  //         task_operation_id: x.id,
  //         discussion_id: 0,
  //         file_id: 0
  //       }).pipe(
  //         catchError(error => {
  //           return of([])
  //         }),
  //         tap(values => {
  //           x.tags = values;
  //         })
  //       ))
  //     ).pipe(
  //       tap(() => {
  //         card.is_ok_tags = true;
  //         this.filters.filter(k => k.group_id == card.group_id).forEach(fil => {
  //           if (fil.task_operation_parameter_value_id == 'no') {
  //             if (card.operations.find(job => job.tags && job.tags.length == 0 && !job.is_checked_tags)) {
  //               card.operations.find(job => job.tags && job.tags.length == 0 && !job.is_checked_tags).is_checked_tags = true;
  //             } else {
  //               card.is_ok_tags = false;
  //             }
  //           } else {
  //             if (card.operations.find(job => job.tags && !!job.tags.filter(tag => tag.parameter_value_id == fil.task_operation_parameter_value_id).length && !job.is_checked_tags)) {
  //               card.operations.find(job => job.tags && !!job.tags.filter(tag => tag.parameter_value_id == fil.task_operation_parameter_value_id).length && !job.is_checked_tags).is_checked_tags = true;
  //             } else {
  //               card.is_ok_tags = false;
  //             }
  //           }
  //         });
  
  //         if (!card.is_ok_tags) {
  //           console.log('splice');
  //           this.totalTasksCount--;
  //           this.tasks.splice(this.tasks.findIndex(l => l.id == card.id), 1);
  //           console.log('spliced');
  //         } else {
  //           card.neededEnd = true;
  //           if (this.tasks.filter(t => !!t.neededEnd).length == this.tasks.length) {
  //             this.stage = 'To Compilation wizard'
  //           }
  //         }
  
  //       })
  //     )
  //   } else {
  //     card.neededEnd = true;
  //     if (this.tasks.filter(t => !!t.neededEnd).length == this.tasks.length) {
  //       this.stage = 'To Compilation wizard'
  //     }
  //     return of(card)
  //   }

  // }

  checkNeededEndCount() {
    return this.tasks.filter(k => !!k.neededEnd).length
  }

  duplicate(fil) {
    this.filters.push({...fil})
  }

  addFilter() {
    this.filters.push({
      group_id: 'ignore',
      task_parameter_value_id: [],
      task_operation_operation_id: '',
      operation_status_id: 'ignore',
      task_operation_parameter_value_id: 'ignore'
    })
  }

  delFilter(i) {
    this.filters.splice(i, 1)
  }

  getAllApiParameterValues() {
    this.attachSubscriptions(
      this.parametersService.getAllValues('1', this.data.company_id, null, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.parametersService.getAllValues(x, this.data.company_id).pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.allValues = [].concat(...res)
        this.allValues$.next(this.allValues.slice())
      })
    )
  }

  onSearchValues(resp) {
    if (!this.allValues) {
      return;
    }

    if (!resp) {
      this.allValues$.next(this.allValues.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.allValues$.next(
      this.allValues.filter(z => z.value.toLowerCase().indexOf(resp) > -1 || (z.parameter.name && z.parameter.name.toLowerCase().indexOf(resp) > -1))
    );
  }
  
  getValuesById(id) {
    return this.allValues && this.allValues.find(x => x.id == id)
  }

  onSearchGroups(resp) {
    if (!this.groups) {
      return;
    }

    if (!resp) {
      this.groups$.next(this.groups.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.groups$.next(
      this.groups.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  getGroupsCompany() {
    this.attachSubscriptions(
      this.companyService.getInfiniteGroupsWithFilterCompanyWithoutExp(this.data.company_id, '1', '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.companyService.getInfiniteGroupsWithFilterCompanyWithoutExp(this.data.company_id, x).pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.groups = [].concat(...res);
        this.groups$.next(this.groups.slice());
      })
    )
  }

  // Функция для сравнения объектов
  private areObjectsEqual(obj1: any, obj2: any): boolean {
    return obj1.task_operation_operation_id === obj2.task_operation_operation_id &&
          obj1.group_id === obj2.group_id &&
          obj1.task_parameter_value_id.sort().toString() === obj2.task_parameter_value_id.sort().toString() &&
          obj1.operation_status_id === obj2.operation_status_id &&
          obj1.task_operation_parameter_value_id === obj2.task_operation_parameter_value_id;
  }

  // Функция для нахождения дубликатов и их индексов
  public findDuplicateIndices(): number[][] {
    const duplicates: number[][] = [];

    for (let i = 0; i < this.filters.length; i++) {
      for (let j = i + 1; j < this.filters.length; j++) {
        if (this.areObjectsEqual(this.filters[i], this.filters[j])) {
          duplicates.push([i, j]);
        }
      }
    }

    return duplicates;
  }

  openTask(task) {
    const dialogRef = this.dialog.open(OpenTaskComponent, {
      backdropClass: 'backdrop_under_header',
      panelClass: !this.data.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
      autoFocus: false,
      data: {
        task_id: task.id,
        initCompanyId: this.data.company.id
      }
    });
  }

  getOperationsStatus() {
    this.attachSubscriptions(
      this.taskService.getOperationsStatus().subscribe(resp => {
        this.operationStatuses = resp.slice();
      })
    )
  }
  
  onSearchJobTypes(resp) {
    if (!this.jobTypes) {
      return;
    }

    if (!resp) {
      this.jobTypes$.next(this.jobTypes.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    // filter the banks
    this.jobTypes$.next(
      this.jobTypes.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  getJobTypes() {
    this.attachSubscriptions(
      this.taskService.getOperations(this.data.company_id, this.data.activeLang).pipe(
        tap(x => {
          this.jobTypes = x;
          this.jobTypes$.next(this.jobTypes.slice());
        })
      ).subscribe(resp => {
        console.log("getOperations", resp)
      })
    )
  }

  editJobStatus(item) {
    if (!!item.job.gettingStatusInfo) {
      return
    }
    item.job.gettingStatusInfo = true;
    this.attachSubscriptions(
      this.taskService.getOneTaskExpand(this.data.company.id, item.task.id).pipe(
        map(x => x.body[0]),
        switchMap(x => this.neededData(x).pipe(
          map(() => casesModel([x], [], 'update')),
          map(x => x.arr[0])
        ))
      ).subscribe(resp => {
        item.job.gettingStatusInfo = false;
        console.log(resp)
        let _job = resp.operations.find(x => x.id == item.job.id);
        _job.mobEmployees = [];
        let allEmployees = _job.employees.filter(x => x.task_operation_id == _job.id);
        allEmployees.forEach(empl => {
          if (!_job.mobEmployees.find(x => x.employee_id == empl.employee_id)) {
            _job.mobEmployees.push(empl)
          }
        });
        const dialogRef = this.dialog.open(MobChangePersonalStatusComponent, {
          backdropClass: ['mob_interface_backdrop', 'desk_interface_backdrop'],
          panelClass: ['mob_interface_panel', 'desk_interface_panel'],
          data: {
            company: this.data.company,
            task: resp,
            work: _job,
            host: this.host,
            imgRoute: this.data.imgRoute,
            user: this.data.user,
            statuses: this.operationStatuses,
          }
        });
    
        this.attachSubscriptions(
          dialogRef.afterClosed().subscribe(result => {
            if (result) {
              if (result.event == 'back') {
                // this.editWork(job)
              } else if (result.event == 'update') {
                this.updateJob(item);
                // this.initRefreshBoard()
              }
            }
          })
        )
      }, error => {
        item.job.gettingStatusInfo = false;
      })
    )
  }

  removeCard(card) {
    if (this.filteredTasks && this.filteredTasks.find(k => k.id == card.id)) {
      this.filteredTasks.splice(this.filteredTasks.findIndex(k => k.id == card.id), 1)
    }
    this.tasks.splice(this.tasks.findIndex(k => k.id == card.id), 1)
  }

  updateJob(item) {
    this.attachSubscriptions(
      this.taskService.getWork(item.job.id).subscribe(resp => {
        item.job.status_id = resp.status_id;
        item.task.operations.find(k => k.id == item.job.id).status_id = resp.status_id;
        item.task.is_warning_show = false;

        this.filters.filter(k => k.group_id == item.task.group_id || k.group_id == 'ignore').forEach(fil => {
          if (!item.task.is_warning_show) {
            if (fil.operation_status_id != 'ignore') {
              if (item.job.operation_id == fil.task_operation_operation_id && item.job.status_id == fil.operation_status_id && !item.job.is_upd_check) {
                item.job.is_upd_check = true;
              } else {
                item.task.is_warning_show = true;
              }
            }
          }
        });

        console.log("updateJob", item);
      })
    )
  }

  // editJobStatus(item) {   
  //   console.log("editJobStatus", item)
  //   const dialogRef = this.dialog.open(MobChangePersonalStatusComponent, {
  //     // backdropClass: ['mob_interface_backdrop'],
  //     // panelClass: ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'],
  //     data: {
  //       company: this.data.company,
  //       task: item.task,
  //       work: item.job,
  //       host: this.host,
  //       imgRoute: this.data.imgRoute,
  //       user: this.data.user,
  //       statuses: this.operationStatuses,
  //     }
  //   });

  //   this.attachSubscriptions(
  //     dialogRef.afterClosed().subscribe(result => {
  //       if (result) {
  //         if (result.event == 'back') {
  //           // this.editWork(this.task, job)
  //         } else if (result.event == 'update') {
  //           // this.getTask(true);
  //           // this.initRefreshBoard();
  //         }
  //       } else {
  //         // this.getTask(true);
  //         // this.initRefreshBoard();
  //       }
  //     })
  //   )
  // }

  editPriority(item) {
    console.log("editPriority", item)
    const dialogRef = this.dialog.open(WorkEditPriorityComponent, {
      disableClose: true,
      data: {
        company_id: this.data.company_id,
        company: this.data.company,
        tasks: this.tasks,
        is_mobile: this.data.is_mobile,
        imgRoute: this.data.imgRoute,
        user: this.data.user,
        activeLang: this.data.activeLang,
        work: item.job,
        item: item
      }
    });

    this.attachSubscriptions(

      dialogRef.afterClosed().subscribe(result => {
      })
    )
  }

  getBookmarks() {
    this.attachSubscriptions(
      this.companyService.getBookmarks(this.data.company.id, {employee_id: this.data.company.employees[0].id, section: "Cards", type: "Custom filter"}).subscribe(res => {
        this.bookmarks = res;
        console.log("comp assist bookmarks", this.bookmarks);
      })
    )
  }

  changeReqSelect(e) {
    console.log(e);
    if (this.submited) {
  
      this.isEmptyReqs = {
        taskTags: [],
        taskGroup: []
      }
  
      this.filters.forEach(fil => {
        Object.keys(fil).filter(p => ['task_parameter_value_id', 'group_id'].includes(p)).forEach(key => {
          if ('task_parameter_value_id' == key) {
            if (!!fil[key].length) {
              this.isEmptyReqs.taskTags.push(true)
            } else {
              this.isEmptyReqs.taskTags.push(false)
            }
          } else if ('group_id' == key) {
            if (!!fil[key] && fil[key] != 'ignore') {
              this.isEmptyReqs.taskGroup.push(true)
            } else {
              this.isEmptyReqs.taskGroup.push(false)
            }
          }
        });
      });
  
      if (!!this.isEmptyReqs.taskTags.includes(true) && !!this.isEmptyReqs.taskTags.includes(false)) {
        console.log('task tags error',!!this.isEmptyReqs.taskTags.includes(true), !!this.isEmptyReqs.taskTags.includes(false));
        this.haveErrorTags = true;
      } else {
        this.haveErrorTags = false;
      }

      if (!!this.isEmptyReqs.taskGroup.includes(true) && !!this.isEmptyReqs.taskGroup.includes(false)) {
        console.log('task group error',!!this.isEmptyReqs.taskGroup.includes(true), !!this.isEmptyReqs.taskGroup.includes(false));
        this.haveErrorGroup = true;
      } else {
        this.haveErrorGroup = false;
      }
      if (!!this.haveErrorTags || !!this.haveErrorGroup) {
        // this.layoutService.showSnackBar({name: ''}, marker("Need to fix filter"), SnackBarItem)
        return
      }

      this.haveErrorTags = false;
      this.haveErrorGroup = false;
    }
  }
  
  filterCards() {
    console.log('filterCards', this.tasks)
    this.scheme = undefined;

    if (this.filters.length > 1) {

      this.submited = true;
  
      this.isEmptyReqs = {
        taskTags: [],
        taskGroup: []
      }
  
      this.filters.forEach(fil => {
        Object.keys(fil).filter(p => ['task_parameter_value_id', 'group_id'].includes(p)).forEach(key => {
          if ('task_parameter_value_id' == key) {
            if (!!fil[key].length) {
              this.isEmptyReqs.taskTags.push(true)
            } else {
              this.isEmptyReqs.taskTags.push(false)
            }
          } else if ('group_id' == key) {
            if (!!fil[key] && fil[key] != 'ignore') {
              this.isEmptyReqs.taskGroup.push(true)
            } else {
              this.isEmptyReqs.taskGroup.push(false)
            }
          }
        });
      });

      if (!!this.isEmptyReqs.taskTags.includes(true) && !!this.isEmptyReqs.taskTags.includes(false)) {
        console.log('task tags error',!!this.isEmptyReqs.taskTags.includes(true), !!this.isEmptyReqs.taskTags.includes(false));
        this.haveErrorTags = true;
      } else {
        this.haveErrorTags = false;
      }

      if (!!this.isEmptyReqs.taskGroup.includes(true) && !!this.isEmptyReqs.taskGroup.includes(false)) {
        console.log('task group error',!!this.isEmptyReqs.taskGroup.includes(true), !!this.isEmptyReqs.taskGroup.includes(false));
        this.haveErrorGroup = true;
      } else {
        this.haveErrorGroup = false;
      }

      if (!!this.haveErrorTags || !!this.haveErrorGroup) {
        this.layoutService.showSnackBar({name: ''}, marker("Need to fix filter"), SnackBarItem)
        return
      }

      this.haveErrorTags = false;
      this.haveErrorGroup = false;
  
      this.submited = false;  
    }

    let errorLine = -1;
    this.filters.forEach((fil, i) => {
      Object.keys(fil).forEach(key => {
        if (key != 'task_parameter_value_id' && !fil[key]) {
          errorLine = i + 1
        }
      });
      // if (Object.values(fil).filter(p => !!p).length != 4) {
      //   errorLine = i + 1
      // }
    });

    if (errorLine != -1) {
      let string = `The filter line ${errorLine} have empty value:`
      this.layoutService.showSnackBar({name: string}, marker("Fill all line fields."), SnackBarItem)
      return
    }

    const duplicateIndices = this.findDuplicateIndices();
    console.log("duplicateIndices", duplicateIndices);
    if (duplicateIndices.length > 0) {
      let string = `The filter lines are equal to each other:`
      duplicateIndices.forEach((k, i) => {
        string += (i != 0 ? ` and` : ``) + `${[...k].map(x => x+1).join(',')}` 
      })
      string += '.'
      this.layoutService.showSnackBar({name: string}, marker("Fix duplicate filters."), SnackBarItem)
      return
    }
    this.tasks = [];
    this.page = 1;
    if (this.assistantTasksSub) {
      this.assistantTasksSub.unsubscribe();
    }
    if (this.assistantTaskDataSub) {
      this.assistantTaskDataSub.unsubscribe();
    }
    if (this.assistantTaskDataSchemeSub) {
      this.assistantTaskDataSchemeSub.unsubscribe();
    }
    this.assistantTaskDataSub = this.taskService.getAssistantTaskDataStream().pipe(
      concatMap(tasksInPage => {
        return concat(...tasksInPage.map(taskInPage => this.neededData(taskInPage))).pipe(last(),map(x => tasksInPage))
      }),
    ).subscribe(resp => console.log("-----getAssistantTaskDataStream-----",resp));

    this.assistantTaskDataSchemeSub = this.taskService.getAssistantTaskDataSchemeStream().pipe(
      tap(res => {
        console.log("test getAssistantTaskDataSchemeStream", res)
      }),
      concatMap(tasksInPage => {
        return concat(...tasksInPage.map(taskInPage => this.neededDataForScheme(taskInPage))).pipe(last(),map(x => tasksInPage))
      }),
    ).subscribe(resp => console.log("-----getAssistantTaskDataSchemeStream-----",resp));

    this.getCards(this.page);
  }

  checkMatchTags(card, tags) {
    let cardTags = card.parameterValuesToTask.filter(k => !!k.task_id && !k.task_operation_id && !k.discussion_id && !k.file_id).map(p => p.parameter_value_id);
    console.log(card.name, card, cardTags, tags)
    return !!cardTags.length && !!tags.length && cardTags.some(id => tags.includes(id))
  }

  getCards(n) {
    console.log("FILTERs", this.filters)
    this.stage = 'Getting cards';
    this.isLoad = true;
    let filterData: any = {
      task_operation_operation_id: Array.from(new Set(this.filters.map(x => x.task_operation_operation_id))),
      group_id: Array.from(new Set(this.filters.map(x => x.group_id).filter(k => !['ignore'].includes(k)))),
      operation_status_id: Array.from(new Set(this.filters.map(x => x.operation_status_id).filter(k => !['ignore'].includes(k)))),
      task_operation_parameter_value_id: Array.from(new Set(this.filters.map(x => x.task_operation_parameter_value_id).filter(k => !['ignore', 'no'].includes(k)))),
      task_parameter_value_id: Array.from(new Set((this.filters.map(x => x.task_parameter_value_id) as any).flat(Infinity))),
      order_by: 'updated_desc'
    };

    this.assistantTasksSub = of(n).pipe(
      switchMap(pg => {
        if (pg == 1) {
          return this.taskService.getTasksCount(this.data.company_id, filterData).pipe(
            tap(el => {
              this.totalTasksCount = +el.headers.get('x-pagination-total-count')
            })
          )
        } else {
          return of(pg)
        }
      }),
      switchMap(pg => {
        return this.taskService.getCardsNoExpand(n, this.data.company_id, filterData, '50', this.filters.filter(k => k.task_parameter_value_id.length).length && false ? ',parameterValuesToTask.parameter,parameterValuesToTask.parameterValue' : '').pipe(
          tap(arrTasks => {
            console.log("arrTasks", arrTasks)
            if (arrTasks.length < 50) {
              this.hasMore = false
            } else {
              this.hasMore = true;
            }
          }),
          map(resArr => {
            resArr.forEach(card => {
              card.is_ok = true;
              this.filters.filter(k => k.group_id == card.group_id || k.group_id == 'ignore').forEach(fil => {
                // if (fil.task_parameter_value_id.length) {
                //   if (!this.checkMatchTags(card, fil.task_parameter_value_id)) {
                //     card.is_ok = false;
                //   }
                // } 
                if (card.is_ok) {
                  if (fil.operation_status_id == 'ignore') {
                    if (card.operations.find(job => job.operation_id == fil.task_operation_operation_id && !job.is_checked_st_and_type)) {
                      card.operations.find(job => job.operation_id == fil.task_operation_operation_id && !job.is_checked_st_and_type).is_checked_st_and_type = true;
                    } else {
                      card.is_ok = false;
                    }
                  } else {
                    if (card.operations.find(job => job.operation_id == fil.task_operation_operation_id && job.status_id == fil.operation_status_id && !job.is_checked_st_and_type)) {
                      card.operations.find(job => job.operation_id == fil.task_operation_operation_id && job.status_id == fil.operation_status_id && !job.is_checked_st_and_type).is_checked_st_and_type = true;
                    } else {
                      card.is_ok = false;
                    }
                  }
                }
              });
            });
            if (!!this.totalTasksCount) {
              this.totalTasksCount = this.totalTasksCount - (resArr.length - resArr.filter(card => !!card.is_ok).length);
            }
            return resArr.filter(card => !!card.is_ok);
          }),
          tap(resp => {
            if (resp && resp.length) {
              this.taskService.assistantTaskData$.next(resp)
            }
          }),
          distinctUntilChanged()
        )
      })
    ).subscribe(resp => {

      if (+this.page == 1) {
        this.tasks = resp;
      } else {
        this.tasks.push(...resp)
      }
      console.log("this.tasks "+this.page, this.tasks)

      this.page = this.page + 1;

      if (this.hasMore) {
        this.getCards(this.page);
      } else {
        this.totalTasksCount = this.tasks.length;
        this.isLoad = false;
        this.stage = 'Getting job tags'
      }
    },
    error => {
      this.isLoad = false;
    })    
  }

  copyLiveData(type) {
    this.layoutService.showSnackBar({name: type}, marker("Copied"), SnackBarItem)
  }

  log() {
    console.log("CompilationAssistantComponent this.filters", this.filters)
    console.log("CompilationAssistantComponent this.scheme", this.scheme)
    console.log("CompilationAssistantComponent this.tasks", this.tasks)
    console.log("CompilationAssistantComponent this.isEmptyReqs", this.isEmptyReqs)
    console.log("CompilationAssistantComponent this.haveErrorTags", this.haveErrorTags)
    console.log("CompilationAssistantComponent this.haveErrorGroup", this.haveErrorGroup)
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  ngOnDestroy(): void {
    if (this.assistantTasksSub) {
      this.assistantTasksSub.unsubscribe()
    }
    if (this.assistantTaskDataSub) {
      this.assistantTaskDataSub.unsubscribe()
    }
    if (this.assistantTaskDataSchemeSub) {
      this.assistantTaskDataSchemeSub.unsubscribe();
    }
    this.clearSubscriptions()
  }

}
