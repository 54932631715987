<mat-dialog-content class="mat-typography" dirEnter (dirSubmit)="!form.valid ? null : onSubmit()">
    <div class="container form_wrapper">
        <div class="title_modal">
            {{ "Editing a job" | translate }}
        </div>
        <form [formGroup]="form" class="form_with_pads form_with_valids">
            <div class="priority_chips">
                <label>{{ "Priority" | translate }}: </label>
                <mat-chip-list [multiple]="false" selectable formControlName="priority">
                    <mat-chip *ngFor="let priority of prioritys" [ngClass]="{
                        'Urgent': priority.id == '1000',
                        'High': priority.id == '900',
                        'Middle': priority.id == '800',
                        'Low': priority.id == '700',
                        'No': priority.id == '0'
                    }" class="priority_chip" (click)="selectPrioritys(priority)" [value]="priority.id">
                        {{ priority.name | translate }}
                    </mat-chip>
                </mat-chip-list>
            </div>
        </form>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <div class="f_w_p_btns">
        <button mat-raised-button (click)="close()">
            {{ "Close" | translate }}
        </button>
        <button class="ml-1 d-f-btn" (click)="onSubmit()" cdkFocusInitial mat-raised-button color="primary" [disabled]="isSubmit">
            <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>  
            {{ "Save" | translate }}
        </button>
    </div>
</mat-dialog-actions>