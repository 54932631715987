
import { Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { Subscription, concat, forkJoin, from, fromEvent, of, throwError } from 'rxjs';
import { catchError, concatMap, filter, finalize, last, map, retry, switchMap, take, tap } from 'rxjs/operators';
import { DeleteAlertComponent } from 'src/app/shared/global_components/delete-alert/delete-alert.component';
import { BaseClass } from 'src/app/shared/models/base-class';
import { FileService } from 'src/app/shared/services/rest/file.service';
import { MembersService } from 'src/app/shared/services/rest/members.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { BetaVideoEditorComponent } from '../../../beta-video-editor/beta-video-editor.component';
import { MainVideoEditorComponent } from '../../main-video-editor.component';
import { AlphaVideoEditorComponent } from '../../../alpha-video-editor/alpha-video-editor.component';
import { ChatService } from 'src/app/shared/services/rest/chat.service';
import { casesModel } from 'src/app/shared/functions/casesModel';
import { VideoEditorTestComponent } from '../../../video-editor-test/video-editor-test.component';
import { OpenTaskComponent } from 'src/app/components/workspace-pages/cases/dialogs/open-task/open-task.component';
import { OpenJobComponent } from 'src/app/components/workspace-pages/cases/dialogs/open-job/open-job.component';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { Router } from '@angular/router';
import { VideoEditorV5Component } from '../../../video-editor-v5/video-editor-v5.component';
import { TemplatePortal } from '@angular/cdk/portal';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { VideoViewerComponent } from '../../../video-viewer/video-viewer.component';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { SelectionModel } from '@angular/cdk/collections';
import { LiteJobComponent } from 'src/app/components/workspace-pages/cases/dialogs/lite-job/lite-job.component';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { environment } from 'src/environments/environment';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { ProjectsServersTableComponent } from '../../projects-servers-table/projects-servers-table.component';
import { ScenariosService } from 'src/app/shared/services/rest/scenarios.service';
import { VideoFfmpegEditorComponent } from '../../../video-ffmpeg-editor/video-ffmpeg-editor.component';
import { MatMenuTrigger } from '@angular/material/menu';
import { SelectJobComponent } from 'src/app/shared/global_components/select-job/select-job.component';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent extends BaseClass implements OnInit, OnChanges, OnDestroy {  
  // @ViewChild('outFileMenu') outFileMenu: TemplateRef<any>;
  @ViewChild('liteFmMenu') liteFmMenu: TemplateRef<any>;

  public overlayRef: OverlayRef | null;
  public sub: Subscription;

  public is_mobile: boolean = false;
  public taskTemplates: any;
  public activeLang: any;
  public isLoad: boolean = false;
  public projects: any;
  public projectsPage: number = 1;
  public projectsPagination: any;
  public origin = window.location.origin;
  public host: any = environment.host;
  public projectsDataSub: Subscription;
  public imgRoute: any = "";
  public user: any = {};
  @Input() isGlobal: boolean = false;
  @Input() showAddOptions: boolean;
  @Input() company: any;
  @Input() globalFilterForm: any;
  @Input() globalFilter: any;
  @Input() data: any;
  @Output() Close = new EventEmitter<any>();
  @Output() Pagination = new EventEmitter<any>();
  public selectedFiles = new SelectionModel(
    true,
    []
  );
  
  throttle = 100;
  scrollUpDistance = 3.5;
  constructor(
    private taskService: TaskService,
    private dialog: MatDialog,
    private router: Router,
    private auth: AuthService,
    private scenariosService: ScenariosService,
    private languageService: LanguageService,
    private bottomSheet: MatBottomSheet,
    private sm: StorageManagerService,
    public overlay: Overlay,
    public viewContainerRef: ViewContainerRef,
    private chatService: ChatService,
    private membersService: MembersService,
    private fileService: FileService,
    private layoutService: LayoutService,
  ) { super() }

  ngOnInit(): void {
    console.log("ProjectsComponent", this.data)
    // this.projectsDataSub = this.fileService.getProjectsData().pipe(
    //   concatMap(itemsInPage => {
    //     return concat(...itemsInPage.map(itemInPage => this.neededProjectsData(itemInPage))).pipe(last(),map(x => itemsInPage))
    //   }),
    // ).subscribe(resp => console.log("-----getProjectsDataStream-----",resp));
    this.getProjects(this.projectsPage);

    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => this.imgRoute = el)
    )

    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        this.user = resp;
      })
    )

    if (window.innerWidth <= 769) {
      this.is_mobile = true;
    } else {
      this.is_mobile = false;
    }
    this.onResize();
    this.getLangData();
    this.getTaskTemplates();

    if (!this.company && this.data.company) {
      this.company = this.data.company
    }

    this.attachSubscriptions(
      this.fileService.getProjectsRefreshData().subscribe(res => {
        if (!!res) {
          this.refreshProjects()
        }
      })
    )
  }

  openInNew(e, project, key) {
    e.preventDefault();
    e.stopPropagation();
    window.open(this.origin + `/projects?company_id=${this.data.company_id}&order_by=id_desc&${key}=${project[key]}`, '_blank');
  }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this.activeLang = resp.active;
      })
    )
  }

  onResize() {
    this.attachSubscriptions(
      fromEvent(window, "resize").pipe(
        map(() => window.innerWidth)
      ).subscribe((wWidth) => {
          if (wWidth <= 769) {
            this.is_mobile = true;
          } else {
            this.is_mobile = false;
          }
        }
      )
    )
  }

  openServersTable() {
    console.log('openServersTable');
    const dialogRef = this.dialog.open(ProjectsServersTableComponent, {
      backdropClass: ['ve_back_auto'],
      panelClass: ['ve_panel_auto'],
      disableClose: true,
      autoFocus: false,
      data: {
        company: this.company,
        host: this.host,
        is_mobile: this.is_mobile,
        activeLang: this.activeLang,
        task_id: this.data.task_id,
        task_operation_id: this.data.task_operation_id,
        isGlobal: this.isGlobal,
        filterVal: this.globalFilter,
        page: +JSON.parse(JSON.stringify(this.projectsPage)),
        pagination: JSON.parse(JSON.stringify(this.projectsPagination)),
        projects: [...this.projects],
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
      })
    )
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log("Projects changes !!!!", changes)
    if (changes.company && changes.company.currentValue) {
      this.company = changes.company.currentValue
    }
    if (changes.globalFilter && changes.globalFilter.currentValue) {
      this.projectsPage = 1;
      this.projectsPagination = undefined;
      this.getProjects(this.projectsPage);
    }
  }

  log() {
    console.log('projects', this.projects)
  }

  openLiteJob(project, index?, loc?) {
    console.log('openLiteJob', this.company)
    project.targetIds = {
      task_id: project.task_id,
      task_operation_id: project.task_operation_id
    }
    const dialogRef = this.dialog.open(LiteJobComponent, {
      backdropClass: !this.is_mobile ? 'backdrop_under_header': ['backdrop_under_header', 'mob_card_backdrop'],
      panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header', 'lite_job_dialog'] : ['open_task_dialog', 'animate__animated', 'animate__slideInUp'],
      autoFocus: false,
      data: {
        task_id: project.task_id,
        task_operation_id: project.task_operation_id,
        user: this.user,
        company: this.company,
        imgRoute: this.imgRoute,
        host: this.host,
        activeLang: this.activeLang,
        is_mobile: this.is_mobile,
        job: project,
        selectedIndex: index || 0,
        loc: loc,
        openJob: (task_id, item, inData) => {
          console.log("from Lite", task_id, item, inData)
          this.openJob(task_id, item, inData?.loc, inData)
        } 
      }
    })

    // this.attachSubscriptions(
    //   dialogRef.afterClosed().subscribe(result => {
    //     this.updateJob(job.id)
    //   })
    // )
  }
  

  close() {
    this.Close.emit()
  }

  neededProjectsData(proj) {
    let arr = []

    if (true) {
      arr.push(
        this.taskService.getTaskForItem(this.data.company.id, {id: proj.task_id, expand: 'group'}).pipe(
          map(res => {
            if (!!res.length) {
              return res[0]
            } else {
              return undefined
            }
          }),
          tap(task => {
            proj.task = task;
          })
        )
      )
    }

    if (!!proj.task_operation_id) {
      let filter = {task_id: proj.task_id, task_operation_id: proj.task_operation_id}

      arr.push(
        this.membersService.getMembers(this.data.company.id != proj.company_id ? Object.assign({partner_company_id: proj.company_id}, filter) : filter, this.data.company.id).pipe(
          tap(res => {
            console.log("getMembers", res)
            res.forEach(el => {
              if (!proj.employees) {
                proj.employees = [el]
              } else if (proj.employees.filter(u => u.employee_id == el.employee_id).length == 0) {
                proj.employees.push(el);
              }
            })
          })
        )
      )

      arr.push(
        this.membersService.getTaskPartners(this.data.company.id != proj.company_id ? Object.assign({partner_company_id: proj.company_id}, filter) : filter, this.data.company.id).pipe(
          tap(res => {
            console.log("getTaskPartners", res)
            res.forEach(el => {
              el.is_partner = true;
              if (!proj.employees) {
                proj.employees = [el]
              } else if (proj.employees.filter(u => u.partner_company_id == el.partner_company_id).length == 0) {
                proj.employees.push(el);
              }
            })
          })
        )
      )
    }

    arr.push(this.fileService.getVideoProjectRunHistory('1', this.data.company.id, { video_project_id: proj.id }).pipe(
      map(x => x.body),
      tap(res => {
        // console.log('neededProjectsData res', res)
        proj.runHistory = res;
        let x = 0;
        if (res) {
          res.forEach(element => {
            x = +x + +element.price_unit;
          });
        }
        proj.total_price_unit = x;
      }),
      catchError(err => of(err))
    ))

    arr.push(this.fileService.getVideoProjectTaskFile(this.data.company.id, {video_project_id: proj.id }).pipe(
      map(x => x.body),
      tap(res => {
        // console.log('neededProjectsData res', res)
        proj.createdCards = res;
        proj.templateIds = []
        res.forEach(card => {
          if (!proj.templateIds.includes(card.task_template_id)) {
            proj.templateIds.push(card.task_template_id)
          }
        })
        // let x = 0;
        // if (res) {
        //   res.forEach(element => {
        //     x = +x + +element.price_unit;
        //   });
        // }
        // proj.total_price_unit = x;
      }),
      catchError(err => of(err))
    ))
    
    return forkJoin(arr)
  }

  getTaskTemplates() {
    this.attachSubscriptions(
      this.scenariosService.getTaskTemplates('1', {company_id: this.data.company.id}, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.scenariosService.getTaskTemplates(x, {company_id: this.data.company.id}, '200').pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              this.taskTemplates = [].concat(...values)
              this.taskTemplates.unshift({
                id: '',
                name: "Not set"
              })
              console.log("taskTemplates", this.taskTemplates)
            }),
          )
        }),
      ).subscribe(resp => {
        console.log("getTaskTemplates sub", resp);
      })
    )
  }

  getTemplateById(id) {
    if (!this.taskTemplates || this.taskTemplates.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.taskTemplates.find(el => el.id == id)
  }

  onPartnerImgError(event){
    event.target.src = this.imgRoute+'/assets/img/partner.png'
  }

  toggleManager(e, manager) {
    manager.disabled = true
    this.membersService.editMember(manager.id, {is_price_manager: Number(e.checked)}, this.data.company.id).subscribe(resp => {
      manager.is_price_manager = Number(e.checked);
      manager.disabled = false;
    })
  }

  refreshProjects() {
    // if (this.projectsDataSub) {
    //   this.projectsDataSub.unsubscribe()
    // }
    // this.projectsDataSub = this.fileService.getProjectsData().pipe(
    //   concatMap(itemsInPage => {
    //     return concat(...itemsInPage.map(itemInPage => this.neededProjectsData(itemInPage))).pipe(last(),map(x => itemsInPage))
    //   }),
    // ).subscribe(resp => console.log("-----getProjectsDataStream-----",resp));
    this.projectsPage = 1;
    this.getProjects(this.projectsPage);
  }
  
  // addProject() {
  //   const dialogRef = this.dialog.open(TaskManagerAddComponent, {
  //     data: {
  //       company: this.data.company,
  //       user: this.data.user,
  //       taskManagers: this.taskManagers,
  //       task: this.data.task
  //     }
  //   });

  //   this.attachSubscriptions(
  //     dialogRef.afterClosed().subscribe(result => {
  //       console.log(result)
  //       if (result.event == 'update') {
  //         this.getTaskManagers();
  //       }
  //     })
  //   )
  // }


  videoEditorV2(proj, i, openAsNew:boolean = false) {
    if (!this.data.task) {
      this.attachSubscriptions(
        this.taskService.getOneTaskExpand(this.data.company_id, proj.task_id).pipe(
          map(x => x.body[0]),
          switchMap(x => this.neededData(x, this.data.company).pipe(map(() => casesModel([x], [], 'update')),map(x => x.arr[0]))),
        ).subscribe(resp => {
          const dialogRef = this.dialog.open(MainVideoEditorComponent, {
            panelClass: ['full_screen_modal', 'video_editor', 'video_editor_black'],
            autoFocus: false,
            data: {
              company_id: this.data.company_id,
              target_company_id: this.data.target_company_id,
              company: this.data.company,
              user: this.data.user,
              imgRoute: this.data.imgRoute,
              activeLang: this.data.activeLang,
              host: this.data.host,
              task: resp,
              work: !!this.data.task_operation_id ? resp.operations.find(x => x.id == this.data.task_operation_id) : undefined,
              project: proj,
              openAsNew: openAsNew
            }
          });
      
          this.attachSubscriptions(
            dialogRef.afterClosed().subscribe(result => {
              console.log(result)
              if (openAsNew) {
                let index = this.projects.indexOf(proj)
                this.updateProject(proj, index);
              } else {
                this.refreshProjects();
              }
            })
          )
        })
      )
    } else {
      const dialogRef = this.dialog.open(MainVideoEditorComponent, {
        panelClass: ['full_screen_modal', 'video_editor', 'video_editor_black'],
        autoFocus: false,
        data: {
          company_id: this.data.company_id,
          target_company_id: this.data.target_company_id,
          company: this.data.company,
          user: this.data.user,
          imgRoute: this.data.imgRoute,
          activeLang: this.data.activeLang,
          host: this.data.host,
          task: this.data.task,
          work: this.data.work,
          project: proj,
          openAsNew: openAsNew
        }
      });
  
      this.attachSubscriptions(
        dialogRef.afterClosed().subscribe(result => {
          console.log(result)
          if (openAsNew) {
            let index = this.projects.indexOf(proj)
            this.updateProject(proj, index);
          } else {
            this.refreshProjects();
          }
        })
      )
    }
  }
  
  videoEditorV4(proj, i, openAsNew:boolean = false) {
    if (!this.data.task) {
      this.attachSubscriptions(
        this.taskService.getOneTaskExpand(this.data.company_id, proj.task_id).pipe(
          map(x => x.body[0]),
          switchMap(x => this.neededData(x, this.data.company).pipe(map(() => casesModel([x], [], 'update')),map(x => x.arr[0]))),
        ).subscribe(resp => {
          const dialogRef = this.dialog.open(AlphaVideoEditorComponent, {
            panelClass: ['full_screen_modal', 'video_editor', 'video_editor_black'],
            autoFocus: false,
            data: {
              company_id: this.data.company_id,
              target_company_id: this.data.target_company_id,
              company: this.data.company,
              user: this.data.user,
              imgRoute: this.data.imgRoute,
              activeLang: this.data.activeLang,
              host: this.data.host,
              task: resp,
              work: !!this.data.task_operation_id ? resp.operations.find(x => x.id == this.data.task_operation_id) : undefined,
              project: proj,
              openAsNew: openAsNew
            }
          });
      
          this.attachSubscriptions(
            dialogRef.afterClosed().subscribe(result => {
              console.log(result)
              if (openAsNew) {
                let index = this.projects.indexOf(proj)
                this.updateProject(proj, index);
              } else {
                this.refreshProjects();
              }
            })
          )
        })
      )
    } else {
      const dialogRef = this.dialog.open(AlphaVideoEditorComponent, {
        panelClass: ['full_screen_modal', 'video_editor', 'video_editor_black'],
        autoFocus: false,
        data: {
          company_id: this.data.company_id,
          target_company_id: this.data.target_company_id,
          company: this.data.company,
          user: this.data.user,
          imgRoute: this.data.imgRoute,
          activeLang: this.data.activeLang,
          host: this.data.host,
          task: this.data.task,
          work: this.data.work,
          project: proj,
          openAsNew: openAsNew
        }
      });
  
      this.attachSubscriptions(
        dialogRef.afterClosed().subscribe(result => {
          console.log(result)
          if (openAsNew) {
            let index = this.projects.indexOf(proj)
            this.updateProject(proj, index);
          } else {
            this.refreshProjects();
          }
        })
      )
    }
  }

  getCircularReplacer() {
    const ancestors:any = [];
    return function (key, value) {
      if (typeof value !== "object" || value === null) {
        return value;
      }
      // `this` is the object that value is contained in,
      // i.e., its direct parent.
      while (ancestors.length > 0 && ancestors.at(-1) !== this) {
        ancestors.pop();
      }
      if (ancestors.includes(value)) {
        return "[Circular]";
      }
      ancestors.push(value);
      return value;
    };
  }

  videoEditorV6(proj, i, openAsNew:boolean = false) {
    if (!this.data.task) {
      this.attachSubscriptions(
        this.taskService.getOneTaskExpand(this.data.company_id, proj.task_id).pipe(
          map(x => x.body[0]),
          switchMap(x => this.neededData(x, this.data.company).pipe(map(() => casesModel([x], [], 'update')),map(x => x.arr[0]))),
        ).subscribe(resp => {

          // let project = JSON.parse(JSON.stringify(proj, this.getCircularReplacer()))
          // delete project.createdCards;
          // delete project.createdEmployee;
          // delete project.employees;
          // delete project.runHistory;
          // delete project.task;
          // delete project.taskOperation;
          // delete project.updatedEmployee;
          // delete project.templateIds;
          
          // console.log("project", project)
          let work = !!this.data.task_operation_id ? resp.operations.find(x => x.id == this.data.task_operation_id) : undefined
   
          if (work) {
            delete work.updatedEmployee;
            delete work.automationScenarios;
            delete work.uniqueEmployees;
            delete work.chatsInfo;
            delete work.createdEmployee;
            delete work.parameterValuesToTask;
            delete work.employees;
            delete work.employeesStatuses;
            delete work.mainEmployees;
            delete work.partnerCompanies;
            delete work.partnerCompaniesStatuses;
            delete work.partnerEmployees;
            delete work.partnerEmployeesStatuses;
          }
          console.log("work", work)
  
          // card
          delete resp.channels;
          delete resp.chatsInfo;
          delete resp.clients;
          delete resp.contentPlans;
          delete resp.data;
          delete resp.groupedParametersForTask;
          delete resp.previewImgs;
          delete resp.jobEmployees;
          delete resp.parameterValuesToTask;
          delete resp.worksByTypeAndStatus;
          resp.operations = [work];
          console.log("resp", resp)
  

          let modalData = {
            previusUrl: this.router.url,
            company_id: this.data.company_id,
            target_company_id: proj.company_id,
            company: this.data.company,
            user: this.data.user,
            imgRoute: this.data.imgRoute,
            activeLang: this.data.activeLang,
            host: this.data.host,
            task: resp,
            work: work,
            project: {id: proj.id},
            openAsNew: openAsNew
          }
      
          console.log('modalData', modalData)
          this.sm.localStorageSetItem('ve_data', JSON.stringify(modalData, this.getCircularReplacer()))
          // window.open(this.host,)
          // console.log('sm modalData', JSON.parse(this.sm.localStorageGetItem('ve_data')))
          
          window.open(this.origin + `/video-editor?company_id=${this.data.company_id}`, '_blank');
        })
      )
    } else {
      let modalData = {
        previusUrl: this.router.url,
        company_id: this.data.company_id,
        target_company_id: proj.company_id,
        company: this.data.company,
        user: this.data.user,
        imgRoute: this.data.imgRoute,
        activeLang: this.data.activeLang,
        host: this.data.host,
        task: this.data.task,
        work: this.data.work,
        project: proj,
        openAsNew: openAsNew
      }
  
      console.log('modalData', modalData)
      this.sm.localStorageSetItem('ve_data', JSON.stringify(modalData, this.getCircularReplacer()))
      // window.open(this.host,)
      // console.log('sm modalData', JSON.parse(this.sm.localStorageGetItem('ve_data')))
      
      window.open(this.origin + `/video-editor?company_id=${this.data.company_id}`, '_blank');
    }

  }
  
  V5VideoEditor(proj, i, openAsNew:boolean = false) {
    if (!this.data.task) {
      this.attachSubscriptions(
        this.taskService.getOneTaskExpand(this.data.company_id, proj.task_id).pipe(
          map(x => x.body[0]),
          switchMap(x => this.neededData(x, this.data.company).pipe(map(() => casesModel([x], [], 'update')),map(x => x.arr[0]))),
        ).subscribe(resp => {
          const dialogRef = this.dialog.open(VideoEditorV5Component, {
            panelClass: ['full_screen_modal', 'video_editor', 'video_editor_black'],
            autoFocus: false,
            disableClose: true,
            data: {
              company_id: this.data.company_id,
              target_company_id: proj.company_id,
              company: this.data.company,
              user: this.data.user,
              imgRoute: this.data.imgRoute,
              activeLang: this.data.activeLang,
              host: this.data.host,
              task: resp,
              work: !!this.data.task_operation_id ? resp.operations.find(x => x.id == this.data.task_operation_id) : undefined,
              project: proj,
              openAsNew: openAsNew
            }
          });
      
          this.attachSubscriptions(
            dialogRef.afterClosed().subscribe(result => {
              console.log(result)
              if (openAsNew) {
                let index = this.projects.indexOf(proj)
                this.updateProject(proj, index);
              } else {
                this.refreshProjects();
              }
            })
          )
        })
      )
    } else {
      const dialogRef = this.dialog.open(VideoEditorV5Component, {
        panelClass: ['full_screen_modal', 'video_editor', 'video_editor_black'],
        autoFocus: false,
        disableClose: true,
        data: {
          company_id: this.data.company_id,
          target_company_id: proj.company_id,
          company: this.data.company,
          user: this.data.user,
          imgRoute: this.data.imgRoute,
          activeLang: this.data.activeLang,
          host: this.data.host,
          task: this.data.task,
          work: this.data.work,
          project: proj,
          openAsNew: openAsNew
        }
      });
  
      this.attachSubscriptions(
        dialogRef.afterClosed().subscribe(result => {
          console.log(result)
          if (openAsNew) {
            let index = this.projects.indexOf(proj)
            this.updateProject(proj, index);
          } else {
            this.refreshProjects();
          }
        })
      )
    }

  }

  videoEditorV5(proj, i, openAsNew:boolean = false) {
    if (!this.data.task) {
      this.attachSubscriptions(
        this.taskService.getOneTaskExpand(this.data.company_id, proj.task_id).pipe(
          map(x => x.body[0]),
          switchMap(x => this.neededData(x, this.data.company).pipe(map(() => casesModel([x], [], 'update')),map(x => x.arr[0]))),
        ).subscribe(resp => {
          const dialogRef = this.dialog.open(VideoEditorTestComponent, {
            panelClass: ['full_screen_modal', 'video_editor', 'video_editor_black'],
            autoFocus: false,
            disableClose: true,
            data: {
              company_id: this.data.company_id,
              target_company_id: proj.company_id,
              company: this.data.company,
              user: this.data.user,
              imgRoute: this.data.imgRoute,
              activeLang: this.data.activeLang,
              host: this.data.host,
              task: resp,
              work: !!this.data.task_operation_id ? resp.operations.find(x => x.id == this.data.task_operation_id) : undefined,
              project: proj,
              openAsNew: openAsNew
            }
          });
      
          this.attachSubscriptions(
            dialogRef.afterClosed().subscribe(result => {
              console.log(result)
              if (openAsNew) {
                let index = this.projects.indexOf(proj)
                this.updateProject(proj, index);
              } else {
                this.refreshProjects();
              }
            })
          )
        })
      )
    } else {
      const dialogRef = this.dialog.open(VideoEditorTestComponent, {
        panelClass: ['full_screen_modal', 'video_editor', 'video_editor_black'],
        autoFocus: false,
        disableClose: true,
        data: {
          company_id: this.data.company_id,
          target_company_id: proj.company_id,
          company: this.data.company,
          user: this.data.user,
          imgRoute: this.data.imgRoute,
          activeLang: this.data.activeLang,
          host: this.data.host,
          task: this.data.task,
          work: this.data.work,
          project: proj,
          openAsNew: openAsNew
        }
      });
  
      this.attachSubscriptions(
        dialogRef.afterClosed().subscribe(result => {
          console.log(result)
          if (openAsNew) {
            let index = this.projects.indexOf(proj)
            this.updateProject(proj, index);
          } else {
            this.refreshProjects();
          }
        })
      )
    }

  }

  duplicateToOtherJob(proj, i, openAsNew:boolean = false, trigger:MatMenuTrigger, version) {
    trigger.closeMenu();

    const dialogRef = this.dialog.open(SelectJobComponent, {
      disableClose: true,
      data: {
        user: this.user,
        company: this.company,
        project: proj
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log("duplicateToOtherJob", result);
        if (result.data.job) {
          if (version == "A") {
            this.videoEditorVA(proj,i,openAsNew, result.data.job)
          } else {
            this.videoEditorAA(proj,i,openAsNew, result.data.job)
          }
        }
      })
    )
  } 

  videoEditorAA(proj, i, openAsNew:boolean = false, otherJob?) {
    this.attachSubscriptions(
      this.taskService.getOneTaskExpand(this.data.company_id, proj.task_id).pipe(
        map(x => x.body[0]),
        switchMap(x => this.neededData(x, this.company).pipe(map(() => casesModel([x], [], 'update')),map(x => x.arr[0]))),
      ).subscribe(resp => {

        // let project = JSON.parse(JSON.stringify(proj, this.getCircularReplacer()))
        // delete project.createdCards;
        // delete project.createdEmployee;
        // delete project.employees;
        // delete project.runHistory;
        // delete project.task;
        // delete project.taskOperation;
        // delete project.updatedEmployee;
        // delete project.templateIds;
        
        // console.log("project", project)
        let work = !!proj.task_operation_id ? resp.operations.find(x => x.id == proj.task_operation_id) : undefined
        if (!!otherJob) {
          work = otherJob;
        }
        if (work) {
          delete work.updatedEmployee;
          delete work.automationScenarios;
          delete work.uniqueEmployees;
          delete work.chatsInfo;
          delete work.createdEmployee;
          delete work.parameterValuesToTask;
          delete work.employees;
          delete work.employeesStatuses;
          delete work.mainEmployees;
          delete work.partnerCompanies;
          delete work.partnerCompaniesStatuses;
          delete work.partnerEmployees;
          delete work.partnerEmployeesStatuses;
        }
        console.log("work", work)

        // card
        delete resp.channels;
        delete resp.chatsInfo;
        delete resp.clients;
        delete resp.contentPlans;
        delete resp.data;
        delete resp.groupedParametersForTask;
        delete resp.previewImgs;
        delete resp.jobEmployees;
        delete resp.parameterValuesToTask;
        delete resp.worksByTypeAndStatus;
        resp.operations = [work];
        console.log("resp", resp)


        let modalData = {
          previusUrl: this.router.url,
          company_id: this.data.company_id,
          target_company_id: proj.company_id,
          company: this.data.company,
          user: this.user,
          imgRoute: this.imgRoute,
          activeLang: this.activeLang,
          host: this.host,
          task: resp,
          work: work,
          project: {id: proj.id},
          openAsNew: openAsNew,
          otherJob: !!otherJob
        }
    
        console.log('modalData', modalData)
        this.sm.localStorageSetItem('ve_data', JSON.stringify(modalData, this.getCircularReplacer()))
        // window.open(this.host,)
        // console.log('sm modalData', JSON.parse(this.sm.localStorageGetItem('ve_data')))
        
        window.open(this.origin + `/video-editor-a?company_id=${this.data.company_id}`, '_blank');
      })
    )
  }

  videoEditorVA(proj, i, openAsNew:boolean = false, otherJob?) {
    this.attachSubscriptions(
      this.taskService.getOneTaskExpand(this.data.company_id, proj.task_id).pipe(
        map(x => x.body[0]),
        switchMap(x => this.neededData(x, this.company).pipe(map(() => casesModel([x], [], 'update')),map(x => x.arr[0]))),
      ).subscribe(resp => {

        let work = !!proj.task_operation_id ? resp.operations.find(x => x.id == proj.task_operation_id) : undefined;
        if (!!otherJob) {
          work = otherJob
        }
        
        const dialogRef = this.dialog.open(VideoFfmpegEditorComponent, {
          panelClass: ['full_screen_modal', 'video_editor', 'video_editor_black'],
          autoFocus: false,
          disableClose: true,
          data: {
            company_id: this.data.company_id,
            target_company_id: proj.company_id,
            company: this.data.company,
            user: this.data.user,
            imgRoute: this.data.imgRoute,
            activeLang: this.data.activeLang,
            host: this.data.host,
            task: resp,
            work: work,
            project: proj,
            openAsNew: openAsNew
          }
        });
    
        this.attachSubscriptions(
          dialogRef.afterClosed().subscribe(result => {
            console.log(result)
            if (openAsNew) {
              let index = this.projects.indexOf(proj)
              this.updateProject(proj, index);
            } else {
              this.refreshProjects();
            }
          })
        )
      })
    )
  }

  neededCardData(task) {
    let arr = [
      this.chatService.getTasksChats(this.data.company.id, [task.id]).pipe(
        tap(res => {
          task['operations'].map(o_el => {
            o_el['chatsInfo'] = [];
            o_el['chatsInfo'].push(...res.filter(z => z.task_operation_id == o_el.id));
            o_el['unReadChats'] = res.filter(z => z.task_operation_id == o_el.id && z.is_read != '1').length;
            o_el['unClosedChats'] = res.filter(z => z.task_operation_id == o_el.id && z.status_id != '4').length;
          });
          task['chatsInfo'] = [];
          task['chatsInfo'].push(...res.filter(z => z.task_id == task.id));
          task['unReadChats'] = res.filter(z => z.is_read != '1').length;
          task['unClosedChats'] = res.filter(z => z.status_id != '4').length;
        })
      )
    ]
    if (task.company_id == this.data.company.id) {
      arr.push(
        this.taskService.getTaskClients(task.id, this.data.company.id).pipe(
          tap(res => {
            task.clients = res
          }),
          catchError(err => {
            return err
          })
        )
      )
    }
    arr.push(...task.operations.filter(z => (z.status_id == 3 && z.is_to_approve_files) || (z.status_id == 98 && z.is_approved_files)).map(x => this.fileService.getFilesForOpenTask(this.data.company.id, x.id, x.status_id == 3 ? '/to_approve' : '/ready_files').pipe(
      tap(val => {
        x.filesCount = val.headers.get('x-pagination-total-count')
        x.files = val.body
      })
    )))

    return forkJoin(arr)
  }
  

  openTask(task) {
    const dialogRef = this.dialog.open(OpenTaskComponent, {
      backdropClass: 'backdrop_under_header',
      panelClass: !this.data.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
      autoFocus: false,
      data: {
        task_id: task.id,
        initCompanyId: this.data.company.id
      }
    });
  }

  editProjStatus(project, key) {
    this.attachSubscriptions(
      this.fileService.editVideoProject(project.id, {[key]: 1, is_to_process: 0}, this.data.company.id).subscribe(res => {
        console.log('editProjStatus res', res)
        project.is_to_process = res.is_to_process;
        project[key] = res[key];
      }, error => {
        this.layoutService.showSnackBar({name: ''}, marker(error), SnackBarItem)
      })
    )
  }

  editProjPriority(project) {
    this.attachSubscriptions(
      this.fileService.editVideoProject(project.id, {waiting_time_limit: project.waiting_time_limit}, this.data.company.id).subscribe(res => {
        console.log('editProjPriority res', res)
        project.save_waiting_time_limit = res.waiting_time_limit;
      }, error => {
        this.layoutService.showSnackBar({name: ''}, marker(error), SnackBarItem)
        project.waiting_time_limit = project.save_waiting_time_limit;
      })
    )
  }

  openJob(task_id, task_operation_id, loc?, initData?) {
    this.attachSubscriptions(
      this.taskService.getOneTaskExpand(this.data.company.id, task_id).pipe(
        map(x => x.body[0]),
        switchMap(x => this.neededCardData(x).pipe(map(() => casesModel([x], [], 'update')),map(x => x.arr[0]))),
      ).subscribe(resp => {
        const dialogRef = this.dialog.open(OpenJobComponent, {
          backdropClass: 'backdrop_under_header',
          panelClass: !this.data.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
          autoFocus: false,
          data: {
            task_id: task_id,
            task_operation_id: initData ? task_operation_id.task_operation_id : task_operation_id,
            task: resp,
            initCompanyId: this.data.company.id,
            loc: loc,
            fast_mode: initData ? true : false,
            selectedIndex: !this.is_mobile && !initData ? 4 : initData?.selectedIndex,
          }
        });
      })
    )
  }


  videoEditorV3(proj, i, openAsNew:boolean = false) {
    if (!this.data.task) {
      this.attachSubscriptions(
        this.taskService.getOneTaskExpand(this.data.company_id, proj.task_id).pipe(
          map(x => x.body[0]),
          switchMap(x => this.neededData(x, this.data.company).pipe(map(() => casesModel([x], [], 'update')),map(x => x.arr[0]))),
        ).subscribe(resp => {
          const dialogRef = this.dialog.open(BetaVideoEditorComponent, {
            panelClass: ['full_screen_modal', 'video_editor', 'video_editor_black'],
            autoFocus: false,
            disableClose: true,
            data: {
              company_id: this.data.company_id,
              target_company_id: proj.company_id,
              company: this.data.company,
              user: this.data.user,
              imgRoute: this.data.imgRoute,
              activeLang: this.data.activeLang,
              host: this.data.host,
              task: resp,
              work: !!this.data.task_operation_id ? resp.operations.find(x => x.id == this.data.task_operation_id) : undefined,
              project: proj,
              openAsNew: openAsNew
            }
          });
      
          this.attachSubscriptions(
            dialogRef.afterClosed().subscribe(result => {
              console.log(result)
              if (openAsNew) {
                let index = this.projects.indexOf(proj)
                this.updateProject(proj, index);
              } else {
                this.refreshProjects();
              }
            })
          )
        })
      )
    } else {
      const dialogRef = this.dialog.open(BetaVideoEditorComponent, {
        panelClass: ['full_screen_modal', 'video_editor', 'video_editor_black'],
        autoFocus: false,
        disableClose: true,
        data: {
          company_id: this.data.company_id,
          target_company_id: proj.company_id,
          company: this.data.company,
          user: this.data.user,
          imgRoute: this.data.imgRoute,
          activeLang: this.data.activeLang,
          host: this.data.host,
          task: this.data.task,
          work: this.data.work,
          project: proj,
          openAsNew: openAsNew
        }
      });
  
      this.attachSubscriptions(
        dialogRef.afterClosed().subscribe(result => {
          console.log(result)
          if (openAsNew) {
            let index = this.projects.indexOf(proj)
            this.updateProject(proj, index);
          } else {
            this.refreshProjects();
          }
        })
      )
    }

  }


  openContext({ x, y }: MouseEvent, project) {
    this.closeContext();
    this.selectedFiles.clear();
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo({ x, y })
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        }
      ]);

    console.log("openContext project", project);
    project.is_clicked = true;
    project.outputFolder = {
      targetIds: {
        task_id: project.task_id,
        task_operation_id: project.task_operation_id,
      },
      page: 1,
      pagination: undefined,
      files: [],
      is_load: true
    }

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    this.overlayRef.attach(new TemplatePortal(this.liteFmMenu, this.viewContainerRef, {
      $implicit: project
    }));
    
    this.sub = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter(event => {
          const clickTarget = event.target as HTMLElement;
          let check1 = !document.getElementsByClassName('proj-file-menu') || document.getElementsByClassName('proj-file-menu').length > 0 && !document.getElementsByClassName('proj-file-menu')[0].contains(clickTarget);
          let check2 = !!this.overlayRef && !this.overlayRef.overlayElement.contains(clickTarget) && !clickTarget.closest('.proj-file-menu')
          let check3 = !!this.overlayRef && !this.overlayRef.overlayElement.contains(clickTarget) 
          let check4 = !clickTarget.closest('.proj-file-menu')
          console.log("clickTarget", clickTarget)
          console.log("this.overlayRef.overlayElement", this.overlayRef.overlayElement)
          console.log("check1", check1)
          console.log("check2", check2)
          console.log("check3", check3)
          console.log("check4", check4)
          return check2;
        }),
        take(1)
      ).subscribe(() => {
        project.is_clicked = false;
        this.closeContext()
      })

  }

  onImgError(event){
    event.target.src = this.data.imgRoute+'/assets/img/image_black_48dp.svg'
  }

  closeContext() {
    this.sub && this.sub.unsubscribe();
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }

  toggleProject(project) {
    console.log("project", project)
    project.isOpen = !project.isOpen;
  }

  getProjects(page) {
    if (this.isLoad) {
      return
    }
    this.isLoad = true;
    let filter:any = {};
    if (this.isGlobal) {
      filter = {...this.globalFilter}
    } else {
      if (this.data.task_id) {
        filter.task_id = this.data.task_id
      }
      if (this.data.task_operation_id) {
        filter.task_operation_id = this.data.task_operation_id
      }
    }

    if (page == 1) {
      if (this.projectsDataSub) {
        this.projectsDataSub.unsubscribe()
      }
      this.projectsDataSub = this.fileService.getProjectsData().pipe(
        concatMap(itemsInPage => {
          return concat(...itemsInPage.map(itemInPage => this.neededProjectsData(itemInPage))).pipe(last(),map(x => itemsInPage))
        }),
      ).subscribe(resp => console.log("-----getProjectsDataStream-----",resp));
    }
    this.attachSubscriptions(
      this.fileService.getVideoProjects(page, this.data.company.id, filter, '100').pipe(
        tap(el => {
          this.projectsPagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
          this.Pagination.emit(this.projectsPagination)
        }),
        map(x => x.body),
        tap(resp => {
          resp.map(u => {
            u.save_waiting_time_limit = u.waiting_time_limit;
          })
          this.fileService.projectsData$.next(resp)
        }),
      ).subscribe(resp => {
        if (page == 1) {
          this.projects = resp;
        } else {
          this.projects.push(...resp);
        }
        console.log("getProjects - " + this.projectsPage, this.projects);
        this.projectsPage++;
        this.isLoad = false;
      }, error => {
        this.isLoad = false;
      })
    )
  }

  onDown(e) {
    console.log("SCROLL DOWN", e);
    if (this.projectsPagination['pageCount'] >= this.projectsPage) {
      console.log("getProjects")
      this.getProjects(this.projectsPage);
    }
  }


  neededData(task, company) {
    let arr = [
      this.chatService.getTasksChats(company.id, [task.id]).pipe(
        tap(res => {
          task['operations'].map(o_el => {
            o_el['chatsInfo'] = [];
            o_el['chatsInfo'].push(...res.filter(z => z.task_operation_id == o_el.id));
            o_el['unReadChats'] = res.filter(z => z.task_operation_id == o_el.id && z.is_read != '1').length;
            o_el['unClosedChats'] = res.filter(z => z.task_operation_id == o_el.id && z.status_id != '4').length;
          });
          task['chatsInfo'] = [];
          task['chatsInfo'].push(...res.filter(z => z.task_id == task.id));
          task['unReadChats'] = res.filter(z => z.is_read != '1').length;
          task['unClosedChats'] = res.filter(z => z.status_id != '4').length;
        })
      )
    ]
    if (task.company_id == company.id) {
      arr.push(
        this.taskService.getTaskClients(task.id, company.id).pipe(
          tap(res => {
            task.clients = res
          }),
          catchError(err => {
            return err
          })
        )
      )
    }
    return forkJoin(arr)
  }

  updateProject(project, i) {
    this.attachSubscriptions(
      this.fileService.getVideoProjects('1', this.data.company.id, {id: project.id}, '100').pipe(
        map(res => res.body),
        switchMap(res => {
          return this.fileService.getVideoEditorHistory('1', this.data.company.id, {
            company_id: project.company_id,
            video_project_id: res.id,
            is_active: 1
          }, '200').pipe(
            tap(el => {
              res.historyCount = +el.headers.get('x-pagination-total-count')
            }),
            map(() => res)
          )
        }),
      ).subscribe(result => {
        this.projects.splice(i,1,result[0]);
      })
    )
  }

  refreshProject(project, i) {
    this.attachSubscriptions(
      this.fileService.editVideoProject(project.id, {
        is_draft: 0,
        is_error: 0,
        is_processing: 0,
        is_ready: 0,
        is_to_process: 1
      }, this.data.company.id).pipe(
        switchMap(x => {
          return this.fileService.getVideoProjects('1', this.data.company.id, {id: project.id,task_id: this.data.task_id, task_operation_id: this.data.task_operation_id}, '100').pipe(
            map(res => res.body),
            switchMap(res => {
              return this.fileService.getVideoEditorHistory('1', this.data.company.id, {
                company_id: this.data.target_company_id,
                video_project_id: res.id,
                is_active: 1
              }, '200').pipe(
                tap(el => {
                  res.historyCount = +el.headers.get('x-pagination-total-count')
                }),
                map(() => res)
              )
            }),
            tap(result => {
              this.projects.splice(i,1,result[0]);
            })
          )
        })
      ).subscribe(resp => {
        console.log("END Refresh", resp)
      })
    )
  }

  deleteProject(project, i) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: {},
        target: marker("project")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.fileService.deleteVideoProject(project.id, this.data.company.id).pipe(
            tap(x => {
              console.log(x)
              this.projects.splice(i,1);
            }),
          ).subscribe(resp => {
            console.log("END DELETE", resp)
          })
        )
      }
    });
  
  }

  ngOnDestroy() {
    if (this.projectsDataSub) {
      this.projectsDataSub.unsubscribe()
    }
    this.clearSubscriptions()
  }

}
