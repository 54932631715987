import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ReplaySubject, forkJoin, of } from 'rxjs';
import { catchError, concatMap, debounceTime, last, map, switchMap, tap } from 'rxjs/operators';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { FileService } from 'src/app/shared/services/rest/file.service';
import { ParametersService } from 'src/app/shared/services/rest/parameters.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MY_FORMATS } from 'src/app/components/atTasksDialog/task-profile-add/task-profile-add.component';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { MembersService } from 'src/app/shared/services/rest/members.service';
import { RefreshService } from 'src/app/shared/services/rest/refresh.service';
import { jobsModel } from 'src/app/shared/functions/jobsModel';
import { GlobalDataService } from 'src/app/shared/services/common/global-data.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { StatusReasonComponent } from '../status-reason/status-reason.component';
import { ToApproveStepperComponent } from '../to-approve-stepper/to-approve-stepper.component';
import { casesModel } from 'src/app/shared/functions/casesModel';
@Component({
  selector: 'app-lite-job',
  templateUrl: './lite-job.component.html',
  styleUrls: ['./lite-job.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})
export class LiteJobComponent extends BaseClass implements OnInit, OnDestroy {
  public selectedIndex = new FormControl(null);
  public count:number = 0;
  public origin = window.location.origin
  public is_filter_active: boolean = false;

  public liteBoardFilter: FormGroup = this.fb.group({
    q: '',
    order_by: 'priority_order'
  })

  public savedLiteBoardFilter = {
    q: '',
    order_by: 'priority_order'
  }
  public currencyData: any;
  public groups: any;

  public liteChatFilter: FormGroup = this.fb.group({
    q: '',
    q_name: '',
    q_text: '',
    discussion_parameter_value_id: [[]],
    data_file_id: '',
    discussion_created_at_from: "",
    discussion_created_at_to: "",
    posted_at_from : '',
    posted_at_to : '',
    discussion_planned_completed_at_from : '',
    discussion_planned_completed_at_to : '',
    discussion_completed_at_from : '',
    discussion_completed_at_to : '',
  })

  public savedLiteChatFilter = {
    q: '',
    q_name: '',
    q_text: '',
    discussion_parameter_value_id: [[]],
    data_file_id: '',
    discussion_created_at_from: "",
    discussion_created_at_to: "",
    posted_at_from : '',
    posted_at_to : '',
    discussion_planned_completed_at_from : '',
    discussion_planned_completed_at_to : '',
    discussion_completed_at_from : '',
    discussion_completed_at_to : '',
  }

  public liteFmFilter: FormGroup = this.fb.group({
    count: '',
    q: '',
    is_dir: '',
    content_type_id: '',
    operation_reminder_id: '',
    meta_device: '',
    related_count: '',
    part_of_count: '',
    consist_of_count: '',
    is_ready_if_approved: '',
    task_parameter_value_id: [[]],
    task_operation_parameter_value_id : [[]],
    discussion_parameter_value_id: [[]],
    file_parameter_value_id : [[]],
    parameter_value_id: [[]],
    operation_employee_id: [[]],
    operation_partner_company_id: [[]],
    origin_company_id : '',
    channel_platform_id : '',
    // group_by : 'original_file_id',
    channel_id: [[]],
    no_channel_id: [[]],
    task_custom_id: '',
    file_id: '',
    order_by: 'updated_desc',
    content_status_id: '',
    id: '',
    internal_id: "",
    file_created_at_from: "",
    file_created_at_to: "",
    file_updated_at_from: "",
    file_updated_at_to: "",
    file_meta_creation_time_from: "",
    file_meta_creation_time_to: "",
    location_type: "location",
  });

  public savedLiteFmFilter = {
    count: '',
    q: '',
    is_dir: '',
    content_type_id: '',
    operation_reminder_id: '',
    meta_device: '',
    related_count: '',
    part_of_count: '',
    consist_of_count: '',
    is_ready_if_approved: '',
    task_parameter_value_id: [[]],
    task_operation_parameter_value_id : [[]],
    discussion_parameter_value_id: [[]],
    file_parameter_value_id : [[]],
    parameter_value_id: [[]],
    operation_employee_id: [[]],
    operation_partner_company_id: [[]],
    origin_company_id : '',
    channel_platform_id : '',
    group_by : 'original_file_id',
    channel_id: [[]],
    no_channel_id: [[]],
    task_custom_id: '',
    file_id: '',
    order_by: 'updated_desc',
    content_status_id: '',
    id: '',
    internal_id: "",
    file_created_at_from: "",
    file_created_at_to: "",
    file_updated_at_from: "",
    file_updated_at_to: "",
    file_meta_creation_time_from: "",
    file_meta_creation_time_to: "",
    location_type: "location",
  }

  public job: any;
  public statuses: any;
  public operationsValues: any;
  public allValues: any;
  public allValuesControl: FormControl = new FormControl();
  public allValues$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public checklists: any;
  public checklistsControl: FormControl = new FormControl();
  public checklists$: ReplaySubject<any> = new ReplaySubject<any>(1);

  public devices: any;
  public devices$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public devicesMoreControl: FormControl = new FormControl();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private taskService: TaskService,
    private fileService: FileService,
    private membersService: MembersService,
    private refreshService: RefreshService,
    private companyService: CompanyService,
    private globalDataService: GlobalDataService,
    private parametersService: ParametersService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private _adapter: DateAdapter<any>,
    public dialogRef: MatDialogRef<LiteJobComponent>,
    private sm: StorageManagerService,
    private layoutService: LayoutService
  ) { super() }

  ngOnInit(): void {
    console.log("LiteJobComponent", this.data);
    this.data.dialogHasFm = true;
    this._adapter.setLocale(this.data.activeLang);
    this.attachSubscriptions(
      this.selectedIndex.valueChanges.subscribe(val => {
        console.log("this.selectedIndex.valueChanges", val);
        this.count++
        if (val == 3) {
          this.data.job.can_open = true;
          this.data.job.is_clicked = true;
          if (!(this.count == 1 && this.data.job.output_location)) {
            this.data.job.active_location = '/'
            this.data.job.output_location = ''
          } else {
            if (this.data.job.output_location) {
              this.data.job.active_location = this.data.job.output_location;
            }
          }
          if (!this.data.job.hasOwnProperty('outputFolder')) {
            this.data.job.outputFolder = {
              targetIds: this.data.job.targetIds,
              page: 1,
              pagination: undefined,
              files: [],
              is_load: true
            }
          }
        } else if (val == 0) {
          delete this.data.job.outputFolder;
          this.data.job.outputChats = {
            targetIds: this.data.job.targetIds,
            page: 1,
            pagination: undefined,
            chats: [],
            is_load: true
          }
        } else if (val == 1) {
          delete this.data.job.outputFolder;
          if (!this.data.job.hasOwnProperty('outputExecutors')) {
            this.data.job.outputExecutors = {
              targetIds: this.data.job.targetIds,
              employees: [],
              partnerCompanies: [],
              clients: [],
              is_load: true,
            }
          }
        } else if (val == 2) {
          if (!this.data.job.hasOwnProperty('outputBoard')) {
            this.data.job.outputBoard = {
              targetIds: this.data.job.targetIds,
              todoPage: 1,
              donePage: 1,
              todoPagination: undefined,
              donePagination: undefined,
              todo_is_load: true,
              done_is_load: true,
              todo: [],
              done: [],
              groupedDone: [],
              boardsIndex: 0
            }
          }
        } else {
          delete this.data.job.outputFolder;
        }

        console.log("this.data.job", this.data.job)
      })
    )

    if (this.data.hasOwnProperty('selectedIndex')) {
      this.selectedIndex.patchValue(this.data.selectedIndex);
    }

    this.attachSubscriptions(
      this.checklistsControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchChecklists(resp))
    )
    this.attachSubscriptions(
      this.devicesMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchDevices(resp))
    )
    this.attachSubscriptions(
      this.allValuesControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchValues(resp))
    )
    this.job = this.data.job.taskOperation ? this.data.job.taskOperation : this.data.job;
    this.getOperations();
    this.getOperationsStatus();
    this.getChecklists();
    this.getFileDevices();
    this.getAllApiParameterValues();

    if (this.data.company && this.job.task_id) {

      if (!this.job.hasOwnProperty('clients')) {
        this.taskService.getTaskClientsDyn("1", this.job.task_id, this.data.company.id, '200', {task_operation_id: `0,${this.job.id}`}).pipe(
          map(x => x.body),
          tap(res => {
            this.job.clients = res;
          })
        ).subscribe(resp => {
          console.log("this.taskService.getTaskClientsDyn", resp)
        })
      }
      if (!this.job.hasOwnProperty('taskManagers') || !this.job.hasOwnProperty('isManager')) {
        this.membersService.getMembers({task_id: this.job.task_id, is_manager: 1, task_operation_id: 0}, this.data.company.id).pipe(
          tap(res => {
            this.job.taskManagers = res;
  
            if (this.data.company.permissions.includes("owner") || this.data.company.permissions.includes("admin") || this.data.company.permissions.includes("manager")) {
              this.job.isManager = true;
            } else {
              if (this.job.taskManagers.filter(x => x.employee_id == this.data.company.employees[0].id).length > 0) {
                this.job.isManager = true;
              }
            }
  
          }),
          catchError(err => {
            return err
          })
        ).subscribe(resp => {
          console.log("this.membersService.getMembers", resp)
        })
      }
    }

    this.getCurrencyList();
    this.refresh();
    console.log("test 0", this.data.job)
    console.log("test 1", !!this.data.job.task_operation_id || !!this.data.job.taskOperation)
    if (!!this.data.job.task_operation_id || !!this.data.job.taskOperation) {
      console.log("test 2", this.data.task_operation_id)
      this.updateJob(this.data.task_operation_id);
    }
  }

  getCurrencyList() {
    this.attachSubscriptions(
      this.globalDataService.getCurrencies().subscribe(resp => {
        this.currencyData = resp.slice()
        console.log("getCurrencyList", this.currencyData)
        this.currencyData.unshift({
          created_at: 0,
          display_format: "{value}",
          id: 8,
          iso_name: "",
          name: "",
          rate: "0.0000",
          short_name: "",
          updated_at: 0
        })
        this.currencyData.push({
          created_at: 0,
          display_format: "PTS {value}",
          id: 0,
          iso_name: "PTS",
          name: "Points",
          rate: "0.0000",
          short_name: "pts",
          updated_at: 0
        })
        this.currencyData.map(z => z.summary = 0);
      })
    )
  }

  neededData(job, partnerEmpl?) {
    let arr = [

    ]

    if (job.task_id) {
      arr.push(
        this.taskService.getTaskClientsDyn("1", job.task_id, this.data.company.id, '200', {task_operation_id: `0,${job.id}`}).pipe(
          map(x => x.body),
          tap(res => {
            job.clients = res
          })
        )
      )
    }

    if (job.company_id != this.data.company.id && partnerEmpl) {
      arr.push(
        this.membersService.getTaskPartnerEmployees({task_id: job.task_id, task_operation_id: job.id, discussion_id: 0}, this.data.company.id, job.company_id).pipe(
          tap(empls => {
            empls.map(b => {
              b.is_partner_employee = true;
              b.employeeStatus = b.partnerEmployeeStatus;
              b.employee = b.partnerEmployee;

              if (b.is_manager == 1) {
                job.mainEmployees.push(b)
              } else {
                job.addEmployee.push(b)
              }
              job.employees.push(b)


              if (!job.hasOwnProperty('emplSummary')) {
                job.emplSummary = JSON.parse(JSON.stringify(this.currencyData));
              }
              
              // job.employees.forEach(y => {
              // })
              job.emplSummary[!!b.price_currency_id ? b.price_currency_id : 0].summary = Number(job.emplSummary[!!b.price_currency_id ? b.price_currency_id : 0].summary) + Number(b.price);
              job.emplSummary[this.currencyData.length - 1].summary = Number(job.emplSummary[this.currencyData.length - 1].summary) + Number(b.rate);
              job.emplSummCount = job.emplSummary.filter(t => t.summary > 0).length
            })
          })
        )
      )
    }

    if (this.data.company && job.task_id) {
      arr.push(
        this.membersService.getMembers({task_id: job.task_id, is_manager: 1, task_operation_id: 0}, this.data.company.id).pipe(
          tap(res => {
            job.taskManagers = res;

            if (this.data.company.permissions.includes("owner") || this.data.company.permissions.includes("admin") || this.data.company.permissions.includes("manager")) {
              job.isManager = true;
            } else {
              if (job.taskManagers.filter(x => x.employee_id == this.data.company.employees[0].id).length > 0) {
                job.isManager = true;
              }
            }

          }),
          catchError(err => {
            return err
          })
        )
      )
    }

    return forkJoin(arr)
  }

  refresh() {
    this.attachSubscriptions(
      this.refreshService.getRefreshJobs().pipe(
        concatMap(res => {

          if ((this.job.id == res.job_id || this.job.task_operation_id == res.job_id) && this.data.company.id == res.company_id ) {
            return this.taskService.getWorks(1, this.data.company.id, {id: [res.job_id]}, '10').pipe(
              map(x => x.body[0]),
              map(val => jobsModel([val], this.currencyData.slice())),
              map(res => res[0]),
              switchMap(val => this.neededData(val, true).pipe(map(() => val))),
              tap(val => {
                console.log("refresh JOB", val);
                this.job = val
              })
            )
          } else {
            return of(false)
          }    
        })
      ).subscribe(resp => {
        console.log("getRefreshJobs", resp)
      })
    )
  }

  updateJob(id) {
    this.refreshService.refreshJob$.next({company_id: this.data.company.id, job_id: id})
  }

  openEmplStatus(job) {
    console.log("openEmplStatus", job)
    // if (job.employees.filter(x => (x.employee_id == this.data.company.employees[0].id || x.partner_company_id == this.data.company.id) && !x.discussion_id).length == 0) {
    //   return
    // }
    if (job.employees.filter(x => ((x.employee_id == this.data.company.employees[0].id) || (x.partner_employee_id == this.data.company.employees[0].id) || (x.partner_company_id == this.data.company.id && !x.partner_employee_id)) && !x.discussion_id).length == 0) {
      return
    }
    if (!job.open) {
      job.open = true;
      let jobStatuses = [];
      if (!!job.employeeStatus || !!job.partnerCompanyStatus) {
        this.statuses.forEach(el => {
          if ((job.employeeStatus.status_id != el.id || job.partnerCompanyStatus.status_id != el.id) && el.is_lock == 0) {
            jobStatuses.push(el)
          }
        })
      } else {
        this.statuses.forEach(el => {
          if (job.status_id != el.id && el.is_lock == 0) {
            jobStatuses.push(el)
          }
        })
      }

      job.statuses = jobStatuses;
    } else {
      job.open = false;
      job.statuses = [];
      this.updateJob(job.id);
    }
    console.log(job);
  }

  openStatus(job) {
    console.log("openStatus", job)
    if (!job.open) {
      if (this.isMainEmpl(job) && !(!!job.isManager || this.ifJobClientModerator(job))) {
        if (this.statuses.find(x => x.id == job.status_id).is_lock == 1) {
          return;
        }

        job.open = true;
        let jobStatuses = [];
        // jobStatuses.push(job.status);
        this.statuses.forEach(el => {
          if (el.id != job.status_id && el.is_lock == 0) {
            jobStatuses.push(el)
          }
        })
        job.statuses = jobStatuses;
      } else {
        job.open = true;
        let jobStatuses = [];
        // jobStatuses.push(job.status);
        this.statuses.forEach(el => {
          if (el.id != job.status_id) {
            jobStatuses.push(el)
          }
        })
        job.statuses = jobStatuses;
      }
    } else {
      job.open = false;
      job.statuses = [];
      job.newStatusFor = null;
      this.updateJob(job.id);
    }
    console.log(job);
  }

  showError() {
    this.layoutService.showSnackBar({name: ''}, marker("You do not have permission to do this"), SnackBarItem)
  }

  applyJobStats(job, initialStatus:any = false) {
    this.attachSubscriptions(
      this.taskService.editWork(job.id, {status_id: initialStatus ? initialStatus.id : job.newStatusFor.id}, this.data.company.id).subscribe(resp => {
        this.updateJob(job.id);
      })
    )
  }

  getGroupsCompany() {
    this.attachSubscriptions(
      this.companyService.getInfiniteGroupsCompany(this.data.company.id, '1', '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.companyService.getInfiniteGroupsCompany(this.data.company.id, x).pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.groups = [].concat(...res);
      })
    )
  }

  checkIsManager(job, company) { 
    return job && (company?.permissions.includes('manager') || company?.permissions.includes('admin') || (!!job.task && !!job.task.group_id && !!this.getGroup(job.task.group_id).groupManager)); 
  }

  getGroup(id) {
    if (!this.groups || !this.groups.find(el => el.id == id)) {
      return false;
    }
    return this.groups.find(el => el.id == id)
  }  

  chooseEmplStatus(job, status) {
    console.log("chooseEmplStatus", status)
    let empl = job.employees.find(x => ((x.employee_id == this.data.company.employees[0].id) || (x.partner_employee_id == this.data.company.employees[0].id)) && !x.discussion_id)
    if ((status.id) == 3) {
      empl.selected = true;
      job.empl_status = [empl]
      this.attachSubscriptions(
        this.taskService.getOneTaskExpand(this.data.company.id, job.task_id).pipe(
          map(x => x.body[0]),
          switchMap(x => of(x).pipe(map(() => casesModel([x], [], 'update')),map(x => x.arr[0]))),
        ).subscribe(resp => { 
          const dialogRef = this.dialog.open(ToApproveStepperComponent, {
            panelClass: 'to_appove_dialog',
            data: {
              company: this.data.company,
              user: this.data.user,
              task: resp,
              activeLang: this.data.activeLang,
              job: job,
              status: status,
              statuses: this.statuses,
              imgRoute: this.data.imgRoute,
              is_mobile: this.data.is_mobile,
              host: this.data.host,
              empl_status: this.data.company.permissions.includes('owner') || this.checkIsManager(job, this.data.company),
              operationsValues: this.operationsValues,
              getTask: () => {this.updateJob(job.id)}
            }
          });
          return
        })
      )
    }
    
    if (!!empl?.is_partner) {
      if (!!empl.partnerCompanyStatus?.id) {
        this.attachSubscriptions(
          this.membersService.editPartnerStatus(empl.partnerCompanyStatus.id, {status_id: status.id}, this.data.company.id).subscribe(resp => {
             this.updateJob(job.id);
          },
          error => {
            this.showError()
          })
        )
      } else {
        let val:any = {
          status_id: status.id,
          company_id: this.data.company.id,
          task_id: job.task_id,
          task_operation_id: job.id,
          partner_company_id: empl.partner_company_id,
        }
        if (empl.discussion_id) {
          val.discussion_id = empl.discussion_id
        }
        this.attachSubscriptions(
          this.membersService.addPartnerStatus(val, this.data.company.id).subscribe(resp => {
             this.updateJob(job.id);
          },
          error => {
            this.showError()
          })
        )
      }
    } else if (!!empl.is_partner_employee) {
      if (!!empl.employeeStatus?.id) {
        this.attachSubscriptions(
          this.membersService.editPartnerEmployeeStatus(empl.employeeStatus.id, {status_id: status.id}, this.data.company.id).subscribe(resp => {
             this.updateJob(job.id);
          },
          error => {
            this.showError()
          })
        )
      
      } else {
        let val:any = {
          status_id: status.id,
          company_id: this.data.company.id,
          task_id: job.task_id,
          partner_company_id: empl.partner_company_id,
          partner_employee_id: empl.employee_id,
        }
        if (empl.discussion_id) {
          val.discussion_id = empl.discussion_id
        }
        this.attachSubscriptions(
          this.membersService.addPartnerEmployeeStatus(val, this.data.company.id).subscribe(resp => {
             this.updateJob(job.id);
          },
          error => {
            this.showError()
          })
        )
      }
    } else {
      if (!!empl.employeeStatus?.id) {
        this.attachSubscriptions(
          this.membersService.editTaskEmployeeStatus(empl.employeeStatus.id, {status_id: status.id}, this.data.company.id).subscribe(resp => {
             this.updateJob(job.id);
          },
          error => {
            this.showError()
          })
        )
      } else {
        let val:any = {
          status_id: status.id,
          company_id: this.data.company.id,
          task_id: job.task_id,
          task_operation_id: job.id,
          employee_id: empl.employee_id,
        }
        if (empl.discussion_id) {
          val.discussion_id = empl.discussion_id
        }
        this.attachSubscriptions(
          this.membersService.addTaskEmployeeStatus(val, this.data.company.id).subscribe(resp => {
             this.updateJob(job.id);
          },
          error => {
            this.showError()
          })
        )
      }
    }

  }

  chooseStatus(job, status) {
    console.log("chooseStatus", status)
    // job.empl_status = job.employees.filter(x => !x.discussion_id);
    job.empl_status = job.employees.filter(x => !x.discussion_id && (this.data.company.id != job.company_id && !(this.data.company.permissions.includes('owner') || this.checkIsManager(job, this.data.company)) ? !x.is_partner && x.employee.company_id == this.data.company.id : true));
    job.empl_status.map(x => {
      if (x?.employeeStatus?.status_id == status.id) {
        x.initial_selected = true;
      }
      if (x?.partnerCompanyStatus?.status_id == status.id) {
        x.initial_selected = true;
      }
    })
    if (this.checkIsManager(job, this.data.company) && [4,98,99].includes(status.id) && status.id > job.status_id) {
      this.attachSubscriptions(
        this.taskService.editWork(job.id, {status_id: status.id}, this.data.company.id).subscribe(resp => {
          this.updateJob(job.id);
        })
      )
    } else {
      if (job.empl_status.length == 1) {
        job.empl_status[0].selected = true;
        if (job.mainEmployees.length == 0) {
          this.attachSubscriptions(
            this.taskService.editWork(job.id, {status_id: status.id}, this.data.company.id).subscribe(resp => {
              this.applyStats(job, status)
            })
          )
        } else {
          this.applyStats(job, status)
        }
        // this.selectEmpl(job, job.empl_status[0], true, status)
      } else if (job.empl_status.length == 0) {
        this.attachSubscriptions(
          this.taskService.editWork(job.id, {status_id: status.id}, this.data.company.id).subscribe(resp => {
            this.updateJob(job.id);
          })
        )
      } else {
        job.newStatusFor = status;
      }

    }
  }

  selectEmpl(job, empl, initial = false, initialStatus:any = false) {
    if (!!empl.initial_selected) {
      return
    }
    if (!!empl.selected) {
      empl.selected = false;
    } else {
      empl.selected = true;
    } 
  }

  showStatusError(error, user?) {
    this.layoutService.showSnackBar({name: user}, `You do not have permission to do this. ${error}`, SnackBarItem)
  }

  applyStats(job, initialStatus:any = false) {
    console.log("applyStats", job, initialStatus)
    if (job.empl_status.filter(x => x.selected).length == 0) {
      return
    }

    if ([3,4,98].includes(job.status_id) && [1,2,5,6].includes(initialStatus ? initialStatus.id : job.newStatusFor.id)) {
      this.attachSubscriptions(
        this.taskService.getOneTaskExpand(this.data.company.id, job.task_id).pipe(
          map(x => x.body[0]),
          switchMap(x => of(x).pipe(map(() => casesModel([x], [], 'update')),map(x => x.arr[0]))),
        ).subscribe(resp => { 
          
          const dialogRef = this.dialog.open(StatusReasonComponent, {
            panelClass: 'open_task_dialog',
            data: {
              company: this.data.company,
              user: this.data.user,
              task: resp,
              activeLang: this.data.activeLang,
              job: job,
              status: initialStatus ? initialStatus : job.newStatusFor,
              statuses: this.statuses,
              groups: this.groups,
              imgRoute: this.data.imgRoute,
              is_mobile: this.data.is_mobile,
              host: this.data.host,
              empl_status: this.data.company.permissions.includes('owner') || this.checkIsManager(job, this.data.company),
              operationsValues: this.operationsValues,
            }
          });
      
          dialogRef.afterClosed().subscribe(result => {
            if (result.change) {
              forkJoin(job.empl_status.filter(x => x.selected).map(empl => {
                if (!!empl.is_partner) {
                  if (!!empl.partnerCompanyStatus?.id) {
                    return this.membersService.editPartnerStatus(empl.partnerCompanyStatus.id, {status_id: initialStatus ? initialStatus.id : job.newStatusFor.id}, this.data.company.id).pipe(
                      catchError(error => {
                        this.showStatusError(error, empl?.partnerCompany?.name)
                        return of(false)
                      })
                    )
                  } else {
                    return this.membersService.addPartnerStatus({
                      status_id: initialStatus ? initialStatus.id : job.newStatusFor.id,
                      company_id: this.data.company.id,
                      task_id: job.task_id,
                      task_operation_id: job.id,
                      partner_company_id: empl.partner_company_id,
                    }, this.data.company.id).pipe(
                      catchError(error => {
                        this.showStatusError(error, empl?.partnerCompany?.name)
                        return of(false)
                      })
                    )
                  }
                } else if (!!empl.is_partner_employee) {
                  if (!!empl.employeeStatus?.id) {
                    return this.membersService.editPartnerEmployeeStatus(empl.employeeStatus.id, {status_id: initialStatus ? initialStatus.id : job.newStatusFor.id}, this.data.company.id).pipe(
                      catchError(error => {
                        this.showStatusError(error, empl?.employee?.display_name)
                        return of(false)
                      })
                    )
                  } else {
                    return this.membersService.addPartnerEmployeeStatus({
                      status_id: initialStatus ? initialStatus.id : job.newStatusFor.id,
                      company_id: this.data.company.id,
                      task_id: job.task_id,
                      task_operation_id: job.id,
                      partner_company_id: empl.partner_company_id,
                      partner_employee_id: empl.partner_employee_id
                    }, this.data.company.id).pipe(
                      catchError(error => {
                        this.showStatusError(error, empl?.employee?.display_name)
                        return of(false)
                      })
                    )
                  }
                } else {
                  if (!!empl.employeeStatus?.id) {
                    return this.membersService.editTaskEmployeeStatus(empl.employeeStatus.id, {status_id: initialStatus ? initialStatus.id : job.newStatusFor.id}, this.data.company.id).pipe(
                      catchError(error => {
                        this.showStatusError(error, empl?.employee?.display_name)
                        return of(false)
                      })
                    )
                  } else {
                    return this.membersService.addTaskEmployeeStatus({
                      status_id: initialStatus ? initialStatus.id : job.newStatusFor.id,
                      company_id: this.data.company.id,
                      task_id: job.task_id,
                      task_operation_id: job.id,
                      employee_id: empl.employee_id,
                    }, this.data.company.id).pipe(
                      catchError(error => {
                        this.showStatusError(error, empl?.employee?.display_name)
                        return of(false)
                      })
                    )
                  }
                }
              })).subscribe(resp => {
                this.updateJob(job.id);
              })
            } else {
              this.updateJob(job.id);
            }
          })
        })
      )
    } else if ((initialStatus ? initialStatus.id : job.newStatusFor.id) == 3) {
      
      this.attachSubscriptions(
        this.taskService.getOneTaskExpand(this.data.company.id, job.task_id).pipe(
          map(x => x.body[0]),
          switchMap(x => of(x).pipe(map(() => casesModel([x], [], 'update')),map(x => x.arr[0]))),
        ).subscribe(resp => {  
          const dialogRef = this.dialog.open(ToApproveStepperComponent, {
            panelClass: 'to_appove_dialog',
            data: {
              company: this.data.company,
              user: this.data.user,
              task: resp,
              activeLang: this.data.activeLang,
              job: job,
              status: initialStatus ? initialStatus : job.newStatusFor,
              statuses: this.statuses,
              imgRoute: this.data.imgRoute,
              is_mobile: this.data.is_mobile,
              host: this.data.host,
              empl_status: this.data.company.permissions.includes('owner') || this.checkIsManager(job, this.data.company),
              operationsValues: this.operationsValues,
              getTask: () => {this.updateJob(job.id);}
            }
          })
        })
      )
    } else {
      forkJoin(job.empl_status.filter(x => x.selected).map(empl => {
        if (!!empl.is_partner) {
          if (!!empl.partnerCompanyStatus?.id) {
            return this.membersService.editPartnerStatus(empl.partnerCompanyStatus.id, {status_id: initialStatus ? initialStatus.id : job.newStatusFor.id}, this.data.company.id).pipe(
              catchError(error => {
                this.showStatusError(error, empl?.partnerCompany?.name)
                return of(false)
              })
            )
          } else {
            return this.membersService.addPartnerStatus({
              status_id: initialStatus ? initialStatus.id : job.newStatusFor.id,
              company_id: this.data.company.id,
              task_id: job.task_id,
              task_operation_id: job.id,
              partner_company_id: empl.partner_company_id,
            }, this.data.company.id).pipe(
              catchError(error => {
                this.showStatusError(error, empl?.partnerCompany?.name)
                return of(false)
              })
            )
          }
        } else if (!!empl.is_partner_employee) {
          if (!!empl.employeeStatus?.id) {
            return this.membersService.editPartnerEmployeeStatus(empl.employeeStatus.id, {status_id: initialStatus ? initialStatus.id : job.newStatusFor.id}, this.data.company.id).pipe(
              catchError(error => {
                this.showStatusError(error, empl?.employee?.display_name)
                return of(false)
              })
            )
          } else {
            return this.membersService.addPartnerEmployeeStatus({
              status_id: initialStatus ? initialStatus.id : job.newStatusFor.id,
              company_id: this.data.company.id,
              task_id: job.task_id,
              task_operation_id: job.id,
              partner_company_id: empl.partner_company_id,
              partner_employee_id: empl.partner_employee_id
            }, this.data.company.id).pipe(
              catchError(error => {
                this.showStatusError(error, empl?.employee?.display_name)
                return of(false)
              })
            )
          }
        } else {
          if (!!empl.employeeStatus?.id) {
            return this.membersService.editTaskEmployeeStatus(empl.employeeStatus.id, {status_id: initialStatus ? initialStatus.id : job.newStatusFor.id}, this.data.company.id).pipe(
              catchError(error => {
                this.showStatusError(error, empl?.employee?.display_name)
                return of(false)
              })
            )
          } else {
            return this.membersService.addTaskEmployeeStatus({
              status_id: initialStatus ? initialStatus.id : job.newStatusFor.id,
              company_id: this.data.company.id,
              task_id: job.task_id,
              task_operation_id: job.id,
              employee_id: empl.employee_id,
            }, this.data.company.id).pipe(
              catchError(error => {
                this.showStatusError(error, empl?.employee?.display_name)
                return of(false)
              })
            )
          }
        }
      })).subscribe(resp => {
        this.updateJob(job.id);
      })
    }
  }


  ifJobClientModerator(job) {
    if (!job.clients || job.clients.length == 0 || !this.data.company) {
      return false;
    }
    // console.log("ifJobClient", job.clients.filter(x => (x.client_employee_id == this.data.company.employees[0].id || x.client_partner_company_id == this.data.company.id) && x.is_principal).length)
    if (job.clients.filter(x => (x.client_employee_id == this.data.company.employees[0].id || x.client_partner_company_id == this.data.company.id) && !!x.is_principal).length > 0) {
      return true;
    } else {
      return false;
    }
  }

  isMainEmpl(job) {
    if (job && this.data.company && job?.employees && (job?.employees.filter(x => !x.discussion_id && this.data.company && this.data.company.employees[0] && (x.employee_id == this.data.company.employees[0].id || x.partner_employee_id == this.data.company.employees[0].id) && x.is_manager == 1).length > 0 || (job.company_id != this.data.company.id && this.data.company.permissions.includes("owner")))) {
      return true;
    } else {
      return false;
    }
  }

  log() {
    console.log("this.liteChatFilter.value", this.liteChatFilter.value)
  }

  checkFmFilterCount() {

    let count = 0;
    if (this.selectedIndex.value == 3) {
      Object.keys(this.liteFmFilter.value).forEach(key => {
        if (this.savedLiteFmFilter[key].toString() !== this.liteFmFilter.value[key].toString()) {
          count++
        }
      })
    } else if (this.selectedIndex.value == 0) {
      Object.keys(this.liteChatFilter.value).forEach(key => {
        if (this.savedLiteChatFilter[key].toString() !== this.liteChatFilter.value[key].toString()) {
          count++
        }
      })
    } else if (this.selectedIndex.value == 2) {
      Object.keys(this.liteBoardFilter.value).forEach(key => {
        if (this.savedLiteBoardFilter[key].toString() !== this.liteBoardFilter.value[key].toString()) {
          count++
        }
      })
    }


    return count
  }

  onSearchValues(resp) {
    if (!this.allValues) {
      return;
    }

    if (!resp) {
      this.allValues$.next(this.allValues.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.allValues$.next(
      this.allValues.filter(z => z.value.toLowerCase().indexOf(resp) > -1 || (z.parameter.name && z.parameter.name.toLowerCase().indexOf(resp) > -1))
    );
  }

  onSearchDevices(resp) {
    if (!this.devices) {
      return;
    }

    if (!resp) {
      this.devices$.next(this.devices.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.devices$.next(
      this.devices.filter(z => z.toLowerCase().indexOf(resp) > -1)
    );
  }

  getOperationsStatus() {
    this.attachSubscriptions(
      this.taskService.getOperationsStatus().subscribe(resp => {
        this.statuses = resp.slice();
      })
    )
  }

  getStatus(id) {
    if (!this.statuses) {
      return false;
    }
    return this.statuses.find(el => el.id == id)
  }  

  copyLink(type) {
    this.layoutService.showSnackBar({name: type + " link"}, marker("Copied"), SnackBarItem)
  }

  copyInfo(type) {
    this.layoutService.showSnackBar({name: type}, "Copied", SnackBarItem)
  }

  getPrimaryTag(job) {
    if (job.parameterValuesToTask && job.parameterValuesToTask.length) {
      return job.parameterValuesToTask.find(x => !!x.is_primary)
    } else {
      return undefined
    }
  }

  getOperations() {
    this.attachSubscriptions(
      this.taskService.getOperations(this.data.company.id, this.data.activeLang).subscribe(resp => {
        this.operationsValues = resp;
      })
    )
  }

  getColumnName(id) {
    if (!this.operationsValues || this.operationsValues.filter(el => el.id == id).length == 0) {
      return '';
    }
    return this.operationsValues.find(el => el.id == id) && this.operationsValues.find(el => el.id == id).translation ? this.operationsValues.find(el => el.id == id).translation.name : this.operationsValues.find(el => el.id == id).name
  }

  onSearchChecklists(resp) {
    if (!this.checklists) {
      return;
    }

    if (!resp) {
      this.checklists$.next(this.checklists.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    // filter the banks
    this.checklists$.next(
      this.checklists.filter(b => b.text.toLowerCase().indexOf(resp) > -1)
    );
  }

  getValuesById(id) {
    return this.allValues && this.allValues.find(x => x.id == id)
  }

  getAllApiParameterValues() {
    this.attachSubscriptions(
      this.parametersService.getAllValues('1', this.data.company.id, null, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.parametersService.getAllValues(x, this.data.company.id).pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.allValues = [].concat(...res);
        this.allValues$.next(this.allValues.slice())
      })
    )
  }

  getFileDevices() {
    this.attachSubscriptions(
      this.fileService.getFileDevices(this.data.company.id).subscribe(resp => {
        console.log("getFileDevices", resp)
        this.devices = resp;
        this.devices$.next(this.devices.slice())
      })
    )
  }
  
  getChecklists() {
    this.attachSubscriptions(
      this.taskService.getOperationReminder(this.data.company.id).pipe(
        map(res => res.body),
        tap(arr => {
          arr.map(x => x.name = x.text)
        }),
      ).subscribe(resp => {
        this.checklists = resp;
        this.checklists$.next(this.checklists.slice());

        console.log("getChecklists", this.checklists)
      })
    )
  }

  resetChatFilter() {
    this.liteChatFilter.patchValue({
      q: '',
      q_name: '',
      q_text: '',
      discussion_parameter_value_id: [[]],
      data_file_id: '',
      discussion_created_at_from: "",
      discussion_created_at_to: "",
      posted_at_from : '',
      posted_at_to : '',
      discussion_planned_completed_at_from : '',
      discussion_planned_completed_at_to : '',
      discussion_completed_at_from : '',
      discussion_completed_at_to : '',
    })
  }

  resetFmFilter() {
    this.liteFmFilter.patchValue({
      count: '',
      q: '',
      is_dir: '',
      content_type_id: '',
      operation_reminder_id: '',
      meta_device: '',
      related_count: '',
      part_of_count: '',
      consist_of_count: '',
      is_ready_if_approved: '',
      task_parameter_value_id: [[]],
      task_operation_parameter_value_id : [[]],
      discussion_parameter_value_id: [[]],
      file_parameter_value_id : [[]],
      parameter_value_id: [[]],
      operation_employee_id: [[]],
      operation_partner_company_id: [[]],
      origin_company_id : '',
      channel_platform_id : '',
      group_by : 'original_file_id',
      channel_id: [[]],
      no_channel_id: [[]],
      task_custom_id: '',
      file_id: '',
      order_by: 'updated_desc',
      content_status_id: '',
      id: '',
      internal_id: "",
      file_created_at_from: "",
      file_created_at_to: "",
      file_updated_at_from: "",
      file_updated_at_to: "",
      file_meta_creation_time_from: "",
      file_meta_creation_time_to: "",
      location_type: "location",
    });
  }

  openProjects() {
    window.open(this.origin + `/projects?company_id=${this.data.company.id}&task_id=${this.data.job.targetIds.task_id}&task_operation_id=${this.data.job.targetIds.task_operation_id}`, '_blank');
  }

  close() {
    this.dialogRef.close()
  }

  toggleFilter() {
    this.is_filter_active = !this.is_filter_active;
  }

  changeTab(val) {
    this.is_filter_active = false;
    this.selectedIndex.patchValue(val);
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
