import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import * as moment from 'moment';
import { concat, forkJoin, fromEvent, of, ReplaySubject, Subscription } from 'rxjs';
import { catchError, concatMap, last, map, switchMap, tap } from 'rxjs/operators';
import { prioritys } from 'src/app/shared/consts/prioritys';
import { BaseClass } from 'src/app/shared/models/base-class';
import { GlobalDataService } from 'src/app/shared/services/common/global-data.service';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { FileService } from 'src/app/shared/services/rest/file.service';
import { MembersService } from 'src/app/shared/services/rest/members.service';
import { ParametersService } from 'src/app/shared/services/rest/parameters.service';
import { RefreshService } from 'src/app/shared/services/rest/refresh.service';
import { ScenariosService } from 'src/app/shared/services/rest/scenarios.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { environment } from 'src/environments/environment';
import { AddEditViewVariableComponent } from './diaglos/add-edit-view-variable/add-edit-view-variable.component';
import { AddEditViewColumnComponent } from './diaglos/add-edit-view-column/add-edit-view-column.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { OpenTaskComponent } from '../cases/dialogs/open-task/open-task.component';
import { ChatService } from 'src/app/shared/services/rest/chat.service';
import { OpenJobComponent } from '../cases/dialogs/open-job/open-job.component';
import { casesModel } from 'src/app/shared/functions/casesModel';
import { DeleteAlertComponent } from 'src/app/shared/global_components/delete-alert/delete-alert.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { VideoEditorV5Component } from 'src/app/shared/global_components/file-manager/dialog/video-editor-v5/video-editor-v5.component';
import { AlphaVideoEditorComponent } from 'src/app/shared/global_components/file-manager/dialog/alpha-video-editor/alpha-video-editor.component';
import { MainVideoEditorComponent } from 'src/app/shared/global_components/file-manager/dialog/main-video-editor/main-video-editor.component';
import { VideoEditorTestComponent } from 'src/app/shared/global_components/file-manager/dialog/video-editor-test/video-editor-test.component';
import { BetaVideoEditorComponent } from 'src/app/shared/global_components/file-manager/dialog/beta-video-editor/beta-video-editor.component';
import { LiteJobComponent } from '../cases/dialogs/lite-job/lite-job.component';
import { ProjectsServersTableComponent } from 'src/app/shared/global_components/file-manager/dialog/main-video-editor/projects-servers-table/projects-servers-table.component';
import { VideoFfmpegEditorComponent } from 'src/app/shared/global_components/file-manager/dialog/video-ffmpeg-editor/video-ffmpeg-editor.component';
import { SelectJobComponent } from 'src/app/shared/global_components/select-job/select-job.component';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'app-projects-page',
  templateUrl: './projects-page.component.html',
  styleUrls: ['./projects-page.component.scss']
})
export class ProjectsPageComponent extends BaseClass implements OnInit, OnDestroy {
  public company_id: any;
  public partners: any;
  public allValues: any;
  public valuesPage: number = 1;
  public valuesPagination: any;
  public pagination: any;
  public company: any;
  public me: any;
  public statuses: any;
  public activeLang: any;
  public groups: any;
  public taskStatuses: any;
  public platforms: any;
  public host: any = environment.host;

  public keys: any = ["is_draft", "is_to_process", "is_processing", "is_reset", "is_error", "is_ready"];

  public groups_partner_id: any;
  public partnerGroups: any;
  throttle = 100;
  scrollUpDistance = 3.5;
  public imgRoute: any = '';
  public profileStatuses: any;
  public allContentTypes: any;
  public profiles: any;
  public contentUrl = [
    {
      name: marker("Without content url"),
      id: 0
    },
    {
      name: marker("With content url"),
      id: 1
    }
  ]
  public employees: any;
  public operationsValues: any;
  public checklists: any;
  public bookmarks: any;
  public user: any;
  public prioritys = prioritys;
  public isSettingsOpen: boolean = false;

  public viewArch = {
    columns: []
  }

  public variables = [
    {
      key: 'id',
      name: 'Project ID',
      is_root: 0,
      fontSize: 14,
      color: "#000"
    },
    {
      key: 'editor_version',
      name: 'VE version',
      is_root: 0,
      fontSize: 8,
      color: "#BCD7FD"
    },
    {
      key: 'task_id',
      name: 'Card ID',
      is_root: 0,
      fontSize: 12,
      color: "#000"
    },
    {
      key: 'task_operation_id',
      name: 'Job ID',
      is_root: 0,
      fontSize: 12,
      color: "#000"
    },
    {
      key: 'task_operation_name',
      name: 'Job Name',
      is_root: 0,
      fontSize: 12,
      color: "#000"
    },
    {
      key: 'task_operation_type',
      name: 'Job Type',
      is_root: 0,
      fontSize: 12,
      color: "#000"
    },
    {
      key: 'task_operation_status',
      name: 'Job Status',
      is_root: 0,
    },
    {
      key: 'name',
      name: 'Project Name',
      is_root: 0,
      fontSize: 12,
      color: "#000"
    },
    {
      key: 'task.group.name',
      name: 'Group Name',
      is_root: 0,
      fontSize: 12,
      color: "#1877F2"
    },
    {
      key: 'server_and_region',
      name: 'Server and Region',
      is_root: 1,
      fontSize: 12,
      color: "#1877F2"
    },
    {
      key: 'templateIds',
      name: 'Template Name',
      is_root: 0,
      fontSize: 12,
      color: "#000"
    },
    {
      key: 'employees',
      name: 'Employees',
      is_root: 0,
    },
    {
      key: 'created_at',
      name: 'Created At',
      is_root: 0,
      fontSize: 12,
      color: "#000"
    },
    {
      key: 'createdEmployee',
      name: 'Created Employee',
      is_root: 0,
    },
    {
      key: 'stage',
      name: 'Stage',
      is_root: 0,
    },
    {
      key: 'total_price_unit',
      name: 'Units',
      is_root: 0,
    },
    {
      key: 'processing_queue_at',
      name: 'Queue time',
      is_root: 0,
      fontSize: 12,
      color: "#000"
    },
    {
      key: 'processing_started_at',
      name: 'Processing start time',
      is_root: 0,
      fontSize: 12,
      color: "#000"
    },
    {
      key: 'processing_completed_at',
      name: 'Processing end time',
      is_root: 0,
      fontSize: 12,
      color: "#000"
    },
    {
      key: 'processing_time',
      name: 'How many seconds did it take',
      is_root: 0,
      fontSize: 12,
      color: "#000"
    },
    {
      key: 'processing_download_time',
      name: 'Preparing',
      is_root: 0,
      fontSize: 12,
      color: "#000"
    }
  ]

  public columns = [
    {
      id: 1,
      name: "ID",
      variables: []
    },
    {
      id: 2,
      name: "Card & Job ID",
      variables: []
    },
    {
      id: 3,
      name: "Project Name",
      variables: []
    },
    {
      id: 4,
      name: "Template",
      variables: []
    },
    {
      id: 5,
      name: "Employees",
      variables: []
    },
    {
      id: 6,
      name: "Created At",
      variables: []
    },
    {
      id: 7,
      name: "Stage",
      variables: []
    },
    {
      id: 8,
      name: "Units",
      variables: []
    },
    {
      id: 9,
      name: "Queue time",
      variables: []
    },
    {
      id: 10,
      name: "Processing start time",
      variables: []
    },
    {
      id: 11,
      name: "Processing end time",
      variables: []
    },
    {
      id: 12,
      name: "How many seconds did it take",
      variables: []
    },
    {
      id: 13,
      name: "Preparing",
      variables: []
    }
  ]

  public isLoad: boolean = false;
  public is_mobile: boolean = false;
  public filterCount: number = 1;
  public bookmarksControl: FormControl = new FormControl();
  public bookmarks$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public filter: FormGroup = this.fb.group({
    order_by: 'id_desc',
    count: '',
    q: '',
    task_id: '',
    preset_id: '',
    group_id: [[]],
    task_operation_id: '',
    task_operation_operation_id: '',
    discussion_id: "",
    id: "",
    is_draft: 0,
    is_to_process: 0,
    is_processing: 0,
    is_reset: 0,
    is_error: 0,
    is_ready: 0
  });

  public origin = window.location.origin;
  public taskTemplates: any;
  public projects: any;
  public projectsPage: number = 1;
  public projectsDataSub: Subscription;
  public projectsPagination: any;

  public showAddOptions: FormControl = new FormControl(false);
  
  constructor(
    public activatedRoute: ActivatedRoute,
    public fb: FormBuilder,
    public languageService: LanguageService,
    public scenariosService: ScenariosService,
    public membersService: MembersService,
    public layoutService: LayoutService,
    public chatService: ChatService,
    public router: Router,
    public auth: AuthService,
    public bottomSheet: MatBottomSheet,
    public fileService: FileService,
    public taskService: TaskService,
    public globalDataService: GlobalDataService,
    private dialog: MatDialog,
    private sm: StorageManagerService,
    public parametersService: ParametersService,
    public refreshService: RefreshService,
    public companyService: CompanyService,
  ) { super() }

  ngOnInit(): void {
    this.company_id = this.activatedRoute.snapshot.queryParamMap.get('company_id');
    if (window.innerWidth <= 769) {
      this.is_mobile = true;
    } else {
      this.is_mobile = false;
    }
    if (!!this.sm.localStorageGetItem('showAddOptions')) {
      this.showAddOptions.patchValue(true)
    }
    // if (!!this.sm.localStorageGetItem('projectsView')) {
    //   this.viewArch = JSON.parse(this.sm.localStorageGetItem('projectsView'));
    // } else {
    //   // this.viewArch.columns.push(...JSON.parse(JSON.stringify(this.columns)))
    //   this.toDefault();
    // }
    // this.sm.localStorageSetItem('projectsView', JSON.stringify(this.viewArch));

    this.attachSubscriptions(
      this.showAddOptions.valueChanges.subscribe(res => {
        if (!!res) {
          this.sm.localStorageSetItem('showAddOptions', true)
        } else {
          localStorage.removeItem('showAddOptions')
        }
      })
    )

    this.getImgRoute();
    this.onResize();
    this.getUser();
    this.getTaskTemplates();
    this.getLangData();
    this.attachSubscriptions(
      this.refreshService.getRefreshBookmarks().subscribe(res => {
        if (res == "Projects") {
          this.getBookmarks(true)
        }
      })
    )

    this.attachSubscriptions(
      this.fileService.getProjectsRefreshData().subscribe(res => {
        if (!!res) {
          this.projectsPage = 1;
          this.projects = [];
          this.getProjects(this.projectsPage);
        }
      })
    )

    this.groups_partner_id = this.activatedRoute.snapshot.queryParamMap.get('groups_partner_id');
    if (!!this.groups_partner_id) {
      this.getPartnerGroups()
    }

    this.getPartners();
    this.getAllValues();
    this.getGroupsCompany();
    this.getProfiles();
    this.getTaskStatus();
    this.getChecklists();
    this.getProfilesStatus();
    this.getEmployees();
    this.getPlatforms();
    this.getOperationsStatus();
    this.getContentTypes();
    this.onRouteChange();
  }

  toDefault() {
    this.viewArch.columns = [
      {
        id: 1,
        name: "ID",
        variables: [
          {
            key: 'id',
            name: 'Project ID',
            is_root: 0,
            fontSize: 14,
            color: "#000"
          },
          {
            key: 'editor_version',
            name: 'VE version',
            is_root: 0,
            fontSize: 8,
            color: "#BCD7FD"
          },
  
        ]
      },
      {
        id: 3,
        name: "Project Name",
        variables: [
          {
            key: 'name',
            name: 'Project Name',
            is_root: 0,
            fontSize: 12,
            color: "#000"
          }
        ]
      },
      {
        id: 7,
        name: "Stage",
        variables: [
          {
            key: 'stage',
            name: 'Stage',
            is_root: 0,
          }
        ]
      },
    ];
    if (this.user && this.user.is_root && this.viewArch.columns.find(x => x.id == 3)) {
      this.viewArch.columns.find(x => x.id == 3).variables.push({
        key: 'server_and_region',
        name: 'Server and Region',
        is_root: 1,
        fontSize: 12,
        color: "#1877F2"
      })
    }
    this.sm.localStorageSetItem('projectsView', JSON.stringify(this.viewArch));
    this.sm.localStorageSetItem('projects_preset_id', -1);
  }

  getTaskTemplates() {
    this.attachSubscriptions(
      this.scenariosService.getTaskTemplates('1', {company_id: this.company_id}, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.scenariosService.getTaskTemplates(x, {company_id: this.company_id}, '200').pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              this.taskTemplates = [].concat(...values)
              this.taskTemplates.unshift({
                id: '',
                name: "Not set"
              })
              console.log("taskTemplates", this.taskTemplates)
            }),
          )
        }),
      ).subscribe(resp => {
        console.log("getTaskTemplates sub", resp);
      })
    )
  }

  getTemplateById(id) {
    if (!this.taskTemplates || this.taskTemplates.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.taskTemplates.find(el => el.id == id)
  }

  onPartnerImgError(event){
    event.target.src = this.imgRoute+'/assets/img/partner.png'
  }

  setPagination($event) {
    this.pagination = $event
  }

  refreshProjects() {
    this.fileService.projectsRefresh$.next(true)
  }


  getUser() {
    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        this.user = resp;
      })
    )
  }

  getPriority(id) {
    if (!this.prioritys) {
      return false;
    }
    return this.prioritys.find(el => el.id == id)
  } 

  getStatus(id) {
    if (!this.statuses) {
      return false;
    }
    return this.statuses.find(el => el.id == id)
  }  

  getOperationsStatus() {
    this.attachSubscriptions(
      this.taskService.getOperationsStatus().subscribe(resp => {
        this.statuses = resp.slice();
      })
    )
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  changeActiveStatus(key) {
    if (key == "all") {
      this.filter.patchValue({
        is_draft: 0,
        task_operation_operation_id: '',
        is_to_process: 0,
        is_processing: 0,
        is_reset: 0,
        is_error: 0,
        is_ready: 0
      })
    } else {
      let x:any = {};
      this.keys.forEach(el => {
        if (el == key) {
          x[el] = 1
        } else {
          x[el] = 0
        }
      });

      this.filter.patchValue(x)
    }

    this.filterProjects()
  }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().pipe(
        tap(resp => {
          this.activeLang = resp.active;
        }),
        switchMap(() => this.taskService.getOperations(this.company_id, this.activeLang).pipe(
          tap(x => {
            this.operationsValues = x;
          })
        ))
      ).subscribe(resp => {
        console.log("getOperations", resp)
      })
    )
  }

  sort(val) {
    this.filter.patchValue({
      order_by: val
    })
    this.filterProjects()
  }
  
  removeValueFromMultiple(key, value) {
    let arr = this.filter.value[key];

    console.log(key, value);
    console.log(arr);
    
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1)
    }
    console.log(arr);
    console.log(this.filter.value[key]);
    // return 
    // this.filter.patchValue({
    //   [key]: arr
    // })
    this.filterProjects()
  }

  removeValue(val) {
    if (val == "all") {
      let params = {
        company_id: this.company_id
      }
      this.router.navigate(['/projects'], {queryParams: params})
    } else {
      let params = {...this.activatedRoute.queryParamMap.source['_value']}
      delete params[val]

      this.router.navigate(['/projects'], {queryParams: params})
    }
  }

  getPartners() {
    this.attachSubscriptions(
      this.companyService.getPartners({company_id: this.company_id}).subscribe(resp => {
        this.partners = resp.filter(x => x.partner_company_id != 0);
      })
    )
  }

  filterProjects() {
    let params = {
      company_id: this.company_id
    }

    let filterData = {...this.filter.value};

    console.log("filterData", filterData)
    
    Object.keys(filterData).filter(x => x != 'preset_id').forEach(element => {
      if (['is_content_url', 'part_of_count', 'consist_of_count', 'related_count'].includes(element) && filterData[element] == '0') {
        params[element] = filterData[element]
      } else if (["group_id", "channel_id", "no_channel_id", "operation_employee_id", "operation_partner_company_id", 'task_parameter_value_id', 'parameter_value_id', 'file_parameter_value_id'].includes(element) && filterData[element].length > 0) {
        params[element] = filterData[element].join(',')
      } else if (filterData[element] == '0' && filterData[element] == 0 && (element == 'operation_employee_id' || element == 'operation_partner_company_id')) {
        params[element] = filterData[element]
      } else if (filterData[element] != '0' && filterData[element] != '') {
        params[element] = filterData[element]
      }
    });


    // console.log("filterData.operation_completed_at_to", filterData.operation_completed_at_to)
    if (filterData.operation_completed_at_to === 0) {
      params['operation_completed_at_to'] = '0';
    }
    
    console.log("proj params", params)

    this.router.navigate(['/projects'], {queryParams: params})
  }

  getAllValues() {
    this.attachSubscriptions(
      this.parametersService.getPaginationAllApiParameterValues(this.valuesPage, this.company_id).pipe(
        tap(el => {
          if (el.headers.get('x-pagination-current-page') == 1) {
            this.valuesPagination = {
              'pageCount': el.headers.get('x-pagination-page-count'),
              'perPage': el.headers.get('x-pagination-per-page'),
              'totalCount': el.headers.get('x-pagination-total-count'),
              'currentPage': el.headers.get('x-pagination-current-page'),
            }
            this.allValues = el.body
          }
          console.log("getAllApiParameterValues", this.allValues)
        }),
        switchMap(el => {
          let pages = [] 
          for (let index = 2; index <= +this.valuesPagination.pageCount; index++) {
            pages.push(index)
          }
  
          console.log("special pages", pages)
          if (pages.length > 0) {
            return forkJoin(pages.map(x => this.parametersService.getPaginationAllApiParameterValues(x, this.company_id))).pipe(
              tap(val => {
                val.forEach(p => {
                  this.allValues.push(...p.body)
                })
              })
            )
          } else {
            return of(el)
          }
  
        })
      ).subscribe(resp => {
          console.log("files VALUES", this.allValues)
      })
    )
  }

  getValueById(id) {
    if (!this.allValues || this.allValues.length == 0) {
      return null
    }
    return this.allValues.find(x => x.id == id)
  }

  onResize() {
    this.attachSubscriptions(
      fromEvent(window, "resize").pipe(
        map(() => window.innerWidth)
      ).subscribe((wWidth) => {
          if (wWidth <= 769) {
            this.is_mobile = true;
          } else {
            this.is_mobile = false;
          }
        }
      )
    )
  }

  getBookmarkById(id) {
    if (!this.bookmarks || this.bookmarks.length == 0) {
      return null
    }
    return this.bookmarks.find(x => x.id == id)
  }

  canSave() {
    if (this.filter.value.preset_id && this.getBookmarkById(this.filter.value.preset_id) && ((this.getBookmarkById(this.filter.value.preset_id).settings && JSON.stringify(this.getBookmarkById(this.filter.value.preset_id).settings.viewArch) != JSON.stringify(this.viewArch)) || this.getBookmarkById(this.filter.value.preset_id).settings.order_by != this.filter.value.order_by)) {
      return true
    } else {
      return false
    }
  }

  saveBookmark() {
    this.attachSubscriptions(
      this.companyService.editBookmark(this.filter.value.preset_id, {type: 'Layout template', settings: {order_by: this.filter.value.order_by, viewArch: this.viewArch}}).subscribe(resp => {
        console.log("saveBookmark", resp)
        this.getBookmarks(true);
      })
    )
  }
  
  getBookmarks(no_change:boolean = false) {
    this.attachSubscriptions(
      this.companyService.getBookmarks(this.company_id, {employee_id: this.company.employees[0].id, section: "Projects"}).subscribe(res => {
        this.bookmarks = res;
        this.bookmarks.unshift({
          id: -1,
          name: 'Default mode'
        });
        this.bookmarks$.next(this.bookmarks.slice());
        console.log("bookmarks", this.bookmarks)

        if (!no_change) {
          if (!!this.activatedRoute.snapshot.queryParamMap.get('preset_id') && !!this.activatedRoute.snapshot.queryParamMap.get('viewArch')) {
            let url = this.router.url.replace('&viewArch=%5Bobject%20Object%5D', '');
            // console.log("viewArch lal", this.activatedRoute.snapshot.queryParamMap.get('viewArch'))
            // console.log("viewArch lal", this.router.url);
            history.replaceState(null, '', url);
            if (this.getBookmarkById(+this.activatedRoute.snapshot.queryParamMap.get('preset_id'))) {
              let x = JSON.parse(JSON.stringify(this.getBookmarkById(+this.activatedRoute.snapshot.queryParamMap.get('preset_id')).settings));
              if (x.viewArch) {
                this.viewArch = x.viewArch;
                this.sm.localStorageSetItem('projectsView', JSON.stringify(this.viewArch));
              }
              this.sm.localStorageSetItem('projects_preset_id', this.activatedRoute.snapshot.queryParamMap.get('preset_id'));
            } else {
              this.toDefault()
            }
          } else if (!!this.activatedRoute.snapshot.queryParamMap.get('preset_id')) {
            this.sm.localStorageSetItem('projects_preset_id', this.activatedRoute.snapshot.queryParamMap.get('preset_id'));
            if (this.activatedRoute.snapshot.queryParamMap.get('preset_id') == '-1') {
              this.toDefault();
            } else {
              if (this.getBookmarkById(+this.activatedRoute.snapshot.queryParamMap.get('preset_id'))) {
                let x = JSON.parse(JSON.stringify(this.getBookmarkById(+this.activatedRoute.snapshot.queryParamMap.get('preset_id')).settings));
                if (x.viewArch) {
                  this.viewArch = x.viewArch;
                  this.sm.localStorageSetItem('projectsView', JSON.stringify(this.viewArch));
                } else {
                  this.toDefault();
                }
              } else {
                this.toDefault();
              }
            }
          } else if (this.sm.localStorageGetItem('projects_preset_id')) {
            if (this.sm.localStorageGetItem('projects_preset_id') == '-1') {
              this.toDefault();
            } else {
              if (this.getBookmarkById(+this.sm.localStorageGetItem('projects_preset_id'))) {
                let x = JSON.parse(JSON.stringify(this.getBookmarkById(+this.sm.localStorageGetItem('projects_preset_id')).settings));
                if (x.viewArch) {
                  this.viewArch = x.viewArch;
                  this.sm.localStorageSetItem('projectsView', JSON.stringify(this.viewArch));
                } else {
                  this.toDefault();
                }
              } else {
                this.toDefault();
              }
            }
          } else {
            this.toDefault()
          }
        }

      })
    )
  }

  getPartner(id) {
    if (!this.partners) {
      return false;
    }
    return this.partners.find(el => el.partner_company_id == id)
  }  

  onRouteChange() {
    this.attachSubscriptions(
      this.activatedRoute.queryParams.pipe(
        tap((val) => {
          if (this.company_id != this.activatedRoute.snapshot.queryParamMap.get('company_id')) {
            this.getPartners();
            this.getAllValues();
            this.getOperationsStatus();
            this.getGroupsCompany();
            this.getProfiles();
            this.getTaskStatus();
            this.getProfilesStatus();
            this.getEmployees();
            this.getChecklists();
            this.getTaskTemplates();
            this.getPlatforms();
            this.getContentTypes();
            this.getLangData();
          }
          this.company_id = this.activatedRoute.snapshot.queryParamMap.get('company_id');

          

          this.filter.patchValue({
            order_by: this.activatedRoute.snapshot.queryParamMap.get('order_by') ? this.activatedRoute.snapshot.queryParamMap.get('order_by') : 'id_desc',
            count: this.activatedRoute.snapshot.queryParamMap.get('count') ? this.activatedRoute.snapshot.queryParamMap.get('count') : '/',
            task_id: !!this.activatedRoute.snapshot.queryParamMap.get('task_id') ? +this.activatedRoute.snapshot.queryParamMap.get('task_id') : '',
            task_operation_operation_id: !!this.activatedRoute.snapshot.queryParamMap.get('task_operation_operation_id') ? +this.activatedRoute.snapshot.queryParamMap.get('task_operation_operation_id') : '',
            group_id: !!this.activatedRoute.snapshot.queryParamMap.get('group_id') ? this.activatedRoute.snapshot.queryParamMap.get('group_id').split(',').map(Number) : [],
            task_operation_id: !!this.activatedRoute.snapshot.queryParamMap.get('task_operation_id') ? +this.activatedRoute.snapshot.queryParamMap.get('task_operation_id') : '',
            discussion_id: !!this.activatedRoute.snapshot.queryParamMap.get('discussion_id') ? +this.activatedRoute.snapshot.queryParamMap.get('discussion_id') : '',
            id: !!this.activatedRoute.snapshot.queryParamMap.get('id') ? +this.activatedRoute.snapshot.queryParamMap.get('id') : '',
            is_draft: !!this.activatedRoute.snapshot.queryParamMap.get('is_draft') ? +this.activatedRoute.snapshot.queryParamMap.get('is_draft') : 0,
            preset_id: !!this.activatedRoute.snapshot.queryParamMap.get('preset_id') ? +this.activatedRoute.snapshot.queryParamMap.get('preset_id') : 0,
            is_to_process: !!this.activatedRoute.snapshot.queryParamMap.get('is_to_process') ? +this.activatedRoute.snapshot.queryParamMap.get('is_to_process') : 0,
            is_processing: !!this.activatedRoute.snapshot.queryParamMap.get('is_processing') ? +this.activatedRoute.snapshot.queryParamMap.get('is_processing') : 0,
            is_reset: !!this.activatedRoute.snapshot.queryParamMap.get('is_reset') ? +this.activatedRoute.snapshot.queryParamMap.get('is_reset') : 0,
            is_error: !!this.activatedRoute.snapshot.queryParamMap.get('is_error') ? +this.activatedRoute.snapshot.queryParamMap.get('is_error') : 0,
            is_ready: !!this.activatedRoute.snapshot.queryParamMap.get('is_ready') ? +this.activatedRoute.snapshot.queryParamMap.get('is_ready') : 0
          })

          console.log("FILTER VALUE", this.filter.value)
        }),
        switchMap((val) => {
          console.log("switchMap", this.company)
          if (this.company == undefined || this.company.id != this.activatedRoute.snapshot.queryParamMap.get('company_id')) {
            return this.companyService.getCompany(this.activatedRoute.snapshot.queryParamMap.get('company_id')).pipe(
              map(x => x[0]),
              tap(x => {
                this.company = x;
                this.me = this.company.employees[0];
                this.companyService.company$.next(x);
              }),
              map(x => val)
            )
          } else {
            return of(val)
          }
        }),
      ).subscribe(params => {
        console.log("Files route STREAM", params);
        if (!!this.activatedRoute.snapshot.queryParamMap.get('groups_partner_id') && this.groups_partner_id != +this.activatedRoute.snapshot.queryParamMap.get('groups_partner_id')) {
          this.groups_partner_id = +this.activatedRoute.snapshot.queryParamMap.get('groups_partner_id');
          this.getPartnerGroups()
        }
        this.getBookmarks();
        this.projectsPage = 1;
        this.projects = [];
        this.getProjects(this.projectsPage);
      })
    )
  }

  openSettings() {
    this.isSettingsOpen = !this.isSettingsOpen
  }

  toggleProject(project) {
    console.log("project", project)
    project.isOpen = !project.isOpen;
  }
  
  neededProjectsData(proj) {
    let arr = []

    if (true) {
      arr.push(
        this.taskService.getTaskForItem(this.company_id, {id: proj.task_id, expand: 'group'}).pipe(
          map(res => {
            if (!!res.length) {
              return res[0]
            } else {
              return undefined
            }
          }),
          tap(task => {
            proj.task = task;
          })
        )
      )
    }

    if (proj.task_operation_id) {
      arr.push(
        this.taskService.getWorks('1', this.company_id, {id: proj.task_operation_id}, '20').pipe(
          map(res => res.body),
          tap(jobs => {
            proj.taskOperation = undefined;
            if (jobs.length > 0) {
              proj.taskOperation = jobs[0];
            }
          })
        )
      )
    }

    if (!!proj.task_operation_id) {
      let filter = {task_id: proj.task_id, task_operation_id: proj.task_operation_id}

      arr.push(
        this.membersService.getMembers(this.company_id != proj.company_id ? Object.assign({partner_company_id: proj.company_id}, filter) : filter, this.company_id).pipe(
          tap(res => {
            console.log("getMembers", res)
            res.forEach(el => {
              if (!proj.employees) {
                proj.employees = [el]
              } else if (proj.employees.filter(u => u.employee_id == el.employee_id).length == 0) {
                proj.employees.push(el);
              }
            })
          })
        )
      )

      arr.push(
        this.membersService.getTaskPartners(this.company_id != proj.company_id ? Object.assign({partner_company_id: proj.company_id}, filter) : filter, this.company_id).pipe(
          tap(res => {
            console.log("getTaskPartners", res)
            res.forEach(el => {
              el.is_partner = true;
              if (!proj.employees) {
                proj.employees = [el]
              } else if (proj.employees.filter(u => u.partner_company_id == el.partner_company_id).length == 0) {
                proj.employees.push(el);
              }
            })
          })
        )
      )
    }

    arr.push(this.fileService.getVideoProjectRunHistory('1', this.company_id, { video_project_id: proj.id }).pipe(
      map(x => x.body),
      tap(res => {
        // console.log('neededProjectsData res', res)
        proj.runHistory = res;
        let x = 0;
        if (res) {
          res.forEach(element => {
            x = +x + +element.price_unit;
          });
        }
        proj.total_price_unit = x;
      }),
      catchError(err => of(err))
    ))

    arr.push(this.fileService.getVideoProjectTaskFile(this.company_id, {video_project_id: proj.id }).pipe(
      map(x => x.body),
      tap(res => {
        // console.log('neededProjectsData res', res)
        proj.createdCards = res;
        proj.templateIds = []
        res.forEach(card => {
          if (!proj.templateIds.includes(card.task_template_id)) {
            proj.templateIds.push(card.task_template_id)
          }
        })
        // let x = 0;
        // if (res) {
        //   res.forEach(element => {
        //     x = +x + +element.price_unit;
        //   });
        // }
        // proj.total_price_unit = x;
      }),
      catchError(err => of(err))
    ))
    
    return forkJoin(arr)
  }

  openTask(task) {
    const dialogRef = this.dialog.open(OpenTaskComponent, {
      backdropClass: 'backdrop_under_header',
      panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
      autoFocus: false,
      data: {
        task_id: task.id,
        initCompanyId: this.company.id
      }
    });
  }

  openLiteJob(project, index?, loc?) {
    console.log('openLiteJob', this.company)
    project.targetIds = {
      task_id: project.task_id,
      task_operation_id: project.task_operation_id
    }
    const dialogRef = this.dialog.open(LiteJobComponent, {
      backdropClass: !this.is_mobile ? 'backdrop_under_header': ['backdrop_under_header', 'mob_card_backdrop'],
      panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header', 'lite_job_dialog'] : ['open_task_dialog', 'animate__animated', 'animate__slideInUp'],
      autoFocus: false,
      data: {
        task_id: project.task_id,
        task_operation_id: project.task_operation_id,
        user: this.user,
        company: this.company,
        imgRoute: this.imgRoute,
        host: this.host,
        activeLang: this.activeLang,
        is_mobile: this.is_mobile,
        job: project,
        selectedIndex: index || 0,
        loc: loc,
        openJob: (task_id, item, inData) => {
          console.log("from Lite", task_id, item, inData)
          this.openJob(task_id, item, inData?.loc, inData)
        } 
      }
    })

    // this.attachSubscriptions(
    //   dialogRef.afterClosed().subscribe(result => {
    //     this.updateJob(job.id)
    //   })
    // )
  }
  
  editProjStatus(project, key) {
    this.attachSubscriptions(
      this.fileService.editVideoProject(project.id, {[key]: 1, is_to_process: 0}, this.company_id).subscribe(res => {
        console.log('editProjStatus res', res)
        project.is_to_process = res.is_to_process;
        project[key] = res[key];
      }, error => {
        this.layoutService.showSnackBar({name: ''}, marker(error), SnackBarItem)
      })
    )
  }

  neededCardData(task) {
    let arr = [
      this.chatService.getTasksChats(this.company_id, [task.id]).pipe(
        tap(res => {
          task['operations'].map(o_el => {
            o_el['chatsInfo'] = [];
            o_el['chatsInfo'].push(...res.filter(z => z.task_operation_id == o_el.id));
            o_el['unReadChats'] = res.filter(z => z.task_operation_id == o_el.id && z.is_read != '1').length;
            o_el['unClosedChats'] = res.filter(z => z.task_operation_id == o_el.id && z.status_id != '4').length;
          });
          task['chatsInfo'] = [];
          task['chatsInfo'].push(...res.filter(z => z.task_id == task.id));
          task['unReadChats'] = res.filter(z => z.is_read != '1').length;
          task['unClosedChats'] = res.filter(z => z.status_id != '4').length;
        })
      )
    ]
    if (task.company_id == this.company_id) {
      arr.push(
        this.taskService.getTaskClients(task.id, this.company_id).pipe(
          tap(res => {
            task.clients = res
          }),
          catchError(err => {
            return err
          })
        )
      )
    }
    arr.push(...task.operations.filter(z => (z.status_id == 3 && z.is_to_approve_files) || (z.status_id == 98 && z.is_approved_files)).map(x => this.fileService.getFilesForOpenTask(this.company_id, x.id, x.status_id == 3 ? '/to_approve' : '/ready_files').pipe(
      tap(val => {
        x.filesCount = val.headers.get('x-pagination-total-count')
        x.files = val.body
      })
    )))

    return forkJoin(arr)
  }
  
  getArray(obj) {
    return Object.values({...obj});
  }

  openJob(task_id, task_operation_id, loc?, initData?) {
    this.attachSubscriptions(
      this.taskService.getOneTaskExpand(this.company_id, task_id).pipe(
        map(x => x.body[0]),
        switchMap(x => this.neededCardData(x).pipe(map(() => casesModel([x], [], 'update')),map(x => x.arr[0]))),
      ).subscribe(resp => {
        const dialogRef = this.dialog.open(OpenJobComponent, {
          backdropClass: 'backdrop_under_header',
          panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
          autoFocus: false,
          data: {
            task_id: task_id,
            task_operation_id: initData ? task_operation_id.task_operation_id : task_operation_id,
            task: resp,
            initCompanyId: this.company_id,
            loc: loc,
            fast_mode: initData ? true : false,
            selectedIndex: !this.is_mobile && !initData ? 4 : initData?.selectedIndex,
          }
        });
      })
    )
  }
  
  updateProject(project, i) {
    this.attachSubscriptions(
      this.fileService.getVideoProjects('1', this.company_id, {id: project.id}, '100').pipe(
        map(res => res.body),
        switchMap(res => {
          return this.fileService.getVideoEditorHistory('1', this.company_id, {
            company_id: project.company_id,
            video_project_id: res.id,
            is_active: 1
          }, '200').pipe(
            tap(el => {
              res.historyCount = +el.headers.get('x-pagination-total-count')
            }),
            map(() => res)
          )
        }),
      ).subscribe(result => {
        this.projects.splice(i,1,result[0]);
      })
    )
  }

  refreshProject(project, i) {
    this.attachSubscriptions(
      this.fileService.editVideoProject(project.id, {
        is_draft: 0,
        is_error: 0,
        is_processing: 0,
        is_ready: 0,
        is_to_process: 1
      }, this.company_id).pipe(
        switchMap(x => {
          return this.fileService.getVideoProjects('1', this.company_id, {id: project.id, task_id: project.task_id, task_operation_id: project.task_operation_id}, '100').pipe(
            map(res => res.body),
            switchMap(res => {
              return this.fileService.getVideoEditorHistory('1', this.company_id, {
                company_id: project.company_id,
                video_project_id: res.id,
                is_active: 1
              }, '200').pipe(
                tap(el => {
                  res.historyCount = +el.headers.get('x-pagination-total-count')
                }),
                map(() => res)
              )
            }),
            tap(result => {
              this.projects.splice(i,1,result[0]);
            })
          )
        })
      ).subscribe(resp => {
        console.log("END Refresh", resp)
      })
    )
  }

  videoEditorV2(proj, i, openAsNew:boolean = false) {
    this.attachSubscriptions(
      this.taskService.getOneTaskExpand(this.company_id, proj.task_id).pipe(
        map(x => x.body[0]),
        switchMap(x => this.neededData(x, this.company).pipe(map(() => casesModel([x], [], 'update')),map(x => x.arr[0]))),
      ).subscribe(resp => {
        const dialogRef = this.dialog.open(MainVideoEditorComponent, {
          panelClass: ['full_screen_modal', 'video_editor', 'video_editor_black'],
          autoFocus: false,
          data: {
            company_id: this.company_id,
            target_company_id: proj.company_id,
            company: this.company,
            user: this.user,
            imgRoute: this.imgRoute,
            activeLang: this.activeLang,
            host: this.host,
            task: resp,
            work: !!proj.task_operation_id ? resp.operations.find(x => x.id == proj.task_operation_id) : undefined,
            project: proj,
            openAsNew: openAsNew
          }
        });
    
        this.attachSubscriptions(
          dialogRef.afterClosed().subscribe(result => {
            console.log(result)
            if (openAsNew) {
              let index = this.projects.indexOf(proj)
              this.updateProject(proj, index);
            } else {
              this.refreshProjects();
            }
          })
        )
      })
    )
  }
  
  videoEditorV4(proj, i, openAsNew:boolean = false) {
    this.attachSubscriptions(
      this.taskService.getOneTaskExpand(this.company_id, proj.task_id).pipe(
        map(x => x.body[0]),
        switchMap(x => this.neededData(x, this.company).pipe(map(() => casesModel([x], [], 'update')),map(x => x.arr[0]))),
      ).subscribe(resp => {
        const dialogRef = this.dialog.open(AlphaVideoEditorComponent, {
          panelClass: ['full_screen_modal', 'video_editor', 'video_editor_black'],
          autoFocus: false,
          data: {
            company_id: this.company_id,
            target_company_id: proj.company_id,
            company: this.company,
            user: this.user,
            imgRoute: this.imgRoute,
            activeLang: this.activeLang,
            host: this.host,
            task: resp,
            work: !!proj.task_operation_id ? resp.operations.find(x => x.id == proj.task_operation_id) : undefined,
            project: proj,
            openAsNew: openAsNew
          }
        });
    
        this.attachSubscriptions(
          dialogRef.afterClosed().subscribe(result => {
            console.log(result)
            if (openAsNew) {
              let index = this.projects.indexOf(proj)
              this.updateProject(proj, index);
            } else {
              this.refreshProjects();
            }
          })
        )
      })
    )
  }

  getCircularReplacer() {
    const ancestors:any = [];
    return function (key, value) {
      if (typeof value !== "object" || value === null) {
        return value;
      }
      // `this` is the object that value is contained in,
      // i.e., its direct parent.
      while (ancestors.length > 0 && ancestors.at(-1) !== this) {
        ancestors.pop();
      }
      if (ancestors.includes(value)) {
        return "[Circular]";
      }
      ancestors.push(value);
      return value;
    };
  }

  neededData(task, company) {
    let arr = [
      this.chatService.getTasksChats(company.id, [task.id]).pipe(
        tap(res => {
          task['operations'].map(o_el => {
            o_el['chatsInfo'] = [];
            o_el['chatsInfo'].push(...res.filter(z => z.task_operation_id == o_el.id));
            o_el['unReadChats'] = res.filter(z => z.task_operation_id == o_el.id && z.is_read != '1').length;
            o_el['unClosedChats'] = res.filter(z => z.task_operation_id == o_el.id && z.status_id != '4').length;
          });
          task['chatsInfo'] = [];
          task['chatsInfo'].push(...res.filter(z => z.task_id == task.id));
          task['unReadChats'] = res.filter(z => z.is_read != '1').length;
          task['unClosedChats'] = res.filter(z => z.status_id != '4').length;
        })
      )
    ]
    if (task.company_id == company.id) {
      arr.push(
        this.taskService.getTaskClients(task.id, company.id).pipe(
          tap(res => {
            task.clients = res
          }),
          catchError(err => {
            return err
          })
        )
      )
    }
    return forkJoin(arr)
  }

  openInNew(e, project, key) {
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();
    window.open(this.origin + `/projects?company_id=${this.company_id}&order_by=id_desc&${key}=${project[key]}`, '_blank');
  }

  videoEditorAA(proj, i, openAsNew:boolean = false, otherJob?) {
    this.attachSubscriptions(
      this.taskService.getOneTaskExpand(this.company_id, proj.task_id).pipe(
        map(x => x.body[0]),
        switchMap(x => this.neededData(x, this.company).pipe(map(() => casesModel([x], [], 'update')),map(x => x.arr[0]))),
      ).subscribe(resp => {

        // let project = JSON.parse(JSON.stringify(proj, this.getCircularReplacer()))
        // delete project.createdCards;
        // delete project.createdEmployee;
        // delete project.employees;
        // delete project.runHistory;
        // delete project.task;
        // delete project.taskOperation;
        // delete project.updatedEmployee;
        // delete project.templateIds;
        
        // console.log("project", project)
        let work = !!proj.task_operation_id ? resp.operations.find(x => x.id == proj.task_operation_id) : undefined
        if (!!otherJob) {
          work = otherJob;
        }
        if (work) {
          delete work.updatedEmployee;
          delete work.automationScenarios;
          delete work.uniqueEmployees;
          delete work.chatsInfo;
          delete work.createdEmployee;
          delete work.parameterValuesToTask;
          delete work.employees;
          delete work.employeesStatuses;
          delete work.mainEmployees;
          delete work.partnerCompanies;
          delete work.partnerCompaniesStatuses;
          delete work.partnerEmployees;
          delete work.partnerEmployeesStatuses;
        }
        console.log("work", work)

        // card
        delete resp.channels;
        delete resp.chatsInfo;
        delete resp.clients;
        delete resp.contentPlans;
        delete resp.data;
        delete resp.groupedParametersForTask;
        delete resp.previewImgs;
        delete resp.jobEmployees;
        delete resp.parameterValuesToTask;
        delete resp.worksByTypeAndStatus;
        resp.operations = [work];
        console.log("resp", resp)

        let modalData = {
          previusUrl: this.router.url,
          company_id: this.company_id,
          target_company_id: proj.company_id,
          company: this.company,
          user: this.user,
          imgRoute: this.imgRoute,
          activeLang: this.activeLang,
          host: this.host,
          task: resp,
          work: work,
          project: {id: proj.id},
          openAsNew: openAsNew,
          otherJob: !!otherJob
        }
    
        console.log('modalData', modalData)
        this.sm.localStorageSetItem('ve_data', JSON.stringify(modalData, this.getCircularReplacer()))
        // window.open(this.host,)
        // console.log('sm modalData', JSON.parse(this.sm.localStorageGetItem('ve_data')))
        
        window.open(this.origin + `/video-editor-a?company_id=${this.company_id}`, '_blank');
      })
    )
  }

  videoEditorV6(proj, i, openAsNew:boolean = false) {
    this.attachSubscriptions(
      this.taskService.getOneTaskExpand(this.company_id, proj.task_id).pipe(
        map(x => x.body[0]),
        switchMap(x => this.neededData(x, this.company).pipe(map(() => casesModel([x], [], 'update')),map(x => x.arr[0]))),
      ).subscribe(resp => {

        // let project = JSON.parse(JSON.stringify(proj, this.getCircularReplacer()))
        // delete project.createdCards;
        // delete project.createdEmployee;
        // delete project.employees;
        // delete project.runHistory;
        // delete project.task;
        // delete project.taskOperation;
        // delete project.updatedEmployee;
        // delete project.templateIds;
        
        // console.log("project", project)
        let work = !!proj.task_operation_id ? resp.operations.find(x => x.id == proj.task_operation_id) : undefined
 
        if (work) {
          delete work.updatedEmployee;
          delete work.automationScenarios;
          delete work.uniqueEmployees;
          delete work.chatsInfo;
          delete work.createdEmployee;
          delete work.parameterValuesToTask;
          delete work.employees;
          delete work.employeesStatuses;
          delete work.mainEmployees;
          delete work.partnerCompanies;
          delete work.partnerCompaniesStatuses;
          delete work.partnerEmployees;
          delete work.partnerEmployeesStatuses;
        }
        console.log("work", work)

        // card
        delete resp.channels;
        delete resp.chatsInfo;
        delete resp.clients;
        delete resp.contentPlans;
        delete resp.data;
        delete resp.groupedParametersForTask;
        delete resp.previewImgs;
        delete resp.jobEmployees;
        delete resp.parameterValuesToTask;
        delete resp.worksByTypeAndStatus;
        resp.operations = [work];
        console.log("resp", resp)


        let modalData = {
          previusUrl: this.router.url,
          company_id: this.company_id,
          target_company_id: proj.company_id,
          company: this.company,
          user: this.user,
          imgRoute: this.imgRoute,
          activeLang: this.activeLang,
          host: this.host,
          task: resp,
          work: work,
          project: {id: proj.id},
          openAsNew: openAsNew
        }
    
        console.log('modalData', modalData)
        this.sm.localStorageSetItem('ve_data', JSON.stringify(modalData, this.getCircularReplacer()))
        // window.open(this.host,)
        // console.log('sm modalData', JSON.parse(this.sm.localStorageGetItem('ve_data')))
        
        window.open(this.origin + `/video-editor?company_id=${this.company_id}`, '_blank');
      })
    )
  }

  duplicateToOtherJob(proj, i, openAsNew:boolean = false, trigger:MatMenuTrigger, version) {
    trigger.closeMenu();

    const dialogRef = this.dialog.open(SelectJobComponent, {
      disableClose: true,
      data: {
        user: this.user,
        company: this.company,
        project: proj
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log("duplicateToOtherJob", result);
        if (result.data.job) {
          if (version == "A") {
            this.videoEditorVA(proj,i,openAsNew, result.data.job)
          } else {
            this.videoEditorAA(proj,i,openAsNew, result.data.job)
          }
        }
      })
    )
  } 
  
  videoEditorVA(proj, i, openAsNew:boolean = false, otherJob?) {
    this.attachSubscriptions(
      this.taskService.getOneTaskExpand(this.company_id, proj.task_id).pipe(
        map(x => x.body[0]),
        switchMap(x => this.neededData(x, this.company).pipe(map(() => casesModel([x], [], 'update')),map(x => x.arr[0]))),
      ).subscribe(resp => {

        let work = !!proj.task_operation_id ? resp.operations.find(x => x.id == proj.task_operation_id) : undefined;
        if (otherJob) {
          work = otherJob
        }
        const dialogRef = this.dialog.open(VideoFfmpegEditorComponent, {
          panelClass: ['full_screen_modal', 'video_editor', 'video_editor_black'],
          autoFocus: false,
          disableClose: true,
          data: {
            company_id: this.company_id,
            target_company_id: proj.company_id,
            company: this.company,
            user: this.user,
            imgRoute: this.imgRoute,
            activeLang: this.activeLang,
            host: this.host,
            task: resp,
            work: work,
            project: proj,
            openAsNew: openAsNew,
            otherJob: !!otherJob
          }
        });
    
        this.attachSubscriptions(
          dialogRef.afterClosed().subscribe(result => {
            console.log(result)
            if (openAsNew) {
              let index = this.projects.indexOf(proj)
              this.updateProject(proj, index);
            } else {
              this.refreshProjects();
            }
          })
        )
      })
    )
  }
  
  V5VideoEditor(proj, i, openAsNew:boolean = false) {
    this.attachSubscriptions(
      this.taskService.getOneTaskExpand(this.company_id, proj.task_id).pipe(
        map(x => x.body[0]),
        switchMap(x => this.neededData(x, this.company).pipe(map(() => casesModel([x], [], 'update')),map(x => x.arr[0]))),
      ).subscribe(resp => {
        const dialogRef = this.dialog.open(VideoEditorV5Component, {
          panelClass: ['full_screen_modal', 'video_editor', 'video_editor_black'],
          autoFocus: false,
          disableClose: true,
          data: {
            company_id: this.company_id,
            target_company_id: proj.company_id,
            company: this.company,
            user: this.user,
            imgRoute: this.imgRoute,
            activeLang: this.activeLang,
            host: this.host,
            task: resp,
            work: !!proj.task_operation_id ? resp.operations.find(x => x.id == proj.task_operation_id) : undefined,
            project: proj,
            openAsNew: openAsNew
          }
        });
    
        this.attachSubscriptions(
          dialogRef.afterClosed().subscribe(result => {
            console.log(result)
            if (openAsNew) {
              let index = this.projects.indexOf(proj)
              this.updateProject(proj, index);
            } else {
              this.refreshProjects();
            }
          })
        )
      })
    )
  }

  videoEditorV5(proj, i, openAsNew:boolean = false) {
    this.attachSubscriptions(
      this.taskService.getOneTaskExpand(this.company_id, proj.task_id).pipe(
        map(x => x.body[0]),
        switchMap(x => this.neededData(x, this.company).pipe(map(() => casesModel([x], [], 'update')),map(x => x.arr[0]))),
      ).subscribe(resp => {
        const dialogRef = this.dialog.open(VideoEditorTestComponent, {
          panelClass: ['full_screen_modal', 'video_editor', 'video_editor_black'],
          autoFocus: false,
          disableClose: true,
          data: {
            company_id: this.company_id,
            target_company_id: proj.company_id,
            company: this.company,
            user: this.user,
            imgRoute: this.imgRoute,
            activeLang: this.activeLang,
            host: this.host,
            task: resp,
            work: !!proj.task_operation_id ? resp.operations.find(x => x.id == proj.task_operation_id) : undefined,
            project: proj,
            openAsNew: openAsNew
          }
        });
    
        this.attachSubscriptions(
          dialogRef.afterClosed().subscribe(result => {
            console.log(result)
            if (openAsNew) {
              let index = this.projects.indexOf(proj)
              this.updateProject(proj, index);
            } else {
              this.refreshProjects();
            }
          })
        )
      })
    )
  }

  videoEditorV3(proj, i, openAsNew:boolean = false) {
    this.attachSubscriptions(
      this.taskService.getOneTaskExpand(this.company_id, proj.task_id).pipe(
        map(x => x.body[0]),
        switchMap(x => this.neededData(x, this.company).pipe(map(() => casesModel([x], [], 'update')),map(x => x.arr[0]))),
      ).subscribe(resp => {
        const dialogRef = this.dialog.open(BetaVideoEditorComponent, {
          panelClass: ['full_screen_modal', 'video_editor', 'video_editor_black'],
          autoFocus: false,
          disableClose: true,
          data: {
            company_id: this.company_id,
            target_company_id: proj.company_id,
            company: this.company,
            user: this.user,
            imgRoute: this.imgRoute,
            activeLang: this.activeLang,
            host: this.host,
            task: resp,
            work: !!proj.task_operation_id ? resp.operations.find(x => x.id == proj.task_operation_id) : undefined,
            project: proj,
            openAsNew: openAsNew
          }
        });
    
        this.attachSubscriptions(
          dialogRef.afterClosed().subscribe(result => {
            console.log(result)
            if (openAsNew) {
              let index = this.projects.indexOf(proj)
              this.updateProject(proj, index);
            } else {
              this.refreshProjects();
            }
          })
        )
      })
    )
  }

  hasKey(arr, key) {
    return !!arr.filter(x => x.key == key).length
  }

  getVariable(arr, key) {
    return arr.find(x => x.key == key)
  }

  deleteProject(project, i) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: {},
        target: marker("project")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.fileService.deleteVideoProject(project.id, this.company_id).pipe(
            tap(x => {
              console.log(x)
              this.projects.splice(i,1);
            }),
          ).subscribe(resp => {
            console.log("END DELETE", resp)
          })
        )
      }
    });
  
  }
  
  editProjPriority(project) {
    this.attachSubscriptions(
      this.fileService.editVideoProject(project.id, {waiting_time_limit: project.waiting_time_limit}, this.company_id).subscribe(res => {
        console.log('editProjPriority res', res)
        project.save_waiting_time_limit = res.waiting_time_limit;
      }, error => {
        this.layoutService.showSnackBar({name: ''}, marker(error), SnackBarItem)
        project.waiting_time_limit = project.save_waiting_time_limit;
      })
    )
  }
  
  deleteColumn(item, i) {
    this.viewArch.columns.splice(i, 1);
    this.sm.localStorageSetItem('projectsView', JSON.stringify(this.viewArch));
  }

  deleteVariable(item, variable, k) {
    item.variables.splice(k, 1)
    this.sm.localStorageSetItem('projectsView', JSON.stringify(this.viewArch));
  }

  addVariable(col, variable?) {
    const dialogRef = this.dialog.open(AddEditViewVariableComponent, {
      disableClose: true,
      data: {
        col: col,
        columns: JSON.parse(JSON.stringify(this.columns)),
        variable: variable,
        variables: this.variables,
        is_edit: !!variable,
        user: this.user
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log("addVariable result", result)
        if (result.data && !variable) {
          col.variables.push(result.data);
        }
        this.sm.localStorageSetItem('projectsView', JSON.stringify(this.viewArch));
      })
    )
  }

  openServersTable() {
    console.log('openServersTable');
    const dialogRef = this.dialog.open(ProjectsServersTableComponent, {
      backdropClass: ['ve_back_auto'],
      panelClass: ['ve_panel_auto'],
      disableClose: true,
      autoFocus: false,
      data: {
        company: this.company,
        host: this.host,
        is_mobile: this.is_mobile,
        activeLang: this.activeLang,
        isGlobal: true,
        filterVal: this.filter.value,
        page: +JSON.parse(JSON.stringify(this.projectsPage)),
        pagination: JSON.parse(JSON.stringify(this.projectsPagination)),
        projects: [...this.projects],
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
      })
    )
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.viewArch.columns, event.previousIndex, event.currentIndex);
    this.sm.localStorageSetItem('projectsView', JSON.stringify(this.viewArch));
  }

  dropVar(event: CdkDragDrop<string[]>, array) {
    moveItemInArray(array, event.previousIndex, event.currentIndex);
    this.sm.localStorageSetItem('projectsView', JSON.stringify(this.viewArch));
  }

  addColumn(col?, index?) {
    const dialogRef = this.dialog.open(AddEditViewColumnComponent, {
      disableClose: true,
      data: {
        col: !!col ? col.id : undefined,
        columns: JSON.parse(JSON.stringify(this.columns)),
        is_edit: !!col
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log("addVariable result", result)
        if (result.data) {
          if (!col) {
            let x = JSON.parse(JSON.stringify(this.columns)).find(x => x.id == result.data)
            x.variables = [];
            this.viewArch.columns.push(x);
          } else {
            let x = JSON.parse(JSON.stringify(this.columns)).find(x => x.id == result.data)
            x.variables = [];
            this.viewArch.columns.splice(index, 1, x);
          }
        }
        this.sm.localStorageSetItem('projectsView', JSON.stringify(this.viewArch));
      })
    )
  }

  getProjects(page) {
    if (this.isLoad) {
      return
    }
    this.isLoad = true;
    let filter:any = {...this.filter.value}

    if (page == 1) {
      if (this.projectsDataSub) {
        this.projectsDataSub.unsubscribe()
      }
      this.projectsDataSub = this.fileService.getProjectsData().pipe(
        concatMap(itemsInPage => {
          return concat(...itemsInPage.map(itemInPage => this.neededProjectsData(itemInPage))).pipe(last(),map(x => itemsInPage))
        }),
      ).subscribe(resp => console.log("-----getProjectsDataStream-----",resp));
    }
    this.attachSubscriptions(
      this.fileService.getVideoProjects(page, this.company_id, filter, '100').pipe(
        tap(el => {
          this.projectsPagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
        }),
        map(x => x.body),
        tap(resp => {
          resp.map(u => {
            u.save_waiting_time_limit = u.waiting_time_limit;
          })
          this.fileService.projectsData$.next(resp)
        }),
      ).subscribe(resp => {
        if (page == 1) {
          this.projects = resp;
        } else {
          this.projects.push(...resp);
        }
        console.log("getProjects - " + this.projectsPage, this.projects);
        this.projectsPage++;
        this.isLoad = false;
      }, error => {
        this.isLoad = false;
      })
    )
  }

  onDown(e) {
    console.log("SCROLL DOWN", e);
    if (this.projectsPagination['pageCount'] >= this.projectsPage) {
      console.log("getProjects")
      this.getProjects(this.projectsPage);
    }
  }

  getPartnerGroups() {
    this.attachSubscriptions(
      this.companyService.getInfiniteGroupsPartnerCompany(this.company_id, this.groups_partner_id, '1', '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.companyService.getInfiniteGroupsPartnerCompany(this.company_id, this.groups_partner_id, x, '200').pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.partnerGroups = [].concat(...res);
        console.log("getPartnerGroups", this.partnerGroups);
      })
    )
  }

  getPartnerGroup(id) {
    if (!this.partnerGroups || !this.partnerGroups.find(el => el.id == id)) {
      return false;
    }
    return this.partnerGroups.find(el => el.id == id)
  } 

  getContentTypes() {
    this.attachSubscriptions(
      this.companyService.getContentTypes().pipe(
        map(el => el.body)
      ).subscribe(resp => {
        this.allContentTypes = resp;
      })
    )
  }

  getEmployees() {
    this.attachSubscriptions(
      this.taskService.getEmployees(this.company_id).subscribe(resp => {
        this.employees = resp;
      })
    )
  }
  
  getColumnName(id) {
    if (!this.operationsValues || this.operationsValues.filter(el => el.id == id).length == 0) {
      return '';
    }
    return this.operationsValues.find(el => el.id == id)?.translation?.name ? this.operationsValues.find(el => el.id == id).translation.name : this.operationsValues.find(el => el.id == id).name
  }

  bookmarksChange(e) {
    console.log("bookmarksChange", e)
    this.isSettingsOpen = false;
    if (e.value == -1) {
      this.toDefault()
      return
    }
    console.log("bookmarksChange this.getBookmarkById(e.value)", this.getBookmarkById(e.value))
    let x = JSON.parse(JSON.stringify(this.getBookmarkById(e.value).settings));
    if (x.viewArch) {
      this.viewArch = x.viewArch
      this.sm.localStorageSetItem('projectsView', JSON.stringify(this.viewArch));
      delete x.viewArch
    }

    this.router.navigate(['/projects'], { queryParams: Object.assign({company_id: this.company_id, order_by: "id_desc", preset_id: e.value}, x)});
  }

  getProfile(id) {
    if (!this.profiles || !this.profiles.find(el => el.id == id)) {
      return false;
    }
    return this.profiles.find(el => el.id == id)
  }  

  getContentById(id) {
    if (!this.allContentTypes || this.allContentTypes.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.allContentTypes.find(el => el.id == id)
  }
  
  getPlatforms() {
    this.attachSubscriptions(
      this.companyService.getGroupPlatforms().subscribe(resp => {
        resp.unshift({
          id:	0,
          name: "Without platform",
          url: '',
          created_at:	0,
          updated_at:	0,
        })
        this.platforms = resp;
        console.log("this.platforms", this.platforms)
      })
    )
  }

  getEmployee(id) {
    if (!this.employees) {
      return false;
    }
    return this.employees.find(el => el.id == id)
  }  

  getPlatformById(id) {
    if (!this.platforms || this.platforms.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.platforms.find(el => el.id == id)
  }

  getTaskStatus() {
    this.attachSubscriptions(
      this.taskService.getTaskStatuses(this.company_id).subscribe(resp => {
        this.taskStatuses = resp.slice()
        this.taskStatuses.unshift({
          id: 0,
          name: marker('All statuses')
        });
      })
    )
  }

  getProfilesStatus() {
    this.attachSubscriptions(
      this.globalDataService.getContentStatus().subscribe(resp => {
        console.log("getProfilesStatus", resp)
        this.profileStatuses = resp.slice();
      })
    )
  }

  getProfileStatus(id) {
    if (!this.profileStatuses) {
      return false;
    }
    return this.profileStatuses.find(el => el.id == id)
  }  

  getTaskStatusById(id) {
    if (!this.taskStatuses || this.taskStatuses.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.taskStatuses.find(el => el.id == id)
  }

  getGroup(id) {
    if (!this.groups || !this.groups.find(el => el.id == id)) {
      return false;
    }
    return this.groups.find(el => el.id == id)
  }  

  getChecklist(id) {
    if (!this.checklists || !this.checklists.find(el => el.id == id)) {
      return false;
    }
    return this.checklists.find(el => el.id == id)
  }  

  getProfiles() {
    this.attachSubscriptions(
      this.companyService.getProfiles(this.company_id).pipe(
        map(el => el.body)
      ).subscribe(resp => {
        console.log("getProfiles", resp)
        this.profiles = resp
      })
    )
  }

  getChecklists() {
    this.attachSubscriptions(
      this.taskService.getOperationReminder(this.company_id).subscribe(resp => {
        this.checklists = resp.body;
        console.log("getChecklists", this.checklists)
      })
    )
  }

  getGroupsCompany() {
    this.attachSubscriptions(
      this.companyService.getInfiniteGroupsCompany(this.company_id, '1', '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.companyService.getInfiniteGroupsCompany(this.company_id, x).pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.groups = [].concat(...res);
      })
    )
  }

  ngOnDestroy(): void {
    if (this.projectsDataSub) {
      this.projectsDataSub.unsubscribe()
    }
    this.clearSubscriptions()
  }

}
