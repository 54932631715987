import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './components/admin/dashboard/dashboard.component';
import { BoardPageComponent } from './components/workspace-pages/board-page/board-page.component';
import { CasesComponent } from './components/workspace-pages/cases/cases.component';
import { RemindersComponent } from './components/workspace-pages/cases/reminders/reminders.component';
import { ChatsPageComponent } from './components/workspace-pages/chats-page/chats-page.component';
import { CompanyCreateComponent } from './components/company-create/company-create.component';
import { CompanyEditComponent } from './components/company-edit/company-edit.component';
import { CompanyComponent } from './components/workspace-settings/categorization/groups/company/company.component';
import { ConnectedAppsComponent } from './components/connected-apps/connected-apps.component';
import { ErrorPageComponent } from './components/error-page/error-page.component';
import { JobsComponent } from './components/workspace-pages/jobs/jobs.component';
import { MainComponent } from './components/main/main.component';
import { SetUpComponent } from './components/main/set-up/set-up.component';
import { SetComponent } from './components/main/set/set.component';
import { WorkspaceDashboardComponent } from './components/workspace-pages/workspace-dashboard/workspace-dashboard.component';
import { UrlsComponent } from './components/workspace-pages/urls/urls.component';
import { RegularPublicationsComponent } from './components/workspace-settings/regular-publications/regular-publications.component';
import { FileManagerComponent } from './shared/global_components/file-manager/file-manager.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { WorkspaceSettingsComponent } from './components/workspace-settings/workspace-settings.component';
import { TaskLinkComponent } from './components/links/task-link/task-link.component';
import { JobLinkComponent } from './components/links/job-link/job-link.component';
import { ChatLinkComponent } from './components/links/chat-link/chat-link.component';
import { FileLinkComponent } from './components/links/file-link/file-link.component';
import { UrlsDashboardComponent } from './components/workspace-pages/urls-dashboard/urls-dashboard.component';
import { BoardListPageComponent } from './components/workspace-pages/board-page/board-list-page/board-list-page.component';
import { OutletsDashboardComponent } from './components/workspace-pages/outlets-dashboard/outlets-dashboard.component';
import { TestPageComponent } from './components/test-page/test-page.component';
import { FilesComponent } from './components/workspace-pages/files/files.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { PrivacyPageComponent } from './components/privacy-page/privacy-page.component';
import { TestVeComponent } from './components/test-ve/test-ve.component';
import { VideoEditorPageComponent } from './shared/global_components/file-manager/dialog/video-editor-page/video-editor-page.component';
import { ProjectsPageComponent } from './components/workspace-pages/projects-page/projects-page.component';
import { UrlsStartComponent } from './components/workspace-pages/urls/urls-start/urls-start.component';
import { UrlsAnalyticComponent } from './components/workspace-pages/urls/urls-analytic/urls-analytic.component';
import { SetUpAutoComponent } from './components/main/set-up-auto/set-up-auto.component';
import { VideoEditorPageAComponent } from './shared/global_components/file-manager/dialog/video-editor-page-a/video-editor-page-a.component';
import { OutletAssistantComponent } from './components/workspace-settings/publication-outlets/outlet-assistant/outlet-assistant.component';

const routes: Routes = [
  { path: "root", component: DashboardComponent, canActivate: [AuthGuard] },
  { path: "main", component: MainComponent },
  { path: "create", component: CompanyCreateComponent },
  { path: "set", component: SetComponent },
  { path: "set-up", component: SetUpComponent },
  { path: "set-up-auto", component: SetUpAutoComponent },
  { path: "edit", component: CompanyEditComponent, children: [
    { path: ":id", component: CompanyEditComponent }
  ]},
  { path: "company", component: CompanyComponent },
  { path: "dashboard", component: WorkspaceDashboardComponent },
  { path: "tasks", component: CasesComponent },
  { path: "jobs", component: JobsComponent },
  { path: "test", component: TestPageComponent },
  { path: "test-ve", component: TestVeComponent },
  { path: "chats", component: ChatsPageComponent },
  { path: "board", component: BoardPageComponent },
  { path: "board-list", component: BoardListPageComponent },
  { path: "files", component: FilesComponent },
  { path: "projects", component: ProjectsPageComponent },
  { path: "outlet-assistant", component: OutletAssistantComponent },
  { path: "urls-start", component: UrlsStartComponent },
  { path: "urls-analytic", component: UrlsAnalyticComponent },
  { path: "urls", component: UrlsComponent },
  { path: "urls-dashboard", component: UrlsDashboardComponent },
  { path: "outlets-dashboard", component: OutletsDashboardComponent },
  { path: "regular-publications", component: RegularPublicationsComponent },
  { path: "reminder-tasks", component: RemindersComponent },
  { path: "video-editor", component: VideoEditorPageComponent },
  { path: "video-editor-a", component: VideoEditorPageAComponent },
  { path: "task", component: TaskLinkComponent , children: [
    { path: ":id", component: TaskLinkComponent }
  ]},
  { path: "job", component: JobLinkComponent , children: [
    { path: ":id", component: JobLinkComponent }
  ]},
  { path: "chat", component: ChatLinkComponent , children: [
    { path: ":id", component: ChatLinkComponent }
  ]},
  { path: "file", component: FileLinkComponent , children: [
    { path: ":id", component: FileLinkComponent }
  ]},
  { path: "static/privacy", component: PrivacyPageComponent },
  { path: "settings", component: WorkspaceSettingsComponent },
  { path: "user/connected-apps", component: ConnectedAppsComponent },
  { path: "error-page", component: ErrorPageComponent },
  { path: "**", redirectTo: "main", pathMatch: "full" }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
