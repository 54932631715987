import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import * as moment from 'moment';
import { concat, forkJoin, from, fromEvent, of, ReplaySubject, Subject, Subscription, throwError } from 'rxjs';
import { catchError, concatMap, debounceTime, distinctUntilChanged, filter, flatMap, last, map, retry, switchMap, take, tap, toArray } from 'rxjs/operators';
import { DeleteAlertComponent } from 'src/app/shared/global_components/delete-alert/delete-alert.component';
import { NotificationsBar } from 'src/app/shared/global_components/notifications-bar/notifications-bar';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { ChatService } from 'src/app/shared/services/rest/chat.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import SwiperCore, { Pagination,  Mousewheel } from 'swiper/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatSelect } from '@angular/material/select';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { InterfaceComponent } from 'src/app/shared/global_components/file-manager/dialog/interface/interface.component';
import { DateRange, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { casesModel } from 'src/app/shared/functions/casesModel';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { LoadingService } from 'src/app/shared/services/rest/loading.service';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { RefreshService } from 'src/app/shared/services/rest/refresh.service';
import { GlobalDataService } from 'src/app/shared/services/common/global-data.service';
import { MY_FORMATS } from '../../workspace-settings/finances/statements/statements.component';
import { ChatsComponent } from '../../chats/chats.component';
import { OperationChatComponent } from '../../atTasksDialog/operation-chat/operation-chat.component';
import { FileService } from 'src/app/shared/services/rest/file.service';
import { UploadService } from 'src/app/shared/services/rest/upload.service';
import { ParametersService } from 'src/app/shared/services/rest/parameters.service';
import { OpenJobComponent } from '../cases/dialogs/open-job/open-job.component';
import { OpenTaskComponent } from '../cases/dialogs/open-task/open-task.component';
import { EditPublishedAtComponent } from '../../atTasksDialog/profiles/edit-published-at/edit-published-at.component';
import { EditStatusComponent } from '../../atTasksDialog/profiles/edit-status/edit-status.component';
import { EditContentNameComponent } from '../../atTasksDialog/profiles/edit-content-name/edit-content-name.component';
import { BackJobToPrevStatusComponent } from 'src/app/shared/global_components/back-job-to-prev-status/back-job-to-prev-status.component';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView, collapseAnimation } from 'angular-calendar';
import { endOfDay, endOfMonth, endOfWeek, isSameDay, isSameMonth, startOfDay, startOfMonth, startOfWeek } from 'date-fns';
import { AddAndEditScheduledPostComponent } from '../../workspace-settings/add-and-edit-scheduled-post/add-and-edit-scheduled-post.component';
import { AddTaskProfileComponent } from '../cases/dialogs/open-task/dialogs/add-task-profile/add-task-profile.component';
import { Moment } from 'moment';
import { MultiEditProfilesStatusComponent } from './dialogs/multi-edit-profiles-status/multi-edit-profiles-status.component';
import { UrlAnalyticsComponent } from '../cases/dialogs/open-task/dialogs/url-analytics/url-analytics.component';
import { AddChannelToTaskComponent } from '../../atTasksDialog/add-channel-to-task/add-channel-to-task.component';
import { MobUrlAnalyticsComponent } from '../cases/dialogs/open-task/dialogs/mob-url-analytics/mob-url-analytics.component';
import { environment } from 'src/environments/environment';
import { prioritys } from 'src/app/shared/consts/prioritys';
import { CommentsComponent } from 'src/app/shared/global_components/comments/comments.component';
import { SetEditCardComponent } from 'src/app/shared/global_components/set-edit-card/set-edit-card.component';
import { AddEditLiveStreamComponent } from '../cases/dialogs/open-task/dialogs/add-edit-live-stream/add-edit-live-stream.component';
import { ServersTableComponent } from '../cases/dialogs/open-task/dialogs/servers-table/servers-table.component';
import { ChannelsTableComponent } from '../cases/dialogs/open-task/dialogs/channels-table/channels-table.component';
import { AskTextComponent } from 'src/app/shared/global_components/ask-text/ask-text.component';
import { PostExtraInfoComponent } from 'src/app/shared/global_components/post-extra-info/post-extra-info.component';
import { ChannelsCounterTableComponent } from '../cases/dialogs/open-task/dialogs/channels-counter-table/channels-counter-table.component';
import { SetPrivacyStatusComponent } from './dialogs/set-privacy-status/set-privacy-status.component';
import { TargetParametersComponent } from '../../atTasksDialog/target-parameters/target-parameters.component';
import { ManagePostsComponent } from '../cases/dialogs/open-task/dialogs/manage-posts/manage-posts.component';
import { PostsCleanerComponent } from 'src/app/shared/global_components/cleaners/posts-cleaner/posts-cleaner.component';
import { makeArray } from 'src/app/shared/functions/objToArray';
import { SelectFileComponent } from 'src/app/shared/global_components/file-manager/dialog/select-file/select-file.component';
import { ImportFilesV2Component } from '../cases/dialogs/import-files-v2/import-files-v2.component';
import { WorkMultiAddComponent } from '../../atTasksDialog/work-multi-add/work-multi-add.component';
import { CardScenarioComponent } from '../../atTasksDialog/card-scenario/card-scenario.component';
import { TagTaskComponent } from 'src/app/shared/global_components/tag-task/tag-task.component';
import { NotesComponent } from 'src/app/shared/global_components/notes/notes.component';
import { SharedOptionsComponent } from 'src/app/shared/global_components/shared-options/shared-options.component';
import { TaskBarService } from 'src/app/shared/services/rest/task-bar.service';
import { MinimizeService } from 'src/app/shared/services/rest/minimize.service';
import { MigrateTargetFilesComponent } from 'src/app/shared/global_components/migrate-target-files/migrate-target-files.component';
import { WorkAddComponent } from '../../atTasksDialog/work-add/work-add.component';
import { MobAddJobComponent } from '../../mob-job/mob-add-job/mob-add-job.component';
import { MoveAllCardJobsComponent } from '../cases/dialogs/move-all-card-jobs/move-all-card-jobs.component';
import { TaskManagersComponent } from '../cases/dialogs/managers/task-managers/task-managers.component';
import { GroupAddComponent } from '../../atTasksDialog/group-add/group-add.component';
import { TaskEditComponent } from '../../atTasksDialog/task-edit/task-edit.component';
import { MobTaskEditComponent } from '../../mob-task/mob-task-edit/mob-task-edit.component';
import { TaskClientsComponent } from '../../clients/task-clients/task-clients.component';
import { TaskGroupEditComponent } from '../../atTasksDialog/group-edit/group-edit.component';
import { MobTaskEditGroupComponent } from '../../mob-task/mob-task-edit-group/mob-task-edit-group.component';
import { MobTitleComponent } from 'src/app/shared/global_components/chat/dialogs/mob-title/mob-title.component';
import { MobPriorityComponent } from 'src/app/shared/global_components/chat/dialogs/mob-priority/mob-priority.component';
import { WorkEmplAddComponent } from '../../atTasksDialog/work-empl-add/work-empl-add.component';
SwiperCore.use([Mousewheel, Pagination]);



const colors: Record<string, any> = {
  1: {
    type: "Waiting",
    primary: '#2F80ED',
    secondary: '#BCD7FD',
  },
  2: {
    type: "Draft",
    primary: '#DB0000',
    secondary: '#FFAEAE',
  },
  3: {
    type: "Scheduled",
    primary: '#BEC11B',
    secondary: '#FEFFB9',
  },
  4: {
    type: "Published",
    primary: '#219653',
    secondary: '#98F1BD',
  },
  5: {
    type: "Canceled",
    primary: '#686868',
    secondary: '#E0E0E0',
  },
  6: {
    type: "content",
    primary: '#DD5E89',
    secondary: '#F7BB97',
  }
};


const users: any[] = [
  {
    id: 0,
    name: 'John smith',
    color: colors['1'],
  },
  {
    id: 1,
    name: 'Jane Doe',
    color: colors['4'],
  },
];

function getTimezoneOffsetString(date: Date): string {
  const timezoneOffset = date.getTimezoneOffset();
  const hoursOffset = String(
    Math.floor(Math.abs(timezoneOffset / 60))
  ).padStart(2, '0');
  const minutesOffset = String(Math.abs(timezoneOffset % 60)).padEnd(2, '0');
  const direction = timezoneOffset > 0 ? '-' : '+';

  return `T00:00:00${direction}${hoursOffset}:${minutesOffset}`;
}


// changeDetection: ChangeDetectionStrategy.OnPush,
@Component({
  selector: 'app-urls',
  templateUrl: './urls.component.html',
  styleUrls: ['./urls.component.scss'],
  animations: [collapseAnimation],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: false } },
  ]
})
export class UrlsComponent extends BaseClass implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("mobile_filter_wrp") mobile_filter_wrp: ElementRef;
  @ViewChild("urlsContainer") urlsContainer: ElementRef;
  @ViewChild("urlsIGContainer") urlsIGContainer: ElementRef;
  @ViewChild("urlsYTContainer") urlsYTContainer: ElementRef;
  // @ViewChild('profilesContainer') profilesContainer: MatSelect;


  users = users;
  
  CalendarView: any = {
    IGGrid: "iggrid",
    YTGrid: "ytgrid",
    Month: "month",
    Week: "week",
    Day: "day",
    List: "list"
  }

  public post_delete_after: boolean = this.sm.localStorageGetItem('post_delete_after') ? true : false;
  public post_backup_after: boolean = this.sm.localStorageGetItem('post_backup_after') ? true : false;
  public post_link_after: boolean = this.sm.localStorageGetItem('post_link_after') ? true : false;
  public post_status_after: any = this.sm.localStorageGetItem('post_status_after') ? +this.sm.localStorageGetItem('post_status_after') : 6;

  public today = moment().set({hour:0,minute:0,second:0}).unix();
  public todayEnd = moment().endOf('day').unix();
  public groups_partner_id: any;
  public partnerGroups: any;
  public per_page: number = 20;

  public bookmarks: any;
  public bookmarksControl: FormControl = new FormControl();
  public bookmarks$: ReplaySubject<any> = new ReplaySubject<any>(1);
  
  public outletsData: any;
  public dashFilters: any;
  public showAddOptions: FormControl = new FormControl(false);

  public viewChart: any[] = [156, 54];
  public colorTimeScheme = {
    domain: ['#2ea5cb']
  };

  public showFilter: boolean = !!this.sm.localStorageGetItem('showFilter')
  public view: FormControl = new FormControl(!!this.sm.localStorageGetItem('url_view') ? this.sm.localStorageGetItem('url_view') : this.CalendarView.List);
  
  public isActiveDashboard: boolean = false;
  viewDate: Date = new Date();

  modalData: {
    action: string;
    event: CalendarEvent;
  };

  actions: CalendarEventAction[] = [
    {
      label: '<i class="fas fa-fw fa-pencil-alt"></i>',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent(null, 'Edited', event);
      },
    },
    {
      label: '<i class="fas fa-fw fa-trash-alt"></i>',
      a11yLabel: 'Delete',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.urls = this.urls.filter((iEvent) => iEvent !== event);
        this.handleEvent(null, 'Deleted', event);
      },
    },
  ];

  refreshCalendar = new Subject<void>();
  // urls: CalendarEvent[] = 

  activeDayIsOpen: boolean = false;
  public gettingJobs: boolean = false;

  public origin = window.location.origin;
  public host: any = environment.host;
  public imgRoute: any = '';
  public user: any = {};
  public user_id: number;
  public linkTo: string = '';
  public company: any;
  public me: any;
  public noPublishCount: number = 0;
  public currMonthName: any;
  public company_id: any;
  public activeLang: any;
  public operations: any;
  public operationsValues: any;
  public operationStatuses: any;
  public currencyes: any;

  public bufferedPage: number = 1;
  public bufferedPagination: any;
  public bufferedUrls: any;
  public bufferUrlsSub: Subscription;
  public isBufLoad: boolean = false;

  public page: number = 1;
  public pagination: object = {};
  
  public contentPage: number = 1;
  public contentPagination: any = {};
  // public contentTypePage: number = 1;
  // public contentTypePagination: any = {};

  public filterCount: number = 1;

  public isRefreshed: boolean = false;
  public isScrolledCollectionBtn: boolean = false;
  public collectionIsActive: boolean = false;
  public dashForm: FormGroup;
  public filter: FormGroup = this.fb.group({
    count: '',
    q: '',
    content_status_id: '',
    no_operation_id: '',
    no_channel_id: [[]],
    views_update_interval: '',
    preset_id: '',
    scheduled_published_time_by: '',
    created_employee_id: [[]],
    updated_employee_id: [[]],
    created_url_employee_id: [[]],
    updated_url_employee_id : [[]],
    task_parameter_value_id: [[]],
    task_operation_parameter_value_id : [[]],
    discussion_parameter_value_id: [[]],
    file_parameter_value_id : [[]],
    parameter_value_id: [[]],
    operation_employee_id: '',
    is_content_url: '',
    task_created_at_from: "",
    task_created_at_to: "",
    task_operation_created_at_from: "",
    task_operation_created_at_to: "",
    task_operation_completed_at_from: "",
    task_operation_completed_at_to: "",
    discussion_created_at_from: "",
    discussion_created_at_to: "",
    file_created_at_from: "",
    file_created_at_to: "",
    file_updated_at_from: "",
    file_updated_at_to: "",
    file_meta_creation_time_from: "",
    file_meta_creation_time_to: "",
    task_channel_published_at_from: "",
    task_channel_published_at_to: "",
    operation_partner_company_id: '',
    client_employee_id: '',
    origin_company_id: '',
    task_operation_operation_id: '',
    task_custom_id: '',
    task_status_id: '',
    external_status: '',
    content_type_id: '',
    is_content_views_count_update: '',
    is_live_stream_backup: '',
    is_bravourl_content_views_count_update: '',
    channel_id: [[]],
    group_id: [[]],
    channel_platform_id: '',
    created_at_from : '',
    created_at_to : '',
    summary_views_count_from : '',
    analytics_views_count_from : '',
    analytics_views_count_to : '',
    content_published_at_from : '',
    content_published_at_to : '',
    order_by: 'content_published_at_desc',
  });
  public timeZone = new Date().getTimezoneOffset();
  public is_mobile: boolean = false;
  public urls: any[] = [];
  public contents: any[] = [];
  public appStatusId: number;
  public profileStatuses: any;
  public sortValue: string = 'content_published_at_desc';
  public activeStatus: number = 0;
  public profileCollection: any;
  public selectedCheck: any;
  public selectedJob: any;
  public urlsBufferSub: Subscription;
  public sub: Subscription;
  public subFilter: Subscription;
  public subClientFilter: Subscription;
  public execFilterSub: Subscription;
  public urlsDataSub: Subscription;
  public postsDataSub: Subscription;
  public mobScrollSub: Subscription;
  public scrollSub: Subscription;
  public taskStatuses: any;
  public summaryViews: any;
  public isLoad: boolean = false;
  public isBufferLoad: boolean = false;
  public uploadLimit: boolean = false;
  public dateFromChanged: boolean = false;
  public dateToChanged: boolean = false;
  public dateFromCreatedChanged: boolean = false;
  public dateToCreatedChanged: boolean = false;
  public urlsSub: Subscription;
  public contentSub: Subscription;
  public curYear = (new Date()).getFullYear()

  
  public createdDateRange = new DateRange("", "");
  public publishDateRange = new DateRange("", "");
  public viewsDateRange = new DateRange("", "");
  overlayDateRefFilter: OverlayRef | null;
  public dateFilterSub: Subscription;
  @ViewChild('filterDateMenu') filterDateMenu: TemplateRef<any>;
  
  public allValues: any;
  
  public viewsUpdateIntervalList: any;
  
  public platforms: any;
  public platforms$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public platformsMoreControl: FormControl = new FormControl();

  public groups: any;
  public groupsControl: FormControl = new FormControl();
  public groups$: ReplaySubject<any> = new ReplaySubject<any>(1);
  
  
  // public allContentTypes: any;

  // public partners: any;
  // public partnersControl: FormControl = new FormControl();
  // public partners$: ReplaySubject<any> = new ReplaySubject<any>(1);
  
  // public employees: any;
  // public employees$: ReplaySubject<any> = new ReplaySubject<any>(1);
  // public execControl: FormControl = new FormControl();

  // public clientControl: FormControl = new FormControl();
  public selectedIndex: FormControl = new FormControl(0);

  // public profilesPagination: any;
  // public profilesPage: number = 1;
  public profiles: any[] = [];
  // public profilesMoreControl: FormControl = new FormControl();
  // public profiles$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public platformProfiles: any[] = [];
  public platformProfilesControl: FormControl = new FormControl();
  public platformProfiles$: ReplaySubject<any> = new ReplaySubject<any>(1);

  @ViewChild('filterUrlExecMenu') filterUrlExecMenu: TemplateRef<any>;
  
  overlayRefFilter: OverlayRef | null;
  // overlayRefClientFilter: OverlayRef | null;
  overlayExecRefFilter: OverlayRef | null;
  overlayRef: OverlayRef | null;
  // @ViewChild('filterMenu') filterMenu: TemplateRef<any>;
  // @ViewChild('filterClientMenu') filterClientMenu: TemplateRef<any>;
  @ViewChild('taskMenu') taskMenu: TemplateRef<any>;
  public totalInfo: object =  {
    'summaryRate': 0,
    'summaryPrice': [
      {
        currency_id: 0,
        summary: 0
      },
      {
        currency_id: 1,
        summary: 0
      },
      {
        currency_id: 2,
        summary: 0
      },
      {
        currency_id: 3,
        summary: 0
      },
      {
        currency_id: 4,
        summary: 0
      }
    ]
  }
  public contentUrl = [
    {
      name: marker("Without content url"),
      id: 0
    },
    {
      name: marker("With content url"),
      id: 1
    }
  ]
  public contentTypes: any[] = [];
  public contentTypeMoreControl: FormControl = new FormControl();
  public contentTypes$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public prioritys = prioritys
  constructor(
    private sm: StorageManagerService,
    private auth: AuthService,
    private router: Router,
    private chatService: ChatService,
    private globalDataService: GlobalDataService,
    public activatedRoute: ActivatedRoute,
    private taskService: TaskService,
    private companyService: CompanyService,
    private languageService: LanguageService,
    private minimizeService: MinimizeService,
    private taskBarService: TaskBarService,
    private parametersService: ParametersService,
    private layoutService: LayoutService,
    private fileService: FileService,
    private uploadService: UploadService,
    private refreshService: RefreshService,
    private _adapter: DateAdapter<any>,
    private dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private fb: FormBuilder,
    private bottomSheet: MatBottomSheet,
    public viewContainerRef: ViewContainerRef,
    public overlay: Overlay,
    private _dateAdapter: DateAdapter<Moment>,
    public ls: LoadingService,
    private cd: ChangeDetectorRef
  ) { super() }

  ngOnInit(): void {
    this.company_id = this.activatedRoute.snapshot.queryParamMap.get('company_id');
    this.checkIsMobile();

    this.dashForm = this.fb.group({
      missing: 1
    })
    
    this.attachSubscriptions(
      this.globalDataService.getTransferData().subscribe(resp => {
        console.log("ActiveFiltersComponent getTransferData", resp)
        if (resp.profiles) {
          this.profiles = resp.profiles;

          if (this.filter.value.channel_id.length == 1 && this.view.value.indexOf('grid') != -1) {
            if (this.profiles.length && this.getProfileById(this.filter.value.channel_id[0])) {
              if (this.getProfileById(this.filter.value.channel_id[0]).platform_id == 1) {
                this.view.patchValue(this.CalendarView.YTGrid, {onlySelf: true, emitEvent: false})
              } else {
                this.view.patchValue(this.CalendarView.IGGrid, {onlySelf: true, emitEvent: false})
              }
            }
          }
        }
      })
    )

    if (!!this.sm.localStorageGetItem('showUrlsAddOptions')) {
      this.showAddOptions.patchValue(true)
    }

    this.attachSubscriptions(
      this.showAddOptions.valueChanges.subscribe(res => {
        if (!!res) {
          this.sm.localStorageSetItem('showUrlsAddOptions', true)
        } else {
          localStorage.removeItem('showUrlsAddOptions')
        }
        this.filterUrls()
      })
    )

    this.attachSubscriptions(
      this.dashForm.get('missing').valueChanges.subscribe(res => {
        console.log("this.dashForm valueChanges", res)
        this.outletsData = this.getOutlets(this.urls)
        console.log("END valueChanges OUTLETS", this.outletsData)
      })
    )

    this.groups_partner_id = this.activatedRoute.snapshot.queryParamMap.get('groups_partner_id');
    if (!!this.groups_partner_id) {
      this.getPartnerGroups()
    }

    this.dashFilters = [
      {
        id:0,
        name: "All",
        for: [this.CalendarView.Day, this.CalendarView.Month, this.CalendarView.Week]
      },
      {
        id: 1,
        name: "Missing today",
        for: [this.CalendarView.Day, this.CalendarView.Month, this.CalendarView.Week],
        from: moment().startOf('day'),
        to: moment().endOf('day'),
      },
      {
        id: 2,
        name: "Missing tommorow",
        for: [this.CalendarView.Month, this.CalendarView.Week],
        from: moment().add(1,'d').startOf('day'),
        to: moment().add(1,'d').endOf('day'),
      },
      {
        id: 3,
        name: "Missing in current week",
        for: [this.CalendarView.Month, this.CalendarView.Week],
        from: moment().startOf('isoWeek'),
        to: moment().endOf('isoWeek'),
      },
      {
        id: 4,
        name: "Missing in current month",
        for: [this.CalendarView.Month],
        from: moment().startOf('month'),
        to: moment().endOf('month'),
      }
    ]


    console.log("dashFilters", this.dashFilters)
    console.log("CalendarView", this.CalendarView)
    
    this.getLangData(this.company_id);
    this.getProfilesStatus();
    this.getPlatforms();
    this.getImgRoute();
    this.getUser();
    this.getOperationsStatus();
    this.getTaskStatus();
    this.getViewsUpdateIntervalList();
    this.onRouteChange();
    this.refresh();
    this.attachSubscriptions(
      this.refreshService.getRefreshBookmarks().subscribe(res => {
        if (res == "Posts") {
          this.getBookmarks()
        }
      })
    )
    this.attachSubscriptions(
      this.groupsControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchGroups(resp))
    )

    this.attachSubscriptions(
      this.bookmarksControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchBookmarks(resp))
    )

    this.attachSubscriptions(
      this.contentTypeMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchContentTypes(resp))
    )

    this.attachSubscriptions(
      this.view.valueChanges.subscribe((resp) => {
        console.log("url_view", resp)
        if (resp.indexOf('grid') == -1) {
          this.sm.localStorageSetItem("url_view", resp);
        } else {
          this.getPlatformProfiles();
        }
        if (['month', 'week', 'day'].includes(this.view.value)) {
          const getStart: any = {
            month: startOfMonth,
            week: startOfWeek,
            day: startOfDay,
          }[this.view.value];
      
          const getEnd: any = {
            month: endOfMonth,
            week: endOfWeek,
            day: endOfDay,
          }[this.view.value];

          if (this.view.value == "week") {
            this.filter.patchValue({
              content_published_at_from : moment(moment(startOfWeek(this.viewDate, {weekStartsOn: 1})).unix() * 1000),
              content_published_at_to : moment(moment(endOfWeek(this.viewDate, {weekStartsOn: 1})).unix() * 1000),
            })
          } else {
            this.filter.patchValue({
              content_published_at_from : moment(moment(getStart(this.viewDate)).unix() * 1000),
              content_published_at_to : moment(moment(getEnd(this.viewDate)).unix() * 1000),
            })
          }
      

          this.filterUrls();
        } else {
          this.filter.patchValue({
            content_published_at_from: '',
            content_published_at_to: '',
          })
          this.filterUrls();
        }
      })
    )

    // this.attachSubscriptions(
    //   this.execControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchEmoloyees(resp))
    // )
    
    // this.attachSubscriptions(
    //   this.partnersControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchPartners(resp))
    // )
    this.attachSubscriptions(
      this.platformProfilesControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchProfiles(resp))
    )
    this.attachSubscriptions(
      this.platformsMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchPlatforms(resp))
    )
    
  }

  setChannel(chan) {
    this.filter.patchValue({
      channel_id: [chan.id]
    })

    this.platformProfilesControl.patchValue('');
    
    this.filterUrls();
  }

  getChannelById(id) {
    if (!this.platformProfiles || this.platformProfiles.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.platformProfiles.find(el => el.id == id)
  }

  getPlatformProfiles() {
    this.attachSubscriptions(
      this.companyService.getAllProfilesDyn('1', this.company_id, '1', {platform_id: this.getProfileById(this.filter.get('channel_id').value[0])?.platform_id}).pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }
  
          return forkJoin(arr.map(x => this.companyService.getAllProfilesDyn(x, this.company_id, '200', {platform_id: this.getProfileById(this.filter.get('channel_id').value[0])?.platform_id}).pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              let conValues = [].concat(...values)
              this.platformProfiles = conValues;
              this.platformProfiles$.next(this.platformProfiles.slice());
            }),
          )
        }),
      ).subscribe(resp => {
        console.log("getPlatformProfiles resp", resp);
        console.log("getPlatformProfiles profiles", this.platformProfiles);
      })
    )
  }

  getProfileById(id) {
    if (!this.profiles || this.profiles.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.profiles.find(el => el.id == id)
  }

  onSearchBookmarks(resp) {
    if (!this.bookmarks) {
      return;
    }

    if (!resp) {
      this.bookmarks$.next(this.bookmarks.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    // filter the banks
    this.bookmarks$.next(
      this.bookmarks.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  getBookmarks() {
    this.attachSubscriptions(
      this.companyService.getBookmarks(this.company_id, {employee_id: this.company.employees[0].id, section: "Post List"}).subscribe(res => {
        this.bookmarks = res
        this.bookmarks$.next(this.bookmarks.slice())
        console.log("bookmarks", this.bookmarks)
      })
    )
  }

  bookmarksChange(e) {
    this.router.navigate(['/urls'], { queryParams: Object.assign({company_id: this.company_id, preset_id: e.value}, this.getBookmarkById(e.value).settings)});
  }

  missChange(e) {
    console.log("missChange", e)
  }
  
  getBookmarkById(id) {
    if (!this.bookmarks || this.bookmarks.length == 0) {
      return null
    }
    return this.bookmarks.find(x => x.id == id)
  }
  
  dayClicked({ date, events }: { date: Date; events: any[] }): void {
    console.log(events);
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  rangeChange(date: Moment, key, range): void {
    console.log(date);

    if (!this.filter.value[key+'from']) {
      // this.filter.value[key+'from'] = date;
      this.filter.patchValue({
        [key+'from']: date,
      })
    } else if (
      !this.filter.value[key+'to'] &&
      this._dateAdapter.compareDate(date.endOf('day'), this.filter.value[key+'from']) >= 0
    ) {
      this.filter.patchValue({
        [key+'to']: date.endOf('day'),
      })
    } else {
      this.filter.patchValue({
        [key+'from']: date,
        [key+'to']: null
      })
    }
    console.log("createdRangeChange", this.filter.value);

    this[range] = new DateRange(this.filter.value[key+'from'], this.filter.value[key+'to']);
  }

  onSearchContentTypes(resp) {
    if (!this.contentTypes) {
      return;
    }

    const contentTypesCopy = this.copyContentTypesGroups(this.contentTypes);
    if (!resp) {
      this.contentTypes$.next(contentTypesCopy);
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    // this.contentTypes$.next(
    //   this.contentTypes.filter(z => (z.name.toLowerCase().indexOf(resp) > -1))
    // );

    // filter the banks
    this.contentTypes$.next(
      contentTypesCopy.filter(type => {
        type.types = type.types.filter(t => t.name.toLowerCase().indexOf(resp) > -1);
        return type.types.length > 0;
      })
    );

    this.contentTypes$.asObservable().subscribe(res => {
      console.log("search", res)
      console.log(this.contentTypes)
    });
  }

  protected copyContentTypesGroups(contentTypes: any) {
    const contentTypesCopy = [];
    contentTypes.forEach(type => {
      contentTypesCopy.push({
        channel_platform_id: type.channel_platform_id,
        types: type.types.slice()
      });
    });
    return contentTypesCopy;
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.urls = this.urls.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent(null, 'Dropped or resized', event);
  }

  checkIfAllUrlsFact(arr) {
    return arr.filter(x => !x.contentSchedule).length == arr.length
  }

  refreshPage() {
    this.filterUrls();
  }

  handleEvent(e, action: string, event: any): void {
    // this.modalData = { event, action };
    console.log("event", event);

    console.log('e', e)
    if (e && e.sourceEvent && (e.sourceEvent.ctrlKey || e.sourceEvent.metaKey)) {
      if (!e.sourceEvent.target.closest('.no_select')) {
        console.log("(ctrl || command) + click", e.sourceEvent);
        e.sourceEvent.preventDefault();
        this.profileCollection.toggle(event);
        return;
      }
    }
    console.log(this.modalData)
    if (event.content_url) {
      window.open(event.content_url, '_blank')
    }
    // this.modal.open(this.modalContent, { size: 'lg' });
  }

  setView(view: MatButtonToggleChange) {
    this.view.patchValue(view.value);

    if (this.view.value == 'list') {
      if (this.is_mobile) {
        this.onMobScroll()
      } else {
        this.onScroll();
      }
    }
  }

  closeOpenMonthViewDay() {
    
    this.activeDayIsOpen = false;
    const getStart: any = {
      month: startOfMonth,
      week: startOfWeek,
      day: startOfDay,
    }[this.view.value];

    const getEnd: any = {
      month: endOfMonth,
      week: endOfWeek,
      day: endOfDay,
    }[this.view.value];
    console.log("change viewDate", getStart(this.viewDate), getEnd(this.viewDate));
    
    if (this.view.value == "week") {
      this.filter.patchValue({
        content_published_at_from : moment(moment(startOfWeek(this.viewDate, {weekStartsOn: 1})).unix() * 1000),
        content_published_at_to : moment(moment(endOfWeek(this.viewDate, {weekStartsOn: 1})).unix() * 1000),
      })
    } else {
      this.filter.patchValue({
        content_published_at_from : moment(moment(getStart(this.viewDate)).unix() * 1000),
        content_published_at_to : moment(moment(getEnd(this.viewDate)).unix() * 1000),
      })
    }
      
    // moment
    console.log("change viewDate moment", moment(moment(getStart(this.viewDate)).unix() * 1000), moment(moment(getEnd(this.viewDate)).unix() * 1000));

    this.urls = [];
    this.contents = [];
    console.log(this.filter.value)
    this.filterUrls();
  }

  onSearchProfiles(resp) {
    if (!this.platformProfiles) {
      return;
    }

    if (!resp) {
      this.platformProfiles$.next(this.platformProfiles.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    // filter the banks
    this.platformProfiles$.next(
      this.platformProfiles.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  onSearchPlatforms(resp) {
    if (!this.platforms) {
      return;
    }

    if (!resp) {
      this.platforms$.next(this.platforms.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    // filter the banks
    this.platforms$.next(
      this.platforms.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }
  // onSearchPartners(resp) {
  //   if (!this.partners) {
  //     return;
  //   }

  //   if (!resp) {
  //     this.partners$.next(this.partners.slice());
  //     return;
  //   } else {
  //     resp = resp.toLowerCase();
  //   }
  //   // filter the banks
  //   this.partners$.next(
  //     this.partners.filter(z => z.partnerCompany.name.toLowerCase().indexOf(resp) > -1)
  //   );
  // }

  getProfilesStatus() {
    this.attachSubscriptions(
      this.globalDataService.getContentStatus().subscribe(resp => {
        console.log("getContentStatus", resp);
        this.profileStatuses = resp.slice();
      })
    )
  }

  // onSearchEmoloyees(resp) {
  //   if (!this.employees) {
  //     return;
  //   }

  //   if (!resp) {
  //     this.employees$.next(this.employees.slice());
  //     return;
  //   } else {
  //     resp = resp.toLowerCase();
  //   }
  //   // filter the banks
  //   this.employees$.next(
  //     this.employees.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
  //   );
  // }

  onSearchGroups(resp) {
    if (!this.groups) {
      return;
    }

    if (!resp) {
      this.groups$.next(this.groups.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    // filter the banks
    this.groups$.next(
      this.groups.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  changeTab(val) {
    this.selectedIndex.patchValue(val);
  }

  changeSelectionList(e, key) {
    console.log(e, key)
    console.log(e.option._value)

    this.filter.patchValue({
      [key]: e.option._value
    })
    // this.closeOpenClientFilter();
    // this.closeExecFilter();
    this.filterUrls();
  }

  refresh() {
    this.attachSubscriptions(
      this.refreshService.getRefreshUrls().pipe(
        concatMap(res => {
          if ( (this.urls.filter(x => x.id == res.url_id).length > 0 || !!res.new) && this.company_id == res.company_id ) {
            return this.taskService.getExpandUrls("1", this.company_id, {id: res.url_id}, "1").pipe(
              map(x => x.body[0]),
              switchMap(channel => {
                let arr = [];            
                if (!!this.showAddOptions.value) {
                  arr.push(this.taskService.getPostExtra(1, this.company_id, {task_channel_id: channel.id}).pipe(
                    map(x => x.body),
                    tap(extras => {
                      channel.extras = extras
                    })
                  ))
                }
            
                if (channel.external_content_id) {
                  let filterData:any = {
                    start_time_from: channel.content_published_at,
                    totals: '1',
                    order_by: 'start_time',
                    'per-page': 1,
                    content_id: channel.external_content_id,
                    period: 'day'
                  }
            
                  arr.push(this.taskService.getContentAnlytics(this.company_id, filterData, "1").pipe(
                    tap(el => {
                      channel.summaryAveragePercentageViewed = Math.round(+el.headers.get('x-summary-average-view-percentage'))
                      channel.summaryAverageViewDuration = +el.headers.get('x-summary-average-view-duration')
                    })
                  ))
            
                }

                let count = 0;
                if (channel.task && channel.task.meta_channel && channel.task.meta_channel.task_channels_by_platform) {
               
                  channel.task.meta_channel.task_channels_by_platform_arr = Object.values(channel.task.meta_channel.task_channels_by_platform).filter((x:any) => !!x.platform_id);
                  channel.task.meta_channel.posts_waiting_count = 0;
                  channel.task.meta_channel.posts_published_count = 0;
        
                  channel.task.meta_channel.task_channels_by_platform_arr.forEach(p => {
                    channel.task.meta_channel.posts_waiting_count += p.waiting;
                    channel.task.meta_channel.posts_published_count += p.published;
                  });
                  channel.task.meta_channel.posts_count = channel.task.meta_channel.posts_waiting_count + channel.task.meta_channel.posts_published_count;
        
                  channel.task.meta_channel.task_channels_by_platform_arr.push({
                    platform_id: 0,
                    waiting: channel.task.meta_channel.posts_waiting_count,
                    published: channel.task.meta_channel.posts_published_count
                  })

                  count = channel.task.meta_channel.posts_waiting_count + channel.task.meta_channel.posts_published_count;  
                }
                if (channel.task && count < 14) {
                  arr.push(
                    this.taskService.getExpandUrls('1', this.company_id, {task_id: channel.task.id}, '50').pipe(
                      map(x => x.body.filter(x => x.id != channel.id)),
                      tap(res => {
                        channel.taskChannels = [...res];
                        // channel.groupedChannels = [];
                        // res.forEach(el => {
                        //   if (channel.groupedChannels.filter(x => x.platform_id == el.channel.platform_id).length == 0) {
                        //     channel.groupedChannels.push({
                        //       platform_id: el.channel.platform_id,
                        //       platform: el.channel.platform,
                        //       published: el.content_status_id == 4 ? [el] : [],
                        //       published_content_views_count: el.content_status_id == 4 ? (!!el.content_views_count ? +el.content_views_count : 0) : 0,
                        //       not_published: el.content_status_id != 4 ? [el] : [],
                        //       not_published_content_views_count: el.content_status_id != 4 ? (!!el.content_views_count ? +el.content_views_count : 0) : 0,
                        //     })
                        //   } else {
                        //     if (el.content_status_id == 4) {
                        //       channel.groupedChannels.find(x => x.platform_id ==  el.channel.platform_id).published.push(el);
                        //       channel.groupedChannels.find(x => x.platform_id ==  el.channel.platform_id).published_content_views_count = +channel.groupedChannels.find(x => x.platform_id ==  el.channel.platform_id).published_content_views_count + (!!el.content_views_count ? +el.content_views_count : 0);
                        //     } else {
                        //       channel.groupedChannels.find(x => x.platform_id ==  el.channel.platform_id).not_published_content_views_count = +channel.groupedChannels.find(x => x.platform_id ==  el.channel.platform_id).not_published_content_views_count + (!!el.content_views_count ? +el.content_views_count : 0);
                        //       channel.groupedChannels.find(x => x.platform_id ==  el.channel.platform_id).not_published.push(el);
                        //     }
                        //   }
                        // });
                      })
                    )
                  )
                }
                arr.push(
                  this.taskService.getHoursChart(this.company_id, channel.id, {
                    start_time_from: moment().subtract(48, 'h').format("X"),
                    period: 'hour',
                    totals: '1',
                    order_by: 'start_time'
                  }).pipe(
                    tap(x => {
                      channel.timeChartSumm = x.headers.get('x-summary-views')
                      for (let index = 0; index < x.body.length; index++) {
                        let element = x.body[index];
                        element.value = element.views_count
                        element.date = moment(element.start_time * 1000).clone()
                        if (moment(element.start_time * 1000).clone().isAfter(moment().hours(0).minutes(0).seconds(0)) || moment(element.start_time * 1000).clone().isSame(moment().hours(0).minutes(0).seconds(0))) {
                          element.name = moment(element.start_time * 1000).clone().format("hh:mm a") + " \u2013 " + moment(element.end_time * 1000).clone().format("hh:mm a");
                        } else if (moment(element.start_time * 1000).clone().year() == moment().year()) {
                          element.name = moment(element.start_time * 1000).clone().format("MM/DD, hh:mm a") + " \u2013 " + moment(element.end_time * 1000).clone().format("hh:mm a");
                        } else {
                          element.name = moment(element.start_time * 1000).clone().format("MM/DD/YYYY, hh:mm a") + " \u2013 " + moment(element.end_time * 1000).clone().format("hh:mm a");
                        }
                      }
                        let model = [];
              
                      for (let i:number = 1; i <= 48; i++) {
                        let date = moment().minutes(0).seconds(0).clone().subtract(48-i, 'h')
                        if (x.body.filter(u => date.isSameOrAfter(moment(u.start_time * 1000)) && date.clone().subtract(1, 's').isSameOrBefore(moment(u.end_time * 1000))).length == 0) {
                          let name = '';
                          if (date.clone().isSameOrAfter(moment().hours(0).minutes(0).seconds(0))) {
                            name = date.clone().format("hh:mm a") + " \u2013 " + date.clone().add(1,'h').subtract(1, 's').format("hh:mm a");
                          } else if (date.clone().year() == moment().year()) {
                            name = date.clone().format("MM/DD, hh:mm a") + " \u2013 " + date.clone().add(1,'h').subtract(1, 's').format("hh:mm a");
                          } else {
                            name = date.clone().format("MM/DD/YYYY, hh:mm a") + " \u2013 " + date.clone().add(1,'h').subtract(1, 's').format("hh:mm a");
                          }
                          model.push({
                            index: i,
                            hour: i - 48,
                            date: date,
                            name: name,
                            value: 0
                          })
                        } else {
                          model.push(x.body.find(u => date.isSameOrAfter(moment(u.start_time * 1000)) && date.clone().subtract(1, 's').isSameOrBefore(moment(u.end_time * 1000))))
                        }
                      }
                      channel.timeChart = [...model]
                    }),
                  )
                )
            
                return forkJoin(arr).pipe(map(() => channel))
              }),
              tap(x => {
                if (res.new) {
                  this.urls.push(x)
                } else {
                  this.urls.splice(this.urls.indexOf(this.urls.find(b => b.id == x.id)), 1, x)
                }
              })
            )
          } else {
            return of(false)
          }    
        })
      ).subscribe(resp => {
        console.log("getRefreshUrls", resp)
      })
    )
  }

  // openCheck(job, check) {
  //   check.open = !check.open

  //   if (check.open) {
  //     this.attachSubscriptions(
  //       this.fileService.getFilesForCheck(this.company_id, job.id, check.id, '/ready_files', '200').subscribe(res => {
  //         check.files = res.body
  //         console.log(check.files)
  //       })
  //     )
  //   }
  // }

  
  // understandFileType(val) {
  //   if (!val) {
  //     return ""
  //   }
  //   if (val.indexOf("/") != -1) {
  //     return val.split('/')[0]
  //   } else {
  //     return ""
  //   }
  // }

  // deleteFile(file, check, ind) {
  //   let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
  //     hasBackdrop: true,
  //     backdropClass: 'bottom-sheed-backdrop',
  //     data: {
  //       msg: marker("Are you sure you want to unlink the file from this checklist item?")
  //     }
  //   });

  //   deleteAlert.afterDismissed().subscribe( data => {
  //     if (data && data.message == 'no') {
  //       console.log("no");
  //       return
  //     } else if (data && data.message == 'yes') {
  //       this.attachSubscriptions(
  //         this.fileService.editFile(file.id, {operation_reminder_id: '0'}, this.company.id).subscribe(resp => {
  //           check.files.splice(ind, 1)
  //         })
  //       )
  //     }
  //   });
  // }

  // onListSelectionChange(val: MatCheckboxChange, check, job) {
  //   check.disable = true;
  //   if (!check.status) {
  //     this.attachSubscriptions(
  //       this.taskService.createOperationReminderStatus(job.id, check.id).subscribe(resp => {
  //         check.status = resp;
  //         check.disable = false;
  //         console.log(check)
  //         // if (job.checklist.filter(x => x.status && x.status.is_done == 1).length == job.checklist.length || (job.checklist.length == 0 || !job.checklist)) {
  //         //   this.taskService.editWork(job.id, {is_reminder: 0}, this.company_id).subscribe(resp => {
  //         //     console.log(resp)
  //         //     job.is_reminder = resp.is_reminder
  //         //   })
  //         // }
  //       }, error => {
  //         delete check.status;
  //         val.source.toggle()
  //         this.layoutService.showSnackBar({name: ''}, marker("You do not have permission to do this. Error: " + error), SnackBarItem)
          
  //       })
  //     )
  //   } else {
  //     // if (job.checklist.filter(x => x.status && x.status.is_done == 1).length == job.checklist.length || (job.checklist.length == 0 || !job.checklist)) {
  //     //   this.taskService.editWork(job.id, {is_reminder: 1}, this.company_id).subscribe(resp => {
  //     //     console.log(resp)
  //     //     job.is_reminder = resp.is_reminder
  //     //   })
  //     // }
  //     this.attachSubscriptions(
  //       this.taskService.deleteOperationReminderStatus(check.status.id).subscribe(resp => {
  //         check.status = resp
  //         check.disable = false;
  //         console.log(check)
  //       })
  //     )
  //   }

  //   console.log("afterChange", job.checklist)
  // }

  // uploadFileBtn(job, check) {
  //   if (!check.hasOwnProperty('files')) {
  //     check.files = [];
  //   }
  //   this.selectedCheck = check
  //   this.selectedJob = job
  //   this.checkUploader.nativeElement.click()
  // }


  // uploadFile(event) {
  //   if (this.uploadLimit) {
  //     this.layoutService.showSnackBar({name: ''}, marker("This company has exceeded the file upload limit."), SnackBarItem)
  //     return
  //   }
  //   if (event.target.files.length > 0) {
  //     this.selectedCheck.filesCount = (this.selectedCheck.filesCount ? this.selectedCheck.filesCount : 0) + event.target.files.length
  //     for (let index = 0; index < event.target.files.length; index++) {
  //       const element = event.target.files[index];
  //       this.putFile(element, false, "/ready_files");
  //     }
  //   } 
  // }

  // putFile(file, is_folder:boolean = false, initPath:any = false) {
  //   let path = '/ready_files'

  //   this.fileService.uploadFile({
  //     company_id: this.company.id,
  //     task_id: this.selectedJob.task_id,
  //     task_operation_id: this.selectedJob.id,
  //     filesize: file.size,
  //     filename: file.name,
  //     operation_reminder_id: this.selectedCheck.id,
  //     content_type: file.type ? file.type : mimeTypes.getType(file.name),
  //     location: path,
  //     is_dir: 0
  //   }, this.company.id).subscribe(resp => {
  //     console.log("putFile", this.selectedCheck)
  //     this.fileService.files$.next({
  //       place: "file_manager",
  //       url: window.location.href,
  //       data: resp,
  //       files: this.selectedCheck.files,
  //       location: path,
  //       target: file,
  //       operation_reminder: this.selectedCheck,
  //       task_id: this.selectedJob.task_id,
  //       work_id: this.selectedJob.id,
  //       company_id: this.company.id,
  //       user: this.user
  //     })
  //     // this.selectedJob = undefined
  //   })
  // }

  // selectFilesFromJob(task, job, check) {
  //   const dialogRef = this.dialog.open(SelectFilesForCheckComponent, {
  //     data: {
  //       company: this.company,
  //       task: task,
  //       work: job,
  //       user: this.user,
  //       check: check,
  //     }
  //   });

  //   this.attachSubscriptions(
  //     dialogRef.afterClosed().pipe(
  //       switchMap(() => this.fileService.getFilesForCheck(this.company_id, job.id, check.id, '/ready_files', '200'))
  //     ).subscribe(result => {
  //       check.files = result.body
  //     })
  //   )
  // }

  // onFileDropped(e, check, job) {
  //   console.log("onFileDropped", e, check, job)

  //   this.getFilesDataTransferItems(e.dataTransfer.items).then((files:any) => {
  //     if (files.length > 0) {
  //       for (let index = 0; index < files.length; index++) {
  //         this.company.filesize = this.company.filesize + files[index].size
  //       }
  //     }
  //   })

  //   if (this.uploadLimit) {
  //     this.layoutService.showSnackBar({name: ''}, marker("This company has exceeded the file upload limit."), SnackBarItem)
  //     return
  //   }
  //   if (!e.dataTransfer.files.length) {
  //     return false;
  //   }

  //   this.selectedCheck = check;
  //   this.selectedJob = job;

  //   this.getFilesDataTransferItems(e.dataTransfer.items).then((files:any) => {
  //     if (files.length > 0) {
  //       for (let index = 0; index < files.length; index++) {
  //         this.putFile(files[index]);
  //       }
  //     }
  //   });
    
  //   if (this.company.filesize >= this.company.filesize_limit) {
  //     this.uploadService.uploadLimit$.next(true)
  //   }
  // }
  // getFilesDataTransferItems(dataTransferItems) {
  //   function traverseFileTreePromise(item, folder) {
  //     return new Promise(resolve => {
  //       if (item.isFile) {
  //         item.file(file => {
  //           file.filepath = item.fullPath.replace(`/${file.name}`, "");
  //           folder.push(file);
  //           resolve(file);
  //         });
  //       } else if (item.isDirectory) {
  //         let dirReader = item.createReader();
  //         dirReader.readEntries(entries => {
  //           let entriesPromises = [];
  //           for (let entr of entries)
  //             entriesPromises.push(
  //               traverseFileTreePromise(entr, folder)
  //             );
  //           resolve(Promise.all(entriesPromises));
  //         });
  //       }
  //     });
  //   }
  
  //   let files = [];
  //   return new Promise((resolve, reject) => {
  //     let entriesPromises = [];
  //     for (let it of dataTransferItems)
  //       entriesPromises.push(
  //         traverseFileTreePromise(it.webkitGetAsEntry(), files)
  //       );
  //     Promise.all(entriesPromises).then(entries => {
  //       resolve(files);
  //     });
  //   });
  // }

  // detectBrowserName() { 
  //   const agent = window.navigator.userAgent.toLowerCase()
  //   switch (true) {
  //     case agent.indexOf('edge') > -1:
  //       return 'edge';
  //     case agent.indexOf('opr') > -1 && !!(<any>window).opr:
  //       return 'opera';
  //     case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
  //       return 'chrome';
  //     case agent.indexOf('trident') > -1:
  //       return 'ie';
  //     case agent.indexOf('firefox') > -1:
  //       return 'firefox';
  //     case agent.indexOf('safari') > -1:
  //       return 'safari';
  //     default:
  //       return 'other';
  //   }
  // }

  neededData(task) {
    let arr = [
      ...task.operations.map(x => this.taskService.getOperationReminder(this.company_id, {task_operation_id: x.id}).pipe(
        tap(val => {
          let checklist = val.body;
          let jobParameters = task.parameterValuesToTask.filter(x => !!x.task_operation_id && !x.discussion_id && !x.file_id)
          x.checklist = [];
          checklist.forEach(element => {
            if (element.exceptParameterValues.length > 0) {
              if (element.exceptParameterValues.map(b => b.parameter_value_id).every(n => !jobParameters.map(m => m.parameter_value_id).includes(n))) {
                x.checklist.push(element)
              }
            } else if (element.toParameterValues.length > 0) {
              if (element.toParameterValues.map(b => b.parameter_value_id).some(n => jobParameters.map(m => m.parameter_value_id).includes(n))) {
                x.checklist.push(element)
              }
            } else {
              x.checklist.push(element)
            }
          });

          if (x.checklist.length == 0 && x.checklist.filter(b => !!b.status).length == x.checklist.length) {
            x.hasChecklistError = true;
          }
        })
      )),
      this.chatService.getTasksChats(this.company_id, [task.id]).pipe(
        tap(res => {
          task['operations'].map(o_el => {
            o_el['chatsInfo'] = [];
            o_el['chatsInfo'].push(...res.filter(z => z.task_operation_id == o_el.id));
            o_el['unReadChats'] = res.filter(z => z.task_operation_id == o_el.id && z.is_read != '1').length;
            o_el['unClosedChats'] = res.filter(z => z.task_operation_id == o_el.id && z.status_id != '4').length;
          });
          task['chatsInfo'] = [];
          task['chatsInfo'].push(...res.filter(z => z.task_id == task.id));
          task['unReadChats'] = res.filter(z => z.is_read != '1').length;
          task['unClosedChats'] = res.filter(z => z.status_id != '4').length;
        })
      ),
      ...task.operations.filter(x => !x.hasChecklistError).map(x => this.fileService.getFilesForOpenTask(this.company_id, x.id, '/ready_files', '6').pipe(
        tap(val => x.files = val.body)
      ))
    ]
    // if (task.company_id == this.company.id) {
    //   arr.push(
    //     this.taskService.getTaskClients(task.id).pipe(
    //       tap(res => {
    //         task.clients = res
    //       }),
    //       catchError(err => {
    //         return err
    //       })
    //     )
    //   )
    // }
    return forkJoin(arr)
  }

  understandFileType(val) {
    if (!val) {
      return ""
    }
    
    if (val == 'application/pdf') {
      return 'pdf'
    } else if (val.indexOf("/") != -1) {
      return val.split('/')[0]
    } else {
      return ""
    }
  }

  setContType(val) {
    this.filter.patchValue({
      content_type_id: val
    })
    this.filterUrls();
  }

  neededPostData(channel) {
    let arr = []
    // if (post.company_id == this.company.id) {
    //   arr.push(
    //     this.taskService.getTaskClients(task.id).pipe(
    //       tap(res => {
    //         task.clients = res
    //       }),
    //       catchError(err => {
    //         return err
    //       })
    //     )
    //   )
    // }

    if (channel.taskChannelFiles && channel.taskChannelFiles.length > 0 && !channel.file) {
      if (channel.taskChannelFiles.filter(x => this.understandFileType(x.originalFile.content_type) == 'video').length > 0) {
        channel.file = channel.taskChannelFiles.find(x => this.understandFileType(x.originalFile.content_type) == 'video').originalFile;
      } else {
        channel.file = channel.taskChannelFiles.filter(x => this.understandFileType(x.originalFile.content_type) != 'audio')[0].originalFile;
      }
    }

    if (!!this.showAddOptions.value) {
      arr.push(this.taskService.getPostExtra(1, this.company_id, {task_channel_id: channel.id}).pipe(
        map(x => x.body),
        tap(extras => {
          channel.extras = extras
        })
      ))
    }

    if (channel.external_content_id) {
      let filterData:any = {
        start_time_from: channel.content_published_at,
        totals: '1',
        order_by: 'start_time',
        'per-page': 1,
        content_id: channel.external_content_id,
        period: 'day'
      }

      arr.push(this.taskService.getContentAnlytics(this.company_id, filterData, "1").pipe(
        tap(el => {
          channel.summaryAveragePercentageViewed = Math.round(+el.headers.get('x-summary-average-view-percentage'))
          channel.summaryAverageViewDuration = +el.headers.get('x-summary-average-view-duration')
        })
      ))

    }
    let count = 0;
    if (channel.task && channel.task.meta_channel && channel.task.meta_channel.task_channels_by_platform) {
               
      channel.task.meta_channel.task_channels_by_platform_arr = Object.values(channel.task.meta_channel.task_channels_by_platform).filter((x:any) => !!x.platform_id);
      channel.task.meta_channel.posts_waiting_count = 0;
      channel.task.meta_channel.posts_published_count = 0;

      channel.task.meta_channel.task_channels_by_platform_arr.forEach(p => {
        channel.task.meta_channel.posts_waiting_count += p.waiting;
        channel.task.meta_channel.posts_published_count += p.published;
      });
      channel.task.meta_channel.posts_count = channel.task.meta_channel.posts_waiting_count + channel.task.meta_channel.posts_published_count;

      channel.task.meta_channel.task_channels_by_platform_arr.push({
        platform_id: 0,
        waiting: channel.task.meta_channel.posts_waiting_count,
        published: channel.task.meta_channel.posts_published_count
      })

      count = channel.task.meta_channel.posts_waiting_count + channel.task.meta_channel.posts_published_count;  
    }
    if (channel.task && count < 14) {
      arr.push(
        this.taskService.getExpandUrls('1', this.company_id, {task_id: channel.task.id}, '50').pipe(
          map(x => x.body.filter(x => !!x.channel && x.id != channel.id)),
          tap(res => {
            channel.taskChannels = [...res];
            // channel.groupedChannels = [];
            // res.forEach(el => {
            //   if (channel.groupedChannels.filter(x => x.platform_id == el.channel.platform_id).length == 0) {
            //     channel.groupedChannels.push({
            //       platform_id: el.channel.platform_id,
            //       platform: el.channel.platform,
            //       published: el.content_status_id == 4 ? [el] : [],
            //       published_content_views_count: el.content_status_id == 4 ? (!!el.content_views_count ? +el.content_views_count : 0) : 0,
            //       not_published: el.content_status_id != 4 ? [el] : [],
            //       not_published_content_views_count: el.content_status_id != 4 ? (!!el.content_views_count ? +el.content_views_count : 0) : 0,
            //     })
            //   } else {
            //     if (el.content_status_id == 4) {
            //       channel.groupedChannels.find(x => x.platform_id ==  el.channel.platform_id).published.push(el);
            //       channel.groupedChannels.find(x => x.platform_id ==  el.channel.platform_id).published_content_views_count = +channel.groupedChannels.find(x => x.platform_id ==  el.channel.platform_id).published_content_views_count + (!!el.content_views_count ? +el.content_views_count : 0);
            //     } else {
            //       channel.groupedChannels.find(x => x.platform_id ==  el.channel.platform_id).not_published_content_views_count = +channel.groupedChannels.find(x => x.platform_id ==  el.channel.platform_id).not_published_content_views_count + (!!el.content_views_count ? +el.content_views_count : 0);
            //       channel.groupedChannels.find(x => x.platform_id ==  el.channel.platform_id).not_published.push(el);
            //     }
            //   }
            // });
          })
        )
      )
    }
    arr.push(
      this.taskService.getHoursChart(this.company_id, channel.id, {
        start_time_from: moment().subtract(48, 'h').format("X"),
        period: 'hour',
        totals: '1',
        order_by: 'start_time'
      }).pipe(
        tap(x => {
          channel.timeChartSumm = x.headers.get('x-summary-views')
          for (let index = 0; index < x.body.length; index++) {
            let element = x.body[index];
            element.value = element.views_count
            element.date = moment(element.start_time * 1000).clone()
            if (moment(element.start_time * 1000).clone().isAfter(moment().hours(0).minutes(0).seconds(0)) || moment(element.start_time * 1000).clone().isSame(moment().hours(0).minutes(0).seconds(0))) {
              element.name = moment(element.start_time * 1000).clone().format("hh:mm a") + " \u2013 " + moment(element.end_time * 1000).clone().format("hh:mm a");
            } else if (moment(element.start_time * 1000).clone().year() == moment().year()) {
              element.name = moment(element.start_time * 1000).clone().format("MM/DD, hh:mm a") + " \u2013 " + moment(element.end_time * 1000).clone().format("hh:mm a");
            } else {
              element.name = moment(element.start_time * 1000).clone().format("MM/DD/YYYY, hh:mm a") + " \u2013 " + moment(element.end_time * 1000).clone().format("hh:mm a");
            }
          }
            let model = [];
  
          for (let i:number = 1; i <= 48; i++) {
            let date = moment().minutes(0).seconds(0).clone().subtract(48-i, 'h')
            if (x.body.filter(u => date.isSameOrAfter(moment(u.start_time * 1000)) && date.clone().subtract(1, 's').isSameOrBefore(moment(u.end_time * 1000))).length == 0) {
              let name = '';
              if (date.clone().isSameOrAfter(moment().hours(0).minutes(0).seconds(0))) {
                name = date.clone().format("hh:mm a") + " \u2013 " + date.clone().add(1,'h').subtract(1, 's').format("hh:mm a");
              } else if (date.clone().year() == moment().year()) {
                name = date.clone().format("MM/DD, hh:mm a") + " \u2013 " + date.clone().add(1,'h').subtract(1, 's').format("hh:mm a");
              } else {
                name = date.clone().format("MM/DD/YYYY, hh:mm a") + " \u2013 " + date.clone().add(1,'h').subtract(1, 's').format("hh:mm a");
              }
              model.push({
                index: i,
                hour: i - 48,
                date: date,
                name: name,
                value: 0
              })
            } else {
              model.push(x.body.find(u => date.isSameOrAfter(moment(u.start_time * 1000)) && date.clone().subtract(1, 's').isSameOrBefore(moment(u.end_time * 1000))))
            }
          }
          channel.timeChart = [...model]
          // channel.timeChart = [...x.body]
        }),
      )
    )

    return forkJoin(arr)
  }

  onStartDateCreatedChange(e: MatDatepickerInputEvent<Date, Date>) {
    if (e && e.value) {
      this.dateFromCreatedChanged = true;
    }
    this.checkCreatedRange();
  }

  onEndDateCreatedChange(e: MatDatepickerInputEvent<Date, Date>) {
    if (e && e.value) {
      this.dateToCreatedChanged = true;
    }
    this.checkRange();
  }
  checkCreatedRange(): void {
    if (this.dateFromCreatedChanged || this.dateToCreatedChanged) {
      this.dateFromCreatedChanged = false;
      this.dateToCreatedChanged = false;
      this.filterUrls()
    }
  }

  onStartDateChange(e: MatDatepickerInputEvent<Date, Date>) {
    if (e && e.value) {
      this.dateFromChanged = true;
    }
    this.checkRange();
  }

  onEndDateChange(e: MatDatepickerInputEvent<Date, Date>) {
    if (e && e.value) {
      this.dateToChanged = true;
    }
    this.checkRange();
  }

  checkRange(): void {
    if (this.dateFromChanged || this.dateToChanged) {
      this.dateFromChanged = false;
      this.dateToChanged = false;
      this.filterUrls()
    }
  }

  selectChannelThumb(channel) {
    let x:any = {
      company: this.company,
      task: channel.task,
      channel: channel,
      work: undefined,
      user: this.user,
      thumbFile: channel.file,
      only: 'image',
      is_multi: false,
      no_matches: true
    }
    // if (is_photo) {
    //   x.selectedFiles = this.morePhotos;
    //   x.thumbFile = this.data.file;
    // }

    const dialogRef = this.dialog.open(SelectFileComponent, {
      data: x
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log(result);
        if (!!result && result.event == 'select' && result.data) {
          this.taskService.setChannelThumbnail({
            task_channel_id: channel.id,
            thumbnail_file_id: result.data.id
          }).subscribe(resp => {
            this.refreshService.refreshUrls$.next({url_id: channel.id, company_id: this.company_id})
          }, error => {
            this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
          })
        }
      })
    )
  }

  copyLink(type) {
    this.layoutService.showSnackBar(marker(type + " link"), marker("Copied"), SnackBarItem)
  }

  copyData(type) {
    this.layoutService.showSnackBar(marker(type), marker("Copied"), SnackBarItem)
  }

  addWork(element) {
    if (!this.is_mobile) {
      const dialogRef = this.dialog.open(WorkAddComponent, {
        disableClose: true,
        data: {
          user: this.user,
          company: this.company,
          company_id: this.company_id,
          task: element
        }
      });
  
      this.attachSubscriptions(
        dialogRef.afterClosed().subscribe(result => {
          
          if (!!result && result.event == "update") {
            this.refreshService.refreshTask$.next({company_id: this.company_id, task_id: element.id})
          }
        })
      )
    } else {
      const dialogRef = this.dialog.open(MobAddJobComponent, {
        backdropClass: ['mob_interface_backdrop'],
        panelClass: ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'],
        disableClose: true,
        data: {
          user: this.user,
          company: this.company,
          company_id: this.company_id,
          task: element
        }
      });
      
      this.attachSubscriptions(
        dialogRef.afterClosed().subscribe(result => {
          
          if (!!result && result.event == "update") {
            this.refreshService.refreshTask$.next({company_id: this.company_id, task_id: element.id})
          }
        })
      )
    }
  }

  
  mobEditTaskGroup(task) {
    const dialogRef = this.dialog.open(MobTaskEditGroupComponent, {
      backdropClass: ['mob_interface_backdrop'],
      panelClass: ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'],
      data: {
        company: this.company,
        task: task,
        user: this.user,
        target: 'task',
        // array: this.groups,
        control: 'group_id',
        title: 'Edit Card group'
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          if (result.event == 'back') {
            this.goToEditTask(task)
          } else if (result.event == 'apply') {
            if (task && task.channels && task.channels.length && result.data.group_id != task.group_id) {
              let deletedChannelsData = [];
              task.channels.filter(x => x.content_status_id == 1 && !x.content_url).forEach(channel => {
                deletedChannelsData.push({
                  "path": `/api/task-channel/${channel.id}/`,
                  "query": {},
                  "method": "DELETE",
                  "body": ''
                });
              });
              this.taskService.multiRequest(deletedChannelsData).pipe(
                switchMap(() => this.taskService.editTask(task.id, result.data, this.company.id))
              ).subscribe(resp => {
                this.refreshService.refreshTask$.next({company_id: this.company_id, task_id: task.id})
              })
            } else {
              this.taskService.editTask(task.id, result.data, this.company.id).subscribe(resp => {
                this.refreshService.refreshTask$.next({company_id: this.company_id, task_id: task.id})
              })
            }
          } else {
            this.refreshService.refreshTask$.next({company_id: this.company_id, task_id: task.id})
          }
        } else {
          this.refreshService.refreshTask$.next({company_id: this.company_id, task_id: task.id})
        }
      })
    )
  }
  
  mobTaskEditTitle(task, type_prop, type) {
    const dialogRef = this.dialog.open(MobTitleComponent, {
      backdropClass: ['mob_interface_backdrop'],
      panelClass: ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'],
      data: {
        company: this.company,
        task: task,
        user: this.user,
        type: type,
        type_prop: type_prop,
        title: 'Edit Task',
        target: 'task'
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        
        if (result) {
          if (result.event == 'back') {
            this.goToEditTask(task)
          } else {
            this.refreshService.refreshTask$.next({company_id: this.company_id, task_id: task.id})
          }
        } else {
          this.refreshService.refreshTask$.next({company_id: this.company_id, task_id: task.id})
        }
      })
    )
  }


  goToEditTask(element) {
    if (!this.is_mobile) {
      const dialogRef = this.dialog.open(TaskEditComponent, {
        disableClose: true,
        data: {
          company_id: this.company_id,
          task: element,
        }
      });
  
      this.attachSubscriptions(
        dialogRef.afterClosed().subscribe(result => {
          
          if (!!result && result.event == 'update') {
            this.refreshService.refreshTask$.next({company_id: this.company_id, task_id: element.id})
          }
        })
      )
    } else {
      const dialogRef = this.dialog.open(MobTaskEditComponent, {
        backdropClass: ['mob_interface_backdrop'],
        panelClass: ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'],
        disableClose: true,
        data: {
          company_id: this.company_id,
          task: element,
        }
      });
  
      this.attachSubscriptions(
        dialogRef.afterClosed().subscribe(result => {
          if (!!result) {

            this.refreshService.refreshTask$.next({company_id: this.company_id, task_id: element.id})
            switch (result.event) {
              case 'private_comment':
                this.mobTaskEditTitle(element, 'private_comment', "Hidden notes");
                break;
              case 'comment':
                this.mobTaskEditTitle(element, 'comment', "Brief Description");
                break;
              case 'name':
                this.mobTaskEditTitle(element, 'name', "Title");
                break;
              case 'priority':
                this.mobEditTaskPriority(element)
                break;
              case 'group':
                this.mobEditTaskGroup(element)
                break;

              default:
                break;
            }
          }
        })
      )
    }
  }

  mobEditTaskPriority(task) {
    const dialogRef = this.dialog.open(MobPriorityComponent, {
      backdropClass: ['mob_interface_backdrop'],
      panelClass: ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'],
      data: {
        company: this.company,
        task: task,
        user: this.user,
        target: 'task'
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          if (result.event == 'back') {
            this.goToEditTask(task)
          } else {
            this.refreshService.refreshTask$.next({company_id: this.company_id, task_id: task.id})
          }
        } else {
          this.refreshService.refreshTask$.next({company_id: this.company_id, task_id: task.id})
        }
      })
    )
  }

  deleteTask(task) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: task,
        target: marker("task")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.taskService.deleteTask(task.id).subscribe(resp => {
            this.refreshPage()
          })
        )
      }
    });

  }
  
  addEmplWork(element) {
    const dialogRef = this.dialog.open(WorkEmplAddComponent, {
      disableClose: true,
      data: {
        company: this.company,
        task: element
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        
        if (!!result && result.event == "update") {
          this.refreshService.refreshTask$.next({company_id: this.company_id, task_id: element.id})
        }
      })
    )
  }

  goToClients(task) {
    const dialogRef = this.dialog.open(TaskClientsComponent, {
      data: {
        company: this.company,
        company_id: this.company_id,
        task: task,
        user: this.user
      }
    });
    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
      })
    )
  }

  editGroup(element) {
    if (this.is_mobile) {
      this.mobEditTaskGroup(element)
    } else {
      const dialogRef = this.dialog.open(TaskGroupEditComponent, {
        disableClose: true,
        data: {
          company_id: this.company_id,
          company: this.company,
          task: element
        }
      });
  
      this.attachSubscriptions(
        dialogRef.afterClosed().subscribe(result => {
          
          if (!!result && result.event == "Add") {
            this.refreshService.refreshTask$.next({company_id: this.company_id, task_id: element.id})
          }
        })
      )
    }
  }
  
  addGroup(element) {
    const dialogRef = this.dialog.open(GroupAddComponent, {
      disableClose: true,
      data: {
        company_id: this.company_id,
        task: element
      }
    });
    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        
        if (!!result && result.event == "Add") {
          this.refreshService.refreshTask$.next({company_id: this.company_id, task_id: element.id})
        }
      })
    )
  }
  
  taskManagers(task) {
    const dialogRef = this.dialog.open(TaskManagersComponent, {
      data: {
        company: this.company,
        activeLang: this.activeLang,
        user: this.user,
        task: task
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
      })
    )
  }
  
  moveAllJobs(task) {
    const dialogRef = this.dialog.open(MoveAllCardJobsComponent, {
      data: {
        company: this.company,
        task: task,
        user: this.user,
      }
    });
  }
  
  checkIsManager(task, company, _user) { 
    return (task && task.managers && task.managers.filter(x => x.user_id == _user.id).length > 0) || (task.group && task.group.managers && task.group.managers.filter(x => x.user_id == _user.id).length > 0) || company?.permissions.includes('admin') || company?.permissions.includes('manager'); 
  }
  
  // noOperation(e, id, jobTypeSelect: MatSelect) {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   this.filter.patchValue({
  //     no_operation_id: id,
  //   })

  //   jobTypeSelect.close();
  //   this.filterUrls()
  // }

  // getContentTypes(platform_id, contentTypePage) {
  //   this.attachSubscriptions(
  //     this.companyService.getContentTypes(platform_id, contentTypePage).pipe(
  //       tap(el => {
  //         this.contentTypePagination = {
  //           'pageCount': el.headers.get('x-pagination-page-count'),
  //           'perPage': el.headers.get('x-pagination-per-page'),
  //           'totalCount': el.headers.get('x-pagination-total-count'),
  //           'currentPage': el.headers.get('x-pagination-current-page'),
  //         }
  //       }),
  //       map(el => el.body)
  //     ).subscribe(resp => {

  //       this.allContentTypes = resp;

  //       let arr = [];

  //       resp.forEach(el => {
  //         if (arr.filter(x => x.channel_platform_id == el.channel_platform_id).length == 0) {
  //           arr.push({
  //             channel_platform_id: el.channel_platform_id,
  //             types: [el]
  //           })
  //         } else {
  //           arr.find(x => x.channel_platform_id == el.channel_platform_id).types.push(el)
  //         }
  //       })

  //       this.contentTypes.push(...arr);
  //       this.contentTypes$.next(this.copyContentTypesGroups(this.contentTypes));
  //       this.contentTypePage = this.contentTypePage + 1;
  //       console.log("getContentTypes", this.contentTypePage, this.contentTypes)

  //       if (this.contentTypePagination.pageCount >= this.contentTypePage) {
  //         this.getContentTypes(platform_id, this.contentTypePage);
  //       }
  //     })
  //   )
  // }

  removeValue(val) {
    if (val == "all") {
      this.activeStatus = 0;
      this.filter.patchValue({
        count: '',
        q: '',
        content_status_id: '',
        no_operation_id: '',
        no_channel_id: [],
        views_update_interval: '',
        preset_id: '',
        scheduled_published_time_by: '',
        created_employee_id: [],
        task_parameter_value_id: [],
        task_operation_parameter_value_id : [],
        discussion_parameter_value_id: [],
        file_parameter_value_id : [],
        parameter_value_id: [],
        operation_employee_id: '',
        is_content_url: '',
        task_created_at_from: "",
        task_created_at_to: "",
        task_operation_created_at_from: "",
        task_operation_created_at_to: "",
        task_operation_completed_at_from: "",
        task_operation_completed_at_to: "",
        discussion_created_at_from: "",
        discussion_created_at_to: "",
        file_created_at_from: "",
        file_created_at_to: "",
        file_updated_at_from: "",
        file_updated_at_to: "",
        file_meta_creation_time_from: "",
        file_meta_creation_time_to: "",
        task_channel_published_at_from: "",
        task_channel_published_at_to: "",
        operation_partner_company_id: '',
        is_live_stream_backup: '',
        client_employee_id: '',
        origin_company_id: '',
        task_operation_operation_id: '',
        task_custom_id: '',
        task_status_id: '',
        external_status: '',
        updated_employee_id: [],
        created_url_employee_id: [],
        updated_url_employee_id : [],
        channel_id: [],
        content_type_id: '',
        is_content_views_count_update: '',
        is_bravourl_content_views_count_update: '',
        group_id: [],
        channel_platform_id: '',
        created_at_from : '',
        created_at_to : '',
        summary_views_count_from: '',
        analytics_views_count_from : '',
        analytics_views_count_to : '',
        content_published_at_from : '',
        content_published_at_to : '',
        order_by: 'content_published_at_desc'
      })
      this.view.patchValue('list')
      this.sm.localStorageSetItem("url_view", 'list');
      this.filterReset()
    } else {
      if (['content_published_at_to', 'content_published_at_from'].includes(val)) {
        this.view.patchValue('list')
        this.sm.localStorageSetItem("url_view", 'list');
      }
      if (val.indexOf('employee_id') != -1) {
        this.filter.patchValue({
          [val]: []
        })
      } else {
        this.filter.patchValue({
          [val]: ''
        })
      }

      this.filterUrls()
    }
    
  }

  getPlatforms() {
    this.attachSubscriptions(
      this.companyService.getGroupPlatforms().subscribe(resp => {
        resp.unshift({
          id:	0,
          name: "Without platform",
          url: '',
          created_at:	0,
          updated_at:	0,
        })
        this.platforms = resp;
        this.platforms$.next(this.platforms.slice());
      })
    )
  }

  onRouteChange() {
    this.attachSubscriptions(
      this.activatedRoute.queryParams.pipe(
        tap((val) => {
          this.getCurrencyList();
          if (this.urlsSub) {
            this.urlsSub.unsubscribe()
          }
          if (this.contentSub) {
            this.contentSub.unsubscribe()
          }
          if (this.urlsDataSub) {
            this.urlsDataSub.unsubscribe();
          }
          if (this.postsDataSub) {
            this.postsDataSub.unsubscribe();
          }
          if (this.company_id != this.activatedRoute.snapshot.queryParamMap.get('company_id')) {
            this.getLangData(this.activatedRoute.snapshot.queryParamMap.get('company_id'));
          }
          this.company_id = this.activatedRoute.snapshot.queryParamMap.get('company_id');
          this.sortValue = !!this.activatedRoute.snapshot.queryParamMap.get('order_by') ? this.activatedRoute.snapshot.queryParamMap.get('order_by') : "content_published_at_desc";
          this.filter.patchValue({
            count: this.filterCount,
            q: !!this.activatedRoute.snapshot.queryParamMap.get('q') ? this.activatedRoute.snapshot.queryParamMap.get('q') : "",
            content_status_id: !!this.activatedRoute.snapshot.queryParamMap.get('content_status_id') ? +this.activatedRoute.snapshot.queryParamMap.get('content_status_id') : "",
            no_operation_id: !!this.activatedRoute.snapshot.queryParamMap.get('no_operation_id') ? +this.activatedRoute.snapshot.queryParamMap.get('no_operation_id') : "",
            no_channel_id: !!this.activatedRoute.snapshot.queryParamMap.get('no_channel_id') ? this.activatedRoute.snapshot.queryParamMap.get('no_channel_id').split(',').map(Number) : [],
            channel_id: !!this.activatedRoute.snapshot.queryParamMap.get('channel_id') ? this.activatedRoute.snapshot.queryParamMap.get('channel_id').split(',').map(Number) : [],
            views_update_interval: !!this.activatedRoute.snapshot.queryParamMap.get('views_update_interval') ? +this.activatedRoute.snapshot.queryParamMap.get('views_update_interval') : "",
            preset_id: !!this.activatedRoute.snapshot.queryParamMap.get('preset_id') ? +this.activatedRoute.snapshot.queryParamMap.get('preset_id') : "",
            scheduled_published_time_by: !!this.activatedRoute.snapshot.queryParamMap.get('scheduled_published_time_by') ? this.activatedRoute.snapshot.queryParamMap.get('scheduled_published_time_by') : "",
            created_employee_id: !!this.activatedRoute.snapshot.queryParamMap.get('created_employee_id') ? [+this.activatedRoute.snapshot.queryParamMap.get('created_employee_id')] : [],
            task_parameter_value_id: !!this.activatedRoute.snapshot.queryParamMap.get('task_parameter_value_id') ? this.activatedRoute.snapshot.queryParamMap.get('task_parameter_value_id').split(',').map(Number) : [],
            task_operation_parameter_value_id: !!this.activatedRoute.snapshot.queryParamMap.get('task_operation_parameter_value_id') ? this.activatedRoute.snapshot.queryParamMap.get('task_operation_parameter_value_id').split(',').map(Number) : [],
            discussion_parameter_value_id: !!this.activatedRoute.snapshot.queryParamMap.get('discussion_parameter_value_id') ? this.activatedRoute.snapshot.queryParamMap.get('discussion_parameter_value_id').split(',').map(Number) : [],
            file_parameter_value_id: !!this.activatedRoute.snapshot.queryParamMap.get('file_parameter_value_id') ? this.activatedRoute.snapshot.queryParamMap.get('file_parameter_value_id').split(',').map(Number) : [],
            parameter_value_id: !!this.activatedRoute.snapshot.queryParamMap.get('parameter_value_id') ? this.activatedRoute.snapshot.queryParamMap.get('parameter_value_id').split(',').map(Number) : [],
            operation_employee_id: !!this.activatedRoute.snapshot.queryParamMap.get('operation_employee_id') ? +this.activatedRoute.snapshot.queryParamMap.get('operation_employee_id') : '',
            is_content_url: !!this.activatedRoute.snapshot.queryParamMap.get('is_content_url') ? +this.activatedRoute.snapshot.queryParamMap.get('is_content_url') : '',
            client_employee_id: !!this.activatedRoute.snapshot.queryParamMap.get('client_employee_id') ? +this.activatedRoute.snapshot.queryParamMap.get('client_employee_id') : '',
            origin_company_id: !!this.activatedRoute.snapshot.queryParamMap.get('origin_company_id') ? +this.activatedRoute.snapshot.queryParamMap.get('origin_company_id') : '',
            task_operation_operation_id: !!this.activatedRoute.snapshot.queryParamMap.get('task_operation_operation_id') ? +this.activatedRoute.snapshot.queryParamMap.get('task_operation_operation_id') : '',
            task_custom_id: !!this.activatedRoute.snapshot.queryParamMap.get('task_custom_id') ? this.activatedRoute.snapshot.queryParamMap.get('task_custom_id') : '',
            operation_partner_company_id: !!this.activatedRoute.snapshot.queryParamMap.get('operation_partner_company_id') ? +this.activatedRoute.snapshot.queryParamMap.get('operation_partner_company_id') : '',
            is_live_stream_backup: this.activatedRoute.snapshot.queryParamMap.get('is_live_stream_backup') == '1' || this.activatedRoute.snapshot.queryParamMap.get('is_live_stream_backup') == '0' ? +this.activatedRoute.snapshot.queryParamMap.get('is_live_stream_backup') : '',
            task_status_id: !!this.activatedRoute.snapshot.queryParamMap.get('task_status_id') ? +this.activatedRoute.snapshot.queryParamMap.get('task_status_id') : '',
            external_status: !!this.activatedRoute.snapshot.queryParamMap.get('external_status') ? this.activatedRoute.snapshot.queryParamMap.get('external_status') : '',
            updated_employee_id: !!this.activatedRoute.snapshot.queryParamMap.get('updated_employee_id') ? [+this.activatedRoute.snapshot.queryParamMap.get('updated_employee_id')] : [],
            created_url_employee_id: !!this.activatedRoute.snapshot.queryParamMap.get('created_url_employee_id') ? [+this.activatedRoute.snapshot.queryParamMap.get('created_url_employee_id')] : [],
            updated_url_employee_id: !!this.activatedRoute.snapshot.queryParamMap.get('updated_url_employee_id') ? [+this.activatedRoute.snapshot.queryParamMap.get('updated_url_employee_id')] : [],
            content_type_id: !!this.activatedRoute.snapshot.queryParamMap.get('content_type_id') ? +this.activatedRoute.snapshot.queryParamMap.get('content_type_id') : "",
            is_content_views_count_update: !!this.activatedRoute.snapshot.queryParamMap.get('is_content_views_count_update') ? this.activatedRoute.snapshot.queryParamMap.get('is_content_views_count_update') : "",
            is_bravourl_content_views_count_update: !!this.activatedRoute.snapshot.queryParamMap.get('is_bravourl_content_views_count_update') ? this.activatedRoute.snapshot.queryParamMap.get('is_bravourl_content_views_count_update') : "",
            group_id: !!this.activatedRoute.snapshot.queryParamMap.get('group_id') ? this.activatedRoute.snapshot.queryParamMap.get('group_id').split(',').map(Number) : [],
            channel_platform_id: !!this.activatedRoute.snapshot.queryParamMap.get('channel_platform_id') ? +this.activatedRoute.snapshot.queryParamMap.get('channel_platform_id') : "",
            created_at_from: !!this.activatedRoute.snapshot.queryParamMap.get('created_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('created_at_from')*1000) : "",
            created_at_to: !!this.activatedRoute.snapshot.queryParamMap.get('created_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('created_at_to')*1000) : "",
            summary_views_count_from: !!this.activatedRoute.snapshot.queryParamMap.get('summary_views_count_from') ? +this.activatedRoute.snapshot.queryParamMap.get('summary_views_count_from') : "",
            analytics_views_count_from: !!this.activatedRoute.snapshot.queryParamMap.get('analytics_views_count_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('analytics_views_count_from')*1000) : "",
            analytics_views_count_to: this.activatedRoute.snapshot.queryParamMap.get('analytics_views_count_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('analytics_views_count_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('analytics_views_count_to')*1000) : ""),
            content_published_at_from: !!this.activatedRoute.snapshot.queryParamMap.get('content_published_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('content_published_at_from')*1000) : "",
            content_published_at_to: this.activatedRoute.snapshot.queryParamMap.get('content_published_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('content_published_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('content_published_at_to')*1000) : ""),
            order_by: !!this.activatedRoute.snapshot.queryParamMap.get('order_by') ? this.activatedRoute.snapshot.queryParamMap.get('order_by') : "content_published_at_desc",
            task_created_at_from: this.activatedRoute.snapshot.queryParamMap.get('task_created_at_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('task_created_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('task_created_at_from')*1000) : ""),
            task_created_at_to: this.activatedRoute.snapshot.queryParamMap.get('task_created_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('task_created_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('task_created_at_to')*1000) : ""),
            task_operation_created_at_from: this.activatedRoute.snapshot.queryParamMap.get('task_operation_created_at_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('task_operation_created_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('task_operation_created_at_from')*1000) : ""),
            task_operation_created_at_to: this.activatedRoute.snapshot.queryParamMap.get('task_operation_created_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('task_operation_created_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('task_operation_created_at_to')*1000) : ""),
            task_operation_completed_at_from: this.activatedRoute.snapshot.queryParamMap.get('task_operation_completed_at_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('task_operation_completed_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('task_operation_completed_at_from')*1000) : ""),
            task_operation_completed_at_to: this.activatedRoute.snapshot.queryParamMap.get('task_operation_completed_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('task_operation_completed_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('task_operation_completed_at_to')*1000) : ""),
            discussion_created_at_from: this.activatedRoute.snapshot.queryParamMap.get('discussion_created_at_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('discussion_created_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('discussion_created_at_from')*1000) : ""),
            discussion_created_at_to: this.activatedRoute.snapshot.queryParamMap.get('discussion_created_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('discussion_created_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('discussion_created_at_to')*1000) : ""),
            file_created_at_from: this.activatedRoute.snapshot.queryParamMap.get('file_created_at_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('file_created_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('file_created_at_from')*1000) : ""),
            file_created_at_to: this.activatedRoute.snapshot.queryParamMap.get('file_created_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('file_created_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('file_created_at_to')*1000) : ""),
            file_updated_at_from: this.activatedRoute.snapshot.queryParamMap.get('file_updated_at_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('file_updated_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('file_updated_at_from')*1000) : ""),
            file_updated_at_to: this.activatedRoute.snapshot.queryParamMap.get('file_updated_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('file_updated_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('file_updated_at_to')*1000) : ""),
            file_meta_creation_time_from: this.activatedRoute.snapshot.queryParamMap.get('file_meta_creation_time_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('file_meta_creation_time_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('file_meta_creation_time_from')*1000) : ""),
            file_meta_creation_time_to: this.activatedRoute.snapshot.queryParamMap.get('file_meta_creation_time_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('file_meta_creation_time_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('file_meta_creation_time_to')*1000) : ""),
            task_channel_published_at_from: this.activatedRoute.snapshot.queryParamMap.get('task_channel_published_at_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('task_channel_published_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('task_channel_published_at_from')*1000) : ""),
            task_channel_published_at_to: this.activatedRoute.snapshot.queryParamMap.get('task_channel_published_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('task_channel_published_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('task_channel_published_at_to')*1000) : ""),
          })

          if (this.filter.value.channel_id.length == 1 && this.view.value.indexOf('grid') != -1) {
            if (this.profiles.length && this.getProfileById(this.filter.value.channel_id[0])) {
              if (this.getProfileById(this.filter.value.channel_id[0]).platform_id == 1) {
                this.view.patchValue(this.CalendarView.YTGrid, {onlySelf: true, emitEvent: false})
              } else {
                this.view.patchValue(this.CalendarView.IGGrid, {onlySelf: true, emitEvent: false})
              }
            }
          }

          if (['month', 'week', 'day'].includes(this.view.value)) {
            const getStart: any = {
              month: startOfMonth,
              week: startOfWeek,
              day: startOfDay,
            }[this.view.value];
        
            const getEnd: any = {
              month: endOfMonth,
              week: endOfWeek,
              day: endOfDay,
            }[this.view.value];

            // console.log("this.view", this.view)
            // console.log("this.viewDate", this.viewDate)
            // console.log("this.viewDate getStart(this.viewDate)", getStart(this.viewDate))
        
            if (this.view.value == "week") {
              this.filter.patchValue({
                content_published_at_from : moment(moment(startOfWeek(this.viewDate, {weekStartsOn: 1})).unix() * 1000),
                content_published_at_to : moment(moment(endOfWeek(this.viewDate, {weekStartsOn: 1})).unix() * 1000),
              })
            } else {
              this.filter.patchValue({
                content_published_at_from : moment(moment(getStart(this.viewDate)).unix() * 1000),
                content_published_at_to : moment(moment(getEnd(this.viewDate)).unix() * 1000),
              })
            }
          }

        }),
        switchMap((val) => {
          console.log("switchMap", this.company)
          if (this.company == undefined || this.company.id != this.activatedRoute.snapshot.queryParamMap.get('company_id')) {
            return this.companyService.getCompany(this.activatedRoute.snapshot.queryParamMap.get('company_id')).pipe(
              map(x => x[0]),
              tap(x => {
                this.company = x;
                this.me = this.company.employees[0];
                this.companyService.company$.next(x);
                if (x.employees.find(x => x.user_id == this.user.id)) {
                  this.appStatusId = x.employees.find(x => x.user_id == this.user.id).application_status_id
                }
                if (!!x && !!x.employees && !!x.employees[0]) {
                  this.company['rate_target'] = x.employees[0].rate_target
                }

                // COMMENT BEFORE MERGE
                // const dialogRef = this.dialog.open(CreateTaskComponent, {
                //   disableClose: true,
                //   data: {
                //     company_id: this.company_id,
                //     company: this.company,
                //     imgRoute: this.imgRoute,
                //     host: this.host,
                //   }
                // });
            
              }),
              switchMap(() => this.uploadService.getUploadLimit()
              .pipe(
                tap(res => {
                  if (res || this.company.filesize >= this.company.filesize_limit) {
                    this.uploadLimit = true;
                  }
                })
              )),
              map(() => val)
            )
          } else {
            return of(val)
          }
        })
      ).subscribe(params => {
        console.log("route STREAM", params);
        this.getGroupsCompany();
        this.linkTo = '';
        this.profileCollection = new SelectionModel(
          true,
          []
        )

        // console.log("this.linkTo", this.linkTo)
        // console.log('this.linkTo.split("_")[1]', this.linkTo.split("_")[1])
        // console.log("this.filter.value", this.filter.value)
        if (!!this.activatedRoute.snapshot.queryParamMap.get('groups_partner_id') && this.groups_partner_id != +this.activatedRoute.snapshot.queryParamMap.get('groups_partner_id')) {
          this.groups_partner_id = +this.activatedRoute.snapshot.queryParamMap.get('groups_partner_id');
          this.getPartnerGroups()
        }
        
        this.urls = [];
        this.contents = [];
        this.outletsData = [];
        this.page = 1;
        this.contentPage = 1;
        // this.contentTypePage = 1;
        
        this.urlsDataSub = this.taskService.getTaskDataStream().pipe(
          concatMap(tasksInPage => {
            return concat(...tasksInPage.map(taskInPage => this.neededData(taskInPage))).pipe(last(),map(x => tasksInPage))
          }),
        ).subscribe(resp => console.log("-----getTaskDataStream-----",resp));

        this.postsDataSub = this.taskService.getPostDataStream().pipe(
          concatMap(postsInPage => {
            return concat(...postsInPage.map(postInPage => this.neededPostData(postInPage))).pipe(last(),map(x => postsInPage))
          }),
        ).subscribe(resp => console.log("-----getPostDataStream-----",resp));

        
        this.createdDateRange = new DateRange(this.filter.value['created_at_from'], this.filter.value['created_at_to']);
        this.publishDateRange = new DateRange(this.filter.value['content_published_at_from'], this.filter.value['content_published_at_to']);
        this.viewsDateRange = new DateRange(this.filter.value['analytics_views_count_from'], this.filter.value['analytics_views_count_to']);
        this.getBookmarks();
        this.getAllApiParameterValues();
        console.log("INIT this.createdDateRange", this.createdDateRange)
        console.log("INIT this.publishDateRange", this.publishDateRange)
        this.getUrls(this.page);
      })
    )
  }

  getGroupsCompany() {
    this.attachSubscriptions(
      this.companyService.getInfiniteGroupsCompany(this.company_id, '1', '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.companyService.getInfiniteGroupsCompany(this.company_id, x).pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.groups = [].concat(...res);
        this.groups.unshift({
          id: 0,
          company_id: this.company_id,
          name: "Without group",
          description: '',
          created_at:	0,
          updated_at:	0,
          channels: [],
          groupManager:	null
        })
        this.groups$.next(this.groups.slice());
      })
    )
  }

  getPartnerGroups() {
    this.attachSubscriptions(
      this.companyService.getGroupsPartnerCompany(this.company_id, this.groups_partner_id).subscribe(resp => {
        this.partnerGroups = resp;
      })
    )
  }

  getPartnerGroup(id) {
    if (!this.partnerGroups || !this.partnerGroups.find(el => el.id == id)) {
      return false;
    }
    return this.partnerGroups.find(el => el.id == id)
  } 

  goToReminderCases() {
    console.log("goToReminderCases");
  }

  openJob(task_id, task_operation_id) {
    this.attachSubscriptions(
      this.taskService.getOneTaskExpand(this.company_id, task_id).pipe(
        map(x => x.body[0]),
        switchMap(x => this.neededData(x).pipe(map(() => casesModel([x], [], 'update')),map(x => x.arr[0]))),
      ).subscribe(resp => {
        const dialogRef = this.dialog.open(OpenJobComponent, {
          backdropClass: 'backdrop_under_header',
          panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
          autoFocus: false,
          data: {
            task_id: task_id,
            task_operation_id: task_operation_id,
            task: resp,
          }
        });
      })
    )
  }

  postDeleteAfterToggle() {
    if (!!this.post_delete_after) {
      localStorage.removeItem('post_delete_after');
    } else {
      this.sm.localStorageSetItem('post_delete_after', 1)
    }
    this.post_delete_after = this.sm.localStorageGetItem('post_delete_after') ? true : false
  }

  postBckpAfterToggle() {
    if (!!this.post_backup_after) {
      localStorage.removeItem('post_backup_after');
    } else {
      this.sm.localStorageSetItem('post_backup_after', 1)
    }
    this.post_backup_after = this.sm.localStorageGetItem('post_backup_after') ? true : false
  }
  
  postLinkAfterToggle() {
    if (!!this.post_link_after) {
      localStorage.removeItem('post_link_after');
    } else {
      this.sm.localStorageSetItem('post_link_after', 1)
    }
    this.post_link_after = this.sm.localStorageGetItem('post_link_after') ? true : false
  }
  
  editStatusAfter() {
    this.sm.localStorageSetItem('post_status_after', this.post_status_after);
    this.post_status_after = this.sm.localStorageGetItem('post_status_after') ? +this.sm.localStorageGetItem('post_status_after') : '';
  }

  extraInfo(card, post) {
    const dialogRef = this.dialog.open(PostExtraInfoComponent, {
      disableClose: true,
      panelClass: 'push_settigns_modal',
      data: {
        post: post,
        company: this.company,
        card: card,
        host: this.host,
        imgRoute: this.imgRoute,
        origin: this.origin,
        user: this.user,
        activeLang: this.activeLang,
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        
      })
    )
  }

  // if post is live stream
  restartProfile2(card, post, is_backup:boolean = false) {
    console.log("card", card)
    console.log("post", post)

    if (!post.taskChannelFiles || !post.taskChannelFiles.length) {
      this.layoutService.showSnackBar({name: 'No files selected for Live stream.'}, '', SnackBarItem)
      return
    }

    let deleteErrors:number = 0;   

    let sbmtData:any = {
      task_id: card.id,
      channel_id: post.channel_id,
      content_url: post.content_url,
      content_name: post.content_name,
      is_content_views_count_update: post.is_content_views_count_update,
      is_bravourl_content_views_count_update: post.is_bravourl_content_views_count_update,
      content_image: post.content_image,
      content_filename: post.content_filename,
      external_content_id: post.external_content_id,
      content_type_id: post.content_type_id,
      content_published_at: post.content_published_at,
      content_views_count: post.content_views_count,
      publishing_params: post.publishing_params,
    }

    if (is_backup) {
      sbmtData.publishing_params.is_backup = 1;
      sbmtData.publishing_params.live_stream_url_backup = 'rtmp://b.rtmp.youtube.com/live2?backup=1';
      sbmtData.publishing_params.live_stream_to_backup =  sbmtData.publishing_params.live_stream_url_backup.split('?')[0] + '/' + sbmtData.publishing_params.live_stream_key + "?backup=1";
    }

    const dialogRef = this.dialog.open(AskTextComponent, {
      disableClose: true,
      panelClass: 'push_settigns_modal',
      data: {
        title: "Broadcast restart setup",
        field: 'Url',
        withSeconds: true,
        company_id: this.company_id
      }
    });
    // https://www.youtube.com/watch?v=36YnV9STBqc
    this.attachSubscriptions(
      dialogRef.afterClosed().pipe(
        switchMap(result => {
          if (!!result) {
            let newLink;
            if (result.event == 'old link') {
              newLink = sbmtData.content_url
            } else if (result.event == 'new link') {
              newLink = result.data
            } else {
              return of(null)
            }
            this.ls.requests$.next({
              value: 0,
              target: `Restart post ID: ${post.id}`
            })
            return this.taskService.getExpandUrls("1", this.company_id, {id: post.id}, "1").pipe(
              map(x => x.body[0]),
              switchMap(refreshedPost => {
                
                this.ls.requests$.next({
                  value: 15,
                  target: `Restart post ID: ${post.id}`
                })
                sbmtData = {
                  task_id: card.id,
                  channel_id: refreshedPost.channel_id,
                  content_url: refreshedPost.content_url,
                  content_name: refreshedPost.content_name,
                  is_content_views_count_update: refreshedPost.is_content_views_count_update,
                  is_bravourl_content_views_count_update: refreshedPost.is_bravourl_content_views_count_update,
                  content_image: refreshedPost.content_image,
                  content_filename: refreshedPost.content_filename,
                  external_content_id: refreshedPost.external_content_id,
                  content_type_id: refreshedPost.content_type_id,
                  content_published_at: refreshedPost.content_published_at,
                  content_views_count: refreshedPost.content_views_count,
                  publishing_params: refreshedPost.publishing_params,
                }
            
                if (is_backup) {
                  sbmtData.publishing_params.is_backup = 1;
                  sbmtData.publishing_params.live_stream_url_backup = 'rtmp://b.rtmp.youtube.com/live2?backup=1';
                  sbmtData.publishing_params.live_stream_to_backup =  sbmtData.publishing_params.live_stream_url_backup.split('?')[0] + '/' + sbmtData.publishing_params.live_stream_key + "?backup=1";
                }
                if (refreshedPost.publishing_params.random_start_count <= refreshedPost.taskChannelFiles.length) {
                  return this.taskService.getSmartLinkData(newLink, post.channel_id, this.company_id, (post.company_id != this.company_id ? post.company_id : null)).pipe(
                    switchMap(newLinkData => {
                      sbmtData.content_name = newLinkData.name ? newLinkData.name : 'Post has no text';
                      sbmtData.content_url = newLink;
                      sbmtData.external_content_id = newLinkData.external_content_id;
                      sbmtData.content_filename = !!newLinkData.filename ? newLinkData.filename : '';
                      sbmtData.content_image = newLinkData.image;
                      sbmtData.content_resumed_at = 0;
                      if (newLinkData.published_at) {
                        sbmtData.content_published_at = newLinkData.published_at;
                      }
                      sbmtData.content_views_count = !!newLinkData.views_count ? newLinkData.views_count : '0';
      
                      this.ls.requests$.next({
                        value: 25,
                        target: `Restart post ID: ${post.id}`
                      })
                      return this.taskService.getTaskChannelFile(this.company_id, {task_channel_id: post.id}).pipe(
                        switchMap(files => {
                          if (files.length) {
      
                            files.forEach((chFile, ind) => {
                              if (!!chFile.is_error) {
                                chFile.deleted = true;
                                if (files.filter(u => u.file_id == chFile.file_id && u.is_new).length == 0) {
                                  files.push({
                                    company_id: chFile.company_id,
                                    task_channel_id: '',
                                    file_id: chFile.file_id,
                                    is_to_upload: 1,
                                    is_live_stream_content: 1,
                                    file: chFile.file,
                                    is_new: true
                                  })
                                }
                              }
                            });
      
                            let newFiles = [...files.filter(u => !!u.is_new)];
                            let deletedFiles = [...files.filter(u => !!u.deleted)];
                            console.log("newFiles", newFiles);
                            console.log("deletedFiles", deletedFiles);
                            if (deletedFiles.length) {
                              return forkJoin([...deletedFiles.map(fileData => this.taskService.unsetTaskChannelFile(fileData.id, this.company_id).pipe(
                                catchError((error) => {
                                  deleteErrors++;                            
                                  return of(null)
                                })
                              ))]).pipe(
                                switchMap(deleted => {
                                  if (deleteErrors == 0 && newFiles.length) {
                                    return forkJoin([...newFiles.map(fileData => this.taskService.setTaskChannelFile({
                                      company_id: fileData.company_id,
                                      task_channel_id: post.id,
                                      file_id: fileData.file_id,
                                      is_to_upload: !!fileData.is_to_upload ? 1 : 0,
                                      is_live_stream_content: !!fileData.is_live_stream_content ? 1 : 0
                                    }, this.company_id).pipe(
                                      catchError((error) => {
                                        return of(null)
                                      })
                                    ))]).pipe(
                                      map(() => files)
                                    )
                                  } else {
                                    return of(files)
                                  }
                                })
                              )
                            } else if (newFiles.length) {
                              return forkJoin([...newFiles.map(fileData => this.taskService.setTaskChannelFile({
                                company_id: fileData.company_id,
                                task_channel_id: post.id,
                                file_id: fileData.file_id,
                                is_to_upload: !!fileData.is_to_upload ? 1 : 0,
                                is_live_stream_content: !!fileData.is_live_stream_content ? 1 : 0
                              }, this.company_id).pipe(
                                catchError((error) => {
                                  return of(null)
                                })
                              ))]).pipe(
                                map(() => files)
                              )
                            } else {
                              return of(files)
                            }
      
                          } else {
                            return of(files)
                          }
                        }),
                        switchMap(files => {
                          this.ls.requests$.next({
                            value: 50,
                            target: `Restart post ID: ${post.id}`
                          })
                          if (deleteErrors > 0 || !files.length) {
                            return of(files)
                          } else {
                            return this.taskService.editProfile(post.id, sbmtData, this.company_id).pipe(map(() => files))
                          }
                        }),
                        switchMap(files => {
                          this.ls.requests$.next({
                            value: 75,
                            target: `Restart post ID: ${post.id}`
                          })
                          if (deleteErrors > 0 || !files.length) {
                            return of(false)
                          } else {
                            return this.taskService.getPostExtra(1, this.company_id, {task_channel_id: post.id, is_active: 1}).pipe(
                              map(broadsRes => broadsRes.body),
                              switchMap(broads => {
                                if (broads.length) {
                                  if (broads.filter(u => !u.is_pause).length) {
                                    return forkJoin(broads.filter(u => !u.is_pause).map(notPausedBroad => this.taskService.editBroadcast(notPausedBroad.id, {is_pause: 1, pause_timeout: result.time})))
                                  } else {
                                    return of('stream already restarted')
                                  }
                                } else {
                                  return of('no active broadcast')
                                }
                              })
                            )
                          }
                        })
                      )
                    })
                  )
                } else {
                  return of({error: 'random_start_count error', setCount: refreshedPost.publishing_params.random_start_count, count: refreshedPost.taskChannelFiles.length})
                }
              }) 
            )
          }
        })
      ).subscribe(resp => {
        console.log('resp', resp)
        this.ls.requests$.next({
          value: 100,
          target: `Restart post ID: ${post.id}`
        })
        if (!!resp) {
          this.refreshService.refreshUrls$.next({url_id: post.id, company_id: this.company_id})
        }

        if (resp == 'stream already restarted') {
          this.layoutService.showSnackBar({name: ``}, resp, SnackBarItem)
        }

        if (resp.error && resp.error == 'random_start_count error') {
          this.layoutService.showSnackBar({name: ``}, `You didn't selected enough files to start stream.<br><br>It's set ${resp.setCount} files to start broadcast but selected ${resp.count} files for streaming.<br><br>Select ${resp.setCount - resp.count} files more to meet minimum requirement.`, SnackBarItem)
        }

        if (resp == 'no active broadcast') {
          this.layoutService.showSnackBar({name: ``}, resp, SnackBarItem)
        }

        if (deleteErrors > 0) {
          this.layoutService.showSnackBar({name: `Post id ${post.id} has corrupted files. Contact admin.`}, 'Copy message to admin', SnackBarItem, 60000, undefined, true, post.id, `Post id ${post.id} has corrupted files.\n\nAdmin must restart stream to fix the issue.\n\nLink to find the post: ${this.origin}/urls?company_id=${this.company_id}&q=${post.id}`)
        }
      })
    )
  }
  
  restartProfile(card, post, is_backup:boolean = false) {
    console.log("card", card)
    console.log("post", post)

    if (!post.taskChannelFiles || !post.taskChannelFiles.length) {
      this.layoutService.showSnackBar({name: 'No files selected for Live stream.'}, '', SnackBarItem)
      return
    }

    let deleteErrors:number = 0;   

    let sbmtData:any = {
      task_id: card.id,
      channel_id: post.channel_id,
      content_url: post.content_url,
      content_name: post.content_name,
      is_content_views_count_update: post.is_content_views_count_update,
      is_bravourl_content_views_count_update: post.is_bravourl_content_views_count_update,
      content_image: post.content_image,
      content_filename: post.content_filename,
      external_content_id: post.external_content_id,
      content_type_id: post.content_type_id,
      content_published_at: post.content_published_at,
      content_status_id: 2,
      content_views_count: post.content_views_count,
      publishing_params: post.publishing_params,
    }

    if (is_backup) {
      sbmtData.publishing_params.is_backup = 1;
      sbmtData.publishing_params.live_stream_url_backup = 'rtmp://b.rtmp.youtube.com/live2?backup=1';
      sbmtData.publishing_params.live_stream_to_backup =  sbmtData.publishing_params.live_stream_url_backup.split('?')[0] + '/' + sbmtData.publishing_params.live_stream_key + "?backup=1";
    }

    const dialogRef = this.dialog.open(AskTextComponent, {
      disableClose: true,
      panelClass: 'push_settigns_modal',
      data: {
        title: "Fill new url",
        field: 'Url',
        company_id: this.company_id
      }
    });
    // https://www.youtube.com/watch?v=36YnV9STBqc
    this.attachSubscriptions(
      dialogRef.afterClosed().pipe(
        switchMap(result => {
          if (!!result) {
            let newLink;
            if (result.event == 'old link') {
              newLink = sbmtData.content_url
            } else if (result.event == 'new link') {
              newLink = result.data
            } else {
              return of(null)
            }
            this.ls.requests$.next({
              value: 0,
              target: `Restart post ID: ${post.id}`
            })
            return this.taskService.getExpandUrls("1", this.company_id, {id: post.id}, "1").pipe(
              map(x => x.body[0]),
              switchMap(refreshedPost => {
                this.ls.requests$.next({
                  value: 15,
                  target: `Restart post ID: ${post.id}`
                })
                sbmtData = {
                  task_id: card.id,
                  channel_id: refreshedPost.channel_id,
                  content_url: refreshedPost.content_url,
                  content_name: refreshedPost.content_name,
                  is_content_views_count_update: refreshedPost.is_content_views_count_update,
                  is_bravourl_content_views_count_update: refreshedPost.is_bravourl_content_views_count_update,
                  content_image: refreshedPost.content_image,
                  content_filename: refreshedPost.content_filename,
                  external_content_id: refreshedPost.external_content_id,
                  content_type_id: refreshedPost.content_type_id,
                  content_status_id: 2,
                  content_published_at: refreshedPost.content_published_at,
                  content_views_count: refreshedPost.content_views_count,
                  publishing_params: refreshedPost.publishing_params,
                }
            
                if (is_backup) {
                  sbmtData.publishing_params.is_backup = 1;
                  sbmtData.publishing_params.live_stream_url_backup = 'rtmp://b.rtmp.youtube.com/live2?backup=1';
                  sbmtData.publishing_params.live_stream_to_backup =  sbmtData.publishing_params.live_stream_url_backup.split('?')[0] + '/' + sbmtData.publishing_params.live_stream_key + "?backup=1";
                }
                if (refreshedPost.publishing_params.random_start_count <= refreshedPost.taskChannelFiles.length) {
                  return this.taskService.getSmartLinkData(newLink, post.channel_id, this.company_id, (post.company_id != this.company_id ? post.company_id : null)).pipe(
                    switchMap(newLinkData => {
                      sbmtData.content_name = newLinkData.name ? newLinkData.name : 'Post has no text';
                      sbmtData.content_url = newLink;
                      sbmtData.external_content_id = newLinkData.external_content_id;
                      sbmtData.content_filename = !!newLinkData.filename ? newLinkData.filename : '';
                      sbmtData.content_image = newLinkData.image;
                      if (newLinkData.published_at) {
                        sbmtData.content_published_at = newLinkData.published_at;
                      }
                      if (newLinkData.external_content_id) {
                        sbmtData.content_status_id = 6;
                        sbmtData.content_resumed_at = 0;
                      }
                      sbmtData.content_views_count = !!newLinkData.views_count ? newLinkData.views_count : '0';
      
                      this.ls.requests$.next({
                        value: 45,
                        target: `Restart post ID: ${post.id}`
                      })
                      return this.taskService.getTaskChannelFile(this.company_id, {task_channel_id: post.id}).pipe(
                        switchMap(files => {
                          if (files.length) {
      
                            files.forEach((chFile, ind) => {
                              if (!!chFile.is_error) {
                                chFile.deleted = true;
                                if (files.filter(u => u.file_id == chFile.file_id && u.is_new).length == 0) {
                                  files.push({
                                    company_id: chFile.company_id,
                                    task_channel_id: '',
                                    file_id: chFile.file_id,
                                    is_to_upload: 1,
                                    is_live_stream_content: 1,
                                    file: chFile.file,
                                    is_new: true
                                  })
                                }
                              }
                            });
      
                            let newFiles = [...files.filter(u => !!u.is_new)];
                            let deletedFiles = [...files.filter(u => !!u.deleted)];
                            console.log("newFiles", newFiles);
                            console.log("deletedFiles", deletedFiles);
                            if (deletedFiles.length) {
                              return forkJoin([...deletedFiles.map(fileData => this.taskService.unsetTaskChannelFile(fileData.id, this.company_id).pipe(
                                catchError((error) => {
                                  deleteErrors++;                            
                                  return of(null)
                                })
                              ))]).pipe(
                                switchMap(deleted => {
                                  if (deleteErrors == 0 && newFiles.length) {
                                    return forkJoin([...newFiles.map(fileData => this.taskService.setTaskChannelFile({
                                      company_id: fileData.company_id,
                                      task_channel_id: post.id,
                                      file_id: fileData.file_id,
                                      is_to_upload: !!fileData.is_to_upload ? 1 : 0,
                                      is_live_stream_content: !!fileData.is_live_stream_content ? 1 : 0
                                    }, this.company_id).pipe(
                                      catchError((error) => {
                                        return of(null)
                                      })
                                    ))]).pipe(
                                      map(() => files)
                                    )
                                  } else {
                                    return of(files)
                                  }
                                })
                              )
                            } else if (newFiles.length) {
                              return forkJoin([...newFiles.map(fileData => this.taskService.setTaskChannelFile({
                                company_id: fileData.company_id,
                                task_channel_id: post.id,
                                file_id: fileData.file_id,
                                is_to_upload: !!fileData.is_to_upload ? 1 : 0,
                                is_live_stream_content: !!fileData.is_live_stream_content ? 1 : 0
                              }, this.company_id).pipe(
                                catchError((error) => {
                                  return of(null)
                                })
                              ))]).pipe(
                                map(() => files)
                              )
                            } else {
                              return of(files)
                            }
      
                          } else {
                            return of(files)
                          }
                        }),
                        switchMap(files => {
                          this.ls.requests$.next({
                            value: 75,
                            target: `Restart post ID: ${post.id}`
                          })
                          if (deleteErrors > 0 || !files.length) {
                            return of(files)
                          } else {
                            return this.taskService.editProfile(post.id, sbmtData, this.company_id).pipe(map(() => files))
                          }
                        })
                      )
                    })
                  )
                } else {
                  return of({error: 'random_start_count error', setCount: refreshedPost.publishing_params.random_start_count, count: refreshedPost.taskChannelFiles.length})
                }
              }) 
            )
          }
        })
      ).subscribe(resp => {
        console.log('resp', resp)
        this.ls.requests$.next({
          value: 100,
          target: `Restart post ID: ${post.id}`
        })
        if (!!resp) {
          this.refreshService.refreshUrls$.next({url_id: post.id, company_id: this.company_id})
        }


        if (resp.error && resp.error == 'random_start_count error') {
          this.layoutService.showSnackBar({name: ``}, `You didn't selected enough files to start stream.<br><br>It's set ${resp.setCount} files to start broadcast but selected ${resp.count} files for streaming.<br><br>Select ${resp.setCount - resp.count} files more to meet minimum requirement.`, SnackBarItem)
        }

        if (deleteErrors > 0) {
          this.layoutService.showSnackBar({name: `Post id ${post.id} has corrupted files. Contact admin.`}, 'Copy message to admin', SnackBarItem, 60000, undefined, true, post.id, `Post id ${post.id} has corrupted files.\n\nAdmin must restart stream to fix the issue.\n\nLink to find the post: ${this.origin}/urls?company_id=${this.company_id}&q=${post.id}`)
        }
      })
    )
  }
  

  // if published
  startLivePost(card, post, is_backup:boolean = false) {
    console.log("card", card)
    console.log("post", post)

    if (!post.taskChannelFiles || !post.taskChannelFiles.length) {
      this.layoutService.showSnackBar({name: 'No files selected for Live stream.'}, '', SnackBarItem)
      return
    }

    let deleteErrors:number = 0;   

    let sbmtData:any = {
      task_id: card.id,
      channel_id: post.channel_id,
      content_url: post.content_url,
      content_name: post.content_name,
      is_content_views_count_update: post.is_content_views_count_update,
      is_bravourl_content_views_count_update: post.is_bravourl_content_views_count_update,
      content_image: post.content_image,
      content_filename: post.content_filename,
      external_content_id: post.external_content_id,
      content_type_id: post.content_type_id,
      content_published_at: post.content_published_at,
      content_status_id: 2,
      content_views_count: post.content_views_count,
      publishing_params: post.publishing_params,
    }

    if (is_backup) {
      sbmtData.publishing_params.is_backup = 1;
      sbmtData.publishing_params.live_stream_url_backup = 'rtmp://b.rtmp.youtube.com/live2?backup=1';
      sbmtData.publishing_params.live_stream_to_backup =  sbmtData.publishing_params.live_stream_url_backup.split('?')[0] + '/' + sbmtData.publishing_params.live_stream_key + "?backup=1";
    }

    const dialogRef = this.dialog.open(AskTextComponent, {
      disableClose: true,
      panelClass: 'push_settigns_modal',
      data: {
        title: "Fill new url",
        field: 'Url',
        company_id: this.company_id
      }
    });
    // https://www.youtube.com/watch?v=36YnV9STBqc
    this.attachSubscriptions(
      dialogRef.afterClosed().pipe(
        switchMap(result => {
          if (!!result) {
            let newLink;
            if (result.event == 'old link') {
              newLink = sbmtData.content_url
            } else if (result.event == 'new link') {
              newLink = result.data
            } else {
              return of(null)
            }
            this.ls.requests$.next({
              value: 0,
              target: `Restart post ID: ${post.id}`
            })
            return this.taskService.getExpandUrls("1", this.company_id, {id: post.id}, "1").pipe(
              map(x => x.body[0]),
              switchMap(refreshedPost => {
                this.ls.requests$.next({
                  value: 15,
                  target: `Restart post ID: ${post.id}`
                })
                sbmtData = {
                  task_id: card.id,
                  channel_id: refreshedPost.channel_id,
                  content_url: refreshedPost.content_url,
                  content_name: refreshedPost.content_name,
                  is_content_views_count_update: refreshedPost.is_content_views_count_update,
                  is_bravourl_content_views_count_update: refreshedPost.is_bravourl_content_views_count_update,
                  content_image: refreshedPost.content_image,
                  content_status_id: 2,
                  content_filename: refreshedPost.content_filename,
                  external_content_id: refreshedPost.external_content_id,
                  content_type_id: refreshedPost.content_type_id,
                  content_published_at: refreshedPost.content_published_at,
                  content_views_count: refreshedPost.content_views_count,
                  publishing_params: refreshedPost.publishing_params,
                }
            
                if (is_backup) {
                  sbmtData.publishing_params.is_backup = 1;
                  sbmtData.publishing_params.live_stream_url_backup = 'rtmp://b.rtmp.youtube.com/live2?backup=1';
                  sbmtData.publishing_params.live_stream_to_backup =  sbmtData.publishing_params.live_stream_url_backup.split('?')[0] + '/' + sbmtData.publishing_params.live_stream_key + "?backup=1";
                }
                if (refreshedPost.publishing_params.random_start_count <= refreshedPost.taskChannelFiles.length) {
                  return this.taskService.getSmartLinkData(newLink, post.channel_id, this.company_id, (post.company_id != this.company_id ? post.company_id : null)).pipe(
                    switchMap(newLinkData => {
                      sbmtData.content_name = newLinkData.name ? newLinkData.name : 'Post has no text';
                      sbmtData.content_url = newLink;
                      sbmtData.external_content_id = newLinkData.external_content_id;
                      sbmtData.content_filename = !!newLinkData.filename ? newLinkData.filename : '';
                      sbmtData.content_image = newLinkData.image;
                      if (newLinkData.published_at) {
                        sbmtData.content_published_at = newLinkData.published_at;
                      }
                      if (newLinkData.external_content_id) {
                        sbmtData.content_status_id = 6
                      }
                      sbmtData.content_views_count = !!newLinkData.views_count ? newLinkData.views_count : '0';
      
                      this.ls.requests$.next({
                        value: 45,
                        target: `Restart post ID: ${post.id}`
                      })
                      return this.taskService.getTaskChannelFile(this.company_id, {task_channel_id: post.id}).pipe(
                        switchMap(files => {
                          if (files.length) {
      
                            files.forEach((chFile, ind) => {
                              if (!!chFile.is_error) {
                                chFile.deleted = true;
                                if (files.filter(u => u.file_id == chFile.file_id && u.is_new).length == 0) {
                                  files.push({
                                    company_id: chFile.company_id,
                                    task_channel_id: '',
                                    file_id: chFile.file_id,
                                    is_to_upload: 1,
                                    is_live_stream_content: 1,
                                    file: chFile.file,
                                    is_new: true
                                  })
                                }
                              }
                            });
      
                            let newFiles = [...files.filter(u => !!u.is_new)];
                            let deletedFiles = [...files.filter(u => !!u.deleted)];
                            console.log("newFiles", newFiles);
                            console.log("deletedFiles", deletedFiles);
                            if (deletedFiles.length) {
                              return forkJoin([...deletedFiles.map(fileData => this.taskService.unsetTaskChannelFile(fileData.id, this.company_id).pipe(
                                catchError((error) => {
                                  deleteErrors++;                            
                                  return of(null)
                                })
                              ))]).pipe(
                                switchMap(deleted => {
                                  if (deleteErrors == 0 && newFiles.length) {
                                    return forkJoin([...newFiles.map(fileData => this.taskService.setTaskChannelFile({
                                      company_id: fileData.company_id,
                                      task_channel_id: post.id,
                                      file_id: fileData.file_id,
                                      is_to_upload: !!fileData.is_to_upload ? 1 : 0,
                                      is_live_stream_content: !!fileData.is_live_stream_content ? 1 : 0
                                    }, this.company_id).pipe(
                                      catchError((error) => {
                                        return of(null)
                                      })
                                    ))]).pipe(
                                      map(() => files)
                                    )
                                  } else {
                                    return of(files)
                                  }
                                })
                              )
                            } else if (newFiles.length) {
                              return forkJoin([...newFiles.map(fileData => this.taskService.setTaskChannelFile({
                                company_id: fileData.company_id,
                                task_channel_id: post.id,
                                file_id: fileData.file_id,
                                is_to_upload: !!fileData.is_to_upload ? 1 : 0,
                                is_live_stream_content: !!fileData.is_live_stream_content ? 1 : 0
                              }, this.company_id).pipe(
                                catchError((error) => {
                                  return of(null)
                                })
                              ))]).pipe(
                                map(() => files)
                              )
                            } else {
                              return of(files)
                            }
      
                          } else {
                            return of(files)
                          }
                        }),
                        switchMap(files => {
                          this.ls.requests$.next({
                            value: 75,
                            target: `Restart post ID: ${post.id}`
                          })
                          if (deleteErrors > 0 || !files.length) {
                            return of(files)
                          } else {
                            return this.taskService.editProfile(post.id, sbmtData, this.company_id).pipe(map(() => files))
                          }
                        })
                      )
                    })
                  )
                } else {
                  return of({error: 'random_start_count error', setCount: refreshedPost.publishing_params.random_start_count, count: refreshedPost.taskChannelFiles.length})
                }
              }) 
            )
          }
        })
      ).subscribe(resp => {
        console.log('resp', resp)
        this.ls.requests$.next({
          value: 100,
          target: `Restart post ID: ${post.id}`
        })
        if (!!resp) {
          this.refreshService.refreshUrls$.next({url_id: post.id, company_id: this.company_id})
        }

        if (resp.error && resp.error == 'random_start_count error') {
          this.layoutService.showSnackBar({name: ``}, `You didn't selected enough files to start stream.<br><br>It's set ${resp.setCount} files to start broadcast but selected ${resp.count} files for streaming.<br><br>Select ${resp.setCount - resp.count} files more to meet minimum requirement.`, SnackBarItem)
        }

        if (deleteErrors > 0) {
          this.layoutService.showSnackBar({name: `Post id ${post.id} has corrupted files. Contact admin.`}, 'Copy message to admin', SnackBarItem, 60000, undefined, true, post.id, `Post id ${post.id} has corrupted files.\n\nAdmin must restart stream to fix the issue.\n\nLink to find the post: ${this.origin}/urls?company_id=${this.company_id}&q=${post.id}`)
        }
      })
    )
  }

  duplicatePost(card, post, not_new:boolean = false) {
    console.log("card", card)
    console.log("post", post)

    if (!post.external_content_id) {
      this.layoutService.showSnackBar({name: 'This post has no external content id, you cannot duplicate it.'}, '', SnackBarItem)
      return
    }

    let with_new_link = this.post_link_after;

    if (not_new) {
      with_new_link = false;
    }

    let sbmtData = {
      task_id: card.id,
      channel_id: post.channel_id,
      content_url: post.content_url,
      content_name: post.content_name,
      is_content_views_count_update: post.is_content_views_count_update,
      is_bravourl_content_views_count_update: post.is_bravourl_content_views_count_update,
      content_image: post.content_image,
      content_filename: post.content_filename,
      external_content_id: post.external_content_id,
      content_type_id: post.content_type_id,
      content_published_at: post.content_published_at,
      content_status_id: this.post_status_after ? +this.post_status_after : post.content_status_id,
      content_views_count: post.content_views_count,
      publishing_params: post.publishing_params,
    }

    if (with_new_link) {
      const dialogRef = this.dialog.open(AskTextComponent, {
        disableClose: true,
        panelClass: 'push_settigns_modal',
        data: {
          title: "Fill new url",
          field: 'Url',
          company_id: this.company_id
        }
      });
      // https://www.youtube.com/watch?v=36YnV9STBqc
      this.attachSubscriptions(
        dialogRef.afterClosed().pipe(
          switchMap(result => {
            if (!!result) {
              if (result.event == 'old link') {
                return of('old link')
              } else if (result.event == 'new link') {

                this.ls.requests$.next({
                  value: 0,
                  target: "Duplicate Post"
                })

                return this.taskService.getSmartLinkData(result.data, post.channel_id, this.company_id, (post.company_id != this.company_id ? post.company_id : null)).pipe(
                  switchMap(newLinkData => {
                    sbmtData.content_name = newLinkData.name ? newLinkData.name : 'Post has no text';
                    sbmtData.content_url = result.data;
                    sbmtData.external_content_id = newLinkData.external_content_id;
                    sbmtData.content_filename = !!newLinkData.filename ? newLinkData.filename : '';
                    sbmtData.content_image = newLinkData.image;
                    if (newLinkData.published_at) {
                      sbmtData.content_published_at = newLinkData.published_at;
                    }
                    sbmtData.content_views_count = !!newLinkData.views_count ? newLinkData.views_count : '0';

                    this.ls.requests$.next({
                      value: 25,
                      target: "Duplicate Post"
                    })

                    return this.taskService.getTaskChannelFile(this.company_id, {task_channel_id: post.id}).pipe(
                      switchMap(files => {
                        if (this.post_backup_after) {
                          sbmtData.publishing_params.live_stream_url = "rtmp://b.rtmp.youtube.com/live2";
                          sbmtData.publishing_params.live_stream_to = ((sbmtData.publishing_params.live_stream_url.length && sbmtData.publishing_params.live_stream_url[sbmtData.publishing_params.live_stream_url.length - 1] == '/' ? sbmtData.publishing_params.live_stream_url : (sbmtData.publishing_params.live_stream_url + '/')) + sbmtData.publishing_params.live_stream_key) + "?backup=1";
                        }
                        this.ls.requests$.next({
                          value: 50,
                          target: "Duplicate Post"
                        })
                        return this.taskService.addProfile(sbmtData, this.company_id).pipe(
                          switchMap(res => {
                            this.ls.requests$.next({
                              value: 75,
                              target: "Duplicate Post"
                            })
                            if (files.length) {
                               return forkJoin([...files.map(fileData => this.taskService.setTaskChannelFile({
                                company_id: fileData.company_id,
                                task_channel_id: res.id,
                                file_id: fileData.file_id,
                                is_to_upload: !!fileData.is_to_upload ? 1 : 0,
                                is_live_stream_content: !!fileData.is_live_stream_content ? 1 : 0
                              }, this.company_id).pipe(
                                catchError((error) => {
                                  res.hasError = true;
                                  return of(null)
                                })
                              ))]).pipe(
                                tap(u => {
                                  this.ls.requests$.next({
                                    value: 100,
                                    target: "Duplicate Post"
                                  })
                                }),
                                map((newFiles) => {
                                  return {
                                    newPost: res,
                                    newLinkData: newLinkData,
                                    files: files,
                                    newFiles: newFiles
                                  }
                                }),
                              )
                            } else {
                              this.ls.requests$.next({
                                value: 100,
                                target: "Duplicate Post"
                              })
                              return of({
                                newPost: res,
                                newLinkData: newLinkData,
                                files: files
                              })
                            }
                          })
                        )
                      }),
                      switchMap(result => {
                        if (this.post_delete_after) {
                          return this.taskService.deleteProfile(post.id).pipe(
                            tap(u => {
                              if (this.urls.find(x => x.id == post.id)) {
                                this.urls.splice(this.urls.findIndex(x => x.id == post.id), 1)
                              }
                            }),
                            map(() => result)
                          )
                        } else {
                          return of(result)
                        }
                      })
                    )
                  })
                )
              } else {
                return of(null)
              }
            }
          })
        ).subscribe(resp => {
          console.log('resp with_new_link', resp)
          if (resp == 'old link') {
            this.duplicatePost(card, post, true);
          }
          if (resp && resp.newPost) {
            console.log("resp.newPost")
            // this.urls.unshift(resp.newPost);
            this.refreshService.refreshUrls$.next({url_id: resp.newPost.id, company_id: this.company_id, new: true})
          }
        })
      )
    } else {

      this.ls.requests$.next({
        value: 0,
        target: "Duplicate Post"
      })
      this.attachSubscriptions(
        this.taskService.getTaskChannelFile(this.company_id, {task_channel_id: post.id}).pipe(
          switchMap(files => {
            this.ls.requests$.next({
              value: 33,
              target: "Duplicate Post"
            })
            if (this.post_backup_after) {
              sbmtData.publishing_params.live_stream_url = "rtmp://b.rtmp.youtube.com/live2";
              sbmtData.publishing_params.live_stream_to = ((sbmtData.publishing_params.live_stream_url.length && sbmtData.publishing_params.live_stream_url[sbmtData.publishing_params.live_stream_url.length - 1] == '/' ? sbmtData.publishing_params.live_stream_url : (sbmtData.publishing_params.live_stream_url + '/')) + sbmtData.publishing_params.live_stream_key) + "?backup=1";
            }
            return this.taskService.addProfile(sbmtData, this.company_id).pipe(
              switchMap(res => {
                this.ls.requests$.next({
                  value: 66,
                  target: "Duplicate Post"
                })
                if (files.length) {
                   return forkJoin([...files.map(fileData => this.taskService.setTaskChannelFile({
                    company_id: fileData.company_id,
                    task_channel_id: res.id,
                    file_id: fileData.file_id,
                    is_to_upload: !!fileData.is_to_upload ? 1 : 0,
                    is_live_stream_content: !!fileData.is_live_stream_content ? 1 : 0
                  }, this.company_id).pipe(
                    catchError((error) => {
                      res.hasError = true;
                      return of(null)
                    })
                  ))]).pipe(
                    tap(q => {
                      this.ls.requests$.next({
                        value: 100,
                        target: "Duplicate Post"
                      })
                    }),
                    map((newFiles) => {
                      return {
                        newPost: res,
                        files: files,
                        newFiles: newFiles
                      }
                    }),
                  )
                } else {
                  this.ls.requests$.next({
                    value: 100,
                    target: "Duplicate Post"
                  })
                  return of({
                    newPost: res,
                    files: files
                  })
                }
              })
            )
          }),
          switchMap(result => {
            if (this.post_delete_after) {
              return this.taskService.deleteProfile(post.id).pipe(
                tap(u => {
                  if (this.urls.find(x => x.id == post.id)) {
                    this.urls.splice(this.urls.findIndex(x => x.id == post.id), 1)
                  }
                }),
                map(() => result)
              )
            } else {
              return of(result)
            }
          })
        ).subscribe(resp => {
          console.log("duplicatePost", resp);
          if (resp.newPost) {
            console.log("resp.newPost")
            // this.urls.unshift(resp.newPost);
            this.refreshService.refreshUrls$.next({url_id: resp.newPost.id, company_id: this.company_id, new: true})
          }
        })
      )
    }

  }

  openProfileLink(profile) {
    window.open(profile.content_url, '_blank')
  }

  openTask(task) {
    let taskData:any = {
      task_id: task.id,
      task: task
    }
    // if (task.hasOwnProperty("operations")) {
    //   taskData.task = task
    // }
    if (this.urls.length) {
      taskData.tasks = this.urls
    }
    const dialogRef = this.dialog.open(OpenTaskComponent, {
      backdropClass: 'backdrop_under_header',
      panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
      autoFocus: false,
      data: taskData
    });
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  getUser() {
    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        this.user = resp;
      })
    )
  }

  getRateStats() {
    this.attachSubscriptions(
      this.taskService.getRateStats(this.company_id, moment().utc().clone().startOf('month').format('X'), moment().utc().clone().endOf('month').format('X')).subscribe(resp => {
        this.taskService.userStats$.next(resp);
      })
    )
  }

  // getEmployees() {
  //   this.attachSubscriptions(
  //     this.taskService.getEmployees(this.company_id).subscribe(resp => {
  //       this.employees = resp;
  //       this.employees$.next(this.employees.slice());
  //     })
  //   )
  // }

  showStats(profile) {
    if (!this.is_mobile) {
      const dialogRef = this.dialog.open(UrlAnalyticsComponent, {
        disableClose: true,
        autoFocus: false,
        data: {
          company: this.company,
          company_id: this.company_id,
          task: profile.task,
          profile: profile,
          is_edit: true,
        }
      });
  
      this.attachSubscriptions(
        dialogRef.afterClosed().subscribe(result => {
          this.refreshService.refreshUrls$.next({url_id: profile.id, company_id: this.company_id})
        })
      )
    } else {
      const dialogRef = this.dialog.open(MobUrlAnalyticsComponent, {
        backdropClass: ['mob_interface_backdrop'],
        panelClass: ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'],
        disableClose: true,
        data: {
          company: this.company,
          company_id: this.company_id,
          task: profile.task,
          profile: profile,
          is_edit: true,
        }
      });
  
      this.attachSubscriptions(
        dialogRef.afterClosed().subscribe(result => {
          this.refreshService.refreshUrls$.next({url_id: profile.id, company_id: this.company_id})
        })
      )
    }
  }

  getLangData(id) {
    this.attachSubscriptions(
      this.languageService.getLangData().pipe(
        tap(resp => {
          this.activeLang = resp.active;
          this._adapter.setLocale(resp.active);
          this.currMonthName = moment().format('MMMM');
        }),
        switchMap(() => this.taskService.getOperations(id, this.activeLang).pipe(
          tap(x => {
            this.operationsValues = x;
          })
        ))
      ).subscribe(resp => {
        console.log("getOperations", resp)
      })
    )
  }


  addRowData(row_obj) {
    let data = casesModel([row_obj], JSON.parse(JSON.stringify(this.urls)), "add")
    this.urls.unshift(...data.arr);
    this.pagination['totalCount'] = +this.pagination['totalCount'] + 1;
  }

  checkIsMobile() {
    if (window.innerWidth <= 769) {
      this.is_mobile = true;
    } else {
      this.is_mobile = false;
    }
    this.onResize();
  }

  onResize() {
    this.attachSubscriptions(
      fromEvent(window, "resize").pipe(
        map(() => window.innerWidth)
      ).subscribe((wWidth) => {
          if (wWidth <= 769) {
            this.is_mobile = true;
          } else {
            this.is_mobile = false;
          }
        }
      )
    )
  }

  ngAfterViewInit(): void {

    if (this.view.value == 'list') {
      if (this.is_mobile) {
        this.onMobScroll()
      } else {
        this.onScroll();
      }
    }

    // if (this.profilesContainer) {
    //   this.attachSubscriptions(
    //     this.profilesContainer.openedChange.subscribe((e) => {
    //       if (e) {
    //         this.onScrollProfiles()
    //       }
    //     })
    //   )
    // }

    this.cd.detectChanges();
  }

  closeDateFilter() {
    this.dateFilterSub && this.dateFilterSub.unsubscribe();
    if (this.overlayDateRefFilter) {
      this.overlayDateRefFilter.dispose();
      this.overlayDateRefFilter = null;
    }
  }

  openDateFilter(el, val) {
    this.closeDateFilter();
    let x = el.offsetLeft - 350 + el.offsetWidth;
    let y =  el.offsetTop + el.offsetHeight;
    const positionStrategy = this.overlay.position()
    .flexibleConnectedTo({ x, y })
    .withPositions([
      {
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'top',
      }
    ]);

    this.overlayDateRefFilter = this.overlay.create({
      backdropClass: 'transparent_bg',
      hasBackdrop: true,
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    this.overlayDateRefFilter.attach(new TemplatePortal(this.filterDateMenu, this.viewContainerRef, {
      $implicit: val
    }));
    
    this.dateFilterSub = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter(event => {
          const clickTarget = event.target as HTMLElement;
       
          let check1 = !!this.overlayDateRefFilter && !this.overlayDateRefFilter.overlayElement.contains(clickTarget)
          // let check2 = (document.getElementsByClassName('mat-calendar-table').length > 0 && !document.getElementsByClassName('mat-calendar-table')[0].contains(clickTarget))
          // let check3 = (document.getElementsByClassName('filter_autocomlete').length == 0);
          // let check4 = (clickTarget.classList.contains('mat-option-text') || clickTarget.classList.contains('mat-option'))
          let check5 = !clickTarget.classList.contains('mat-calendar-body-cell-content')
          // console.log("check1", check1)
          // console.log("check2", check2)
          // console.log("clickTarget", clickTarget.classList.contains('mat-calendar-body-cell-content'))
          // console.log("document.getElementsByClassName('mat-calendar-table')", document.getElementsByClassName('mat-calendar-table'))
          // console.log("document.getElementsByClassName('mat-calendar-table')[0].contains(clickTarget)", document.getElementsByClassName('mat-calendar-table')[0].contains(clickTarget))
//(check1 && check2) || (check1 && check3 && !check4)
          // return false
          return check1 && check5
        }),
        take(1)
      ).subscribe(() => this.closeDateFilter())

  }
  
  multiRefreshViewCounts() {
    this.isRefreshed = true;
    let count: number = 0;
    this.attachSubscriptions(
      concat(...this.profileCollection.selected.map(profile => {
        console.log("PROFILE", profile);
        profile.isRefreshed = true;
        if (profile && profile.channel && profile.channel.platform_id == 1 && profile.content_type_id != 106) {
          return this.taskService.getYouTubeViews(profile.id, {}).pipe(
            catchError(err => {
              console.log("catchError1", err)
              return of(false)
            }),
            switchMap(el => {
              console.log("switchMap1", el)
              if (!!el) {
                return this.taskService.editProfile(profile.id, {content_views_count: !!el.content_views_count ? el.content_views_count : '0', is_bravourl_content_views_count_update: !!profile.is_bravourl_content_views_count_update ? '1' : '0', is_content_views_count_update: !!profile.is_content_views_count_update ? '1' : '0'}, this.company_id).pipe(
                  tap(result => {
                    profile.content_views_count = result.content_views_count.toString();
                    profile.is_content_views_count_update = result.is_content_views_count_update;
                    profile.is_bravourl_content_views_count_update = result.is_bravourl_content_views_count_update;
                    profile.views_updated_at = result.views_updated_at;
                    profile.views_update_at = result.views_update_at;
                  }),
                  catchError(err => {
                    console.log("catchError11", err)
                    return of(false)
                  })
                )
              } else {
                return of(el)
              }
            }),
            tap(el => {
              profile.isRefreshed = false;
            })
          )
        } else if (profile && profile.channel && profile.channel.platform_id == 3) {
          return this.taskService.getTikTokViews(profile.id, {}).pipe(
            catchError(err => {
              console.log("catchError2", err)
              return of(false)
            }),
            switchMap(el => {
              if (!!el) {
                return this.taskService.editProfile(profile.id, {content_views_count: !!el.content_views_count ? el.content_views_count : '0', is_bravourl_content_views_count_update: !!profile.is_bravourl_content_views_count_update ? '1' : '0', is_content_views_count_update: !!profile.is_content_views_count_update ? '1' : '0'}, this.company_id).pipe(
                  tap(result => {
                    profile.content_views_count = result.content_views_count.toString();
                    profile.is_content_views_count_update = result.is_content_views_count_update;
                    profile.is_bravourl_content_views_count_update = result.is_bravourl_content_views_count_update;
                    profile.views_updated_at = result.views_updated_at;
                    profile.views_update_at = result.views_update_at;
                  }),
                  catchError(err => {
                    console.log("catchError22", err)
                    return of(false)
                  })
                )
              } else {
                return of(el)
              }
            }),
            tap(el => {
              profile.isRefreshed = false;
            })
          )
        } else if (profile && profile.channel && profile.channel.platform_id == 4) {
          return this.taskService.getFacebookViews(profile.id, {}).pipe(
            catchError(err => {
              console.log("catchError3", err)
              return of(false)
            }),
            switchMap(el => {
              if (!!el) {
                return this.taskService.editProfile(profile.id, {content_views_count: !!el.content_views_count ? el.content_views_count : '0', is_bravourl_content_views_count_update: !!profile.is_bravourl_content_views_count_update ? '1' : '0', is_content_views_count_update: !!profile.is_content_views_count_update ? '1' : '0'}, this.company_id).pipe(
                  tap(result => {
                    profile.content_views_count = result.content_views_count.toString();
                    profile.is_content_views_count_update = result.is_content_views_count_update;
                    profile.is_bravourl_content_views_count_update = result.is_bravourl_content_views_count_update;
                    profile.views_updated_at = result.views_updated_at;
                    profile.views_update_at = result.views_update_at;
                  }),
                  catchError(err => {
                    console.log("catchError33", err)
                    return of(false)
                  })
                )
              } else {
                return of(el)
              }
            }),
            tap(el => {
              profile.isRefreshed = false;
            })
          )
        } else {
          if (profile.content_url) {
            return this.taskService.getLinkData(profile.content_url, this.company_id, (profile.company_id != this.company_id ? profile.company_id : null), profile.channel_id).pipe(
              catchError(err => {
                console.log("catchError4", err)
                return of(false)
              }),
              switchMap(el => {
                if (!!el) {
                  return this.taskService.editProfile(profile.id, {content_views_count: !!el.views_count ? el.views_count : '0', is_bravourl_content_views_count_update: !!profile.is_bravourl_content_views_count_update ? '1' : '0', is_content_views_count_update: !!profile.is_content_views_count_update ? '1' : '0'}, this.company_id).pipe(
                    tap(result => {
                      profile.content_views_count = result.content_views_count.toString();
                      profile.is_content_views_count_update = result.is_content_views_count_update;
                      profile.is_bravourl_content_views_count_update = result.is_bravourl_content_views_count_update;
                      profile.views_updated_at = result.views_updated_at;
                      profile.views_update_at = result.views_update_at;
                    }),
                    catchError(err => {
                      console.log("catchError44", err)
                      return of(false)
                    })
                  )
                } else {
                  return of(el)
                }
              }),
              tap(el => {
                profile.isRefreshed = false;
              })
            )
          } else {
            profile.isRefreshed = false;
            return of(false)
          }
        }
      })).subscribe({
        next: (next) => {
          console.log("next", next);
          count++;
          this.ls.requests$.next({
            value: Math.round((100 / (this.profileCollection.selected.length)) * (count)),
            target: "Posts update number of views"
          })
        },
        complete: () => {
          console.log("complete");
          this.isRefreshed = false;
          this.profileCollection.clear();
        },
        error: (error) => {
          this.isRefreshed = false;
          console.log(error)
        }
      })
    )
  }


  refreshViewCounts(profile) {
    profile.isRefreshed = true;

    if (profile && profile.channel && profile.channel.platform_id == 1) {
      this.attachSubscriptions(
        this.taskService.getYouTubeViews(profile.id, {}).pipe(
          switchMap(el => {
            return this.taskService.editProfile(profile.id, {content_views_count: !!el.content_views_count ? el.content_views_count : '0', is_bravourl_content_views_count_update: !!profile.is_bravourl_content_views_count_update ? '1' : '0', is_content_views_count_update: !!profile.is_content_views_count_update ? '1' : '0'}, this.company_id).pipe(
              tap(result => {
                profile.content_views_count = result.content_views_count.toString();
                profile.is_content_views_count_update = result.is_content_views_count_update;
                profile.is_bravourl_content_views_count_update = result.is_bravourl_content_views_count_update;
                profile.views_updated_at = result.views_updated_at;
                profile.views_update_at = result.views_update_at;
              })
            )
          }),
          retry(3)
        ).subscribe((resp:any) => {
          console.log("refreshViewCounts", resp)
          profile.isRefreshed = false;
        },
        error => {
          profile.isRefreshed = false;
          this.layoutService.showSnackBar({name: ''}, marker(error), SnackBarItem)
        })
      )
    } else if (profile && profile.channel && profile.channel.platform_id == 3) {
      this.attachSubscriptions(
        this.taskService.getTikTokViews(profile.id, {}).pipe(
          switchMap(el => {
            return this.taskService.editProfile(profile.id, {content_views_count: !!el.content_views_count ? el.content_views_count : '0', is_bravourl_content_views_count_update: !!profile.is_bravourl_content_views_count_update ? '1' : '0', is_content_views_count_update: !!profile.is_content_views_count_update ? '1' : '0'}, this.company_id).pipe(
              tap(result => {
                profile.content_views_count = result.content_views_count.toString();
                profile.is_content_views_count_update = result.is_content_views_count_update;
                profile.is_bravourl_content_views_count_update = result.is_bravourl_content_views_count_update;
                profile.views_updated_at = result.views_updated_at;
                profile.views_update_at = result.views_update_at;
              })
            )
          }),
          retry(3)
        ).subscribe((resp:any) => {
          console.log("refreshViewCounts", resp)
          profile.isRefreshed = false;
        },
        error => {
          profile.isRefreshed = false;
          this.layoutService.showSnackBar({name: ''}, marker(error), SnackBarItem)
        })
      )
    } else if (profile && profile.channel && profile.channel.platform_id == 4) {
      this.attachSubscriptions(
        this.taskService.getFacebookViews(profile.id, {}).pipe(
          switchMap(el => {
            return this.taskService.editProfile(profile.id, {content_views_count: !!el.content_views_count ? el.content_views_count : '0', is_bravourl_content_views_count_update: !!profile.is_bravourl_content_views_count_update ? '1' : '0', is_content_views_count_update: !!profile.is_content_views_count_update ? '1' : '0'}, this.company_id).pipe(
              tap(result => {
                profile.content_views_count = result.content_views_count.toString();
                profile.is_content_views_count_update = result.is_content_views_count_update;
                profile.is_bravourl_content_views_count_update = result.is_bravourl_content_views_count_update;
                profile.views_updated_at = result.views_updated_at;
                profile.views_update_at = result.views_update_at;
              })
            )
          }),
          retry(3)
        ).subscribe((resp:any) => {
          console.log("refreshViewCounts", resp)
          profile.isRefreshed = false;
        },
        error => {
          profile.isRefreshed = false;
          this.layoutService.showSnackBar({name: ''}, marker(error), SnackBarItem)
        })
      )
    } else {
      this.attachSubscriptions(
        this.taskService.getLinkData(profile.content_url, this.company_id, (profile.company_id != this.company_id ? profile.company_id : null), profile.channel_id).pipe(
          switchMap(el => {
            return this.taskService.editProfile(profile.id, {content_views_count: !!el.views_count ? el.views_count : '0', is_bravourl_content_views_count_update: !!profile.is_bravourl_content_views_count_update ? '1' : '0', is_content_views_count_update: !!profile.is_content_views_count_update ? '1' : '0'}, this.company_id).pipe(
              tap(result => {
                profile.content_views_count = result.content_views_count.toString();
                profile.is_content_views_count_update = result.is_content_views_count_update;
                profile.is_bravourl_content_views_count_update = result.is_bravourl_content_views_count_update;
                profile.views_updated_at = result.views_updated_at;
                profile.views_update_at = result.views_update_at;
              })
            )
          })
        ).subscribe((resp:any) => {
          console.log("refreshViewCounts", resp)
          profile.isRefreshed = false;
        },
        error => {
          profile.isRefreshed = false;
        })
      )
    }
  }

  // onScrollProfiles() {
  //   this.attachSubscriptions(
  //     fromEvent(this.profilesContainer.panel.nativeElement, "scroll").pipe(
  //       filter((e:any) => e.target.scrollTop >=  e.target.scrollHeight - e.target.offsetHeight - 400),
  //       map(() => this.profilesContainer.panel.nativeElement.scrollTop),
  //       debounceTime(200),
  //       distinctUntilChanged()
  //     ).subscribe(() => {
  //         if (this.profilesPage <= this.profilesPagination['pageCount']) {
  //           this.getProfiles(this.company_id, this.profilesPage);
  //         }
  //       }
  //     )
  //   )
  // }

  changeBackupFil(val) {
    console.log('changeBackupFil val', val)
    this.filter.patchValue({
      is_live_stream_backup: val === "zero" ? '' : val
    })

    this.filterUrls();
  }

  // getProfiles(company_id, page) {
  //   this.attachSubscriptions(
  //     this.companyService.getProfiles(company_id, page).pipe(
  //       tap(el => {
  //         this.profilesPagination = {
  //           'pageCount': el.headers.get('x-pagination-page-count'),
  //           'perPage': el.headers.get('x-pagination-per-page'),
  //           'totalCount': el.headers.get('x-pagination-total-count'),
  //           'currentPage': el.headers.get('x-pagination-current-page'),
  //         }
  //       }),
  //       map(el => el.body)
  //     ).subscribe(resp => {
  //       console.log("getProfiles", resp)
  //       if (page == 1) {
  //         resp.unshift({
  //           id: 0,
  //           company_id: this.company_id,
  //           platform_id: 0,
  //           channel_account_id: 0,
  //           name: "Without channel",
  //           url: "",
  //           created_at: 0,
  //           updated_at: 0,
  //           groups: [],
  //           channelAccount: null
  //         })
  //       }
  //       resp.forEach(profile => {
  //         if (this.profiles.filter(x => x.id == profile.id).length == 0) {
  //           this.profiles.push(...resp);
  //         }
  //       })
  //       this.profiles$.next(this.profiles.slice());
  //       this.profilesPage = this.profilesPage + 1;
  //     })
  //   )
  // }

  searchInMobile(element: ElementRef) {
    this.filter.patchValue({
      q: element['value']
    })
    this.filterUrls();
  }

  openMobFilter() {
    this.layoutService.overflowHidden();
    this.mobile_filter_wrp.nativeElement.classList.add("active");
  }

  closeMobFilter() {
    this.layoutService.overflowAuto();
    this.mobile_filter_wrp.nativeElement.classList.remove("active");
  }

  sort(val) {
    this.filterUrls(null, val)
  }

  checkActions(e, task) {
    e.preventDefault();
    e.stopPropagation();
    this.attachSubscriptions(
      this.taskService.getActions(task.id, 1, this.company.id).subscribe(resp => {
        if (resp.body.length) {
          this.dialog.open(NotificationsBar, {
            backdropClass: 'backdrop_under_header',
            panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
            data: {
              notifications: resp.body,
              employees: [],
              taskStatuses: this.taskStatuses,
              company: this.company,
              user: this.user,
              task: task,
              header: false,
              tasks: this.urls,
              initCompanyId: this.company_id,
              empl_status: this.company.permissions.includes('owner') || this.company.permissions.includes('admin') || this.company.permissions.includes('manager'),
              pagination: {
                'pageCount': resp.headers.get('x-pagination-page-count'),
                'perPage': resp.headers.get('x-pagination-per-page'),
                'totalCount': resp.headers.get('x-pagination-total-count'),
                'currentPage': resp.headers.get('x-pagination-current-page'),
              },
            }
          });
        }
      })
    )
  }

  getGroup(id) {
    if (!this.groups || !this.groups.find(el => el.id == id)) {
      return false;
    }
    return this.groups.find(el => el.id == id)
  }  

  // getEmployee(id) {
  //   if (!this.employees) {
  //     return false;
  //   }
  //   return this.employees.find(el => el.id == id)
  // }  

  // getPartner(id) {
  //   if (!this.partners) {
  //     return false;
  //   }
  //   return this.partners.find(el => el.partner_company_id == id)
  // }

  selectChange(e) {
    // console.log(e)
    // console.log(this.filter.value);
    // this.closeExecFilter();
    this.filterUrls();
  }

  // selectOperation(e, id, jobTypeSelect) {
  //   e.preventDefault();
  //   e.stopPropagation();

  //   this.filter.patchValue({
  //     operation_id: id,
  //   })

  //   jobTypeSelect.close()
  //   this.closeExecFilter();
  //   this.filterUrls();

  // }

  removePlan(val) {
    this.attachSubscriptions(
      this.companyService.editScheduledPost(val.id, {task_id: 0, task_channel_id: 0}).pipe(
        switchMap(x => {
          return this.taskService.editProfile(val.task_channel_id, {content_status_id: 1}, this.company_id)
        })
      ).subscribe(resp => {
        val.task_id = resp.task_id;
        val.task_channel_id = resp.task_channel_id;
        this.updateScheduledPost(resp)
      })
    )
  }

  setEditCard(val) {
    this.close();
    const dialogRef = this.dialog.open(SetEditCardComponent, {
      disableClose: true,
      data: {
        user: this.user,
        company: this.company,
        activeLang: this.activeLang,
        val: val,
        needChannel: true
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log("addScheduledPost", result)
        if (!!result && result.event == 'update') {
          this.updateScheduledPost(result.data)
        }
      })
    )
  }

  addScheduledPost(val?) {
    this.close();
    const dialogRef = this.dialog.open(AddAndEditScheduledPostComponent, {
      disableClose: true,
      data: {
        user: this.user,
        company: this.company,
        activeLang: this.activeLang,
        val: val
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log("addScheduledPost", result)
        if (!!result && result.event == 'add') {
          this.updateScheduledPost(result.data)
        }
      })
    )
  }

  goToCards(filter_type) {
    let params = {company_id: this.company_id};

    let url = `${this.origin}/tasks/?company_id=${this.company_id}`

    let chanels_ids = []
    if (this.profileCollection && this.profileCollection.selected.length) {
      this.profileCollection.selected.forEach(el => {
        if (!chanels_ids.includes(el.channel_id)) {
          chanels_ids.push(el.channel_id)
        }
      })
    }
    if (chanels_ids.length) {
      let x = `&channel_id=${chanels_ids.join(',')}`
      url = url + x
    }

    window.open(url, '_blank')

    // this.router.navigate(['/tasks'], { queryParams: params});
  }

  goToDashboard() {
    this.router.navigate(['/urls-dashboard'], { queryParams: {company_id: this.company_id}});
  }

  onOpenMonthContext(e, val) {
    console.log(e, val);
    e.preventDefault()
    this.open(e,val)
  }

  onOpenContext(e, val) {
    console.log("onOpenContext", e, val)
    e.preventDefault();
    this.open(e,val)
  }

  updateScheduledPost(post) {
    this.companyService.getExpandScheduledPosts('1', this.company_id, {id:post.id}, '10').pipe(
      map(x => x.body),
      tap(urls => {
        urls.map(url => {
          // url.start = moment(url.content_published_at * 1000);
          // url.start = startOfDay(new Date());
          url.start = new Date(url.publish_at * 1000);
          url.title = url.name || marker("Without name");
          url.color = colors[6];
          url.actions = this.actions;
          // url.allDay = true;
          url.cssClass = 'publish_url';

          let by = ''
          if ((this.filter.value.group_id.length) && (this.filter.value.channel_id.length || this.filter.value.channel_platform_id || this.filter.value.channel_platform_id.toString() == '0')) {
            by = 'combine'
          } else {
            if ((this.filter.value.group_id.length) && !(this.filter.value.channel_id.length || this.filter.value.channel_platform_id || this.filter.value.channel_platform_id.toString() == '0')) {
              by = 'group'
            } else {
              by = 'platform'
            }
          }
      
          url.meta = {
            type:'content',
            filterBy: by
          }
        });
      }),
    ).subscribe(res => {
      if (res && res.length) {
        if (this.urls.indexOf(this.urls.find(x => x.id == post.id))) {
          this.urls.splice(this.urls.indexOf(this.urls.find(x => x.id == post.id)), 1, res[0])
        } else {
          let con = res[0]
          // if (this.urls.length > 0 && this.urls.filter(x => x.content_type_id == con.content_type_id && x.content_published_at == con.publish_at && x.meta.type == 'publication').length > 0) {
          //   this.urls.find(x => x.content_type_id == con.content_type_id && x.content_published_at == con.publish_at && x.meta.type == 'publication').scheduledObj = con
          // } else {
          //   this.urls.push(con)
          // }
          if (this.urls.length > 0 && this.urls.filter(x => x.content_type_id == con.content_type_id && Math.abs(x.content_published_at - con.publish_at) <= 600 && x.meta.type == 'publication').length > 0) {
            let someUrls = this.urls.filter(x => x.content_type_id == con.content_type_id && Math.abs(x.content_published_at - con.publish_at) <= 600 && x.meta.type == 'publication')
            let minValue:number = 601;
            let minIndex:number = 0;
            someUrls.forEach((url,i) => {
              if (Math.abs(con.publish_at - url.content_published_at) < minValue) {
                minValue = Math.abs(con.publish_at - url.content_published_at);
                minIndex = i;
              }
            })
            someUrls[minIndex].scheduledObj = con;
          } else {
            this.urls.push(con)
          }
        }
        if (this.view.value != 'list') {
          this.outletsData = this.getOutlets(this.urls)
        }
        this.refreshCalendar.next()
      }
    })
  }

  openComments(profile) {
    const dialogRef = this.dialog.open(CommentsComponent, {
      panelClass: ['without_paddings_modal', 'comments_modal'],
      data: {
        profile: profile,
        company: this.company,
        task: profile.task,
        host: this.host,
        imgRoute: this.imgRoute,
        origin: this.origin,
        user: this.user,
        activeLang: this.activeLang,
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log("SortToApproveFiles res", result)
      })
    )
  }

  retryPublish(profile) {
    this.attachSubscriptions(
      this.taskService.editProfile(profile.id, {content_status_id: 6}, this.company_id).subscribe(resp => {
        this.refreshService.refreshUrls$.next({url_id: profile.id, company_id: this.company_id})
      }, error => {
        this.layoutService.showSnackBar({name: ''}, marker("You can not publish content at this time. Please, choose another time."), SnackBarItem)
      })
    )
  }

  setProfileUpdate(post) {
    this.attachSubscriptions(
      this.taskService.editProfile(post.id, {is_get_data_queue: 1}, this.company_id).subscribe(resp => {
        post.is_get_data_queue = resp.is_get_data_queue;
      })
    )
  }

  multiSelectChannelThumb() {
    let lenSelection = this.profileCollection.selected.filter(x => !!x.channel && x.channel.platform_id == 1).length;
    let count = 0;
    if (!lenSelection) {
      this.layoutService.showSnackBar({name: 'There are no YouTube channels selected through which you can set the thumbnail'}, '', SnackBarItem)
      return
    }
    let x:any = {
      company: this.company,
      task: this.profileCollection.selected.filter(x => !!x.channel && x.channel.platform_id == 1)[0].task,
      channel: this.profileCollection.selected.filter(x => !!x.channel && x.channel.platform_id == 1)[0],
      work: undefined,
      user: this.user,
      thumbFile: undefined,
      only: 'image',
      is_multi: false,
      no_matches: true
    }
    // if (is_photo) {
    //   x.selectedFiles = this.morePhotos;
    //   x.thumbFile = this.data.file;
    // }

    const dialogRef = this.dialog.open(SelectFileComponent, {
      data: x
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log(result);
        if (!!result && result.event == 'select' && result.data) {
          from(this.profileCollection.selected.filter(x => !!x.channel && x.channel.platform_id == 1)).pipe(
            concatMap((post:any) => {
              return this.taskService.setChannelThumbnail({
                task_channel_id: post.id,
                thumbnail_file_id: result.data.id
              }).pipe(
                catchError(error => {
                  return of(error)
                }),
                tap(x => {
                  this.refreshService.refreshUrls$.next({url_id: post.id, company_id: this.company_id})
                })
              )
            })
          ).subscribe({
            next: (next) => {
              console.log("next multiBackup", next);
              this.ls.requests$.next({
                value: Math.round((100 / lenSelection) * (count+1)),
                target: "Change Thumbnail",
              })
              count++;
            },
            complete: () => {
              console.log("complete multiBackup");
              this.profileCollection.clear();
            },
            error: (error) => {
              console.log("error multiBackup", error)
            }
          })
        }
      })
    )
  }

  multiRetryPublishToFirst() {
    console.log("multiRetryPublishToFirst", this.profileCollection.selected);
    let lenSelection = this.profileCollection.selected.length;
    let count = 0;
    from(this.profileCollection.selected).pipe(
      concatMap((profile:any) => {
        let filterData = {
          channel_id: profile.channel_id,
          content_type_id: profile.content_type_id,
          publish_at_from: moment().format("X")
        }

        return this.companyService.getExpandPublishScheduledPosts('3', this.company_id, {...filterData}, '200').pipe(
          switchMap(contents => {
            let urlsData:any = {
              channel_id: profile.channel_id,
              content_type_id: profile.content_type_id,
              content_published_at_from: moment().format("X"),
              order_by: 'content_published_at_desc'
            };
        
            return this.taskService.getExpandPublishUrls('1', this.company_id, {...urlsData}, '200').pipe(
              map(expUrls => {
                return {    
                  urls: expUrls.body,
                  contents: contents.body
                }
              })
            ).pipe(
              switchMap(obj => {
                console.log(profile.id, obj)
                let urls = obj.urls;
                let regularFreeDateTimes = []
                urls.forEach(x => {
                  x.scheduledObj = {};
                  x.name = x.content_name;
                })

                obj.contents.forEach(con => {
                  if (urls.length > 0 && urls.filter(x => x.content_published_at == con.publish_at).length > 0) {
                    urls.find(x => x.content_published_at == con.publish_at).scheduledObj = con
                  } else {
                    con.is_con = true;
                    con.content_published_at = con.publish_at;
                    urls.push(con);
                    regularFreeDateTimes.push(con);
                  }
                })

                urls.sort(function(a, b) {
                  return (a.content_published_at - b.content_published_at);
                })
                regularFreeDateTimes.sort(function(a, b) {
                  return (a.content_published_at - b.content_published_at);
                })


                console.log("urls", urls)
                console.log("regularFreeDateTimes", regularFreeDateTimes)
                if (regularFreeDateTimes.length) {
                  return this.taskService.editProfile(profile.id, {content_published_at: regularFreeDateTimes[0].content_published_at, content_status_id: 6}, this.company_id).pipe(
                    tap(res => {
                      this.refreshService.refreshUrls$.next({url_id: profile.id, company_id: this.company_id})
                      this.ls.requests$.next({
                        value: Math.round((100 / lenSelection) * (count+1)),
                        target: "Retry publish to first available slot",
                      })
                      count++;
                    }), 
                    catchError(error => {
                      this.ls.requests$.next({
                        value: Math.round((100 / lenSelection) * (count+1)),
                        target: "Retry publish to first available slot",
                      })
                      count++;
                      return of(error)
                    })
                  )
                } else {
                  this.ls.requests$.next({
                    value: Math.round((100 / lenSelection) * (count+1)),
                    target: "Retry publish to first available slot",
                  })
                  count++;
                  return of(null)
                }
              })
            )
          })
        )
      })
    ).subscribe({
      next: (next) => {
        console.log("next multiRetryPublishToFirst", next);
      },
      complete: () => {
        console.log("complete multiRetryPublishToFirst");
        this.profileCollection.clear();
      },
      error: (error) => {
        console.log("error multiRetryPublishToFirst", error)
      }
    })
    // this.profileCollection.selected.forEach(item => {
    //   multiData.push({
    //     "path": `/api/task-channel/${item.id}/`,
    //     "query": {company_id: this.company_id},
    //     "method": "POST",
    //     "body": Object.assign({
    //       [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token')
    //   }, {content_status_id: 6}) 
    //   })
    // });

    // this.attachSubscriptions(
    //   this.taskService.multiRequest(multiData).subscribe(resp => {
    //     console.log(resp);
    //     this.profileCollection.selected.forEach((item, ind) => {
    //       this.refreshService.refreshUrls$.next({url_id: item.id, company_id: this.company_id})

    //       if (ind == this.profileCollection.selected.length - 1) {
    //         this.profileCollection.clear()
    //       }
    //     });

    //   })
    // )

    
  }
  
  showFilesInterfaceCard(task, work?, loc?) {
    const dialogRef = this.dialog.open(InterfaceComponent, {
      backdropClass: ['backdrop_under_header', 'file_backdrop_under_header'],
      panelClass: !this.is_mobile ? ['file_manager_modal', 'show_header'] : 'file_manager_modal',
      data: {
        company: this.company,
        task: task,
        work: work != undefined ? work : 0,
        user: this.user,
        loc: loc != undefined ? loc : "",
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == 'update') {

        }
      })
    )
  }

  addProfile(element) {
    const dialogRef = this.dialog.open(AddTaskProfileComponent, {
      disableClose: true,
      data: {
        company: this.company,
        company_id: this.company_id,
        task: element
      }
    });
    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == "Add") {
          this.refreshService.refreshTask$.next({company_id: this.company_id, task_id: element.id})
        }
      })
    )
  }

  openTagConnection(task) {
    const dialogRef = this.dialog.open(TagTaskComponent, {
      backdropClass: 'backdrop_under_header',
      panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
      data: {
        company: this.company,
        user: this.user,
        task: task,
        is_mobile: this.is_mobile,
        activeLang: this.activeLang
      }
    });
    
    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
      })
    )
  }
  
  openNotes(item) {
    const dialogRef = this.dialog.open(NotesComponent, {
      data: {
        company: this.company,
        company_id: this.company_id,
        user: this.user,
        type: 'task',
        item: item,
        is_mobile: this.is_mobile
      }
    });
    
    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
      })
    )
  }

  toShortCut(e, task) {
    this.attachSubscriptions(
      this.taskBarService.addBarItem({company_id: this.company.id, task_id: task.id, task_operation_id: 0, discussion_id: 0}).subscribe(res => {
        this.minimizeService.minimizes$.next(res)
      }, error => {
        console.log("error toShortCut")
      })
    )
  }

  openAccessSettings(task) {
    const dialogRef = this.dialog.open(SharedOptionsComponent, {
      data: {
        company_id: this.company_id,
        user: this.user,
        task: task,
        is_mobile: this.is_mobile
      }
    });
    
    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
      })
    )
  }

  anCardScen(task) {
    const dialogRef = this.dialog.open(CardScenarioComponent, {
      data: {
        company_id: this.company_id,
        company: this.company,
        task: task,
        user: this.user,
        host: this.host,
        imgRoute: this.imgRoute,
        activeLang: this.activeLang,
      }
    });
    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        // this.refreshService.refreshTask$.next({company_id: this.company_id, task_id: task.id})
      })
    )
  }

  
  addChannel(element) {
    console.log(element)
    const dialogRef = this.dialog.open(AddChannelToTaskComponent, {
      disableClose: true,
      data: {
        company: this.company,
        company_id: this.company_id,
        task: element
      }
    });
    
    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == "Add") {
          this.refreshService.refreshTask$.next({company_id: this.company_id, task_id: element.id})
        }
      })
    )
  }

  migrateTargetFiles(task) {
    const dialogRef = this.dialog.open(MigrateTargetFilesComponent, {
      panelClass: ['chats_dialog'],
      data: {
        company: this.company,
        target: {
          task_id: task.id,
          task_operation_id: 0
        },
        user: this.user,
        host: this.host,
      }
    });
    
    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        this.refreshService.refreshTask$.next({company_id: this.company_id, task_id: task.id})
      })
    )
  }
  
  addMultiWorks(is_all_results: boolean = false) {
    // console.log(this.taskCollection.selected);

    let initData:any = {
      user: this.user,
      company: this.company,
      company_id: this.company_id,
    }

    if (this.profileCollection.selected.length > 0) {
      initData.taskCollection = Array.from(new Map(
        [...this.profileCollection.selected].map(item => [item.task.id, item.task])
      ).values());
    }

    const dialogRef = this.dialog.open(WorkMultiAddComponent, {
      disableClose: true,
      data: initData
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
        this.profileCollection.clear()
      })
    )
  }

  importFilesV2() {
    // console.log(this.taskCollection.selected);
    if (!!this.gettingJobs) {
      return
    }

    this.gettingJobs = true;

    let initData:any = {
      user: this.user,
      company: this.company,
      company_id: this.company_id,
    }

    
    if (this.profileCollection.selected.length > 0) {
      initData.taskCollection = Array.from(new Map(
        [...this.profileCollection.selected].map(item => [item.task.id, item.task])
      ).values());
    }

    this.attachSubscriptions(
      forkJoin(initData.taskCollection.map(task => this.taskService.getCardJobs(this.company_id, {task_id: task.id}).pipe(
        tap(jobs => {
          task.operations = jobs;
        })
      ))).subscribe(resp => {
        this.gettingJobs = false;
        console.log(resp)
        const dialogRef = this.dialog.open(ImportFilesV2Component, {
          disableClose: true,
          data: initData
        });

        this.attachSubscriptions(
          dialogRef.afterClosed().subscribe(result => {
            console.log(result)
            this.profileCollection.clear()
          })
        )
      })
    )

  }

  openTargetValuesCard(info, element) {
    let initData:any = {
      company: this.company,
      task: element
    }

    initData.target = {
      task_id: info[0],
      task_operation_id: info[1],
      discussion_id: info[2]
    }
    if (element && element.company_id != this.company.id) {
      initData.target.partner_company_id = element.company_id
    }

    const dialogRef = this.dialog.open(TargetParametersComponent, {
      backdropClass: ['parameters_modal_backdrop'],
      panelClass: ['without_paddings_modal', 'parameters_modal'],
      data: initData
    });

    // this.attachSubscriptions(
    //   dialogRef.afterClosed().subscribe(result => {
    //     this.refreshService.refreshTask$.next({company_id: this.company_id, task_id: element.id})
    //   })
    // )
  }

  openTargetValuesCards(info, element) {
    let initData:any = {
      company: this.company,
      task: element
    }

    initData.target = {
      task_id: info[0],
      task_operation_id: info[1],
      discussion_id: info[2]
    }
    if (element && element.company_id != this.company.id) {
      initData.target.partner_company_id = element.company_id
    }

    if (this.profileCollection.selected.length > 0) {
      initData.selectedCards = Array.from(new Map(
        [...this.profileCollection.selected].map(item => [item.task.id, item.task])
      ).values());
    }

    const dialogRef = this.dialog.open(TargetParametersComponent, {
      backdropClass: ['parameters_modal_backdrop'],
      panelClass: ['without_paddings_modal', 'parameters_modal'],
      data: initData
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
        this.profileCollection.clear()
      })
    )
  }

  multiRetryPublish() {
    
    console.log(this.profileCollection.selected);
    let multiData = [];

    this.profileCollection.selected.forEach(item => {
      multiData.push({
        "path": `/api/task-channel/${item.id}/`,
        "query": {company_id: this.company_id},
        "method": "POST",
        "body": Object.assign({
          [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token')
      }, {content_status_id: 6}) 
      })
    });

    this.attachSubscriptions(
      this.taskService.multiRequest(multiData).subscribe(resp => {
        console.log(resp);
        this.profileCollection.selected.forEach((item, ind) => {
          this.refreshService.refreshUrls$.next({url_id: item.id, company_id: this.company_id})

          if (ind == this.profileCollection.selected.length - 1) {
            this.profileCollection.clear()
          }
        });

      })
    )
  }

  editScheduledPost(post) {
    this.close()
    const dialogRef = this.dialog.open(AddAndEditScheduledPostComponent, {
      disableClose: true,
      data: {
        user: this.user,
        company: this.company,
        activeLang: this.activeLang,
        post: post
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
        if (!!result && result.event == 'edit') {
          this.updateScheduledPost(post)
        }
      })
    )
  }

  getPriority(id) {
    if (!this.prioritys) {
      return false;
    }
    return this.prioritys.find(el => el.id == id)
  } 

  goToRegularPublications(e) {
    e.preventDefault();
    this.router.navigate(['/regular-publications'], { queryParams: {company_id: this.company_id}});
  }

  filterReset() {
    // this.filter.patchValue({
    // })
    this.router.navigate(['/urls'], { queryParams: {company_id: this.company_id, order_by: "content_published_at_desc"}});
    if (this.is_mobile) {
      this.layoutService.overflowAuto();
      this.mobile_filter_wrp.nativeElement.classList.remove("active");
    }
  }

  // resetSearchParams() {
  //   this.filter.patchValue({
  //   custom_id: "",
  //   task_company_id: '',
  //   })
  // }

  getCountSumm(arr, startIndex) {
    let count = 0

    for (startIndex; startIndex < arr.length; startIndex++) {
      const element = arr[startIndex];
      count = count + element.count
    }
    
    return count
  }

  changeActiveStatus(id) {
    this.filter.patchValue({
      content_status_id: id
    })

    this.filterUrls()
  }

  // if (id == 0) {
  //   this.activeStatus = [0];
  // } else {
  //   this.activeStatus = [...id];
  // }

  toNoPublishUrls() {
    this.filter.patchValue({
      content_published_at_from: '',
      content_published_at_to: 0
    })
    this.filterUrls();
  }

  filterUrls(op_status_id = null, order_by = null, is_btn = false) {
    this.closeDateFilter()
    // this.closeOpenFilter();
    this.filterCount = this.filterCount + 1;
    this.filter.patchValue({
      count: this.filterCount
    })
    
    let filterData = {...this.filter.value};
    if (!!filterData.content_published_at_from) {
      filterData.content_published_at_from = moment(filterData.content_published_at_from._d).format("X");
    }
    if (!!filterData.content_published_at_to) {
      filterData.content_published_at_to = moment(filterData.content_published_at_to._d).format("X");
    }
    if (!!filterData.task_channel_published_at_from) {
      filterData.task_channel_published_at_from = moment(filterData.task_channel_published_at_from._d).format("X");
    }
    if (!!filterData.task_channel_published_at_to) {
      filterData.task_channel_published_at_to = moment(filterData.task_channel_published_at_to._d).format("X");
    }
    if (!!filterData.analytics_views_count_from) {
      filterData.analytics_views_count_from = moment(filterData.analytics_views_count_from._d).format("X");
      this.filter.patchValue({
        summary_views_count_from: 1
      })
      filterData.summary_views_count_from = 1;
    }
    if (!!filterData.analytics_views_count_to) {
      filterData.analytics_views_count_to = moment(filterData.analytics_views_count_to._d).format("X");
    } else {
      filterData.analytics_views_count_to = ''
    }

    if (!filterData.analytics_views_count_from && !filterData.analytics_views_count_to) {
      this.filter.patchValue({
        summary_views_count_from: ''
      })

      filterData.summary_views_count_from = '';
    }

    
    if (!!filterData.created_at_from) {
      filterData.created_at_from = moment(filterData.created_at_from._d).format("X");
    }
    if (!!filterData.created_at_to) {
      filterData.created_at_to = moment(filterData.created_at_to._d).format("X");
    }

    if (!!order_by || order_by == "0") {
      filterData.order_by = order_by
    }
    
    let params = {
      company_id: this.company_id
    };

    // if (is_btn) {
    //   this.filter.removeControl('id');
    // }

    Object.keys(filterData).filter(x => x != 'preset_id').forEach(element => {
      if ((element.indexOf("parameter_value_id") != -1 || ['status_id', 'group_id', 'channel_id', 'no_channel_id'].includes(element)) && filterData[element].length) {
        params[element] = filterData[element].join(',')
      } else if (filterData[element].toString() != '') {
        params[element] = filterData[element]
      }
    });


    // console.log("filterData.content_published_at_to", filterData.content_published_at_to)
    if (filterData.content_published_at_to === 0) {
      params['content_published_at_to'] = '0';
    }
    
    if (filterData.is_content_views_count_update === '0') {
      params['is_content_views_count_update'] = '0';
    }
    if (filterData.is_bravourl_content_views_count_update === '0') {
      params['is_bravourl_content_views_count_update'] = '0';
    }

    console.log("params45555555555555555555555", params)
    this.router.navigate(['/urls'], { queryParams: params });

    if (this.is_mobile) {
      this.layoutService.overflowAuto();
      if (this.mobile_filter_wrp) {
        this.mobile_filter_wrp.nativeElement.classList.remove("active");
      }
    }
  }

  exportToCsv() {

    let bufferUrls = JSON.parse(JSON.stringify(this.urls, this.getCircularReplacer()))
    let bufferPage = this.page;
    let bufferPagination = this.pagination;
    let length = bufferPagination['pageCount']-bufferPagination['currentPage'];
    let count = 0;
    this.ls.requests$.next({
      value: Math.round((100 / length) * (count+1)),
      target: "Getting .csv"
    })
    this.getBufferUrls(bufferPage, bufferUrls, bufferPagination, length, count)
  }

  getBufferUrls(n, array, pagination, length, count) {
    this.isBufferLoad = true;
    this.urlsBufferSub = this.taskService.getExpandUrls(n, this.company_id, {...this.filter.value}, ['list', 'iggrid', 'ytgrid'].includes(this.view.value) ? (!this.is_mobile ? '20' : '10') : '200').pipe(
      tap(el => {
        pagination = {
          'pageCount': el.headers.get('x-pagination-page-count'),
          'perPage': el.headers.get('x-pagination-per-page'),
          'totalCount': el.headers.get('x-pagination-total-count'),
          'currentPage': el.headers.get('x-pagination-current-page')
        }
      }),
      map(el => el.body),
      tap(urls => {
        urls.map(url => {
          // url.start = moment(url.content_published_at * 1000);
          // url.start = startOfDay(new Date());
          url.start = new Date(url.content_published_at * 1000);
          url.title = url.content_name || marker("Without name");
          url.color = colors[url.content_status_id];
          // url.allDay = true;
          url.cssClass = 'publish_url';
          let by = ''
          if ((this.filter.value.group_id.length) && (this.filter.value.channel_id.length || this.filter.value.channel_platform_id || this.filter.value.channel_platform_id.toString() == '0')) {
            by = 'combine'
          } else {
            if ((this.filter.value.group_id.length) && !(this.filter.value.channel_id.length || this.filter.value.channel_platform_id || this.filter.value.channel_platform_id.toString() == '0')) {
              by = 'group'
            } else {
              by = 'platform'
            }
          }
      
          url.meta = {
            type:'publication',
            filterBy: by
          }
        });
      }),
      distinctUntilChanged()
    ).subscribe(resp => {


      
      console.log("getUrls", resp)
      
      if (n == 1) {
        array = resp;
      } else {
        array.push(...resp);
      }
      n++;
      count++;
      this.ls.requests$.next({
        value: Math.round((100 / length) * (count+1)),
        target: "Getting .csv"
      })

      if (+pagination['currentPage'] < +pagination['pageCount'] && +pagination['pageCount'] > 0) {
        this.getBufferUrls(n, array, pagination, length, count)
      } else {
        this.ls.requests$.next({
          value: 100,
          target: "Getting .csv"
        })
        this.uploadService.dataToCsv(array, `Reports Urls at ${moment().format("MMM DD Y hh-mm a")}`);
      }
      this.isBufferLoad = false;
    },
    error => {
      this.isBufferLoad = false;
    })
    
  }

  getCircularReplacer() {
    const ancestors:any = [];
    return function (key, value) {
      if (typeof value !== "object" || value === null) {
        return value;
      }
      // `this` is the object that value is contained in,
      // i.e., its direct parent.
      while (ancestors.length > 0 && ancestors.at(-1) !== this) {
        ancestors.pop();
      }
      if (ancestors.includes(value)) {
        return "[Circular]";
      }
      ancestors.push(value);
      return value;
    };
  }

  getTaskStatus() {
    this.attachSubscriptions(
      this.taskService.getTaskStatuses(this.company_id).subscribe(resp => {
        this.taskStatuses = resp.slice()
        this.taskStatuses.unshift({
          id: 0,
          name: marker('All statuses')
        });
      })
    )
  }

  getOperationsStatus() {
    this.attachSubscriptions(
      this.taskService.getOperationsStatus().subscribe(resp => {
        this.operationStatuses = resp.slice();
        this.operationStatuses.unshift({
          id: 0,
          name: marker('All statuses')
        });
      })
    )
  }

  profileDialog(value) {
    let returnDialog;
    switch (value) {
      case 'PublishedAt':
        returnDialog = EditPublishedAtComponent;
        break
      case 'Status':
        returnDialog = EditStatusComponent;
        break
      case 'Channel':
        returnDialog = AddChannelToTaskComponent;
        break
      case 'Name':
        returnDialog = EditContentNameComponent;
        break
    }
    return returnDialog
  }

  dblEditProfile(profile, value) {
    const dialogRef = this.dialog.open(this.profileDialog(value), {
      disableClose: true,
      data: {
        company: this.company,
        company_id: this.company_id,
        task: profile.task,
        profile: profile,
        is_edit: true
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        
        if (!!result && result.event == 'edit') {
          // this.getTask(true);
          this.refreshService.refreshUrls$.next({url_id: profile.id, company_id: this.company_id})
        }
      })
    )
  }

  deleteScheduledPost(post) {
    this.close()
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: post,
        target: marker("scheduled post")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.companyService.deleteScheduledPost(post.id).subscribe(resp => {
            this.urls.splice(this.urls.indexOf(this.urls.find(x => x.id == post.id)), 1)
            this.refreshCalendar.next()
          })
        )
      }
    });

  }

  deleteProfile(task, profile, k) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: task,
        target: marker("profile")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.taskService.deleteProfile(profile.id).subscribe(resp => {
            this.urls.splice(k, 1)
          })
        )
      }
    });

  }

  setIsBackup(post, val) {
    let publParams = JSON.parse(JSON.stringify(post.publishing_params));
    if (!!val) {
      publParams.live_stream_url_backup = 'rtmp://b.rtmp.youtube.com/live2?backup=1';
      publParams.live_stream_to_backup =  publParams.live_stream_url_backup.split('?')[0] + '/' + publParams.live_stream_key + "?backup=1";
    }
    publParams.is_backup = val;
    this.attachSubscriptions(
      this.taskService.editProfile(post.id, {
        publishing_params: publParams,
      }, this.company_id).subscribe(resp => {
        post.publishing_params = resp.publishing_params;
        // this.refreshService.refreshUrls$.next({url_id: post.id, company_id: this.company_id})
      })
    )
  }

  addLive(element, profile?) {
    let x:any = {
      company: this.company,
      company_id: this.company_id,
      task: element
    }
    if (profile) {
      x.profile = profile;
      x.is_edit = true
    }
    const dialogRef = this.dialog.open(AddEditLiveStreamComponent, {
      disableClose: true,
      data: x
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && (result.event == "Add" || result.event == 'edit')) {
          this.refreshService.refreshUrls$.next({url_id: profile.id, company_id: this.company_id})
        }
      })
    )
  }

  stopLive(element, profile) {
    this.attachSubscriptions(
      this.taskService.editProfile(profile.id, {
        task_id: element.id,
        content_status_id: 4,
      }, this.company_id).subscribe(resp => {
        this.refreshService.refreshUrls$.next({url_id: profile.id, company_id: this.company_id})
      })
    )
  }

  multiSetPrivacyStatus() {
    const dialogRef = this.dialog.open(SetPrivacyStatusComponent, {
      disableClose: true,
      data: {
        company: this.company,
        company_id: this.company_id,
        collection: this.profileCollection.selected
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == 'update') {
          this.profileCollection.selected.forEach(x => {
            this.refreshService.refreshUrls$.next({url_id: x.id, company_id: this.company_id})
          })
          this.profileCollection.clear();
        }
      })
    )
  }

  setPrivacyStatus(element, profile) {
    const dialogRef = this.dialog.open(SetPrivacyStatusComponent, {
      disableClose: true,
      data: {
        company: this.company,
        company_id: this.company_id,
        task: element,
        profile: profile
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {

      })
    )
  }

  startLive(element, profile) {
    this.attachSubscriptions(
      this.taskService.editProfile(profile.id, {
        task_id: element.id,
        content_status_id: 6,
      }, this.company_id).subscribe(resp => {
        this.refreshService.refreshUrls$.next({url_id: profile.id, company_id: this.company_id})
      })
    )
  }

  editProfile(element, profile) {
    if (profile.content_type_id == '106') {
      this.addLive(element, profile);
      return
    }
    const dialogRef = this.dialog.open(AddTaskProfileComponent, {
      disableClose: true,
      data: {
        company: this.company,
        company_id: this.company_id,
        task: element,
        profile: profile,
        is_edit: true
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        
        if (!!result && result.event == 'edit') {
          // this.getTask(true);
          this.refreshService.refreshUrls$.next({url_id: profile.id, company_id: this.company_id})
        }
      })
    )
  }

  getContentPlans(n) {
    console.log("FILTER getContentPlans", this.filter.value)
    let filterData = {...this.filter.value};
    filterData.publish_at_from = filterData.content_published_at_from;
    filterData.publish_at_to = filterData.content_published_at_to;

    delete filterData.content_published_at_from;
    delete filterData.content_published_at_to;

    this.isLoad = true;
    this.contentSub = this.companyService.getExpandScheduledPosts(n, this.company_id, {...filterData}, ['list', 'iggrid', 'ytgrid'].includes(this.view.value) ? (!this.is_mobile ? '20' : '10') : '200').pipe(
      tap(el => {
        this.contentPagination = {
          'pageCount': el.headers.get('x-pagination-page-count'),
          'perPage': el.headers.get('x-pagination-per-page'),
          'totalCount': el.headers.get('x-pagination-total-count'),
          'currentPage': el.headers.get('x-pagination-current-page'),
        }
      }),
      map(el => el.body),
      tap(urls => {
        urls.map(url => {
          // url.start = moment(url.content_published_at * 1000);
          // url.start = startOfDay(new Date());
          url.start = new Date(url.publish_at * 1000);
          url.title = url.name || marker("Without name");
          url.color = colors[6];
          url.actions = this.actions;
          // url.allDay = true;
          url.cssClass = 'publish_url';

          let by = ''
          if ((this.filter.value.group_id.length) && (this.filter.value.channel_id.length || this.filter.value.channel_platform_id || this.filter.value.channel_platform_id.toString() == '0')) {
            by = 'combine'
          } else {
            if ((this.filter.value.group_id.length) && !(this.filter.value.channel_id.length || this.filter.value.channel_platform_id || this.filter.value.channel_platform_id.toString() == '0')) {
              by = 'group'
            } else {
              by = 'platform'
            }
          }
      
          url.meta = {
            type:'content',
            filterBy: by
          }
        });
      }),
      distinctUntilChanged()
    ).subscribe(resp => {

      console.log("getContentPlans", resp)

      this.contents.push(...resp);
      this.refreshCalendar.next()

      // console.log(this.totalInfo);

      console.log("this.contents ", this.contentPage, this.contents)

      this.contentPage = this.contentPage + 1;
      this.isLoad = false;
      if (['list', 'iggrid', 'ytgrid'].includes(this.view.value)) {
        if (+this.contentPagination['currentPage'] < 2 && +this.contentPagination['currentPage'] != +this.contentPagination['pageCount'] && +this.contentPagination['pageCount'] > 0) {
          this.getContentPlans(this.contentPage);
        } else {
          // console.log("this.contents", this.contents);
          // console.log("this.urls", this.urls);
          this.contents.forEach(con => {
            
            // console.log("this.urls.length", this.urls.length);
            // console.log("this.urls.filter(x => x.content_type_id == con.content_type_id && x.content_published_at == con.publish_at && x.meta.type == 'publication')", this.urls.filter(x => x.content_type_id == con.content_type_id && x.content_published_at == con.publish_at && x.meta.type == 'publication'));
            if (this.urls.length > 0 && this.urls.filter(x => x.content_type_id == con.content_type_id && Math.abs(x.content_published_at - con.publish_at) <= 600 && x.meta.type == 'publication').length > 0) {
              let someUrls = this.urls.filter(x => x.content_type_id == con.content_type_id && Math.abs(x.content_published_at - con.publish_at) <= 600 && x.meta.type == 'publication')
              let minValue:number = 601;
              let minIndex:number = 0;
              someUrls.forEach((url,i) => {
                if (Math.abs(con.publish_at - url.content_published_at) < minValue) {
                  minValue = Math.abs(con.publish_at - url.content_published_at);
                  minIndex = i;
                }
              })
              someUrls[minIndex].scheduledObj = con;
            } else {
              this.urls.push(con)
            }
          })
          this.refreshCalendar.next()
        }
      } else {
        if (+this.contentPagination['pageCount'] > 0 && +this.contentPage <= +this.contentPagination['pageCount']) {
          this.getContentPlans(this.contentPage);
        } else {

          // console.log("resp", resp);
          // console.log("this.urls", this.urls);
          this.contents.forEach(con => {
            
            // console.log("this.urls.length", this.urls.length);
            // console.log("this.urls.filter(x => x.content_type_id == con.content_type_id && x.content_published_at == con.publish_at && x.meta.type == 'publication')", this.urls.filter(x => x.content_type_id == con.content_type_id && x.content_published_at == con.publish_at && x.meta.type == 'publication'));
            if (this.urls.length > 0 && this.urls.filter(x => x.content_type_id == con.content_type_id && Math.abs(x.content_published_at - con.publish_at) <= 600 && x.meta.type == 'publication').length > 0) {
              let someUrls = this.urls.filter(x => x.content_type_id == con.content_type_id && Math.abs(x.content_published_at - con.publish_at) <= 600 && x.meta.type == 'publication')
              let minValue:number = 601;
              let minIndex:number = 0;
              someUrls.forEach((url,i) => {
                if (Math.abs(con.publish_at - url.content_published_at) < minValue) {
                  minValue = Math.abs(con.publish_at - url.content_published_at);
                  minIndex = i;
                }
              })
              someUrls[minIndex].scheduledObj = con;
            } else {
              this.urls.push(con)
            }
          })
          this.refreshCalendar.next()

          console.log("END 2", this.urls);
          this.outletsData = this.getOutlets(this.urls)
          console.log("END OUTLETS", this.outletsData)
        }
      }
    },
    error => {
      this.isLoad = false;
    })
    
  }

  getAvailability(outlet) {
    this.attachSubscriptions(
      this.chatService.getChatsAvailabilityCount(this.company_id, outlet.content_type_id, outlet.channel_id, 1).pipe(
        tap(el => {
          outlet.availability = el.headers.get('x-pagination-total-count');
        })
      ).subscribe(res => {
        console.log("getAvailability", outlet)
      })
    )
  }

  getOutlets(arr) {
    let outlets = [];


    let fil = this.dashFilters[this.dashForm.get('missing').value];

    console.log("fil outle", fil)

    arr.forEach(el => {
      let item = {...el}
      if (!!item.publish_at) {
        item.content_published_at = item.publish_at
      }
      if (outlets.filter(x => x.channel_id == el.channel_id && x.content_type_id == el.content_type_id).length == 0) {
        outlets.push({
          channel_id: item.channel_id,
          content_type_id: item.content_type_id,
          channel: item.channel,
          contentType: item.contentType,
          filterPlan: 0,
          filterReel: 0,
          plan: 0,
          real: 0,
          order: 9999999999,
          arr: [item]
        })
      } else {
        outlets.find(x => x.channel_id == el.channel_id && x.content_type_id == el.content_type_id).arr.push(item)
      }
    });
    
    outlets.forEach(outlet => {
      outlet.arr.sort((a,b) => {
        return +a.content_published_at - +b.content_published_at;
      })
      if (outlet.arr.find(x => !x.content_status_id && moment(x.content_published_at*1000).isSameOrAfter(moment()))) {
        outlet.order =  +outlet.arr.find(x => !x.content_status_id && moment(x.content_published_at*1000).isSameOrAfter(moment())).content_published_at - +moment().format("X");
      }

      if (!!fil && !!fil.from) {
        outlet.filterPlan = outlet.arr.filter(x => moment(x.content_published_at*1000).isSameOrAfter(fil.from) && moment(x.content_published_at*1000).isSameOrBefore(fil.to) && (x.meta && x.meta.type == 'content')).length;
        console.log("fil outle vals", outlet)
      }
      outlet.plan = outlet.arr.filter(x => (x.meta && x.meta.type == 'content') || !!x.scheduledObj).length;
      outlet.real = outlet.arr.filter(x => x.meta && x.meta.type == 'publication').length;
      outlet.groupedItemsByDay = [];
      let date = moment(this.filter.get('content_published_at_from').value);

      const numDays = date.clone().daysInMonth();
      console.log("END numDays", numDays);
    
      for (let i = 0; i < numDays; i++) {
        const currentDay = date.clone().add(i,'d').format("X");
        outlet.groupedItemsByDay.push({ day: currentDay, dayMoment: date.clone().add(i,'d'), active: moment().startOf('day').format("X") == currentDay, items: [] });
      }
      outlet.arr.forEach(item => {
        if (outlet.groupedItemsByDay.find(x => x.day == moment(item.content_published_at * 1000).startOf('day').format('X'))) {
          outlet.groupedItemsByDay.find(x => x.day == moment(item.content_published_at * 1000).startOf('day').format('X')).items.push(item)
        }
      })
    });

    outlets.sort((a,b) => {
      return +a.order - +b.order;
    })

    return outlets
  }

  sortUrls(val) {
    switch (val) {
      case 'content_views_count_desc':
        this.urls.sort((a,b) => {
          return b.content_views_count - a.content_views_count;
        })
        break;
      case 'content_views_count':
        this.urls.sort((a,b) => {
          return a.content_views_count - b.content_views_count;
        })
        break;
      case 'content_published_at_desc':
        this.urls.sort((a,b) => {
          return +(b.content_published_at || b.publish_at) - +(a.content_published_at || a.publish_at);
        })
        break;
      case 'content_published_at':
        this.urls.sort((a,b) => {
          return +(a.content_published_at || a.publish_at) - +(b.content_published_at || b.publish_at);
        })
        break;
      case 'analytics_views_count_desc':
        this.urls.sort((a,b) => {
          return +(b.content_published_at || b.publish_at) - +(a.content_published_at || a.publish_at);
        })
        break;
      case 'summary_views_count':
        this.urls.sort((a,b) => {
          return +(a.content_published_at || a.publish_at) - +(b.content_published_at || b.publish_at);
        })
        break;
      case 'id_desc':
        this.urls.sort((a,b) => {
          return b.id - a.id;
        })
        break;
      case 'id':
        this.urls.sort((a,b) => {
          return a.id - b.id;
        })
        break;
    }
  }

  multiBackup() {
    let lenSelection = this.profileCollection.selected.filter(u => !!u.external_content_id).length;
    let count = 0;
    if (!lenSelection) {
      this.layoutService.showSnackBar({name: 'All of selected posts has no external content id, you cannot duplicate them.'}, '', SnackBarItem)
      return
    }
    from(this.profileCollection.selected.filter(u => !!u.external_content_id)).pipe(
      concatMap((post:any) => {
        // let filterData = {
        //   channel_id: profile.channel_id,
        //   content_type_id: profile.content_type_id,
        //   publish_at_from: moment().format("X")
        // }

        return this.taskService.getTaskChannelFile(this.company_id, {task_channel_id: post.id}).pipe(
          switchMap(files => {
            let sbmtData = {
              task_id: post.task_id,
              channel_id: post.channel_id,
              content_url: post.content_url,
              content_name: post.content_name,
              is_content_views_count_update: post.is_content_views_count_update,
              is_bravourl_content_views_count_update: post.is_bravourl_content_views_count_update,
              content_image: post.content_image,
              content_filename: post.content_filename,
              external_content_id: post.external_content_id,
              content_type_id: post.content_type_id,
              content_published_at: post.content_published_at,
              content_status_id: 6,
              content_views_count: post.content_views_count,
              publishing_params: post.publishing_params,
            }
            sbmtData.publishing_params.live_stream_url = "rtmp://b.rtmp.youtube.com/live2";
            sbmtData.publishing_params.live_stream_to = ((sbmtData.publishing_params.live_stream_url.length && sbmtData.publishing_params.live_stream_url[sbmtData.publishing_params.live_stream_url.length - 1] == '/' ? sbmtData.publishing_params.live_stream_url : (sbmtData.publishing_params.live_stream_url + '/')) + sbmtData.publishing_params.live_stream_key) + "?backup=1";
            return this.taskService.addProfile(sbmtData, this.company_id).pipe(
              switchMap(res => {
                if (files.length) {
                   return forkJoin([...files.map(fileData => this.taskService.setTaskChannelFile({
                    company_id: fileData.company_id,
                    task_channel_id: res.id,
                    file_id: fileData.file_id,
                    is_to_upload: !!fileData.is_to_upload ? 1 : 0,
                    is_live_stream_content: !!fileData.is_live_stream_content ? 1 : 0
                  }, this.company_id).pipe(
                    catchError((error) => {
                      res.hasError = true;
                      return of(null)
                    })
                  ))]).pipe(
                    map((newFiles) => {
                      return {
                        newPost: res,
                        files: files,
                        newFiles: newFiles
                      }
                    }),
                  )
                } else {
                  return of({
                    newPost: res,
                    files: files
                  })
                }
              })
            )
          })
        )
      })
    ).subscribe({
      next: (next) => {
        console.log("next multiBackup", next);
        this.refreshService.refreshUrls$.next({url_id: next.newPost.id, company_id: this.company_id, new: true})
        this.ls.requests$.next({
          value: Math.round((100 / lenSelection) * (count+1)),
          target: "Backup",
        })
        count++;
      },
      complete: () => {
        console.log("complete multiBackup");
        this.profileCollection.clear();
      },
      error: (error) => {
        console.log("error multiBackup", error)
      }
    })
  }

  postClickHandler(event, url, index) {
    if (event) {
      if (!event.target.closest('.no_select')) {
        if (event.ctrlKey || event.metaKey) { // для Mac OS
          event.preventDefault();
          event.stopPropagation();
          // Если нажат Ctrl или Command (на Mac), то добавляем или удаляем из выбранных
          const selectedIndex = this.profileCollection.selected.indexOf(url);
          if (selectedIndex === -1) {
            this.profileCollection.select(url);
          } else {
            this.profileCollection.deselect(url)
          }
          return
        } else if (event.shiftKey) {
          event.preventDefault();
          event.stopPropagation();
          // Если нажат Shift и уже есть выбранные элементы
          if (this.profileCollection.selected.length === 0) {
            // Если нет выбранных элементов, начинаем выбор с первого элемента
            this.profileCollection.select(url);
          } else {
            // Если уже есть выбранные элементы
            const firstSelectedIndex = this.urls.findIndex(x => x.id == this.profileCollection.selected[0].id);
            console.log("firstSelectedIndex", firstSelectedIndex)
            const start = Math.min(firstSelectedIndex, index);
            const end = Math.max(firstSelectedIndex, index);
            console.log("SE", start, end)
            this.profileCollection.clear();
            for (let i = start; i <= end; i++) {
              this.profileCollection.select(this.urls[i]);
            }
          }
          return
        } 
      }
    }
  }

  setMultyAutoUpdate() {
    let multiData = [];

    this.profileCollection.selected.forEach(item => {
      if ([101, 102, 202, 203, 301, 401, 404].includes(item.content_type_id) && item.content_status_id == 4) {
        multiData.push({
          "path": `/api/task-channel/${item.id}/`,
          "query": {company_id: this.company.id},
          "method": "POST",
          "body": {
            [this.sm.localStorageGetItem('csrf_param')]: this.sm.localStorageGetItem('csrf_token'),
            is_content_views_count_update: 1,
            is_bravourl_content_views_count_update: 1,
          }
        })
      }
    });

    if (multiData.length) {
      this.attachSubscriptions(
        this.taskService.multiRequest(multiData).subscribe(resp => {
          this.layoutService.showSnackBar({name: ''}, `Auto update for ${multiData.length} profile(s) succsess!`, SnackBarItem)
          this.profileCollection.selected.forEach(x => {
            this.refreshService.refreshUrls$.next({url_id: x.id, company_id: this.company.id})
          })
          this.profileCollection.clear()
        })
      )
    } else {
      this.layoutService.showSnackBar({name: ''}, `Selected ${this.profileCollection.selected.length} profile(s) can't auto update.`, SnackBarItem)
    }
  }

  openChanFolder(profile, original) {
    console.log("openChanFolder", !!original.task_operation_id)
    if (!!original.task_operation_id) {
      // window.open(event.content_url, '_blank')
      this.attachSubscriptions(
        this.taskService.getWork(original.task_operation_id).subscribe(job => {
          window.open(`${this.origin}/job/${job.acm}?path=${original.location}`, '_blank');
        })
      )
    } else {
      this.attachSubscriptions(
        this.taskService.getTask(original.task_id, this.company_id).subscribe(task => {
          window.open(`${this.origin}/task/${task.acm}?path=${original.location}`, '_blank');
        })
      )
    }
  }

  getUrls(n) {
    console.log("FILTER getUrls", this.filter.value)
    this.isLoad = true;
    this.urlsSub = this.taskService.getExpandUrls(n, this.company_id, {...this.filter.value}, ['list', 'iggrid', 'ytgrid'].includes(this.view.value) ? (!this.is_mobile ? '20' : '10') : '200').pipe(
      tap(el => {
        this.pagination = {
          'pageCount': el.headers.get('x-pagination-page-count'),
          'perPage': el.headers.get('x-pagination-per-page'),
          'totalCount': el.headers.get('x-pagination-total-count'),
          'currentPage': el.headers.get('x-pagination-current-page')
        }
        if (this.pagination['currentPage'] == 1) {
          this.summaryViews = el.headers.get('x-summary-views')
          this.noPublishCount = el.headers.get('x-summary-not-published');
          console.log("GetUnPublishCount", this.noPublishCount)
        }
        console.log("this.pagination TEST", this.pagination)
      }),
      map(el => el.body),
      tap(urls => {
        urls.map(url => {
          // url.start = moment(url.content_published_at * 1000);
          // url.start = startOfDay(new Date());
          url.start = new Date(url.content_published_at * 1000);
          url.title = url.content_name || marker("Without name");
          url.color = colors[url.content_status_id];
          url.actions = this.actions;
          // url.allDay = true;
          url.cssClass = 'publish_url';
          let by = ''
          if ((this.filter.value.group_id.length) && (this.filter.value.channel_id.length || this.filter.value.channel_platform_id || this.filter.value.channel_platform_id.toString() == '0')) {
            by = 'combine'
          } else {
            if ((this.filter.value.group_id.length) && !(this.filter.value.channel_id.length || this.filter.value.channel_platform_id || this.filter.value.channel_platform_id.toString() == '0')) {
              by = 'group'
            } else {
              by = 'platform'
            }
          }
      
          url.meta = {
            type:'publication',
            filterBy: by
          }
        });
      }),
      tap(urls => {
        if (['list', 'iggrid', 'ytgrid'].includes(this.view.value)) {
          this.taskService.postData$.next(urls)
        }
      }),
      distinctUntilChanged()
    ).subscribe(resp => {

      console.log("getUrls", resp)
      
      if (this.page == 1) {
        this.urls = resp;
      } else {
        resp.forEach(el => {
          if (!this.urls.find(k => k.id == el.id)) {
            this.urls.push(el);
          }
        });
      }

      this.refreshCalendar.next();

      console.log(this.totalInfo);

      console.log("this.urls "+this.page, this.urls)
      if (+this.pagination['currentPage'] == 1) {
        this.urlsContainer.nativeElement.scrollTop = 0;
        this.urlsIGContainer.nativeElement.scrollTop = 0;
      }
      this.page = this.page + 1;
      this.isLoad = false;

      
      if (['list', 'iggrid', 'ytgrid'].includes(this.view.value)) {
        if (+this.pagination['currentPage'] < 2 && +this.pagination['currentPage'] != +this.pagination['pageCount'] && +this.pagination['pageCount'] > 0) {
          this.getUrls(this.page);
        }
      } else {
        if (+this.pagination['pageCount'] > 0 && +this.page <= +this.pagination['pageCount']) {
          this.getUrls(this.page);
        } else {
          this.getContentPlans(this.contentPage);
        }
      } 


      if (+this.pagination['currentPage'] == 2 || +this.pagination['pageCount'] <= 1) {
        // this.openTask(this.urls[0]);
        // if (this.filter.value.operation_partner_company_id && this.filter.value.operation_partner_company_id.length) {
        //   this.getGroupsPartnerCompany()
        // } else {
        // }
        // this.getEmployees();
        // if (this.company.permissions.includes('owner') || this.company.permissions.includes('admin') || this.company.permissions.includes('manager')) {
        //   this.getPartners();
        // }
        // this.getProfiles(this.company_id, this.profilesPage);
        this.getRateStats();
        // this.getContentTypes(this.activatedRoute.snapshot.queryParamMap.get('channel_platform_id'), this.contentPage);
      }
    },
    error => {
      this.isLoad = false;
    })
    
  }

  getViewColumns() {
    // console.log("getViewColumns")
    if ((this.filter.value.group_id.length) && (this.filter.value.channel_id.length || this.filter.value.channel_platform_id || this.filter.value.channel_platform_id.toString() == '0')) {
      console.log("getViewColumns 1")
      return [{
        name: ''
      }]
    }

    if ((this.filter.value.group_id.length) && !(this.filter.value.channel_id.length || this.filter.value.channel_platform_id || this.filter.value.channel_platform_id.toString() == '0')) {
      // console.log("getViewColumns 2")
      return this.getViewPlatforms()
    } else {
      // console.log("getViewColumns 3")
      return this.getViewGroups()
    }
  }

  getViewPlatforms() {
    // console.log("getViewPlatforms");
    if (!this.platforms) {
      return []
    }

    let viewPlatforms = []

    if (this.filter.value.group_id.length) {
      this.filter.value.group_id.forEach(group_id => {
        let group = this.getGroup(group_id);
        if (group && group.channels && group.channels.length) {
          group.channels.forEach(chan => {
            if (viewPlatforms.filter(x => x.id == chan.platform_id).length == 0) {
              viewPlatforms.push(chan.platform)
            }
          });
        }
      });
    }

    // let filterGroup = this.getGroup(this.filter.value.group_id)
    // if (filterGroup && filterGroup.channels && filterGroup.channels.length) {
    //   filterGroup.channels.forEach(chan => {
    //     if (viewPlatforms.filter(x => x.id == chan.platform_id).length == 0) {
    //       viewPlatforms.push(chan.platform)
    //     }          
    //   });
    // }

    if (this.urls.length) {
      this.urls.forEach(x => {
        if (!x.channel && viewPlatforms.filter(b => b.id == 0).length == 0) {
          viewPlatforms.push(this.platforms[0]);
        } else if (viewPlatforms.filter(b => b.id == x.channel.platform_id).length == 0) {
          // if ()
          // x.channel.platform.isAdd = true;
          let platform = this.getPlatformById(x.channel.platform_id)
          platform.isAdd = true;
          viewPlatforms.push(platform);
        }
      })
    }

    console.log("viewPlatforms", viewPlatforms)
    return viewPlatforms
  }

  getViewGroups() {
    if (!this.groups) {
      return []
    }



    if (this.urls.length && !this.filter.value.channel_id.length && !this.filter.value.channel_platform_id && this.filter.value.channel_platform_id.toString() == '') {
      let viewGroups = []

      this.urls.forEach(x => {
        if ((!x.task || (!!x.task && x.task.group_id == 0)) && viewGroups.filter(b => b.id == 0).length == 0) {
          viewGroups.push(this.groups[0]);
        } else if (!!x.task && viewGroups.filter(b => b.id == x.task.group_id).length == 0) {
          let group = this.getGroup(x.task.group_id)
          // group.isAdd = true;
          viewGroups.push(group);
        }
      })

      return viewGroups

    } else {
      let viewGroups = this.groups.slice()

      if (!!this.filter.value.channel_id.length || !!this.filter.value.channel_platform_id || this.filter.value.channel_platform_id.toString() == '0') {
        viewGroups = viewGroups.filter(x => this.urls.filter(b => (!b.task && x.id == 0) || (!!b.task && b.task.group_id == x.id)).length > 0)
      }
      return viewGroups
    }
  }

  userChanged({ event, newUser }) {
    event.color = newUser.color;
    event.meta.user = newUser;
    this.urls = [...this.urls];
  }

  // getGroupsPartnerCompany() {
  //   console.log("getGroupsPartnerCompany", this.filter.value.operation_partner_company_id);
  //   this.attachSubscriptions(
  //     this.companyService.getGroupsPartnerCompany(this.company.id, this.filter.value.operation_partner_company_id).subscribe(resp => {
  //       // this.partnerGroups = resp
  //       this.groups = resp;
  //       this.groups$.next(this.groups.slice());
  //       console.log("getGroupsPartnerCompany", resp);
  //     })
  //   )
  // }

  groupedEvents(array) {
    if (array && !!array.length) {
      let groupedArray = [];

      array.forEach(ev => {
        // console.log(ev);
        if (!ev.channel) {
          return
        }
        if (groupedArray.filter(x => x.platform_id == ev.channel.platform_id).length == 0) {
          let item = {
            platform_id: ev.channel.platform_id,
            profiles: [],
            contents: []
          }
          if (ev.meta.type == 'content') {
            item.contents.push(ev)
          } else {
            item.profiles.push(ev)
          }
          groupedArray.push(item);
        } else if (groupedArray.find(x => x.platform_id == ev.channel.platform_id).profiles.filter(b => b.id == ev.id).length == 0) {
          let item = groupedArray.find(x => x.platform_id == ev.channel.platform_id)
          if (ev.meta.type == 'content') {
            item.contents.push(ev)
          } else {
            item.profiles.push(ev)
          }
        }
      });

      return groupedArray;
    } else {
      return []
    }

  }

  getSumm(arr, key) {
    return arr.map(x => x[key].length).reduce((partialSum, a) => partialSum + a, 0);
  }
  
  openChat(id) {
    this.attachSubscriptions(
      this.chatService.getChatsExpand(1, this.company_id, {id: id}).pipe(
        map(res => res.body[0])
      ).subscribe(chat => {
        const goToChat = this.dialog.open(ChatsComponent, {
          backdropClass: ['backdrop_under_header', "without_pad"],
          panelClass: !this.is_mobile ? ['full_screen_dialog', 'global_chats_dialog', 'show_header'] : ['full_screen_dialog', 'global_chats_dialog'],
          position: !this.is_mobile ? { top: '58px', left: '0', right: '0', bottom: '0' } : {},
          data: {
            company: this.company,
            user: this.user,
            imgRoute: this.imgRoute,
            host: this.host,
            dialogType: 'global_chat',
            activeLang: this.activeLang, 
            chat: chat
          }
        });
      })
    )
  }

  onPartnerImgError(event){
    event.target.src = this.imgRoute+'/assets/img/partner.png'
  }

  // closeOpenFilter() {
  //   this.subFilter && this.subFilter.unsubscribe();
  //   if (this.overlayRefFilter) {
  //     this.overlayRefFilter.dispose();
  //     this.overlayRefFilter = null;
  //   }
  // }

  // openFilter(el, val) {
  //   this.closeOpenFilter();
  //   let x = el.offsetLeft;
  //   let y =  el.offsetTop + el.offsetHeight;
  //   const positionStrategy = this.overlay.position()
  //   .flexibleConnectedTo({ x, y })
  //   .withPositions([
  //     {
  //       originX: 'start',
  //       originY: 'bottom',
  //       overlayX: 'start',
  //       overlayY: 'top',
  //     }
  //   ]);

  //   this.overlayRefFilter = this.overlay.create({
  //     backdropClass: 'transparent_bg',
  //     hasBackdrop: true,
  //     positionStrategy,
  //     scrollStrategy: this.overlay.scrollStrategies.close()
  //   });

  //   this.overlayRefFilter.attach(new TemplatePortal(this.filterMenu, this.viewContainerRef, {
  //     $implicit: val
  //   }));
    
  //   this.subFilter = fromEvent<MouseEvent>(document, 'click')
  //     .pipe(
  //       filter(event => {
  //         const clickTarget = event.target as HTMLElement;
  //         return !!this.overlayRefFilter && !this.overlayRefFilter.overlayElement.contains(clickTarget);
  //       }),
  //       take(1)
  //     ).subscribe(() => this.closeOpenFilter())

  // }

  // closeOpenClientFilter() {
  //   this.subClientFilter && this.subClientFilter.unsubscribe();
  //   if (this.overlayRefClientFilter) {
  //     this.overlayRefClientFilter.dispose();
  //     this.overlayRefClientFilter = null;
  //   }
  // }

  // openClientFilter(el, val) {
  //   this.closeOpenFilter();
  //   let x = el.offsetLeft;
  //   let y =  el.offsetTop + el.offsetHeight;
  //   const positionStrategy = this.overlay.position()
  //   .flexibleConnectedTo({ x, y })
  //   .withPositions([
  //     {
  //       originX: 'start',
  //       originY: 'bottom',
  //       overlayX: 'start',
  //       overlayY: 'top',
  //     }
  //   ]);

  //   this.overlayRefClientFilter = this.overlay.create({
  //     backdropClass: 'transparent_bg',
  //     hasBackdrop: true,
  //     positionStrategy,
  //     scrollStrategy: this.overlay.scrollStrategies.close()
  //   });

  //   this.overlayRefClientFilter.attach(new TemplatePortal(this.filterClientMenu, this.viewContainerRef, {
  //     $implicit: val
  //   }));
    
  //   this.subClientFilter = fromEvent<MouseEvent>(document, 'click')
  //     .pipe(
  //       filter(event => {
  //         const clickTarget = event.target as HTMLElement;
  //         return !!this.overlayRefClientFilter && !this.overlayRefClientFilter.overlayElement.contains(clickTarget);
  //       }),
  //       take(1)
  //     ).subscribe(() => this.closeOpenClientFilter())

  // }

  goToChat(chat) {
    const goToChat = this.dialog.open(OperationChatComponent, {
      backdropClass: ['backdrop_under_header', "without_pad"],
      panelClass: ['full_screen_dialog', 'global_chats_dialog'],
      disableClose: true,
      data: {
        company: this.company,
        task: this.urls[0],
        tasks: this.urls,
        dialogType: 'operation_chat',
        operationsValues: this.operationsValues,
        work: this.urls[0].operations.find(el => el.id == this.linkTo.split("_")[2]),
        user: this.user,
        empl_status: this.company.permissions.includes('owner') || this.company.permissions.includes('admin') || this.company.permissions.includes('manager'),
        chat: chat,
        chats: this.urls[0].operations.find(el => el.id == this.linkTo.split("_")[2]).chatsInfo
      }
    });

    this.attachSubscriptions(
      goToChat.afterClosed().subscribe(result => {
        if (!!result && result.event == 'close') {
          this.chatService.postChatRead(chat.id, 0, this.company.id).subscribe(resp => {
            console.log(resp);
          })
        }
      })
    )
  }

  removeValueFromMultiple(obj) {
    let key = obj.key;
    let value = obj.id;
    let arr = this.filter.value[key];
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1)
    }
    this.filter.patchValue({
      [key]: arr
    })
    this.filterUrls()
  }

  getAllApiParameterValues() {
    this.attachSubscriptions(
      this.parametersService.getAllValues('1', this.company.id, null, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.parametersService.getAllValues(x, this.company.id).pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.allValues = [].concat(...res)
      })
    )
  }

  getViewsUpdateIntervalList() {
    this.attachSubscriptions(
      this.companyService.getViewsUpdateIntervalList().pipe(
        map(res => {
          let arr = []
          Object.keys(res).forEach(key => {
            arr.push({
              name: key,
              value: res[key]
            })
          })
          return arr
        })
      ).subscribe(resp => {
        console.log("getViewsUpdateIntervalList", resp)
        this.viewsUpdateIntervalList = resp;
      })
    )
  }
  
  getViewsUpdateIntervalListByValue(val) {
    if (!this.viewsUpdateIntervalList || this.viewsUpdateIntervalList.length == 0) {
      return null
    }
    return this.viewsUpdateIntervalList.find(x => x.value == val)
  }

  getValueById(id) {
    if (!this.allValues || this.allValues.length == 0) {
      return null
    }
    return this.allValues.find(x => x.id == id)
  }

  getStatusById(id) {
    if (!this.profileStatuses || this.profileStatuses.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.profileStatuses.find(el => el.id == id)
  }

  // getContentById(id) {
  //   if (!this.allContentTypes || this.allContentTypes.filter(el => el.id == id).length == 0) {
  //     return false;
  //   }
  //   return this.allContentTypes.find(el => el.id == id)
  // }

  getPlatformById(id) {
    if (!this.platforms || this.platforms.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.platforms.find(el => el.id == id)
  }

  getTaskStatusById(id) {
    if (!this.taskStatuses || this.taskStatuses.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.taskStatuses.find(el => el.id == id)
  }
  
  onMobScroll() {
    if (this.mobScrollSub) {
      this.mobScrollSub.unsubscribe()
    }
    this.mobScrollSub = fromEvent(window, "scroll").pipe(
      map(() => window.scrollY),
      tap((val) => {
        val > 100 ? this.isScrolledCollectionBtn = true : this.isScrolledCollectionBtn = false;
      }),
      filter(current => current >=  document.body.clientHeight - window.innerHeight - 100),
      debounceTime(300),
      distinctUntilChanged()
    ).subscribe(() => {
      if (this.page > 2 && this.page <= this.pagination['pageCount']) {
        this.getUrls(this.page);
      }
    })
  }

  openServersTable() {
    console.log('openServersTable');
    const dialogRef = this.dialog.open(ServersTableComponent, {
      backdropClass: ['ve_back_auto'],
      panelClass: ['ve_panel_auto'],
      disableClose: true,
      autoFocus: false,
      data: {
        company: this.company,
        host: this.host,
        user: this.user,
        is_mobile: this.is_mobile,
        activeLang: this.activeLang,
        filterVal: this.filter.value,
        viewVal: this.view.value,
        page: +JSON.parse(JSON.stringify(this.page)),
        pagination: JSON.parse(JSON.stringify(this.pagination)),
        urls: [...this.urls],
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
      })
    )
  }

  postsCleaner() {
    const dialogRef = this.dialog.open(PostsCleanerComponent, {
      backdropClass: ['ve_back_auto'],
      panelClass: ['ve_panel_auto'],
      disableClose: true,
      autoFocus: false,
      data: {
        company: this.company,
        host: this.host,
        is_mobile: this.is_mobile,
        activeLang: this.activeLang,
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == 'update') {
          this.filterUrls()
        }
      })
    )
  }

  managePosts() {
    console.log('openChannelsCounterTable');
    const dialogRef = this.dialog.open(ManagePostsComponent, {
      backdropClass: ['ve_back_auto'],
      panelClass: ['ve_panel_auto'],
      disableClose: true,
      autoFocus: false,
      data: {
        company: this.company,
        host: this.host,
        is_mobile: this.is_mobile,
        activeLang: this.activeLang,
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == 'update') {
          this.filterUrls()
        }
      })
    )
  }

  openChannelsCounterTable() {
    console.log('openChannelsCounterTable');
    const dialogRef = this.dialog.open(ChannelsCounterTableComponent, {
      backdropClass: ['ve_back_auto'],
      panelClass: ['ve_panel_auto'],
      disableClose: true,
      autoFocus: false,
      data: {
        company: this.company,
        host: this.host,
        per_page: this.per_page,
        is_mobile: this.is_mobile,
        activeLang: this.activeLang,
        filterVal: this.filter.value,
        viewVal: this.view.value,
        page: +JSON.parse(JSON.stringify(this.page)),
        pagination: JSON.parse(JSON.stringify(this.pagination)),
        urls: [...this.urls],
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
      })
    )
  }

  openChannelsTable() {
    console.log('openChannelsTable');
    const dialogRef = this.dialog.open(ChannelsTableComponent, {
      backdropClass: ['ve_back_auto'],
      panelClass: ['ve_panel_auto'],
      disableClose: true,
      autoFocus: false,
      data: {
        company: this.company,
        host: this.host,
        is_mobile: this.is_mobile,
        activeLang: this.activeLang,
        filterVal: this.filter.value,
        viewVal: this.view.value,
        page: +JSON.parse(JSON.stringify(this.page)),
        pagination: JSON.parse(JSON.stringify(this.pagination)),
        urls: [...this.urls],
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
      })
    )
  }
  
  log() {
    console.log("pagination", this.pagination)
    console.log("page", this.page)
    console.log("urls", this.urls)
  }

  onScroll() {
    if (this.scrollSub) {
      this.scrollSub.unsubscribe()
    }
    // tap((e:any) => console.log(e)),
    this.scrollSub = fromEvent(this.view.value == this.CalendarView.List ? this.urlsContainer.nativeElement : (this.view.value == this.CalendarView.IGGrid ? this.urlsIGContainer.nativeElement : this.urlsYTContainer.nativeElement), "scroll").pipe(
      tap((e:any) => console.log(e.target.scrollTop >= e.target.scrollHeight - e.target.offsetHeight - 400)),
      filter((e:any) => e.target.scrollTop >= e.target.scrollHeight - e.target.offsetHeight - 400),
      debounceTime(300)
    ).subscribe(() => {
      console.log("this.page", this.page)
      console.log("this.page > 2", this.page > 2)
      console.log("this.page <= this.pagination['pageCount']", this.page <= this.pagination['pageCount'])
      if (this.page > 2 && this.page <= this.pagination['pageCount']) {
        this.getUrls(this.page);
      }
    })
  
  }

  // @HostListener('window:scroll', ['$event']) // for window scroll events
  // onMobileScroll(e) {
  //   if (this.is_mobile) {
  //     window.pageYOffset > 100 ? this.isScrolledCollectionBtn = true : this.isScrolledCollectionBtn = false;
  //   }
  // }

  // getPartners() {
  //   this.attachSubscriptions(
  //     this.companyService.getPartners({company_id: this.company_id}).subscribe(resp => {
  //       this.partners = resp.filter(x => x.partner_company_id != 0);
  //       console.log("getPartners", this.partners)
  //       this.partners$.next(this.partners.slice())
  //     }, error => {
  //       console.log(error)
  //     })
  //   )
  // }

  closeExecFilter() {
    this.execFilterSub && this.execFilterSub.unsubscribe();
    if (this.overlayExecRefFilter) {
      this.overlayExecRefFilter.dispose();
      this.overlayExecRefFilter = null;
    }
  }

  openExecFilter(el, val) {
    this.closeExecFilter();
    let x = el.offsetLeft;
    let y =  el.offsetTop + el.offsetHeight;
    const positionStrategy = this.overlay.position()
    .flexibleConnectedTo({ x, y })
    .withPositions([
      {
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'top',
      }
    ]);

    this.overlayExecRefFilter = this.overlay.create({
      backdropClass: 'transparent_bg',
      hasBackdrop: true,
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    this.overlayExecRefFilter.attach(new TemplatePortal(this.filterUrlExecMenu, this.viewContainerRef, {
      $implicit: val
    }));
    
    this.execFilterSub = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter(event => {
          const clickTarget = event.target as HTMLElement;
          return !!this.overlayExecRefFilter && !this.overlayExecRefFilter.overlayElement.contains(clickTarget);
        }),
        take(1)
      ).subscribe(() => this.closeExecFilter())

  }

  open({ x, y }: MouseEvent, val) {
    this.close();
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo({ x, y })
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        }
      ]);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    this.overlayRef.attach(new TemplatePortal(this.taskMenu, this.viewContainerRef, {
      $implicit: val
    }));
    
    this.sub = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter(event => {
          const clickTarget = event.target as HTMLElement;
          return !!this.overlayRef && !this.overlayRef.overlayElement.contains(clickTarget);
        }),
        take(1)
      ).subscribe(() => this.close())

  }

  setBufferStatus() {
    const dialogRef = this.dialog.open(EditStatusComponent, {
      disableClose: true,
      data: {
        company: this.company,
        company_id: this.company_id,
        needStatus: true
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        
        if (!!result && result.event == 'set') {
          // this.getTask(true);
          // this.refreshService.refreshUrls$.next({url_id: profile.id, company_id: this.company_id})
          this.bufferedPage = 1;
          this.ls.requests$.next({
            value: 0,
            target: "Getting posts"
          })
          this.bufferUrls(this.bufferedPage, {event: 'set_status', content_status_id: result.data});
        }
      })
    )


  }

  bufferUrls(n, action) {
    this.isBufLoad = true;
    this.bufferUrlsSub = this.taskService.getLiteUrls(n, this.company_id, {...this.filter.value}, '100').pipe(
      tap(el => {
        this.bufferedPagination = {
          'pageCount': el.headers.get('x-pagination-page-count'),
          'perPage': el.headers.get('x-pagination-per-page'),
          'totalCount': el.headers.get('x-pagination-total-count'),
          'currentPage': el.headers.get('x-pagination-current-page')
        }
        console.log("this.bufferedPagination TEST", this.bufferedPagination)
      }),
      map(el => el.body),
      tap(urls => {
        urls.map(url => {
          // url.start = moment(url.content_published_at * 1000);
          // url.start = startOfDay(new Date());
          url.start = new Date(url.content_published_at * 1000);
          url.title = url.content_name || marker("Without name");
          url.color = colors[url.content_status_id];
          // url.allDay = true;
          url.cssClass = 'publish_url';
          let by = ''
          if ((this.filter.value.group_id.length) && (this.filter.value.channel_id.length || this.filter.value.channel_platform_id || this.filter.value.channel_platform_id.toString() == '0')) {
            by = 'combine'
          } else {
            if ((this.filter.value.group_id.length) && !(this.filter.value.channel_id.length || this.filter.value.channel_platform_id || this.filter.value.channel_platform_id.toString() == '0')) {
              by = 'group'
            } else {
              by = 'platform'
            }
          }
      
          url.meta = {
            type:'publication',
            filterBy: by
          }
        });
      }),
      distinctUntilChanged()
    ).subscribe(resp => {

      console.log("bufferUrls", resp)
      
      if (n == 1) {
        this.bufferedUrls = resp;
      } else {
        this.bufferedUrls.push(...resp);
      }
      
      this.ls.requests$.next({
        value: Math.round((this.bufferedUrls.length/+this.pagination['totalCount']) * 100),
        target: "Getting posts"
      })

      n++;
      this.isBufLoad = false;

      
      if (+this.bufferedPagination['currentPage'] < +this.bufferedPagination['pageCount'] && +this.bufferedPagination['pageCount'] > 0) {
        this.bufferUrls(n, action)
      } else {
        if (action.event == 'set_status') {
          this.multiSetStatus(this.bufferedUrls, action.content_status_id)
        }
        console.log("action", action);
        console.log("bufferedUrls", this.bufferedUrls);
      }
    },
    error => {
      this.isBufLoad = false;
    })
  }

  multiSetStatus(array, content_status_id) {
    let lenSelection = array.filter(x => x.content_status_id != content_status_id).length;
    let count = 0;
    from(array.filter(x => x.content_status_id != content_status_id)).pipe(
      concatMap((profile:any) => {
        return this.taskService.editProfile(profile.id, {content_status_id: content_status_id}, this.company_id).pipe(
          tap(res => {
            this.refreshService.refreshUrls$.next({url_id: profile.id, company_id: this.company_id})
            this.ls.requests$.next({
              value: Math.round((100 / lenSelection) * (count+1)),
              target: "Set Status",
            })
            count++;
          }), 
          catchError(error => {
            this.ls.requests$.next({
              value: Math.round((100 / lenSelection) * (count+1)),
              target: "Set Status",
            })
            count++;
            return of(error)
          })
        )
      })
    ).subscribe({
      next: (next) => {
        console.log("next multiSetStatus", next);
      },
      complete: () => {
        console.log("complete multiSetStatus");
      },
      error: (error) => {
        console.log("error multiSetStatus", error)
      }
    })
  }

  openTargetValues(info, element) {
    this.close();
    let initData:any = {
      company: this.company
    }

    initData.el = element;

    initData.targetContentPlan = {
      content_plan_id: info[0],
      content_schedule_id: info[1],
      content_schedule_item_id: info[2]
    }
    if (element.company_id != this.company.id) {
      initData.target.partner_company_id = element.company_id
    }
    const dialogRef = this.dialog.open(TargetParametersComponent, {
      backdropClass: ['parameters_modal_backdrop'],
      panelClass: ['without_paddings_modal', 'parameters_modal'],
      data: initData
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        this.updateScheduledPost(element)
      })
    )
  }
  
  close() {
    this.sub && this.sub.unsubscribe();
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }

  checkboxLabel(row?): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.profileCollection.isSelected(row) ? 'deselect' : 'select'} task ${row.name}`;
  }

  isAllSelected() {
    const numSelected = this.profileCollection.selected.length;
    const numRows = this.urls.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
        this.profileCollection.clear() :
        this.urls.forEach(row => this.profileCollection.select(row));
  }

  changeStatuses() {
    console.log(this.profileCollection.selected);
    const goToChangingStatuses = this.dialog.open(MultiEditProfilesStatusComponent, {
      disableClose: true,
      data: {
        company: this.company,
        collection: this.profileCollection.selected,
        urls: this.urls,
        profileStatuses: this.profileStatuses
      }
    });

    this.attachSubscriptions(
      goToChangingStatuses.afterClosed().subscribe(result => {
        if (!!result && result.event == 'update') {
          result.data.forEach(x => {
            this.refreshService.refreshUrls$.next({url_id: x.id, company_id: this.company_id})
          })
          this.profileCollection.clear()
        }
      })
    )
  }

  deleteProfiles() {
    console.log(this.profileCollection.selected);

    let confirmAlert =  this.bottomSheet.open(BackJobToPrevStatusComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        msg: marker(`Are you sure you want to delete ${this.profileCollection.selected.length} profile(s):`),
        profileCollection: this.profileCollection.selected,
        btn_no: "Cancel",
        btn_yes: "Delete"
      }
    });

    confirmAlert.afterDismissed().subscribe(data => {
      if (data && data.message == 'no') {
        console.log("no");
        this.profileCollection.clear()
      } else if (data && data.message == 'yes') {

        if (this.profileCollection.selected.filter(x => !!x.content_url).length > 0) {
          let newConfirmAlert =  this.bottomSheet.open(BackJobToPrevStatusComponent, {
            hasBackdrop: true,
            backdropClass: 'bottom-sheed-backdrop',
            data: {
              msg: marker(`Are you sure you want to delete ${this.profileCollection.length} profile(s):`),
              profileCollection: this.profileCollection.selected,
              hasInfoProfileCollection: this.profileCollection.selected.filter(x => !!x.content_url),
              btn_no: "Yes!",
              btn_yes: "Leave non-empty profiles."
            }
          });
      
          newConfirmAlert.afterDismissed().subscribe(data => {
            if (data && data.message == 'no') {
              forkJoin(this.profileCollection.selected.map(x => this.taskService.deleteProfile(x.id))).subscribe(resp => {
                this.profileCollection.selected.forEach(el => {
                  this.urls.splice(this.urls.find(x => x.id == el.id), 1)
                });
                this.profileCollection.clear()
                // this.getTask(true)
              })
            } else if (data && data.message == 'yes') {
              forkJoin(this.profileCollection.selected.filter(x => !x.content_url).map(x => this.taskService.deleteProfile(x.id))).subscribe(resp => {
                this.profileCollection.selected.forEach(el => {
                  this.urls.splice(this.urls.find(x => x.id == el.id), 1)
                });
                this.profileCollection.clear()
                // this.getTask(true)
              })
            }
          })
        } else {
          forkJoin(this.profileCollection.selected.map(x => this.taskService.deleteProfile(x.id))).subscribe(resp => {
            this.profileCollection.selected.forEach(el => {
              this.urls.splice(this.urls.find(x => x.id == el.id), 1)
            });
            this.profileCollection.clear()
            // this.getTask(true)
          })
        }
      }
    })
  }

  
  toggleProfile(profile) {
    this.profileCollection.toggle(profile);
  }

  getCurrencyList() {
    this.attachSubscriptions(
      this.globalDataService.getCurrencies().subscribe(resp => {
        console.log("getCurrencyList", resp)
        let currencyData = resp.slice()
        currencyData.map(x => x.summary = 0)
        this.currencyes = currencyData;
        this.totalInfo['summaryPrice'] = [...currencyData]
        console.log("this.totalInfo", this.totalInfo);
      })
    )
  }

  getOperationStatusName(id) {
    switch (+id) {
      case 1:
        return "Waiting";
        break;
      case 2:
        return "In process";
        break;
      case 3:
        return "To approve";
        break;
      case 4:
        return "Approved";
        break;
      case 5:
        return "To correct";
        break;
      case 6:
        return "Sources Needed";
        break;
      case 97:
        return "On hold";
        break;
      case 98:
        return "Ready";
        break;
      case 99:
        return "Canceled";
        break;
    }
  }



  getColumnName(id) {
    if (!this.operationsValues || this.operationsValues.filter(el => el.id == id).length == 0) {
      return '';
    }
    return this.operationsValues.find(el => el.id == id)?.translation?.name ? this.operationsValues.find(el => el.id == id).translation.name : this.operationsValues.find(el => el.id == id).name
  }

  showFilesInterface(task, work?, loc?) {
    const dialogRef = this.dialog.open(InterfaceComponent, {
      backdropClass: ['backdrop_under_header', 'file_backdrop_under_header'],
      panelClass: !this.is_mobile ? ['file_manager_modal', 'show_header'] : 'file_manager_modal',
      data: {
        company: this.company,
        task: task,
        work: work != undefined ? work : 0,
        tasks: this.urls,
        user: this.user,
        loc: loc != undefined ? loc : "",
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == 'update') {

        }
      })
    )
  }
  
  ngOnDestroy(): void {
    this.layoutService.overflowAuto();
    if (this.scrollSub) {
      this.scrollSub.unsubscribe()
    }
    if (this.mobScrollSub) {
      this.mobScrollSub.unsubscribe()
    }
    if (this.urlsSub) {
      this.urlsSub.unsubscribe()
    }
    if (this.contentSub) {
      this.contentSub.unsubscribe()
    }
    if (this.urlsDataSub) {
      this.urlsDataSub.unsubscribe();
    }
    if (this.urlsBufferSub) {
      this.urlsBufferSub.unsubscribe();
    }
    if (this.bufferUrlsSub) {
      this.bufferUrlsSub.unsubscribe();
    }
    if (this.postsDataSub) {
      this.postsDataSub.unsubscribe();
    }
    this.clearSubscriptions()
  }

}


