import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, fromEvent, interval, of, ReplaySubject, Subscription, timer } from 'rxjs';
import { LanguageService } from '../../services/common/language.service';
import { LayoutService } from '../../services/common/layout.service';
import { StorageManagerService } from '../../services/common/storage-manager.service';
import { AuthService } from '../../services/rest/auth.service';
import { CompanyService } from '../../services/rest/company.service';
import { TaskService } from '../../services/rest/task.service';
import * as moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/en-gb';
import 'moment/locale/uk';
import 'moment/locale/ru';
import { BaseClass } from '../../models/base-class';
import { NotificationsBar } from '../notifications-bar/notifications-bar';
import { MatDialog } from '@angular/material/dialog';
import { ChatsComponent } from 'src/app/components/chats/chats.component';
import { catchError, debounceTime, filter, last, map, switchMap, take, tap } from 'rxjs/operators';
import { ChatService } from '../../services/rest/chat.service';
import { FormControl } from '@angular/forms';
import { UploadService } from '../../services/rest/upload.service';
import { page_id } from '../../consts/page_id';
import { app_version } from '../../consts/app_version';
import { InterfaceComponent } from '../file-manager/dialog/interface/interface.component';
import { CreateNewTaskComponent } from 'src/app/components/create-new-task/create-new-task.component';
import { CreateTaskComponent } from 'src/app/components/atTasksDialog/create-task/create-task.component';
import { EmplCreateTaskComponent } from 'src/app/components/atTasksDialog/empl-create-task/empl-create-task.component';
import { RefreshService } from '../../services/rest/refresh.service';
import { QuestsService } from '../../services/rest/quests.service';
import { SelectCompanyComponent } from 'src/app/components/mob-task/select-company/select-company.component';
import { MobAddTaskComponent } from 'src/app/components/mob-task/mob-add-task/mob-add-task.component';
import { ActivityComponent } from '../activity/activity.component';
import { ActivityService } from '../../services/rest/activity.service';
import { TaskBarService } from '../../services/rest/task-bar.service';
import { casesModel } from '../../functions/casesModel';
import { PermissionsPipe } from '../../pipes/permissions.pipe';
import { FileService } from '../../services/rest/file.service';
import { OpenJobComponent } from 'src/app/components/workspace-pages/cases/dialogs/open-job/open-job.component';
import { MinimizeService } from '../../services/rest/minimize.service';
import { OperationChatComponent } from 'src/app/components/atTasksDialog/operation-chat/operation-chat.component';
import { OpenTaskComponent } from 'src/app/components/workspace-pages/cases/dialogs/open-task/open-task.component';
import { SwPush } from '@angular/service-worker';
import { environment } from 'src/environments/environment';
import { MobFmViewComponent } from '../mob-file-manager/dialogs/mob-fm-view/mob-fm-view.component';
import { VideoViewerComponent } from '../file-manager/dialog/video-viewer/video-viewer.component';
import { mimeTypes } from 'mime-wrapper';
import { NotificationsCenterComponent } from '../notifications-center/notifications-center.component';
import { MobNotificationsCenterComponent } from '../mob-notifications-center/mob-notifications-center.component';
import { UserScheduleComponent } from '../user-schedule/user-schedule.component';
import { UserCalendarComponent } from '../user-calendar/user-calendar.component';
import { MobShortcutsComponent } from '../navigation-bar/mob-filters/mob-shortcuts/mob-shortcuts.component';
import { Overlay, OverlayContainer, OverlayRef } from '@angular/cdk/overlay';
import { AskTextComponent } from '../ask-text/ask-text.component';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class HeaderComponent extends BaseClass implements OnInit, OnDestroy {
  @ViewChild('contextMenu') contextMenu: TemplateRef<any>;
  public imgRoute: any = "";
  public user: any = {};
  public user_id: number;
  public version: any = app_version;
  public languages: string[];
  public activeLang: any;
  public workTimer: any;
  public company: any;
  public company_id: FormControl = new FormControl(0);
  public localCompany: any;
  public taskStatuses: any;
  public canCreate: boolean = false;
  public companyes: any;
  public employees: any;
  public todayScheduleData: any;
  public barItems: any;
  public today: any;
  public uploadProgress: any;
  public paramT: any = 0;
  public allInvites: any;
  public uploadsIsOpen: boolean = false;
  public isDeskOpenWorkspaces: boolean = false;
  public isOpenDeskBurger: boolean = false;
  public isOpenBurger: FormControl = new FormControl(false);
  public isOpenSections: boolean = false;
  public isOpenLanguages: boolean = false;
  public isOpenSettings: boolean = false;
  public isOpenFinances: boolean = false;
  public isOpenAutomation: boolean = false;
  public isOpenMembers: boolean = false;
  public isOpenCategorization: boolean = false;
  public isOpenWorkspaces: boolean = false;
  public is_mobile: boolean = false;
  public createLoader: boolean = false;
  public mobStatsIsOpen: boolean = false;
  public isApplicationAlert: boolean = false;
  public invite: string = '';
  public currMonthName: any;
  public queryParams: any;
  // public unReadChatsCount: number = 0;
  public appStatusId: number;
  public unReadNotsCount: number = 0;
  public chatSub: Subscription;
  public companySub: Subscription;
  public webPushStage: number = 0;
  public _window = window;
  public envVar = environment;
  public host: any = this.envVar.host;
  public showPushModal: boolean = this.sm.localStorageGetItem('showPushModal') != '1'
  public shortcuts: boolean = !!this.sm.localStorageGetItem('shortcuts');
  public origin = window.location.origin;
  overlayRef: OverlayRef | null;
  public contextSub: Subscription;
  private contextMenuElement: HTMLElement;

  @Input() is_main_not_auth: boolean = false;

  constructor(
    private languageService: LanguageService,
    private sm: StorageManagerService,
    private auth: AuthService,
    private activityService: ActivityService,
    private taskBarService: TaskBarService,
    private minimizeService: MinimizeService,
    private taskService: TaskService,
    public viewContainerRef: ViewContainerRef,
    public overlay: Overlay,
    private dialog: MatDialog,
    private translate: TranslateService,
    private fileService: FileService,
    private router: Router,
    private uploads: UploadService,
    private refreshService: RefreshService,
    private chatService: ChatService,
    private questsService: QuestsService,
    private companyService: CompanyService,
    private activatedRoute: ActivatedRoute,
    private layoutService: LayoutService,
    private uploadService: UploadService,
    private swPush: SwPush,
  ) { super() }

  ngOnInit(): void {
    window.scroll(0,0);
    this.today = moment().utc().startOf("day").format("X");
    console.log("this.envVar", this.envVar);
    console.log("moment", moment());
    console.log("moment", moment().startOf("day"));
    console.log("moment", moment().startOf("day").format("X"));

    console.log("moment utc", moment().startOf("day").utc());
    console.log("moment utc 2", moment.utc().startOf('day'));

    
    // Получаем текущую дату в вашем часовом поясе
    const now = moment();

    // Устанавливаем время на начало текущего дня в вашем часовом поясе
    const startOfDay = now.startOf('day');

    // Переводим в формат UTC с нулевым смещением времени
    const startOfDayUTC = startOfDay.utcOffset(0, true);

    // Получаем таймстамп в формате UNIX
    this.today = startOfDayUTC.format("X");

    console.log("w8", now)
    console.log("w8 startOfDay", startOfDay)
    console.log("w8 startOfDayUTC", startOfDayUTC)
    console.log("w8 timestamp", this.today)
    // console.log("moment utc", moment().utc().startOf("day").format("X"));

    console.log("this.router", this.router)
    console.log("this.activatedRoute", this.activatedRoute)
    console.log("this.router.url", this.router.url)


    this.attachSubscriptions(
      this.isOpenBurger.valueChanges.subscribe(res => {
        if (res) {
          this.layoutService.overflowHidden();
        } else {
          this.layoutService.overflowAuto();
        }
      })
    )

    this.attachSubscriptions(
      this.auth.$userToken.subscribe(resp => {
        this.user_id = resp.user_id;
        this.sm.localStorageSetItem("csrf_param", resp.csrf_param)
        this.sm.localStorageSetItem("csrf_token", resp.csrf_token)
      })
    )
    
    this.attachSubscriptions(
      this.taskBarService.getGoToItemStream().subscribe(resp => {
        this.goToItem(resp)
        // console.log("getProgressUpload", resp)
      })
    )
    
    this.attachSubscriptions(
      this.uploads.getProgressUpload().subscribe(resp => {
        this.uploadProgress = resp
        // console.log("getProgressUpload", resp)
      })
    )

   
    this.swPush.notificationClicks.subscribe(({action, notification}) => {
      // TODO: Do something in response to notification click.
      console.log("notificationClicks action", action)
      console.log("notificationClicks notification", notification)
    });

    this.attachSubscriptions(
      this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd)  
      ).subscribe((event: NavigationEnd) => {
        if (event.url == '/main') {
          this.company = undefined;
          this.workTimer = undefined;
          this.company_id.patchValue(0)
        }
      })
    )

    this.attachSubscriptions(
      interval(1000).pipe(
        filter(() => !!this.workTimer && this.company && this.company.employees && this.company.employees[0] && ['active', 'break'].includes(this.company.employees[0].status) && !!this.company.employees[0].status_active_at)
      ).subscribe(() => {
        this.workTimer += 1;
      })
    )

    this.attachSubscriptions(
      this.companyService.getAddTaskStream().subscribe(resp => {
        if (resp) {
          this.addNewTask()
        }
      })
    )
    
 
    this.attachSubscriptions(
      this.companyService.getCompanyStream().pipe(
        debounceTime(500)
      ).subscribe(resp => {
        console.log("companySub", resp)
        if (Object.keys(resp).length) {
          this.company = resp;
          this.company_id.patchValue(this.company.id)
          
          this.getTodaySchedule()

          if (this.company && this.company.employees && this.company.employees[0] && ['active', 'break'].includes(this.company.employees[0].status) && !!this.company.employees[0].status_active_at) {
            
            let currentTime = moment();

            // Создание объекта Moment из временного штампа
            let timestampMoment = moment.unix(this.company.employees[0].status_active_at);

            // Вычисление разницы во времени
            this.workTimer = +(currentTime.diff(timestampMoment) / 1000).toFixed(0);
            console.log(`Разница во времени: ${this.workTimer}`);
          }

          this.getTaskStatus();
          let employee = resp.employees.find(x => x.user_id == this.user.id)
          if (employee) {
            this.appStatusId = employee.application_status_id
          }
          console.log("COMPANY !!!" ,resp)
          this.attachSubscriptions(
            this.questsService.getQuestEmplCount(this.company.id, employee.id).pipe(map(el => el.headers.get('x-pagination-total-count'))).subscribe(result => {
              console.log("getQuestEmplCount result", result)
              if (result > 0) {
                if (employee && employee.is_application_done == 0 && window.location.search.indexOf('questionnaires') == -1) {
                  this.isApplicationAlert = true;
                }
              }
              if (this.isApplicationAlert && this.appStatusId <= 2) {
                this.sm.localStorageSetItem("app_alert", "1");
                document.body.classList.add("has_alert");
              } else {
                document.body.classList.remove("has_alert");
                localStorage.removeItem("app_alert");
              }
              
            })
          )
          // if (!resp.permissions.includes('owner')) {
          //   this.getHeaderStats(resp);
          // }
          
          this.getEmployees(resp);
          // this.getUnReadChatsCount(resp.id)
          // this.getUnReadNotsCount(resp.id)
          if (!!resp && !!resp.employees && !!resp.employees[0]) {
            this.company['rate_target'] = resp.employees[0].rate_target
            this.company['salary'] = resp.employees[0].salary
            this.company['currency_id'] = resp.employees[0].currency_id
          }
        } else {
          this.company = undefined;
          this.workTimer = undefined;
          this.company_id.patchValue(0)
        }
      })
    )
    

    // this.attachSubscriptions(
    //   this.chatService.getUnreadChatsStream().subscribe(resp => {
    //     this.getUnReadChatsCount(this.company.id);
    //   })
    // )

    // this.attachSubscriptions(
    //   this.taskService.getUnreadNotsStream().subscribe(resp => {
    //     this.getUnReadNotsCount(this.company.id, true);
    //   })
    // )

    if (window.innerWidth <= 769) {
      this.is_mobile = true;
    } else {
      this.is_mobile = false;
    }
    this.onResize();
    this.getCompanyes();
    
    this.attachSubscriptions(
      this.refreshService.getNewWorkSpace().subscribe(resp => {
        if (resp) {
          this.getCompanyes();
        }
      })
    )

    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => this.imgRoute = el)
    )
    


    if (!this.shortcuts) {
      this.attachSubscriptions(
        this.taskBarService.getBarItemsStream().pipe(
          tap(barItems => {
  
            console.log("getBarItemsStream 1", barItems)
            barItems.forEach(barItem => {
              if (!!barItem.file_id) {
                barItem.type = 'file'
              } else if (!!barItem.task_operation_id && !!barItem.discussion_id && !barItem.file_id) {
                barItem.type = 'chat'
              } else if (!!barItem.task_operation_id && !barItem.discussion_id && !barItem.file_id) {
                barItem.type = 'job'
              } else {
                barItem.type = 'task'
              }
            });
          }),
          switchMap(barItems => {
            console.log("getBarItemsStream 2", barItems)
            if (!barItems.length) {
              console.log("getBarItemsStream 3", barItems)
              return of(barItems)
            } else {
              console.log("getBarItemsStream 4", barItems)
              return forkJoin(barItems.map(barItem => {
                if (barItem.type == 'chat') {
                  return this.chatService.getChatsExpand('1', barItem.company_id, {id: [barItem.discussion_id]}).pipe(
                    map(chatsExpand => chatsExpand.body),
                    switchMap(chats => {
                      if (chats.length != 0) {
                        barItem.switchData = chats[0];
                        return this.taskService.getOneTaskExpand(barItem.company_id, barItem.task_id).pipe(
                          catchError(err => {
                            return err
                          }),
                          map((task) => casesModel(!!task ? task.body : [undefined], [], 'update').arr[0]),
                          tap(task => {
                            if (!!task) {                  
                              // console.log("casesModel", task)
                              barItem.work = task.operations.find(x => x.id == barItem.task_operation_id);
            
                              barItem.work.employees = barItem.work.employees.filter(x => x.discussion_id == barItem.discussion_id || x.discussion_id == 0 || !x.discussion_id)
                              barItem.work.mainEmployees = barItem.work.mainEmployees.filter(x => x.discussion_id == barItem.discussion_id || x.discussion_id == 0 || !x.discussion_id)
                              barItem.work.addEmployee = barItem.work.addEmployee.filter(x => x.discussion_id == barItem.discussion_id || x.discussion_id == 0 || !x.discussion_id)
                              barItem.work.addEmployee.forEach(x => {
                                if (!x.employeeStatus && barItem.switchData.employeesStatuses.find(z => z.employee_id == x.employee_id)) {
                                  x.employeeStatus = barItem.switchData.employeesStatuses.find(z => z.employee_id == x.employee_id)
                                }
                              })
                              barItem.work.allChatEmpl = [...barItem.work.mainEmployees, ...barItem.work.addEmployee].sort((a,b) => b.is_manager - a.is_manager);
                            }
                          }),
                          map(() => barItem)
                        )
                      } else {
                        return this.taskBarService.deleteBarItem(barItem.id).pipe(
                          tap(() => {
                            barItems.splice(barItems.indexOf(barItem), 1)
                          })
                        )
                      }
                    })
                  )
                } else if (barItem.type == 'job') {
                  return this.taskService.getOneTaskExpand(barItem.company_id, barItem.task_id).pipe(
                    catchError(err => {
                      let x:any = {body: []}
                      return x
                    }),
                    map(x => x.body[0]),
                    switchMap(x => this.neededData(x, barItem.company_id).pipe(
                      map(() => casesModel([x], [], 'update')),
                      map(x => x.arr[0]),
                      tap(task => {
                        if (!!task) {
                          barItem.task = task;
                          barItem.switchData = task.operations.find(x => x.id == barItem.task_operation_id);
                        }
                      }),
                      map(() => barItem)
                    ))
                  )
                } else if (barItem.type == 'task') {
                  return this.taskService.getOneTaskExpand(barItem.company_id, barItem.task_id).pipe(
                    catchError(err => {
                      let x:any = {body: []}
                      return x
                    }),
                    map(x => x.body[0]),
                    switchMap(x => this.neededData(x, barItem.company_id).pipe(
                      map(() => casesModel([x], [], 'update')),
                      map(x => x.arr[0]),
                      tap(task => {
                        if (!!task) {
                          barItem.switchData = task;
                        }
                      }),
                      map(() => barItem)
                    ))
                  )
                } else if (barItem.type == 'file') {
                  return this.fileService.getFilesPreview(barItem.company_id, [barItem.file_id]).pipe(
                    tap(file => {
                      barItem.switchData = file[0];
                    }),
                    map(() => barItem)
                  )
                } else {
                  return of(barItem)
                }
              })).pipe(
                map(() => barItems),
                tap(barItems => {
                  console.log("tap getBarItemsStream 5", barItems)
                  this.barItems = barItems;
                })
              )
            }
          })
        ).subscribe(resp => {
          if (!!resp) {
            this.barItems = [...resp]
          }
          console.log("this.barItems", this.barItems)
        }, error => {
          console.log("this.barItems ERROR", error, this.barItems)
        })
      )
    }


    this.attachSubscriptions(
      this.minimizeService.getMinimizes().pipe(
        tap(barItem => {
          if (!!barItem.file_id) {
            barItem.type = 'file'
          } else if (!!barItem.task_operation_id && !!barItem.discussion_id && !barItem.file_id) {
            barItem.type = 'chat'
          } else if (!!barItem.task_operation_id && !barItem.discussion_id && !barItem.file_id) {
            barItem.type = 'job'
          } else {
            barItem.type = 'task'
          }
        }),
        switchMap(barItem => {
          if (barItem.type == 'chat') {
            return this.chatService.getChatsExpand('1', barItem.company_id, {id: [barItem.discussion_id]}).pipe(
              map(chatsExpand => chatsExpand.body),
              switchMap(chats => {
                if (chats.length != 0) {
                  barItem.switchData = chats[0];
                  return this.taskService.getOneTaskExpand(barItem.company_id, barItem.task_id).pipe(
                    map((task) => casesModel(task.body, [], 'update').arr[0]),
                    tap(task => {
                      // console.log("casesModel", task)
                      barItem.work = task.operations.find(x => x.id == barItem.task_operation_id);
    
                      barItem.work.employees = barItem.work.employees.filter(x => x.discussion_id == barItem.discussion_id || x.discussion_id == 0 || !x.discussion_id)
                      barItem.work.mainEmployees = barItem.work.mainEmployees.filter(x => x.discussion_id == barItem.discussion_id || x.discussion_id == 0 || !x.discussion_id)
                      barItem.work.addEmployee = barItem.work.addEmployee.filter(x => x.discussion_id == barItem.discussion_id || x.discussion_id == 0 || !x.discussion_id)
                      barItem.work.addEmployee.forEach(x => {
                        if (!x.employeeStatus && barItem.switchData.employeesStatuses.find(z => z.employee_id == x.employee_id)) {
                          x.employeeStatus = barItem.switchData.employeesStatuses.find(z => z.employee_id == x.employee_id)
                        }
                      })
                      barItem.work.allChatEmpl = [...barItem.work.mainEmployees, ...barItem.work.addEmployee].sort((a,b) => b.is_manager - a.is_manager);
                    }),
                    map(() => barItem)
                  )
                } else {
                  return this.taskBarService.deleteBarItem(barItem.id).pipe(
                    tap(() => {
                      this.barItems.splice(this.barItems.indexOf(barItem), 1)
                    }),
                    map(() => barItem)
                  )
                }
              })
            )
          } else if (barItem.type == 'job') {
            return this.taskService.getOneTaskExpand(barItem.company_id, barItem.task_id).pipe(
              map(x => x.body[0]),
              switchMap(x => this.neededData(x, barItem.company_id).pipe(
                map(() => casesModel([x], [], 'update')),
                map(x => x.arr[0]),
                tap(task => {
                  barItem.task = task;
                  barItem.switchData = task.operations.find(x => x.id == barItem.task_operation_id);
                }),
                map(() => barItem)
              ))
            )
          } else if (barItem.type == 'task') {
            return this.taskService.getOneTaskExpand(barItem.company_id, barItem.task_id).pipe(
              map(x => x.body[0]),
              switchMap(x => this.neededData(x, barItem.company_id).pipe(
                map(() => casesModel([x], [], 'update')),
                map(x => x.arr[0]),
                tap(task => {
                  barItem.switchData = task;
                }),
                map(() => barItem)
              ))
            )
          } else if (barItem.type == 'file') {
            return this.fileService.getFilesPreview(barItem.company_id, [barItem.file_id]).pipe(
              tap(file => {
                barItem.switchData = file[0];
              }),
              map(() => barItem)
            )
          } else {
            return of(barItem)
          }
        })
      ).subscribe(resp => {
        if (this.barItems.filter(x => x.task_id == resp.task_id && x.discussion_id == resp.discussion_id && x.company_id == resp.company_id && x.task_operation_id == resp.task_operation_id && x.file_id == resp.file_id).length == 0) {
          this.barItems.push(resp);
        }

        console.log("getMinimizes this.barItems", this.barItems)
      })
    )
    
    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        if (resp.is_root) {
          this.sm.localStorageSetItem("is_root", resp.is_root)
        } else {
          localStorage.removeItem("is_root");
        }
        this.user = resp;

        console.log(this.user);
      })
    )

    this.attachSubscriptions(
      this.languageService.getLangData().subscribe(resp => {
        this.translate.setDefaultLang(resp.active);
        this.languages = resp.languages;
        console.log("this.languages", this.languages)
        this.activeLang = resp.active;
        moment.locale(resp.active);
        this.currMonthName = moment().format('MMMM');
      })
    )

    this.attachSubscriptions(
      this.activatedRoute.queryParams.subscribe(params => {
        if (params.hasOwnProperty('code')) {
          this.invite = "?code=" + this.activatedRoute.snapshot.queryParamMap.get('code');
        }
      })
    )


  }

  goToFilterPage(e, comp, page) {
    console.log("goToFilterPage ", e, comp)

    if (page == '/board-list') {
      this.router.navigate(['/board-list'], { queryParams: {company_id: comp.id, order_by: "updated_desc", count: 1, operation_employee_id: e.value} });
    }
  }
  
  getEmployeesComp(comp) {
    this.attachSubscriptions(
      this.taskService.getEmployeesDyn('1', comp.id, null, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }
  
          return forkJoin(arr.map(x => this.taskService.getEmployeesDyn(x, comp.id, null, '200').pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              let conValues = [].concat(...values)
              // public employeesControl: FormControl = new FormControl();
              // public employees$: ReplaySubject<any> = new ReplaySubject<any>(1);
              comp.employeesSub = new Subscription();
              comp.employees$ = new ReplaySubject<any>(1);
              comp.employeesControl = new FormControl('')
              comp.selEmployees = conValues;
              comp.employees$.next(comp.selEmployees.slice())

              if (comp.employeesSub) {
                comp.employeesSub.unsubscribe()
              }

              comp.employeesSub = comp.employeesControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchEmoloyees(comp, resp))
            }),
          )
        }),
      ).subscribe(resp => {
        console.log("getEmployees", comp);
      })
    )
  }

  onSearchEmoloyees(comp, resp) {
    if (!comp.selEmployees) {
      return;
    }

    if (!resp) {
      comp.employees$.next(comp.selEmployees.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    comp.employees$.next(
      comp.selEmployees.filter(z => z.name.toLowerCase().indexOf(resp) > -1)
    );
  }
  
  openContext(event: MouseEvent, val, page) {
    this.closeContext();  // Close any existing context menu

    this.isDeskOpenWorkspaces = true;
    this.getEmployeesComp(val);

    // Create a container for the context menu
    const embeddedView = this.viewContainerRef.createEmbeddedView(this.contextMenu, {
      company: val,
      page: page
    });

    // Extract the root nodes from the embedded view and append them to the body
    this.contextMenuElement = embeddedView.rootNodes[0];
    this.contextMenuElement.style.zIndex = '9999999999';
    this.contextMenuElement.style.position = 'fixed';
    this.contextMenuElement.style.right = `${window.innerWidth - event.clientX}px`;
    this.contextMenuElement.style.top = `${event.clientY}px`;
    document.body.appendChild(this.contextMenuElement);

    // Listen for click events to close the context menu
    this.contextSub = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter(clickEvent => {
          const clickTarget = clickEvent.target as HTMLElement;
          return !this.contextMenuElement.contains(clickTarget);
        }),
        take(1)
      )
      .subscribe(() => this.closeContext());
  }

  closeContext() {
    if (this.contextSub) {
      this.contextSub.unsubscribe();
    }

    // Remove the context menu element from the DOM
    if (this.contextMenuElement) {
      document.body.removeChild(this.contextMenuElement);
      this.contextMenuElement = null;
    }

    // Clear the view container to prevent memory leaks
    this.viewContainerRef.clear();
    this.isDeskOpenWorkspaces = false;
  }

  getTodaySchedule() {
    this.attachSubscriptions(
      this.companyService.getScheduleItem(this.company.id, 1, '200', {day: this.today, employee_id: this.company.employees[0].id}).pipe(map(x => x.body)).subscribe(resp => {
        if (resp && resp.filter(x => x.from_time != 0 && x.to_time != 0 && x.from_time > x.day && x.to_time > x.day).length) {
          this.todayScheduleData = resp.filter(x => x.from_time != 0 && x.to_time != 0 && x.from_time > x.day && x.to_time > x.day);
        } else {
          this.todayScheduleData = undefined;
        }
        console.log("getTodaySchedule", this.todayScheduleData);
      })
    )
  }

  getSubscription(key) {
    console.log(Notification.permission);
    this.showPushModal = false;


      if (!("Notification" in window)) {
        this.dontShowPushModal();
      }
      if (Notification.permission === 'default') {
        console.log("default");
        try {
          Notification.requestPermission().then(() => {
              this.requestSubscription(key);
            }).catch(() => {
            // show permission denied error
            console.log("catch");
          });                                                                                                                                            
        } catch (error) {
            // Safari doesn't return a promise for requestPermissions and it                                                                                                                                       
            // throws a TypeError. It takes a callback as the first argument                                                                                                                                       
            // instead.
            if (error instanceof TypeError) {
              Notification.requestPermission(function (permission) {
                // If the user accepts, let's create a notification
                if (permission === "granted") {
                  this.requestSubscription(key);
                } else if (permission === 'denied') {
                  console.log("denied");
                  this.dontShowPushModal();
                }
              });
            } else {
                throw error;                                                                                                                                                                                       
            }                                                                                                                                                                                                      
        } 
        // Notification.requestPermission().then(() => {
        //   this.requestSubscription(key || "BJIzvudySeQRj--RKaJWb8fbGp3KI4ZQp5IVPANUDEaD5azIhkE_ss7sR_8pJMMf3KmsBnj3OKInlWUnnOMINZ0");
        // }).catch(() => {
        //   // show permission denied error
        //   console.log("catch");
        // });
      } else if (Notification.permission === 'denied') {
        console.log("denied");
        this.dontShowPushModal();
        // show permission is denied, please allow it error
      } else {
        this.requestSubscription(key);
      }
  }

  getBrowserName(): string {
    const agent = window.navigator.userAgent.toLowerCase();
    const browser =
      agent.indexOf('edge') > -1 ? 'Microsoft Edge'
        : agent.indexOf('edg') > -1 ? 'Chromium-based Edge'
        : agent.indexOf('opr') > -1 ? 'Opera'
        : agent.indexOf('chrome') > -1 ? 'Chrome'
        : agent.indexOf('trident') > -1 ? 'Internet Explorer'
        : agent.indexOf('firefox') > -1 ? 'Firefox'
        : agent.indexOf('safari') > -1 ? 'Safari'
        : 'other';

    return browser;
  }

  async requestSubscription(key) {
    // if (this.getBrowserName() == '32Safari') {
    //   // navigator.serviceWorker.getRegistration().then(reg => {
    //   //   console.log("navigator.serviceWorker.getRegistration()" ,reg)
    //   //   reg.pushManager.subscribe({
    //   //     userVisibleOnly: true,
    //   //     applicationServerKey: key
    //   //   }).then(sub => {
    //   //     console.log("sub", sub)
    //   //   })
    //   // })
    //   try {
    //     const reg = await navigator.serviceWorker.getRegistration();
    //     console.log("navigator.serviceWorker.getRegistration()", reg);
    //     const sub = await reg.pushManager.subscribe({
    //       userVisibleOnly: true,
    //       applicationServerKey: key,
    //     });
    //     console.log("sub", sub);
    //     this.companyService.sendSubs({ subscription: sub }).subscribe(resp => {
    //       this.sm.localStorageSetItem("showPushModal", '1')
    //     }, error => {
    //       if (error == 'Subscription already exists.') {
    //         this.sm.localStorageSetItem("showPushModal", '1');
    //       } else {
    //         this.sm.localStorageSetItem("showPushModal", '0');
    //       }
    //     })
    //   } catch (error) {
    //     console.error("An error occurred while subscribing to push notifications:", error);
    //   }
    // } else {
      try {
        console.log("requestSubscription object ", 'try');
        this.webPushStage = 1;
        const sub = await this.swPush.requestSubscription({ serverPublicKey: key});
        this.webPushStage = 2;
        console.log("requestSubscription object ", sub);
        this.companyService.sendSubs({ subscription: sub }).subscribe(resp => {
          this.sm.localStorageSetItem("showPushModal", '1')
          this.webPushStage = 3;
        }, error => {
          this.webPushStage = 4;
          if (error == 'Subscription already exists.') {
            this.sm.localStorageSetItem("showPushModal", '1');
          } else {
            this.sm.localStorageSetItem("showPushModal", '0');
          }
        })
      } catch (e) {
        this.webPushStage = 4;
        console.log("requestSubscription catch ", e);
        this.sm.localStorageSetItem("showPushModal", '0');
      }
    // }
  }

  setEmployeeStatus(e, status) {
    e.preventDefault();
    e.stopPropagation();
    this.attachSubscriptions(
      this.companyService.editEmployeee(this.company.employees[0].id, {status: status}).subscribe(resp => {
        console.log("setEmployeeStatus", resp)
        this.company.employees[0].status_active_at = resp.status_active_at;
        this.company.employees[0].status = resp.status;
      })
    )
  }

  getMobSubscription(key) {
    this.showPushModal = false;
    alert(Notification.permission);
    if (!("Notification" in window)) {
      this.dontShowPushModal();
    }
    if (Notification.permission === 'default') {
      alert("default");
      Notification.requestPermission().then(() => {
        this.requestSubscription(key || "BJIzvudySeQRj--RKaJWb8fbGp3KI4ZQp5IVPANUDEaD5azIhkE_ss7sR_8pJMMf3KmsBnj3OKInlWUnnOMINZ0");
      }).catch(() => {
        // show permission denied error
        alert("catch");
      });
    } else if (Notification.permission === 'denied') {
      alert("denied");
      this.dontShowPushModal();
      // show permission is denied, please allow it error
    } else {
      this.requestSubscription(key);
    }
  }

  async requestMobSubscription(key) {
    try {
      alert("requestSubscription object");
      const sub = await this.swPush.requestSubscription({ serverPublicKey: key});
      alert(JSON.stringify(sub));
      this.companyService.sendSubs({ subscription: sub }).subscribe(resp => {
        this.sm.localStorageSetItem("showPushModal", '1')
      })
    } catch (e) {
      alert(e);
      this.sm.localStorageSetItem("showPushModal", '0');
    }
  }

  getPathForLink(val) {
    return val.replaceAll('//', '%2F').replaceAll('/', '%2F').replaceAll(' ', '%20')
  }

  linkToItem(item) {
    console.log("linkToItem", item)
    if (item.type == "file") {
      if (item.switchData.is_dir == 1) {
        // if (!!item.switchData.task_operation_id) {

        // } else {
        //   window.open(this.origin + '/file/' + item.switchData.acm + "?path=" + this.getPathForLink(item.switchData.location + '/' + item.switchData.filename), "_blank")
        // }
        window.open(this.origin + '/file/' + item.switchData.acm + "?path=" + this.getPathForLink(item.switchData.location + '/' + item.switchData.filename), "_blank")
      } else {
        window.open(this.origin + '/file/' + item.switchData.acm, "_blank")
      }
    } else if (item.type == "chat") {
      window.open(this.origin + '/chat/' + item.switchData.acm, "_blank")
    } else if (item.type == "job") {
      window.open(this.origin + '/job/' + item.switchData.acm, "_blank")
    } else {
      window.open(this.origin + '/task/' + item.switchData.acm, "_blank")
    }
  }

  removeItem(item) {
    console.log(item);
    this.attachSubscriptions(
      this.taskBarService.deleteBarItem(item.id).subscribe(resp => {
        this.barItems.splice(this.barItems.indexOf(item), 1)
      })
    )
  }

  hidePushModal() {
    this.showPushModal = false;
  }

  dontShowPushModal() {
    this.showPushModal = false;
    this.sm.localStorageSetItem("showPushModal", '1');
  }

  goToItem(item) {
    console.log(item);
    if (item.type == "file") {
      if (item.switchData.is_dir == 1) {
        this.openFolder(item)
      } else {
        this.openFile(item)
      }
    } else if (item.type == "chat") {
      this.openChat(item)
    } else if (item.type == "job") {
      this.openJob(item)
    } else {
      this.openTask(item)
    }
  }

  goToBookmark(e, bookmark, cID) {
    if (e) {
      e.stopPropagation();
    }
    let page;
    
    let activeSections = bookmark.section;

    switch (activeSections) {
      case "Jobs":
        page = '/jobs';
        break;
      case "Chats":
        page = '/chats';
        break;
      case "Board by Date":
        page = '/board';
        break;
      case "Projects":
        page = '/projects';
        break;
      case "Board by Order":
        page = '/board-list';
        break;
      case 'Posts':
        page = '/urls';
        break;
      case 'Posts Start':
        page = '/urls-start';
        break;
      case 'Post Dashboards':
        page = '/urls-dashboard';
        break;
      case 'Outlet Dashboards':
        page = '/outlets-dashboard';
        break;
      case 'Regular Publications':
        return '/regular-publications'
        break;
      case 'Files':
        page = '/files';
        break;
      default:
        page = '/tasks';
        break;
    }
    console.log('page', page);
    this.router.navigate([page], { queryParams: page.indexOf('dashboard') != -1 ? {company_id: cID, count: 1} : Object.assign(bookmark.settings, {company_id: cID, preset_id: bookmark.id}) });
  }

  openMobShortcuts(comp, e) {
    e.preventDefault();
    console.log(comp)
    const dialogRef = this.dialog.open(MobShortcutsComponent, {
      backdropClass: ['mob_interface_backdrop'],
      panelClass: ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'],
      disableClose: true,
      autoFocus: false,
      data: {
        user: this.user,
        company: comp,
        activeLang: this.activeLang,
        is_mobile: this.is_mobile,
        needGet: true,
        favoriteBookmarks: [],
        groupedBarItems: []
      }
    });

    this.attachSubscriptions(

      dialogRef.afterClosed().subscribe(result => {
        
        if (!!result) {
          if (result.event == 'go') {
            this.goToItem(result.data)
          } else if (result.event == 'bookmark') {
            this.goToBookmark(null, result.data, result.company.id)
          }
        }
      })
    )
  }

  understandFileType(val) {
    if (!val) {
      return ""
    }
    
    if (val == 'application/pdf') {
      return 'pdf'
    } else if (val.indexOf("/") != -1) {
      return val.split('/')[0]
    } else {
      return ""
    }
  }

  download(file, company_id) {
    window.open(this.host + file.original + '?company_id=' + company_id + `&filename=${file.filename}`, '_blank');
  }

  openFolder(barItem) {
    console.log("openFolder", barItem)
    if (!barItem.task_operation_id) {
      this.openFolderTask(barItem, this.company)
    } else {
      this.openFolderJob(barItem, this.company)
    } 
  }

  openFolderJob(barItem, company) {
    console.log(this.activatedRoute.snapshot.queryParamMap.get('path'))
    this.attachSubscriptions(
      this.taskService.getOneTaskExpand(barItem.company_id, barItem.task_id).pipe(
        map(x => x.body[0]),
        switchMap(x => this.neededData(x, barItem.company_id).pipe(map(() => casesModel([x], [], 'update')),map(x => x.arr[0]))),
      ).subscribe(resp => {
        const dialogRef = this.dialog.open(OpenJobComponent, {
          backdropClass: 'backdrop_under_header',
          panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
          autoFocus: false,
          data: {
            task_id: barItem.task_id,
            task_operation_id: barItem.task_operation_id,
            task: resp,
            company: company,
            initCompanyId: barItem.company_id,
            loc: (barItem.switchData.location != '/' ? (barItem.switchData.location + "/") : barItem.switchData.location) + barItem.switchData.filename
          }
        });

        dialogRef.afterClosed().subscribe(res => {
          // this.toMainPage()
        })
      })
    )
  }


  openFolderTask(barItem, company) {
    let taskData:any = {
      task_id: barItem.task_id,
      company: company,
      initCompanyId: company.id,
      loc: (barItem.switchData.location != '/' ? (barItem.switchData.location + "/") : barItem.switchData.location) + barItem.switchData.filename
    }

    const dialogRef = this.dialog.open(OpenTaskComponent, {
      backdropClass: 'backdrop_under_header',
      panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
      autoFocus: false,
      data: taskData
    });

    dialogRef.afterClosed().subscribe(res => {
      // this.toMainPage()
    })
  }
  
  openFile(barItem) {
    this.attachSubscriptions(
      this.taskService.getOneTaskExpand(barItem.company_id, barItem.task_id).pipe(
        map(x => x.body[0]),
        switchMap(x => this.neededData(x, barItem.company_id).pipe(map(() => casesModel([x], [], 'update')),map(x => x.arr[0]))),
        switchMap(x => this.taskService.getOperations(barItem.company_id, this.activeLang).pipe(tap(u => x.operationsValues = u), map(() => x))),
      ).subscribe(resp => {
        console.log("NEXT NEXT NEXT", resp)
        let task = resp;
        let work;

        if (!(['image', 'video', 'pdf', 'audio'].includes(this.understandFileType(barItem.switchData.content_type)) || (!!mimeTypes.getType(barItem.switchData.filename) && (mimeTypes.getType(barItem.switchData.filename).indexOf("image") != -1 ||  mimeTypes.getType(barItem.switchData.filename).indexOf("video") != -1)))) {
          this.download(barItem.switchData, barItem.company_id)
          return
        }

        if (!!barItem.task_operation_id) {
          work = task.operations.find(x => x.id == barItem.task_operation_id)
        }

        if (!this.is_mobile) {
          const dialogRef = this.dialog.open(VideoViewerComponent, {
            panelClass: ['video_view_modal', 'video_viewer'],
            autoFocus: false,
            data: {
              file: barItem.switchData,
              task: task,
              work: work,
              operationsValues: task.operationsValues,
              company: this.company,
              initCompanyID: barItem.company_id,
              activeLang: this.activeLang,
              user: this.user
            }
          });
          dialogRef.afterClosed().subscribe(res => {
            // this.toMainPage()
          })
        } else {
          const dialogRef = this.dialog.open(MobFmViewComponent, {
            backdropClass: ['mob_video_viewer_backdrop'],
            panelClass: 'mob_video_viewer',
            autoFocus: false,
            data: {
              file: barItem.switchData,
              task: task,
              work: work,
              operationsValues: task.operationsValues,
              company: this.company,
              initCompanyID: barItem.company_id,
              activeLang: this.activeLang,
              user: this.user
            }
          });
          dialogRef.afterClosed().subscribe(res => {
            // this.toMainPage()
          })
        }
      })
    )

  }

  goToPage(params) {
    console.log("goToPage", params);
    this.queryParams = params
  }

  goToMobPage(page, e?) {
    if (e) {
      e.preventDefault();
    }

    console.log("goToMobPage", page);

    let params:any = JSON.parse(JSON.stringify(this.queryParams))


    if (page == '/settings') {
      params.page = 'finances_dashboard'
    }
    

    this.router.navigate([page], { queryParams: params });
  }

  openJob(item) {
    this.attachSubscriptions(
      this.taskService.getOneTaskExpand(item.company_id, item.task_id).pipe(
        map(x => x.body[0]),
        switchMap(x => this.neededData(x, item.company_id).pipe(
          map(() => casesModel([x], [], 'update')),
          map(x => x.arr[0])
        ))
      ).subscribe(resp => {
        this.dialog.open(OpenJobComponent, {
          backdropClass: !this.is_mobile ? 'backdrop_under_header': ['backdrop_under_header', 'mob_card_backdrop'],
          panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header'] : ['open_task_dialog', 'animate__animated', 'animate__slideInUp'],
          autoFocus: false,
          data: {
            initCompanyId: this.company_id.value,
            task_id: item.task_id,
            task_operation_id: item.task_operation_id,
            task: resp,
            selectedIndex: undefined,
            loc: undefined
          }
        });
      })
    )
  }

  openTask(item) {
    let taskData:any = {
      task_id: item.task_id
    }
    if (item.switchData.hasOwnProperty("operations")) {
      taskData.task = item.switchData
    }

    this.dialog.open(OpenTaskComponent, {
      backdropClass: 'backdrop_under_header',
      panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
      autoFocus: false,
      data: taskData
    });
  }

  openChat(item) {

    this.attachSubscriptions(
      this.taskService.getOneTaskExpand(item.company_id, item.task_id).pipe(
        map(x => x.body[0]),
        switchMap(x => this.neededTaskData(x, item.company_id).pipe(map(() => casesModel([x], [], 'update')),map(x => x.arr[0]))),
        switchMap(x => {
          return this.taskService.getOperations(item.company_id, this.activeLang).pipe(
            tap(val => {
              x.operationsValues = val;
            }),
            map(() => x)
          )
        })
      ).subscribe(resp => {
        console.log(resp);
        const goToChat = this.dialog.open(OperationChatComponent, {
          backdropClass: ['backdrop_under_header', "without_pad"],
          panelClass: !this.is_mobile ? ['full_screen_dialog', 'global_chats_dialog', 'show_header'] : ['full_screen_dialog', 'global_chats_dialog'],
          position: !this.is_mobile ? { top: '58px', left: '0', right: '0', bottom: '0' } : {},
          autoFocus: this.is_mobile ? false : true,
          data: {
            company: this.company,
            task: resp,
            dialogType: 'operation_chat',
            operationsValues: resp.operationsValues,
            work: resp.operations.find(el => el.id == item.task_operation_id),
            user: this.user,
            empl_status: this.company.permissions.includes('owner') || this.company?.permissions.includes('admin') || this.company.permissions.includes('manager'),
            chat: item.switchData,
            chats: resp.operations.find(el => el.id == item.task_operation_id).chatsInfo
          }
        });
    
      })
    )
  }

  neededTaskData(task, companyID) {
    let arr = [
      this.chatService.getTasksChats(companyID, [task.id]).pipe(
        tap(res => {
          task['operations'].map(o_el => {
            o_el['chatsInfo'] = [];
            o_el['chatsInfo'].push(...res.filter(z => z.task_operation_id == o_el.id));
            o_el['unReadChats'] = res.filter(z => z.task_operation_id == o_el.id && z.is_read != '1').length;
            o_el['unClosedChats'] = res.filter(z => z.task_operation_id == o_el.id && z.status_id != '4').length;
          });
          task['chatsInfo'] = [];
          task['chatsInfo'].push(...res.filter(z => z.task_id == task.id));
          task['unReadChats'] = res.filter(z => z.is_read != '1').length;
          task['unClosedChats'] = res.filter(z => z.status_id != '4').length;
        })
      )
    ]
    return forkJoin(arr)
  }

  neededData(task, companyID) {
    if (!task) {
      return of(task)
    }
    let arr = [
      this.chatService.getTasksChats(companyID, [task.id]).pipe(
        tap(res => {
          task['operations'].map(o_el => {
            o_el['chatsInfo'] = [];
            o_el['chatsInfo'].push(...res.filter(z => z.task_operation_id == o_el.id));
            o_el['unReadChats'] = res.filter(z => z.task_operation_id == o_el.id && z.is_read != '1').length;
            o_el['unClosedChats'] = res.filter(z => z.task_operation_id == o_el.id && z.status_id != '4').length;
          });
          task['chatsInfo'] = [];
          task['chatsInfo'].push(...res.filter(z => z.task_id == task.id));
          task['unReadChats'] = res.filter(z => z.is_read != '1').length;
          task['unClosedChats'] = res.filter(z => z.status_id != '4').length;
        })
      )
    ]
    if (task.company_id == companyID) {
      arr.push(
        this.taskService.getTaskClients(task.id, companyID).pipe(
          tap(res => {
            task.clients = res
          }),
          catchError(err => {
            return err
          })
        )
      )
    }
    
    // if (this.company | PermissionsPipe:'see_files')
    if (new PermissionsPipe().transform(this.company, 'see_files')) {
      arr.push(...task.operations.filter(z => (z.status_id == 3 && z.is_to_approve_files) || (z.status_id == 98 && z.is_approved_files)).map(x => this.fileService.getFilesForOpenTask(companyID, x.id, x.status_id == 3 ? '/to_approve' : '/ready_files', this.is_mobile ? '20' : '3').pipe(
        tap(val => {
          x.filesCount = +val.headers.get('x-pagination-total-count');
          x.files = val.body.filter(x => !!x.thumbnail).slice(0,4);
          console.log("JOB FILES", x.files)
        })
      )))
    }

    return forkJoin(arr)
  }

  
  activeSections() {
    switch (window.location.pathname) {
      case '/dashboard':
        return 'Dashboard'
        break;
      case '/tasks':
        return 'Cards'
        break;
      case '/jobs':
        return 'Jobs'
        break;
      case '/chats':
        return 'Chats'
        break;
      case '/board':
        return 'Board by Date'
        break;
      case '/board-list':
        return 'Board by Order'
        break;
      case '/urls':
        return 'Posts'
        break;
      case '/urls-start':
        return 'Posts Start'
        break;
      case '/urls-dashboard':
        return 'Post Dashboards'
        break;
      case '/outlets-dashboard':
        return 'Outlet Dashboards'
        break;
      case '/settings':
        // if (window.location.search.indexOf('individuals') != -1) {
        //   return 'Individuals'
        // } else if (window.location.search.indexOf('profiles') != -1) {
        //   return 'Outlets'
        // } else {
        //   return 'Settings'
        // }
        return 'Settings'
        break;
      case '/files':
        return 'Files'
        break;
      default:
        return window.location.pathname.replace('/', '')
        break;
    }
  }


  openSections() {
    this.isOpenSections = !this.isOpenSections

    if (this.isOpenSections) {
      this.isOpenLanguages = false;
      this.isOpenSettings = false;

      this.isOpenFinances = false;
      this.isOpenAutomation = false;
      this.isOpenMembers = false;
      this.isOpenCategorization = false;
      
      this.isOpenWorkspaces = false;
      this.isOpenBurger.patchValue(false);
    }
  }

  openBurger() {
    this.isOpenBurger.patchValue(!this.isOpenBurger.value);

    if (!this.isOpenBurger.value) {
      this.isOpenLanguages = false;
      this.isOpenSettings = false;

      this.isOpenFinances = false;
      this.isOpenAutomation = false;
      this.isOpenMembers = false;
      this.isOpenCategorization = false;
    } else {
      this.isOpenSections = false;
      this.isOpenWorkspaces = false;
    }
  }

  openWorkspaces() {
    this.isOpenWorkspaces = !this.isOpenWorkspaces

    if (this.isOpenWorkspaces) {
      this.isOpenLanguages = false;
      this.isOpenSettings = false;

      this.isOpenFinances = false;
      this.isOpenAutomation = false;
      this.isOpenMembers = false;
      this.isOpenCategorization = false;
      
      this.isOpenSections = false;
      this.isOpenBurger.patchValue(false);
    }
  }
  
  openLanguages() {
    this.isOpenLanguages = !this.isOpenLanguages
  }

  openSettings() {
    this.isOpenSettings = !this.isOpenSettings
  }

  openFinances() {
    this.isOpenFinances = !this.isOpenFinances
  }

  openAutomation() {
    this.isOpenAutomation = !this.isOpenAutomation
  }

  openMembers() {
    this.isOpenMembers = !this.isOpenMembers
  }

  openCategorization() {
    this.isOpenCategorization = !this.isOpenCategorization
  }

  showFilesInterface(company) {
    const dialogRef = this.dialog.open(InterfaceComponent, {
      backdropClass: ['backdrop_under_header', 'file_backdrop_under_header'],
      panelClass: !this.is_mobile ? ['file_manager_modal', 'show_header'] : 'file_manager_modal',
      hasBackdrop: true,
      data: {
        company: this.company,
        user: this.user,
        loc: '',
        is_global: true
      }
    });
  }

  changeCompany() {
    this.goToCompany(this.company_id.value)
  }

  closeNav(el) {
    console.log(el);
    el.classList.add('hide_nav')
    this.isOpenDeskBurger = false
    setTimeout(() => {
      el.classList.remove('hide_nav')
    },100)
  }

  companyVerificationText(comp) {
    return comp.translations.filter(x => x.language == this.activeLang).length > 0 ? comp.translations.find(x => x.language == this.activeLang) : undefined
  }

  getCompanyes() {
    this.attachSubscriptions(
      timer(300).pipe(
        switchMap(() => {
          return this.companyService.getCompanyes()
        })
      ).subscribe(resp => {
        this.companyes = resp;
        this.canCreate = !!this.companyes.filter(x => x.permissions.includes('create_task')).length;
      })
    )
  }

  closeLastDialog() {
    if (this.dialog.openDialogs.length > 0) {
      this.dialog.openDialogs[this.dialog.openDialogs.length - 1].close()
    }
  }

  closeAllDialogs(e?) {
    if (e) {
      e.stopPropagation();
      e.preventDefault()
    }
    this.dialog.closeAll()
  }
  
  // getUnReadChatsCount(company_id) {
  //   this.attachSubscriptions(
  //     this.chatService.getUnreadChats(company_id).subscribe(resp => {
  //       console.log("getUnReadChatsCount", resp)
  //       this.unReadChatsCount = resp
  //     })
  //   )
  // }

  goToPartners(e, id) {
    e.preventDefault();
    this.router.navigate(['/settings'], { queryParams: {company_id: id, page: 'partner_workspaces'} });
  }

  setLocalCompany(count, company_id) {
    let randomNumber = page_id;
    console.log("randomNumber", randomNumber)
    if (this.sm.localStorageGetItem(`company_${company_id}`)) {
      this.localCompany = JSON.parse(this.sm.localStorageGetItem(`company_${company_id}`));
      console.log("this.localCompany", this.localCompany)
      this.sm.localStorageSetItem(`company_${company_id}`, this.localCompany)
    } else {
      this.sm.localStorageSetItem(`company_${company_id}`, {
        tab_id: randomNumber,
        time: moment().format("X"),
        company_id: company_id
      })
      this.localCompany = {
        tab_id: randomNumber,
        time: moment().format("X"),
        company_id: company_id
      }
    }

    console.log('this.sm.localStorageGetItem(`company_${company_id}`)', this.sm.localStorageGetItem(`company_${company_id}`))

  }

  // getUnReadNotsCount(company_id, fromZero?) {
  //   let isPlayData = {
  //     sms: false,
  //     to_approve: false,
  //     approved: false,
  //     to_correct: false,
  //   }

  //   // this.setLocalCompany(0, company_id);

  //   this.attachSubscriptions(
  //     this.taskService.getNotReadActions(company_id, !!fromZero ? 0 : this.paramT).pipe(
  //       tap((res) => {
  //         this.paramT = res.new_t
  //       }),
  //       switchMap(x => {
  //         if (x.count > -1) {
  //           this.unReadNotsCount = x.count;
  //         }
  //         if (this.unReadNotsCount < x.count && x.count > -1) {
  //           this.unReadNotsCount = x.count;
  //           return this.taskService.getActionsForAudio(this.company.id, x.count - this.unReadNotsCount).pipe(
  //             map(z => z.body),
  //             tap(val => {
  //               if (this.sm.localStorageGetItem("hasSound") != "0" && val.find(b => b.action == "task_operation_status") && val.find(b => b.action == "task_operation_status").task_operation_status_id == 3 && !isPlayData.to_approve && (this.company.permissions.includes("client") || this.company.permissions.includes("manager") || this.company.permissions.includes("owner"))) {
  //                 this.audioService.playToApprove()
  //                 console.log("playToApprove")
  //                 isPlayData.to_approve = true;
  //               }
  //             }),
  //             tap(val => {
  //               if (this.sm.localStorageGetItem("hasSound") != "0") {
  //                 this.chatSub = this.chatService._isInChat$.subscribe(m => {
  //                   if (val.find(b => b.action == "discussion_post_added") && val.find(b => b.action == "discussion_post_added").discussion_id != m && !isPlayData.sms) {
  //                     this.audioService.playSMS()
  //                     console.log("playSMS")
  //                     isPlayData.sms = true;
  //                   }
  //                 })
  //               }
  //             }),
  //             tap(val => {
  //               if (this.sm.localStorageGetItem("hasSound") != "0" && val.find(b => b.action == "task_operation_status") && val.find(b => b.action == "task_operation_status").task_operation_status_id == 5 && !isPlayData.to_correct && (this.company.permissions.includes("employee") && this.company.permissions.length == 1)) {
  //                 this.audioService.playToCorrect()
  //                 console.log("playToCorrect")
  //                 isPlayData.to_correct = true;
  //               }
  //             }),
  //             tap(val => {
  //               if (this.sm.localStorageGetItem("hasSound") != "0" && val.find(b => b.action == "task_operation_status") && val.find(b => b.action == "task_operation_status").task_operation_status_id == 98 && !isPlayData.approved && (this.company.permissions.includes("employee") && this.company.permissions.length == 1)) {
  //                 this.audioService.playApproved()
  //                 console.log("playApproved")
  //                 isPlayData.approved = true;
  //               }
  //             }),
  //             map(() => x)
  //           )
  //         } else {
  //           return of(x)
  //         }
  //       }),
  //       distinctUntilChanged()
  //     ).subscribe(resp => {
  //       if (this.chatSub) {
  //         this.chatSub.unsubscribe()
  //       }
  //     })
  //   )
  // }

  onResize() {
    this.attachSubscriptions(
      fromEvent(window, "resize").pipe(
        map(() => window.innerWidth)
      ).subscribe((wWidth) => {
          if (wWidth <= 769) {
            this.is_mobile = true;
          } else {
            this.is_mobile = false;
          }
        }
      )
    )
  }

  openStats() {
    this.mobStatsIsOpen = !this.mobStatsIsOpen;
  }

  getHeaderStats(resp) {
    let sources = [];
    if (!!resp && !!resp.id) {
      sources.push(this.taskService.getHeaderStats(this.company.id, '3', moment().utc().clone().startOf('month').format('X'), moment().utc().clone().endOf('month').format('X')))
      sources.push(this.taskService.getHeaderStats(this.company.id, '4', moment().utc().clone().startOf('month').format('X'), moment().utc().clone().endOf('month').format('X')))
      sources.push(this.taskService.getHeaderStats(this.company.id, '98', moment().utc().clone().startOf('month').format('X'), moment().utc().clone().endOf('month').format('X')))
    }
    this.attachSubscriptions(
      forkJoin(sources).subscribe(result => {
        console.log(result)
        let rate_toAprove = result[0]['headers'].get('x-summary-rate') ? result[0]['headers'].get('x-summary-rate') : 0;
        let rate_Aproved = result[1]['headers'].get('x-summary-rate') ? result[1]['headers'].get('x-summary-rate') : 0;
        this.company.rate_pending = +rate_Aproved + +rate_toAprove;
        this.company.rate_ready = result[2]['headers'].get('x-summary-rate') ? result[2]['headers'].get('x-summary-rate') : 0;

        let price_toAprove = JSON.parse(result[0]['headers'].get('x-summary-price')).length ? JSON.parse(result[0]['headers'].get('x-summary-price'))[0].summary : 0;
        let price_Aproved = JSON.parse(result[1]['headers'].get('x-summary-price')).length ? JSON.parse(result[1]['headers'].get('x-summary-price'))[0].summary : 0;
        this.company.price_pending = +price_toAprove + +price_Aproved;
        this.company.price_ready = JSON.parse(result[2]['headers'].get('x-summary-price')).length ? JSON.parse(result[2]['headers'].get('x-summary-price'))[0].summary : 0;
      })
    )
  }

  // getEmployees(comp) {
  //   this.attachSubscriptions(
  //     this.taskService.getEmployees(comp.id).subscribe(resp => {
  //       this.employees = resp;
  //     })
  //   )
  // }


  getEmployees(comp) {
    this.attachSubscriptions(
      this.taskService.getEmployeesDyn('1', comp.id, null, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }
  
          return forkJoin(arr.map(x => this.taskService.getEmployeesDyn(x, comp.id, null, '200').pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              let conValues = [].concat(...values)
              this.employees = conValues;
            }),
          )
        }),
      ).subscribe(resp => {
        console.log("getEmployees resp", resp);
        console.log("getEmployees", this.employees);
      })
    )
  }
  

  checkActions() {
    if (this.company.permissions.includes('owner') || this.company.permissions.includes('admin') || this.company.permissions.includes('manager')) {
      let filterData = {
        analytics: 'by_hour',
        start_time_from: moment().startOf('day').format("X"),
        query_type: 'subtotals'
      }

      let totals;
      this.attachSubscriptions(
        this.activityService.getActivity(this.company.id, Object.assign({...filterData}, {
          employee_id: "group",
          partner_company_id: "group"
        })).pipe(
          switchMap(x => {
            if (x.body.length) {
              let subFilterData = {...filterData}
              subFilterData.query_type = 'totals'
              return this.activityService.getActivity(this.company.id, subFilterData).pipe(
                tap(val => {
                  totals = val.body
                  console.log("TOTALS", totals)
                }),
                map(() => x)
              )
            } else {
              return of(x)
            }
          })
        ).subscribe(resp => {
          console.log(resp)

          const dialogRef = this.dialog.open(ActivityComponent, {
            backdropClass: this.is_mobile ? ['mob_interface_backdrop'] : 'backdrop_under_header',
            panelClass: this.is_mobile ? ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'] : ['open_task_dialog', 'show_header', 'activity_modal'],
            data: {
              activity: resp.body,
              employees: !!this.employees ? this.employees : [],
              taskStatuses: this.taskStatuses,
              company: this.company,
              user: this.user,
              empl_status: this.company.permissions.includes('owner') || this.company.permissions.includes('admin') || this.company.permissions.includes('manager'),
              header: true,
              totals: totals,
              pagination: {
                'pageCount': resp.headers.get('x-pagination-page-count'),
                'perPage': resp.headers.get('x-pagination-per-page'),
                'totalCount': resp.headers.get('x-pagination-total-count'),
                'currentPage': resp.headers.get('x-pagination-current-page'),
              },
            }
          });

          this.attachSubscriptions(
            dialogRef.afterClosed().subscribe(result => {
              console.log("getONE BEFORE", result);
              if (!!result && result.to_feed) {
                this.toFeed();
              }
              // if (!!result && result.event == 'Add') {
              //   this.taskService.getOneTaskExpand(this.company_id, result.data.id).subscribe(resp => {
              //     console.log("getONE", resp.body[0]);
              //     this.addRowData(resp.body[0]);
              //   })
              // }
            })
          )

        })
      )
    } else {
      this.attachSubscriptions(
        this.taskService.getActions(false, 1, this.company.id).subscribe(resp => {
          
          if (resp.body.length) {
            this.dialog.open(NotificationsBar, {
              backdropClass: 'backdrop_under_header',
              panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
              data: {
                notifications: resp.body,
                employees: !!this.employees ? this.employees : [],
                taskStatuses: this.taskStatuses,
                company: this.company,
                user: this.user,
                empl_status: this.company.permissions.includes('owner') || this.company.permissions.includes('admin') || this.company.permissions.includes('manager'),
                header: true,
                pagination: {
                  'pageCount': resp.headers.get('x-pagination-page-count'),
                  'perPage': resp.headers.get('x-pagination-per-page'),
                  'totalCount': resp.headers.get('x-pagination-total-count'),
                  'currentPage': resp.headers.get('x-pagination-current-page'),
                },
              }
            });
          }
        })
      )
    }
  }

  toFeed() {
    this.attachSubscriptions(
      this.taskService.getActions(false, 1, this.company.id).subscribe(resp => {
        
        if (resp.body.length) {
          this.dialog.open(NotificationsBar, {
            backdropClass: 'backdrop_under_header',
            panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
            data: {
              notifications: resp.body,
              employees: !!this.employees ? this.employees : [],
              taskStatuses: this.taskStatuses,
              company: this.company,
              user: this.user,
              empl_status: this.company.permissions.includes('owner') || this.company.permissions.includes('admin') || this.company.permissions.includes('manager'),
              header: true,
              pagination: {
                'pageCount': resp.headers.get('x-pagination-page-count'),
                'perPage': resp.headers.get('x-pagination-per-page'),
                'totalCount': resp.headers.get('x-pagination-total-count'),
                'currentPage': resp.headers.get('x-pagination-current-page'),
              },
            }
          });
        }
      })
    )
  }


  getTaskStatus() {
    this.attachSubscriptions(
      this.taskService.getTaskStatuses(this.company.id).subscribe(resp => {
        this.taskStatuses = resp;
      })
    )
  }

  menuHref(e, url, bool) {
    e.preventDefault()
    console.log(this.host + url + `&returnUrl=${window.location.href}`)
    console.log(this.host + url + `&returnUrl=${window.location.origin}`)

    if (bool) {
      window.location.href = this.host + url + `&returnUrl=${window.location.href}`;
    } else {
      window.location.href = this.host + url + `?returnUrl=${window.location.origin}`;
    }
  }

  goToRoot() {
    this.router.navigate(['/root'], { queryParams: {tab: 'companyes'}});
  }

  goToJobTypes(e, id) {
    e.preventDefault();
    this.router.navigate(['/settings'], { queryParams: {company_id: id, page: 'job_types'} });
  }

  goToTaskTypes(e, id) {
    e.preventDefault();
    this.router.navigate(['/settings'], { queryParams: {company_id: id, page: 'task_types'} });
  }

  // goToCompanyCases(e, id) {
  //   e.preventDefault();
  //   this.router.navigate(['/cases'], { queryParams: {company_id: id} });
  // }

  goToChecklists(e, id) {
    e.preventDefault();
    this.router.navigate(['/settings'], { queryParams: {company_id: id, page: 'checklists'} });
  }
  
  goToQuestionnaires(e, id) {
    e.preventDefault();
    this.router.navigate(['/settings'], { queryParams: {company_id: id, page: 'questionnaires'} });
  }

  goToForm(e, id) {
    e.preventDefault();
    this.router.navigate(['/settings'], { queryParams: {company_id: id, page: 'form'} });
  }

  goToCompanyStats(e, id) {
    if (!!e) {
      e.preventDefault();
    }
    this.router.navigate(['/settings'], { queryParams: {company_id: id, page: 'stats'} });
  }

  goToCompanyStatsCreator(e, id) {
    if (!!e) {
      e.preventDefault();
    }
    this.router.navigate(['/settings'], { queryParams: {company_id: id, page: 'stats'} });
  }

  goToGroups(e, company_id) {
    e.preventDefault()
    this.router.navigate(['/settings'], { queryParams: {company_id: company_id, page: 'company'} });
  }
  goToBookmarks(e, company_id) {
    e.preventDefault()
    this.router.navigate(['/settings'], { queryParams: {company_id: company_id, page: 'bookmarks'} });
  }

  goToProfiles(e, company_id) {
    e.preventDefault()
    this.router.navigate(['/settings'], { queryParams: {company_id: company_id, page: 'profiles'} });
  }
  
  goToUsage(e, company_id) {
    e.preventDefault()
    this.router.navigate(['/settings'], { queryParams: {company_id: company_id, page: 'usage'} });
  }
  
  goToTotalUsage(e, company_id) {
    e.preventDefault()
    this.router.navigate(['/settings'], { queryParams: {company_id: company_id, page: 'total_usage'} });
  }
  
  goToDubbingUsage(e, company_id) {
    e.preventDefault()
    this.router.navigate(['/settings'], { queryParams: {company_id: company_id, page: 'dubbing_usage'} });
  }

  goToStuUsage(e, company_id) {
    e.preventDefault()
    this.router.navigate(['/settings'], { queryParams: {company_id: company_id, page: 'speech_to_text'} });
  }
  
  goToTsuUsage(e, company_id) {
    e.preventDefault()
    this.router.navigate(['/settings'], { queryParams: {company_id: company_id, page: 'text_to_speech'} });
  }
  
  goToSftp(e, company_id) {
    e.preventDefault()
    this.router.navigate(['/settings'], { queryParams: {company_id: company_id, page: 'sftp'} });
  }

  goToManageAuth(e, company_id) {
    e.preventDefault()
    this.router.navigate(['/settings'], { queryParams: {company_id: company_id, page: 'manage-auth'} });
  }

  goToImportData(e, company_id) {
    e.preventDefault()
    this.router.navigate(['/settings'], { queryParams: {company_id: company_id, page: 'import-data'} });
  }

  // goToAllCompany(e) {
  //   e.preventDefault();
  //   this.router.navigate(['/all-tasks'], { queryParams: {v2: 1, order_by: 'updated_desc', count: 1} });
  // }

  goToChat() {
    const dialogRef = this.dialog.open(ChatsComponent, {
      backdropClass: ['backdrop_under_header', "without_pad"],
      disableClose: true,
      panelClass: ['full_screen_dialog', 'global_chats_dialog'],
      data: {
        company: this.company,
        user: this.user,
        imgRoute: this.imgRoute,
        dialogType: 'global_chat',
        host: this.host,
        activeLang: this.activeLang
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == 'update') {
        }
      })
    )
  }

  // goToChat(e, company_id) {
  //   e.stopPropagation();
  //   e.preventDefault();
  //   this.router.navigate(['/chats'], { queryParams: {company_id: company_id} });
  // }

  goToEmployees(e, company_id) {
    e.preventDefault()
    this.router.navigate(['/settings'], { queryParams: {company_id: company_id, page: 'individuals'} });
  }

  goToMembers(e, company_id) {
    e.preventDefault()
    this.router.navigate(['/settings'], { queryParams: {company_id: company_id, page: 'workspace_members'} });
  }

  goToUsers(e, company_id) {
    e.preventDefault()
    this.router.navigate(['/settings'], { queryParams: {company_id: company_id, page: 'users'} });
  }

  goToCategorization(e, company_id) {
    e.preventDefault()
    this.router.navigate(['/settings'], { queryParams: {company_id: company_id, page: 'categorization'} });
  }

  addClient(e, company_id) {
    e.preventDefault()
    this.router.navigate(['/task/add-client'], { queryParams: {company_id: company_id} });
  }

  goToClients(e, company_id) {
    e.preventDefault()
    this.router.navigate(['/task/clients'], { queryParams: {company_id: company_id} });
  }

  nextFalseLimit() {
    this.uploadService.uploadLimit$.next(false)
  }
  
  goToCompany(company_id, e?) {
    if (!!e) {
      e.preventDefault();
    }
    if (this.company.id != company_id) {
      this.uploadService.uploadLimit$.next(false)
    }
    this.router.navigate(['/tasks'], { queryParams: {company_id: company_id, order_by: "updated_desc", count: 1} });
  }

  toggleUploads() {
    console.log("toggleUploads")
    this.uploadsIsOpen = !this.uploadsIsOpen
  }

  closeUploads() {
    console.log("closeUploads")
    this.uploadsIsOpen = false
  }

  openUploads() {
    console.log("openUploads")
    this.uploadsIsOpen = true
  }

  addNewTask(e?) {
    if (e) {
      e.stopPropagation(); 
      e.preventDefault(); 
      e.stopImmediatePropagation();
    }

    console.log("this.companyes", this.companyes)

    // if (this.companyes.filter(x => x.permissions.includes('create_task')).length == 0) {
    //   this.router.navigate(['/set']);
    // } else 
    if (this.companyes.filter(x => x.permissions.includes('create_task')).length == 1) {
      let company_id = this.companyes.filter(x => x.permissions.includes('create_task'))[0].id;
      console.log(company_id);
      if (!this.user.hasOwnProperty('partnersCount')) {
        this.createLoader = true;
        this.companyService.getPartnerCompanyesCount(company_id).pipe(
          map(x => x.headers.get('x-pagination-total-count')), 
          catchError(error => {
            console.log(error)
            return of(0)
          }),
          take(1))
        .subscribe(resp => {
          this.user.partnersCount = resp
          this.createLoader = false;
          this.createFunc(company_id)
        })
      } else {
        this.createFunc(company_id)
      }
    } else {
      this.goToCreateTask()
    }
  }

  createFunc(company_id) {
    if (this.user.partnersCount == 0) {
      let targetCompany = this.companyes.find(x => x.id == company_id)
      if (targetCompany.permissions.includes("owner") || targetCompany.permissions.includes("manager") || targetCompany.permissions.includes("admin")) {
        this.createManagerTask(targetCompany);
      } else {
        this.emplCreateTask(targetCompany)
      }
    } else {
      this.goToCreateTask()
    }
  }

  userCalendar(company) {
    const dialogRef = this.dialog.open(UserCalendarComponent, {
      backdropClass: ['back_user_calendar_schedule'],
      panelClass: ['panel_user_calendar_schedule'],
      data: {
        company_id: company.id,
        company: company,
        imgRoute: this.imgRoute,
        host: this.host
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log("getONE BEFORE", result);
      })
    )
  }

  scheduleSettings(company) {
    const dialogRef = this.dialog.open(UserScheduleComponent, {
      backdropClass: ['back_user_schedule'],
      panelClass: ['panel_user_schedule'],
      data: {
        company_id: company.id,
        company: company,
        imgRoute: this.imgRoute,
        host: this.host
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log("getONE BEFORE", result);
      })
    )
  }

  emplCreateTask(company) {
    const dialogRef = this.dialog.open(EmplCreateTaskComponent, {
      disableClose: true,
      data: {
        company_id: company.id,
        company: company,
        imgRoute: this.imgRoute,
        host: this.host,
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        console.log("getONE BEFORE", result);
        // if (!!result && result.event == 'Add') {
        //   this.taskService.getOneTaskExpand(this.company_id, result.data.id).subscribe(resp => {
        //     console.log("getONE", resp.body[0]);
        //     this.addRowData(resp.body[0]);
        //   })
        // }
      })
    )
  }

  createManagerTask(company) {
    if (!this.is_mobile) {
      const dialogRef = this.dialog.open(CreateTaskComponent, {
        disableClose: true,
        data: {
          company_id: company.id,
          company: company,
          imgRoute: this.imgRoute,
          host: this.host,
        }
      });
  
      this.attachSubscriptions(
        dialogRef.afterClosed().subscribe(result => {
          
          if (!!result && result.event == 'Add') {
            // this.attachSubscriptions(
            //   this.taskService.getOneTaskExpand(this.company_id, result.data.id).subscribe(resp => {
            //     this.addRowData(resp.body[0]);
            //   })
            // )
          }
        })
      )
    } else {
      const dialogRef = this.dialog.open(MobAddTaskComponent, {
        backdropClass: ['mob_interface_backdrop'],
        panelClass: ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'],
        disableClose: true,
        data: {
          company_id: company.id,
          company: company,
          imgRoute: this.imgRoute,
          host: this.host,
        }
      });

      this.attachSubscriptions(
        dialogRef.afterClosed().subscribe(result => {
          
          if (!!result && result.event == 'Add') {
            // this.attachSubscriptions(
            //   this.taskService.getOneTaskExpand(this.company_id, result.data.id).subscribe(resp => {
            //     this.addRowData(resp.body[0]);
            //   })
            // )
          }
        })
      )
    }

  }

  goToCreateTask() {
    if (!this.is_mobile) {
      const dialogRef = this.dialog.open(CreateNewTaskComponent, {
        data: {
          companyes: this.companyes.filter(x => x.permissions.includes('create_task')),
          user: this.user,
          imgRoute: this.imgRoute,
          host: this.host,
          activeLang: this.activeLang
        }
      });
    } else {
      const dialogRef = this.dialog.open(SelectCompanyComponent, {
        backdropClass: ['mob_interface_backdrop'],
        panelClass: ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'],
        disableClose: true,
        data: {
          companyes: this.companyes.filter(x => x.permissions.includes('create_task')),
          user: this.user,
          imgRoute: this.imgRoute,
          host: this.host,
          activeLang: this.activeLang
        }
      });
    }

        // this.attachSubscriptions(
    //   dialogRef.afterClosed().subscribe(result => {
    //     if (!!result && result.event == 'update') {
    //     }
    //   })
    // )
  }
  
  openNotifications(e) {
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();
    if (!this.is_mobile) {
      const dialogRef = this.dialog.open(NotificationsCenterComponent, {
        backdropClass: ['backdrop_under_header', 'back_not_center'],
        panelClass: ['open_task_dialog', 'back_not_panel', 'show_header'],
        data: {
          company: this.company,
          companyes: this.companyes,
          user: this.user,
          imgRoute: this.imgRoute,
          host: this.host,
          activeLang: this.activeLang
        }
      });
    } else {
      const dialogRef = this.dialog.open(MobNotificationsCenterComponent, {
        backdropClass: ['mob_interface_backdrop', 'back_not_center'],
        panelClass: ['mob_interface_panel', 'animate__animated', 'animate__slideInUp', 'back_not_panel'],
        disableClose: true,
        data: {
          company: this.company,
          companyes: this.companyes,
          user: this.user,
          imgRoute: this.imgRoute,
          host: this.host,
          activeLang: this.activeLang
        }
      });
    }
  }
  
  goToDashboard(e, company_id) {
    e.preventDefault();
    this.router.navigate(['/dashboard'], { queryParams: {company_id: company_id} });
  }
  
  goToParameters(e, company_id) {
    e.preventDefault();
    this.router.navigate(['/settings'], { queryParams: {company_id: company_id, page: 'parameters'} });
  }

  isLinkActive(link) {
    const url = this.router.url;
    return this.router.url.indexOf(link) != -1;
  }

  goToChats(e, company_id) {
    e.preventDefault();
    this.router.navigate(['/chats'], { queryParams: {company_id: company_id, order_by: "updated_desc", count: 1} });
  }

  goToUserSettings(e?) {
    if (e) {
      e.preventDefault();
    }
    this.router.navigate(['/user/connected-apps'], { queryParams: {tab: "settings"} });
  }

  goToConnectedApps(e) {
    e.preventDefault();
    this.router.navigate(['/user/connected-apps'], { queryParams: {tab: "youtube"} });
  }

  goToBoard(e, company_id) {
    e.preventDefault();
    this.router.navigate(['/board'], { queryParams: {company_id: company_id, order_by: "updated_desc", count: 1} });
  }

  goToBoardList(e, company_id) {
    e.preventDefault();
    this.router.navigate(['/board-list'], { queryParams: {company_id: company_id, order_by: "updated_desc", count: 1} });
  }

  goToStructure(e, company_id) {
    e.preventDefault();
    this.router.navigate(['/settings'], { queryParams: {company_id: company_id, page: 'structure'} });
  }
  
  goToScenarios(e, company_id) {
    e.preventDefault();
    this.router.navigate(['/settings'], { queryParams: {company_id: company_id, page: 'automation'} });
  }

  onPartnerImgError(event){
    event.target.src = this.imgRoute+'/assets/img/partner.png'
  }
  
  goToFinances(e, company_id) {
    e.preventDefault();
    this.router.navigate(['/settings'], { queryParams: {company_id: company_id, page: 'finances_dashboard'}});
  }

  goToCurrentActivity(e, company_id) {
    e.preventDefault()
    this.router.navigate(['/settings'], { queryParams: {company_id: company_id, page: 'finances', tab: 'current_activity'} });
  }

  goToStatements(e, company_id) {
    e.preventDefault()
    this.router.navigate(['/settings'], { queryParams: {company_id: company_id, page: 'finances', tab: 'statements'} });
  }

  goToPayouts(e, company_id) {
    e.preventDefault()
    this.router.navigate(['/settings'], { queryParams: {company_id: company_id, page: 'finances', tab: 'payouts'} });
  }

  goToSettings(e, company_id) {
    e.preventDefault();
    this.router.navigate(['/settings'], { queryParams: {company_id: company_id, page: 'automation'}});
  }

  goToJobs(e, company_id) {
    e.preventDefault();
    this.router.navigate(['/jobs'], { queryParams: {company_id: company_id, order_by: "updated_desc", count: 1} });
  }

  goToUrls(e, company_id) {
    e.preventDefault();
    this.router.navigate(['/urls'], { queryParams: {company_id: company_id, order_by: "content_published_at_desc", count: 1} });
  }

  goToUrlsStart(e, company_id) {
    e.preventDefault();
    this.router.navigate(['/urls-start'], { queryParams: {company_id: company_id, order_by: "content_published_at_desc", count: 1} });
  }

  goToOutletsDash(e, company_id) {
    e.stopPropagation();
    e.preventDefault();
    this.router.navigate(['/outlets-dashboard'], { queryParams: {company_id: company_id, count: 1} });
  }
  
  goToUrlsDash(e, company_id) {
    e.stopPropagation();
    e.preventDefault();
    this.router.navigate(['/urls-dashboard'], { queryParams: {company_id: company_id, count: 1} });
  }
  
  createTask(company_id, status) {
    console.log(this.router.url);
    if (this.router.url.indexOf('all-tasks') != -1) {
      // this.router.navigate(['/all-tasks'], { queryParams: {c_id: company_id, order_by: "updated_desc", add_task: 1, is_employee: !status} });
    } else {
      this.router.navigate(['/tasks'], { queryParams: {company_id: company_id, order_by: "updated_desc", add_task: 1, is_employee: !status} });
    }
  }

  selectLang(lang) {
    this.activeLang = lang;
    this.attachSubscriptions(
      this.languageService.setLanguage(lang).subscribe(resp => {
        if (resp.success) {
          this.languageService.languageData$.next({
            active: lang,
            languages: this.languages
          })
          this.translate.use(lang)
        }
      })
    )
  }

  ngOnDestroy(): void {
    // let x = {
    //   "notification": {
    //     "title": "New Notification!",
    //     "body": "",
    //     "icon": 'assets/icons/icon-192x192.png',
    //     "actions": [
    //       {"action": "open", "title": "Open new tab"},
    //     ],
    //     "data": {
    //       "onActionClick": {
    //         "default": {"operation": "openWindow"},
    //         "open": {"operation": "openWindow", "url": "/path/"},
    //       }
    //     }
    //   }
    // }
    this.clearSubscriptions();
  }

}
