import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { map, tap } from 'rxjs/operators';
import { BaseClass } from 'src/app/shared/models/base-class';
import { CompanyService } from 'src/app/shared/services/rest/company.service';

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss']
})
export class TransactionsComponent extends BaseClass implements OnInit, OnDestroy {

  public totalBalance: number = 0;
  public transactionsPagination: any;
  public isLoadTransactions: boolean = false;
  public transactions: any;
  public transactionsPage: number = 1;
  public company_id: number = +this.activatedRoute.snapshot.queryParamMap.get('company_id');

  throttle = 100;
  scrollUpDistance = 3.5;

  public transactionsFilter: FormGroup = this.fb.group({
    company_id: this.company_id,
    id: '',
    type: '',
    // state: '',
    is_applied: '',
    is_hide: '',
    done_at_from: '',
    done_at_to: '',
  })
  
  constructor(
    private fb: FormBuilder,
    private companyService: CompanyService,
    private activatedRoute: ActivatedRoute,
  ) { 
    super()
   }

  ngOnInit(): void {
    this.getWorkspaceTransactions(this.transactionsPage);
  }

  onDownTransactions(e) {
    console.log("SCROLL DOWN", e);
    if (this.transactionsPagination['pageCount'] >= this.transactionsPage) {
      console.log("onDownCompany")
      this.getWorkspaceTransactions(this.transactionsPage);
    }
  }

  wholeDisc(item) {
    if (+item.product_discounted_price > 0 && +item.product_base_price > 0) {
      return Math.round(100 - ((+item.product_discounted_price*100)/+item.product_base_price))
    } else {
      return 0
    }
  }

  specialDisc(item) {
    if (+item.product_discounted_price > 0) {
      return Math.round(100 - ((+item.product_price*100)/+item.product_discounted_price))
    } else {
      return 0
    }
  }

  getWorkspaceTransactions(page) {
    this.isLoadTransactions = true;
    let params:any = {
      is_new: 0,
      is_planned: 0
    }
    Object.keys(this.transactionsFilter.value).forEach(element => {
      
      if (this.transactionsFilter.value[element] !== '') {
        if (element == 'company_id') {
          if (this.transactionsFilter.value[element] != 0) {
            params[element] = this.transactionsFilter.value[element];
          }
        } else if (element == 'type') {
          params[this.transactionsFilter.value[element]] = 1;
        } else if (element == 'state') {
          params[this.transactionsFilter.value[element]] = 1;
        } else {
          params[element] = this.transactionsFilter.value[element];
        }
      }
    });
    this.attachSubscriptions(
      this.companyService.getWorkspaceTransactions(page, params).pipe(
        tap(el => {
          this.transactionsPagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
        }),
        map(res => res.body),
      ).subscribe(resp => {
        resp.forEach(el => {
          if (el.serverLog && el.serverLog.data) {
            el.serverLog.data = JSON.parse(el.serverLog.data)
          }
          if (el.serverLog && el.serverLog.log) {
            el.serverLog.log = el.serverLog.log.replaceAll("\n","<br>")
          }
        })
        
        if (page == 1) {
          this.transactions = resp;
        } else {
          this.transactions.push(...resp)
        }
        if (this.transactionsFilter.value.company_id && this.transactions.length && this.transactions.find(k => !!k.is_applied)) {
          this.totalBalance = this.transactions.find(k => !!k.is_applied).balance;
        }
        console.log("getWorkspacetransactions-" + this.transactionsPage, this.transactions);
        this.transactionsPage++;
        this.isLoadTransactions = false;
      })
    )
  }

  filterTransactionsData() {
    this.transactionsPage = 1;
    this.getWorkspaceTransactions(this.transactionsPage);
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
