<mat-dialog-content class="mat-typography">
    <ng-container *ngIf="!is_mobile; else elseTemplateMobileOut">
        <div class="container open_job_card_out">
            <div class="open_job_card">
                <div class="open_job_header">
                    <div class="task_header" [ngClass]="{'full': task?.previewImgs.length == 0 && (!task?.avatarFile || !task?.avatarFile?.thumbnail)}">
                        <div class="close_job_card_btn" (click)="closeJob()">
                            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.7916 1.67676L13.3228 0.208008L7.49992 6.03092L1.677 0.208008L0.208252 1.67676L6.03117 7.49967L0.208252 13.3226L1.677 14.7913L7.49992 8.96842L13.3228 14.7913L14.7916 13.3226L8.96867 7.49967L14.7916 1.67676Z" fill="#383838"/>
                            </svg>
                        </div>
                        <div class="job_icons">
                            <!-- <div class="close_job_card_btn" (click)="closeJob()">
                                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14.7916 1.67676L13.3228 0.208008L7.49992 6.03092L1.677 0.208008L0.208252 1.67676L6.03117 7.49967L0.208252 13.3226L1.677 14.7913L7.49992 8.96842L13.3228 14.7913L14.7916 13.3226L8.96867 7.49967L14.7916 1.67676Z" fill="#383838"/>
                                </svg>
                            </div>

                            <svg (click)="checkActions($event, task)" class="cp show_on_hover" style="width: 12px; height: 12px; margin-top: 5px;" width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path style="width: 12px; height: 12px;" d="M7.7381 3.05556H6.80952V6.11111L9.45905 7.66333L9.90476 6.92389L7.7381 5.65278V3.05556ZM7.42857 0C5.95094 0 4.53382 0.579463 3.48898 1.61091C2.44413 2.64236 1.85714 4.04131 1.85714 5.5H0L2.45143 7.96278L4.95238 5.5H3.09524C3.09524 4.36546 3.55178 3.27739 4.36444 2.47515C5.1771 1.67292 6.2793 1.22222 7.42857 1.22222C8.57784 1.22222 9.68004 1.67292 10.4927 2.47515C11.3054 3.27739 11.7619 4.36546 11.7619 5.5C11.7619 6.63454 11.3054 7.72261 10.4927 8.52485C9.68004 9.32708 8.57784 9.77778 7.42857 9.77778C6.23381 9.77778 5.15048 9.295 4.37048 8.51889L3.49143 9.38667C4.50048 10.3889 5.88095 11 7.42857 11C8.90621 11 10.3233 10.4205 11.3682 9.38909C12.413 8.35764 13 6.95869 13 5.5C13 4.04131 12.413 2.64236 11.3682 1.61091C10.3233 0.579463 8.90621 0 7.42857 0" fill="#C7D0C7"/>
                            </svg> -->
                            <div class="cp show_on_hover" ngxClipboard [cbContent]="task?.custom_id" (cbOnSuccess)="copyId(task?.custom_id)">
                                <svg style="width: 5.1px; height: 6px; " width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.6 5.4H0.6V1.5C0.6 1.335 0.465 1.2 0.3 1.2C0.135 1.2 0 1.335 0 1.5V5.4C0 5.73 0.27 6 0.6 6H3.6C3.765 6 3.9 5.865 3.9 5.7C3.9 5.535 3.765 5.4 3.6 5.4ZM5.1 4.2V0.6C5.1 0.27 4.83 0 4.5 0H1.8C1.47 0 1.2 0.27 1.2 0.6V4.2C1.2 4.53 1.47 4.8 1.8 4.8H4.5C4.83 4.8 5.1 4.53 5.1 4.2ZM4.5 4.2H1.8V0.6H4.5V4.2Z" fill="white" fill-opacity="0.64"/>
                                </svg> 
                            </div>
                            <div class="cp show_on_hover" ngxClipboard [cbContent]="task?.custom_id + '-' + task?.internal_id" (cbOnSuccess)="copyId(task?.custom_id + '-' + task?.internal_id)">
                                <svg style="width: 6.8px; height: 8px;" width="7" height="8" viewBox="0 0 7 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4.8 7.2H0.8V2C0.8 1.78 0.62 1.6 0.4 1.6C0.18 1.6 0 1.78 0 2V7.2C0 7.64 0.36 8 0.8 8H4.8C5.02 8 5.2 7.82 5.2 7.6C5.2 7.38 5.02 7.2 4.8 7.2ZM6.8 5.6V0.8C6.8 0.36 6.44 0 6 0H2.4C1.96 0 1.6 0.36 1.6 0.8V5.6C1.6 6.04 1.96 6.4 2.4 6.4H6C6.44 6.4 6.8 6.04 6.8 5.6ZM6 5.6H2.4V0.8H6V5.6Z" fill="white" fill-opacity="0.64"/>
                                </svg> 
                            </div>
                        </div>
                        <div class="task_header_middle">
                            <div class="d-f-c">
                                <div class="card_name">
                                    <!-- <div class="task_values">
                                        <div class="spans">
                                            <div class="status">
                                                {{ getTaskStatusById(task?.status_id)?.name }}
                                            </div>
                                            <div><span>cID</span>{{task?.custom_id}}</div>
                                            <div class="show_on_hover"><span>wID</span>{{task?.internal_id}}</div>
                                            <div class="cp show_on_hover" ngxClipboard [cbContent]="task?.custom_id" (cbOnSuccess)="copyId(task?.custom_id)">
                                                <svg style="width: 5.1px; height: 6px; " width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M3.6 5.4H0.6V1.5C0.6 1.335 0.465 1.2 0.3 1.2C0.135 1.2 0 1.335 0 1.5V5.4C0 5.73 0.27 6 0.6 6H3.6C3.765 6 3.9 5.865 3.9 5.7C3.9 5.535 3.765 5.4 3.6 5.4ZM5.1 4.2V0.6C5.1 0.27 4.83 0 4.5 0H1.8C1.47 0 1.2 0.27 1.2 0.6V4.2C1.2 4.53 1.47 4.8 1.8 4.8H4.5C4.83 4.8 5.1 4.53 5.1 4.2ZM4.5 4.2H1.8V0.6H4.5V4.2Z" fill="white" fill-opacity="0.64"/>
                                                </svg> 
                                            </div>
                                            <div class="cp show_on_hover" ngxClipboard [cbContent]="task?.custom_id + '-' + task?.internal_id" (cbOnSuccess)="copyId(task?.custom_id + '-' + task?.internal_id)">
                                                <svg style="width: 6.8px; height: 8px;" width="7" height="8" viewBox="0 0 7 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M4.8 7.2H0.8V2C0.8 1.78 0.62 1.6 0.4 1.6C0.18 1.6 0 1.78 0 2V7.2C0 7.64 0.36 8 0.8 8H4.8C5.02 8 5.2 7.82 5.2 7.6C5.2 7.38 5.02 7.2 4.8 7.2ZM6.8 5.6V0.8C6.8 0.36 6.44 0 6 0H2.4C1.96 0 1.6 0.36 1.6 0.8V5.6C1.6 6.04 1.96 6.4 2.4 6.4H6C6.44 6.4 6.8 6.04 6.8 5.6ZM6 5.6H2.4V0.8H6V5.6Z" fill="white" fill-opacity="0.64"/>
                                                </svg> 
                                            </div>
                                            <div class="show_on_hover" style="color: #383838 !important;">{{task?.id}}</div>
                                        </div>
                                    </div> -->
                                    <div class="task_name">
                                        <div class="cst_id" #tooltip="matTooltip" 
                                        matTooltip="{{'wID'+ task?.internal_id + '  ' + task?.id }}"
                                        [matTooltipPosition]="'above'" >{{task?.custom_id}}</div>
                                        <div class="group" *ngIf="task?.group_id">
                                            {{ task?.group?.name || getGroup(task?.group_id)?.name }}
                                        </div>
                                        <div class="name_val text_one_line" (click)="openTask()">
                                            {{ task.name }}
                                        </div>
                                        <div class="d-f-c ml-1" style="flex-direction: column;">
                                            <svg ngxClipboard [cbContent]="origin + '/task/' + task?.acm" (cbOnSuccess)="copyLink('Card')" style="width: 10px; height: 5px; transform: translateX(2px);" class="cp show_on_hover" width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7.5 0H5.5V1H7.5C8.325 1 9 1.675 9 2.5C9 3.325 8.325 4 7.5 4H5.5V5H7.5C8.88 5 10 3.88 10 2.5C10 1.12 8.88 0 7.5 0ZM4.5 4H2.5C1.675 4 1 3.325 1 2.5C1 1.675 1.675 1 2.5 1H4.5V0H2.5C1.12 0 0 1.12 0 2.5C0 3.88 1.12 5 2.5 5H4.5V4ZM3 2H7V3H3V2Z" fill="#D7D7D7"/>
                                            </svg> 
                                            <svg ngxClipboard [cbContent]="task.name + '\n' + origin + '/task/' + task?.acm" (cbOnSuccess)="copyLink('Card name and')" style="width: 16px; height: 8px; margin-top: 2px" class="ml-1 cp show_on_hover" width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12 0H8.8V1.6H12C13.32 1.6 14.4 2.68 14.4 4C14.4 5.32 13.32 6.4 12 6.4H8.8V8H12C14.208 8 16 6.208 16 4C16 1.792 14.208 0 12 0ZM7.2 6.4H4C2.68 6.4 1.6 5.32 1.6 4C1.6 2.68 2.68 1.6 4 1.6H7.2V0H4C1.792 0 0 1.792 0 4C0 6.208 1.792 8 4 8H7.2V6.4ZM4.8 3.2H11.2V4.8H4.8V3.2Z" fill="#D7D7D7"/>
                                            </svg>
                                        </div>
                                        <svg class="cp ml-1 show_on_hover" ngxClipboard [cbContent]="task?.name" (cbOnSuccess)="copyName('Card')" style="width: 11px; height: 12px;" width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.2 10.8H1.2V3C1.2 2.67 0.93 2.4 0.6 2.4C0.27 2.4 0 2.67 0 3V10.8C0 11.46 0.54 12 1.2 12H7.2C7.53 12 7.8 11.73 7.8 11.4C7.8 11.07 7.53 10.8 7.2 10.8ZM10.2 8.4V1.2C10.2 0.54 9.66 0 9 0H3.6C2.94 0 2.4 0.54 2.4 1.2V8.4C2.4 9.06 2.94 9.6 3.6 9.6H9C9.66 9.6 10.2 9.06 10.2 8.4ZM9 8.4H3.6V1.2H9V8.4Z" fill="white" fill-opacity="0.64"/>
                                        </svg> 
                                        <div class="ml-1 copy_name_icon custom_copy cp" [matMenuTriggerFor]="clipboardMenu" #menuTrigger="matMenuTrigger">
                                            <mat-icon color="#686868" class="main_icon">content_copy</mat-icon>
                                            <mat-icon class="sub_icon">settings</mat-icon>
                                        </div>
                                        <mat-menu #clipboardMenu="matMenu" xPosition="after">
                                            <button class="scheme_item" *ngFor="let item of bookmarks" ngxClipboard [cbContent]="getSchemeContent(item)" (cbOnSuccess)="copyScheme(item)" mat-menu-item>
                                                <span>{{ item.name }}</span>
                                                <div class="d-f-c show_scheme_hover" *ngIf="is_scheme_editing">
                                                    <mat-icon class="small_scheme_icon green" (click)="editScheme($event, menuTrigger, item, task)">edit</mat-icon>
                                                    <mat-icon class="small_scheme_icon red" (click)="deleteScheme($event, menuTrigger, item)">delete</mat-icon>
                                                </div>
                                            </button>
                                            <button class="scheme_item scheme_item_create" mat-menu-item (click)="createNewOpenCardClipboard($event, task)">
                                                <mat-icon>add</mat-icon>
                                                <span>{{ "Create new scheme" | translate }}</span>
                                                <mat-icon style="margin-left: 50px !important; margin-right: 0 !important;" *ngIf="bookmarks && bookmarks.length" [ngStyle]="{'color': is_scheme_editing ? '#3f51b5' : '#686868'}" (click)="toggleSchemeEditing($event)">settings</mat-icon>
                                            </button>
                                        </mat-menu>

                                        <div class="status ml-1">
                                            {{ getTaskStatusById(task?.status_id)?.name }}
                                        </div>
                                    </div>
                                </div>
                                <div class="d-f-c">
                                    <div class="group_wrp">
                                        <ng-container *ngIf="(company | perm:'see_task_channels') && !!task?.meta_channel && !!task?.meta_channel?.task_channels_by_platform_arr && !!task?.meta_channel?.task_channels_by_platform_arr?.length">
                                            <div class="profiles_wrp">
                                                <ng-container *ngFor="let platform of task?.meta_channel?.task_channels_by_platform_arr">
                                                    <ng-container *ngIf="platform.platform_id">
                                                    <div *ngIf="!!platform.published" class="plat_meta published">
                                                        <app-platform [platform_id]="platform.platform_id"></app-platform>
                                                        <span *ngIf="platform.published > 1">{{platform.published}}</span>
                                                    </div>
                                                    <div *ngIf="!!platform.waiting" class="plat_meta waiting">
                                                        <app-platform [platform_id]="platform.platform_id"></app-platform>
                                                        <span *ngIf="platform.waiting > 1">{{platform.waiting}}</span>
                                                    </div>
                                                    </ng-container>
                                                </ng-container>
                                            </div>
                                        </ng-container>
                                    </div>
                                    
                                    <span class="filesize" *ngIf="task?.filesize" #tooltip="matTooltip" [matTooltipPosition]="'above'" matTooltip="{{ task?.file_stat_updated_at > 0 ? (task?.file_stat_updated_at*1000 | date:'hh:mm a, MMM d, y':'':activeLang | yearPipe) : '' }}">{{ task?.filesize | filesize:0 }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="task_header_right">
                            <div class="dates_wrp" *ngIf="task">
                                <div class="date">
                                    <div>{{"Updated" | translate}}</div>
                                    <div>{{"Created" | translate}}</div>
                                </div>
                                <div class="date">
                                    <div>{{task?.action_updated_at*1000 | date:'MMM dd. Y':'': activeLang | yearPipe }}</div>
                                    <div>{{ task?.created_at*1000 | date:'MMM dd. Y':'': activeLang | yearPipe }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="open_preview_wrp" *ngIf="task?.previewImgs.length || (task?.avatarFile && task?.avatarFile?.thumbnail)">
                        <swiper [pagination]="false" [navigation]="false">
                            <ng-container *ngIf="task?.previewImgs.length > 0 && task?.custom_avatar_property == ''; else elseTemplateHasPreview">
                              <ng-container *ngFor="let preview of task?.previewImgs">
                                <ng-template swiperSlide>
                                  <div class="preview_img" [lazyLoad]="(!preview.is_parser_url ? host : '') + preview.src"></div>
                                </ng-template>
                              </ng-container>
                            </ng-container>
                            <ng-template #elseTemplateHasPreview>
                              <ng-template swiperSlide>
                                <div class="preview_img" [lazyLoad]="host + task?.avatarFile?.thumbnail + '?company_id=' + company?.id"></div>
                              </ng-template>
                            </ng-template>
                        </swiper>
                    </div>
                </div>
    
                <div class="main_open_job_block" #mainDesk [appDeviceClass]="'custom-scroll'">
                    <div class="active_job_interface">
                        <div class="tabs">
                            <div class="tab" [ngClass]="{'active': selectedIndex.value == 4}" (click)="changeJobTab(4)">
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10 0V6H18V0M10 18H18V8H10M0 18H8V12H0M0 10H8V0H0V10Z" fill="#686868"/>
                                </svg> 
                                <span>{{"Dash" | translate}}</span>
                            </div>
                            <div class="tab" [ngClass]="{'active': selectedIndex.value == 0}" (click)="changeJobTab(0)">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M21.6 0H2.4C1.08 0 0 1.08 0 2.4V24L4.8 19.2H21.6C22.92 19.2 24 18.12 24 16.8V2.4C24 1.08 22.92 0 21.6 0ZM21.6 16.8H3.84L2.4 18.24V2.4H21.6V16.8Z" fill="#686868"/>
                                </svg> 
                                <span>{{"Chats" | translate}}</span>
                            </div>
                            <div class="tab" [ngClass]="{'active': selectedIndex.value == 3}" (click)="changeJobTab(3)">
                                <svg width="12" height="24" viewBox="0 0 12 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.3636 5.45455V18C10.3636 19.1573 9.9039 20.2672 9.08556 21.0856C8.26722 21.9039 7.15731 22.3636 6 22.3636C4.84269 22.3636 3.73278 21.9039 2.91444 21.0856C2.0961 20.2672 1.63636 19.1573 1.63636 18V4.36364C1.63636 3.64032 1.9237 2.94663 2.43516 2.43516C2.94663 1.9237 3.64032 1.63636 4.36364 1.63636C5.08695 1.63636 5.78065 1.9237 6.29211 2.43516C6.80357 2.94663 7.09091 3.64032 7.09091 4.36364V15.8182C7.09091 16.1075 6.97597 16.385 6.77139 16.5896C6.5668 16.7942 6.28933 16.9091 6 16.9091C5.71067 16.9091 5.4332 16.7942 5.22861 16.5896C5.02403 16.385 4.90909 16.1075 4.90909 15.8182V5.45455H3.27273V15.8182C3.27273 16.5415 3.56006 17.2352 4.07153 17.7467C4.58299 18.2581 5.27668 18.5455 6 18.5455C6.72332 18.5455 7.41701 18.2581 7.92847 17.7467C8.43994 17.2352 8.72727 16.5415 8.72727 15.8182V4.36364C8.72727 3.20633 8.26753 2.09642 7.44919 1.27808C6.63085 0.459739 5.52095 0 4.36364 0C3.20633 0 2.09642 0.459739 1.27808 1.27808C0.459739 2.09642 0 3.20633 0 4.36364V18C0 19.5913 0.632141 21.1174 1.75736 22.2426C2.88258 23.3679 4.4087 24 6 24C7.5913 24 9.11742 23.3679 10.2426 22.2426C11.3679 21.1174 12 19.5913 12 18V5.45455H10.3636Z" fill="#686868"/>
                                </svg>
                                <span>{{"Files" | translate}}</span>
                            </div>
                            <div class="tab" [ngClass]="{'active': selectedIndex.value == 1}" (click)="changeJobTab(1)">
                                <svg width="24" height="17" viewBox="0 0 24 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17.4 8.4C17.794 8.4 18.1841 8.3224 18.5481 8.17164C18.912 8.02087 19.2427 7.7999 19.5213 7.52132C19.7999 7.24274 20.0209 6.91203 20.1716 6.54805C20.3224 6.18407 20.4 5.79397 20.4 5.4C20.4 4.60435 20.0839 3.84129 19.5213 3.27868C18.9587 2.71607 18.1957 2.4 17.4 2.4C16.6044 2.4 15.8413 2.71607 15.2787 3.27868C14.7161 3.84129 14.4 4.60435 14.4 5.4C14.4 5.79397 14.4776 6.18407 14.6284 6.54805C14.7791 6.91203 15.0001 7.24274 15.2787 7.52132C15.8413 8.08393 16.6044 8.4 17.4 8.4ZM8.4 7.2C9.35478 7.2 10.2705 6.82072 10.9456 6.14558C11.6207 5.47045 12 4.55478 12 3.6C12 2.64522 11.6207 1.72955 10.9456 1.05442C10.2705 0.379285 9.35478 0 8.4 0C7.44522 0 6.52955 0.379285 5.85442 1.05442C5.17929 1.72955 4.8 2.64522 4.8 3.6C4.8 4.55478 5.17929 5.47045 5.85442 6.14558C6.52955 6.82072 7.44522 7.2 8.4 7.2ZM17.4 10.8C15.204 10.8 10.8 11.904 10.8 14.1V16.8H24V14.1C24 11.904 19.596 10.8 17.4 10.8ZM8.4 9.6C5.604 9.6 0 11.004 0 13.8V16.8H8.4V14.1C8.4 13.08 8.796 11.292 11.244 9.936C10.2 9.72 9.192 9.6 8.4 9.6Z" fill="#686868"/>
                                </svg> 
                                <span>{{"Asgn" | translate}}</span>
                            </div>
                            <div class="tab" [ngClass]="{'active': selectedIndex.value == 2}" (click)="changeJobTab(2)">
                                <svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M19.2 21.6H2.4V8.4H19.2M15.6 0V2.4H6V0H3.6V2.4H2.4C1.068 2.4 0 3.468 0 4.8V21.6C0 22.2365 0.252856 22.847 0.702944 23.2971C1.15303 23.7471 1.76348 24 2.4 24H19.2C19.8365 24 20.447 23.7471 20.8971 23.2971C21.3471 22.847 21.6 22.2365 21.6 21.6V4.8C21.6 3.468 20.52 2.4 19.2 2.4H18V0M16.8 13.2H10.8V19.2H16.8V13.2Z" fill="#686868"/>
                                </svg> 
                                <span>{{"Plan" | translate}}</span>
                            </div>
                        </div>
                        <div class="content" #jobContent [appDeviceClass]="'custom-scroll custom-scroll-t'">
                            <div class="main_job_out">
                                <div class="main_job">
                                    <div class="job">
                                        <div class="d-f-c">
                                            <div class="main_block" style="max-width: 220px;">
                                                <div class="prim_tag">
                                                    <span class="text_one_line" (dblclick)="dblEditWork($event, task, activeJob, 'OperationId', company?.permissions.includes('owner') || checkIsManager(task, company, user), company?.permissions.includes('client'))">{{ activeJob && activeJob.operation && activeJob.operation.name ? activeJob.operation.name : getColumnName(activeJob.operation_id) }}</span>
                                                    <span *ngIf="getPrimaryTag(activeJob)" class="text_one_line val">{{getPrimaryTag(activeJob)?.parameterValue?.value}}</span>
                
                                                    <!-- <div class="jobs_id">
                                                        <div><span>JOB ID</span> {{activeJob.id}}</div>
                                                        <div class="tags cp show_on_hover" *ngIf="company?.permissions.includes('owner') || company?.permissions.includes('admin') || company?.permissions.includes('manager') || company?.permissions.includes('assign_task_parameter')" (click)="$event.preventDefault(); $event.stopPropagation(); openTargetValues([activeJob?.task_id, activeJob.id, 0], activeJob)">
                                                            <svg style="width: 8px; height: 6px;" width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M1.3125 1.875C1.46168 1.875 1.60476 1.81574 1.71025 1.71025C1.81574 1.60476 1.875 1.46168 1.875 1.3125C1.875 1.16332 1.81574 1.02024 1.71025 0.914752C1.60476 0.809263 1.46168 0.75 1.3125 0.75C1.16332 0.75 1.02024 0.809263 0.914752 0.914752C0.809263 1.02024 0.75 1.16332 0.75 1.3125C0.75 1.46168 0.809263 1.60476 0.914752 1.71025C1.02024 1.81574 1.16332 1.875 1.3125 1.875ZM5.77875 2.8425C5.91375 2.9775 6 3.165 6 3.375C6 3.58125 5.9175 3.76875 5.77875 3.90375L3.90375 5.77875C3.76875 5.91375 3.58125 6 3.375 6C3.16875 6 2.98125 5.9175 2.8425 5.77875L0.22125 3.1575C0.0825 3.01875 0 2.83125 0 2.625V0.75C0 0.33375 0.33375 0 0.75 0H2.625C2.83125 0 3.01875 0.0825 3.15375 0.2175L5.77875 2.8425ZM4.3275 0.64125L4.7025 0.26625L7.27875 2.8425C7.4175 2.9775 7.5 3.16875 7.5 3.375C7.5 3.58125 7.4175 3.76875 7.2825 3.90375L5.265 5.92125L4.89 5.54625L7.03125 3.375L4.3275 0.64125Z" fill="#686868"/>
                                                            </svg> 
                                                        </div>
                                                    </div> -->
                                                </div>
                                                <div class="d-f-c" style="justify-content: space-between;">
                                                    <ng-container *ngIf="isManager || isMainEmpl(activeJob) || ifJobClientModerator(activeJob); else elseTemplateMANAGERSmall">
                                                        <div class="job_status" [ngClass]="{'full': activeJob.open}">
                                                            <div class="out_job" (click)="$event.preventDefault(); $event.stopPropagation()">
                                                                <div class="inside_job" (click)="openStatus(activeJob)" [ngClass]="{
                                                                    'first': activeJob?.status_id == '1',
                                                                    'second': activeJob?.status_id == '2',
                                                                    'third': activeJob?.status_id == '3',
                                                                    'fours': activeJob?.status_id == '4',
                                                                    'fives': activeJob?.status_id == '5',
                                                                    'sixs': activeJob?.status_id == '6',
                                                                    'nine': activeJob?.status_id == '97',
                                                                    'seven': activeJob?.status_id == '98',
                                                                    'eight': activeJob?.status_id == '99'}">
                                                                    <span class="text_one_line">{{ getOperationByID(activeJob?.status_id)?.name?.length > 15 ? ((getOperationByID(activeJob?.status_id)?.name | translate)|slice:0:12) + "..." : (getOperationByID(activeJob?.status_id)?.name | translate) }}</span>  
                                                                    <div class="job_price" *ngIf="activeJob?.emplSummCount > 0" [ngClass]="{'more_one': activeJob?.emplSummCount > 1}">
                                                                        <ng-container *ngFor="let emplSumm of activeJob?.emplSummary">
                                                                            <div class="val" *ngIf="emplSumm?.summary > 0">
                                                                                <span class="unit">
                                                                                    {{emplSumm?.short_name}}
                                                                                </span>
                                                                                <span class="price_numb">
                                                                                    {{ emplSumm?.summary | int }}
                                                                                </span>
                                                                                <span class="dot" *ngIf="+emplSumm?.summary > +(emplSumm?.summary | int)">
                                                                                    {{ ((+emplSumm?.summary - +(emplSumm?.summary | int)) | reportsNumber).toString()|slice:1 }}
                                                                                </span>
                                                                                <span class="dot" *ngIf="+emplSumm?.summary == +(emplSumm?.summary | int)">
                                                                                    {{ '.00' }}
                                                                                </span>
                                                                            </div>
                                                                        </ng-container>
                                                                    </div>
                                                                </div>
                                                                <ng-container *ngIf="!activeJob.newStatusFor; else elseTemplateNewStatusSmall">
                        
                                                                    <div class="cst_menu" *ngIf="activeJob.open">
                                                                        <div class="item" *ngFor="let stat of activeJob.statuses" (click)="chooseStatus(activeJob, stat)" [ngClass]="{
                                                                            'first': stat.id == '1',
                                                                            'second': stat.id == '2',
                                                                            'third': stat.id == '3',
                                                                            'fours': stat.id == '4',
                                                                            'fives': stat.id == '5',
                                                                            'sixs': stat.id == '6',
                                                                            'nine': stat.id == '97',
                                                                            'seven': stat.id == '98',
                                                                            'eight': stat.id == '99'}">
                                                                            <span>{{ (stat.name | translate).length > 15 ? (((stat.name | translate) | slice:0:12) + '...') : stat.name | translate}}</span>
                                                                        </div>
                                                                    </div>
                                                                </ng-container>
                                                                <ng-template #elseTemplateNewStatusSmall>
                                                                    <div class="cst_menu" *ngIf="activeJob.open">
                                                                        <div class="item" [ngClass]="{'initial_selected': empl.initial_selected, 'selected': empl.selected}" *ngFor="let empl of activeJob.empl_status" (click)="selectEmpl(activeJob, empl)">
                                                                            <ng-container *ngIf="!empl?.is_partner; else elseTemplateIsPartnerStatus">                                                            
                                                                                <ng-container *ngIf="empl?.employee?.image; else elseTemplateStat">
                                                                                    <img [appHoverUserInfo]="{ user: empl?.employee, company_id: company?.id }" [src]="empl?.employee?.image">
                                                                                </ng-container>
                                                                                <ng-template #elseTemplateStat>
                                                                                    <mat-icon [appHoverUserInfo]="{ user: empl?.employee, company_id: company?.id }">account_circle</mat-icon>
                                                                                </ng-template>
                                                                                <span>{{ empl?.employee?.name }}</span>
                                                                            </ng-container>
                                                                            <ng-template #elseTemplateIsPartnerStatus>
                                                                                <ng-container *ngIf="!!empl?.partnerCompany?.avatarFile?.thumbnail && !!host; else elseTemplatePartnerAvatar">
                                                                                    <img [appHoverUserInfo]="{ user: {partnerCompany: empl?.partnerCompany}, company_id: company?.id }" class="partner_avatar" (error)="onPartnerImgError($event)" [src]="host + empl?.partnerCompany?.avatarFile?.thumbnail + '?company_id=' + company?.id">
                                                                                </ng-container>
                                                                                <ng-template #elseTemplatePartnerAvatar>
                                                                                    <img [appHoverUserInfo]="{ user: {partnerCompany: empl?.partnerCompany}, company_id: company?.id }" class="partner_avatar" (error)="onPartnerImgError($event)" src="{{ imgRoute }}assets/img/partner.png">
                                                                                </ng-template>
                                                                                <span>{{empl?.partnerCompany?.name}}</span>
                                                                            </ng-template>
                                                                        </div>
                                                                        <div class="cst_divider"></div>
                                                                        <div class="item" *ngIf="activeJob.mainEmployees.length == 0" (click)="applyJobStats(activeJob)">
                                                                            <span>{{ "Apply to job" | translate }}</span>
                                                                        </div>
                                                                        <div class="item" (click)="applyStats(activeJob)">
                                                                            <span>{{ "Apply" | translate }}</span>
                                                                        </div>
                                                                        <div class="item" (click)="openStatus(activeJob)">
                                                                            <span>{{ "Cancel" | translate }}</span>
                                                                        </div>
                                                                    </div>
                                                                </ng-template>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                    <ng-template #elseTemplateMANAGERSmall>
                                                        <div class="job_status" [ngClass]="{'full': activeJob.open}">
                                                            <div class="out_job">
                                                                <ng-container *ngIf="!!activeJob?.employeeStatus?.status; else elseTemplateHasPersonalSmall">
                                                                    <div class="inside_job" (click)="$event.preventDefault(); $event.stopPropagation(); openEmplStatus(activeJob)" [ngClass]="{
                                                                        'first': activeJob?.employeeStatus?.status_id == '1',
                                                                        'second': activeJob?.employeeStatus?.status_id == '2',
                                                                        'third': activeJob?.employeeStatus?.status_id == '3',
                                                                        'fours': activeJob?.employeeStatus?.status_id == '4',
                                                                        'fives': activeJob?.employeeStatus?.status_id == '5',
                                                                        'sixs': activeJob?.employeeStatus?.status_id == '6',
                                                                        'nine': activeJob?.employeeStatus?.status_id == '97',
                                                                        'seven': activeJob?.employeeStatus?.status_id == '98',
                                                                        'eight': activeJob?.employeeStatus?.status_id == '99'}">
                                                                        <span class="text_one_line">{{ (activeJob?.employeeStatus?.status?.name | translate).length > 15 ? ((activeJob?.employeeStatus?.status?.name | translate) | slice:0:12) + "..." : (activeJob?.employeeStatus?.status?.name | translate) }}</span>  
                                                                        <div class="job_price" *ngIf="activeJob?.emplSummCount > 0" [ngClass]="{'more_one': activeJob?.emplSummCount > 1}">
                                                                            <ng-container *ngFor="let emplSumm of activeJob?.emplSummary">
                                                                                <div class="val" *ngIf="emplSumm?.summary > 0">
                                                                                    <span class="unit">
                                                                                        {{emplSumm?.short_name}}
                                                                                    </span>
                                                                                    <span class="price_numb">
                                                                                        {{ emplSumm?.summary | int }}
                                                                                    </span>
                                                                                    <span class="dot" *ngIf="+emplSumm?.summary > +(emplSumm?.summary | int)">
                                                                                        {{ ((+emplSumm?.summary - +(emplSumm?.summary | int)) | reportsNumber).toString()|slice:1 }}
                                                                                    </span>
                                                                                    <span class="dot" *ngIf="+emplSumm?.summary == +(emplSumm?.summary | int)">
                                                                                        {{ '.00' }}
                                                                                    </span>
                                                                                </div>
                                                                            </ng-container>
                                                                        </div>
                                                                    </div>
                                                                </ng-container>
                                                                <ng-template #elseTemplateHasPersonalSmall>
                                                                    <div class="inside_job" (click)="$event.preventDefault(); $event.stopPropagation(); openEmplStatus(activeJob)" [ngClass]="{
                                                                        'first': activeJob?.status_id == '1',
                                                                        'second': activeJob?.status_id == '2',
                                                                        'third': activeJob?.status_id == '3',
                                                                        'fours': activeJob?.status_id == '4',
                                                                        'fives': activeJob?.status_id == '5',
                                                                        'sixs': activeJob?.status_id == '6',
                                                                        'nine': activeJob?.status_id == '97',
                                                                        'seven': activeJob?.status_id == '98',
                                                                        'eight': activeJob?.status_id == '99'}">
                                                                        <span class="text_one_line">{{ getOperationByID(activeJob?.status_id)?.name?.length > 15 ? ((getOperationByID(activeJob?.status_id)?.name | translate)|slice:0:12) + "..." : (getOperationByID(activeJob?.status_id)?.name | translate) }}</span>  
                                                                        <div class="job_price" *ngIf="activeJob?.emplSummCount > 0" [ngClass]="{'more_one': activeJob?.emplSummCount > 1}">
                                                                            <ng-container *ngFor="let emplSumm of activeJob?.emplSummary">
                                                                                <div class="val" *ngIf="emplSumm?.summary > 0">
                                                                                    <span class="unit">
                                                                                        {{emplSumm?.short_name}}
                                                                                    </span>
                                                                                    <span class="price_numb">
                                                                                        {{ emplSumm?.summary | int }}
                                                                                    </span>
                                                                                    <span class="dot" *ngIf="+emplSumm?.summary > +(emplSumm?.summary | int)">
                                                                                        {{ ((+emplSumm?.summary - +(emplSumm?.summary | int)) | reportsNumber).toString()|slice:1 }}
                                                                                    </span>
                                                                                    <span class="dot" *ngIf="+emplSumm?.summary == +(emplSumm?.summary | int)">
                                                                                        {{ '.00' }}
                                                                                    </span>
                                                                                </div>
                                                                            </ng-container>
                                                                        </div>
                                                                    </div>
                                                                </ng-template>
                        
                                                                <div class="cst_menu" *ngIf="activeJob.open">
                                                                    <div class="item" *ngFor="let stat of activeJob.statuses" (click)="$event.preventDefault(); $event.stopPropagation(); chooseEmplStatus(activeJob, stat)" [ngClass]="{
                                                                        'first': stat.id == '1',
                                                                        'second': stat.id == '2',
                                                                        'third': stat.id == '3',
                                                                        'fours': stat.id == '4',
                                                                        'fives': stat.id == '5',
                                                                        'sixs': stat.id == '6',
                                                                        'nine': stat.id == '97',
                                                                        'seven': stat.id == '98',
                                                                        'eight': stat.id == '99'}">
                                                                        <span>{{ (stat.name | translate).length > 15 ? (((stat.name | translate) | slice:0:12) + '...') : stat.name | translate}}</span>
                                                                    </div>
                                                                </div>  
                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                    <a *ngIf="!activeJob.open && activeJob.content_url" class="link_to_job show_on_hover" (click)="$event.preventDefault(); $event.stopPropagation(); goToLink(activeJob.content_url)" target="_blank" [href]="activeJob.content_url"><mat-icon style="font-size: 20px; width: 20px; height: 20px;">open_in_new</mat-icon></a>
                                                </div>
                                            </div>
                                            <div class="main_block d-f" *ngIf="activeJob?.name || activeJob?.comment">
                                                <div class="main_name text_3_line" *ngIf="!!activeJob.name" (dblclick)="dblEditWork($event, task, activeJob, 'Name', company?.permissions.includes('owner') || checkIsManager(task, company, user) || activeJob.status.is_lock == 0, company?.permissions.includes('client'))">
                                                    {{activeJob?.name}}
                                                </div>
                                                <div class="main_comment text_4_line" [ngClass]="{'has_name': !!activeJob?.name}" *ngIf="!!activeJob.comment" (dblclick)="dblEditWork($event, task, activeJob, 'Comment', company?.permissions.includes('owner') || checkIsManager(task, company, user) || activeJob.status.is_lock == 0, company?.permissions.includes('client'))">
                                                    <ng-container *ngIf="activeJob.comment.length > 115; else elseTemplateHasMore">
                                                        <span [innerHTML]="activeJob.comment | slice:0:110 | linky:{newWindow: true}"></span> <span style="margin-left: 2.5px; cursor: pointer; color: #3f51b5; font-weight: 500;" (click)="$event.preventDefault(); $event.stopPropagation(); openFullText($event, activeJob.comment)">more</span>
                                                    </ng-container>
                                                    <ng-template #elseTemplateHasMore>
                                                        <span [innerHTML]="activeJob.comment | linky:{newWindow: true}"></span>
                                                    </ng-template>
                                                </div>
                                            </div>
        
                                            <!-- <div class="dates">
                                                <div class="deadline" *ngIf="!!activeJob.planned_completed_at && activeJob.planned_completed_at > 0" [ngClass]="{'big_dead': !activeJob.completed_at, red: !activeJob.completed_at || activeJob.planned_completed_at < activeJob.completed_at}">
                                                    <span>{{ activeJob.planned_completed_at | reportsDate:activeLang }}</span>
                                                </div>
                                                <div class="completed" *ngIf="!!activeJob.completed_at" (click)="$event.preventDefault(); $event.stopPropagation();" (dblclick)="dblEditWork($event, task, activeJob, 'CompletedAt', company?.permissions.includes('owner') || checkIsManager(task, company, user), company?.permissions.includes('client'))">
                                                    <span>{{ activeJob.completed_at | reportsDate:activeLang }}</span>
                                                </div>
                                            </div> -->
                                            <!-- <div class="info_dates show_on_hover">                             
                                                <div class="create_date">
                                                    <span>
                                                        {{ "Created" | translate }} 
                                                    </span>
                                                    <span class="fl">
                                                        {{ activeJob.created_at | reportsDate:activeLang }}
                                                    </span>
                                                    <ng-container *ngIf="activeJob?.createdEmployee">
                                                        <span class="by_show_on_hover">
                                                            {{ "by" | translate }} 
                                                        </span>
                                                        <app-user [class]="'only_photo'" [employee]="activeJob?.createdEmployee"></app-user>
                                                    </ng-container>
                                                </div>
                                                <div class="update_date">
                                                    <span>
                                                        {{ "Updated" | translate }} 
                                                    </span>
                                                    <span class="fl">
                                                        {{ activeJob.updated_at | reportsDate:activeLang }}
                                                    </span>
                                                    <ng-container *ngIf="activeJob?.updatedEmployee">
                                                        <span class="by_show_on_hover">
                                                            {{ "by" | translate }} 
                                                        </span>
                                                        <app-user [class]="'only_photo'" [employee]="activeJob?.updatedEmployee"></app-user>
                                                    </ng-container>
                                                </div>
                                            </div> -->
                                            <div class="main_block d-f wsn">
                                                <div class="ex_white_block col_white" style="padding: 3px 5px;">
                                                    <div class="ex_date" #tooltip="matTooltip" 
                                                    matTooltip="{{'Job ID' | translate}}"
                                                    [matTooltipPosition]="'above'">ID {{activeJob?.id}}</div>
                                                    <div class="ex_date update_date">
                                                        <span>
                                                            Upd
                                                        </span>
                                                        <ng-container *ngIf="activeJob?.updatedEmployee">
                                                            <app-user [class]="'only_photo'" [employee]="activeJob?.updatedEmployee"></app-user>
                                                        </ng-container>
                                                        <span class="fl">
                                                            {{ activeJob.updated_at | reportsDate:activeLang }}
                                                        </span>
                                                    </div>
                                                    <div class="ex_date create_date">
                                                        <span>
                                                            Add
                                                        </span>
                                                        <ng-container *ngIf="activeJob?.createdEmployee">
                                                            <app-user [class]="'only_photo'" [employee]="activeJob?.createdEmployee"></app-user>
                                                        </ng-container>
                                                        <span class="fl">
                                                            {{ activeJob.created_at | reportsDate:activeLang }}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div style="margin-left: 7px;" class="ex_job_date">
                                                    <div class="ex_white_block">
                                                        <div class="deadline" *ngIf="!!activeJob.planned_completed_at && activeJob.planned_completed_at > 0" [ngClass]="{'big_dead': !activeJob.completed_at, red: !activeJob.completed_at || activeJob.planned_completed_at < activeJob.completed_at}" #tooltip="matTooltip" 
                                                            matTooltip="{{'Job deadline' | translate}}"
                                                            [matTooltipPosition]="'above'">
                                                            <span class="fl">{{ activeJob.planned_completed_at | reportsDate:activeLang }}</span>
                                                        </div>
                                                        <div class="deadline" *ngIf="!(!!activeJob.planned_completed_at && activeJob.planned_completed_at > 0)">
                                                            <span class="fl"><span class="fl">{{ "No deadline" | translate }}</span></span>
                                                        </div>
                                                    </div>
                                                    <div class="ex_white_block">
                                                        <div class="completed" *ngIf="!!activeJob.completed_at" (click)="$event.preventDefault(); $event.stopPropagation();" (dblclick)="dblEditWork($event, task, activeJob, 'CompletedAt', company?.permissions.includes('owner') || checkIsManager(task, company, user), company?.permissions.includes('client'))">
                                                            <span class="fl">{{ activeJob.completed_at | reportsDate:activeLang }}</span>
                                                        </div>
                                                        <div class="completed" *ngIf="!activeJob.completed_at" (click)="$event.preventDefault(); $event.stopPropagation(); dblEditWork($event, task, activeJob, 'CompletedAt', company?.permissions.includes('owner') || checkIsManager(task, company, user), company?.permissions.includes('client'))" >
                                                            <span class="fl">{{ "When done?" | translate }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
        
                                            <div class="main_block d-f">
                                                <div class="ex_main_block" *ngIf="getJobManagers() && getJobManagers().length">
                                                    <div class="ex_label">{{"Managers" | translate}}</div>
                                                    <div class="avatars_line" [ngStyle]="{'width': (getJobManagers().length > 4 ? 86 : (26 + (getJobManagers().length - 1) * 20)) + 'px'}">
                                                        <ng-container *ngFor="let item of getJobManagers(); let i = index;">
                                                            <div class="avatar" *ngIf="i < 4"                              
                                                            #tooltip="matTooltip" 
                                                            matTooltip="{{item?.name}}"
                                                            [matTooltipPosition]="'above'" 
                                                            [ngStyle]="{'z-index': getJobManagers().length - i, 'transform': 'translateX(-' + i*6 + 'px)'}">
                                                                <ng-container *ngIf="!!item?.image; else elseTemplateHasAvatar">
                                                                    <img [appHoverUserInfo]="{ user: item, company_id: company?.id }" [src]="item?.image" alt="">
                                                                </ng-container>
                                                                <ng-template #elseTemplateHasAvatar>
                                                                    <mat-icon [appHoverUserInfo]="{ user: item, company_id: company?.id }">account_circle</mat-icon>
                                                                </ng-template>
                                                            </div>
                                                            <div class="has_more" *ngIf="getJobManagers().length > 4 && i == getJobManagers().length - 1">+{{getJobManagers().length - 4}}</div>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                                <div class="ex_main_block cp" (click)="openActiveExecutors()" *ngIf="activeJob?.uniqueEmployees && activeJob?.uniqueEmployees?.length">
                                                    <div class="ex_label">{{"Executors" | translate}}</div>
                                                    <div class="avatars_line" [ngStyle]="{'width': (activeJob?.uniqueEmployees.length > 4 ? 86 : (26 + (activeJob?.uniqueEmployees.length - 1) * 20)) + 'px'}">
                                                        <ng-container *ngFor="let item of activeJob?.uniqueEmployees; let i = index;">
                                                            <div class="avatar" *ngIf="i < 4"  
                                                            [ngClass]="{
                                                            'first': (item?.employeeStatus ? item?.employeeStatus?.status_id : item?.partnerCompanyStatus?.status_id) == '1',
                                                            'second': (item?.employeeStatus ? item?.employeeStatus?.status_id : item?.partnerCompanyStatus?.status_id) == '2',
                                                            'third': (item?.employeeStatus ? item?.employeeStatus?.status_id : item?.partnerCompanyStatus?.status_id) == '3',
                                                            'fours': (item?.employeeStatus ? item?.employeeStatus?.status_id : item?.partnerCompanyStatus?.status_id) == '4',
                                                            'fives': (item?.employeeStatus ? item?.employeeStatus?.status_id : item?.partnerCompanyStatus?.status_id) == '5',
                                                            'sixs': (item?.employeeStatus ? item?.employeeStatus?.status_id : item?.partnerCompanyStatus?.status_id) == '6',
                                                            'nine': (item?.employeeStatus ? item?.employeeStatus?.status_id : item?.partnerCompanyStatus?.status_id) == '97',
                                                            'seven': (item?.employeeStatus ? item?.employeeStatus?.status_id : item?.partnerCompanyStatus?.status_id) == '98',
                                                            'eight': (item?.employeeStatus ? item?.employeeStatus?.status_id : item?.partnerCompanyStatus?.status_id) == '99'}"                              
                                                            #tooltip="matTooltip" 
                                                            matTooltip="{{item?.employee?.name || item?.partnerCompany?.name}}"
                                                            [matTooltipPosition]="'above'" [ngStyle]="{'z-index': activeJob?.uniqueEmployees.length - i, 'transform': 'translateX(-' + i*6 + 'px)'}">
                                                                <ng-container *ngIf="!!item?.employee?.image; else elseTemplateHasUserAvatar">
                                                                    <img [appHoverUserInfo]="{ user: item?.employee, company_id: company?.id }" [src]="item?.employee?.image" alt="">
                                                                </ng-container>
                                                                <ng-template #elseTemplateHasUserAvatar>
                                                                    <ng-container *ngIf="item?.is_partner; else elsePartner">
                                                                        <ng-container *ngIf="!!item?.partnerCompany?.avatarFile?.thumbnail && !!host; else elseTemplatePartnerAvatar">
                                                                            <img [appHoverUserInfo]="{ user: {partnerCompany: item?.partnerCompany}, company_id: company?.id }" class="partner_avatar" (error)="onPartnerImgError($event)" [src]="host + item?.partnerCompany?.avatarFile?.thumbnail + '?company_id=' + company?.id" alt="">
                                                                        </ng-container>
                                                                        <ng-template #elseTemplatePartnerAvatar>
                                                                            <img [appHoverUserInfo]="{ user: {partnerCompany: item?.partnerCompany}, company_id: company?.id }" class="partner_avatar" (error)="onPartnerImgError($event)" src="{{ imgRoute }}assets/img/partner.png" alt="">
                                                                        </ng-template>
                                                                    </ng-container>
                                                                    <ng-template #elsePartner>
                                                                        <mat-icon [appHoverUserInfo]="{ user: item?.employee, company_id: company?.id }">account_circle</mat-icon>
                                                                    </ng-template>
                                                                </ng-template>
                                                            </div>
                                                            <div class="has_more" *ngIf="activeJob?.uniqueEmployees?.length > 4 && i == activeJob?.uniqueEmployees?.length - 1">+{{activeJob?.uniqueEmployees?.length - 4}}</div>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                                <div class="ex_main_block cp" (click)="openActiveExecutors()" *ngIf="activeJob?.uniqueAddEmployee && activeJob?.uniqueAddEmployee?.length">
                                                    <div class="ex_label">{{"Assist-s" | translate}}</div>
                                                    <div class="avatars_line" [ngStyle]="{'width': (activeJob?.uniqueAddEmployee?.length > 4 ? 86 : (26 + (activeJob?.uniqueAddEmployee?.length - 1) * 20)) + 'px'}">
                                                        <ng-container *ngFor="let item of activeJob?.uniqueAddEmployee; let i = index;">
                                                            <div class="avatar" *ngIf="i < 4"        
                                                            [ngClass]="{
                                                                'first': (item?.employeeStatus ? item?.employeeStatus?.status_id : item?.partnerCompanyStatus?.status_id) == '1',
                                                                'second': (item?.employeeStatus ? item?.employeeStatus?.status_id : item?.partnerCompanyStatus?.status_id) == '2',
                                                                'third': (item?.employeeStatus ? item?.employeeStatus?.status_id : item?.partnerCompanyStatus?.status_id) == '3',
                                                                'fours': (item?.employeeStatus ? item?.employeeStatus?.status_id : item?.partnerCompanyStatus?.status_id) == '4',
                                                                'fives': (item?.employeeStatus ? item?.employeeStatus?.status_id : item?.partnerCompanyStatus?.status_id) == '5',
                                                                'sixs': (item?.employeeStatus ? item?.employeeStatus?.status_id : item?.partnerCompanyStatus?.status_id) == '6',
                                                                'nine': (item?.employeeStatus ? item?.employeeStatus?.status_id : item?.partnerCompanyStatus?.status_id) == '97',
                                                                'seven': (item?.employeeStatus ? item?.employeeStatus?.status_id : item?.partnerCompanyStatus?.status_id) == '98',
                                                                'eight': (item?.employeeStatus ? item?.employeeStatus?.status_id : item?.partnerCompanyStatus?.status_id) == '99'}"                           
                                                            #tooltip="matTooltip" 
                                                            matTooltip="{{item?.employee?.name || item?.partnerCompany?.name}}"
                                                            [matTooltipPosition]="'above'" [ngStyle]="{'z-index': activeJob?.uniqueAddEmployee.length - i, 'transform': 'translateX(-' + i*6 + 'px)'}">
                                                                <ng-container *ngIf="!!item?.employee?.image; else elseTemplateHasUserAvatarAss">
                                                                    <img [appHoverUserInfo]="{ user: item?.employee, company_id: company?.id }" [src]="item?.employee?.image" alt="">
                                                                </ng-container>
                                                                <ng-template #elseTemplateHasUserAvatarAss>
                                                                    <ng-container *ngIf="item?.is_partner; else elsePartnerAss">
                                                                        <ng-container *ngIf="!!item?.partnerCompany?.avatarFile?.thumbnail && !!host; else elseTemplatePartnerAvatar">
                                                                            <img [appHoverUserInfo]="{ user: {partnerCompany: item?.partnerCompany}, company_id: company?.id }" class="partner_avatar" (error)="onPartnerImgError($event)" [src]="host + item?.partnerCompany?.avatarFile?.thumbnail + '?company_id=' + company?.id" alt="">
                                                                        </ng-container>
                                                                        <ng-template #elseTemplatePartnerAvatar>
                                                                            <img [appHoverUserInfo]="{ user: {partnerCompany: item?.partnerCompany}, company_id: company?.id }" class="partner_avatar" (error)="onPartnerImgError($event)" src="{{ imgRoute }}assets/img/partner.png" alt="">
                                                                        </ng-template>
                                                                    </ng-container>
                                                                    <ng-template #elsePartnerAss>
                                                                        <mat-icon [appHoverUserInfo]="{ user: item?.employee, company_id: company?.id }">account_circle</mat-icon>
                                                                    </ng-template>
                                                                </ng-template>
                                                            </div>
                                                            <div class="has_more" *ngIf="activeJob?.uniqueAddEmployee?.length > 4 && i == activeJob?.uniqueAddEmployee?.length - 1">+{{activeJob?.uniqueAddEmployee?.length - 4}}</div>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                                <div class="ex_main_block">
                                                    <div class="ex_label">&nbsp;</div>
                                                    <div class="board_btn cp" (click)="addToBoardJob(activeJob)" #tooltip="matTooltip" 
                                                    matTooltip="{{'Add to planning board' | translate}}"
                                                    [matTooltipPosition]="'above'">
                                                        <svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.8 12L6 16.8H9.6V24H12V16.8H15.6L10.8 12ZM19.2 2.4H18V0H15.6V2.4H6V0H3.6V2.4H2.4C1.76348 2.4 1.15303 2.65286 0.702944 3.10294C0.252856 3.55303 0 4.16348 0 4.8V21.6C0 22.2365 0.252856 22.847 0.702944 23.2971C1.15303 23.7471 1.76348 24 2.4 24H7.2V21.6H2.4V8.4H19.2V21.6H14.4V24H19.2C19.8365 24 20.447 23.7471 20.8971 23.2971C21.3471 22.847 21.6 22.2365 21.6 21.6V4.8C21.6 4.16348 21.3471 3.55303 20.8971 3.10294C20.447 2.65286 19.8365 2.4 19.2 2.4Z" fill="#219653"></path></svg>
                                                    </div>
                                                </div>
                                                <div class="ex_main_block cp" (click)="openActiveExecutors()" *ngIf="getJobClients(activeJob) && getJobClients(activeJob)?.length">
                                                    <div class="ex_label">{{"Clients" | translate}}</div>
                                                    <div class="avatars_line" [ngStyle]="{'width': (getJobClients(activeJob)?.length > 4 ? 86 : (26 + (getJobClients(activeJob)?.length - 1) * 20)) + 'px'}">
                                                        <ng-container *ngFor="let item of getJobClients(activeJob); let i = index;">
                                                            <div class="avatar" *ngIf="i < 4"                                   
                                                            #tooltip="matTooltip" 
                                                            matTooltip="{{item?.employee?.name || item?.partnerCompany?.name}}"
                                                            [matTooltipPosition]="'above'" [ngStyle]="{'z-index': getJobClients(activeJob).length - i, 'transform': 'translateX(-' + i*6 + 'px)'}">
                                                                <ng-container *ngIf="!!item?.employee?.image; else elseTemplateHasUserAvatarClients">
                                                                    <img [appHoverUserInfo]="{ user: item?.employee, company_id: company?.id }" [src]="item?.employee?.image" alt="">
                                                                </ng-container>
                                                                <ng-template #elseTemplateHasUserAvatarClients>
                                                                    <ng-container *ngIf="item?.partnerCompany; else elsePartnerClients">
                                                                        <ng-container *ngIf="!!item?.partnerCompany?.avatarFile?.thumbnail && !!host; else elseTemplatePartnerAvatar">
                                                                            <img [appHoverUserInfo]="{ user: {partnerCompany: item?.partnerCompany}, company_id: company?.id }" class="partner_avatar" (error)="onPartnerImgError($event)" [src]="host + item?.partnerCompany?.avatarFile?.thumbnail + '?company_id=' + company?.id" alt="">
                                                                        </ng-container>
                                                                        <ng-template #elseTemplatePartnerAvatar>
                                                                            <img [appHoverUserInfo]="{ user: {partnerCompany: item?.partnerCompany}, company_id: company?.id }" class="partner_avatar" (error)="onPartnerImgError($event)" src="{{ imgRoute }}assets/img/partner.png" alt="">
                                                                        </ng-template>
                                                                    </ng-container>
                                                                    <ng-template #elsePartnerClients>
                                                                        <mat-icon [appHoverUserInfo]="{ user: item?.employee, company_id: company?.id }">account_circle</mat-icon>
                                                                    </ng-template>
                                                                </ng-template>
                                                            </div>
                                                            <div class="has_more" *ngIf="getJobClients(activeJob).length > 4 && i == getJobClients(activeJob).length - 1">+{{getJobClients(activeJob).length - 4}}</div>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </div>    
                                            
                                            <div class="main_block" *ngIf="(activeJob?.parameterValuesToTask && !!activeJob?.parameterValuesToTask?.length) || (company?.permissions.includes('owner') || company?.permissions.includes('admin') || company?.permissions.includes('manager') || company?.permissions.includes('assign_task_parameter')) || (activeJob.files && activeJob.files.length)">
                                                <div class="d-f-c">
                                                    <div class="job_tags text_one_line" *ngIf="activeJob?.parameterValuesToTask && !!activeJob?.parameterValuesToTask?.length">
                                                        <ng-container *ngFor="let item of activeJob?.parameterValuesToTask; let i = index;">{{item?.parameterValue?.value + (i == activeJob?.parameterValuesToTask.length - 1 ? '.' : ', ')}}</ng-container>
                                                    </div>
                                                    <div class="tags cp" *ngIf="company?.permissions.includes('owner') || company?.permissions.includes('admin') || company?.permissions.includes('manager') || company?.permissions.includes('assign_task_parameter')" (click)="$event.preventDefault(); $event.stopPropagation(); openTargetValues([activeJob?.task_id, activeJob.id, 0], activeJob)">
                                                        <svg style="width: 8px; height: 6px;" width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M1.3125 1.875C1.46168 1.875 1.60476 1.81574 1.71025 1.71025C1.81574 1.60476 1.875 1.46168 1.875 1.3125C1.875 1.16332 1.81574 1.02024 1.71025 0.914752C1.60476 0.809263 1.46168 0.75 1.3125 0.75C1.16332 0.75 1.02024 0.809263 0.914752 0.914752C0.809263 1.02024 0.75 1.16332 0.75 1.3125C0.75 1.46168 0.809263 1.60476 0.914752 1.71025C1.02024 1.81574 1.16332 1.875 1.3125 1.875ZM5.77875 2.8425C5.91375 2.9775 6 3.165 6 3.375C6 3.58125 5.9175 3.76875 5.77875 3.90375L3.90375 5.77875C3.76875 5.91375 3.58125 6 3.375 6C3.16875 6 2.98125 5.9175 2.8425 5.77875L0.22125 3.1575C0.0825 3.01875 0 2.83125 0 2.625V0.75C0 0.33375 0.33375 0 0.75 0H2.625C2.83125 0 3.01875 0.0825 3.15375 0.2175L5.77875 2.8425ZM4.3275 0.64125L4.7025 0.26625L7.27875 2.8425C7.4175 2.9775 7.5 3.16875 7.5 3.375C7.5 3.58125 7.4175 3.76875 7.2825 3.90375L5.265 5.92125L4.89 5.54625L7.03125 3.375L4.3275 0.64125Z" fill="#686868"/>
                                                        </svg> 
                                                    </div>
                                                </div>
                                                <div class="files" *ngIf="activeJob.files && activeJob.files.length">
                                                    <div class="file" (click)="$event.preventDefault(); $event.stopPropagation(); previewVideo(file, activeJob)" *ngFor="let file of activeJob.files">
                                                        <ng-container [ngSwitch]="understandFileType(file.content_type)">
                                                            <ng-container *ngSwitchCase="'video'">
                                                                <ng-container *ngIf="file.thumbnail; else elseTemplateThumbnail">
                                                                    <img style="max-width: 100%; max-height: 100%;" (error)="onImgError($event)" [src]="host + file.thumbnail + '?company_id=' + company?.id">
                                                                </ng-container>
                                                                <ng-template #elseTemplateThumbnail>
                                                                    <mat-icon class="file_img_icon" style="color: #3f51b5">
                                                                        movie
                                                                    </mat-icon>
                                                                </ng-template>
                                                            </ng-container>
                                                            <ng-container *ngSwitchCase="'audio'">
                                                                <mat-icon class="file_img_icon" style="color: #3f51b5">
                                                                    music_note
                                                                </mat-icon>
                                                            </ng-container>
                                                            <ng-container *ngSwitchCase="'image'">
                                                                <img (error)="onImgError($event)" style="max-width: 100%; max-height: 100%;" [src]="host + (file.thumbnail ? file.thumbnail : file.original) + '?company_id=' + company?.id">
                                                            </ng-container>
                                                            <ng-container *ngSwitchCase="'pdf'">
                                                                <svg height="800px" width="800px" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
                                                                    viewBox="0 0 303.188 303.188" xml:space="preserve">
                                                                    <g>
                                                                        <polygon style="fill:#E8E8E8;" points="219.821,0 32.842,0 32.842,303.188 270.346,303.188 270.346,50.525 	"/>
                                                                        <path style="fill:#FB3449;" d="M230.013,149.935c-3.643-6.493-16.231-8.533-22.006-9.451c-4.552-0.724-9.199-0.94-13.803-0.936
                                                                            c-3.615-0.024-7.177,0.154-10.693,0.354c-1.296,0.087-2.579,0.199-3.861,0.31c-1.314-1.36-2.584-2.765-3.813-4.202
                                                                            c-7.82-9.257-14.134-19.755-19.279-30.664c1.366-5.271,2.459-10.772,3.119-16.485c1.205-10.427,1.619-22.31-2.288-32.251
                                                                            c-1.349-3.431-4.946-7.608-9.096-5.528c-4.771,2.392-6.113,9.169-6.502,13.973c-0.313,3.883-0.094,7.776,0.558,11.594
                                                                            c0.664,3.844,1.733,7.494,2.897,11.139c1.086,3.342,2.283,6.658,3.588,9.943c-0.828,2.586-1.707,5.127-2.63,7.603
                                                                            c-2.152,5.643-4.479,11.004-6.717,16.161c-1.18,2.557-2.335,5.06-3.465,7.507c-3.576,7.855-7.458,15.566-11.815,23.02
                                                                            c-10.163,3.585-19.283,7.741-26.857,12.625c-4.063,2.625-7.652,5.476-10.641,8.603c-2.822,2.952-5.69,6.783-5.941,11.024
                                                                            c-0.141,2.394,0.807,4.717,2.768,6.137c2.697,2.015,6.271,1.881,9.4,1.225c10.25-2.15,18.121-10.961,24.824-18.387
                                                                            c4.617-5.115,9.872-11.61,15.369-19.465c0.012-0.018,0.024-0.036,0.037-0.054c9.428-2.923,19.689-5.391,30.579-7.205
                                                                            c4.975-0.825,10.082-1.5,15.291-1.974c3.663,3.431,7.621,6.555,11.939,9.164c3.363,2.069,6.94,3.816,10.684,5.119
                                                                            c3.786,1.237,7.595,2.247,11.528,2.886c1.986,0.284,4.017,0.413,6.092,0.335c4.631-0.175,11.278-1.951,11.714-7.57
                                                                            C231.127,152.765,230.756,151.257,230.013,149.935z M119.144,160.245c-2.169,3.36-4.261,6.382-6.232,9.041
                                                                            c-4.827,6.568-10.34,14.369-18.322,17.286c-1.516,0.554-3.512,1.126-5.616,1.002c-1.874-0.11-3.722-0.937-3.637-3.065
                                                                            c0.042-1.114,0.587-2.535,1.423-3.931c0.915-1.531,2.048-2.935,3.275-4.226c2.629-2.762,5.953-5.439,9.777-7.918
                                                                            c5.865-3.805,12.867-7.23,20.672-10.286C120.035,158.858,119.587,159.564,119.144,160.245z M146.366,75.985
                                                                            c-0.602-3.514-0.693-7.077-0.323-10.503c0.184-1.713,0.533-3.385,1.038-4.952c0.428-1.33,1.352-4.576,2.826-4.993
                                                                            c2.43-0.688,3.177,4.529,3.452,6.005c1.566,8.396,0.186,17.733-1.693,25.969c-0.299,1.31-0.632,2.599-0.973,3.883
                                                                            c-0.582-1.601-1.137-3.207-1.648-4.821C147.945,83.048,146.939,79.482,146.366,75.985z M163.049,142.265
                                                                            c-9.13,1.48-17.815,3.419-25.979,5.708c0.983-0.275,5.475-8.788,6.477-10.555c4.721-8.315,8.583-17.042,11.358-26.197
                                                                            c4.9,9.691,10.847,18.962,18.153,27.214c0.673,0.749,1.357,1.489,2.053,2.22C171.017,141.096,166.988,141.633,163.049,142.265z
                                                                            M224.793,153.959c-0.334,1.805-4.189,2.837-5.988,3.121c-5.316,0.836-10.94,0.167-16.028-1.542
                                                                            c-3.491-1.172-6.858-2.768-10.057-4.688c-3.18-1.921-6.155-4.181-8.936-6.673c3.429-0.206,6.9-0.341,10.388-0.275
                                                                            c3.488,0.035,7.003,0.211,10.475,0.664c6.511,0.726,13.807,2.961,18.932,7.186C224.588,152.585,224.91,153.321,224.793,153.959z"/>
                                                                        <polygon style="fill:#FB3449;" points="227.64,25.263 32.842,25.263 32.842,0 219.821,0 	"/>
                                                                        <g>
                                                                            <path style="fill:#A4A9AD;" d="M126.841,241.152c0,5.361-1.58,9.501-4.742,12.421c-3.162,2.921-7.652,4.381-13.472,4.381h-3.643
                                                                                v15.917H92.022v-47.979h16.606c6.06,0,10.611,1.324,13.652,3.971C125.321,232.51,126.841,236.273,126.841,241.152z
                                                                                M104.985,247.387h2.363c1.947,0,3.495-0.546,4.644-1.641c1.149-1.094,1.723-2.604,1.723-4.529c0-3.238-1.794-4.857-5.382-4.857
                                                                                h-3.348C104.985,236.36,104.985,247.387,104.985,247.387z"/>
                                                                            <path style="fill:#A4A9AD;" d="M175.215,248.864c0,8.007-2.205,14.177-6.613,18.509s-10.606,6.498-18.591,6.498h-15.523v-47.979
                                                                                h16.606c7.701,0,13.646,1.969,17.836,5.907C173.119,235.737,175.215,241.426,175.215,248.864z M161.76,249.324
                                                                                c0-4.398-0.87-7.657-2.609-9.78c-1.739-2.122-4.381-3.183-7.926-3.183h-3.773v26.877h2.888c3.939,0,6.826-1.143,8.664-3.43
                                                                                C160.841,257.523,161.76,254.028,161.76,249.324z"/>
                                                                            <path style="fill:#A4A9AD;" d="M196.579,273.871h-12.766v-47.979h28.355v10.403h-15.589v9.156h14.374v10.403h-14.374
                                                                                L196.579,273.871L196.579,273.871z"/>
                                                                        </g>
                                                                        <polygon style="fill:#D1D3D3;" points="219.821,50.525 270.346,50.525 219.821,0 	"/>
                                                                    </g>
                                                                </svg>
                                                            </ng-container>
                                                            <ng-container *ngSwitchDefault>
                                                                <ng-container *ngIf="!!file?.thumbnail; else elseTemplateHasThumb">
                                                                    <img (error)="onImgError($event)" style="max-width: 100%; max-height: 100%;" [src]="host + file.thumbnail + '?company_id=' + company?.id">
                                                                </ng-container>
                                                                <ng-template #elseTemplateHasThumb>
                                                                    <mat-icon class="file_img_icon" style="color: #3f51b5">
                                                                        insert_drive_file
                                                                    </mat-icon>
                                                                </ng-template>
                                                            </ng-container>
                                                        </ng-container>
                                                    </div>
                                                    <!-- <img class="file" (click)="$event.preventDefault(); $event.stopPropagation(); previewVideo(file, activeJob)" [src]="host + file.thumbnail + '?company_id=' + company?.id" alt="" *ngFor="let file of activeJob.files"> -->
                                                    <div class="file more" *ngIf="activeJob.filesCount > activeJob.files.length" (click)="$event.preventDefault(); $event.stopPropagation(); openJob(task?.id, activeJob, {loc: activeJob.status_id == 3 ? '/to_approve' : '/ready_files', selectedIndex: 3})">+{{activeJob.filesCount - activeJob.files.length}}</div>
                                                </div>
                                            </div>
        
        
                
                                            <!-- <div class="names">
                                                <div class="name text_2_line">
                                                    {{activeJob?.name}}
                                                </div>
                
                                                <div class="job_tags text_2_line" *ngIf="activeJob?.parameterValuesToTask && !!activeJob?.parameterValuesToTask?.length">
                                                    <ng-container *ngFor="let item of activeJob?.parameterValuesToTask; let i = index;">{{item?.parameterValue?.value + (i == activeJob?.parameterValuesToTask.length - 1 ? '.' : ', ')}}</ng-container>
                                                </div>
                                                <div class="job_dt show_on_hover">
                                                    <div class="create_date by_show_on_hover">
                                                        <span><ng-container *ngIf="ifJobClient(activeJob)">c</ng-container><ng-container *ngIf="ifJobClientModerator(activeJob)">m</ng-container></span>
                                                    </div>
                                                </div>
                                            </div> -->
                
                                            <!-- <ng-container *ngIf="!!activeJob.filesize; else elseTemplateHasFiles">
                                                <div class="summary cp" *ngIf="!!activeJob.filesize" (click)="$event.preventDefault(); $event.stopPropagation(); openFiles(activeJob, '/');">
                                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <g clip-path="url(#clip0_3095_41992e31)">
                                                        <path d="M11.0003 4.00008V11.6667C11.0003 13.1401 9.80699 14.3334 8.33366 14.3334C6.86033 14.3334 5.66699 13.1401 5.66699 11.6667V3.33341C5.66699 2.41341 6.41366 1.66675 7.33366 1.66675C8.25366 1.66675 9.00033 2.41341 9.00033 3.33341V10.3334C9.00033 10.7001 8.70033 11.0001 8.33366 11.0001C7.96699 11.0001 7.66699 10.7001 7.66699 10.3334V4.00008H6.66699V10.3334C6.66699 11.2534 7.41366 12.0001 8.33366 12.0001C9.25366 12.0001 10.0003 11.2534 10.0003 10.3334V3.33341C10.0003 1.86008 8.80699 0.666748 7.33366 0.666748C5.86033 0.666748 4.66699 1.86008 4.66699 3.33341V11.6667C4.66699 13.6934 6.30699 15.3334 8.33366 15.3334C10.3603 15.3334 12.0003 13.6934 12.0003 11.6667V4.00008H11.0003Z" fill="#6682B4"/>
                                                        </g>
                                                        <defs>
                                                        <clipPath id="clip0_3095_41992e31">
                                                            <rect width="16" height="16" fill="white"/>
                                                        </clipPath>
                                                        </defs>
                                                    </svg>
                                                    <span>{{ activeJob.filesize | filesize }}</span>
                                                </div>
                                            </ng-container>
                                            <ng-template #elseTemplateHasFiles>
                                                <div class="summary cp by_show_on_hover" (click)="$event.preventDefault(); $event.stopPropagation(); openFiles(activeJob, '/');">
                                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <g clip-path="url(#clip0_3095_41992eqwer)">
                                                        <path d="M11.0003 4.00008V11.6667C11.0003 13.1401 9.80699 14.3334 8.33366 14.3334C6.86033 14.3334 5.66699 13.1401 5.66699 11.6667V3.33341C5.66699 2.41341 6.41366 1.66675 7.33366 1.66675C8.25366 1.66675 9.00033 2.41341 9.00033 3.33341V10.3334C9.00033 10.7001 8.70033 11.0001 8.33366 11.0001C7.96699 11.0001 7.66699 10.7001 7.66699 10.3334V4.00008H6.66699V10.3334C6.66699 11.2534 7.41366 12.0001 8.33366 12.0001C9.25366 12.0001 10.0003 11.2534 10.0003 10.3334V3.33341C10.0003 1.86008 8.80699 0.666748 7.33366 0.666748C5.86033 0.666748 4.66699 1.86008 4.66699 3.33341V11.6667C4.66699 13.6934 6.30699 15.3334 8.33366 15.3334C10.3603 15.3334 12.0003 13.6934 12.0003 11.6667V4.00008H11.0003Z" fill="#6682B4"/>
                                                        </g>
                                                        <defs>
                                                        <clipPath id="clip0_3095_41992eqwer">
                                                        <rect width="16" height="16" fill="white"/>
                                                        </clipPath>
                                                        </defs>
                                                    </svg>
                                                    <span>{{ "Files" | translate }}</span>
                                                </div>
                                            </ng-template>
                                            <div class="notes_count card_notes" style="margin: 0 5px;" (click)="$event.preventDefault(); $event.stopPropagation(); openNotes(activeJob, 'job')" *ngIf="activeJob?.documents_count && activeJob?.documents_count > 0">
                                                <mat-icon>text_snippet</mat-icon>
                                                <span>{{ activeJob?.documents_count }}</span>
                                            </div>
                                            <ng-container *ngIf="activeJob?.chatsInfo?.length; else elseTemplateHasChats">
                                                <div class="chat_icon cp" (click)="$event.preventDefault(); $event.stopPropagation(); openJob(task?.id, activeJob, {selectedIndex: 0})">
                                                    <svg style="width: 34px; height: 32px;" width="45" height="42" viewBox="0 0 45 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M10.2124 31.7692H7.14286C3.75077 31.7692 1 29.0142 1 25.616L1.00123 7.15446C1.00123 3.75631 3.75077 1 7.14347 1H37.8584C41.2498 1 44 3.75569 44 7.15385V25.616C44 29.0142 41.2498 31.7692 37.8584 31.7692H19.4267C19.4267 36.8658 15.3018 41 10.2149 41V31.7692H10.2124Z" stroke="#1877F2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                                    </svg>
                                                      
                                                    <div class="count">{{ activeJob?.chatsInfo?.length }}</div>
                                                    <div class="title">{{ "Chats" | translate }}</div>
                                                    <div class="red_point" *ngIf="activeJob?.unReadChats"></div>
                                                </div>
                                            </ng-container>
                                            <ng-template #elseTemplateHasChats>
                                                <div class="chat_icon by_show_on_hover cp" style="align-items: center !important;" (click)="$event.preventDefault(); $event.stopPropagation(); createChat(task, activeJob)" *ngIf="company && company?.permissions?.includes('create_discussion')">
                                                    <svg style="width: 34px; height: 32px;" width="45" height="42" viewBox="0 0 45 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M10.2124 31.7692H7.14286C3.75077 31.7692 1 29.0142 1 25.616L1.00123 7.15446C1.00123 3.75631 3.75077 1 7.14347 1H37.8584C41.2498 1 44 3.75569 44 7.15385V25.616C44 29.0142 41.2498 31.7692 37.8584 31.7692H19.4267C19.4267 36.8658 15.3018 41 10.2149 41V31.7692H10.2124Z" stroke="#1877F2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                                    </svg>
                                                    <div class="count">{{ "Add" }}</div>
                                                    <div class="title">{{ "Chat" | translate }}</div>
                                                </div>
                                            </ng-template> -->
                                        </div>
    
                                        <div class="job_btns">
                                            <div class="flex-col">
                                                <div class="job_dots" [matMenuTriggerFor]="menuActions" #tooltip="matTooltip" 
                                                matTooltip="{{'Job menu' | translate}}"
                                                [matTooltipPosition]="'above'">
                                                    <svg width="12" height="4" style="width: 12px; height: 4px;" viewBox="0 0 12 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M3 2C3 1.175 2.325 0.5 1.5 0.5C0.675 0.5 0 1.175 0 2C0 2.825 0.675 3.5 1.5 3.5C2.325 3.5 3 2.825 3 2ZM4.5 2C4.5 2.825 5.175 3.5 6 3.5C6.825 3.5 7.5 2.825 7.5 2C7.5 1.175 6.825 0.5 6 0.5C5.175 0.5 4.5 1.175 4.5 2ZM9 2C9 2.825 9.675 3.5 10.5 3.5C11.325 3.5 12 2.825 12 2C12 1.175 11.325 0.5 10.5 0.5C9.675 0.5 9 1.175 9 2Z" fill="#A3A3A3"/>
                                                    </svg> 
                                                </div>
                                                <div class="job_link cp" ngxClipboard [cbContent]="origin + '/job/' + activeJob?.acm" (cbOnSuccess)="copyLink('Job')" #tooltip="matTooltip" 
                                                matTooltip="{{'Copy job URL to share' | translate}}"
                                                [matTooltipPosition]="'above'">
                                                    <svg width="12" height="6" style="width: 12px; height: 6px;" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M9 0H6.6V1.2H9C9.99 1.2 10.8 2.01 10.8 3C10.8 3.99 9.99 4.8 9 4.8H6.6V6H9C10.656 6 12 4.656 12 3C12 1.344 10.656 0 9 0ZM5.4 4.8H3C2.01 4.8 1.2 3.99 1.2 3C1.2 2.01 2.01 1.2 3 1.2H5.4V0H3C1.344 0 0 1.344 0 3C0 4.656 1.344 6 3 6H5.4V4.8ZM3.6 2.4H8.4V3.6H3.6V2.4Z" fill="#A3A3A3"/>
                                                    </svg> 
                                                </div>
                                                <div class="job_nots" (click)="checkWorkActions($event, task, activeJob)" #tooltip="matTooltip" 
                                                matTooltip="{{'Activity in job' | translate}}"
                                                [matTooltipPosition]="'above'">
                                                    <svg width="14" height="12" style="width: 14px; height: 12px;" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M8.33333 3.24074H7.33333V6.48148L10.1867 8.12778L10.6667 7.34352L8.33333 5.99537V3.24074ZM8 0C6.4087 0 4.88258 0.614582 3.75736 1.70854C2.63214 2.80251 2 4.28624 2 5.83333H0L2.64 8.44537L5.33333 5.83333H3.33333C3.33333 4.63004 3.825 3.47602 4.70017 2.62516C5.57534 1.7743 6.76232 1.2963 8 1.2963C9.23768 1.2963 10.4247 1.7743 11.2998 2.62516C12.175 3.47602 12.6667 4.63004 12.6667 5.83333C12.6667 7.03663 12.175 8.19064 11.2998 9.0415C10.4247 9.89236 9.23768 10.3704 8 10.3704C6.71333 10.3704 5.54667 9.85833 4.70667 9.03519L3.76 9.95556C4.84667 11.0185 6.33333 11.6667 8 11.6667C9.5913 11.6667 11.1174 11.0521 12.2426 9.95812C13.3679 8.86416 14 7.38043 14 5.83333C14 4.28624 13.3679 2.80251 12.2426 1.70854C11.1174 0.614582 9.5913 0 8 0" fill="#A3A3A3"/>
                                                    </svg>                                  
                                                </div>
                                                <div class="job_scenarios" *ngIf="!(!templates || !templates.length)" style="margin-top: 3px;">
                                                    <ng-container *ngIf="activeJob?.automationScenarios?.length > 0; else elseTemplateJobScen">
                                                        <div class="job_scen_ico">
                                                            <ng-container *ngIf="allDone(activeJob); else elseTemplateAllDone">
                                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M9 5C8.46957 5 7.96086 5.21071 7.58579 5.58579C7.21071 5.96086 7 6.46957 7 7V9C7 9.53043 7.21071 10.0391 7.58579 10.4142C7.96086 10.7893 8.46957 11 9 11H11V13H7V15H11C11.5304 15 12.0391 14.7893 12.4142 14.4142C12.7893 14.0391 13 13.5304 13 13V11C13 10.4696 12.7893 9.96086 12.4142 9.58579C12.0391 9.21071 11.5304 9 11 9H9V7H13V5H9ZM10 0C11.3132 0 12.6136 0.258658 13.8268 0.761205C15.0401 1.26375 16.1425 2.00035 17.0711 2.92893C17.9997 3.85752 18.7362 4.95991 19.2388 6.17317C19.7413 7.38642 20 8.68678 20 10C20 12.6522 18.9464 15.1957 17.0711 17.0711C15.1957 18.9464 12.6522 20 10 20C8.68678 20 7.38642 19.7413 6.17317 19.2388C4.95991 18.7362 3.85752 17.9997 2.92893 17.0711C1.05357 15.1957 0 12.6522 0 10C0 7.34784 1.05357 4.8043 2.92893 2.92893C4.8043 1.05357 7.34784 0 10 0Z" fill="#00FF38"/>
                                                                </svg>
                                                            </ng-container>
                                                            <ng-template #elseTemplateAllDone>
                                                                <ng-container *ngIf="someDone(activeJob); else elseTemplateSomeDone">
                                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M9 5C8.46957 5 7.96086 5.21071 7.58579 5.58579C7.21071 5.96086 7 6.46957 7 7V9C7 9.53043 7.21071 10.0391 7.58579 10.4142C7.96086 10.7893 8.46957 11 9 11H11V13H7V15H11C11.5304 15 12.0391 14.7893 12.4142 14.4142C12.7893 14.0391 13 13.5304 13 13V11C13 10.4696 12.7893 9.96086 12.4142 9.58579C12.0391 9.21071 11.5304 9 11 9H9V7H13V5H9ZM10 0C11.3132 0 12.6136 0.258658 13.8268 0.761205C15.0401 1.26375 16.1425 2.00035 17.0711 2.92893C17.9997 3.85752 18.7362 4.95991 19.2388 6.17317C19.7413 7.38642 20 8.68678 20 10C20 12.6522 18.9464 15.1957 17.0711 17.0711C15.1957 18.9464 12.6522 20 10 20C8.68678 20 7.38642 19.7413 6.17317 19.2388C4.95991 18.7362 3.85752 17.9997 2.92893 17.0711C1.05357 15.1957 0 12.6522 0 10C0 7.34784 1.05357 4.8043 2.92893 2.92893C4.8043 1.05357 7.34784 0 10 0Z" fill="#00FF3880"/>
                                                                    </svg>
                                                                </ng-container>
                                                                <ng-template #elseTemplateSomeDone>
                                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M9 5C8.46957 5 7.96086 5.21071 7.58579 5.58579C7.21071 5.96086 7 6.46957 7 7V9C7 9.53043 7.21071 10.0391 7.58579 10.4142C7.96086 10.7893 8.46957 11 9 11H11V13H7V15H11C11.5304 15 12.0391 14.7893 12.4142 14.4142C12.7893 14.0391 13 13.5304 13 13V11C13 10.4696 12.7893 9.96086 12.4142 9.58579C12.0391 9.21071 11.5304 9 11 9H9V7H13V5H9ZM10 0C11.3132 0 12.6136 0.258658 13.8268 0.761205C15.0401 1.26375 16.1425 2.00035 17.0711 2.92893C17.9997 3.85752 18.7362 4.95991 19.2388 6.17317C19.7413 7.38642 20 8.68678 20 10C20 12.6522 18.9464 15.1957 17.0711 17.0711C15.1957 18.9464 12.6522 20 10 20C8.68678 20 7.38642 19.7413 6.17317 19.2388C4.95991 18.7362 3.85752 17.9997 2.92893 17.0711C1.05357 15.1957 0 12.6522 0 10C0 7.34784 1.05357 4.8043 2.92893 2.92893C4.8043 1.05357 7.34784 0 10 0Z" fill="#B5F2CE"/>
                                                                    </svg>
                                                                </ng-template>
                                                            </ng-template>                                                  
                                                        </div>
                                                    </ng-container>
                                                    <ng-template #elseTemplateJobScen>
                                                        <div class="job_scen_ico by_show_on_hover">
                                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M9 5H13V7H9V9H11C11.5304 9 12.0391 9.21071 12.4142 9.58579C12.7893 9.96086 13 10.4696 13 11V13C13 13.5304 12.7893 14.0391 12.4142 14.4142C12.0391 14.7893 11.5304 15 11 15H7V13H11V11H9C8.46957 11 7.96086 10.7893 7.58579 10.4142C7.21071 10.0391 7 9.53043 7 9V7C7 6.46957 7.21071 5.96086 7.58579 5.58579C7.96086 5.21071 8.46957 5 9 5ZM10 0C11.3132 0 12.6136 0.258658 13.8268 0.761205C15.0401 1.26375 16.1425 2.00035 17.0711 2.92893C17.9997 3.85752 18.7362 4.95991 19.2388 6.17317C19.7413 7.38642 20 8.68678 20 10C20 12.6522 18.9464 15.1957 17.0711 17.0711C15.1957 18.9464 12.6522 20 10 20C8.68678 20 7.38642 19.7413 6.17317 19.2388C4.95991 18.7362 3.85752 17.9997 2.92893 17.0711C1.05357 15.1957 0 12.6522 0 10C0 7.34784 1.05357 4.8043 2.92893 2.92893C4.8043 1.05357 7.34784 0 10 0ZM10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2Z" fill="#C9C9C9"/>
                                                            </svg>
                                                        </div>
                                                    </ng-template>
                                                             
                                                    <div class="job_scenarios_hover" [ngClass]="{'visible': !!activeJob?.scen_visible}" (click)="$event.stopPropagation()">
                                                        <div class="job_scenarios_hover_in">
                                                            <div class="users_select with_tabs_scen">
                                                                <ng-container *ngIf="activeJob.groupScenarios && activeJob.groupScenarios.length; else elseTemplate_groupScenarios">
                                                                    <div class="tabs_scen">
                                                                        <div [ngClass]="{'active': !!activeJob.scenarioTab}" (click)="activeJob.scenarioTab = true">Group</div>
                                                                        <div [ngClass]="{'active': !activeJob.scenarioTab}" (click)="activeJob.scenarioTab = false">All</div>
                                                                    </div>
                                                                    <ng-container [ngSwitch]="activeJob.scenarioTab">
                                                                        <ng-container *ngSwitchCase="true">
                                                                            <mat-chip-list class="scen_list" [multiple]="true" selectable>
                                                                                <label>{{ "Choose a scenario" | translate }}: </label>
                                                                                <ng-container *ngFor="let template of activeJob.groupScenarios; let i = index">
                                                                                    <mat-chip [disabled]="disableTemplate" style="padding-left: 5px !important; display: flex !important;" class="chip_user text_one_line" (click)="toggleChipTemplate(template, activeJob)" [value]="template.id" [selected]="scenIsSelected(activeJob, template)">
                                                                                        <mat-icon class="active" *ngIf="activeScan(activeJob, template)">done</mat-icon>
                                                                                        {{ template.name }}
                                                                                        <mat-icon class="try_scen" *ngIf="scenIsSelected(activeJob, template)" (click)="tryScen($event, activeJob, template)">replay</mat-icon>
                                                                                    </mat-chip>
                                                                                    <ng-container *ngIf="scenIsSelected(activeJob, template) && duplicatesScenCount(activeJob, template).length > 0">
                                                                                        <ng-container *ngFor="let u of [].constructor(duplicatesScenCount(activeJob, template).length); let q = index;">
                                                                                            <mat-chip *ngIf="q != 0" [disabled]="disableTemplate" style="padding-left: 5px !important;" class="chip_user text_one_line dubl_scen" (click)="toggleDuplicateScen(template, activeJob, q)" [value]="template.id" [selected]="true">
                                                                                                <mat-icon class="active" *ngIf="activeDuplicateScan(activeJob, template, q)">done</mat-icon>
                                                                                                {{ template.name }}
                                                                                                <mat-icon class="try_scen" *ngIf="scenIsSelected(activeJob, template)" (click)="tryDuplicateScen($event, activeJob, template, q)">replay</mat-icon>
                                                                                            </mat-chip>
                                                                                        </ng-container>
                                                                                    </ng-container>
                                                                                </ng-container>
                                                                            </mat-chip-list>
                                                                        </ng-container>
                                                                        <ng-container *ngSwitchCase="false">
                                                                            <mat-chip-list class="scen_list" [multiple]="true" selectable>
                                                                                <label>{{ "Choose a scenario" | translate }}: </label>
                                                                                <ng-container *ngFor="let template of templates; let i = index">
                                                                                    <mat-chip [disabled]="disableTemplate" style="padding-left: 5px !important;" class="chip_user text_one_line" (click)="toggleChipTemplate(template, activeJob)" [value]="template.id" [selected]="scenIsSelected(activeJob, template)">
                                                                                        <mat-icon class="active" *ngIf="activeScan(activeJob, template)">done</mat-icon>
                                                                                        {{ template.name }}
                                                                                        <mat-icon class="try_scen" *ngIf="scenIsSelected(activeJob, template)" (click)="tryScen($event, activeJob, template)">replay</mat-icon>
                                                                                    </mat-chip>  
                                                                                    <ng-container *ngIf="scenIsSelected(activeJob, template) && duplicatesScenCount(activeJob, template).length > 0">
                                                                                        <ng-container *ngFor="let u of [].constructor(duplicatesScenCount(activeJob, template).length); let q = index;">
                                                                                            <mat-chip *ngIf="q != 0" [disabled]="disableTemplate" style="padding-left: 5px !important;" class="chip_user text_one_line dubl_scen" (click)="toggleDuplicateScen(template, activeJob, q)" [value]="template.id" [selected]="true">
                                                                                                <mat-icon class="active" *ngIf="activeDuplicateScan(activeJob, template, q)">done</mat-icon>
                                                                                                {{ template.name }}
                                                                                                <mat-icon class="try_scen" *ngIf="scenIsSelected(activeJob, template)" (click)="tryDuplicateScen($event, activeJob, template, q)">replay</mat-icon>
                                                                                            </mat-chip>
                                                                                        </ng-container>
                                                                                    </ng-container>                                                                                  
                                                                                    <mat-chip *ngIf="i == templates?.length - 1 && i > 40" class="small_chip more" (click)="jobScenContainer.open(); activeJob.scen_visible = true;">
                                                                                        {{ "More..." | translate }}
                                                                                    </mat-chip>
                                                                                </ng-container>
                                                                            </mat-chip-list>
                                                                            <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                                                                                <mat-select [multiple]="true" #jobScenContainer [(ngModel)]="activeJob.selectedScenIds" (openedChange)="scenChangeOpen($event, activeJob)">
                                                                                    <mat-option>
                                                                                        <ngx-mat-select-search 
                                                                                        placeholderLabel="{{ 'Search' | translate }}" 
                                                                                        noEntriesFoundLabel="{{ 'no matching templates found' | translate }}"
                                                                                        [formControl]="templatesControl"></ngx-mat-select-search>
                                                                                    </mat-option>
                                                                                    <mat-option *ngFor="let template of templates$ | async" [value]="template.id" (click)="toggleChipTemplate(template, activeJob)">
                                                                                        {{ template.name }}
                                                                                    </mat-option>
                                                                                </mat-select>
                                                                            </mat-form-field>
                                                                        </ng-container>
                                                                    </ng-container>
                                                                </ng-container>
                                                                <ng-template #elseTemplate_groupScenarios>
                                                                    <mat-chip-list class="scen_list" [multiple]="true" selectable>
                                                                        <label>{{ "Choose a scenario" | translate }}: </label>
                                                                        <ng-container *ngFor="let template of templates; let i = index">
                                                                            <mat-chip [disabled]="disableTemplate" style="padding-left: 5px !important;" class="chip_user text_one_line" (click)="toggleChipTemplate(template, activeJob)" [value]="template.id" [selected]="scenIsSelected(activeJob, template)">
                                                                                <mat-icon class="active" *ngIf="activeScan(activeJob, template)">done</mat-icon>
                                                                                {{ template.name }}
                                                                                <mat-icon class="try_scen" *ngIf="scenIsSelected(activeJob, template)" (click)="tryScen($event, activeJob, template)">replay</mat-icon>
                                                                            </mat-chip>
                                                                            <ng-container *ngIf="scenIsSelected(activeJob, template) && duplicatesScenCount(activeJob, template).length > 0">
                                                                                <ng-container *ngFor="let u of [].constructor(duplicatesScenCount(activeJob, template).length); let q = index;">
                                                                                    <mat-chip *ngIf="q != 0" [disabled]="disableTemplate" style="padding-left: 5px !important;" class="chip_user text_one_line dubl_scen" (click)="toggleDuplicateScen(template, activeJob, q)" [value]="template.id" [selected]="true">
                                                                                        <mat-icon class="active" *ngIf="activeDuplicateScan(activeJob, template, q)">done</mat-icon>
                                                                                        {{ template.name }}
                                                                                        <mat-icon class="try_scen" *ngIf="scenIsSelected(activeJob, template)" (click)="tryDuplicateScen($event, activeJob, template, q)">replay</mat-icon>
                                                                                    </mat-chip>
                                                                                </ng-container>
                                                                            </ng-container>
                                                                            <mat-chip *ngIf="i == templates?.length - 1 && i > 40" class="small_chip more" (click)="jobScenContainer.open(); activeJob.scen_visible = true;">
                                                                                {{ "More..." | translate }}
                                                                            </mat-chip>
                                                                        </ng-container>
                                                                    </mat-chip-list>
                                                                    <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                                                                        <mat-select [multiple]="true" #jobScenContainer [(ngModel)]="activeJob.selectedScenIds" (openedChange)="scenChangeOpen($event, activeJob)">
                                                                            <mat-option>
                                                                                <ngx-mat-select-search 
                                                                                placeholderLabel="{{ 'Search' | translate }}" 
                                                                                noEntriesFoundLabel="{{ 'no matching templates found' | translate }}"
                                                                                [formControl]="templatesControl"></ngx-mat-select-search>
                                                                            </mat-option>
                                                                            <mat-option *ngFor="let template of templates$ | async" [value]="template.id" (click)="toggleChipTemplate(template, activeJob)">
                                                                                {{ template.name }}
                                                                            </mat-option>
                                                                        </mat-select>
                                                                    </mat-form-field>
                                                                </ng-template>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                           
                                            <mat-menu #menuActions="matMenu">
                                                <button mat-menu-item (click)="openFiles(activeJob, '/')" *ngIf="company | perm:'see_files'">
                                                  <mat-icon>perm_media</mat-icon> 
                                                  <span>{{ "Files" | translate }}</span>
                                                </button>
                                                <button mat-menu-item (click)="openNotes(activeJob)" #tooltip="matTooltip" 
                                                matTooltip="{{'Text documents' | translate}}"
                                                [matTooltipPosition]="'above'">
                                                    <mat-icon>text_snippet</mat-icon>
                                                    <span>{{ "Notes" | translate }}</span>
                                                </button>
                                                <button mat-menu-item (click)="toShortCut($event, activeJob, 'job')">
                                                    <div class="d-f-c">
                                                      <svg width="18" style="margin-right: 16px;" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M9 0L5.778 5.004L0 6.516L3.78 11.142L3.438 17.1L9 14.931L14.562 17.1L14.22 11.142L18 6.516L12.222 5.004L9 0ZM6.642 7.2C7.2 7.2 7.65 7.65 7.65 8.217C7.65 8.48434 7.5438 8.74073 7.35476 8.92976C7.16573 9.1188 6.90934 9.225 6.642 9.225C6.075 9.225 5.625 8.775 5.625 8.217C5.625 7.65 6.075 7.2 6.642 7.2ZM11.367 7.2C11.925 7.2 12.375 7.65 12.375 8.217C12.375 8.48434 12.2688 8.74073 12.0798 8.92976C11.8907 9.1188 11.6343 9.225 11.367 9.225C10.8 9.225 10.35 8.775 10.35 8.217C10.35 7.65 10.8 7.2 11.367 7.2ZM6.3 11.25H11.7C11.25 12.339 10.179 13.05 9 13.05C7.821 13.05 6.75 12.339 6.3 11.25Z" fill="#686868"/>
                                                      </svg>
                                                      <span>{{ "Shortcut" | translate }}</span>
                                                    </div>
                                                  </button>
                                                <button mat-menu-item *ngIf="company?.permissions.includes('owner') || checkIsManager(task, company, user)" (click)="openAutomation(job)">
                                                    <mat-icon>auto_awesome_motion</mat-icon> 
                                                    <span>{{ "Scenarios" | translate }}</span>
                                                </button>
                                                <button mat-menu-item (click)="openChecklist(task, activeJob)">
                                                  <mat-icon>checklist</mat-icon> 
                                                  <span>{{ "Checklist" | translate }}</span>
                                                </button>
                                                <button mat-menu-item ngxClipboard [cbContent]="origin + '/job/' + activeJob?.acm" (cbOnSuccess)="copyLink('Job')">
                                                  <mat-icon>link</mat-icon> 
                                                  <span>{{ "Copy job url" | translate }}</span>
                                                </button>
                                                <button mat-menu-item (click)="migrateTargetFiles(task, activeJob)" *ngIf="!!user?.is_root">
                                                    <mat-icon>move_up</mat-icon> 
                                                    <span>{{ "Migrate all job files" | translate }}</span>
                                                </button>
                                                <button mat-menu-item (click)="createActiveChat()" *ngIf="company && company?.permissions?.includes('create_discussion')">
                                                  <mat-icon>add_comment</mat-icon> 
                                                  <span>{{ "Add chat" | translate }}</span>
                                                </button>
                                                <button mat-menu-item (click)="addToBoardJob(activeJob)">
                                                    <mat-icon>add</mat-icon> 
                                                    <span>{{ "Add to board" | translate }}</span>
                                                </button>
                                                <button *ngIf="company?.permissions.includes('owner') || company?.permissions.includes('admin') || company?.permissions.includes('manager') || company?.permissions.includes('assign_task_parameter')" mat-menu-item (click)="openTargetValues([task.id, activeJob.id, 0], activeJob)">
                                                  <mat-icon>settings_suggest</mat-icon> 
                                                  <span>{{ "Tags" | translate }}</span>
                                                </button>
                                                <ng-container *ngIf="company?.permissions.includes('owner') || checkIsManager(task, company, user); else elseLockTemplateDesk">
                                                    <button mat-menu-item (click)="goToClients(activeJob)">
                                                        <mat-icon>group</mat-icon> 
                                                        <span>{{ "Clients" | translate }}</span>
                                                    </button>
                                                    <button mat-menu-item (click)="editWork(task, activeJob)">
                                                        <mat-icon>edit</mat-icon> 
                                                        <span>{{ "Edit" | translate }}</span>
                                                    </button>
                                                    <button mat-menu-item (click)="executorsAssignments()">
                                                        <mat-icon>group</mat-icon> 
                                                        <span>{{ "Executors Assignments" | translate }}</span>
                                                    </button>
                                                </ng-container>
                                                <ng-template #elseLockTemplateDesk>
                                                  <button mat-menu-item *ngIf="activeJob?.status?.is_lock == 0 && !company?.permissions.includes('client')" (click)="editEmloyeeWork(task, activeJob)">
                                                    <mat-icon>edit</mat-icon> 
                                                    <span>{{ "Edit" | translate }}</span>
                                                  </button>
                                                </ng-template>
                                                
                                    
                                                
                                                <ng-container *ngIf="company?.permissions.includes('owner') || checkIsManager(task, company, user)">
                                                  <button mat-menu-item (click)="moveWork(task, activeJob)">
                                                    <mat-icon style="transform: rotate(45deg);">zoom_out_map</mat-icon> 
                                                    <span>{{ "Move to card" | translate }}</span>
                                                  </button>
                                                  <button *ngIf="company?.permissions?.includes('owner') || company?.permissions?.includes('admin')" mat-menu-item (click)="deleteWork(task, activeJob)">
                                                    <mat-icon>delete</mat-icon> 
                                                    <span>{{ "Delete" | translate }}</span>
                                                  </button>
                                     
                                                </ng-container>
                                                
                                    
                                            </mat-menu>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="job_name" (dblclick)="dblEditWork($event, task, activeJob, 'Comment', company?.permissions.includes('owner') || checkIsManager(task, company, user) || activeJob.status.is_lock == 0, company?.permissions.includes('client'))">
                                <div [innerHTML]="activeJob?.comment ? (activeJob?.comment.replaceAll('\n','<BR>') | linky:{newWindow: true}) : ''"></div>
                            </div> -->
                            <div class="job_tabs_wrp_out" [ngClass]="{'full_jobs': !(task?.operations && task?.operations.length > 1)}">
                                <div class="job_tabs_wrp" [ngClass]="{'dash_tab': selectedIndex.value == 4}">
                                    <ng-container [ngSwitch]="selectedIndex.value">
                                        <ng-container *ngSwitchCase="0">
                                            <!-- <div class="add_new_item_in_tab" [ngClass]="{'big_btn': !activeJob?.chatsInfo?.length}" (click)="createActiveChat()" *ngIf="company && company?.permissions?.includes('create_discussion')">
                                                + {{ "Add chat" | translate }}
                                            </div> -->
                                        </ng-container>
                                        <ng-container *ngSwitchCase="1">
                                            <!-- <div class="add_new_item_in_tab" (click)="tellAddExecutor()">
                                                + {{ "Assign executor " | translate }}
                                            </div> -->
                                        </ng-container>
                                        <ng-container *ngSwitchCase="2">
                                        
                                        </ng-container>
                                        <ng-container *ngSwitchCase="3">
                                        
                                        </ng-container>
                                    </ng-container>
                                    <mat-tab-group animationDuration="0ms" [disableRipple]="true" [selectedIndex]="selectedIndex.value" (selectedIndexChange)="changeIndex($event)">
                                        <mat-tab>
                                          <ng-template mat-tab-label>
                                            {{ "Chats" | translate }}
                                          </ng-template>
                                          <app-chats-page *ngIf="company_id && selectedIndex.value == 0"
                                          [initCompanyId]="company_id"
                                          [task]="task"
                                          [tasks]="undefined"
                                          [job]="activeJob"
                                          [task_id]="data.task_id"
                                          [task_operation_id]="activeJob?.id"
                                          [initChats]="activeJob?.chatsInfo"
                                          [useOut]="true"
                                          [changing]="updateChatSub"
                                          (ChatAdded)="chatAdded($event)"
                                          ></app-chats-page>
                                        </mat-tab>
                                      
                                        <mat-tab>
                                            <ng-template mat-tab-label>
                                                {{ "Assignments" | translate }}
                                            </ng-template>
                                            <app-executors *ngIf="company && user && task && activeLang && activeJob && statuses && operationsValues && selectedIndex.value == 1" class="" [appDeviceClass]="'custom-scroll custom-scroll-t'" [addItem]="addExecutorSub" [initData]="{
                                                company: company,
                                                user: user,
                                                task: task,
                                                activeLang: activeLang,
                                                job: activeJob,
                                                statuses: statuses,
                                                host: host,
                                                empl_status: company.permissions.includes('owner') || checkIsManager(task, company, user),
                                                operationsValues: operationsValues
                                            }"
                                            (Update)="update()"></app-executors>
                                        </mat-tab>
                                        
                              
                                        <mat-tab>
                                            <ng-template mat-tab-label>
                                                {{ "Schedule" | translate }}
                                            </ng-template>
                                            <app-board-page *ngIf="selectedIndex.value == 2"
                                            [initCompanyId]="company_id"
                                            [task_id]="data.task_id"
                                            [task_operation_id]="activeJob?.id"
                                            [useOut]="true"
                                            >
                                            </app-board-page>
                                        </mat-tab>
                                        <mat-tab>
                                          <ng-template mat-tab-label>
                                              <div>
                                                  <div *ngIf="activeJob.filesize > 0" class="tab_filesize">{{activeJob.filesize | filesize}}</div>
                                                  <div>
                                                      {{ "Files" | translate }}
                                                  </div>
                                              </div>
                                          </ng-template>
                    
                                          <app-file-manager *ngIf="(company | perm:'see_files') && selectedIndex.value == 3"
                                          [useOut]="true"
                                          [useFromJob]="true"
                                          [initCompanyId]="company_id"
                                          [initLocation]="!!data.loc ? data.loc : '/'"
                                          [initData]="{
                                              'task': task,
                                              'work': activeJob,
                                              'company': company
                                          }"></app-file-manager>
                                        </mat-tab>
                                        <mat-tab>
                                            <ng-template mat-tab-label>
                                                {{ "Dash" | translate }}
                                            </ng-template>
                                            <div class="job_dash">
                                                <div class="dash_chats_wrp" *ngIf="todoChats?.length || doneChats?.length">
                                                    <div class="dash_chats" *ngIf="todoChats?.length">
                                                        <div class="dash_chat_head">
                                                            <div class="dash_chat_head_name cp" (click)="changeJobTab(0)">
                                                                <div class="svg_div">
                                                                    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M20.7 0H2.3C1.69 0 1.10499 0.242321 0.673654 0.673654C0.242321 1.10499 0 1.69 0 2.3V23L4.6 18.4H20.7C21.31 18.4 21.895 18.1577 22.3263 17.7263C22.7577 17.295 23 16.71 23 16.1V2.3C23 1.0235 21.965 0 20.7 0Z" fill="#5494EC"/>
                                                                    </svg>
                                                                    <span>{{todoChatsCount}}</span>
                                                                </div>
                                                                {{"Chats to do" | translate}}

                                                                <div class="icon_show_hover">
                                                                    <svg style="width: 7px; height: 11px" width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M1 9.5L4.88571 5.61429L1 1.5" stroke="#5494EC" stroke-width="2" stroke-linecap="round"/>
                                                                    </svg> 
                                                                </div>
                                                            </div>

                                                            <div class="dash_chat_head_btns">
                                                                <div class="blue_btn" (click)="createActiveChat()" *ngIf="company && company?.permissions?.includes('create_discussion')">+ {{ "Add chat" | translate }}</div>
                                                                <!-- <div class="dash_chat_head_btn" *ngIf="item.original" (click)="openFolderArchives(item.original)">
                                                                    <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M5.5 15.9C3.98 15.9 2.68333 15.3767 1.61 14.33C0.536667 13.2767 0 11.9933 0 10.48C0 9.18 0.39 8.02 1.17 7C1.95667 5.98 2.98333 5.33 4.25 5.05C4.53667 3.85 5.24667 2.71 6.38 1.63C7.50667 0.543333 8.71333 0 10 0C10.5533 0 11.0233 0.196667 11.41 0.59C11.8033 0.983333 12 1.45333 12 2V8.05L13.6 6.5L15 7.9L11 11.9L7 7.9L8.4 6.5L10 8.05V2C8.73333 2.23333 7.75 2.84667 7.05 3.84C6.35 4.83333 6 5.85333 6 6.9H5.5C4.53333 6.9 3.71 7.24333 3.03 7.93C2.34333 8.61 2 9.43333 2 10.4C2 11.3667 2.34333 12.2 3.03 12.9C3.71 13.5667 4.53333 13.9 5.5 13.9H17.5C18.2 13.9 18.79 13.6567 19.27 13.17C19.7567 12.69 20 12.1 20 11.4C20 10.7 19.7567 10.11 19.27 9.63C18.79 9.14333 18.2 8.9 17.5 8.9H16V6.9C16 6.1 15.8167 5.35333 15.45 4.66C15.0833 3.97333 14.6 3.38667 14 2.9V0.58C15.2333 1.16 16.21 2.02 16.93 3.16C17.6433 4.32 18 5.56667 18 6.9C19.1533 7.03333 20.1067 7.53333 20.86 8.4C21.62 9.25333 22 10.2533 22 11.4C22 12.6533 21.5633 13.7167 20.69 14.59C19.8167 15.4633 18.7533 15.9 17.5 15.9" fill="#383838"/>
                                                                    </svg> 
                                                                </div> -->
                                                            </div>
                                                        </div>
                                                        <div class="dash_chats_body" [appDeviceClass]="'custom-scroll custom-scroll-t'">
                                                            <div class="dash_chat todo_chat" *ngFor="let item of todoChats" (click)="goToChat(item, activeJob)" [ngClass]="{'highlight_chat': item.is_read == '0'}" (contextmenu)="openChatMenu($event, item); $event.preventDefault(); $event.stopPropagation();">
                                                                <div class="dash_chat_name text_one_line">{{item.name}}</div>

                                                                
                                                                <div class="d-f-c">

                                                                    <ng-container *ngIf="item.updated_at > today; else updatedAtTempl">
                                                                        <div class="chat_updated_at ml-5 time" #tooltip="matTooltip"
                                                                        matTooltip="{{ item.updated_at*1000 | date:'MMM d, y':'timeZone':activeLang | yearPipe }}"
                                                                        [matTooltipPosition]="'left'">
                                                                          {{ item.updated_at*1000 | date:'hh:mm a':'timeZone':activeLang | yearPipe }}
                                                                        </div>
                                                                    </ng-container>
                                                                    <ng-template #updatedAtTempl>
                                                                        <div class="chat_updated_at ml-5" #tooltip="matTooltip"
                                                                        matTooltip="{{ item.updated_at*1000 | date:'hh:mm a':'timeZone':activeLang | yearPipe }}"
                                                                        [matTooltipPosition]="'left'">
                                                                          {{ item.updated_at*1000 | date:'MMM d, y':'timeZone':activeLang | yearPipe }}
                                                                        </div>
                                                                    </ng-template>
                                                                    <div class="to_board_btn ml-5" (click)="$event.preventDefault(); $event.stopPropagation(); addToBoard(item)">
                                                                        <svg style="width: 12px; height: 14px" width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                          <path d="M10.8 12L6 16.8H9.6V24H12V16.8H15.6L10.8 12ZM19.2 2.4H18V0H15.6V2.4H6V0H3.6V2.4H2.4C1.76348 2.4 1.15303 2.65286 0.702944 3.10294C0.252856 3.55303 0 4.16348 0 4.8V21.6C0 22.2365 0.252856 22.847 0.702944 23.2971C1.15303 23.7471 1.76348 24 2.4 24H7.2V21.6H2.4V8.4H19.2V21.6H14.4V24H19.2C19.8365 24 20.447 23.7471 20.8971 23.2971C21.3471 22.847 21.6 22.2365 21.6 21.6V4.8C21.6 4.16348 21.3471 3.55303 20.8971 3.10294C20.447 2.65286 19.8365 2.4 19.2 2.4Z" fill="#219653"/>
                                                                        </svg>
                                                                    </div>
                                                                    <svg [ngClass]="{'non_vis': !item.data}" matTooltip="{{ 'Chat connected to the file. Open chat to see this file.' | translate }}" [matTooltipPosition]="'left'" class="chat_have_file ml-5" style="width: 12px; height: 12px;" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M8.25 3V8.75C8.25 9.855 7.355 10.75 6.25 10.75C5.145 10.75 4.25 9.855 4.25 8.75V2.5C4.25 1.81 4.81 1.25 5.5 1.25C6.19 1.25 6.75 1.81 6.75 2.5V7.75C6.75 8.025 6.525 8.25 6.25 8.25C5.975 8.25 5.75 8.025 5.75 7.75V3H5V7.75C5 8.44 5.56 9 6.25 9C6.94 9 7.5 8.44 7.5 7.75V2.5C7.5 1.395 6.605 0.5 5.5 0.5C4.395 0.5 3.5 1.395 3.5 2.5V8.75C3.5 10.27 4.73 11.5 6.25 11.5C7.77 11.5 9 10.27 9 8.75V3H8.25Z" fill="#6682B4" fill-opacity="0.63"/>
                                                                    </svg>
                                                                    <div class="ml-5 is_pin">
                                                                        <ng-container *ngIf="!item?.pin; else elseTemplatePin">
                                                                          <button class="pin_btn" mat-icon-button [matMenuTriggerFor]="menuPin" (click)="$event.preventDefault(); $event.stopPropagation();">
                                                                            <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                              <path d="M7.2 1.6V5.6C7.2 6.496 7.496 7.328 8 8H3.2C3.72 7.312 4 6.48 4 5.6V1.6H7.2ZM9.6 0H1.6C1.16 0 0.8 0.36 0.8 0.8C0.8 1.24 1.16 1.6 1.6 1.6H2.4V5.6C2.4 6.928 1.328 8 0 8V9.6H4.776V15.2L5.576 16L6.376 15.2V9.6H11.2V8C9.872 8 8.8 6.928 8.8 5.6V1.6H9.6C10.04 1.6 10.4 1.24 10.4 0.8C10.4 0.36 10.04 0 9.6 0Z" fill="#A3A3A3"/>
                                                                            </svg>
                                                                          </button>
                                                                          <mat-menu #menuPin="matMenu">
                                                                            <button mat-menu-item (click)="pinChat($event, item, true)">
                                                                              <span>{{ "Pin SMS within the job" | translate }}</span>
                                                                            </button>
                                                                            <button mat-menu-item (click)="pinChat($event, item)">
                                                                              <span>{{ "Pin SMS within the workspace" | translate }}</span>
                                                                            </button>
                                                                          </mat-menu>
                                                                        </ng-container>
                                                                        <ng-template #elseTemplatePin>
                                                                          <button mat-icon-button (click)="unPinChat($event, item)">
                                                                            <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                              <rect x="3" y="1" width="5" height="8" fill="#A3A3A3"/>
                                                                              <path d="M7.2 1.6V5.6C7.2 6.496 7.496 7.328 8 8H3.2C3.72 7.312 4 6.48 4 5.6V1.6H7.2ZM9.6 0H1.6C1.16 0 0.8 0.36 0.8 0.8C0.8 1.24 1.16 1.6 1.6 1.6H2.4V5.6C2.4 6.928 1.328 8 0 8V9.6H4.776V15.2L5.576 16L6.376 15.2V9.6H11.2V8C9.872 8 8.8 6.928 8.8 5.6V1.6H9.6C10.04 1.6 10.4 1.24 10.4 0.8C10.4 0.36 10.04 0 9.6 0Z" fill="#A3A3A3"/>
                                                                            </svg>
                                                                          </button>
                                                                        </ng-template>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="dash_chats" *ngIf="doneChats?.length">
                                                        <div class="dash_chat_head">
                                                            <div class="dash_chat_head_name cp" (click)="changeJobTab(0)">
                                                                <div class="svg_div">
                                                                    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M20.7 0H2.3C1.69 0 1.10499 0.242321 0.673654 0.673654C0.242321 1.10499 0 1.69 0 2.3V23L4.6 18.4H20.7C21.31 18.4 21.895 18.1577 22.3263 17.7263C22.7577 17.295 23 16.71 23 16.1V2.3C23 1.0235 21.965 0 20.7 0Z" fill="#219653"/>
                                                                    </svg>
                                                                    <span>{{doneChatsCount}}</span>
                                                                </div>
                                                                {{"Chats done" | translate}}

                                                                <div class="icon_show_hover">
                                                                    <svg style="width: 7px; height: 11px" width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M1 9.5L4.88571 5.61429L1 1.5" stroke="#219653" stroke-width="2" stroke-linecap="round"/>
                                                                    </svg> 
                                                                </div>
                                                            </div>

                                                            <div class="dash_chat_head_btns">
                                                                <div class="blue_btn" (click)="createActiveChat()" *ngIf="!todoChats?.length && company && company?.permissions?.includes('create_discussion')">+ {{ "Add chat" | translate }}</div>
                                                                <!-- <div class="dash_chat_head_btn" *ngIf="item.original" (click)="openFolderArchives(item.original)">
                                                                    <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M5.5 15.9C3.98 15.9 2.68333 15.3767 1.61 14.33C0.536667 13.2767 0 11.9933 0 10.48C0 9.18 0.39 8.02 1.17 7C1.95667 5.98 2.98333 5.33 4.25 5.05C4.53667 3.85 5.24667 2.71 6.38 1.63C7.50667 0.543333 8.71333 0 10 0C10.5533 0 11.0233 0.196667 11.41 0.59C11.8033 0.983333 12 1.45333 12 2V8.05L13.6 6.5L15 7.9L11 11.9L7 7.9L8.4 6.5L10 8.05V2C8.73333 2.23333 7.75 2.84667 7.05 3.84C6.35 4.83333 6 5.85333 6 6.9H5.5C4.53333 6.9 3.71 7.24333 3.03 7.93C2.34333 8.61 2 9.43333 2 10.4C2 11.3667 2.34333 12.2 3.03 12.9C3.71 13.5667 4.53333 13.9 5.5 13.9H17.5C18.2 13.9 18.79 13.6567 19.27 13.17C19.7567 12.69 20 12.1 20 11.4C20 10.7 19.7567 10.11 19.27 9.63C18.79 9.14333 18.2 8.9 17.5 8.9H16V6.9C16 6.1 15.8167 5.35333 15.45 4.66C15.0833 3.97333 14.6 3.38667 14 2.9V0.58C15.2333 1.16 16.21 2.02 16.93 3.16C17.6433 4.32 18 5.56667 18 6.9C19.1533 7.03333 20.1067 7.53333 20.86 8.4C21.62 9.25333 22 10.2533 22 11.4C22 12.6533 21.5633 13.7167 20.69 14.59C19.8167 15.4633 18.7533 15.9 17.5 15.9" fill="#383838"/>
                                                                    </svg> 
                                                                </div> -->
                                                            </div>
                                                        </div>
                                                        <div class="dash_chats_body" [appDeviceClass]="'custom-scroll custom-scroll-t'">
                                                            <div class="dash_chat done_chat" *ngFor="let item of doneChats" (click)="goToChat(item, activeJob)" [ngClass]="{'highlight_chat': item.is_read == '0'}" (contextmenu)="openChatMenu($event, item); $event.preventDefault(); $event.stopPropagation();">
                                                                <div class="dash_chat_name text_one_line">{{item.name}}</div>

                                                                <div class="d-f-c">

                                                                    <ng-container *ngIf="item.updated_at > today; else updatedAtTempl">
                                                                        <div class="chat_updated_at ml-5 time" #tooltip="matTooltip"
                                                                        matTooltip="{{ item.updated_at*1000 | date:'MMM d, y':'timeZone':activeLang | yearPipe }}"
                                                                        [matTooltipPosition]="'left'">
                                                                          {{ item.updated_at*1000 | date:'hh:mm a':'timeZone':activeLang | yearPipe }}
                                                                        </div>
                                                                      </ng-container>
                                                                      <ng-template #updatedAtTempl>
                                                                        <div class="chat_updated_at ml-5" #tooltip="matTooltip"
                                                                        matTooltip="{{ item.updated_at*1000 | date:'hh:mm a':'timeZone':activeLang | yearPipe }}"
                                                                        [matTooltipPosition]="'left'">
                                                                          {{ item.updated_at*1000 | date:'MMM d, y':'timeZone':activeLang | yearPipe }}
                                                                        </div>
                                                                      </ng-template>
                                                                    <div class="to_board_btn ml-5" (click)="$event.preventDefault(); $event.stopPropagation(); addToBoard(item)">
                                                                        <svg style="width: 12px; height: 14px" width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                          <path d="M10.8 12L6 16.8H9.6V24H12V16.8H15.6L10.8 12ZM19.2 2.4H18V0H15.6V2.4H6V0H3.6V2.4H2.4C1.76348 2.4 1.15303 2.65286 0.702944 3.10294C0.252856 3.55303 0 4.16348 0 4.8V21.6C0 22.2365 0.252856 22.847 0.702944 23.2971C1.15303 23.7471 1.76348 24 2.4 24H7.2V21.6H2.4V8.4H19.2V21.6H14.4V24H19.2C19.8365 24 20.447 23.7471 20.8971 23.2971C21.3471 22.847 21.6 22.2365 21.6 21.6V4.8C21.6 4.16348 21.3471 3.55303 20.8971 3.10294C20.447 2.65286 19.8365 2.4 19.2 2.4Z" fill="#219653"/>
                                                                        </svg>
                                                                    </div>
                                                                    <svg [ngClass]="{'non_vis': !item.data}" matTooltip="{{ 'Chat connected to the file. Open chat to see this file.' | translate }}" [matTooltipPosition]="'left'" class="chat_have_file ml-5" style="width: 12px; height: 12px;" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M8.25 3V8.75C8.25 9.855 7.355 10.75 6.25 10.75C5.145 10.75 4.25 9.855 4.25 8.75V2.5C4.25 1.81 4.81 1.25 5.5 1.25C6.19 1.25 6.75 1.81 6.75 2.5V7.75C6.75 8.025 6.525 8.25 6.25 8.25C5.975 8.25 5.75 8.025 5.75 7.75V3H5V7.75C5 8.44 5.56 9 6.25 9C6.94 9 7.5 8.44 7.5 7.75V2.5C7.5 1.395 6.605 0.5 5.5 0.5C4.395 0.5 3.5 1.395 3.5 2.5V8.75C3.5 10.27 4.73 11.5 6.25 11.5C7.77 11.5 9 10.27 9 8.75V3H8.25Z" fill="#6682B4" fill-opacity="0.63"/>
                                                                    </svg>
                                                                    <div class="ml-5 is_pin">
                                                                        <ng-container *ngIf="!item?.pin; else elseTemplatePin">
                                                                            <button class="pin_btn" mat-icon-button [matMenuTriggerFor]="menuPin" (click)="$event.preventDefault(); $event.stopPropagation();">
                                                                                <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                  <path d="M7.2 1.6V5.6C7.2 6.496 7.496 7.328 8 8H3.2C3.72 7.312 4 6.48 4 5.6V1.6H7.2ZM9.6 0H1.6C1.16 0 0.8 0.36 0.8 0.8C0.8 1.24 1.16 1.6 1.6 1.6H2.4V5.6C2.4 6.928 1.328 8 0 8V9.6H4.776V15.2L5.576 16L6.376 15.2V9.6H11.2V8C9.872 8 8.8 6.928 8.8 5.6V1.6H9.6C10.04 1.6 10.4 1.24 10.4 0.8C10.4 0.36 10.04 0 9.6 0Z" fill="#A3A3A3"/>
                                                                                </svg>
                                                                            </button>
                                                                            <mat-menu #menuPin="matMenu">
                                                                                <button mat-menu-item (click)="pinChat($event, item, true)">
                                                                                    <span>{{ "Pin SMS within the job" | translate }}</span>
                                                                                </button>
                                                                                <button mat-menu-item (click)="pinChat($event, item)">
                                                                                    <span>{{ "Pin SMS within the workspace" | translate }}</span>
                                                                                </button>
                                                                            </mat-menu>
                                                                        </ng-container>
                                                                        <ng-template #elseTemplatePin>
                                                                          <button mat-icon-button (click)="unPinChat($event, item)">
                                                                            <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                              <rect x="3" y="1" width="5" height="8" fill="#A3A3A3"/>
                                                                              <path d="M7.2 1.6V5.6C7.2 6.496 7.496 7.328 8 8H3.2C3.72 7.312 4 6.48 4 5.6V1.6H7.2ZM9.6 0H1.6C1.16 0 0.8 0.36 0.8 0.8C0.8 1.24 1.16 1.6 1.6 1.6H2.4V5.6C2.4 6.928 1.328 8 0 8V9.6H4.776V15.2L5.576 16L6.376 15.2V9.6H11.2V8C9.872 8 8.8 6.928 8.8 5.6V1.6H9.6C10.04 1.6 10.4 1.24 10.4 0.8C10.4 0.36 10.04 0 9.6 0Z" fill="#A3A3A3"/>
                                                                            </svg>
                                                                          </button>
                                                                        </ng-template>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <ng-container *ngIf="!(todoChats?.length || doneChats?.length) && todoChatsGet && doneChatsGet && company && company?.permissions?.includes('create_discussion')">
                                                    <div class="dash_no_chat">
                                                        <div class="dash_chat_head">
                                                            <div class="dash_chat_head_name cp" (click)="changeJobTab(0)">
                                                                <div class="svg_div">
                                                                    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M20.7 0H2.3C1.69 0 1.10499 0.242321 0.673654 0.673654C0.242321 1.10499 0 1.69 0 2.3V23L4.6 18.4H20.7C21.31 18.4 21.895 18.1577 22.3263 17.7263C22.7577 17.295 23 16.71 23 16.1V2.3C23 1.0235 21.965 0 20.7 0Z" fill="#5494EC"/>
                                                                    </svg>
                                                                </div>
                                                                {{"Chats" | translate}}

                                                                <div class="icon_show_hover">
                                                                    <svg style="width: 7px; height: 11px" width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M1 9.5L4.88571 5.61429L1 1.5" stroke="#5494EC" stroke-width="2" stroke-linecap="round"/>
                                                                    </svg> 
                                                                </div>
                                                            </div>

                                                            <div class="dash_chat_head_btns">
                                                                <!-- <div class="dash_chat_head_btn" *ngIf="item.original" (click)="openFolderArchives(item.original)">
                                                                    <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M5.5 15.9C3.98 15.9 2.68333 15.3767 1.61 14.33C0.536667 13.2767 0 11.9933 0 10.48C0 9.18 0.39 8.02 1.17 7C1.95667 5.98 2.98333 5.33 4.25 5.05C4.53667 3.85 5.24667 2.71 6.38 1.63C7.50667 0.543333 8.71333 0 10 0C10.5533 0 11.0233 0.196667 11.41 0.59C11.8033 0.983333 12 1.45333 12 2V8.05L13.6 6.5L15 7.9L11 11.9L7 7.9L8.4 6.5L10 8.05V2C8.73333 2.23333 7.75 2.84667 7.05 3.84C6.35 4.83333 6 5.85333 6 6.9H5.5C4.53333 6.9 3.71 7.24333 3.03 7.93C2.34333 8.61 2 9.43333 2 10.4C2 11.3667 2.34333 12.2 3.03 12.9C3.71 13.5667 4.53333 13.9 5.5 13.9H17.5C18.2 13.9 18.79 13.6567 19.27 13.17C19.7567 12.69 20 12.1 20 11.4C20 10.7 19.7567 10.11 19.27 9.63C18.79 9.14333 18.2 8.9 17.5 8.9H16V6.9C16 6.1 15.8167 5.35333 15.45 4.66C15.0833 3.97333 14.6 3.38667 14 2.9V0.58C15.2333 1.16 16.21 2.02 16.93 3.16C17.6433 4.32 18 5.56667 18 6.9C19.1533 7.03333 20.1067 7.53333 20.86 8.4C21.62 9.25333 22 10.2533 22 11.4C22 12.6533 21.5633 13.7167 20.69 14.59C19.8167 15.4633 18.7533 15.9 17.5 15.9" fill="#383838"/>
                                                                    </svg> 
                                                                </div> -->
                                                            </div>
                                                        </div>
                                                        <div class="dash_no_chats_body">
                                                            <div class="blue_btn" (click)="createActiveChat()">+ {{ "Add chat" | translate }}</div>
                                                        </div>
                                                    </div>
                                                </ng-container>

                                                <div class="dash_folders" *ngIf="company | perm:'see_files'">
                                                    <div class="dash_folders_head">
                                                        <div class="folder_head_name cp" (click)="openFiles(activeJob, '/')" (contextmenu)="$event.preventDefault(); $event.stopPropagation(); openFolderContext($event, foldersPerm[0])">
                                                            <svg width="29" height="23" viewBox="0 0 29 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path opacity="0.8" d="M11.6 0H2.9C1.2905 0 0 1.27937 0 2.875V20.125C0 20.8875 0.305535 21.6188 0.84939 22.1579C1.39325 22.6971 2.13087 23 2.9 23H26.1C26.8691 23 27.6068 22.6971 28.1506 22.1579C28.6945 21.6188 29 20.8875 29 20.125V5.75C29 4.15438 27.695 2.875 26.1 2.875H14.5L11.6 0Z" fill="#A3A3A3"/>
                                                            </svg>
                                                            {{ "Files" | translate}}
                                                        </div>

                                                        <div class="folder_head_btns">
                                                            <!-- <div class="folder_head_btn" *ngIf="item.original" (click)="openFolderArchives(item.original)">
                                                                <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M5.5 15.9C3.98 15.9 2.68333 15.3767 1.61 14.33C0.536667 13.2767 0 11.9933 0 10.48C0 9.18 0.39 8.02 1.17 7C1.95667 5.98 2.98333 5.33 4.25 5.05C4.53667 3.85 5.24667 2.71 6.38 1.63C7.50667 0.543333 8.71333 0 10 0C10.5533 0 11.0233 0.196667 11.41 0.59C11.8033 0.983333 12 1.45333 12 2V8.05L13.6 6.5L15 7.9L11 11.9L7 7.9L8.4 6.5L10 8.05V2C8.73333 2.23333 7.75 2.84667 7.05 3.84C6.35 4.83333 6 5.85333 6 6.9H5.5C4.53333 6.9 3.71 7.24333 3.03 7.93C2.34333 8.61 2 9.43333 2 10.4C2 11.3667 2.34333 12.2 3.03 12.9C3.71 13.5667 4.53333 13.9 5.5 13.9H17.5C18.2 13.9 18.79 13.6567 19.27 13.17C19.7567 12.69 20 12.1 20 11.4C20 10.7 19.7567 10.11 19.27 9.63C18.79 9.14333 18.2 8.9 17.5 8.9H16V6.9C16 6.1 15.8167 5.35333 15.45 4.66C15.0833 3.97333 14.6 3.38667 14 2.9V0.58C15.2333 1.16 16.21 2.02 16.93 3.16C17.6433 4.32 18 5.56667 18 6.9C19.1533 7.03333 20.1067 7.53333 20.86 8.4C21.62 9.25333 22 10.2533 22 11.4C22 12.6533 21.5633 13.7167 20.69 14.59C19.8167 15.4633 18.7533 15.9 17.5 15.9" fill="#383838"/>
                                                                </svg> 
                                                            </div> -->
                                                        </div>
                                                    </div>
                                                    <div class="root_folder" appDnd (fileDropped)="onFileDropped($event, foldersPerm[0])" *ngIf="foldersPerm && foldersPerm[0] && ((foldersPerm[0].files && foldersPerm[0].files.length > 0) || !foldersPerm[0].cant_write)">
                                                        <!-- <div class="root_head cp" (click)="openFiles(activeJob, '/')">
                                                            Root
                                                        </div> -->
                                                        <div class="root_body" [appDeviceClass]="'custom-scroll custom-scroll-t'" (contextmenu)="$event.preventDefault(); $event.stopPropagation(); openFolderContext($event, foldersPerm[0])">
                                                            
                                                            <ng-container>
                                                                <ng-container *ngFor="let file of foldersPerm[0].files">
                                                                    <ng-container *ngIf="!!file.is_dir; else elseTemplateRootIsDir">  
                                                                        <div class="dash_file cp" appDnd (fileDropped)="onFileDropped($event, false, file)" (click)="openFiles(activeJob, file.location+file.filename)" (contextmenu)="$event.preventDefault(); $event.stopPropagation()">
                                                                            <div class="dash_file_img" [ngClass]="{'op_icon': file.files_count == 0}">
                                                                                <svg width="50" height="40" style="width: 50px !important; height: 40px !important;" viewBox="0 0 50 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path opacity="0.8" d="M20 0H5C2.225 0 0 2.18852 0 4.91803V34.4262C0 35.7306 0.526784 36.9815 1.46447 37.9038C2.40215 38.8261 3.67392 39.3443 5 39.3443H45C46.3261 39.3443 47.5979 38.8261 48.5355 37.9038C49.4732 36.9815 50 35.7306 50 34.4262V9.83607C50 7.10656 47.75 4.91803 45 4.91803H25L20 0Z" fill="#A3A3A3"/>
                                                                                </svg>
                                                                            </div>
                                                                            <div class="dash_file_name text_2_line">
                                                                                {{ file?.filename | translate }}
                                                                            </div>
                                                                        </div>
                                                                    </ng-container>
                                                                    <ng-template #elseTemplateRootIsDir>
                                                                        <div class="dash_file cp" (click)="previewVideo(file, activeJob)" (contextmenu)="$event.preventDefault(); $event.stopPropagation()">
                                                                            <div class="dash_file_img">
                                                                                <ng-container [ngSwitch]="understandFileType(file.content_type)">
                                                                                    <ng-container *ngSwitchCase="'video'">
                                                                                        <ng-container *ngIf="file.thumbnail; else elseTemplateThumbnail">
                                                                                            <img style="max-width: 100%; max-height: 100%;" (error)="onImgError($event)" [src]="host + file.thumbnail + '?company_id=' + company?.id">
                                                                                        </ng-container>
                                                                                        <ng-template #elseTemplateThumbnail>
                                                                                            <mat-icon class="file_img_icon" style="color: #3f51b5">
                                                                                                movie
                                                                                            </mat-icon>
                                                                                        </ng-template>
                                                                                    </ng-container>
                                                                                    <ng-container *ngSwitchCase="'audio'">
                                                                                        <mat-icon class="file_img_icon" style="color: #3f51b5">
                                                                                            music_note
                                                                                        </mat-icon>
                                                                                    </ng-container>
                                                                                    <ng-container *ngSwitchCase="'image'">
                                                                                        <img (error)="onImgError($event)" style="max-width: 100%; max-height: 100%;" [src]="host + (file.thumbnail ? file.thumbnail : file.original) + '?company_id=' + company?.id">
                                                                                    </ng-container>
                                                                                    <ng-container *ngSwitchCase="'pdf'">
                                                                                        <svg height="800px" width="800px" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
                                                                                            viewBox="0 0 303.188 303.188" xml:space="preserve">
                                                                                            <g>
                                                                                                <polygon style="fill:#E8E8E8;" points="219.821,0 32.842,0 32.842,303.188 270.346,303.188 270.346,50.525 	"/>
                                                                                                <path style="fill:#FB3449;" d="M230.013,149.935c-3.643-6.493-16.231-8.533-22.006-9.451c-4.552-0.724-9.199-0.94-13.803-0.936
                                                                                                    c-3.615-0.024-7.177,0.154-10.693,0.354c-1.296,0.087-2.579,0.199-3.861,0.31c-1.314-1.36-2.584-2.765-3.813-4.202
                                                                                                    c-7.82-9.257-14.134-19.755-19.279-30.664c1.366-5.271,2.459-10.772,3.119-16.485c1.205-10.427,1.619-22.31-2.288-32.251
                                                                                                    c-1.349-3.431-4.946-7.608-9.096-5.528c-4.771,2.392-6.113,9.169-6.502,13.973c-0.313,3.883-0.094,7.776,0.558,11.594
                                                                                                    c0.664,3.844,1.733,7.494,2.897,11.139c1.086,3.342,2.283,6.658,3.588,9.943c-0.828,2.586-1.707,5.127-2.63,7.603
                                                                                                    c-2.152,5.643-4.479,11.004-6.717,16.161c-1.18,2.557-2.335,5.06-3.465,7.507c-3.576,7.855-7.458,15.566-11.815,23.02
                                                                                                    c-10.163,3.585-19.283,7.741-26.857,12.625c-4.063,2.625-7.652,5.476-10.641,8.603c-2.822,2.952-5.69,6.783-5.941,11.024
                                                                                                    c-0.141,2.394,0.807,4.717,2.768,6.137c2.697,2.015,6.271,1.881,9.4,1.225c10.25-2.15,18.121-10.961,24.824-18.387
                                                                                                    c4.617-5.115,9.872-11.61,15.369-19.465c0.012-0.018,0.024-0.036,0.037-0.054c9.428-2.923,19.689-5.391,30.579-7.205
                                                                                                    c4.975-0.825,10.082-1.5,15.291-1.974c3.663,3.431,7.621,6.555,11.939,9.164c3.363,2.069,6.94,3.816,10.684,5.119
                                                                                                    c3.786,1.237,7.595,2.247,11.528,2.886c1.986,0.284,4.017,0.413,6.092,0.335c4.631-0.175,11.278-1.951,11.714-7.57
                                                                                                    C231.127,152.765,230.756,151.257,230.013,149.935z M119.144,160.245c-2.169,3.36-4.261,6.382-6.232,9.041
                                                                                                    c-4.827,6.568-10.34,14.369-18.322,17.286c-1.516,0.554-3.512,1.126-5.616,1.002c-1.874-0.11-3.722-0.937-3.637-3.065
                                                                                                    c0.042-1.114,0.587-2.535,1.423-3.931c0.915-1.531,2.048-2.935,3.275-4.226c2.629-2.762,5.953-5.439,9.777-7.918
                                                                                                    c5.865-3.805,12.867-7.23,20.672-10.286C120.035,158.858,119.587,159.564,119.144,160.245z M146.366,75.985
                                                                                                    c-0.602-3.514-0.693-7.077-0.323-10.503c0.184-1.713,0.533-3.385,1.038-4.952c0.428-1.33,1.352-4.576,2.826-4.993
                                                                                                    c2.43-0.688,3.177,4.529,3.452,6.005c1.566,8.396,0.186,17.733-1.693,25.969c-0.299,1.31-0.632,2.599-0.973,3.883
                                                                                                    c-0.582-1.601-1.137-3.207-1.648-4.821C147.945,83.048,146.939,79.482,146.366,75.985z M163.049,142.265
                                                                                                    c-9.13,1.48-17.815,3.419-25.979,5.708c0.983-0.275,5.475-8.788,6.477-10.555c4.721-8.315,8.583-17.042,11.358-26.197
                                                                                                    c4.9,9.691,10.847,18.962,18.153,27.214c0.673,0.749,1.357,1.489,2.053,2.22C171.017,141.096,166.988,141.633,163.049,142.265z
                                                                                                    M224.793,153.959c-0.334,1.805-4.189,2.837-5.988,3.121c-5.316,0.836-10.94,0.167-16.028-1.542
                                                                                                    c-3.491-1.172-6.858-2.768-10.057-4.688c-3.18-1.921-6.155-4.181-8.936-6.673c3.429-0.206,6.9-0.341,10.388-0.275
                                                                                                    c3.488,0.035,7.003,0.211,10.475,0.664c6.511,0.726,13.807,2.961,18.932,7.186C224.588,152.585,224.91,153.321,224.793,153.959z"/>
                                                                                                <polygon style="fill:#FB3449;" points="227.64,25.263 32.842,25.263 32.842,0 219.821,0 	"/>
                                                                                                <g>
                                                                                                    <path style="fill:#A4A9AD;" d="M126.841,241.152c0,5.361-1.58,9.501-4.742,12.421c-3.162,2.921-7.652,4.381-13.472,4.381h-3.643
                                                                                                        v15.917H92.022v-47.979h16.606c6.06,0,10.611,1.324,13.652,3.971C125.321,232.51,126.841,236.273,126.841,241.152z
                                                                                                        M104.985,247.387h2.363c1.947,0,3.495-0.546,4.644-1.641c1.149-1.094,1.723-2.604,1.723-4.529c0-3.238-1.794-4.857-5.382-4.857
                                                                                                        h-3.348C104.985,236.36,104.985,247.387,104.985,247.387z"/>
                                                                                                    <path style="fill:#A4A9AD;" d="M175.215,248.864c0,8.007-2.205,14.177-6.613,18.509s-10.606,6.498-18.591,6.498h-15.523v-47.979
                                                                                                        h16.606c7.701,0,13.646,1.969,17.836,5.907C173.119,235.737,175.215,241.426,175.215,248.864z M161.76,249.324
                                                                                                        c0-4.398-0.87-7.657-2.609-9.78c-1.739-2.122-4.381-3.183-7.926-3.183h-3.773v26.877h2.888c3.939,0,6.826-1.143,8.664-3.43
                                                                                                        C160.841,257.523,161.76,254.028,161.76,249.324z"/>
                                                                                                    <path style="fill:#A4A9AD;" d="M196.579,273.871h-12.766v-47.979h28.355v10.403h-15.589v9.156h14.374v10.403h-14.374
                                                                                                        L196.579,273.871L196.579,273.871z"/>
                                                                                                </g>
                                                                                                <polygon style="fill:#D1D3D3;" points="219.821,50.525 270.346,50.525 219.821,0 	"/>
                                                                                            </g>
                                                                                        </svg>
                                                                                    </ng-container>
                                                                                    <ng-container *ngSwitchDefault>
                                                                                        <ng-container *ngIf="!!file?.thumbnail; else elseTemplateHasThumb">
                                                                                            <img (error)="onImgError($event)" style="max-width: 100%; max-height: 100%;" [src]="host + file.thumbnail + '?company_id=' + company?.id">
                                                                                        </ng-container>
                                                                                        <ng-template #elseTemplateHasThumb>
                                                                                            <mat-icon class="file_img_icon" style="color: #3f51b5">
                                                                                                insert_drive_file
                                                                                            </mat-icon>
                                                                                        </ng-template>
                                                                                    </ng-container>
                                                                                </ng-container>
                                                                            </div>
                                                                            <div class="dash_file_name text_2_line">
                                                                                {{ file?.filename | translate }}
                                                                            </div>
                                                                        </div>
                                                                    </ng-template>
                                                                </ng-container>
                                                            </ng-container>

                                                        </div>
                                                        <div class="no_perm" *ngIf="foldersPerm.length == 1">
                                                            <ng-container *ngFor="let fol of folders">
                                                                <div class="no_perm_folder" *ngIf="fol.id >= 0 && canShowFolder(fol)" appDnd (fileDropped)="onFileDropped($event, fol)" (click)="openFiles(activeJob, fol.location)">
                                                                    <div class="no_perm_img">
                                                                        <svg width="16" height="13" style="width: 16px !important; height: 13px !important;" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path opacity="0.8" d="M6.4 0H1.6C0.712 0 0 0.700328 0 1.57377V11.0164C0 11.4338 0.168571 11.8341 0.468629 12.1292C0.768687 12.4244 1.17565 12.5902 1.6 12.5902H14.4C14.8243 12.5902 15.2313 12.4244 15.5314 12.1292C15.8314 11.8341 16 11.4338 16 11.0164V3.14754C16 2.2741 15.28 1.57377 14.4 1.57377H8L6.4 0Z" [ngStyle]="{'fill': fol?.color}" fill="#D9D8D8"/>
                                                                        </svg>
                                                                    </div>
                                                                    <div class="no_perm_name text_one_line">
                                                                        {{ fol?.location?.replace("/", "") | translate }}
                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                        </div>
                                                    </div>
                                                    <div class="folders" [appDeviceClass]="'custom-scroll custom-scroll-t'">
                                                        <ng-container *ngFor="let item of foldersPerm">
                                                            <div [class]="'folder ' + item.location.replace('/', '')" *ngIf="item.id >= 0 && !item.cant_read" appDnd (fileDropped)="onFileDropped($event, item)">
                                                                <div class="folder_head">
                                                                    <div class="folder_head_name cp" (click)="openFiles(activeJob, item.location)" (contextmenu)="$event.preventDefault(); $event.stopPropagation(); openFolderContext($event, item)">
                                                                        <svg width="29" height="23" viewBox="0 0 29 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path opacity="0.8" d="M11.6 0H2.9C1.2905 0 0 1.27937 0 2.875V20.125C0 20.8875 0.305535 21.6188 0.84939 22.1579C1.39325 22.6971 2.13087 23 2.9 23H26.1C26.8691 23 27.6068 22.6971 28.1506 22.1579C28.6945 21.6188 29 20.8875 29 20.125V5.75C29 4.15438 27.695 2.875 26.1 2.875H14.5L11.6 0Z" fill="#2F80ED"/>
                                                                        </svg> 
                                                                        {{item.location.replace("/","") | translate}}
                                                                    </div>

                                                                    <div class="folder_head_btns">
                                                                        <div class="folder_head_btn" *ngIf="item.original" (click)="openFolderArchives(item.original)">
                                                                            <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M5.5 15.9C3.98 15.9 2.68333 15.3767 1.61 14.33C0.536667 13.2767 0 11.9933 0 10.48C0 9.18 0.39 8.02 1.17 7C1.95667 5.98 2.98333 5.33 4.25 5.05C4.53667 3.85 5.24667 2.71 6.38 1.63C7.50667 0.543333 8.71333 0 10 0C10.5533 0 11.0233 0.196667 11.41 0.59C11.8033 0.983333 12 1.45333 12 2V8.05L13.6 6.5L15 7.9L11 11.9L7 7.9L8.4 6.5L10 8.05V2C8.73333 2.23333 7.75 2.84667 7.05 3.84C6.35 4.83333 6 5.85333 6 6.9H5.5C4.53333 6.9 3.71 7.24333 3.03 7.93C2.34333 8.61 2 9.43333 2 10.4C2 11.3667 2.34333 12.2 3.03 12.9C3.71 13.5667 4.53333 13.9 5.5 13.9H17.5C18.2 13.9 18.79 13.6567 19.27 13.17C19.7567 12.69 20 12.1 20 11.4C20 10.7 19.7567 10.11 19.27 9.63C18.79 9.14333 18.2 8.9 17.5 8.9H16V6.9C16 6.1 15.8167 5.35333 15.45 4.66C15.0833 3.97333 14.6 3.38667 14 2.9V0.58C15.2333 1.16 16.21 2.02 16.93 3.16C17.6433 4.32 18 5.56667 18 6.9C19.1533 7.03333 20.1067 7.53333 20.86 8.4C21.62 9.25333 22 10.2533 22 11.4C22 12.6533 21.5633 13.7167 20.69 14.59C19.8167 15.4633 18.7533 15.9 17.5 15.9" fill="#383838"/>
                                                                            </svg> 
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="folder_body" [appDeviceClass]="'custom-scroll custom-scroll-t'" (contextmenu)="$event.preventDefault(); $event.stopPropagation(); openFolderContext($event, item)">
                                                                    <ng-container *ngIf="item.files">
                                                                        <ng-container *ngFor="let file of item.files">
                                                                            <ng-container *ngIf="!!file.is_dir; else elseTemplateIsDir">             
                                                                                <div class="dash_file dash_file_folder cp" appDnd (fileDropped)="onFileDropped($event, false, file)" (click)="openFiles(activeJob, file.location + '/' + file.filename)" (contextmenu)="$event.preventDefault(); $event.stopPropagation();">
                                                                                    <div [class]="'dash_file_img folder_img ' + item.location.replace('/', '')" [ngClass]="{'op_icon': file.files_count == 0}">
                                                                                        <svg width="61" height="48" style="width: 61px !important; height: 48px !important;" viewBox="0 0 61 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                            <path opacity="0.8" d="M24.4 0H6.1C2.7145 0 0 2.67 0 6V42C0 43.5913 0.642676 45.1174 1.78665 46.2426C2.93062 47.3679 4.48218 48 6.1 48H54.9C56.5178 48 58.0694 47.3679 59.2133 46.2426C60.3573 45.1174 61 43.5913 61 42V12C61 8.67 58.255 6 54.9 6H30.5L24.4 0Z" fill="#2F80ED"/>
                                                                                        </svg> 
                                                                                    </div>
                                                                                    <div class="dash_file_name text_2_line">
                                                                                        {{ file?.filename | translate }}
                                                                                    </div>
                                                                                </div>
                                                                            </ng-container>
                                                                            <ng-template #elseTemplateIsDir>
                                                                                <div class="dash_file cp" (click)="previewVideo(file, activeJob)" (contextmenu)="$event.preventDefault(); $event.stopPropagation();">
                                                                                    <div class="dash_file_img">
                                                                                        <ng-container [ngSwitch]="understandFileType(file.content_type)">
                                                                                            <ng-container *ngSwitchCase="'video'">
                                                                                                <ng-container *ngIf="file.thumbnail; else elseTemplateThumbnail">
                                                                                                    <img style="max-width: 100%; max-height: 100%;" (error)="onImgError($event)" [src]="host + file.thumbnail + '?company_id=' + company?.id">
                                                                                                </ng-container>
                                                                                                <ng-template #elseTemplateThumbnail>
                                                                                                    <mat-icon class="file_img_icon" style="color: #3f51b5">
                                                                                                        movie
                                                                                                    </mat-icon>
                                                                                                </ng-template>
                                                                                            </ng-container>
                                                                                            <ng-container *ngSwitchCase="'audio'">
                                                                                                <mat-icon class="file_img_icon" style="color: #3f51b5">
                                                                                                    music_note
                                                                                                </mat-icon>
                                                                                            </ng-container>
                                                                                            <ng-container *ngSwitchCase="'image'">
                                                                                                <img (error)="onImgError($event)" style="max-width: 100%; max-height: 100%;" [src]="host + (file.thumbnail ? file.thumbnail : file.original) + '?company_id=' + company?.id">
                                                                                            </ng-container>
                                                                                            <ng-container *ngSwitchCase="'pdf'">
                                                                                                <svg height="800px" width="800px" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
                                                                                                    viewBox="0 0 303.188 303.188" xml:space="preserve">
                                                                                                    <g>
                                                                                                        <polygon style="fill:#E8E8E8;" points="219.821,0 32.842,0 32.842,303.188 270.346,303.188 270.346,50.525 	"/>
                                                                                                        <path style="fill:#FB3449;" d="M230.013,149.935c-3.643-6.493-16.231-8.533-22.006-9.451c-4.552-0.724-9.199-0.94-13.803-0.936
                                                                                                            c-3.615-0.024-7.177,0.154-10.693,0.354c-1.296,0.087-2.579,0.199-3.861,0.31c-1.314-1.36-2.584-2.765-3.813-4.202
                                                                                                            c-7.82-9.257-14.134-19.755-19.279-30.664c1.366-5.271,2.459-10.772,3.119-16.485c1.205-10.427,1.619-22.31-2.288-32.251
                                                                                                            c-1.349-3.431-4.946-7.608-9.096-5.528c-4.771,2.392-6.113,9.169-6.502,13.973c-0.313,3.883-0.094,7.776,0.558,11.594
                                                                                                            c0.664,3.844,1.733,7.494,2.897,11.139c1.086,3.342,2.283,6.658,3.588,9.943c-0.828,2.586-1.707,5.127-2.63,7.603
                                                                                                            c-2.152,5.643-4.479,11.004-6.717,16.161c-1.18,2.557-2.335,5.06-3.465,7.507c-3.576,7.855-7.458,15.566-11.815,23.02
                                                                                                            c-10.163,3.585-19.283,7.741-26.857,12.625c-4.063,2.625-7.652,5.476-10.641,8.603c-2.822,2.952-5.69,6.783-5.941,11.024
                                                                                                            c-0.141,2.394,0.807,4.717,2.768,6.137c2.697,2.015,6.271,1.881,9.4,1.225c10.25-2.15,18.121-10.961,24.824-18.387
                                                                                                            c4.617-5.115,9.872-11.61,15.369-19.465c0.012-0.018,0.024-0.036,0.037-0.054c9.428-2.923,19.689-5.391,30.579-7.205
                                                                                                            c4.975-0.825,10.082-1.5,15.291-1.974c3.663,3.431,7.621,6.555,11.939,9.164c3.363,2.069,6.94,3.816,10.684,5.119
                                                                                                            c3.786,1.237,7.595,2.247,11.528,2.886c1.986,0.284,4.017,0.413,6.092,0.335c4.631-0.175,11.278-1.951,11.714-7.57
                                                                                                            C231.127,152.765,230.756,151.257,230.013,149.935z M119.144,160.245c-2.169,3.36-4.261,6.382-6.232,9.041
                                                                                                            c-4.827,6.568-10.34,14.369-18.322,17.286c-1.516,0.554-3.512,1.126-5.616,1.002c-1.874-0.11-3.722-0.937-3.637-3.065
                                                                                                            c0.042-1.114,0.587-2.535,1.423-3.931c0.915-1.531,2.048-2.935,3.275-4.226c2.629-2.762,5.953-5.439,9.777-7.918
                                                                                                            c5.865-3.805,12.867-7.23,20.672-10.286C120.035,158.858,119.587,159.564,119.144,160.245z M146.366,75.985
                                                                                                            c-0.602-3.514-0.693-7.077-0.323-10.503c0.184-1.713,0.533-3.385,1.038-4.952c0.428-1.33,1.352-4.576,2.826-4.993
                                                                                                            c2.43-0.688,3.177,4.529,3.452,6.005c1.566,8.396,0.186,17.733-1.693,25.969c-0.299,1.31-0.632,2.599-0.973,3.883
                                                                                                            c-0.582-1.601-1.137-3.207-1.648-4.821C147.945,83.048,146.939,79.482,146.366,75.985z M163.049,142.265
                                                                                                            c-9.13,1.48-17.815,3.419-25.979,5.708c0.983-0.275,5.475-8.788,6.477-10.555c4.721-8.315,8.583-17.042,11.358-26.197
                                                                                                            c4.9,9.691,10.847,18.962,18.153,27.214c0.673,0.749,1.357,1.489,2.053,2.22C171.017,141.096,166.988,141.633,163.049,142.265z
                                                                                                            M224.793,153.959c-0.334,1.805-4.189,2.837-5.988,3.121c-5.316,0.836-10.94,0.167-16.028-1.542
                                                                                                            c-3.491-1.172-6.858-2.768-10.057-4.688c-3.18-1.921-6.155-4.181-8.936-6.673c3.429-0.206,6.9-0.341,10.388-0.275
                                                                                                            c3.488,0.035,7.003,0.211,10.475,0.664c6.511,0.726,13.807,2.961,18.932,7.186C224.588,152.585,224.91,153.321,224.793,153.959z"/>
                                                                                                        <polygon style="fill:#FB3449;" points="227.64,25.263 32.842,25.263 32.842,0 219.821,0 	"/>
                                                                                                        <g>
                                                                                                            <path style="fill:#A4A9AD;" d="M126.841,241.152c0,5.361-1.58,9.501-4.742,12.421c-3.162,2.921-7.652,4.381-13.472,4.381h-3.643
                                                                                                                v15.917H92.022v-47.979h16.606c6.06,0,10.611,1.324,13.652,3.971C125.321,232.51,126.841,236.273,126.841,241.152z
                                                                                                                M104.985,247.387h2.363c1.947,0,3.495-0.546,4.644-1.641c1.149-1.094,1.723-2.604,1.723-4.529c0-3.238-1.794-4.857-5.382-4.857
                                                                                                                h-3.348C104.985,236.36,104.985,247.387,104.985,247.387z"/>
                                                                                                            <path style="fill:#A4A9AD;" d="M175.215,248.864c0,8.007-2.205,14.177-6.613,18.509s-10.606,6.498-18.591,6.498h-15.523v-47.979
                                                                                                                h16.606c7.701,0,13.646,1.969,17.836,5.907C173.119,235.737,175.215,241.426,175.215,248.864z M161.76,249.324
                                                                                                                c0-4.398-0.87-7.657-2.609-9.78c-1.739-2.122-4.381-3.183-7.926-3.183h-3.773v26.877h2.888c3.939,0,6.826-1.143,8.664-3.43
                                                                                                                C160.841,257.523,161.76,254.028,161.76,249.324z"/>
                                                                                                            <path style="fill:#A4A9AD;" d="M196.579,273.871h-12.766v-47.979h28.355v10.403h-15.589v9.156h14.374v10.403h-14.374
                                                                                                                L196.579,273.871L196.579,273.871z"/>
                                                                                                        </g>
                                                                                                        <polygon style="fill:#D1D3D3;" points="219.821,50.525 270.346,50.525 219.821,0 	"/>
                                                                                                    </g>
                                                                                                </svg>
                                                                                            </ng-container>
                                                                                            <ng-container *ngSwitchDefault>
                                                                                                <ng-container *ngIf="!!file?.thumbnail; else elseTemplateHasThumb">
                                                                                                    <img (error)="onImgError($event)" style="max-width: 100%; max-height: 100%;" [src]="host + file.thumbnail + '?company_id=' + company?.id">
                                                                                                </ng-container>
                                                                                                <ng-template #elseTemplateHasThumb>
                                                                                                    <mat-icon class="file_img_icon" style="color: #3f51b5">
                                                                                                        insert_drive_file
                                                                                                    </mat-icon>
                                                                                                </ng-template>
                                                                                            </ng-container>
                                                                                        </ng-container>
                                                                                    </div>
                                                                                    <div class="dash_file_name text_2_line">
                                                                                        {{ file?.filename | translate }}
                                                                                    </div>
                                                                                </div>
                                                                                
                                                                            </ng-template>
                                                                            
                                                                        </ng-container>
                                                                        
                                                                    </ng-container>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </div>
                                        </mat-tab>
                                      </mat-tab-group>
                                      
                                </div>
                            </div>

                            <ng-container *ngIf="task?.operations && task?.operations.length > 1">
                                <ng-container *ngTemplateOutlet="elseTemplateOtherJobs"></ng-container>
                            </ng-container>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </ng-container>
    
    
    <ng-template #elseTemplateOtherJobs>
        <div class="other_wrp">
            <div class="title_out_wrp">
                <div class="title_out">
                    <span class="title">
                        {{ "Jobs from this card" | translate }}
                    </span>
        
                    <ng-container *ngIf="company && company?.permissions?.includes('create_task_operation')">
                        <div class="add_job" *ngIf="(company?.permissions.includes('owner') || checkIsManager(task, company, user)) && task?.company_id == company?.id; else elseAddWorkDesk" (click)="addWork()">
                            + {{ 'Add job ' | translate }}
                        </div>
                        <ng-template #elseAddWorkDesk>
                            <div class="add_job" *ngIf="!company?.permissions.includes('client')" (click)="addEmplWork()">
                                + {{ 'Add job ' | translate }}
                            </div>
                        </ng-template>
                    </ng-container>
                    
                </div>
            </div>
    
            <div class="jobs_wrp" [appDeviceClass]="'custom-scroll custom-scroll-t'">
                <ng-container *ngFor="let job of task?.operations">
                    <div class="job" (click)="selectJob(job)" *ngIf="job.id != activeJob.id">
                        <div *ngIf="job.filesize && (company | perm:'see_files')" class="summary" #tooltip="matTooltip" [matTooltipPosition]="'above'" matTooltip="{{ job?.file_stat_updated_at > 0 ? (job?.file_stat_updated_at*1000 | date:'hh:mm a, MMM d, y':'':activeLang | yearPipe) : '' }}">{{ job.filesize | filesize }}</div>
                        <div class="job_btns">
                            <div class="job_nots" (click)="$event.preventDefault(); $event.stopPropagation(); checkWorkActions($event, task, job)">
                                <svg width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.6905 5.47619H12.0476V10.9524L16.7352 13.7343L17.5238 12.409L13.6905 10.131V5.47619ZM13.1429 0C10.5286 0 8.02138 1.03852 6.1728 2.88709C4.32423 4.73566 3.28571 7.24287 3.28571 9.85714H0L4.33714 14.271L8.7619 9.85714H5.47619C5.47619 7.82382 6.28393 5.87377 7.72171 4.43599C9.15948 2.99821 11.1095 2.19048 13.1429 2.19048C15.1762 2.19048 17.1262 2.99821 18.564 4.43599C20.0018 5.87377 20.8095 7.82382 20.8095 9.85714C20.8095 11.8905 20.0018 13.8405 18.564 15.2783C17.1262 16.7161 15.1762 17.5238 13.1429 17.5238C11.029 17.5238 9.11238 16.6586 7.73238 15.2676L6.17714 16.8229C7.96238 18.619 10.4048 19.7143 13.1429 19.7143C15.7571 19.7143 18.2643 18.6758 20.1129 16.8272C21.9615 14.9786 23 12.4714 23 9.85714C23 7.24287 21.9615 4.73566 20.1129 2.88709C18.2643 1.03852 15.7571 0 13.1429 0Z" fill="#686868"/>
                                </svg>                                  
                            </div>
                            <div class="job_dots" [matMenuTriggerFor]="menuActions" (click)="$event.preventDefault(); $event.stopPropagation();" #tooltip="matTooltip" 
                            matTooltip="{{'Job menu' | translate}}"
                            [matTooltipPosition]="'above'">
                                <svg  width="14" height="4" viewBox="0 0 14 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.8281 3.17188C10.4896 2.83333 10.3203 2.44271 10.3203 2C10.3203 1.55729 10.4896 1.16667 10.8281 0.828125C11.1667 0.489583 11.5573 0.320313 12 0.320313C12.4427 0.320313 12.8333 0.489583 13.1719 0.828125C13.5104 1.16667 13.6797 1.55729 13.6797 2C13.6797 2.44271 13.5104 2.83333 13.1719 3.17188C12.8333 3.51042 12.4427 3.67969 12 3.67969C11.5573 3.67969 11.1667 3.51042 10.8281 3.17188ZM5.82812 3.17188C5.48958 2.83333 5.32031 2.44271 5.32031 2C5.32031 1.55729 5.48958 1.16667 5.82812 0.828125C6.16667 0.489583 6.55729 0.320312 7 0.320312C7.44271 0.320312 7.83333 0.489583 8.17188 0.828125C8.51042 1.16667 8.67969 1.55729 8.67969 2C8.67969 2.44271 8.51042 2.83333 8.17188 3.17188C7.83333 3.51042 7.44271 3.67969 7 3.67969C6.55729 3.67969 6.16667 3.51042 5.82812 3.17188ZM3.17188 0.828125C3.51042 1.16667 3.67969 1.55729 3.67969 2C3.67969 2.44271 3.51042 2.83333 3.17188 3.17188C2.83333 3.51042 2.44271 3.67969 2 3.67969C1.55729 3.67969 1.16667 3.51042 0.828125 3.17188C0.489583 2.83333 0.320313 2.44271 0.320313 2C0.320313 1.55729 0.489583 1.16667 0.828125 0.828125C1.16667 0.489583 1.55729 0.320312 2 0.320312C2.44271 0.320312 2.83333 0.489583 3.17188 0.828125Z" fill="#B7BBB7"/>
                                </svg>
                            </div>
                            <mat-menu #menuActions="matMenu">
                                <button mat-menu-item (click)="openFiles(job, '/')" *ngIf="company | perm:'see_files'">
                                  <mat-icon>perm_media</mat-icon> 
                                  <span>{{ "Files" | translate }}</span>
                                </button>
                                <button mat-menu-item (click)="openNotes(job)">
                                    <mat-icon>text_snippet</mat-icon>
                                    <span>{{ "Notes" | translate }}</span>
                                </button>
                                <button mat-menu-item (click)="toShortCut($event, job, 'job')">
                                    <div class="d-f-c">
                                      <svg width="18" style="margin-right: 16px;" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9 0L5.778 5.004L0 6.516L3.78 11.142L3.438 17.1L9 14.931L14.562 17.1L14.22 11.142L18 6.516L12.222 5.004L9 0ZM6.642 7.2C7.2 7.2 7.65 7.65 7.65 8.217C7.65 8.48434 7.5438 8.74073 7.35476 8.92976C7.16573 9.1188 6.90934 9.225 6.642 9.225C6.075 9.225 5.625 8.775 5.625 8.217C5.625 7.65 6.075 7.2 6.642 7.2ZM11.367 7.2C11.925 7.2 12.375 7.65 12.375 8.217C12.375 8.48434 12.2688 8.74073 12.0798 8.92976C11.8907 9.1188 11.6343 9.225 11.367 9.225C10.8 9.225 10.35 8.775 10.35 8.217C10.35 7.65 10.8 7.2 11.367 7.2ZM6.3 11.25H11.7C11.25 12.339 10.179 13.05 9 13.05C7.821 13.05 6.75 12.339 6.3 11.25Z" fill="#686868"/>
                                      </svg>
                                      <span>{{ "Shortcut" | translate }}</span>
                                    </div>
                                  </button>
                                <button mat-menu-item *ngIf="company?.permissions.includes('owner') || checkIsManager(task, company, user)" (click)="openAutomation(job)">
                                    <mat-icon>auto_awesome_motion</mat-icon> 
                                    <span>{{ "Scenarios" | translate }}</span>
                                </button>
                                <button mat-menu-item (click)="openChecklist(task, job)">
                                  <mat-icon>checklist</mat-icon> 
                                  <span>{{ "Checklist" | translate }}</span>
                                </button>
                                <button mat-menu-item ngxClipboard [cbContent]="origin + '/job/' + job.acm" (cbOnSuccess)="copyLink('Job')">
                                  <mat-icon>link</mat-icon> 
                                  <span>{{ "Copy job url" | translate }}</span>
                                </button>
                                <button mat-menu-item (click)="migrateTargetFiles(task, job)" *ngIf="!!user?.is_root">
                                    <mat-icon>move_up</mat-icon> 
                                    <span>{{ "Migrate all job files" | translate }}</span>
                                </button>
                                <button mat-menu-item (click)="createChat(task, job)" *ngIf="company && company?.permissions?.includes('create_discussion')">
                                  <mat-icon>add_comment</mat-icon> 
                                  <span>{{ "Add chat" | translate }}</span>
                                </button>
                                <button mat-menu-item (click)="selectJob(job)">
                                  <mat-icon>comment</mat-icon> 
                                  <span>{{ "Open chats" | translate }}</span>
                                </button>
                                <button mat-menu-item (click)="addToBoardJob(job)">
                                    <mat-icon>add</mat-icon> 
                                    <span>{{ "Add to board" | translate }}</span>
                                </button>
                                <button *ngIf="company?.permissions.includes('owner') || company?.permissions.includes('admin') || company?.permissions.includes('manager') || company?.permissions.includes('assign_task_parameter')" mat-menu-item (click)="openTargetValues([task.id, job.id, 0], job)">
                                  <mat-icon>settings_suggest</mat-icon> 
                                  <span>{{ "Tags" | translate }}</span>
                                </button>
                                <ng-container *ngIf="company?.permissions.includes('owner') || checkIsManager(task, company, user); else elseLockTemplateDesk">
                                    <button mat-menu-item (click)="goToClients(job)">
                                        <mat-icon>group</mat-icon> 
                                        <span>{{ "Clients" | translate }}</span>
                                    </button>
                                    <button mat-menu-item (click)="editWork(task, job)">
                                        <mat-icon>edit</mat-icon> 
                                        <span>{{ "Edit" | translate }}</span>
                                    </button>
                                    <button mat-menu-item (click)="executorsAssignments(job)">
                                        <mat-icon>group</mat-icon> 
                                        <span>{{ "Executors Assignments" | translate }}</span>
                                    </button>
                                </ng-container>
                                <ng-template #elseLockTemplateDesk>
                                  <button mat-menu-item *ngIf="job?.status?.is_lock == 0 && !company?.permissions.includes('client')" (click)="editEmloyeeWork(task, job)">
                                    <mat-icon>edit</mat-icon> 
                                    <span>{{ "Edit" | translate }}</span>
                                  </button>
                                </ng-template>
                                
                    
                                
                                <ng-container *ngIf="company?.permissions.includes('owner') || checkIsManager(task, company, user)">
                                    
                                  <button mat-menu-item (click)="moveWork(task, job)">
                                    <mat-icon style="transform: rotate(45deg);">zoom_out_map</mat-icon> 
                                    <span>{{ "Move to card" | translate }}</span>
                                  </button>
                                  <button *ngIf="company?.permissions?.includes('owner') || company?.permissions?.includes('admin')" mat-menu-item (click)="deleteWork(task, job)">
                                    <mat-icon>delete</mat-icon> 
                                    <span>{{ "Delete" | translate }}</span>
                                  </button>
                 
                                </ng-container>
                            </mat-menu>
                        </div>
                        <div class="job_info">
                            <div class="empl_info" (click)="$event.preventDefault(); $event.stopPropagation(); openExecutors(job)">
                                <div class="empl_avatars" [ngClass]="{'has_add': !!job?.uniqueAddEmployee?.length}">
                                    <div class="main_employees" [ngStyle]="{'width': 32 + (job.uniqueEmployees?.length - 1) * 26 + 'px'}">
                                        <ng-container *ngFor="let main of job.uniqueEmployees; let i = index;">
                                            <ng-container *ngIf="!!main?.employee?.image; else elseTemplateHasUserAvatar">
                                                <img [appHoverUserInfo]="{ user: main?.employee, company_id: company?.id }" [src]="main?.employee?.image" alt="" [ngStyle]="{'z-index': job.uniqueEmployees.length - i, 'transform': 'translateX(-' + i*6 + 'px)'}">
                                            </ng-container>
                                            <ng-template #elseTemplateHasUserAvatar>
                                                <ng-container *ngIf="main?.is_partner; else elsePartner">
                                                    <ng-container *ngIf="!!main?.partnerCompany?.avatarFile?.thumbnail && !!host; else elseTemplatePartnerAvatar">
                                                        <img [appHoverUserInfo]="{ user: {partnerCompany: main?.partnerCompany}, company_id: company?.id }" class="partner_avatar" (error)="onPartnerImgError($event)" [src]="host + main?.partnerCompany?.avatarFile?.thumbnail + '?company_id=' + company?.id" [ngStyle]="{'z-index': job.uniqueEmployees.length - i, 'transform': 'translateX(-' + i*6 + 'px)'}" alt="">
                                                    </ng-container>
                                                    <ng-template #elseTemplatePartnerAvatar>
                                                        <img [appHoverUserInfo]="{ user: {partnerCompany: main?.partnerCompany}, company_id: company?.id }" class="partner_avatar" (error)="onPartnerImgError($event)" src="{{ imgRoute }}assets/img/partner.png" [ngStyle]="{'z-index': job.uniqueEmployees.length - i, 'transform': 'translateX(-' + i*6 + 'px)'}" alt="">
                                                    </ng-template>
                                                </ng-container>
                                                <ng-template #elsePartner>
                                                    <mat-icon [appHoverUserInfo]="{ user: main?.employee, company_id: company?.id }" [ngStyle]="{'z-index': job.uniqueEmployees.length - i, 'transform': 'translateX(-' + i*6 + 'px)'}">account_circle</mat-icon>
                                                </ng-template>
                                            </ng-template>
                                        </ng-container>
                                        <img *ngIf="job.uniqueEmployees?.length == 0" class="partner_avatar" src="{{ imgRoute }}assets/img/no-user.png" alt="">
                                    </div>
                                    <!-- (dblclick)="dblEditWork($event, task, job, 'employee', company?.permissions.includes('owner') || checkIsManager(task, company, user), company?.permissions.includes('client'))" -->
                                    <div class="add_employees" *ngIf="!!job?.uniqueAddEmployee?.length" (click)="$event.preventDefault(); $event.stopPropagation();" (dblclick)="company?.permissions.includes('owner') || checkIsManager(task, company, user) ? additionalEmployees(task, job) : null">
                                        <div class="add_emp" *ngFor="let addEmp of job?.uniqueAddEmployee; let j = index" [ngStyle]="{'z-index': job?.uniqueAddEmployee.length - j, 'transform': 'translateX(-' + j*7 + 'px)'}">
                                            <ng-container *ngIf="!!addEmp?.employee?.image; else elseTemplateAddEmpl">
                                                <img [appHoverUserInfo]="{ user: addEmp?.employee, company_id: company?.id }" [src]="addEmp?.employee?.image" alt="">
                                            </ng-container>
                                            <ng-template #elseTemplateAddEmpl>
                                                <ng-container *ngIf="addEmp?.is_partner; else elsePartnerAdvisersMob">
                                                    <ng-container *ngIf="!!addEmp?.partnerCompany?.avatarFile?.thumbnail && !!host; else elseTemplatePartnerAvatar">
                                                        <img [appHoverUserInfo]="{ user: {partnerCompany: addEmp?.partnerCompany}, company_id: company?.id }" class="partner_avatar" (error)="onPartnerImgError($event)" [src]="host + addEmp?.partnerCompany?.avatarFile?.thumbnail + '?company_id=' + company?.id" alt="">
                                                    </ng-container>
                                                    <ng-template #elseTemplatePartnerAvatar>
                                                        <img [appHoverUserInfo]="{ user: {partnerCompany: addEmp?.partnerCompany}, company_id: company?.id }" class="partner_avatar" (error)="onPartnerImgError($event)" src="{{ imgRoute }}assets/img/partner.png">
                                                    </ng-template>
                                                </ng-container>
                                                <ng-template #elsePartnerAdvisersMob>
                                                    <mat-icon [appHoverUserInfo]="{ user: addEmp?.employee, company_id: company?.id }">account_circle</mat-icon>
                                                </ng-template>
                                            </ng-template>
                                        </div>
                                    </div>
                                </div>
    
                                <ng-container *ngIf="job.uniqueEmployees?.length <= 1">
                                    <ng-container *ngIf="job.partnerCompany; else elseTemplatejobPartnerCompany">
                                        <span class="partner"> {{ job.partnerCompany.name }}</span>
                                    </ng-container>
                                    <ng-template #elseTemplatejobPartnerCompany>
                                        <span>{{ job?.employee?.employee?.name }}</span>
                                    </ng-template>
                                    
                                   
                                    
                                </ng-container>
                                
                                <!-- (dblclick)="dblEditWork($event, task, job, 'employee', company?.permissions.includes('owner') || checkIsManager(task, company, user), company?.permissions.includes('client'))" -->
    
    
                                <div class="job_creator" *ngIf="job?.createdEmployee">
                                    <ng-container *ngIf="job?.createdEmployee?.image; else elseTemplatePhoto">
                                        <img [appHoverUserInfo]="{ user: job?.createdEmployee, company_id: company?.id }" [src]="job?.createdEmployee?.image">
                                    </ng-container>
                                    <ng-template #elseTemplatePhoto>
                                        <mat-icon [appHoverUserInfo]="{ user: job?.createdEmployee, company_id: company?.id }">account_circle</mat-icon>
                                    </ng-template>
                                    <span class="user_name">
                                        {{ job?.createdEmployee?.name }}
                                    </span>
                                </div>
                            </div>
                            <div class="job_dates">
                                <div class="job_id">
                                    <span class="ml-1"><ng-container *ngIf="ifJobClient(job)">c</ng-container><ng-container *ngIf="ifJobClientModerator(job)">m</ng-container></span>
                                    {{ job.id }}
                                </div>
                                
                                <div class="completed_at" (click)="$event.preventDefault(); $event.stopPropagation();" (dblclick)="dblEditWork($event, task, job, 'CompletedAt', company?.permissions.includes('owner') || checkIsManager(task, company, user), company?.permissions.includes('client'))">
                                    <ng-container *ngIf="job.completed_at; else elseTemplateComplete">
                                        {{ job.completed_at*1000 | date:'MMM dd. yyyy':'': activeLang | yearPipe }}
                                    </ng-container>
                                    <ng-template #elseTemplateComplete>
                                        &nbsp;
                                    </ng-template>
                                </div>
                                <!-- <div class="update_date">
                                    {{ job.updated_at*1000 | date:'MMM dd. yyyy':'': activeLang | yearPipe }}
                                </div> -->
                                <div class="create_date">
                                    {{ job.created_at*1000 | date:'MMM dd. yyyy':'': activeLang | yearPipe }}
                                </div>
                            </div>
                        </div>
                        <div class="job_price" [ngClass]="{'more_one': job?.emplSummCount > 1}">
                            <ng-container *ngFor="let emplSumm of job?.emplSummary">
                                <div class="val" *ngIf="emplSumm?.summary > 0">
                                    <span class="price_numb">
                                        {{ emplSumm?.summary | int }}
                                    </span>
                                    <span class="dot" *ngIf="+emplSumm?.summary > +(emplSumm?.summary | int)">
                                        {{ ((+emplSumm?.summary - +(emplSumm?.summary | int)) | reportsNumber).toString()|slice:1 }}
                                    </span>
                                    <span class="dot" *ngIf="+emplSumm?.summary == +(emplSumm?.summary | int)">
                                        {{ '.00' }}
                                    </span>
                                    <span class="unit">
                                        {{emplSumm?.iso_name}}
                                    </span>
                                </div>
                            </ng-container>
                        </div>
                        <!--  (click)="$event.preventDefault(); $event.stopPropagation();" (dblclick)="dblEditWork($event, task, job, 'OperationId', company?.permissions.includes('owner') || checkIsManager(task, company, user), company?.permissions.includes('client'))" -->
                        <!-- (click)="$event.preventDefault(); $event.stopPropagation();" (dblclick)="dblEditWork($event, task, job, 'Comment', company?.permissions.includes('owner') || checkIsManager(task, company, user) || job.status.is_lock == 0, company?.permissions.includes('client'))" -->
                        <!-- (click)="$event.preventDefault(); $event.stopPropagation();" (dblclick)="dblEditWork($event, task, job, 'Comment', company?.permissions.includes('owner') || checkIsManager(task, company, user) || job.status.is_lock == 0, company?.permissions.includes('client'))"  -->
                        <!-- (click)="$event.preventDefault(); $event.stopPropagation();" (dblclick)="dblEditWork($event, task, job, 'Comment', company?.permissions.includes('owner') || checkIsManager(task, company, user) || job.status.is_lock == 0, company?.permissions.includes('client'))" -->
                        <div class="job_type">
                            <div class="type_val_wrp">
                                <div class="primary_tag">
                                    <span class="text_one_line">{{ job.operation && job.operation.name ? job.operation.name : getColumnName(job.operation_id) }}</span>
                                    <span *ngIf="getPrimaryTag(job)" class="text_one_line val">{{getPrimaryTag(job)?.parameterValue?.value}}</span>
                                </div>
                       
                                <div class="job_private_comment" *ngIf="job.private_comment">
                                    {{ job.private_comment.length > 20 ? (job.private_comment|slice:0:17) + '...' : job.private_comment }}
                                </div>
                            </div>
                            <div class="job_comment">{{ job?.name?.length > 35 ? (job?.name|slice:0:30) + '...' : job?.name }} <mat-icon *ngIf="job.comment" class="info_comment" matTooltip="{{job.comment}}" [matTooltipPosition]="'above'">info</mat-icon></div>
                            <div class="empl_comment" *ngIf="job.employee_comment">
                                {{ job.employee_comment.length > 35 ? (job.employee_comment|slice:0:32) + '...' : job.employee_comment }}
                            </div>
    
                            <div class="job_indicators">
                                <div class="job_scenarios" [ngClass]="{'not_show_scen': !templates || !templates.length}">
                                    <ng-container *ngIf="job?.automationScenarios?.length > 0; else elseTemplateJobScen">
                                        <div class="job_scen_ico">
                                            <ng-container *ngIf="allDone(job); else elseTemplateAllDone">
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9 5C8.46957 5 7.96086 5.21071 7.58579 5.58579C7.21071 5.96086 7 6.46957 7 7V9C7 9.53043 7.21071 10.0391 7.58579 10.4142C7.96086 10.7893 8.46957 11 9 11H11V13H7V15H11C11.5304 15 12.0391 14.7893 12.4142 14.4142C12.7893 14.0391 13 13.5304 13 13V11C13 10.4696 12.7893 9.96086 12.4142 9.58579C12.0391 9.21071 11.5304 9 11 9H9V7H13V5H9ZM10 0C11.3132 0 12.6136 0.258658 13.8268 0.761205C15.0401 1.26375 16.1425 2.00035 17.0711 2.92893C17.9997 3.85752 18.7362 4.95991 19.2388 6.17317C19.7413 7.38642 20 8.68678 20 10C20 12.6522 18.9464 15.1957 17.0711 17.0711C15.1957 18.9464 12.6522 20 10 20C8.68678 20 7.38642 19.7413 6.17317 19.2388C4.95991 18.7362 3.85752 17.9997 2.92893 17.0711C1.05357 15.1957 0 12.6522 0 10C0 7.34784 1.05357 4.8043 2.92893 2.92893C4.8043 1.05357 7.34784 0 10 0Z" fill="#00FF38"/>
                                                </svg>
                                            </ng-container>
                                            <ng-template #elseTemplateAllDone>
                                                <ng-container *ngIf="someDone(job); else elseTemplateSomeDone">
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M9 5C8.46957 5 7.96086 5.21071 7.58579 5.58579C7.21071 5.96086 7 6.46957 7 7V9C7 9.53043 7.21071 10.0391 7.58579 10.4142C7.96086 10.7893 8.46957 11 9 11H11V13H7V15H11C11.5304 15 12.0391 14.7893 12.4142 14.4142C12.7893 14.0391 13 13.5304 13 13V11C13 10.4696 12.7893 9.96086 12.4142 9.58579C12.0391 9.21071 11.5304 9 11 9H9V7H13V5H9ZM10 0C11.3132 0 12.6136 0.258658 13.8268 0.761205C15.0401 1.26375 16.1425 2.00035 17.0711 2.92893C17.9997 3.85752 18.7362 4.95991 19.2388 6.17317C19.7413 7.38642 20 8.68678 20 10C20 12.6522 18.9464 15.1957 17.0711 17.0711C15.1957 18.9464 12.6522 20 10 20C8.68678 20 7.38642 19.7413 6.17317 19.2388C4.95991 18.7362 3.85752 17.9997 2.92893 17.0711C1.05357 15.1957 0 12.6522 0 10C0 7.34784 1.05357 4.8043 2.92893 2.92893C4.8043 1.05357 7.34784 0 10 0Z" fill="#00FF3880"/>
                                                    </svg>
                                                </ng-container>
                                                <ng-template #elseTemplateSomeDone>
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M9 5C8.46957 5 7.96086 5.21071 7.58579 5.58579C7.21071 5.96086 7 6.46957 7 7V9C7 9.53043 7.21071 10.0391 7.58579 10.4142C7.96086 10.7893 8.46957 11 9 11H11V13H7V15H11C11.5304 15 12.0391 14.7893 12.4142 14.4142C12.7893 14.0391 13 13.5304 13 13V11C13 10.4696 12.7893 9.96086 12.4142 9.58579C12.0391 9.21071 11.5304 9 11 9H9V7H13V5H9ZM10 0C11.3132 0 12.6136 0.258658 13.8268 0.761205C15.0401 1.26375 16.1425 2.00035 17.0711 2.92893C17.9997 3.85752 18.7362 4.95991 19.2388 6.17317C19.7413 7.38642 20 8.68678 20 10C20 12.6522 18.9464 15.1957 17.0711 17.0711C15.1957 18.9464 12.6522 20 10 20C8.68678 20 7.38642 19.7413 6.17317 19.2388C4.95991 18.7362 3.85752 17.9997 2.92893 17.0711C1.05357 15.1957 0 12.6522 0 10C0 7.34784 1.05357 4.8043 2.92893 2.92893C4.8043 1.05357 7.34784 0 10 0Z" fill="#B5F2CE"/>
                                                    </svg>
                                                </ng-template>
                                            </ng-template>                                                  
                                        </div>
                                    </ng-container>
                                    <ng-template #elseTemplateJobScen>
                                        <div class="job_scen_ico no_job_scen_ico">
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M9 5H13V7H9V9H11C11.5304 9 12.0391 9.21071 12.4142 9.58579C12.7893 9.96086 13 10.4696 13 11V13C13 13.5304 12.7893 14.0391 12.4142 14.4142C12.0391 14.7893 11.5304 15 11 15H7V13H11V11H9C8.46957 11 7.96086 10.7893 7.58579 10.4142C7.21071 10.0391 7 9.53043 7 9V7C7 6.46957 7.21071 5.96086 7.58579 5.58579C7.96086 5.21071 8.46957 5 9 5ZM10 0C11.3132 0 12.6136 0.258658 13.8268 0.761205C15.0401 1.26375 16.1425 2.00035 17.0711 2.92893C17.9997 3.85752 18.7362 4.95991 19.2388 6.17317C19.7413 7.38642 20 8.68678 20 10C20 12.6522 18.9464 15.1957 17.0711 17.0711C15.1957 18.9464 12.6522 20 10 20C8.68678 20 7.38642 19.7413 6.17317 19.2388C4.95991 18.7362 3.85752 17.9997 2.92893 17.0711C1.05357 15.1957 0 12.6522 0 10C0 7.34784 1.05357 4.8043 2.92893 2.92893C4.8043 1.05357 7.34784 0 10 0ZM10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2Z" fill="#C9C9C9"/>
                                            </svg>
                                        </div>
                                    </ng-template>
                                                                                    
                                    <div class="job_scenarios_hover" [ngClass]="{'visible': !!job?.scen_visible}" (click)="$event.stopPropagation()">
                                        <div class="job_scenarios_hover_in">
                                            <div class="users_select with_tabs_scen">
                                                <ng-container *ngIf="job.groupScenarios && job.groupScenarios.length; else elseTemplate_groupScenarios">
                                                    <div class="tabs_scen">
                                                        <div [ngClass]="{'active': !!job.scenarioTab}" (click)="job.scenarioTab = true">Group</div>
                                                        <div [ngClass]="{'active': !job.scenarioTab}" (click)="job.scenarioTab = false">All</div>
                                                    </div>
                                                    <ng-container [ngSwitch]="job.scenarioTab">
                                                        <ng-container *ngSwitchCase="true">
                                                            <mat-chip-list class="scen_list" [multiple]="true" selectable>
                                                                <label>{{ "Choose a scenario" | translate }}: </label>
                                                                <ng-container *ngFor="let template of job.groupScenarios; let i = index">
                                                                    <mat-chip [disabled]="disableTemplate" style="padding-left: 5px !important; display: flex !important;" class="chip_user text_one_line" (click)="toggleChipTemplate(template, job)" [value]="template.id" [selected]="scenIsSelected(job, template)">
                                                                        <mat-icon class="active" *ngIf="activeScan(job, template)">done</mat-icon>
                                                                        {{ template.name }}
                                                                        <mat-icon class="try_scen" *ngIf="scenIsSelected(job, template)" (click)="tryScen($event, job, template)">replay</mat-icon>
                                                                    </mat-chip>
                                                                    <ng-container *ngIf="scenIsSelected(job, template) && duplicatesScenCount(job, template).length > 0">
                                                                        <ng-container *ngFor="let u of [].constructor(duplicatesScenCount(job, template).length); let q = index;">
                                                                            <mat-chip *ngIf="q != 0" [disabled]="disableTemplate" style="padding-left: 5px !important;" class="chip_user text_one_line dubl_scen" (click)="toggleDuplicateScen(template, job, q)" [value]="template.id" [selected]="true">
                                                                                <mat-icon class="active" *ngIf="activeDuplicateScan(job, template, q)">done</mat-icon>
                                                                                {{ template.name }}
                                                                                <mat-icon class="try_scen" *ngIf="scenIsSelected(job, template)" (click)="tryDuplicateScen($event, job, template, q)">replay</mat-icon>
                                                                            </mat-chip>
                                                                        </ng-container>
                                                                    </ng-container>
                                                                </ng-container>
                                                            </mat-chip-list>
                                                        </ng-container>
                                                        <ng-container *ngSwitchCase="false">
                                                            <mat-chip-list class="scen_list" [multiple]="true" selectable>
                                                                <label>{{ "Choose a scenario" | translate }}: </label>
                                                                <ng-container *ngFor="let template of templates; let i = index">
                                                                    <mat-chip [disabled]="disableTemplate" style="padding-left: 5px !important;" class="chip_user text_one_line" (click)="toggleChipTemplate(template, job)" [value]="template.id" [selected]="scenIsSelected(job, template)">
                                                                        <mat-icon class="active" *ngIf="activeScan(job, template)">done</mat-icon>
                                                                        {{ template.name }}
                                                                        <mat-icon class="try_scen" *ngIf="scenIsSelected(job, template)" (click)="tryScen($event, job, template)">replay</mat-icon>
                                                                    </mat-chip>
                                                                    <ng-container *ngIf="scenIsSelected(job, template) && duplicatesScenCount(job, template).length > 0">
                                                                        <ng-container *ngFor="let u of [].constructor(duplicatesScenCount(job, template).length); let q = index;">
                                                                            <mat-chip *ngIf="q != 0" [disabled]="disableTemplate" style="padding-left: 5px !important;" class="chip_user text_one_line dubl_scen" (click)="toggleDuplicateScen(template, job, q)" [value]="template.id" [selected]="true">
                                                                                <mat-icon class="active" *ngIf="activeDuplicateScan(job, template, q)">done</mat-icon>
                                                                                {{ template.name }}
                                                                                <mat-icon class="try_scen" *ngIf="scenIsSelected(job, template)" (click)="tryDuplicateScen($event, job, template, q)">replay</mat-icon>
                                                                            </mat-chip>
                                                                        </ng-container>
                                                                    </ng-container>
                                                                    <mat-chip *ngIf="i == templates?.length - 1 && i > 40" class="small_chip more" (click)="jobScenContainer.open(); job.scen_visible = true;">
                                                                        {{ "More..." | translate }}
                                                                    </mat-chip>
                                                                </ng-container>
                                                            </mat-chip-list>
                                                            <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                                                                <mat-select [multiple]="true" #jobScenContainer [(ngModel)]="job.selectedScenIds" (openedChange)="scenChangeOpen($event, job)">
                                                                    <mat-option>
                                                                        <ngx-mat-select-search 
                                                                        placeholderLabel="{{ 'Search' | translate }}" 
                                                                        noEntriesFoundLabel="{{ 'no matching templates found' | translate }}"
                                                                        [formControl]="templatesControl"></ngx-mat-select-search>
                                                                    </mat-option>
                                                                    <mat-option *ngFor="let template of templates$ | async" [value]="template.id" (click)="toggleChipTemplate(template, job)">
                                                                        {{ template.name }}
                                                                    </mat-option>
                                                                </mat-select>
                                                            </mat-form-field>
                                                        </ng-container>
                                                    </ng-container>
                                                </ng-container>
                                                <ng-template #elseTemplate_groupScenarios>
                                                    <mat-chip-list class="scen_list" [multiple]="true" selectable>
                                                        <label>{{ "Choose a scenario" | translate }}: </label>
                                                        <ng-container *ngFor="let template of templates; let i = index">
                                                            <mat-chip [disabled]="disableTemplate" style="padding-left: 5px !important;" class="chip_user text_one_line" (click)="toggleChipTemplate(template, job)" [value]="template.id" [selected]="scenIsSelected(job, template)">
                                                                <mat-icon class="active" *ngIf="activeScan(job, template)">done</mat-icon>
                                                                {{ template.name }}
                                                                <mat-icon class="try_scen" *ngIf="scenIsSelected(job, template)" (click)="tryScen($event, job, template)">replay</mat-icon>
                                                            </mat-chip>
                                                            <ng-container *ngIf="scenIsSelected(job, template) && duplicatesScenCount(job, template).length > 0">
                                                                <ng-container *ngFor="let u of [].constructor(duplicatesScenCount(job, template).length); let q = index;">
                                                                    <mat-chip *ngIf="q != 0" [disabled]="disableTemplate" style="padding-left: 5px !important;" class="chip_user text_one_line dubl_scen" (click)="toggleDuplicateScen(template, job, q)" [value]="template.id" [selected]="true">
                                                                        <mat-icon class="active" *ngIf="activeDuplicateScan(job, template, q)">done</mat-icon>
                                                                        {{ template.name }}
                                                                        <mat-icon class="try_scen" *ngIf="scenIsSelected(job, template)" (click)="tryDuplicateScen($event, job, template, q)">replay</mat-icon>
                                                                    </mat-chip>
                                                                </ng-container>
                                                            </ng-container>
                                                            <mat-chip *ngIf="i == templates?.length - 1 && i > 40" class="small_chip more" (click)="jobScenContainer.open(); job.scen_visible = true;">
                                                                {{ "More..." | translate }}
                                                            </mat-chip>
                                                        </ng-container>
                                                    </mat-chip-list>
                                                    <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                                                        <mat-select [multiple]="true" #jobScenContainer [(ngModel)]="job.selectedScenIds" (openedChange)="scenChangeOpen($event, job)">
                                                            <mat-option>
                                                                <ngx-mat-select-search 
                                                                placeholderLabel="{{ 'Search' | translate }}" 
                                                                noEntriesFoundLabel="{{ 'no matching templates found' | translate }}"
                                                                [formControl]="templatesControl"></ngx-mat-select-search>
                                                            </mat-option>
                                                            <mat-option *ngFor="let template of templates$ | async" [value]="template.id" (click)="toggleChipTemplate(template, job)">
                                                                {{ template.name }}
                                                            </mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </ng-template>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="notes_count card_notes_mob" style="margin-right: 10px;" (click)="$event.preventDefault(); $event.stopPropagation(); openNotes(job)" *ngIf="job?.documents_count && job?.documents_count > 0">
                                    <mat-icon>text_snippet</mat-icon>
                                    <span>{{ job?.documents_count }}</span>
                                </div>
                                <div class="job_chat" (click)="$event.preventDefault(); $event.stopPropagation(); selectJob(job)" *ngIf="job?.chatsInfo?.length; else elseTemplateCHAT">
                                    <span>{{ job?.chatsInfo?.length }}</span>
                                    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M23 0.5H3C1.625 0.5 0.5 1.625 0.5 3V25.5L5.5 20.5H23C24.375 20.5 25.5 19.375 25.5 18V3C25.5 1.625 24.375 0.5 23 0.5Z" fill="#0F3FEB"/>
                                    </svg>
                                    <div class="red_point" *ngIf="job?.unReadChats"></div>
                                </div>
                                <ng-template #elseTemplateCHAT>
                                    <div class="job_chat add_chat" (click)="$event.preventDefault(); $event.stopPropagation(); createChat(task, job)" *ngIf="company && company?.permissions?.includes('create_discussion')">
                                        <span>+</span>
                                        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M23 0.5H3C1.625 0.5 0.5 1.625 0.5 3V25.5L5.5 20.5H23C24.375 20.5 25.5 19.375 25.5 18V3C25.5 1.625 24.375 0.5 23 0.5Z" fill="#0F3FEB"/>
                                        </svg>
                                    </div>
                                </ng-template>
                            </div>
                            
                        </div>
                        
    
                        <ng-container *ngIf="isManager || isMainEmpl(job) || ifJobClientModerator(job); else elseTemplateMANAGER">
                            <!-- STATUSES FOR MANAGER -->
                            <div class="job_status" [ngClass]="{'show_borders': job?.is_approved_files || job.is_to_approve_files || +job?.filesCount, 'full': job.open}" >
                                <!-- (dblclick)="dblEditWork($event, task, job, company?.permissions.includes('owner') || checkIsManager(task, company, user) ? 'Status' : 'StatusEmpl', company?.permissions.includes('owner') || checkIsManager(task, company, user) || job.status.is_lock == 0, company?.permissions.includes('client'))" -->
                                <div class="out_job">
                                    <ng-container *ngIf="!job.newStatusFor; else elseTemplateNewStatus">
                                        <div class="inside_job" (click)="$event.preventDefault(); $event.stopPropagation(); openStatus(job)" [ngClass]="{
                                            'first': job?.status_id == '1',
                                            'second': job?.status_id == '2',
                                            'third': job?.status_id == '3',
                                            'fours': job?.status_id == '4',
                                            'fives': job?.status_id == '5',
                                            'sixs': job?.status_id == '6',
                                            'nine': job?.status_id == '97',
                                            'seven': job?.status_id == '98',
                                            'eight': job?.status_id == '99'}">
                                            <span>{{ getOperationByID(job?.status_id)?.name?.length > 15 ? ((getOperationByID(job?.status_id)?.name | translate)|slice:0:12) + "..." : (getOperationByID(job?.status_id)?.name | translate) }}</span>  
                                        </div>
                                        <div class="inside_job more" (click)="$event.preventDefault(); $event.stopPropagation(); chooseStatus(job, stat)" *ngFor="let stat of job.statuses" [ngClass]="{
                                            'first': stat.id == '1',
                                            'second': stat.id == '2',
                                            'third': stat.id == '3',
                                            'fours': stat.id == '4',
                                            'fives': stat.id == '5',
                                            'sixs': stat.id == '6',
                                            'nine': stat.id == '97',
                                            'seven': stat.id == '98',
                                            'eight': stat.id == '99'}">
                                            <span>{{ (stat.name | translate).length > 15 ? (((stat.name | translate) | slice:0:12) + '...') : stat.name | translate}}</span>  
                                        </div>
                                    </ng-container>
                                    <ng-template #elseTemplateNewStatus>
                                        <div class="inside_job" [ngClass]="{
                                            'first': job?.newStatusFor?.id == '1',
                                            'second': job?.newStatusFor?.id == '2',
                                            'third': job?.newStatusFor?.id == '3',
                                            'fours': job?.newStatusFor?.id == '4',
                                            'fives': job?.newStatusFor?.id == '5',
                                            'sixs': job?.newStatusFor?.id == '6',
                                            'nine': job?.newStatusFor?.id == '97',
                                            'seven': job?.newStatusFor?.id == '98',
                                            'eight': job?.newStatusFor?.id == '99'}">
                                            <span>{{ (job?.newStatusFor?.name | translate).length > 18 ? ((job?.newStatusFor?.name | translate)|slice:0:15) + "..." : (job?.newStatusFor?.name | translate) }}</span>  
                                        </div>
                                        <div class="ml-1 mr-1">
                                            {{ 'applies to' | translate }}
                                        </div>
                                        <div class="status_for">
                                            <div class="empl_stat" [ngClass]="{'initial_selected': empl.initial_selected,'selected': empl.selected}" *ngFor="let empl of job.empl_status" (click)="selectEmpl(job, empl)">
                                                <ng-container *ngIf="!empl?.is_partner; else elseTemplateIsPartnerStatus">                                                            
                                                    <ng-container *ngIf="empl?.employee?.image; else elseTemplateStat">
                                                        <img [appHoverUserInfo]="{ user: empl?.employee, company_id: company?.id }" [src]="empl?.employee?.image">
                                                    </ng-container>
                                                    <ng-template #elseTemplateStat>
                                                        <mat-icon [appHoverUserInfo]="{ user: empl?.employee, company_id: company?.id }">account_circle</mat-icon>
                                                    </ng-template>
                                                    <span>{{ empl?.employee?.name }}</span>
                                                </ng-container>
                                                <ng-template #elseTemplateIsPartnerStatus>
                                                    <ng-container *ngIf="!!empl?.partnerCompany?.avatarFile?.thumbnail && !!host; else elseTemplatePartnerAvatar">
                                                        <img [appHoverUserInfo]="{ user: {partnerCompany: empl?.partnerCompany}, company_id: company?.id }" class="partner_avatar" (error)="onPartnerImgError($event)" [src]="host + empl?.partnerCompany?.avatarFile?.thumbnail + '?company_id=' + company?.id" alt="">
                                                    </ng-container>
                                                    <ng-template #elseTemplatePartnerAvatar>
                                                        <img [appHoverUserInfo]="{ user: {partnerCompany: empl?.partnerCompany}, company_id: company?.id }" class="partner_avatar" (error)="onPartnerImgError($event)" src="{{ imgRoute }}assets/img/partner.png">
                                                    </ng-template>
                                                    <span>{{empl?.partnerCompany?.name}}</span>
                                                </ng-template>
                                            </div>
                                        </div>
                                        <div class="apply_empls_stat" *ngIf="job.mainEmployees.length == 0" (click)="applyJobStats(job)">
                                            {{ "Apply to job" | translate }}
                                        </div>
                                        <div class="apply_empls_stat" (click)="$event.preventDefault(); $event.stopPropagation(); applyStats(job)">
                                           {{ "Apply" | translate }}
                                        </div>
                                        <div class="close_stats_for" (click)="$event.preventDefault(); $event.stopPropagation(); openStatus(job)">
                                            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M9.08332 1.73916L8.26082 0.916656L4.99999 4.17749L1.73916 0.916656L0.916656 1.73916L4.17749 4.99999L0.916656 8.26082L1.73916 9.08332L4.99999 5.82249L8.26082 9.08332L9.08332 8.26082L5.82249 4.99999L9.08332 1.73916Z" fill="#EDEDED"/>
                                            </svg> 
                                        </div>
                                    </ng-template>
                                </div>
                                <a *ngIf="!job.open && job.content_url" (click)="$event.preventDefault(); $event.stopPropagation(); goToLink(job.content_url)" target="_blank" [href]="job.content_url"><mat-icon style="font-size: 20px; width: 20px; height: 20px;">open_in_new</mat-icon></a>
                            </div>
                        </ng-container>
                        <ng-template #elseTemplateMANAGER>
                            <!-- STATUSES FOR EMPL -->
                            <div class="job_status" [ngClass]="{'show_borders': job?.is_approved_files || job.is_to_approve_files || +job?.filesCount, 'full': job.open}" >
                                <!-- (dblclick)="dblEditWork($event, task, job, company?.permissions.includes('owner') || checkIsManager(task, company, user) ? 'Status' : 'StatusEmpl', company?.permissions.includes('owner') || checkIsManager(task, company, user) || job.status.is_lock == 0, company?.permissions.includes('client'))" -->
                                <div class="out_job">
                                    <ng-container *ngIf="!!job?.employeeStatus?.status; else elseTemplateHasPersonal">
                                        <div class="inside_job" (click)="$event.preventDefault(); $event.stopPropagation(); openEmplStatus(job)" [ngClass]="{
                                            'first': job?.employeeStatus?.status_id == '1',
                                            'second': job?.employeeStatus?.status_id == '2',
                                            'third': job?.employeeStatus?.status_id == '3',
                                            'fours': job?.employeeStatus?.status_id == '4',
                                            'fives': job?.employeeStatus?.status_id == '5',
                                            'sixs': job?.employeeStatus?.status_id == '6',
                                            'nine': job?.employeeStatus?.status_id == '97',
                                            'seven': job?.employeeStatus?.status_id == '98',
                                            'eight': job?.employeeStatus?.status_id == '99'}">
                                            <span>{{ (job?.employeeStatus?.status?.name | translate).length > 15 ? ((job?.employeeStatus?.status?.name | translate) | slice:0:12) + "..." : (job?.employeeStatus?.status?.name | translate) }}</span>  
                                        </div>
                                    </ng-container>
                                    <ng-template #elseTemplateHasPersonal>
                                        <div class="inside_job" (click)="$event.preventDefault(); $event.stopPropagation(); openEmplStatus(job)" [ngClass]="{
                                            'first': job?.status_id == '1',
                                            'second': job?.status_id == '2',
                                            'third': job?.status_id == '3',
                                            'fours': job?.status_id == '4',
                                            'fives': job?.status_id == '5',
                                            'sixs': job?.status_id == '6',
                                            'nine': job?.status_id == '97',
                                            'seven': job?.status_id == '98',
                                            'eight': job?.status_id == '99'}">
                                            <span>{{ getOperationByID(job?.status_id)?.name?.length > 15 ? ((getOperationByID(job?.status_id)?.name | translate)|slice:0:12) + "..." : (getOperationByID(job?.status_id)?.name | translate) }}</span>  
                                        </div>
                                    </ng-template>
                                    
       
                                    <div class="inside_job more" (click)="$event.preventDefault(); $event.stopPropagation(); chooseEmplStatus(job, stat)" *ngFor="let stat of job.statuses" [ngClass]="{
                                        'first': stat.id == '1',
                                        'second': stat.id == '2',
                                        'third': stat.id == '3',
                                        'fours': stat.id == '4',
                                        'fives': stat.id == '5',
                                        'sixs': stat.id == '6',
                                        'nine': stat.id == '97',
                                        'seven': stat.id == '98',
                                        'eight': stat.id == '99'}">
                                        <span>{{ (stat.name | translate).length > 15 ? ((stat.name | translate) | slice:0:12) + "..." : (stat.name | translate) }}</span>  
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                        
    
    
    
    
                        <!-- <div class="job_files_link" [ngClass]="{'show_borders': +job?.filesCount}">
                            <div class="block" (click)="showFilesInterface(task, job)">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.75 5.00001V14.5833C13.75 16.425 12.2583 17.9167 10.4167 17.9167C8.575 17.9167 7.08333 16.425 7.08333 14.5833V4.16668C7.08333 3.01668 8.01666 2.08334 9.16666 2.08334C10.3167 2.08334 11.25 3.01668 11.25 4.16668V12.9167C11.25 13.375 10.875 13.75 10.4167 13.75C9.95833 13.75 9.58333 13.375 9.58333 12.9167V5.00001H8.33333V12.9167C8.33333 14.0667 9.26666 15 10.4167 15C11.5667 15 12.5 14.0667 12.5 12.9167V4.16668C12.5 2.32501 11.0083 0.833344 9.16666 0.833344C7.32499 0.833344 5.83333 2.32501 5.83333 4.16668V14.5833C5.83333 17.1167 7.88333 19.1667 10.4167 19.1667C12.95 19.1667 15 17.1167 15 14.5833V5.00001H13.75Z" fill="#2F80ED"/>
                                </svg>
                            </div>
                        </div> -->
                        <div class="job_files" [ngClass]="{'small_width': job.open}" *ngIf="!job.open && (company | perm:'see_files')">
                            <!-- <div class="sources" [ngClass]="{'has_files': true}">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3 17V7C3 5.89543 3.89543 5 5 5H9.58579C9.851 5 10.1054 5.10536 10.2929 5.29289L12 7H19C20.1046 7 21 7.89543 21 9V17C21 18.1046 20.1046 19 19 19H5C3.89543 19 3 18.1046 3 17Z" stroke="#2F80ED" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
    
                            </div> -->
                            <div class="folders">
                                <div class="file_folder sources" (click)="$event.preventDefault(); $event.stopPropagation();" *ngIf="job.is_sources_files">                                            
                                    <svg (click)="openFiles(job, '/sources')" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3 17V7C3 5.89543 3.89543 5 5 5H9.58579C9.851 5 10.1054 5.10536 10.2929 5.29289L12 7H19C20.1046 7 21 7.89543 21 9V17C21 18.1046 20.1046 19 19 19H5C3.89543 19 3 18.1046 3 17Z" stroke="#2F80ED" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </div>
                                <!-- <div class="file_folder trash" *ngIf="job.is_trash_files">                
                                    <svg (click)="showFilesInterface(task, job, '/trash')" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11 19H5C3.89543 19 3 18.1046 3 17V7C3 5.89543 3.89543 5 5 5H9.58579C9.851 5 10.1054 5.10536 10.2929 5.29289L12 7H19C20.1046 7 21 7.89543 21 9V10" stroke="#686868" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M15.0251 14.0251C14.3918 14.6585 14 15.5335 14 16.5C14 18.433 15.567 20 17.5 20C18.4665 20 19.3415 19.6082 19.9749 18.9749M15.0251 14.0251C15.6585 13.3918 16.5335 13 17.5 13C19.433 13 21 14.567 21 16.5C21 17.4665 20.6082 18.3415 19.9749 18.9749M15.0251 14.0251L17.5 16.5L19.9749 18.9749" stroke="#686868" stroke-width="2" stroke-linecap="round"/>
                                    </svg>
                                </div> -->
                                <div class="file_folder to_approve" (click)="$event.preventDefault(); $event.stopPropagation();" *ngIf="job.is_to_approve_files" [ngClass]="{'has_files': job?.status_id == 3 && +job?.filesCount}">
                                    <ng-container *ngIf="job?.status_id == 3">
                                        <ng-container *ngIf="job?.status_id != 3; else elseTemplateFILESBLOCK"></ng-container>
                                    </ng-container>
                                    
                                    <svg (click)="openFiles(job, '/to_approve')" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M13 19H5C3.89543 19 3 18.1046 3 17V7C3 5.89543 3.89543 5 5 5H9.58579C9.851 5 10.1054 5.10536 10.2929 5.29289L12 7H19C20.1046 7 21 7.89543 21 9V11" stroke="#F56718" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M18 14V17" stroke="#F56718" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <circle cx="18" cy="20" r="1" fill="#F56718"/>
                                    </svg>
                                </div>
    
                                <div class="file_folder ready" (click)="$event.preventDefault(); $event.stopPropagation();" *ngIf="job.is_approved_files" [ngClass]="{'has_files': job?.status_id == 98 && +job?.filesCount}">
                                    <ng-container *ngIf="job?.status_id == 98">
                                        <ng-container *ngIf="job?.status_id != 98; else elseTemplateFILESBLOCK"></ng-container>
                                    </ng-container>
                                    <svg (click)="openFiles(job, '/ready_files')" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11 19H5C3.89543 19 3 18.1046 3 17V7C3 5.89543 3.89543 5 5 5H9.58579C9.851 5 10.1054 5.10536 10.2929 5.29289L12 7H19C20.1046 7 21 7.89543 21 9V11" stroke="#219653" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M14 18L16 20L21 15" stroke="#219653" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </div>
    
                                <div class="file_folder project" (click)="$event.preventDefault(); $event.stopPropagation();" *ngIf="job.is_project_files">                                            
                                    <svg (click)="openFiles(job, '/project')" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 19H5C3.89543 19 3 18.1046 3 17V7C3 5.89543 3.89543 5 5 5H9.58579C9.851 5 10.1054 5.10536 10.2929 5.29289L12 7H19C20.1046 7 21 7.89543 21 9V11" stroke="#9B51E0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M18.5 14V20M18.5 20L16 17.5M18.5 20L21 17.5" stroke="#9B51E0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </div>
    
                                <ng-template #elseTemplateFILESBLOCK>
                                    <ng-container *ngIf="+job?.filesCount">
                                        <div class="files_outside" [ngClass]="{'has_plus': +job?.filesCount > job.files.slice(0,3).length || job.files.length > +job?.filesCount && job.files.length > 3}">
                                            <ng-container *ngFor="let file of job.files; let k = index;">
                                                <div class="file" *ngIf="k < 3">
                                                    <div class="file_inside" [ngClass]="{'no_name': understandFileType(file.content_type) == 'image' || understandFileType(file.content_type) == 'video'}">
                                                        <ng-container [ngSwitch]="understandFileType(file.content_type)">
                                                            <ng-container *ngSwitchCase="'video'">
                                                                <ng-container *ngIf="file.thumbnail; else elseTemplateThumbnail">
                                                                    <div class="movie_thumb" (click)="$event.preventDefault(); $event.stopPropagation(); previewVideo(file, job)">
                                                                        <img style="max-width: 100%; max-height: 100%;" (error)="onImgError($event)"  [src]="host + file.thumbnail + '?company_id=' + company?.id">
                                                                        <mat-icon>play_circle</mat-icon>
                                                                    </div>
                                                                </ng-container>
                                                                <ng-template #elseTemplateThumbnail>
                                                                    <mat-icon class="file_img_icon" (click)="$event.preventDefault(); $event.stopPropagation(); previewVideo(file, job)" style="color: #3f51b5">
                                                                        movie
                                                                    </mat-icon>
                                                                </ng-template>
                                                            </ng-container>
                                                            <ng-container *ngSwitchCase="'pdf'">
                                                                <div class="pdf">
                                                                    <svg height="800px" width="800px" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
                                                                        viewBox="0 0 303.188 303.188" xml:space="preserve">
                                                                        <g>
                                                                            <polygon style="fill:#E8E8E8;" points="219.821,0 32.842,0 32.842,303.188 270.346,303.188 270.346,50.525 	"/>
                                                                            <path style="fill:#FB3449;" d="M230.013,149.935c-3.643-6.493-16.231-8.533-22.006-9.451c-4.552-0.724-9.199-0.94-13.803-0.936
                                                                                c-3.615-0.024-7.177,0.154-10.693,0.354c-1.296,0.087-2.579,0.199-3.861,0.31c-1.314-1.36-2.584-2.765-3.813-4.202
                                                                                c-7.82-9.257-14.134-19.755-19.279-30.664c1.366-5.271,2.459-10.772,3.119-16.485c1.205-10.427,1.619-22.31-2.288-32.251
                                                                                c-1.349-3.431-4.946-7.608-9.096-5.528c-4.771,2.392-6.113,9.169-6.502,13.973c-0.313,3.883-0.094,7.776,0.558,11.594
                                                                                c0.664,3.844,1.733,7.494,2.897,11.139c1.086,3.342,2.283,6.658,3.588,9.943c-0.828,2.586-1.707,5.127-2.63,7.603
                                                                                c-2.152,5.643-4.479,11.004-6.717,16.161c-1.18,2.557-2.335,5.06-3.465,7.507c-3.576,7.855-7.458,15.566-11.815,23.02
                                                                                c-10.163,3.585-19.283,7.741-26.857,12.625c-4.063,2.625-7.652,5.476-10.641,8.603c-2.822,2.952-5.69,6.783-5.941,11.024
                                                                                c-0.141,2.394,0.807,4.717,2.768,6.137c2.697,2.015,6.271,1.881,9.4,1.225c10.25-2.15,18.121-10.961,24.824-18.387
                                                                                c4.617-5.115,9.872-11.61,15.369-19.465c0.012-0.018,0.024-0.036,0.037-0.054c9.428-2.923,19.689-5.391,30.579-7.205
                                                                                c4.975-0.825,10.082-1.5,15.291-1.974c3.663,3.431,7.621,6.555,11.939,9.164c3.363,2.069,6.94,3.816,10.684,5.119
                                                                                c3.786,1.237,7.595,2.247,11.528,2.886c1.986,0.284,4.017,0.413,6.092,0.335c4.631-0.175,11.278-1.951,11.714-7.57
                                                                                C231.127,152.765,230.756,151.257,230.013,149.935z M119.144,160.245c-2.169,3.36-4.261,6.382-6.232,9.041
                                                                                c-4.827,6.568-10.34,14.369-18.322,17.286c-1.516,0.554-3.512,1.126-5.616,1.002c-1.874-0.11-3.722-0.937-3.637-3.065
                                                                                c0.042-1.114,0.587-2.535,1.423-3.931c0.915-1.531,2.048-2.935,3.275-4.226c2.629-2.762,5.953-5.439,9.777-7.918
                                                                                c5.865-3.805,12.867-7.23,20.672-10.286C120.035,158.858,119.587,159.564,119.144,160.245z M146.366,75.985
                                                                                c-0.602-3.514-0.693-7.077-0.323-10.503c0.184-1.713,0.533-3.385,1.038-4.952c0.428-1.33,1.352-4.576,2.826-4.993
                                                                                c2.43-0.688,3.177,4.529,3.452,6.005c1.566,8.396,0.186,17.733-1.693,25.969c-0.299,1.31-0.632,2.599-0.973,3.883
                                                                                c-0.582-1.601-1.137-3.207-1.648-4.821C147.945,83.048,146.939,79.482,146.366,75.985z M163.049,142.265
                                                                                c-9.13,1.48-17.815,3.419-25.979,5.708c0.983-0.275,5.475-8.788,6.477-10.555c4.721-8.315,8.583-17.042,11.358-26.197
                                                                                c4.9,9.691,10.847,18.962,18.153,27.214c0.673,0.749,1.357,1.489,2.053,2.22C171.017,141.096,166.988,141.633,163.049,142.265z
                                                                                M224.793,153.959c-0.334,1.805-4.189,2.837-5.988,3.121c-5.316,0.836-10.94,0.167-16.028-1.542
                                                                                c-3.491-1.172-6.858-2.768-10.057-4.688c-3.18-1.921-6.155-4.181-8.936-6.673c3.429-0.206,6.9-0.341,10.388-0.275
                                                                                c3.488,0.035,7.003,0.211,10.475,0.664c6.511,0.726,13.807,2.961,18.932,7.186C224.588,152.585,224.91,153.321,224.793,153.959z"/>
                                                                            <polygon style="fill:#FB3449;" points="227.64,25.263 32.842,25.263 32.842,0 219.821,0 	"/>
                                                                            <g>
                                                                                <path style="fill:#A4A9AD;" d="M126.841,241.152c0,5.361-1.58,9.501-4.742,12.421c-3.162,2.921-7.652,4.381-13.472,4.381h-3.643
                                                                                    v15.917H92.022v-47.979h16.606c6.06,0,10.611,1.324,13.652,3.971C125.321,232.51,126.841,236.273,126.841,241.152z
                                                                                    M104.985,247.387h2.363c1.947,0,3.495-0.546,4.644-1.641c1.149-1.094,1.723-2.604,1.723-4.529c0-3.238-1.794-4.857-5.382-4.857
                                                                                    h-3.348C104.985,236.36,104.985,247.387,104.985,247.387z"/>
                                                                                <path style="fill:#A4A9AD;" d="M175.215,248.864c0,8.007-2.205,14.177-6.613,18.509s-10.606,6.498-18.591,6.498h-15.523v-47.979
                                                                                    h16.606c7.701,0,13.646,1.969,17.836,5.907C173.119,235.737,175.215,241.426,175.215,248.864z M161.76,249.324
                                                                                    c0-4.398-0.87-7.657-2.609-9.78c-1.739-2.122-4.381-3.183-7.926-3.183h-3.773v26.877h2.888c3.939,0,6.826-1.143,8.664-3.43
                                                                                    C160.841,257.523,161.76,254.028,161.76,249.324z"/>
                                                                                <path style="fill:#A4A9AD;" d="M196.579,273.871h-12.766v-47.979h28.355v10.403h-15.589v9.156h14.374v10.403h-14.374
                                                                                    L196.579,273.871L196.579,273.871z"/>
                                                                            </g>
                                                                            <polygon style="fill:#D1D3D3;" points="219.821,50.525 270.346,50.525 219.821,0 	"/>
                                                                        </g>
                                                                    </svg>
                                                                </div>
                                                            </ng-container>
                                                            <ng-container *ngSwitchCase="'image'">
                                                                <img (error)="onImgError($event)"  style="max-width: 100%; max-height: 100%;" [src]="host + (file?.thumbnail ? file?.thumbnail + '?company_id=' + company?.id : file.original + '?company_id=' + company?.id)" (click)="previewVideo(file, job)">
                                                            </ng-container>
                                                            <ng-container *ngSwitchDefault>
                                                                <ng-container *ngIf="!!file?.thumbnail; else elseTemplateHasThumb">
                                                                    <img (click)="$event.preventDefault(); $event.stopPropagation(); previewVideo(file, job)" style="max-width: 100%; max-height: 100%;" (error)="onImgError($event)" [src]="host + file?.thumbnail + '?company_id=' + company?.id" alt="">
                                                                </ng-container>
                                                                <ng-template #elseTemplateHasThumb>
                                                                    <mat-icon (click)="download(file)" class="file_img_icon" style="color: #3f51b5" >
                                                                        insert_drive_file
                                                                    </mat-icon>
                                                                    <div class="file_name" (click)="$event.preventDefault(); $event.stopPropagation(); download(file)">{{ file.filename.length > 20 ? (file.filename | slice:0:10) + "..." + (file.filename | slice:-6) : file.filename | translate }}</div>
                                                                </ng-template>
                                                            </ng-container>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </ng-container>
                                            
                                            <div class="files_more" *ngIf="+job?.filesCount > job.files.slice(0,3).length" (click)="$event.preventDefault(); $event.stopPropagation(); openFiles(job, job?.status_id == 3 ? '/to_approve' : '/ready_files')">
                                                + {{ +job?.filesCount - job.files.length }}
                                            </div>
                                            <div class="files_more" *ngIf="job.files.length > +job?.filesCount && job.files.length > 3" (click)="$event.preventDefault(); $event.stopPropagation(); openFiles(job, job?.status_id == 3 ? '/to_approve' : '/ready_files')">
                                                + {{ job.files.length - 3 }}
                                            </div>
                                        </div>
                                    </ng-container>
                                </ng-template>
                            </div>
                            
                            <div class="files_right">
                                <ng-container *ngIf="!job.open">
                                    <div class="add_file_btn" [matMenuTriggerFor]="fileMenu">
                                        {{ "+ Add files" | translate}}
                                    </div>
                                    <mat-menu #fileMenu="matMenu" xPosition="before" class="header_menu">
                                        <div mat-menu-item (click)="$event.preventDefault(); $event.stopPropagation(); uploadFileBtn(true, job)">
                                            <mat-icon>upload_file</mat-icon>
                                            {{ 'Upload file' | translate }}
                                        </div>
                                        <div mat-menu-item (click)="$event.preventDefault(); $event.stopPropagation(); uploadFileBtn(false, job)">
                                            <mat-icon>drive_folder_upload</mat-icon>
                                            {{ 'Upload folder' | translate }}
                                        </div>
                                    </mat-menu>
                                </ng-container>
    
                                <div class="open_files_btn" (click)="$event.preventDefault(); $event.stopPropagation(); openFiles(job, '/')" [ngClass]="{'show': +job?.filesCount}">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.66669 10.4167C1.66669 7.88334 3.71669 5.83334 6.25002 5.83334H15C16.8417 5.83334 18.3334 7.32501 18.3334 9.16668C18.3334 11.0083 16.8417 12.5 15 12.5H7.91669C6.76669 12.5 5.83335 11.5667 5.83335 10.4167C5.83335 9.26668 6.76669 8.33334 7.91669 8.33334H14.1667V10H7.84169C7.38335 10 7.38335 10.8333 7.84169 10.8333H15C15.9167 10.8333 16.6667 10.0833 16.6667 9.16668C16.6667 8.25001 15.9167 7.50001 15 7.50001H6.25002C4.64169 7.50001 3.33335 8.80834 3.33335 10.4167C3.33335 12.025 4.64169 13.3333 6.25002 13.3333H14.1667V15H6.25002C3.71669 15 1.66669 12.95 1.66669 10.4167Z" fill="#1877F2"/>
                                    </svg>
                                    <span>{{ 'Files' | translate }}</span>
                                    
                                </div>
                            </div>
                        </div>
    
                    </div>
                </ng-container>
            </div>
        </div>
    </ng-template>
    <ng-template #elseTemplateMobileOut>
        <div class="mob_open_wrp">

            <div class="mob_open_header" [ngClass]="{'small_head': isSmallHead}">

                <div class="mob_job_open_bottom_head">
                    <div class="mobile_job">
      

                        <div class="mob_job_snippet">
                            <div class="job_main_data">
                                <div class="empl_info">
                                    <div class="empl_avatars" [ngClass]="{'has_add': !!activeJob?.uniqueAddEmployee?.length}">
                           
                                        <div class="main_employees" [ngStyle]="{'width': 32 + (activeJob.uniqueEmployees?.length - 1) * 26 + 'px'}">
                                            <ng-container *ngFor="let main of activeJob.uniqueEmployees; let i = index;">
                                                <ng-container *ngIf="!!main?.employee?.image; else elseTemplateHasUserAvatar">
                                                    <img [appHoverUserInfo]="{ user: main?.employee, company_id: company?.id }" [src]="main?.employee?.image" alt="" [ngStyle]="{'z-index': activeJob.uniqueEmployees.length - i, 'transform': 'translateX(-' + i*6 + 'px)'}">
                                                </ng-container>
                                                <ng-template #elseTemplateHasUserAvatar>
                                                    <ng-container *ngIf="main?.is_partner; else elsePartner">
                                                        <ng-container *ngIf="!!main?.partnerCompany?.avatarFile?.thumbnail && !!host; else elseTemplatePartnerAvatar">
                                                            <img [appHoverUserInfo]="{ user: {partnerCompany: main?.partnerCompany}, company_id: company?.id }" class="partner_avatar" (error)="onPartnerImgError($event)" [src]="host + main?.partnerCompany?.avatarFile?.thumbnail + '?company_id=' + company?.id" [ngStyle]="{'z-index': activeJob.uniqueEmployees.length - i, 'transform': 'translateX(-' + i*6 + 'px)'}" alt="">
                                                        </ng-container>
                                                        <ng-template #elseTemplatePartnerAvatar>
                                                            <img [appHoverUserInfo]="{ user: {partnerCompany: main?.partnerCompany}, company_id: company?.id }" class="partner_avatar" (error)="onPartnerImgError($event)" src="{{ imgRoute }}assets/img/partner.png" [ngStyle]="{'z-index': activeJob.uniqueEmployees.length - i, 'transform': 'translateX(-' + i*6 + 'px)'}" alt="">
                                                        </ng-template>
                                                    </ng-container>
                                                    <ng-template #elsePartner>
                                                        <mat-icon [appHoverUserInfo]="{ user: main?.employee, company_id: company?.id }" [ngStyle]="{'z-index': activeJob.uniqueEmployees.length - i, 'transform': 'translateX(-' + i*6 + 'px)'}">account_circle</mat-icon>
                                                    </ng-template>
                                                </ng-template>
                                            </ng-container>
                                            <img *ngIf="activeJob.uniqueEmployees?.length == 0" class="partner_avatar" src="{{ imgRoute }}assets/img/no-user.png" alt="">
                                        </div>
                                        
                                        <div class="add_employees" *ngIf="!!activeJob?.uniqueAddEmployee?.length" (dblclick)="company?.permissions.includes('owner') || checkIsManager(task, company, user) ? additionalEmployees(task, activeJob) : null">
                                            <div class="add_emp" *ngFor="let addEmp of activeJob?.uniqueAddEmployee; let j = index" [ngStyle]="{'z-index': activeJob?.uniqueAddEmployee.length - j, 'transform': 'translateX(-' + j*7 + 'px)'}">
                                                <ng-container *ngIf="!!addEmp?.employee?.image; else elseTemplate">
                                                    <img [appHoverUserInfo]="{ user: addEmp?.employee, company_id: company?.id }" [src]="addEmp?.employee?.image" alt="">
                                                </ng-container>
                                                <ng-template #elseTemplate>
                                                    <ng-container *ngIf="addEmp?.is_partner; else elsePartnerAdvisersMob">
                                                        <ng-container *ngIf="!!addEmp?.partnerCompany?.avatarFile?.thumbnail && !!host; else elseTemplatePartnerAvatar">
                                                            <img [appHoverUserInfo]="{ user: {partnerCompany: addEmp?.partnerCompany}, company_id: company?.id }" class="partner_avatar" (error)="onPartnerImgError($event)" [src]="host + addEmp?.partnerCompany?.avatarFile?.thumbnail + '?company_id=' + company?.id">
                                                        </ng-container>
                                                        <ng-template #elseTemplatePartnerAvatar>
                                                            <img [appHoverUserInfo]="{ user: {partnerCompany: addEmp?.partnerCompany}, company_id: company?.id }" class="partner_avatar" (error)="onPartnerImgError($event)" src="{{ imgRoute }}assets/img/partner.png">
                                                        </ng-template>
                                                    </ng-container>
                                                    <ng-template #elsePartnerAdvisersMob>
                                                        <mat-icon [appHoverUserInfo]="{ user: addEmp?.employee, company_id: company?.id }">account_circle</mat-icon>
                                                    </ng-template>
                                                </ng-template>
                                            </div>
                                        </div>
                                    </div>

                                    <ng-container *ngIf="activeJob.uniqueEmployees?.length <= 1">
                                        <ng-container *ngIf="activeJob.partnerCompanies.length; else elseTemplatejobPartnerCompany">
                                            <span class="partner"> {{ activeJob.partnerCompanies[0].partnerCompany.name }}</span>
                                        </ng-container>
                                        <ng-template #elseTemplatejobPartnerCompany>
                                            <span>{{ activeJob?.employee?.employee?.name }}</span>
                                        </ng-template>
                                    </ng-container>
                                </div>

                                <div class="inside_job" (click)="changeMobJobStatus(activeJob)" [ngClass]="{
                                    'first': activeJob?.status_id == '1',
                                    'second': activeJob?.status_id == '2',
                                    'third': activeJob?.status_id == '3',
                                    'fours': activeJob?.status_id == '4',
                                    'fives': activeJob?.status_id == '5',
                                    'sixs': activeJob?.status_id == '6',
                                    'nine': activeJob?.status_id == '97',
                                    'seven': activeJob?.status_id == '98',
                                    'eight': activeJob?.status_id == '99'}">
                                    <span class="text_one_line">{{ getOperationStatusName(activeJob?.status_id) | translate }}</span>  
                                </div>

                                <div class="job_type_out">
                                    <div class="job_type text_one_line">
                                        {{ activeJob.operation && activeJob.operation.name ? activeJob.operation.name : getColumnName(activeJob.operation_id) }}
                                    </div>
                                </div>
                            </div>
                            <div class="mob_job_name" (click)="isSmallHead ? null : (nameOpened ? closeName() : openName())">
                                <span>
                                    <ng-container *ngIf="isSmallHead; else elseTemplateisSmallHead">
                                        {{activeJob?.name.length > 65 ? ((activeJob?.name | slice:0:62) + '...') : activeJob?.name}}
                                    </ng-container>
                                    <ng-template #elseTemplateisSmallHead>
                                        {{ nameOpened ? activeJob?.name : (activeJob?.name.length > 43 ? ((activeJob?.name | slice:0:40) + '...') : activeJob?.name) }}
                                    </ng-template>
                                </span>
                                <span class="name_arrow">
                                    <svg *ngIf="activeJob?.name.length > 50" [ngClass]="{'rotate': nameOpened}" width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2.13 11.115L9 4.245L15.885 11.115L18 9L9 0L0 9L2.13 11.115Z" fill="#A3A3A3"/>
                                    </svg>
                                </span>
                            </div>
                            <div class="mob_job_info">
                                <div class="update_date">
                                    {{ activeJob.updated_at*1000 | date:'MMM dd. yyyy':'': activeLang | yearPipe }}
                                </div>

                                <div class="create_date">
                                    {{ activeJob.created_at*1000 | date:'MMM dd. yyyy':'': activeLang | yearPipe }}
                                </div>



                                <div class="job_price" [ngClass]="{'more_one': activeJob?.emplSummCount > 1}">
                                    <ng-container *ngFor="let emplSumm of activeJob?.emplSummary">
                                        <div class="val" *ngIf="emplSumm?.summary > 0">
                                            <span class="price_numb">
                                                {{ emplSumm?.summary | int }}
                                            </span>
                                            <span class="dot" *ngIf="+emplSumm?.summary > +(emplSumm?.summary | int)">
                                                {{ ((+emplSumm?.summary - +(emplSumm?.summary | int)) | reportsNumber).toString()|slice:1 }}
                                            </span>
                                            <span class="dot" *ngIf="+emplSumm?.summary == +(emplSumm?.summary | int)">
                                                {{ '.00' }}
                                            </span>
                                            <span class="unit">
                                                {{emplSumm?.iso_name}}
                                            </span>
                                        </div>
                                    </ng-container>
                                </div>

                                <ng-container *ngIf="activeJob.filesize && (company | perm:'see_files'); else elseTemplateShowMobFilesize">
                                    <div class="summary" #tooltip="matTooltip" [matTooltipPosition]="'above'" matTooltip="{{ activeJob?.file_stat_updated_at > 0 ? (activeJob?.file_stat_updated_at*1000 | date:'hh:mm a, MMM d, y':'':activeLang | yearPipe) : '' }}">{{ activeJob.filesize | filesize }}</div>
                                </ng-container>
                                <ng-template #elseTemplateShowMobFilesize>
                                    <div class="summary"></div>
                                </ng-template>

                                <div class="job_id">
                                    <span class="ml-1"><ng-container *ngIf="ifJobClient(activeJob)">c</ng-container><ng-container *ngIf="ifJobClientModerator(activeJob)">m</ng-container></span>
                                    {{activeJob?.id}}
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div class="tabs">
                        <div class="d-f-c">
                            <div class="mob_tab" (click)="changeTab(0)" [ngClass]="{'active': tab == 0, 'disabled': !activeJob?.private_comment && !activeJob?.comment}">
                                {{ "Description" | translate }}
                            </div>
                            <div class="mob_tab" (click)="changeTab(1)" [ngClass]="{'active': tab == 1}">
                                {{ "Chats" | translate }}
                            </div>
                            <div class="mob_tab" (click)="changeTab(2)" [ngClass]="{'active': tab == 2, 'disabled': !(company | perm:'see_files')}">
                                {{ "Files" | translate }}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            
            <div class="mob_srollable_block" #mobileScroll style="padding: 0 !important;">
                <ng-container [ngSwitch]="tab">
                    <ng-container *ngSwitchCase="0">
                        <div class="mob_comments" *ngIf="!!activeJob?.private_comment || !!activeJob?.comment">
                            <ng-container *ngIf="activeJob?.comment">
                                <div class="mob_task_comment fw400" [innerHTML]="activeJob?.comment | linky:{newWindow: true}"></div>
                            </ng-container>
            
                            <div class="mob_task_private_out" *ngIf="activeJob?.private_comment">
                                <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.416748 8.75016H9.58341L5.00008 0.833496L0.416748 8.75016ZM5.41675 7.50016H4.58341V6.66683H5.41675V7.50016ZM5.41675 5.8335H4.58341V4.16683H5.41675V5.8335Z" fill="#FF6A6A"/>
                                </svg>
                                <div class="mob_task_private text_one_line" [innerHTML]="activeJob?.private_comment | linky:{newWindow: true}"></div>
                            </div>
                        </div>

                        <ng-container *ngTemplateOutlet="elseTemplateMobChats"></ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="1">
                        <ng-container *ngTemplateOutlet="elseTemplateMobChats"></ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="2">
                        <app-mob-file-manager *ngIf="(company | perm:'see_files')"
                        [useOut]="true"
                        (CloseParentDialog)="closeJob()"
                        [initCompanyId]="company_id"
                        [initLocation]="!!data.loc ? data.loc : '/'"
                        [initData]="{
                            'task': task,
                            'work': activeJob,
                            'company': company
                        }"></app-mob-file-manager>
                    </ng-container>
                </ng-container>

                <ng-template #elseTemplateMobChats>
                    <div class="mob_task_chats">
                        <div class="open_chats_title">
                            <mat-form-field appearance="outline" class="filter_form status mobile_select">
                                <!-- <mat-label>{{ "Status" | translate }}</mat-label> -->
                                <mat-select [disableOptionCentering]="true" selectDir [formControl]="chatsStatsControl">
                                    <mat-option [value]="0">
                                        {{ "All" | translate }}
                                    </mat-option>
                                    <mat-option *ngFor="let status of statuses" [value]="status.id">
                                        {{ status.name | translate }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <div class="d-f-c">
                                <div class="d-f-c" *ngIf="!!activeJob?.chatsInfo?.length">
                                    <mat-form-field *ngIf="searchIsOpen" appearance="legacy" class="filter_form search">
                                        <mat-label>{{ 'Search' | translate }}</mat-label>
                                        <mat-icon matPrefix>search</mat-icon>
                                        <input [formControl]="chatsControl" matInput>
                                        <mat-icon (click)="toggleMobSearch()" matSuffix>close</mat-icon>
                                    </mat-form-field>
                                    <div class="d-f-c icon_mob_filter">
                                        <mat-icon *ngIf="!searchIsOpen" matPrefix (click)="toggleMobSearch()">search</mat-icon>
                                        <button type="button" mat-icon-button [matMenuTriggerFor]="sortBtn" class="mobActiveFilter" #tooltip="matTooltip" matTooltip="{{ 'Sort' | translate }}" [matTooltipPosition]="'above'">
                                            <mat-icon>sort</mat-icon>
                                        </button>
                                    </div>
                                </div>
                                
                                <mat-menu #sortBtn="matMenu" class="sort_tablet" xPosition='before' overlayPanelClass='sortTAB'>
                                    <div class="mat-menu-title">{{"Sort By" | translate}}:</div>
                                    <mat-divider></mat-divider>
                                    <button mat-menu-item (click)="sort(sortValue == 'updated_desc' ? 'updated' : 'updated_desc')" [ngClass]="{'active_status': sortValue == 'updated_desc' || sortValue == 'updated'}">
                                        {{  "Updated" | translate }}
                                        <div class="d-f-c ml-1">
                                            <mat-icon (click)="$event.preventDefault(); $event.stopPropagation(); sort('updated_desc')" [ngClass]="{'active': sortValue == 'updated_desc'}" style="margin-right: 0 !important;">arrow_drop_down</mat-icon>
                                            <mat-icon (click)="$event.preventDefault(); $event.stopPropagation(); sort('updated')" [ngClass]="{'active': sortValue == 'updated'}" style="margin-right: 0 !important;">arrow_drop_up</mat-icon>
                                        </div>
                                    </button>
                                    <button mat-menu-item  (click)="sort(sortValue == 'priority_desc' ? 'priority' : 'priority_desc')" [ngClass]="{'active_status': sortValue == 'priority_desc' || sortValue == 'priority'}">
                                        {{  "Priority" | translate }}
                                        <div class="d-f-c ml-1">
                                            <mat-icon (click)="$event.preventDefault(); $event.stopPropagation(); sort('priority_desc')" [ngClass]="{'active': sortValue == 'priority_desc'}" style="margin-right: 0 !important;">arrow_drop_down</mat-icon>
                                            <mat-icon (click)="$event.preventDefault(); $event.stopPropagation(); sort('priority')" [ngClass]="{'active': sortValue == 'priority'}" style="margin-right: 0 !important;">arrow_drop_up</mat-icon>
                                        </div>
                                    </button>
                                    <button mat-menu-item  (click)="sort(sortValue == 'id_desc' ? 'id' : 'id_desc')" [ngClass]="{'active_status': sortValue == 'id_desc' || sortValue == 'id'}">
                                        {{  "ID" | translate }}
                                        <div class="d-f-c ml-1">
                                            <mat-icon (click)="$event.preventDefault(); $event.stopPropagation(); sort('id_desc')" [ngClass]="{'active': sortValue == 'id_desc'}" style="margin-right: 0 !important;">arrow_drop_down</mat-icon>
                                            <mat-icon (click)="$event.preventDefault(); $event.stopPropagation(); sort('id')" [ngClass]="{'active': sortValue == 'id'}" style="margin-right: 0 !important;">arrow_drop_up</mat-icon>
                                        </div>
                                    </button>
                                </mat-menu>
                            </div>
                        </div>  
                        <div class="mob_chat" (click)="goToChat(chat, activeJob)" *ngFor="let chat of chats$ | async" [ngClass]="{'highlight_chat': chat.is_read == '0'}">
                            <button mat-icon-button class="job_mob_more mob_vert" (click)="$event.stopPropagation(); mobOptions(chat)">
                              <mat-icon>more_horiz</mat-icon>
                            </button>
                            <div class="chat_photo">
                              <ng-container *ngIf="chat?.lastPost?.action; else elseTemplateHasActionPhoto">
                                  <ng-container *ngIf="!!chat?.lastPost?.action?.createdEmployee?.image; else elseOwnerTemplateLastPostPhoto">
                                      <img [appHoverUserInfo]="{ user: chat?.lastPost?.action?.createdEmployee, company_id: company?.id }" [src]="chat?.lastPost?.action?.createdEmployee?.image" alt="">
                                  </ng-container>
                                  <ng-template #elseOwnerTemplateLastPostPhoto>
                                      <mat-icon [appHoverUserInfo]="{ user: chat?.lastPost?.action?.createdEmployee, company_id: company?.id }">account_circle</mat-icon>
                                  </ng-template>
                              </ng-container>
                              <ng-template #elseTemplateHasActionPhoto>
                                  <ng-container *ngIf="!!chat?.lastPost?.employee?.image; else elseOwnerTemplateLastPostPhoto">
                                      <img [appHoverUserInfo]="{ user: chat?.lastPost?.employee, company_id: company?.id }" [src]="chat?.lastPost?.employee?.image" alt="">
                                  </ng-container>
                                  <ng-template #elseOwnerTemplateLastPostPhoto>
                                      <mat-icon [appHoverUserInfo]="{ user: chat?.lastPost?.employee, company_id: company?.id }">account_circle</mat-icon>
                                  </ng-template>
                              </ng-template>
                            </div>
                            <div class="chat_data">
                                <div class="chat_head">
                                    <ng-container *ngIf="chat?.lastPost?.action; else elseTemplateHasActionName">
                                        <div class="user_name">{{ chat?.lastPost?.action?.createdEmployee?.name }}</div>
                                    </ng-container>
                                    <ng-template #elseTemplateHasActionName>
                                        <div class="user_name">{{ chat?.lastPost?.employee?.name }}</div>
                                    </ng-template>
                    
                                    <div class="d-f-c">
                                        <div class="inside_job" [ngClass]="{
                                            'first': chat?.status_id == '1',
                                            'second': chat?.status_id == '2',
                                            'third': chat?.status_id == '3',
                                            'fours': chat?.status_id == '4',
                                            'fives': chat?.status_id == '5',
                                            'sixs': chat?.status_id == '6',
                                            'nine': chat?.status_id == '97',
                                            'seven': chat?.status_id == '98',
                                            'eight': chat?.status_id == '99'}">
                                            <span class="text_one_line">{{ getOperationStatusName(chat?.status_id) | translate }}</span>  
                                        </div>
                    
                                        <div class="date">
                                            {{ chat?.lastPost?.updated_at | reportsDate:activeLang }} 
                                            <!-- {{ chat?.created_at*1000 | date:'MMM d, y':'timeZone':activeLang | yearPipe }}  -->
                                        </div>
                                    </div>
                                </div>
                                <div class="chat_name text_one_line">
                                    {{ chat?.name }}
                                </div>
                    
                                <ng-container *ngIf="chat?.lastPost?.action; else elseTemplateHasAction">
                                  <div class="chat_last_post">           
                                    {{ 'switched to' | translate }} 
                                    <ng-container [ngSwitch]="chat?.lastPost?.action?.action">
                                      <ng-container *ngSwitchCase="'task_employee_status_change'">
                                        <b style="margin-left: 5px; margin-right: 5px;">{{ getOperationStatusName(chat?.lastPost?.action?.task_employee_status_id) }}</b>
                                        {{ "for" | translate }}
                                        <ng-container *ngIf="!!chat?.lastPost?.action?.employee?.image; else elseActionOwnerTemplate">
                                            <img [appHoverUserInfo]="{ user: chat?.lastPost?.action?.employee, company_id: company?.id }" class="ml-5" [src]="chat?.lastPost?.action?.employee?.image" alt="">
                                        </ng-container>
                                        <ng-template #elseActionOwnerTemplate>
                                            <mat-icon class="ml-5" [appHoverUserInfo]="{ user: chat?.lastPost?.action?.employee, company_id: company?.id }">account_circle</mat-icon>
                                        </ng-template>
                                      </ng-container>
                                      <ng-container *ngSwitchCase="'discussion_status'">
                                        <b style="margin-left: 5px;">{{ getOperationStatusName(chat?.lastPost?.action?.discussion_status_id) }}</b>
                                      </ng-container>
                                    </ng-container>
                                  </div>
                                </ng-container>
                    
                                <ng-template #elseTemplateHasAction>
                                    <div class="chat_last_post">
                                        <div class="last_post_file" *ngIf="!!chat?.lastPost?.file_id">
                                        <div class="file_view">
                                            <ng-container [ngSwitch]="understandFileType(chat?.lastPost?.file?.content_type)">
                                                <ng-container *ngSwitchCase="'video'">
                                                    <div class="file movie d-f-c">
                                                        <ng-container *ngIf="chat?.lastPost?.file?.thumbnail; else elseTemplateThumbnail">
                                                            <div class="movie_thumb" >
                                                                <img style="max-width: 100%; max-height: 100%;" (error)="onImgError($event)" [src]="host + chat?.lastPost?.file?.thumbnail + '?company_id=' + company?.id">
                                                            </div>
                                                        </ng-container>
                                                        <ng-template #elseTemplateThumbnail>
                                                            <mat-icon class="file_img_icon" style="color: #3f51b5">
                                                                movie
                                                            </mat-icon>
                                                        </ng-template>
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'pdf'">
                                                    <div class="pdf">
                                                        <svg height="800px" width="800px" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
                                                            viewBox="0 0 303.188 303.188" xml:space="preserve">
                                                            <g>
                                                                <polygon style="fill:#E8E8E8;" points="219.821,0 32.842,0 32.842,303.188 270.346,303.188 270.346,50.525 	"/>
                                                                <path style="fill:#FB3449;" d="M230.013,149.935c-3.643-6.493-16.231-8.533-22.006-9.451c-4.552-0.724-9.199-0.94-13.803-0.936
                                                                    c-3.615-0.024-7.177,0.154-10.693,0.354c-1.296,0.087-2.579,0.199-3.861,0.31c-1.314-1.36-2.584-2.765-3.813-4.202
                                                                    c-7.82-9.257-14.134-19.755-19.279-30.664c1.366-5.271,2.459-10.772,3.119-16.485c1.205-10.427,1.619-22.31-2.288-32.251
                                                                    c-1.349-3.431-4.946-7.608-9.096-5.528c-4.771,2.392-6.113,9.169-6.502,13.973c-0.313,3.883-0.094,7.776,0.558,11.594
                                                                    c0.664,3.844,1.733,7.494,2.897,11.139c1.086,3.342,2.283,6.658,3.588,9.943c-0.828,2.586-1.707,5.127-2.63,7.603
                                                                    c-2.152,5.643-4.479,11.004-6.717,16.161c-1.18,2.557-2.335,5.06-3.465,7.507c-3.576,7.855-7.458,15.566-11.815,23.02
                                                                    c-10.163,3.585-19.283,7.741-26.857,12.625c-4.063,2.625-7.652,5.476-10.641,8.603c-2.822,2.952-5.69,6.783-5.941,11.024
                                                                    c-0.141,2.394,0.807,4.717,2.768,6.137c2.697,2.015,6.271,1.881,9.4,1.225c10.25-2.15,18.121-10.961,24.824-18.387
                                                                    c4.617-5.115,9.872-11.61,15.369-19.465c0.012-0.018,0.024-0.036,0.037-0.054c9.428-2.923,19.689-5.391,30.579-7.205
                                                                    c4.975-0.825,10.082-1.5,15.291-1.974c3.663,3.431,7.621,6.555,11.939,9.164c3.363,2.069,6.94,3.816,10.684,5.119
                                                                    c3.786,1.237,7.595,2.247,11.528,2.886c1.986,0.284,4.017,0.413,6.092,0.335c4.631-0.175,11.278-1.951,11.714-7.57
                                                                    C231.127,152.765,230.756,151.257,230.013,149.935z M119.144,160.245c-2.169,3.36-4.261,6.382-6.232,9.041
                                                                    c-4.827,6.568-10.34,14.369-18.322,17.286c-1.516,0.554-3.512,1.126-5.616,1.002c-1.874-0.11-3.722-0.937-3.637-3.065
                                                                    c0.042-1.114,0.587-2.535,1.423-3.931c0.915-1.531,2.048-2.935,3.275-4.226c2.629-2.762,5.953-5.439,9.777-7.918
                                                                    c5.865-3.805,12.867-7.23,20.672-10.286C120.035,158.858,119.587,159.564,119.144,160.245z M146.366,75.985
                                                                    c-0.602-3.514-0.693-7.077-0.323-10.503c0.184-1.713,0.533-3.385,1.038-4.952c0.428-1.33,1.352-4.576,2.826-4.993
                                                                    c2.43-0.688,3.177,4.529,3.452,6.005c1.566,8.396,0.186,17.733-1.693,25.969c-0.299,1.31-0.632,2.599-0.973,3.883
                                                                    c-0.582-1.601-1.137-3.207-1.648-4.821C147.945,83.048,146.939,79.482,146.366,75.985z M163.049,142.265
                                                                    c-9.13,1.48-17.815,3.419-25.979,5.708c0.983-0.275,5.475-8.788,6.477-10.555c4.721-8.315,8.583-17.042,11.358-26.197
                                                                    c4.9,9.691,10.847,18.962,18.153,27.214c0.673,0.749,1.357,1.489,2.053,2.22C171.017,141.096,166.988,141.633,163.049,142.265z
                                                                    M224.793,153.959c-0.334,1.805-4.189,2.837-5.988,3.121c-5.316,0.836-10.94,0.167-16.028-1.542
                                                                    c-3.491-1.172-6.858-2.768-10.057-4.688c-3.18-1.921-6.155-4.181-8.936-6.673c3.429-0.206,6.9-0.341,10.388-0.275
                                                                    c3.488,0.035,7.003,0.211,10.475,0.664c6.511,0.726,13.807,2.961,18.932,7.186C224.588,152.585,224.91,153.321,224.793,153.959z"/>
                                                                <polygon style="fill:#FB3449;" points="227.64,25.263 32.842,25.263 32.842,0 219.821,0 	"/>
                                                                <g>
                                                                    <path style="fill:#A4A9AD;" d="M126.841,241.152c0,5.361-1.58,9.501-4.742,12.421c-3.162,2.921-7.652,4.381-13.472,4.381h-3.643
                                                                        v15.917H92.022v-47.979h16.606c6.06,0,10.611,1.324,13.652,3.971C125.321,232.51,126.841,236.273,126.841,241.152z
                                                                        M104.985,247.387h2.363c1.947,0,3.495-0.546,4.644-1.641c1.149-1.094,1.723-2.604,1.723-4.529c0-3.238-1.794-4.857-5.382-4.857
                                                                        h-3.348C104.985,236.36,104.985,247.387,104.985,247.387z"/>
                                                                    <path style="fill:#A4A9AD;" d="M175.215,248.864c0,8.007-2.205,14.177-6.613,18.509s-10.606,6.498-18.591,6.498h-15.523v-47.979
                                                                        h16.606c7.701,0,13.646,1.969,17.836,5.907C173.119,235.737,175.215,241.426,175.215,248.864z M161.76,249.324
                                                                        c0-4.398-0.87-7.657-2.609-9.78c-1.739-2.122-4.381-3.183-7.926-3.183h-3.773v26.877h2.888c3.939,0,6.826-1.143,8.664-3.43
                                                                        C160.841,257.523,161.76,254.028,161.76,249.324z"/>
                                                                    <path style="fill:#A4A9AD;" d="M196.579,273.871h-12.766v-47.979h28.355v10.403h-15.589v9.156h14.374v10.403h-14.374
                                                                        L196.579,273.871L196.579,273.871z"/>
                                                                </g>
                                                                <polygon style="fill:#D1D3D3;" points="219.821,50.525 270.346,50.525 219.821,0 	"/>
                                                            </g>
                                                        </svg>
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'image'">
                                                    <div class="img_wrp">
                                                        <img (error)="onImgError($event)" [src]="host + (chat?.lastPost?.file?.thumbnail ? chat?.lastPost?.file?.thumbnail + '?company_id=' + company?.id : chat?.lastPost?.file?.original + '?company_id=' + company?.id)" alt="">
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngSwitchDefault>
                                                <ng-container *ngIf="!!chat?.lastPost?.file?.thumbnail; else elseTemplateHasThumb">
                                                    <div class="img_wrp">
                                                    <img (error)="onImgError($event)" [src]="host + chat?.lastPost?.file?.thumbnail + '?company_id=' + company?.id" alt="">
                                                    </div>
                                                </ng-container>
                                                <ng-template #elseTemplateHasThumb>
                                                    <div class="file d-f-c">
                                                    <mat-icon class="file_img_icon mr-1" style="color: #3f51b5">
                                                        insert_drive_file
                                                    </mat-icon>
                                                </div>
                                                </ng-template>
                                                </ng-container>
                                            </ng-container>
                                        </div>
                                        <!-- <div class="file_info">
                                            <span class="file_name">{{ chat?.lastPost?.file?.filename }}</span>
                                            <span class="file_size">{{ chat?.lastPost?.file?.filesize | filesize }}</span>
                                        </div> -->
                    
                                        </div>
                                        
                                        <div class="text_one_line" [innerHTML]='chat?.lastPost?.text ? (chat?.lastPost?.text.replaceAll("\n"," ").replaceAll("</BR>"," ").replaceAll("<BR>"," ").replaceAll("</br>"," ").replaceAll("<br>"," ")) : ""'></div>
                                    </div>
                              </ng-template>
                              <!-- <div class="chat_footer">
                                <div class="foot_line">
                                  <div class="job_type">
                                      {{ getColumnName(chat?.taskOperation?.operation_id) }}
                                  </div>
                                  <div class="job_desc text_one_line" *ngIf="chat?.taskOperation?.name">
                                      {{ chat?.taskOperation?.name }}
                                  </div>
                                </div>
                                <div class="foot_line">
                                  <div class="group">
                                      <span class="text_one_line" *ngIf="chat?.task?.group_id">
                                        {{ getGroup(chat?.task?.group_id).name }}
                                      </span>
                                  </div>
                                  <div class="task text_one_line">
                                    {{ chat?.task?.name }}
                                  </div>
                                </div>
                              </div> -->
                            </div>
                          </div>
                    </div>
                </ng-template>
            </div>

            <div class="open_dialog_footer mob_open_footer" *ngIf="tab != 2">
                <div class="d-f-c">
                    <div (click)="closeJob()" class="mob_close_icon_wrp">
                        <svg class="mob_close_icon" width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.88 14.1067L3.77333 8L9.88 1.88L8 0L0 8L8 16L9.88 14.1067Z" fill="#FF0000"/>
                        </svg>
                    </div>
                </div>
                <div class="d-f-c margins_mob_btns">

                    <div class="job_scenarios" [ngClass]="{'active': !!activeJob.scenarioOpen, 'not_show_scen': !templates || !templates.length}">
                        <ng-container *ngIf="activeJob?.automationScenarios?.length > 0; else elseTemplateJobScen">
                            <div class="job_scen_ico" (click)="openMobScenarios(activeJob)">
                                <ng-container *ngIf="allDone(activeJob); else elseTemplateAllDone">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9 5C8.46957 5 7.96086 5.21071 7.58579 5.58579C7.21071 5.96086 7 6.46957 7 7V9C7 9.53043 7.21071 10.0391 7.58579 10.4142C7.96086 10.7893 8.46957 11 9 11H11V13H7V15H11C11.5304 15 12.0391 14.7893 12.4142 14.4142C12.7893 14.0391 13 13.5304 13 13V11C13 10.4696 12.7893 9.96086 12.4142 9.58579C12.0391 9.21071 11.5304 9 11 9H9V7H13V5H9ZM10 0C11.3132 0 12.6136 0.258658 13.8268 0.761205C15.0401 1.26375 16.1425 2.00035 17.0711 2.92893C17.9997 3.85752 18.7362 4.95991 19.2388 6.17317C19.7413 7.38642 20 8.68678 20 10C20 12.6522 18.9464 15.1957 17.0711 17.0711C15.1957 18.9464 12.6522 20 10 20C8.68678 20 7.38642 19.7413 6.17317 19.2388C4.95991 18.7362 3.85752 17.9997 2.92893 17.0711C1.05357 15.1957 0 12.6522 0 10C0 7.34784 1.05357 4.8043 2.92893 2.92893C4.8043 1.05357 7.34784 0 10 0Z" fill="#00FF38"/>
                                    </svg>
                                </ng-container>
                                <ng-template #elseTemplateAllDone>
                                    <ng-container *ngIf="someDone(activeJob); else elseTemplateSomeDone">
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9 5C8.46957 5 7.96086 5.21071 7.58579 5.58579C7.21071 5.96086 7 6.46957 7 7V9C7 9.53043 7.21071 10.0391 7.58579 10.4142C7.96086 10.7893 8.46957 11 9 11H11V13H7V15H11C11.5304 15 12.0391 14.7893 12.4142 14.4142C12.7893 14.0391 13 13.5304 13 13V11C13 10.4696 12.7893 9.96086 12.4142 9.58579C12.0391 9.21071 11.5304 9 11 9H9V7H13V5H9ZM10 0C11.3132 0 12.6136 0.258658 13.8268 0.761205C15.0401 1.26375 16.1425 2.00035 17.0711 2.92893C17.9997 3.85752 18.7362 4.95991 19.2388 6.17317C19.7413 7.38642 20 8.68678 20 10C20 12.6522 18.9464 15.1957 17.0711 17.0711C15.1957 18.9464 12.6522 20 10 20C8.68678 20 7.38642 19.7413 6.17317 19.2388C4.95991 18.7362 3.85752 17.9997 2.92893 17.0711C1.05357 15.1957 0 12.6522 0 10C0 7.34784 1.05357 4.8043 2.92893 2.92893C4.8043 1.05357 7.34784 0 10 0Z" fill="#00FF3880"/>
                                        </svg>
                                    </ng-container>
                                    <ng-template #elseTemplateSomeDone>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9 5C8.46957 5 7.96086 5.21071 7.58579 5.58579C7.21071 5.96086 7 6.46957 7 7V9C7 9.53043 7.21071 10.0391 7.58579 10.4142C7.96086 10.7893 8.46957 11 9 11H11V13H7V15H11C11.5304 15 12.0391 14.7893 12.4142 14.4142C12.7893 14.0391 13 13.5304 13 13V11C13 10.4696 12.7893 9.96086 12.4142 9.58579C12.0391 9.21071 11.5304 9 11 9H9V7H13V5H9ZM10 0C11.3132 0 12.6136 0.258658 13.8268 0.761205C15.0401 1.26375 16.1425 2.00035 17.0711 2.92893C17.9997 3.85752 18.7362 4.95991 19.2388 6.17317C19.7413 7.38642 20 8.68678 20 10C20 12.6522 18.9464 15.1957 17.0711 17.0711C15.1957 18.9464 12.6522 20 10 20C8.68678 20 7.38642 19.7413 6.17317 19.2388C4.95991 18.7362 3.85752 17.9997 2.92893 17.0711C1.05357 15.1957 0 12.6522 0 10C0 7.34784 1.05357 4.8043 2.92893 2.92893C4.8043 1.05357 7.34784 0 10 0Z" fill="#B5F2CE"/>
                                        </svg>
                                    </ng-template>
                                </ng-template>                                                  
                            </div>
                        </ng-container>
                        <ng-template #elseTemplateJobScen>
                            <div class="job_scen_ico no_job_scen_ico" (click)="openMobScenarios(activeJob)">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9 5H13V7H9V9H11C11.5304 9 12.0391 9.21071 12.4142 9.58579C12.7893 9.96086 13 10.4696 13 11V13C13 13.5304 12.7893 14.0391 12.4142 14.4142C12.0391 14.7893 11.5304 15 11 15H7V13H11V11H9C8.46957 11 7.96086 10.7893 7.58579 10.4142C7.21071 10.0391 7 9.53043 7 9V7C7 6.46957 7.21071 5.96086 7.58579 5.58579C7.96086 5.21071 8.46957 5 9 5ZM10 0C11.3132 0 12.6136 0.258658 13.8268 0.761205C15.0401 1.26375 16.1425 2.00035 17.0711 2.92893C17.9997 3.85752 18.7362 4.95991 19.2388 6.17317C19.7413 7.38642 20 8.68678 20 10C20 12.6522 18.9464 15.1957 17.0711 17.0711C15.1957 18.9464 12.6522 20 10 20C8.68678 20 7.38642 19.7413 6.17317 19.2388C4.95991 18.7362 3.85752 17.9997 2.92893 17.0711C1.05357 15.1957 0 12.6522 0 10C0 7.34784 1.05357 4.8043 2.92893 2.92893C4.8043 1.05357 7.34784 0 10 0ZM10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2Z" fill="#C9C9C9"/>
                                </svg>
                            </div>
                        </ng-template>

                        <div class="job_scenarios_hover mob_out_scen mobile_job_card_scenarios" [ngClass]="{'visible': !!activeJob.scen_visible}" (click)="$event.stopPropagation()">
                            <div class="job_scenarios_hover_in">
                                <div class="users_select with_tabs_scen">
                                    <ng-container *ngIf="activeJob.groupScenarios && activeJob.groupScenarios.length; else elseTemplate_groupScenarios">
                                        <div class="tabs_scen">
                                            <div [ngClass]="{'active': !!activeJob.scenarioTab}" (click)="activeJob.scenarioTab = true">Group</div>
                                            <div [ngClass]="{'active': !activeJob.scenarioTab}" (click)="activeJob.scenarioTab = false">All</div>
                                        </div>
                                        <ng-container [ngSwitch]="activeJob.scenarioTab">
                                            <ng-container *ngSwitchCase="true">
                                                <mat-chip-list class="scen_list" [multiple]="true" selectable>
                                                    <label>{{ "Choose a scenario" | translate }}: </label>
                                                    <ng-container *ngFor="let template of activeJob.groupScenarios; let i = index">
                                                        <mat-chip [disabled]="disableTemplate" style="padding-left: 5px !important; display: flex !important;" class="chip_user text_one_line" (click)="toggleChipTemplate(template, activeJob)" [value]="template.id" [selected]="scenIsSelected(activeJob, template)">
                                                            <mat-icon class="active" *ngIf="activeScan(activeJob, template)">done</mat-icon>
                                                            {{ template.name }}
                                                            <mat-icon class="try_scen" *ngIf="scenIsSelected(activeJob, template)" (click)="tryScen($event, activeJob, template)">replay</mat-icon>
                                                        </mat-chip>
                                                        <ng-container *ngIf="scenIsSelected(activeJob, template) && duplicatesScenCount(activeJob, template).length > 0">
                                                            <ng-container *ngFor="let u of [].constructor(duplicatesScenCount(activeJob, template).length); let q = index;">
                                                                <mat-chip *ngIf="q != 0" [disabled]="disableTemplate" style="padding-left: 5px !important;" class="chip_user text_one_line dubl_scen" (click)="toggleDuplicateScen(template, activeJob, q)" [value]="template.id" [selected]="true">
                                                                    <mat-icon class="active" *ngIf="activeDuplicateScan(activeJob, template, q)">done</mat-icon>
                                                                    {{ template.name }}
                                                                    <mat-icon class="try_scen" *ngIf="scenIsSelected(activeJob, template)" (click)="tryDuplicateScen($event, activeJob, template, q)">replay</mat-icon>
                                                                </mat-chip>
                                                            </ng-container>
                                                        </ng-container>
                                                    </ng-container>
                                                </mat-chip-list>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="false">
                                                <mat-chip-list class="scen_list" [multiple]="true" selectable>
                                                    <label>{{ "Choose a scenario" | translate }}: </label>
                                                    <ng-container *ngFor="let template of templates; let i = index">
                                                        <mat-chip [disabled]="disableTemplate" style="padding-left: 5px !important;" class="chip_user text_one_line" (click)="toggleChipTemplate(template, activeJob)" [value]="template.id" [selected]="scenIsSelected(activeJob, template)">
                                                            <mat-icon class="active" *ngIf="activeScan(activeJob, template)">done</mat-icon>
                                                            {{ template.name }}
                                                            <mat-icon class="try_scen" *ngIf="scenIsSelected(activeJob, template)" (click)="tryScen($event, activeJob, template)">replay</mat-icon>
                                                        </mat-chip>
                                                        <ng-container *ngIf="scenIsSelected(activeJob, template) && duplicatesScenCount(activeJob, template).length > 0">
                                                            <ng-container *ngFor="let u of [].constructor(duplicatesScenCount(activeJob, template).length); let q = index;">
                                                                <mat-chip *ngIf="q != 0" [disabled]="disableTemplate" style="padding-left: 5px !important;" class="chip_user text_one_line dubl_scen" (click)="toggleDuplicateScen(template, activeJob, q)" [value]="template.id" [selected]="true">
                                                                    <mat-icon class="active" *ngIf="activeDuplicateScan(activeJob, template, q)">done</mat-icon>
                                                                    {{ template.name }}
                                                                    <mat-icon class="try_scen" *ngIf="scenIsSelected(activeJob, template)" (click)="tryDuplicateScen($event, activeJob, template, q)">replay</mat-icon>
                                                                </mat-chip>
                                                            </ng-container>
                                                        </ng-container>
                                                        <mat-chip *ngIf="i == templates?.length - 1 && i > 40" class="small_chip more" (click)="jobScenContainer.open(); activeJob.scen_visible = true;">
                                                            {{ "More..." | translate }}
                                                        </mat-chip>
                                                    </ng-container>
                                                </mat-chip-list>
                                                <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                                                    <mat-select [multiple]="true" #jobScenContainer [(ngModel)]="activeJob.selectedScenIds" (openedChange)="scenChangeOpen($event, activeJob)">
                                                        <mat-option>
                                                            <ngx-mat-select-search 
                                                            placeholderLabel="{{ 'Search' | translate }}" 
                                                            noEntriesFoundLabel="{{ 'no matching templates found' | translate }}"
                                                            [formControl]="templatesControl"></ngx-mat-select-search>
                                                        </mat-option>
                                                        <mat-option *ngFor="let template of templates$ | async" [value]="template.id" (click)="toggleChipTemplate(template, activeJob)">
                                                            {{ template.name }}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                    <ng-template #elseTemplate_groupScenarios>
                                        <mat-chip-list class="scen_list" [multiple]="true" selectable>
                                            <label>{{ "Choose a scenario" | translate }}: </label>
                                            <ng-container *ngFor="let template of templates; let i = index">
                                                <mat-chip [disabled]="disableTemplate" style="padding-left: 5px !important;" class="chip_user text_one_line" (click)="toggleChipTemplate(template, activeJob)" [value]="template.id" [selected]="scenIsSelected(activeJob, template)">
                                                    <mat-icon class="active" *ngIf="activeScan(activeJob, template)">done</mat-icon>
                                                    {{ template.name }}
                                                    <mat-icon class="try_scen" *ngIf="scenIsSelected(activeJob, template)" (click)="tryScen($event, activeJob, template)">replay</mat-icon>
                                                </mat-chip>
                                                <ng-container *ngIf="scenIsSelected(activeJob, template) && duplicatesScenCount(activeJob, template).length > 0">
                                                    <ng-container *ngFor="let u of [].constructor(duplicatesScenCount(activeJob, template).length); let q = index;">
                                                        <mat-chip *ngIf="q != 0" [disabled]="disableTemplate" style="padding-left: 5px !important;" class="chip_user text_one_line dubl_scen" (click)="toggleDuplicateScen(template, activeJob, q)" [value]="template.id" [selected]="true">
                                                            <mat-icon class="active" *ngIf="activeDuplicateScan(activeJob, template, q)">done</mat-icon>
                                                            {{ template.name }}
                                                            <mat-icon class="try_scen" *ngIf="scenIsSelected(activeJob, template)" (click)="tryDuplicateScen($event, activeJob, template, q)">replay</mat-icon>
                                                        </mat-chip>
                                                    </ng-container>
                                                </ng-container>
                                                <mat-chip *ngIf="i == templates?.length - 1 && i > 40" class="small_chip more" (click)="jobScenContainer.open(); activeJob.scen_visible = true;">
                                                    {{ "More..." | translate }}
                                                </mat-chip>
                                            </ng-container>
                                        </mat-chip-list>
                                        <mat-form-field appearance="standard" class="full_width_form hide-form-field">
                                            <mat-select [multiple]="true" #jobScenContainer [(ngModel)]="activeJob.selectedScenIds" (openedChange)="scenChangeOpen($event, activeJob)">
                                                <mat-option>
                                                    <ngx-mat-select-search 
                                                    placeholderLabel="{{ 'Search' | translate }}" 
                                                    noEntriesFoundLabel="{{ 'no matching templates found' | translate }}"
                                                    [formControl]="templatesControl"></ngx-mat-select-search>
                                                </mat-option>
                                                <mat-option *ngFor="let template of templates$ | async" [value]="template.id" (click)="toggleChipTemplate(template, activeJob)">
                                                    {{ template.name }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="d-f-c add_client" *ngIf="company?.permissions.includes('owner') || checkIsManager(task, company, user)" (click)="openMobAssignments(task, activeJob)">
                        <svg width="23" height="16" viewBox="0 0 23 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.5714 8C16.9466 8 17.3182 7.9261 17.6648 7.78251C18.0115 7.63893 18.3264 7.42847 18.5917 7.16316C18.857 6.89785 19.0675 6.58288 19.2111 6.23624C19.3547 5.88959 19.4286 5.51806 19.4286 5.14286C19.4286 4.3851 19.1276 3.65837 18.5917 3.12255C18.0559 2.58673 17.3292 2.28571 16.5714 2.28571C15.8137 2.28571 15.0869 2.58673 14.5511 3.12255C14.0153 3.65837 13.7143 4.3851 13.7143 5.14286C13.7143 5.51806 13.7882 5.88959 13.9318 6.23624C14.0754 6.58288 14.2858 6.89785 14.5511 7.16316C15.0869 7.69898 15.8137 8 16.5714 8ZM8 6.85714C8.90931 6.85714 9.78139 6.49592 10.4244 5.85294C11.0673 5.20996 11.4286 4.33789 11.4286 3.42857C11.4286 2.51926 11.0673 1.64719 10.4244 1.00421C9.78139 0.361224 8.90931 0 8 0C7.09069 0 6.21862 0.361224 5.57563 1.00421C4.93265 1.64719 4.57143 2.51926 4.57143 3.42857C4.57143 4.33789 4.93265 5.20996 5.57563 5.85294C6.21862 6.49592 7.09069 6.85714 8 6.85714ZM16.5714 10.2857C14.48 10.2857 10.2857 11.3371 10.2857 13.4286V16H22.8571V13.4286C22.8571 11.3371 18.6629 10.2857 16.5714 10.2857ZM8 9.14286C5.33714 9.14286 0 10.48 0 13.1429V16H8V13.4286C8 12.4571 8.37714 10.7543 10.7086 9.46286C9.71429 9.25714 8.75429 9.14286 8 9.14286Z" fill="black"/>
                        </svg>
                    </div>
                    
                    <div (click)="editWork(task, activeJob)">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3 17.25V21H6.75L17.81 9.94L14.06 6.19L3 17.25ZM20.71 7.04C21.1 6.65 21.1 6.02 20.71 5.63L18.37 3.29C17.98 2.9 17.35 2.9 16.96 3.29L15.13 5.12L18.88 8.87L20.71 7.04Z" fill="#686868"/>
                        </svg>
                    </div>

                    <div (cbOnSuccess)="copyLink('Job')" ngxClipboard [cbContent]="origin + '/job/' + activeJob?.acm">
                        <svg width="20" height="10" viewBox="0 0 20 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15 0H11V2H15C16.65 2 18 3.35 18 5C18 6.65 16.65 8 15 8H11V10H15C17.76 10 20 7.76 20 5C20 2.24 17.76 0 15 0ZM9 8H5C3.35 8 2 6.65 2 5C2 3.35 3.35 2 5 2H9V0H5C2.24 0 0 2.24 0 5C0 7.76 2.24 10 5 10H9V8ZM6 4H14V6H6V4Z" fill="#686868"/>
                        </svg>
                    </div>

                    <button class="mob_vert" (click)="$event.stopPropagation()" mat-icon-button [matMenuTriggerFor]="mobMenu">
                        <mat-icon style="color: #A3A3A3; vertical-align: baseline;">more_vert</mat-icon>
                    </button>
                      <mat-menu #mobMenu="matMenu" xPosition="after">
                        <button mat-menu-item (click)="checkWorkActions($event, task, activeJob)">
                            <div class="d-f-c">
                                <svg style="margin-right: 16px;" width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.6905 5.47619H12.0476V10.9524L16.7352 13.7343L17.5238 12.409L13.6905 10.131V5.47619ZM13.1429 0C10.5286 0 8.02138 1.03852 6.1728 2.88709C4.32423 4.73566 3.28571 7.24287 3.28571 9.85714H0L4.33714 14.271L8.7619 9.85714H5.47619C5.47619 7.82382 6.28393 5.87377 7.72171 4.43599C9.15948 2.99821 11.1095 2.19048 13.1429 2.19048C15.1762 2.19048 17.1262 2.99821 18.564 4.43599C20.0018 5.87377 20.8095 7.82382 20.8095 9.85714C20.8095 11.8905 20.0018 13.8405 18.564 15.2783C17.1262 16.7161 15.1762 17.5238 13.1429 17.5238C11.029 17.5238 9.11238 16.6586 7.73238 15.2676L6.17714 16.8229C7.96238 18.619 10.4048 19.7143 13.1429 19.7143C15.7571 19.7143 18.2643 18.6758 20.1129 16.8272C21.9615 14.9786 23 12.4714 23 9.85714C23 7.24287 21.9615 4.73566 20.1129 2.88709C18.2643 1.03852 15.7571 0 13.1429 0Z" fill="#686868"/>
                                  </svg>
                                <span>{{ "Notifications" | translate }}</span>
                            </div>
                        </button>
                          
                        <button mat-menu-item (click)="openFiles(activeJob, '/')" *ngIf="company | perm:'see_files'">
                            <mat-icon>perm_media</mat-icon> 
                            <span>{{ "Files" | translate }}</span>
                          </button>
                          <button mat-menu-item (click)="openNotes(activeJob)">
                            <mat-icon>text_snippet</mat-icon>
                            <span>{{ "Notes" | translate }}</span>
                            </button>
                          <button mat-menu-item (click)="toShortCut($event, activeJob, 'job')">
                            <div class="d-f-c">
                              <svg width="18" style="margin-right: 16px;" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9 0L5.778 5.004L0 6.516L3.78 11.142L3.438 17.1L9 14.931L14.562 17.1L14.22 11.142L18 6.516L12.222 5.004L9 0ZM6.642 7.2C7.2 7.2 7.65 7.65 7.65 8.217C7.65 8.48434 7.5438 8.74073 7.35476 8.92976C7.16573 9.1188 6.90934 9.225 6.642 9.225C6.075 9.225 5.625 8.775 5.625 8.217C5.625 7.65 6.075 7.2 6.642 7.2ZM11.367 7.2C11.925 7.2 12.375 7.65 12.375 8.217C12.375 8.48434 12.2688 8.74073 12.0798 8.92976C11.8907 9.1188 11.6343 9.225 11.367 9.225C10.8 9.225 10.35 8.775 10.35 8.217C10.35 7.65 10.8 7.2 11.367 7.2ZM6.3 11.25H11.7C11.25 12.339 10.179 13.05 9 13.05C7.821 13.05 6.75 12.339 6.3 11.25Z" fill="#686868"/>
                              </svg>
                              <span>{{ "Shortcut" | translate }}</span>
                            </div>
                          </button>
                          <button mat-menu-item *ngIf="company?.permissions.includes('owner') || checkIsManager(task, company, user)" (click)="openAutomation(job)">
                              <mat-icon>auto_awesome_motion</mat-icon> 
                              <span>{{ "Scenarios" | translate }}</span>
                          </button>
                          <button mat-menu-item (click)="openChecklist(task, activeJob)">
                            <mat-icon>checklist</mat-icon> 
                            <span>{{ "Checklist" | translate }}</span>
                          </button>
                          <button mat-menu-item ngxClipboard [cbContent]="origin + '/job/' + activeJob?.acm" (cbOnSuccess)="copyLink('Job')">
                            <mat-icon>link</mat-icon> 
                            <span>{{ "Copy job url" | translate }}</span>
                          </button>
                          <button mat-menu-item (click)="migrateTargetFiles(task, activeJob)" *ngIf="!!user?.is_root">
                            <mat-icon>move_up</mat-icon> 
                            <span>{{ "Migrate all job files" | translate }}</span>
                        </button>
                          <button mat-menu-item (click)="createActiveChat()" *ngIf="company && company?.permissions?.includes('create_discussion')">
                            <mat-icon>add_comment</mat-icon> 
                            <span>{{ "Add chat" | translate }}</span>
                          </button>
                          <button mat-menu-item (click)="addToBoardJob(activeJob)">
                            <mat-icon>add</mat-icon> 
                            <span>{{ "Add to board" | translate }}</span>
                        </button>
                          <button *ngIf="company?.permissions.includes('owner') || company?.permissions.includes('admin') || company?.permissions.includes('manager') || company?.permissions.includes('assign_task_parameter')" mat-menu-item (click)="openTargetValues([task.id, activeJob.id, 0], activeJob)">
                            <mat-icon>settings_suggest</mat-icon> 
                            <span>{{ "Tags" | translate }}</span>
                          </button>
                          <ng-container *ngIf="company?.permissions.includes('owner') || checkIsManager(task, company, user); else elseLockTemplateDesk">
                            <button mat-menu-item (click)="editWork(task, activeJob)">
                              <mat-icon>edit</mat-icon> 
                              <span>{{ "Edit" | translate }}</span>
                            </button>
                            <button mat-menu-item (click)="openMobAssignments(task, activeJob)">
                                <mat-icon>group</mat-icon> 
                                <span>{{ "Executors & Clients" | translate }}</span>
                            </button>
                          </ng-container>
                          <ng-template #elseLockTemplateDesk>
                            <button mat-menu-item *ngIf="activeJob?.status?.is_lock == 0 && !company?.permissions.includes('client')" (click)="editEmloyeeWork(task, activeJob)">
                              <mat-icon>edit</mat-icon> 
                              <span>{{ "Edit" | translate }}</span>
                            </button>
                          </ng-template>
                          
              
                          
                          <ng-container *ngIf="company?.permissions.includes('owner') || checkIsManager(task, company, user)">
                            <button mat-menu-item (click)="moveWork(task, activeJob)">
                              <mat-icon style="transform: rotate(45deg);">zoom_out_map</mat-icon> 
                              <span>{{ "Move to card" | translate }}</span>
                            </button>
                            <button *ngIf="company?.permissions?.includes('owner') || company?.permissions?.includes('admin')" mat-menu-item (click)="deleteWork(task, activeJob)">
                              <mat-icon>delete</mat-icon> 
                              <span>{{ "Delete" | translate }}</span>
                            </button>
                
                          </ng-container>
                      </mat-menu>
    
                </div>
            </div>
        </div>
    </ng-template>
</mat-dialog-content>

<input 
hidden 
type="file" 
#uploader
multiple
(change)="uploadFile($event)"
/>
<input 
hidden 
type="file" 
webkitdirectory 
directory 
multiple
#uploaderFolder
(change)="uploadFolder($event)"
/>

<ng-template #outFileMenu let-folder>
	<section class="folder-menu">
        <div (click)="createFolder(folder)">
            <mat-icon class="mr-1">create_new_folder</mat-icon>
            {{ "Create folder" | translate }}
        </div>
        <!-- <div (click)="createDocument(folder)">
            <mat-icon class="mr-1">note_add</mat-icon>
            {{ "Create document" | translate }}
        </div>
        <div *ngIf="folder.id != -1" (click)="archive(true, folder)">
            <mat-icon class="mr-1">folder_zip</mat-icon>
            {{ 'Archive' | translate }}
        </div> -->

        <div (click)="paste(folder)" *ngIf="!!copiedFiles">
            <mat-icon class="mr-1">content_paste</mat-icon>
            {{ 'Paste' | translate }}
        </div>
        <!-- <div (click)="uploaderFolder.click()">
            <mat-icon class="mr-1">drive_folder_upload</mat-icon>
            {{ "Upload folder" | translate }}
        </div>
        <div (click)="uploader.click()">
            <mat-icon class="mr-1">upload</mat-icon>
            {{ "Upload file(s)" | translate }}
        </div> -->
        <div *ngIf="folder.id != -1" (click)="settings(folder)">
            <mat-icon class="mr-1">info</mat-icon>
            {{ 'Properties' | translate }}
        </div>
        <!-- <div (click)="toggleMode()">
            <ng-container *ngIf="is_list; else elseTemplateis_listCont">
                <mat-icon class="mr-1">reorder</mat-icon>
                {{ "List view" | translate }}
            </ng-container>
            <ng-template #elseTemplateis_listCont>
                <mat-icon class="mr-1">apps</mat-icon>
                {{ "Grid view" | translate }}
            </ng-template>
        </div> -->
	</section>
</ng-template>

<ng-template #fullTextMenu let-val>
	<section class="file-menu">
        <div [innerHTML]="val | linky:{newWindow: true}"></div>
	</section>
</ng-template>


<ng-template #chatMenu let-menuData>
	<section class="proj-file-menu">
        <div (click)="addToBoardChat(menuData?.chat); closeChatMenu();">
            <svg class="mr-1" style="width: 18px; height: 18px" width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.8 12L6 16.8H9.6V24H12V16.8H15.6L10.8 12ZM19.2 2.4H18V0H15.6V2.4H6V0H3.6V2.4H2.4C1.76348 2.4 1.15303 2.65286 0.702944 3.10294C0.252856 3.55303 0 4.16348 0 4.8V21.6C0 22.2365 0.252856 22.847 0.702944 23.2971C1.15303 23.7471 1.76348 24 2.4 24H7.2V21.6H2.4V8.4H19.2V21.6H14.4V24H19.2C19.8365 24 20.447 23.7471 20.8971 23.2971C21.3471 22.847 21.6 22.2365 21.6 21.6V4.8C21.6 4.16348 21.3471 3.55303 20.8971 3.10294C20.447 2.65286 19.8365 2.4 19.2 2.4Z" fill="#219653"/>
            </svg>
            {{ 'Add to board' | translate }}
        </div>

        <div (click)="openTargetValues([menuData?.chat?.task_id, menuData?.chat?.task_operation_id, menuData?.chat?.id], menuData?.chat)">
            <svg class="mr-1" width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg" style="width: 18px; height: 15px;"><path d="M3.15 4.5C3.50804 4.5 3.85142 4.35777 4.10459 4.10459C4.35777 3.85142 4.5 3.50804 4.5 3.15C4.5 2.79196 4.35777 2.44858 4.10459 2.19541C3.85142 1.94223 3.50804 1.8 3.15 1.8C2.79196 1.8 2.44858 1.94223 2.19541 2.19541C1.94223 2.44858 1.8 2.79196 1.8 3.15C1.8 3.50804 1.94223 3.85142 2.19541 4.10459C2.44858 4.35777 2.79196 4.5 3.15 4.5ZM13.869 6.822C14.193 7.146 14.4 7.596 14.4 8.1C14.4 8.595 14.202 9.045 13.869 9.369L9.369 13.869C9.045 14.193 8.595 14.4 8.1 14.4C7.605 14.4 7.155 14.202 6.822 13.869L0.531 7.578C0.198 7.245 0 6.795 0 6.3V1.8C0 0.801 0.801 0 1.8 0H6.3C6.795 0 7.245 0.198 7.569 0.522L13.869 6.822ZM10.386 1.539L11.286 0.639L17.469 6.822C17.802 7.146 18 7.605 18 8.1C18 8.595 17.802 9.045 17.478 9.369L12.636 14.211L11.736 13.311L16.875 8.1L10.386 1.539Z" fill="#686868"></path></svg>
            {{ "Tags" | translate }}
        </div>
        
        <div ngxClipboard [cbContent]="origin + '/chat/' + menuData?.chat?.acm" (cbOnSuccess)="copyLink('chat'); closeChatMenu();">
            <mat-icon class="mr-1">link</mat-icon> 
            {{ 'Copy url to chat' | translate }}
        </div>

        <a [href]="origin + '/chat/' + menuData?.chat?.acm" (click)="openInNew($event, menuData?.chat); closeChatMenu();">
            <mat-icon class="mr-1">open_in_new</mat-icon>
            {{ 'Open in new tab' | translate }}
        </a>
        <div (click)="openConnectionChats(menuData?.chat); closeChatMenu();">
            <mat-icon class="mr-1">
                <svg style="cursor: pointer;" class="chat_cons" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18 16.08C17.24 16.08 16.56 16.38 16.04 16.85L8.91 12.7C8.96 12.47 9 12.24 9 12C9 11.76 8.96 11.53 8.91 11.3L15.96 7.19C16.5 7.69 17.21 8 18 8C19.66 8 21 6.66 21 5C21 3.34 19.66 2 18 2C16.34 2 15 3.34 15 5C15 5.24 15.04 5.47 15.09 5.7L8.04 9.81C7.5 9.31 6.79 9 6 9C4.34 9 3 10.34 3 12C3 13.66 4.34 15 6 15C6.79 15 7.5 14.69 8.04 14.19L15.16 18.35C15.11 18.56 15.08 18.78 15.08 19C15.08 20.61 16.39 21.92 18 21.92C19.61 21.92 20.92 20.61 20.92 19C20.92 17.39 19.61 16.08 18 16.08Z" fill="#D7D7D7"/>
                    <circle *ngIf="chat?.partOfDiscussionPartition.length != 0" cx="6" cy="12" r="3" fill="#96A6C2"/>
                    <circle *ngIf="chat?.discussionRelated.length != 0" cx="18" cy="19" r="3" fill="#FFD0A7"/>
                    <circle *ngIf="chat?.consistOfDiscussionPartition.length != 0" cx="18" cy="5" r="3" fill="#98F1BD"/>
                  </svg>
            </mat-icon> 
            {{ "Chat connections" | translate }}
        </div>

        <div (click)="toPersonalStatuses(menuData?.chat); closeChatMenu();">
            <mat-icon class="mr-1">manage_accounts</mat-icon> 
            {{ 'Statuses' | translate }}
        </div>
	</section>
</ng-template>