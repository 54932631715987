import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, Inject, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { Player } from '@vime/angular';
import { forkJoin, fromEvent, interval, of, Subscription } from 'rxjs';
import { catchError, filter, last, map, switchMap, take, tap, toArray } from 'rxjs/operators';
import { CreateOperationChatComponent } from 'src/app/components/atTasksDialog/create-operation-chat/create-operation-chat.component';
import { OperationChatComponent } from 'src/app/components/atTasksDialog/operation-chat/operation-chat.component';
import { casesModel } from 'src/app/shared/functions/casesModel';
import { BaseClass } from 'src/app/shared/models/base-class';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { ChatService } from 'src/app/shared/services/rest/chat.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { FileService } from 'src/app/shared/services/rest/file.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { environment } from 'src/environments/environment';
import { NotificationsBar } from '../../../notifications-bar/notifications-bar';
import SwiperCore, { Mousewheel, Navigation } from 'swiper/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { prioritys } from 'src/app/shared/consts/prioritys';
import { ApproveJobComponent } from '../approve-job/approve-job.component';
import { RefreshService } from 'src/app/shared/services/rest/refresh.service';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { FileSettingsComponent } from '../file-settings/file-settings.component';
import { FilePrivacyComponent } from '../file-privacy/file-privacy.component';
import { SnackBarItem } from '../../../snack-bar/snack-bar-item';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { SameFilesComponent } from '../../../file-interface/dialogs/same-files/same-files.component';
import { el } from 'date-fns/locale';
import { PDFDocumentProxy, PDFProgressData, PdfViewerComponent } from 'ng2-pdf-viewer';
import { TargetParametersComponent } from 'src/app/components/atTasksDialog/target-parameters/target-parameters.component';
import { SelectionModel } from '@angular/cdk/collections';
import { SwiperComponent } from 'swiper/angular';
import { ParametersService } from 'src/app/shared/services/rest/parameters.service';
import { canTranscribe, transcribe } from 'src/app/shared/consts/transcribe.extensions';
import { FileSpeachToTextComponent } from '../file-speach-to-text/file-speach-to-text.component';
import { OpenJobComponent } from 'src/app/components/workspace-pages/cases/dialogs/open-job/open-job.component';
import { OpenTaskComponent } from 'src/app/components/workspace-pages/cases/dialogs/open-task/open-task.component';
import { PublishToOutletComponent } from '../publish-to-outlet/publish-to-outlet.component';
import * as moment from 'moment';
import { MoveToOtherJobComponent } from '../../../chat/dialogs/move-to-other-job/move-to-other-job.component';
import { MembersService } from 'src/app/shared/services/rest/members.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { GlobalDataService } from 'src/app/shared/services/common/global-data.service';

// install Swiper modules
SwiperCore.use([Navigation, Mousewheel]);

@Component({
  selector: 'app-video-viewer',
  templateUrl: './video-viewer.component.html',
  styleUrls: ['./video-viewer.component.scss']
})
export class VideoViewerComponent extends BaseClass implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("videoPlayerParrent") videoPlayerParrent: ElementRef;
  @ViewChild("videoPlayer") videoplayer: Player;
  @ViewChild('swiperRef', { static: false }) swiper?: SwiperComponent;
  public host: any = environment.host;
  public sub: Subscription;
  public chats: any;
  public color: any = "#FF0000";
  public strokeWidth: number = 3;
  public groupedChats: any;
  public filterType: any = 'all';
  public connectObj: any;
  public origin = window.location.origin;
  public chat_id: any;
  public selectedChat: any;
  public company_id: any;
  public videoWithotWorkspaceID: boolean = !!this.sm.localStorageGetItem('videoWithotWorkspaceID')
  public defaultPlayer: boolean = !!this.sm.localStorageGetItem('defaultPlayer')
  public company: any;
  public taskStatuses: any;
  public countSameFiles: number = 0;
  public employees: any;
  public prioritys = prioritys;
  public form: FormGroup;
  public imgRoute: any = '';
  public statuses: any;
  public timeZone = new Date().getTimezoneOffset();
  public menuState: boolean = false;
  public is_draw: boolean = false;
  public new_chat: boolean = false;
  public srcIsChanging: boolean = false;
  public isSubmit: boolean = false;
  public fileCollection: any;
  public is_mobile: boolean = false;
  public frame_is_active: boolean = false;
  public timeVal: number = 10;
  public videoSrc: any;
  public pdfSrc: any;
  public imgSrc: any;
  public fileChats: any[] = []

  public chatsFilter: FormGroup = this.fb.group({
    data_file_id: '',
    status_id: '',
    task_operation_id: this.data.work ? this.data.work.id : ''
  })

  // [
  //   {
  //     "id": 1,
  //     "name": "Waiting",
  //   },
  //   {
  //     "id": 6,
  //     "name": "Sources needed",
  //   },
  //   {
  //     "id": 2,
  //     "name": "In process",
  //   },
  //   {
  //     "id": 5,
  //     "name": "To correct",
  //   },
  //   {
  //     "id": 3,
  //     "name": "To approve",
  //   },
  //   {
  //     "id": 4,
  //     "name": "Approved",
  //   },
  //   {
  //     "id": 98,
  //     "name": "Ready",
  //   },
  //   {
  //     "id": 97,
  //     "name": "On hold",
  //   },
  //   {
  //     "id": 99,
  //     "name": "Canceled",
  //   }
  // ]

  public statusesPredict = {
    1: {
      arr: [3, 5, 6, 2]
    },
    2: {
      arr: [3, 1, 6, 5]
    },
    3: {
      arr: [5, 6, 2]
    },
    5: {
      arr: [3, 6, 2]
    },
    6: {
      arr: [1, 5, 2, 3]
    }
  }

  public vm_theme: string = !!this.sm.localStorageGetItem('vm_theme') ? this.sm.localStorageGetItem('vm_theme') : 'dark';
  public error: any;
  public groups: any;
  public profileStatuses: any;
  public count = 1;
  public page = 1;
  public rotation = 0;
  public zoom = 1.0;
  public zoomScale = 'page-fit';
  public originalSize = false;
  public pdf: any;
  public renderText = true;
  public progressData: PDFProgressData;
  public strokeOpen: boolean = false;
  public isLoaded = false;
  public stickToPage = true;
  public showAll = true;
  public autoresize = true;
  public fitToPage = false;
  public outline: any[];
  public today = moment().set({hour:0,minute:0,second:0}).unix();
  public todayEnd = moment().endOf('day').unix();
  public isOutlineShown = false;
  public pdfQuery = '';
  public mobile = false;
  @ViewChild('relationsVMenu') relationsVMenu: TemplateRef<any>;
  public relationsSub: Subscription;
  public is_add_data = true;
  public selectedChats = new SelectionModel(
    true,
    []
  );

  @ViewChild(PdfViewerComponent)
  private pdfComponent: PdfViewerComponent;
  public transcribe: any = transcribe;
  public canTranscribe: Function = canTranscribe;
  public fileLocationString: string;

  public folders = [
    {
      name: "Sources",
      id: 0,
      location: "/sources",
      over: false,
      rootDir: true
    },
    {
      name: "To Approve",
      id: 1,
      location: "/to_approve",
      over: false,
      rootDir: true
    },
    {
      name: "Ready Files",
      id: 2,
      location: "/ready_files",
      over: false,
      rootDir: true
    },
    {
      name: "Approved",
      id: 3,
      location: "/approved",
      over: false,
      rootDir: true
    },
    {
      name: "Project",
      id: 4,
      location: "/project",
      over: false,
      rootDir: true
    },
    {
      name: "Trash",
      id: 5,
      location: "/trash",
      over: false,
      rootDir: true
    }
  ]
 
  overlayRef: OverlayRef | null;
  @ViewChild('taskMenu') taskMenu: TemplateRef<any>;
  overlayRelations: OverlayRef | null;
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<VideoViewerComponent>,
    private fileService: FileService,
    private taskService: TaskService,
    private activatedRoute: ActivatedRoute,
    private globalDataService: GlobalDataService,
    private companyService: CompanyService,
    private membersService: MembersService,
    private refreshService: RefreshService,
    private auth: AuthService,
    private parametersService: ParametersService,
    private cd: ChangeDetectorRef,
    private chatService: ChatService,
    private layoutService: LayoutService,
    private languageService: LanguageService,
    private sm: StorageManagerService,
    private dialog: MatDialog,
    private _snackBar: MatSnackBar,
    public viewContainerRef: ViewContainerRef,
    public overlay: Overlay,
    private fb: FormBuilder
  ) { super() }

  ngOnInit(): void {
    console.log("VideoViewerComponent data", this.data);
    console.log("VideoViewerComponent dialogRef", this.dialogRef);
    this.data.dialogHasChat = true;
    this.company_id = this.activatedRoute.snapshot.queryParamMap.get('company_id');
    this.fileCollection = new SelectionModel(
      true,
      []
    )
    if (!this.data.user) {
      this.getUser();
    }
    if (this.data.initCompanyId) {
      this.company_id = this.data.initCompanyId
    }
    if (this.data.initCompanyID) {
      this.company_id = this.data.initCompanyID
    }
    console.log(this.data.file.location)
    this.fileLocationString = this.data.file.location
    console.log(this.fileLocationString)
    if (!!this.fileLocationString) {
      if (this.data.file.location == '/') {
        this.fileLocationString = "Root"
      } else {
        this.folders.forEach(el => {
          this.fileLocationString = (this.fileLocationString as any).replaceAll(el.location, el.name)
          console.log(this.fileLocationString);
        })
        this.fileLocationString = (this.fileLocationString as any).replaceAll('/', " / ")
      }
    }
    
    this.setSrcs()
    this.checkIsMobile();
    this.getImgRoute();
    if (!this.data.company) {
      this.getCompany();
    } else {
      this.companyService.company$.next(this.data.company);
      this.company = this.data.company
    }
   
    this.getCopies();
    if (this.data.task) {
      this.getTaskStatus();
      this.getEmployees();
      if (!this.data.task.hasOwnProperty('uniqueChannels')) {
        let expPermFilter:any = {task_id: this.data.file.task_id}
        if (this.company_id != this.data.file.company_id) {
          expPermFilter.partner_company_id = this.data.file.company_id
        }
        this.attachSubscriptions(
          this.taskService.getExpandPermUrls('1', this.company_id, expPermFilter, '100').subscribe(result => {
            this.data.task.uniqueChannels = [];
            result.sort((a, b) => {
              if (a.content_status_id === 13 && b.content_status_id !== 13) {
                return -1;
              } else if (a.content_status_id === 1 && b.content_status_id !== 13 && b.content_status_id !== 1) {
                return -1;
              } else if (b.content_status_id === 13 || b.content_status_id === 1) {
                return 1;
              } else {
                return 0;
              }
            });
            result.forEach(channel => {
              if (channel.channel && [1,2,3,4].includes(channel.channel.platform_id) && !!channel.channel.channel_account_id) {
                if (this.data.task.uniqueChannels.filter(ch => ch.channel_id == channel.channel_id).length == 0) {
                  this.data.task.uniqueChannels.push(channel)
                } else {
                  if (!([1, 13].includes(this.data.task.uniqueChannels.find(ch => ch.channel_id == channel.channel_id).content_status_id) && !this.data.task.uniqueChannels.find(ch => ch.channel_id == channel.channel_id).content_url)) {
                    this.data.task.uniqueChannels.splice(this.data.task.uniqueChannels.indexOf(this.data.task.uniqueChannels.find(ch => ch.channel_id == channel.channel_id)), 1, channel)
                  }
                }
              }
            });
          })
        )

      }
    }
    if (this.data.work) {
      // this.getChats()
      this.getChatStatuses()
    }

    if (this.data.isTaskCard) {
      this.getPlaylist();
    }

    if (!!this.data.chat_id) {
      this.getChatPlaylist();
    }
    
    if (!this.data.activeLang || !this.data.operationsValues) {
      this.getLangData()
    }
    this.getGroupsCompany()
    this.getProfilesStatus()
    
  }

  getUser() {
    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        this.data.user = resp;
      })
    )
  }

  onMouseDown(event: MouseEvent) {
    event.preventDefault();
    this.data.file.grabData.dragging = true;
    this.data.file.grabData.startX = event.clientX - this.data.file.grabData.offsetX;
    this.data.file.grabData.startY = event.clientY - this.data.file.grabData.offsetY;
  }

  onMouseMove(event: MouseEvent) {
    if (this.data.file.grabData.dragging) {
      this.data.file.grabData.offsetX = event.clientX - this.data.file.grabData.startX;
      this.data.file.grabData.offsetY = event.clientY - this.data.file.grabData.startY;
      this.data.file.translate = `translate(${this.data.file.grabData.offsetX}px, ${this.data.file.grabData.offsetY}px)`
    }
  }

  onMouseUp(event: MouseEvent) {
    if (this.data.file.grabData.dragging) {
      this.data.file.grabData.dragging = false;
      this.data.file.grabData.startX = this.data.file.grabData.offsetX;
      this.data.file.grabData.startY = this.data.file.grabData.offsetY;
    }
  }

  @HostListener('document:mousemove', ['$event'])
  hostMouseMove(event: MouseEvent) {
    this.onMouseMove(event);
  }

  @HostListener('document:mouseup', ['$event'])
  hostMouseUp(event: MouseEvent) {
    this.onMouseUp(event);
  }

  openChatStat(e, chat) {
    e.preventDefault();
    e.stopPropagation();

    chat.open_stat = !chat.open_stat;
  }

  leaveChat(chat) {
    chat.open_stat = false;
  }

  changeStat(e, chat, arr?) {
    e.preventDefault();
    e.stopPropagation();
    chat.statusChanged = true;
    this.attachSubscriptions(
      this.membersService.editTaskEmployeeStatus(chat.employeeStatus.id, {status_id: !!chat.selectedStatus ? chat.selectedStatus : this.statusesPredict[chat.employeeStatus.status_id].arr[0]}, this.company_id).subscribe(resp => {
        console.log("changeStat", resp);
        this.updateChat(chat, arr)
      }, error => {
        chat.statusChanged = false;
      })
    )
  }

  updateChat(chat, arr?) {
    this.attachSubscriptions(
      this.chatService.getChats(this.data.company.id, this.data.work.task_id, {id: chat.id}).subscribe(resp => {
        if (resp && resp.length) {
          if (arr) {
            arr.splice(arr.findIndex(x => x.id == chat.id), 1, resp[0])
          } else {
            this.chats.splice(this.chats.findIndex(x => x.id == chat.id), 1, resp[0])
          }
        }
      })
    )
  }

  selectStat(e, id, chat) {
    e.preventDefault();
    e.stopPropagation();
    chat.selectedStatus = id
  }

  toggleFrame() {
    this.frame_is_active = !this.frame_is_active;
  }

  changeTheme(val) {
    this.vm_theme = val;
    this.sm.localStorageSetItem('vm_theme', this.vm_theme);
    console.log("this.videoplayer", (this.videoplayer as any).el)
    if (this.vm_theme == 'dark') {
      (this.videoplayer as any).el.style.setProperty('--vm-player-bg', `#000`);
      (this.videoplayer as any).el.style.setProperty('--vm-icon-fill', `#fff`);
      (this.videoplayer as any).el.style.setProperty('--vm-icon-stroke', `#fff`);
      (this.videoplayer as any).el.style.setProperty('--vm-time-color', `#fff`);
      (this.videoplayer as any).el.style.setProperty('--vm-slider-value-color', `#fff`);
      // document.documentElement.style.setProperty('--vm-player-bg', `#000`);
    } else {
      (this.videoplayer as any).el.style.setProperty('--vm-player-bg', `#fff`);
      (this.videoplayer as any).el.style.setProperty('--vm-icon-fill', `#000`);
      (this.videoplayer as any).el.style.setProperty('--vm-icon-stroke', `#000`);
      (this.videoplayer as any).el.style.setProperty('--vm-time-color', `#000`);
      (this.videoplayer as any).el.style.setProperty('--vm-slider-value-color', `#000`);
      // document.documentElement.style.setProperty('--vm-player-bg', `#fff`);
    }
    // --vm-player-bg
  }

  getTags(file) {
    let tagsFilter:any = {task_id: file.task_id, task_operation_id: file.task_operation_id ? file.task_operation_id : 0, discussion_id: file.discussion_id ? file.discussion_id : 0, file_id: file.id}

    if (file.company_id != this.data.company.id) {
      tagsFilter.partner_company_id = file.company_id
    }
    this.attachSubscriptions(
      this.parametersService.getTargetParameters(this.data.company.id, tagsFilter).subscribe(resp => {
        file.tags = resp;
        console.log("getTags", file.tags)
      })
    )
  }

  transcribeFile(file) {
    this.close();
    const dialogRef = this.dialog.open(FileSpeachToTextComponent, {
      backdropClass: ['speech_file_backdrop'],
      panelClass: ['speech_file_pane'],
      data: {
        company: this.company,
        file: file,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result)
    })
  }

  // toNextChat() {
  //   console.log("toNextChat")
  // }

  setSrcs() {
    if (this.data.file.taskChannelFiles) {
      this.data.file.filteredTaskChannelFiles = this.data.file.taskChannelFiles.filter(k => k.content_status_id != 8)
    }
    this.color = "#FF0000";
    this.strokeOpen = false;
    this.chatsFilter.patchValue({
      status_id: ''
    })
    if (!!this.chatsFilter.value.data_file_id) {
      this.chatsFilter.patchValue({
        data_file_id: this.data.file.id
      })
    }
    if (this.data.file.filesize <= 3000000) {
      this.imgSrc = this.data?.file?.original;
    } else {
      this.imgSrc = this.data?.file?.thumbnail ? this.data?.file?.thumbnail : this.data?.file?.original;
    }
    this.videoSrc = this.data?.file?.preview1080 ? this.data?.file?.preview1080 : this.data?.file?.original;

    this.data.file.grabData = {
      dragging: false,
      startX: 0,
      startY: 0,
      offsetX: 0,
      offsetY: 0,
    }
    if (!this.data.file.hasOwnProperty('zoom')) {
      this.data.file.zoom = 1;
    }


    if (this.data?.file.content_type == 'application/pdf') {

      if (!this.data.file.hasOwnProperty('zoomScale')) {
        this.data.file.zoomScale = 'page-fit';
      }
      if (this.progressData) {
        this.progressData['percent'] = 0;
        this.progressData.loaded = 0;
        this.isLoaded = false;
      }
      this.attachSubscriptions(
        this.fileService.getPdfSrc(this.data.file, this.company_id).subscribe((resp:any) => {
          console.log("getPdfSrc", resp)
          if (resp.download_url) {
            this.pdfSrc = resp.download_url
          } else {
            this.pdfSrc = ''
          }
        })
      )
    }

    // if (this.chats && !this.chatsFilter.value.data_file_id) {
    //   this.data.file.points = []
    //   if (this.chats.filter(x => x.data && x.data.original_file_id == this.data.file.original_file_id).length > 0) {
        
    //     this.chats.filter(x => !!x.data && x.data.original_file_id == this.data.file.original_file_id).forEach(chat => {
    //       if (chat.data.file_timestamps && !!chat.data.file_timestamps.length && chat.data.file_timestamps[0].type == 'currentTime' && this.data.file.points.filter(x => x.chat_id == chat.id).length == 0) {
    //         this.data.file.points.push({
    //           chat_id: chat.id,
    //           chat: chat,
    //           status_id: chat.status_id,
    //           currentTime: chat.data.file_timestamps[0].currentTime
    //         })
    //       }
    //     });
    //   }
    // } else {
    //   this.getChats();
    // }

    if (this.data.work) {
      this.getChats()
    }



    this.getTags(this.data.file)
  }

  log() {
    console.log(this.data.file)
    console.log(this.chats)
    console.log(this.chat_id)
  }


   // Load pdf
   loadPdf() {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', '/assets/pdf-test.pdf', true);
    xhr.responseType = 'blob';

    xhr.onload = (e: any) => {
      console.log(xhr);
      if (xhr.status === 200) {
        const blob = new Blob([xhr.response], { type: 'application/pdf' });
        this.pdfSrc = URL.createObjectURL(blob);
      }
    };

    xhr.send();
  }

  /**
   * Set custom path to pdf worker
   */


  incrementPage(amount: number) {
    this.page += amount;
  }

  noZoom() {
    console.log("noZoom")
    this.data.file.zoom = 1;
    this.data.file.grabData = {
      dragging: false,
      startX: 0,
      startY: 0,
      offsetX: 0,
      offsetY: 0,
    }
    this.data.file.translate = undefined;
  }

  incrementZoom(amount: number) {

    console.log("incrementZoom", amount)
    if (!this.data.file.hasOwnProperty('zoom')) {
      this.data.file.zoom = 1;
    }
    this.data.file.zoom += amount;
  }

  rotate(angle: number) {
    this.rotation += angle;
  }

  /**
   * Render PDF preview on selecting file
   */
  onFileSelected() {
    const $pdf: any = document.querySelector('#file');

    if (typeof FileReader !== 'undefined') {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        this.pdfSrc = e.target.result;
      };

      reader.readAsArrayBuffer($pdf.files[0]);
    }
  }

  /**
   * Get pdf information after it's loaded
   * @param pdf pdf document proxy
   */
  afterLoadComplete(pdf: PDFDocumentProxy) {
    this.pdf = pdf;

    this.loadOutline();
  }

  /**
   * Get outline
   */
  loadOutline() {
    this.pdf.getOutline().then((outline: any[]) => {
      this.outline = outline;
    });
  }

  /**
   * Handle error callback
   *
   * @param error error message
   */
  onError(error: any) {
    this.error = error; // set error

    if (error.name === 'PasswordException') {
      const password = prompt(
        'This document is password protected. Enter the password:'
      );

      if (password) {
        this.error = null;
        this.setPassword(password);
      }
    }
  }

  setPassword(password: string) {
    let newSrc;

    if (this.pdfSrc instanceof ArrayBuffer) {
      newSrc = { data: this.pdfSrc };
    } else if (typeof this.pdfSrc === 'string') {
      newSrc = { url: this.pdfSrc };
    } else {
      newSrc = { ...this.pdfSrc };
    }

    newSrc.password = password;

    this.pdfSrc = newSrc;
  }

  /**
   * Pdf loading progress callback
   * @param progressData pdf progress data
   */
  onProgress(progressData: PDFProgressData) {
    console.log(progressData);
    this.progressData = Object.assign(progressData, {
      percent: Math.round((progressData.loaded/progressData.total)*100)
    });

    this.isLoaded = progressData.loaded >= progressData.total;
    this.error = null; // clear error
  }

  getInt(value: number): number {
    return Math.round(value);
  }

  /**
   * Navigate to destination
   * @param destination pdf navigate to
   */
  navigateTo(destination: any) {
    this.pdfComponent.pdfLinkService.goToDestination(destination);
  }

  /**
   * Scroll view
   */
  scrollToPage() {
    this.pdfComponent.pdfViewer.scrollPageIntoView({
      pageNumber: 3
    });
  }

  /**
   * Page rendered callback, which is called when a page is rendered (called multiple times)
   *
   * @param e custom event
   */
  pageRendered(e: CustomEvent) {
    console.log('(page-rendered)', e);
  }

  /**
   * Page initialized callback.
   *
   * @param {CustomEvent} e
   */
  pageInitialized(e: CustomEvent) {
    console.log('(page-initialized)', e);
  }

  /**
   * Page change callback, which is called when a page is changed (called multiple times)
   *
   * @param e number
   */
  pageChange(e: number) {
    console.log('(page-change)', e);
  }

  searchQueryChanged(newQuery: string) {
    const type = newQuery !== this.pdfQuery ? '' : 'again';
    this.pdfQuery = newQuery;

    // this.pdfComponent.eventBus.dispatch('find', {
    //   type,
    //   query: this.pdfQuery,
    //   highlightAll: true,
    //   caseSensitive: false,
    //   phraseSearch: true,
    //   // findPrevious: undefined,
    // });
  }

  changeTime(val) {
    this.timeVal = val
  }

  changeVideoTime(type) {
    if (type == 'replay') {
      this.videoplayer.currentTime = this.videoplayer.currentTime - this.timeVal 
    } else {
      this.videoplayer.currentTime = this.videoplayer.currentTime + this.timeVal 
    }
  }

  onRemove(file, tag, ind) {
    this.attachSubscriptions(
      this.parametersService.deleteTargetParameter(tag.id).subscribe(resp => {
        file.tags.splice(ind, 1)
      },
      error => {
        this.showError(error)
      })
    )
  }

  showError(error) {
    this.layoutService.showSnackBar({name: error}, marker("You do not have permission to do this"), SnackBarItem)
  }

  openTargetValues(info, element) {
    this.close()
    console.log("this.selectedFiles", this.fileCollection.selected);

    let initData:any = {
      company: this.company,
      task: element,
    }

    if (this.fileCollection.selected.length > 1) {
      let arr = [];
      this.fileCollection.selected.forEach(id => {
        if (this.data.playlist.find(x => x.id == id)) {
          arr.push(this.data.playlist.find(x => x.id == id))
        }
      });
      
      initData.selectedFiles = arr
    }

    initData.target = {
      task_id: info[0],
      task_operation_id: info[1],
      discussion_id: info[2],
      file_id: info[3]
    }
    if (element.company_id != this.company.id) {
      initData.target.partner_company_id = element.company_id
    }
    const dialogRef = this.dialog.open(TargetParametersComponent, {
      backdropClass: ['parameters_modal_backdrop'],
      panelClass: ['without_paddings_modal', 'parameters_modal'],
      data: initData
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (this.fileCollection.selected.length > 0) {
          this.fileCollection.clear()
        }
        this.getTags(this.data.file)
      })
    )
  }

  removeFromBox(file) {
    this.data.boxFiles.deselect(file)
  }
  
  addToBox(file) {
    this.data.boxFiles.select(file)
  }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData()
      .pipe(
        tap(val => {
          this.data.activeLang = val.active;
        }),
        switchMap(res => {
          return this.taskService.getOperations(this.company_id, this.data.activeLang)
          .pipe(
            tap(x => {
              this.data.operationsValues = x;
            })
          )
        })
      ).subscribe(resp => {
        console.log("getLangData", this.data)
      })
    )
  }

  copy(file) {
    this.close();
    let copyData = [];

      copyData.push({
        company_id: file.company_id,
        id: file.id,
        taskChannelFiles: file.taskChannelFiles
      })

    this.sm.localStorageSetItem('copiedFiles', copyData);
    this.layoutService.showSnackBar({name: file.filename}, marker("Copied"), SnackBarItem)
  }

  settings() {
    this.close();
    if (this.data.file) {
      const dialogRef = this.dialog.open(FileSettingsComponent, {
        data: {
          company_id: this.company_id,
          company: this.company,
          file: this.data.file,
          location: this.data.file.location,
          task: this.data.task || this.data.file.task,
          operationsValues: this.data.operationsValues,
          user: this.data.user
        }
      });
    }
  }

  onSrcChange(e) {
    console.log("onSrcChange", e);
    if (this.videoplayer) {
      if (this.vm_theme == 'dark') {
        (this.videoplayer as any).el.style.setProperty('--vm-player-bg', `#000`);
        (this.videoplayer as any).el.style.setProperty('--vm-icon-fill', `#fff`);
        (this.videoplayer as any).el.style.setProperty('--vm-icon-stroke', `#fff`);
        (this.videoplayer as any).el.style.setProperty('--vm-time-color', `#fff`);
        (this.videoplayer as any).el.style.setProperty('--vm-slider-value-color', `#fff`);
      } else {
        (this.videoplayer as any).el.style.setProperty('--vm-player-bg', `#fff`);
        (this.videoplayer as any).el.style.setProperty('--vm-icon-fill', `#000`);
        (this.videoplayer as any).el.style.setProperty('--vm-icon-stroke', `#000`);
        (this.videoplayer as any).el.style.setProperty('--vm-time-color', `#000`);
        (this.videoplayer as any).el.style.setProperty('--vm-slider-value-color', `#000`);
      }
    } 
  }

  changeSrc(type, isImg:boolean = false) {
    this.srcIsChanging = true;

    if (isImg) {
      if (type == 'lite') {
        this.imgSrc = this.data.file.thumbnail
      } else {
        this.imgSrc = this.data.file.original
      }
    } else {
      let saveTime = this.videoplayer.currentTime;
      if (type == 'lite') {
        this.videoSrc = this.data.file.preview1080
      } else {
        this.videoSrc = this.data.file.original
      }
  
      setTimeout(() => {
        this.srcIsChanging = false;
        this.startVideo(saveTime);
      }, 0)
    }
  }

  // changeSrc(type, isImg:boolean = false) {
  //   this.srcIsChanging = true;

  //   if (isImg) {
  //     if (type == 'lite') {
  //       this.imgSrc = this.data.file.thumbnail
  //     } else {
  //       this.imgSrc = this.data.file.original
  //     }
  //   } else {
  //     let saveTime = this.videoplayer.currentTime;
  //     let isPlaying =  !this.videoplayer.paused;
  //     if (type == 'lite') {
  //       this.videoSrc = this.data.file.preview1080
  //     } else {
  //       this.videoSrc = this.data.file.original
  //     }
  
  //     setTimeout(() => {
  //       this.srcIsChanging = false;
  //       this.startVideo(saveTime, isPlaying);
  //     }, 0)
  //   }
  // }
  // startVideo(time?, is_play:boolean = false) {
  //   console.log("startVid", time, is_play);
  //   if (this.sub) {
  //     this.sub.unsubscribe()
  //   }

  //   this.sub = interval(100).pipe(
  //     filter(() => !!this.videoplayer)
  //   ).subscribe(res => {
  //     if (this.data.work) {
  //       document.getElementById('chats_h_wrp').style.maxHeight = this.videoPlayerParrent.nativeElement.offsetHeight + (this.data.playlist && this.data.playlist.length > 1 ? 52 : 0) + "px"
  //     }
  //     if (this.videoplayer.playbackReady) {
  //       if (this.data.work) {
  //         document.getElementById('chats_h_wrp').style.maxHeight = this.videoPlayerParrent.nativeElement.offsetHeight + (this.data.playlist && this.data.playlist.length > 1 ? 52 : 0) + "px"
  //       }
  //       if (!!time) {
  //         this.videoplayer.currentTime = time
  //       }
  //       if (!!is_play) {
  //         this.videoplayer.play()
  //       }
  //       this.sub.unsubscribe()
  //     }
  //   })
  // }

  getGroup(id) {
    if (!this.groups || !this.groups.find(el => el.id == id)) {
      return false;
    }
    return this.groups.find(el => el.id == id)
  }  

  getGroupsCompany() {
    this.attachSubscriptions(
      this.companyService.getInfiniteGroupsCompany(this.company_id, '1', '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.companyService.getInfiniteGroupsCompany(this.company_id, x).pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.groups = [].concat(...res);
      })
    )
  }

  closeRelations() {
    this.relationsSub && this.relationsSub.unsubscribe();
    if (this.overlayRelations) {
      this.overlayRelations.dispose();
      this.overlayRelations = null;
    }
  }  

  openRelations({ x, y }: MouseEvent, val, key) {
    this.closeRelations();
    val.openRelations = key;
    let filterRel;
    if (key == 'part_of') {
      filterRel = {consist_of_file_id: val.original_file_id};
    } else {
      filterRel = {file_id: val.original_file_id};
    }
    this.attachSubscriptions(
      this.fileService.getFilePartition("1", this.company ? this.company.id : this.company_id, filterRel).subscribe(resp => {
        val[key] = resp;
        console.log("get " + key, val);
      
        const positionStrategy = this.overlay.position()
        .flexibleConnectedTo({ x, y })
        .withPositions([
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top',
          }
        ]);
    
    
        this.overlayRelations = this.overlay.create({
          backdropClass: 'transparent_bg',
          hasBackdrop: true,
          positionStrategy,
          scrollStrategy: this.overlay.scrollStrategies.close()
        });
    
        this.overlayRelations.attach(new TemplatePortal(this.relationsVMenu, this.viewContainerRef, {
          $implicit: val
        }));
      
      })
    )

    this.relationsSub = fromEvent<MouseEvent>(document, 'click')
    .pipe(
      filter(event => {
        const clickTarget = event.target as HTMLElement;
      
        let check1 = !!this.overlayRelations && !this.overlayRelations.overlayElement.contains(clickTarget)
        return check1
      }),
      take(1)
    ).subscribe(() => this.closeRelations())
  }

  getProfilesStatus() {
    this.attachSubscriptions(
      this.globalDataService.getContentStatus().subscribe(resp => {
        this.profileStatuses = resp.slice();
        console.log("profileStatuses", this.profileStatuses)
      })
    )
  }

  getProfileStatus(id) {
    if (!this.profileStatuses) {
      return false;
    }
    return this.profileStatuses.find(el => el.id == id)
  } 

  checkIsManager(task, company, _user) { 
    return (!!task && ((!!task.managers && task.managers.filter(x => x.user_id == _user.id).length > 0) || (!!task.group && !!task.group.managers && task.group.managers.filter(x => x.user_id == _user.id).length > 0))) || (!!company && (company.permissions.includes('admin') || company.permissions.includes('manager'))); 
  }

  fileLocation(file) {
    return file.location.indexOf("/to_approve") != -1
  }

  getCopies() {
    this.attachSubscriptions(
      this.fileService.getFileData("1", this.company_id, {'location_all': "/", task_id: 0, original_file_id : this.data.file.original_file_id || this.data.file.id}).pipe(
        tap(el => {
          this.data.file.countSameFiles = el.headers.get('x-pagination-total-count');
          this.data.file.sameFiles = el.body;
          this.fileChats = [];
          el.body.forEach(item => {
            if (item.discussion && this.fileChats.filter(x => x.id == item.discussion.id).length == 0) {
              this.fileChats.push(item.discussion)
            }
          });
          console.log("this.fileChats", this.fileChats);

          if (this.data.file.original_file_id) {
            // if (this.data.file.countSameFiles > 0) {
              // }
              
              if (this.data.file.sameFiles.find(x => x.id == this.data.file.id)) {
                this.data.file.countSameFiles = this.data.file.countSameFiles - 1
                this.data.file.sameFiles.splice(this.data.file.sameFiles.indexOf(this.data.file.sameFiles.find(x => x.id == this.data.file.id)),1)
            }
          }
        })
      ).subscribe(resp => {
        console.log(this.data.file)
      })
    )
  }

  openSameFiles() {
    const dialogRef = this.dialog.open(SameFilesComponent, {
      data: {
        company_id: this.company_id,
        file: this.data.file,
        no_btns: true
      }
    });
  }
  
  approveFile(file) {
    this.attachSubscriptions(
      this.fileService.editFile(file.id, {
        location: !!file.is_ready_if_approved ? '/ready_files' : '/approved'
      }, this.data.company.id).subscribe(res => {
        this.fileService
        this.layoutService.showSnackBar({name: 'File: ' + file.filename}, "Approved", SnackBarItem)
        this.data.files.splice(this.data.files.indexOf(this.data.files.find(t => t.id == file.id)), 1);
        if (this.data.work.hasOwnProperty("filesCount")) {
          this.data.work.filesCount = this.data.work.filesCount - 1;
        }
        this.detectFiles();
        console.log(res)
      })
    )
  }

  rejectFile(file) {
    this.attachSubscriptions(
      this.fileService.editFile(file.id, {
        location: '/reject'
      }, this.company.id).subscribe(res => {
        this.fileService.checkRejectFolder$.next(true);
        this.layoutService.showSnackBar({name: 'File: ' + file.filename}, "Rejected", SnackBarItem)
        this.data.files.splice(this.data.files.indexOf(this.data.files.find(t => t.id == file.id)), 1);
        if (this.data.work.hasOwnProperty("filesCount")) {
          this.data.work.filesCount = this.data.work.filesCount - 1;
        }
        this.detectFiles();
        console.log(res)
      })
    )
  }

  privacy(file) {
    this.close();
    console.log(file);

    const dialogRef = this.dialog.open(FilePrivacyComponent, {
      data: {
        file: file,
        company: this.company
      }
    });
    
    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(resp => [
        console.log(resp)
      ])
    )
  }

  detectFiles() {
    let work = this.data.work;
    console.log("detectFiles work", work)

    if (!work || work.status_id != 3 || !(this.data.company.permissions.includes("owner") || this.checkIsManager(this.data.task, this.data.company, this.data.user))) {
      return
    }
    
    console.log("TRUE");

    this.fileService.detectFiles("/to_approve", "0", this.data.company.id, this.data.work.id).pipe(
      map(x => !!x.length),
      filter(x => !x),
      switchMap(() => forkJoin([
        this.fileService.detectFiles("/approved", "0", this.data.company.id, this.data.work.id).pipe(map(b => !!b.length)),
        this.fileService.detectFiles("/ready_files", "0", this.data.company.id, this.data.work.id).pipe(map(b => !!b.length)),
      ])),
      tap(vals => console.log("vals", vals)),
      map(vals => vals.filter(x => x == true).length > 0 ? true : false)
    ).subscribe(resp => {
      console.log("detectFiles result", resp);
      if (resp) {
        const dialogRef = this.dialog.open(ApproveJobComponent, {
          data: {
            task: this.data.task,
            work: work,
            company: this.data.company
          }
        });
        
        this.attachSubscriptions(
          dialogRef.afterClosed().subscribe(result => {
            if (result.event && result.event == "yes") {
              this.refreshService.refreshTask$.next({company_id: this.company_id, task_id: this.data.task.id, fromFileManager: true})
              this.refreshService.refreshJob$.next({company_id: this.company_id, job_id: work.id})
            }
            this.dialogRef.close({event: "update", data: false})
          })
        )
      } else {
        this.dialogRef.close({event: "update", data: false})
      }
    })

    // this.fileService.detectFiles("/to_approve", "0", this.company_id, this.data.work.id).pipe(
    //   map(x => !!x.length),
    //   switchMap(x => {
    //     if (!x) {
    //       return this.fileService.detectFiles("/approved", "0", this.company_id, this.data.work.id).pipe(map(b => !!b.length))
    //     } else {
    //       return of(false)
    //     }
    //   }),
    // ).subscribe(resp => {

    // })
  }


  getChatPlaylist() {
    this.attachSubscriptions(
      this.fileService.getFileData(1, this.company_id, {task_id: this.data.task.id, task_operation_id: this.data.work.id, discussion_id: this.data.chat_id, location_all: '/', order_by: 'created_at'}, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 100)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.fileService.getFileData(x, this.company_id, {task_id: this.data.task.id, task_operation_id: this.data.work.id, discussion_id: this.data.chat_id, location_all: '/', order_by: 'created_at'}).pipe(map(u => u.body)))).pipe(
            last(),
            tap(values => {
              this.data.playlist = [].concat(...values)
            })
          )
        })
      ).subscribe(resp => {
        console.log("getChatPlaylist", this.data.playlist)
        setTimeout(() => {
          if (this.data.playlist.findIndex(x => x.id == this.data.file.id) && this.data.playlist.findIndex(x => x.id == this.data.file.id) > 15) {
            console.log("this.swiper", this.swiper, this.data.playlist.findIndex(x => x.id == this.data.file.id))
            let translateValue = "-" + ((this.data.playlist.findIndex(x => x.id == this.data.file.id) * 62) - this.swiper.swiperRef.wrapperEl.offsetWidth + 62) + 'px'
            this.swiper.swiperRef.wrapperEl.style.transform = `translate3d(${translateValue}, 0, 0)`
          }
        }, 1000)
        // transform: translate3d(-34534px, 0px, 0px);
        // setTimeout(() => this.swiper.swiperRef.updateSize(), 2000)
        // setTimeout(() => {
        //   console.log("this.swiper", this.swiper, this.data.playlist.findIndex(x => x.id == this.data.file.id))
        //   if (this.swiper && this.swiper.swiperRef && this.data.playlist && this.data.file) {
        //     const index = this.data.playlist.findIndex(x => x.id == this.data.file.id);
        //     if (index !== -1) { // Проверяем, что элемент найден в плейлисте
        //       this.swiper.swiperRef.updateSize();
        //       this.swiper.swiperRef.slideToLoop(index, 0);
        //     }
        //   }
        // }, 4000)
      })
    // this.attachSubscriptions(
    //   this.fileService.getFileData(1, this.company_id, {task_id: this.data.task.id, task_operation_id: this.data.work.id, discussion_id: this.data.chat_id, location_all: '/'}).pipe(
    //     map(x => x.body)
    //   ).subscribe(resp => {
    //     this.data.playlist = resp.filter(x => (x.is_uploaded || !!x.is_document) && (!!x.is_document || (x.content_type.indexOf("image") != -1 || x.content_type.indexOf("video") != -1 || x.content_type.indexOf("audio") != -1) || x.content_type == 'application/pdf' || !!x.thumbnail));
    //     console.log("getPlaylist", this.data.playlist)
    //   })
    )
  }

  getPlaylist() {
    this.attachSubscriptions(
      this.fileService.getFileData(1, this.company_id, {task_id: this.data.task.id, task_operation_id: this.data.work.id, location: this.data.work.status_id == 3 ? '/to_approve' : '/ready_files'}).pipe(
        map(x => x.body)
      ).subscribe(resp => {
        this.data.playlist = resp.filter(x => (x.is_uploaded || !!x.is_document) && (!!x.is_document || (x.content_type.indexOf("image") != -1 || x.content_type.indexOf("video") != -1 || x.content_type.indexOf("audio") != -1) || x.content_type == 'application/pdf' || !!x.thumbnail));
        console.log("getPlaylist", this.data.playlist)
      })
    )
  }

  getEmployees() {
    this.attachSubscriptions(
      this.taskService.getEmployees(this.data.company.id).subscribe(resp => {
        this.employees = resp;
      })
    )
  }

  checkActions(e) {
    e.preventDefault();
    e.stopPropagation();
    this.attachSubscriptions(
      this.taskService.getActions(this.data.task.id, 1, this.data.company.id).subscribe(resp => {
        
        if (resp.body.length) {
          this.dialog.open(NotificationsBar, {
            backdropClass: 'backdrop_under_header',
            panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
            data: {
              notifications: resp.body,
              employees: !!this.employees ? this.employees : [],
              taskStatuses: this.taskStatuses,
              company: this.data.company,
              user: this.data.user,
              task: this.data.task,
              header: false,
              tasks: this.data.tasks,
              initCompanyId: this.company_id,
              empl_status: this.data.company.permissions.includes('owner') || this.data.company.permissions.includes('admin') || this.data.company.permissions.includes('manager'),
              pagination: {
                'pageCount': resp.headers.get('x-pagination-page-count'),
                'perPage': resp.headers.get('x-pagination-per-page'),
                'totalCount': resp.headers.get('x-pagination-total-count'),
                'currentPage': resp.headers.get('x-pagination-current-page'),
              },
            }
          });
        }
      })
    )
  }

  onImgError(event){
    event.target.src = this.imgRoute+'/assets/img/image_black_48dp.svg'
  }

  getTaskStatus() {
    this.attachSubscriptions(
      this.taskService.getTaskStatuses(this.data.company.id).subscribe(resp => {
        this.taskStatuses = resp.slice();
        this.taskStatuses.unshift({
          id: 0,
          name: marker('All statuses')
        });
      })
    )
  }

  checkIsMobile() {
    if (window.innerWidth <= 769) {
      this.is_mobile = true;
    } else {
      this.is_mobile = false;
    }
    this.onResize();
  }

  onResize() {
    this.attachSubscriptions(
      fromEvent(window, "resize").pipe(
        map(() => window.innerWidth)
      ).subscribe((wWidth) => {
          if (wWidth <= 769) {
            this.is_mobile = true;
          } else {
            this.is_mobile = false;
          }
        }
      )
    )
  }

  hideMenu() {
    console.log("hideMenu !!!!!")
    this.attachSubscriptions(
      this.chatService.getChats(this.data.company.id, this.data.work.task_id, {id: this.chat_id}).subscribe(resp => {
        if (resp && resp.length) {
          if (this.chats.filter(x => x.id == this.chat_id).length) {
            this.chats.splice(this.chats.findIndex(x => x.id == this.chat_id), 1, resp[0])
          } 
          if (this.groupedChats) {
            this.groupedChats.forEach(item => {
              if (item.chats.filter(x => x.id == this.chat_id).length) {
                item.chats.splice(item.chats.findIndex(x => x.id == this.chat_id), 1, resp[0])
              }
            });
          }
        }
        this.chat_id = undefined;
        this.selectedChat = undefined;
        this.menuState = false;
      }, error => {
        this.chat_id = undefined;
        this.selectedChat = undefined;
        this.menuState = false;
      })
    )
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  closeAddingChat() {
    this.new_chat = false;
    this.is_draw = false;
    this.is_add_data = true;
    this.connectObj = undefined;
  }

  createChat() {
    if (this.is_draw && this.is_add_data) {
      let sbmtData = {...this.form.value};
      this.attachSubscriptions(
        this.fileService.getDrawDataValues().pipe(
          take(1)
        ).subscribe(result => {
          console.log("getDrawDataValues", result)
    
          if (this.connectObj) {
            if (result && result.draws && result.draws.length) {
              this.connectObj.data.file_timestamps.push(result)
            }
            Object.assign(sbmtData, this.connectObj)
          }

          this.isSubmit = true;
          this.attachSubscriptions(
            this.chatService.createChat(sbmtData, this.data.company.id).subscribe(resp => {
              this.connectObj = undefined;
              console.log(resp);
              this.getChats();
              this.new_chat = false;
              this.is_draw = false;
              this.is_add_data = true;
              this.isSubmit = false;
            })
          )
        })
      )

      this.fileService.drawData$.next(true);

    } else {
      let sbmtData = {...this.form.value};
      if (this.connectObj) {
        if (!this.is_add_data) {
          this.connectObj.data.file_timestamps = []
          Object.assign(sbmtData, this.connectObj)
        } else {
          Object.assign(sbmtData, this.connectObj)
        }
      }
      this.isSubmit = true;
      this.attachSubscriptions(
        this.chatService.createChat(sbmtData, this.data.company.id).subscribe(resp => {
          this.connectObj = undefined;
          console.log(resp);
          this.getChats();
          this.new_chat = false;
          this.is_draw = false;
          this.is_add_data = true;
          this.isSubmit = false;
        })
      )
    }

  }

  ifHasPaths() {
    return !!document.getElementById('drawer_svg') && !!document.getElementById('drawer_svg').childNodes.length
  }

  changeAddData(event) {
    console.log("changeAddData", event);
    this.is_add_data = event.checked;
    if (!event.checked) {
      this.is_draw = false;
    }
  }

  selectPrioritys(priority) {
    this.form.patchValue({
      priority: priority.id
    })
  }

  selectStatus(status) {
    this.form.patchValue({
      status_id: status.id
    })
  }

  chatTimestampClick(e, chat) {
    if (this.videoplayer && chat.data && chat.data.original_file_id == this.data.file.original_file_id) {
      e.preventDefault();
      e.stopPropagation();
      if (chat.data.file_timestamps && chat.data.file_timestamps.length) {
        chat.data.file_timestamps.forEach(setting => {
          if (setting.type == 'currentTime') {
            console.log("this.videoplayer.paused", this.videoplayer.paused);
            this.videoplayer[setting.type] = setting[setting.type];
            this.videoplayer.pause();
          }
        });
      }
    }
  }

  selectChat(chat) {
    if (this.videoplayer && chat.data && chat.data.original_file_id == this.data.file.original_file_id) {
      if (chat.data.file_timestamps && chat.data.file_timestamps.length) {
        chat.data.file_timestamps.forEach(setting => {
          if (setting.type == 'currentTime') {
            console.log("this.videoplayer.paused", this.videoplayer.paused);
            this.videoplayer[setting.type] = setting[setting.type];
            this.videoplayer.pause();
          }
        });
      }
    }
    chat.is_read = 1;
    this.selectedChat = chat;
    this.chat_id = chat.id;
    this.menuState = true;
  }

  toggleDraw() {
    this.noZoom();
    this.is_draw = !this.is_draw;
  }

  addChat() {
    this.chat_id = undefined;
    this.selectedChat = undefined;
    this.menuState = false;
    if (this.videoplayer) {
      this.connectObj = {
        data: {
          file_id: this.data.file.id,
          original_file_id: this.data.file.original_file_id,
          file_timestamps: [
            {
              type: 'currentTime',
              acm: this.data.file.acm,
              currentTime: this.videoplayer.currentTime
            }
          ]
        }
      }
      console.log('addChat currentTime', this.videoplayer.currentTime);
    } else {
      this.connectObj = {
        data: {
          file_id: this.data.file.id,
          original_file_id: this.data.file.original_file_id,
          file_timestamps: [
            {
              type: 'acm',
              acm: this.data.file.acm
            }
          ]
        }
      }
    }
    console.log('addChat this.data.file', this.data.file);
   
    this.new_chat = true;
    this.form = this.fb.group({
      task_operation_id: this.data.work.id,
      status_id: [5, Validators.required],
      name: ['', Validators.required],
      text: '',
      priority: 0
    })

    setTimeout(() => {
      if (document.getElementsByClassName('no_nav') && document.getElementsByClassName('no_nav')[0]) {
        const inputElement = document.getElementsByClassName('no_nav')[0] as HTMLInputElement;

        if (inputElement) {
          console.log('inputElement', inputElement)
          // Установить фокус в конец поля ввода
          inputElement.focus();
          
          if (!!inputElement.innerText) {
            // Определить конечную позицию текста
            const endPosition = inputElement.innerText.length;

            // Создать Range и установить его в конечную позицию
            const range = document.createRange();
            const sel = window.getSelection();
            range.setStart(inputElement.childNodes[0], endPosition);
            range.collapse(true);

            // Очистить текущий выделенный диапазон и установить новый
            sel.removeAllRanges();
            sel.addRange(range);
          } else {
          // Определить конечную позицию текста
          const endPosition = inputElement.value.length;
          
        
          // Установить selectionStart и selectionEnd в конечную позицию
          inputElement.selectionStart = endPosition;
          inputElement.selectionEnd = endPosition;
          }

        }
      }
    }, 300)
  }

  onTimeChange(e) {
    // console.log('onTimeChange', e)
    if (this.connectObj && this.connectObj.data && this.connectObj.data && this.connectObj.data.file_timestamps && this.connectObj.data.file_timestamps.length && this.connectObj.data.file_timestamps[0].type == 'currentTime') {
      this.connectObj.data.file_timestamps[0].currentTime = e.detail
    }
  }

  
  neededJobData(task, company_id) {
    let arr = [
      this.chatService.getTasksChats(company_id, [task.id]).pipe(
        tap(res => {
          task['operations'].map(o_el => {
            o_el['chatsInfo'] = [];
            o_el['chatsInfo'].push(...res.filter(z => z.task_operation_id == o_el.id));
            o_el['unReadChats'] = res.filter(z => z.task_operation_id == o_el.id && z.is_read != '1').length;
            o_el['unClosedChats'] = res.filter(z => z.task_operation_id == o_el.id && z.status_id != '4').length;
          });
          task['chatsInfo'] = [];
          task['chatsInfo'].push(...res.filter(z => z.task_id == task.id));
          task['unReadChats'] = res.filter(z => z.is_read != '1').length;
          task['unClosedChats'] = res.filter(z => z.status_id != '4').length;
        })
      )
    ]
    if (task.company_id == company_id) {
      arr.push(
        this.taskService.getTaskClients(task.id, company_id).pipe(
          tap(res => {
            task.clients = res
          }),
          catchError(err => {
            return err
          })
        )
      )
    }
    return forkJoin(arr)
  }

  openTask(file) {
    let taskData:any = {
      task_id: file.task_id,
      company: this.company,
      initCompanyId: this.company_id,
      tab: 0,
    }

    const dialogRef = this.dialog.open(OpenTaskComponent, {
      backdropClass: 'backdrop_under_header',
      panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
      autoFocus: false,
      data: taskData
    });

    // dialogRef.afterClosed().subscribe(res => {
    //   this.toMainPage()
    // })
  }

  openJob(file) {
    console.log(this.activatedRoute.snapshot.queryParamMap.get('path'))
    this.attachSubscriptions(
      this.taskService.getOneTaskExpand(file.company_id, file.task_id).pipe(
        map(x => x.body[0]),
        switchMap(x => this.neededJobData(x, this.company_id).pipe(map(() => casesModel([x], [], 'update')),map(x => x.arr[0]))),
      ).subscribe(resp => {
        const dialogRef = this.dialog.open(OpenJobComponent, {
          backdropClass: 'backdrop_under_header',
          panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
          autoFocus: false,
          data: {
            task_id: file.task_id,
            task_operation_id: file.task_operation_id,
            task: resp,
            company: this.company,
            initCompanyId: this.company_id
          }
        });

        // dialogRef.afterClosed().subscribe(res => {
        //   this.toMainPage()
        // })
      })
    )
  }

  neededTaskData(task, company) {
    let arr = [
      this.chatService.getTasksChats(company.id, [task.id]).pipe(
        tap(res => {
          task['operations'].map(o_el => {
            o_el['chatsInfo'] = [];
            o_el['chatsInfo'].push(...res.filter(z => z.task_operation_id == o_el.id));
            o_el['unReadChats'] = res.filter(z => z.task_operation_id == o_el.id && z.is_read != '1').length;
            o_el['unClosedChats'] = res.filter(z => z.task_operation_id == o_el.id && z.status_id != '4').length;
          });
          task['chatsInfo'] = [];
          task['chatsInfo'].push(...res.filter(z => z.task_id == task.id));
          task['unReadChats'] = res.filter(z => z.is_read != '1').length;
          task['unClosedChats'] = res.filter(z => z.status_id != '4').length;
        })
      )
    ]
    return forkJoin(arr)
  }

  openChat(chat_id, msg_id) {
    console.log("openChat")
    this.attachSubscriptions(
      this.chatService.getChatsExpand(1, this.data.company.id, {id: chat_id}).pipe(
        map(res => res.body[0]),
        switchMap(chat => {
          return this.taskService.getOneTaskExpand(this.data.company.id, chat.task_id).pipe(
            map(x => x.body[0]),
            switchMap(x => this.neededTaskData(x, this.data.company).pipe(map(() => casesModel([x], [], 'update')),
            switchMap(x => this.taskService.getOperations(this.data.company.id, this.data.activeLang).pipe(tap(u => x.arr[0].operationsValues = u), map(() => x))),
            map((x:any) => { return {task: x.arr[0], 'chat': chat}}))),
          )
        })
      ).subscribe((resp:any) => {
        console.log(resp);

        let data:any = {
          company: this.data.company,
          task: resp.task,
          dialogType: 'operation_chat',
          operationsValues: resp.task.operationsValues,
          work: resp.task.operations.find(el => el.id == resp.chat.task_operation_id),
          user: this.data.user,
          empl_status: this.data.company.permissions.includes('owner') || this.data.company?.permissions.includes('admin') || this.data.company.permissions.includes('manager'),
          chat: resp.chat,
          chats: resp.task.operations.find(el => el.id == resp.chat.task_operation_id).chatsInfo
        }
        if (msg_id) {
          data.m_id = msg_id
        }
        const goToChat = this.dialog.open(OperationChatComponent, {
          backdropClass: ['backdrop_under_header', "without_pad"],
          panelClass: !this.is_mobile ? ['full_screen_dialog', 'global_chats_dialog', 'show_header'] : ['full_screen_dialog', 'global_chats_dialog'],
          position: !this.is_mobile ? { top: '58px', left: '0', right: '0', bottom: '0' } : {},
          autoFocus: this.is_mobile ? false : true,
          data: data
        });
    
        this.attachSubscriptions(
          goToChat.afterClosed().subscribe(result => {
            if (!!result && result.event == 'close') {
              this.chatService.postChatRead(chat_id, 0, this.data.company.id).subscribe(resp => {
                console.log(resp);
              })
            }
          })
        )
      })
    )
  }

  goToChat(chat) {
    console.log(chat);
    const goToChat = this.dialog.open(OperationChatComponent, {
      backdropClass: ['backdrop_under_header', "without_pad"],
      panelClass: ['full_screen_dialog', 'global_chats_dialog'],
      disableClose: true,
      data: {
        company: this.data.company,
        task: this.data.task,
        tasks: this.data.tasks,
        dialogType: 'operation_chat',
        work: this.data.work,
        user: this.data.user,
        empl_status: this.data.company.permissions.includes('owner') || this.data.company.permissions.includes('admin') || this.data.company.permissions.includes('manager'),
        chat: chat,
        chats: this.chats,
        dialogComp: this.dialogRef,
        operationsValues: this.data.operationsValues
      }
    });

    this.attachSubscriptions(
      goToChat.afterClosed().subscribe(result => {
        if (!!result && result.event == 'close') {
          this.getChats();
          this.chatService.postChatRead(chat.id, 0, this.data.company.id).subscribe(resp => {
            console.log(resp);
          })
        }
      })
    )
  }

  changeChatsFilter(key, value) {
    this.chatsFilter.patchValue({
      [key]: value
    })
  }

  toggleChat(chat) {
    this.selectedChats.toggle(chat);
  }

  moveChats() {
    const dialogRef = this.dialog.open(MoveToOtherJobComponent, {
      disableClose: true,
      data: {
        user: this.data.user,
        company: this.company,
        task: this.data.task,
        // chat: chat,
        selectedChats: this.selectedChats.selected
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        this.selectedChats.clear();
        this.getChats();
      })
    )
  }

  getChatStatuses() {
    this.attachSubscriptions(
      this.chatService.getChatStatuses().subscribe(resp => {
        this.statuses = resp.slice();
        console.log('getChatStatuses', this.statuses);
      })
    )
  }

  statusChange() {
    console.log(this.chatsFilter.value)
    this.getChats()
  }

  clearDraw() {
    this.fileService.drawClear$.next(true);
  }

  onStrokeOpen() {
    this.strokeOpen = !this.strokeOpen
  }

  getChats() {
    let filter = JSON.parse(JSON.stringify(this.chatsFilter.value));
    Object.keys(filter).forEach(key => {
      if (filter[key] === '') {
        delete filter[key]
      }
    })

    this.attachSubscriptions(
      this.chatService.getChatsDyn('1', this.data.company.id, this.data.work.task_id, filter, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let pArr = []
          for (let index = 1; index <= pages; index++) {
            pArr.push(index)
          }

          return forkJoin(pArr.map(x => this.chatService.getChatsDyn(x, this.data.company.id, this.data.work.task_id, filter, '200').pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        let arr = [].concat(...res);
        console.log("getChats", arr);
        if (!!this.chatsFilter.value.data_file_id && arr && arr.length) {
          let withTime = arr.filter(x => x.data && x.data.file_timestamps && x.data.file_timestamps.length && x.data.file_timestamps[0].type == 'currentTime');
          let withoutTime = arr.filter(x => !(x.data && x.data.file_timestamps && x.data.file_timestamps.length && x.data.file_timestamps[0].type == 'currentTime'));

          withTime.sort((a,b) => {
            return Number(a.data.file_timestamps[0].currentTime) - Number(b.data.file_timestamps[0].currentTime);
          })

          arr = [...withTime, ...withoutTime]
        }

        if (this.chatsFilter.value.data_file_id === '') {
          let fileCh = arr.filter(x => x.data_file_id == this.data.file.id);
          let justCh = arr.filter(x => x.data_file_id == 0);
          let otherCh = arr.filter(x => !!x.data_file_id && x.data_file_id != this.data.file.id );

          let fileChWT = fileCh.filter(x => x.data && x.data.file_timestamps && x.data.file_timestamps.length && x.data.file_timestamps[0].type == 'currentTime');
          let fileChNT = fileCh.filter(x => !(x.data && x.data.file_timestamps && x.data.file_timestamps.length && x.data.file_timestamps[0].type == 'currentTime'));

          fileChWT.sort((a,b) => {
            return Number(a.data.file_timestamps[0].currentTime) - Number(b.data.file_timestamps[0].currentTime);
          })

          this.groupedChats = [
            {
              name: "",
              chats: [...fileChWT, ...fileChNT]
            },
            {
              name: "Just chats",
              chats: [...justCh]
            },
            {
              name: "Other chats",
              chats: [...otherCh]
            }
          ]
        } else {
          this.groupedChats = undefined;
        }

        this.chats = arr;

        if (this.chats && this.chats.length > 0) {
    
          this.data.file.points = [];
          if (this.chats.filter(x => x.data && x.data.original_file_id == this.data.file.original_file_id).length > 0) {
            
            this.chats.filter(x => !!x.data && x.data.original_file_id == this.data.file.original_file_id).forEach(chat => {
              if (chat.data.file_timestamps && !!chat.data.file_timestamps.length && chat.data.file_timestamps[0].type == 'currentTime' && this.data.file.points.filter(x => x.chat_id == chat.id).length == 0) {
                this.data.file.points.push({
                  chat_id: chat.id,
                  chat: chat,
                  status_id: chat.status_id,
                  currentTime: chat.data.file_timestamps[0].currentTime
                })
              }
            });
          }
        }
        
        if (this.count == 1 && this.data.chat && this.chats.find(x => x.id == this.data.chat.id)) {
          this.count++;
          setTimeout(() => {
            this.selectChat(this.chats.find(x => x.id == this.data.chat.id));
          }, 2000)
        }
      })
    )

    // this.attachSubscriptions(
    //   this.chatService.getChats(this.data.company.id, this.data.work.task_id, filter).subscribe(resp => {
    //     console.log("getChats", resp);
    //     let arr = resp;
    //     if (!!this.chatsFilter.value.data_file_id && resp && resp.length) {
    //       let withTime = resp.filter(x => x.data && x.data.file_timestamps && x.data.file_timestamps.length && x.data.file_timestamps[0].type == 'currentTime');
    //       let withoutTime = resp.filter(x => !(x.data && x.data.file_timestamps && x.data.file_timestamps.length && x.data.file_timestamps[0].type == 'currentTime'));

    //       withTime.sort((a,b) => {
    //         return Number(a.data.file_timestamps[0].currentTime) - Number(b.data.file_timestamps[0].currentTime);
    //       })

    //       arr = [...withTime, ...withoutTime]
    //     }

    //     if (this.chatsFilter.value.data_file_id === '') {
    //       let fileCh = resp.filter(x => x.data_file_id == this.data.file.id);
    //       let justCh = resp.filter(x => x.data_file_id == 0);
    //       let otherCh = resp.filter(x => !!x.data_file_id && x.data_file_id != this.data.file.id );

    //       let fileChWT = fileCh.filter(x => x.data && x.data.file_timestamps && x.data.file_timestamps.length && x.data.file_timestamps[0].type == 'currentTime');
    //       let fileChNT = fileCh.filter(x => !(x.data && x.data.file_timestamps && x.data.file_timestamps.length && x.data.file_timestamps[0].type == 'currentTime'));

    //       fileChWT.sort((a,b) => {
    //         return Number(a.data.file_timestamps[0].currentTime) - Number(b.data.file_timestamps[0].currentTime);
    //       })

    //       this.groupedChats = [
    //         {
    //           name: "",
    //           chats: [...fileChWT, ...fileChNT]
    //         },
    //         {
    //           name: "Just chats",
    //           chats: [...justCh]
    //         },
    //         {
    //           name: "Other chats",
    //           chats: [...otherCh]
    //         }
    //       ]
    //     } else {
    //       this.groupedChats = undefined;
    //     }

    //     this.chats = arr;

    //     if (this.chats && this.chats.length > 0) {
    
    //       this.data.file.points = [];
    //       if (this.chats.filter(x => x.data && x.data.original_file_id == this.data.file.original_file_id).length > 0) {
            
    //         this.chats.filter(x => !!x.data && x.data.original_file_id == this.data.file.original_file_id).forEach(chat => {
    //           if (chat.data.file_timestamps && !!chat.data.file_timestamps.length && chat.data.file_timestamps[0].type == 'currentTime' && this.data.file.points.filter(x => x.chat_id == chat.id).length == 0) {
    //             this.data.file.points.push({
    //               chat_id: chat.id,
    //               chat: chat,
    //               status_id: chat.status_id,
    //               currentTime: chat.data.file_timestamps[0].currentTime
    //             })
    //           }
    //         });
    //       }
    //     }
        
    //     if (this.count == 1 && this.data.chat && this.chats.find(x => x.id == this.data.chat.id)) {
    //       this.count++;
    //       setTimeout(() => {
    //         this.selectChat(this.chats.find(x => x.id == this.data.chat.id));
    //       }, 2000)
    //     }
    //   })
    // )
  }

  checkTimes(time1, time2) {
    console.log("checkTimes", time1, time2);
    if (time1 == time2) {
      return true
    } else {
      return false
    }
  }

  getCompany() {
    console.log(this.company_id);
    this.attachSubscriptions(
      this.companyService.getCompany(this.company_id).subscribe(resp => {
        console.log("getCompany", resp);
        if (resp) {
          this.companyService.company$.next(resp[0]);
          this.company = resp[0];
        }
      })
    )
  }

  getColumnName(id) {
    if (!this.data.operationsValues || !this.data.operationsValues.find(el => el.id == id)) {
      return '';
    }
    return this.data.operationsValues.find(el => el.id == id) && this.data.operationsValues.find(el => el.id == id).translation ? this.data.operationsValues.find(el => el.id == id).translation.name : this.data.operationsValues.find(el => el.id == id).name
  }
  
  ngAfterViewInit(): void {
    // console.log("_this.swiper", this.swiper);
    // console.log("_this.swiper.swiperRef", this.swiper.swiperRef);
    // console.log("_this.data.playlist", this.data.playlist);
    // console.log("_this.data.file", this.data.file);
    // console.log("_this.data.playlist.indexOf(this.data.file)", this.data.playlist.indexOf(this.data.file));
    // // this.swiper.swiperRef.slideTo(this.data.playlist.indexOf(this.data.file), 0);
    // this.swiper.swiperRef.slideTo(Math.round(this.data.playlist.indexOf(this.data.file)/3), 0);
    // // setTimeout(() => {
    // //   if (this.swiper && this.swiper.swiperRef && this.data.playlist && this.data.file) {
    // //     console.log("_this.data.playlist.indexOf(this.data.file)", this.data.playlist.indexOf(this.data.file));
    // //     this.swiper.swiperRef.slideTo(this.data.playlist.indexOf(this.data.file), 0);
    // //   }
    // // }, 1000)
    if (this.data.file.content_type.indexOf('video') == -1) {
      if (this.data.work && document.getElementById('chats_h_wrp') && this.videoPlayerParrent) {
        document.getElementById('chats_h_wrp').style.maxHeight = this.videoPlayerParrent.nativeElement.offsetHeight + (this.data.playlist && this.data.playlist.length > 1 ? 52 : 0) + "px"
      }
      return
    }

    if (this.videoplayer) {
      if (this.vm_theme == 'dark') {
        (this.videoplayer as any).el.style.setProperty('--vm-player-bg', `#000`);
        (this.videoplayer as any).el.style.setProperty('--vm-icon-fill', `#fff`);
        (this.videoplayer as any).el.style.setProperty('--vm-icon-stroke', `#fff`);
        (this.videoplayer as any).el.style.setProperty('--vm-time-color', `#fff`);
        (this.videoplayer as any).el.style.setProperty('--vm-slider-value-color', `#fff`);
      } else {
        (this.videoplayer as any).el.style.setProperty('--vm-player-bg', `#fff`);
        (this.videoplayer as any).el.style.setProperty('--vm-icon-fill', `#000`);
        (this.videoplayer as any).el.style.setProperty('--vm-icon-stroke', `#000`);
        (this.videoplayer as any).el.style.setProperty('--vm-time-color', `#000`);
        (this.videoplayer as any).el.style.setProperty('--vm-slider-value-color', `#000`);
      }
    } 

    this.startVideo()

    this.cd.detectChanges();
  }

  changeRate(val) {
    this.videoplayer.playbackRate = val
  }

  startVideo(time?) {
    if (this.sub) {
      this.sub.unsubscribe()
    }

    this.sub = interval(100).pipe(
      filter(() => !!this.videoplayer)
    ).subscribe(res => {
      if (this.data.work) {
        document.getElementById('chats_h_wrp').style.maxHeight = this.videoPlayerParrent.nativeElement.offsetHeight + (this.data.playlist && this.data.playlist.length > 1 ? 52 : 0) + "px"
      }
      if (this.videoplayer.playbackReady) {
        if (this.data.work) {
          document.getElementById('chats_h_wrp').style.maxHeight = this.videoPlayerParrent.nativeElement.offsetHeight + (this.data.playlist && this.data.playlist.length > 1 ? 52 : 0) + "px"
        }
        if (!!time) {
          this.videoplayer.currentTime = time
        }
        this.videoplayer.play();
        this.sub.unsubscribe()
      }
    })
  }


  open({ x, y }: MouseEvent, val) {
    this.close();
    console.log(val);
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo({ x, y })
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        }
      ]);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    this.overlayRef.attach(new TemplatePortal(this.taskMenu, this.viewContainerRef, {
      $implicit: val
    }));
    
    this.sub = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter(event => {
          const clickTarget = event.target as HTMLElement;
          return !!this.overlayRef && !this.overlayRef.overlayElement.contains(clickTarget);
          // return !!this.overlayRef && !this.overlayRef.overlayElement.contains(clickTarget) && (document.getElementsByClassName('view_publish_to') && !document.getElementsByClassName('view_publish_to')[0].contains(clickTarget));
        }),
        take(1)
      ).subscribe(() => this.close())

  }

  close() {
    this.sub && this.sub.unsubscribe();
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }

  checkTime() {
    if (this.videoplayer) {
      console.log(this.videoplayer.currentTime)
    }
  }

  outletClick(outlet, file) {
    console.log("outletClick", outlet, file);
    this.close();

    if (outlet.content_status_id == 1 && !outlet.content_url) {
      console.log('create in selected')
      let _task = this.data.task || this.data.file.task;
      let _work;
      if (_task && _task.operations) {
        _work = _task.operations.find(z => z.id == this.data.file.task_operation_id) ? _task.operations.find(z => z.id == this.data.file.task_operation_id) : this.data.work;
      }
      const dialogRef = this.dialog.open(PublishToOutletComponent, {
        disableClose: true,
        data: {
          host: this.host,
          imgRoute: this.imgRoute,
          activeLang: this.data.activeLang,
          user: this.data.user,
          company: this.company,
          company_id: this.company_id,
          target_company_id: outlet.company_id,
          profile: outlet,
          file: file,
          task: _task,
          work: _work,
        }
      });
  
      this.attachSubscriptions(
        dialogRef.afterClosed().subscribe(result => {
          console.log(result)
        })
      )
      
    } else {
      console.log('create in new outlet same as selected')
      this.attachSubscriptions(
        this.taskService.addProfile({
          task_id: this.data.file.task_id,
          channel_id: outlet.channel.id,
          content_type_id: outlet.content_type_id,
          content_status_id: 1
        }, this.company_id).pipe(
          switchMap(res => {
            return this.taskService.getTaskForManager(this.company ? this.company.id : this.company_id, this.data.file.task_id).pipe(
              map(el => {
                if (el[0]) {
                  if (this.data.file.task_operation_id != "0") {
                    el[0].operations.find(x => x.id == this.data.file.task_operation_id).is_active = true;
                    el[0].is_open = true;
                  } else {
                    el[0].is_open = false;
                  }
                  return casesModel(el, [], 'update').arr[0]
                } else {
                  return false
                }
              }),
              switchMap(resp => {
                let expPermFilter:any = {task_id: this.data.file.task_id}
                if (this.company_id != this.data.file.company_id) {
                  expPermFilter.partner_company_id = this.data.file.company_id
                }
                return this.taskService.getExpandPermUrls('1', this.company_id, expPermFilter, '100').pipe(
                  tap(rslt => {
                    resp.uniqueChannels = [];
                    rslt.sort((a, b) => {
                      if (a.content_status_id === 13 && b.content_status_id !== 13) {
                        return -1;
                      } else if (a.content_status_id === 1 && b.content_status_id !== 13 && b.content_status_id !== 1) {
                        return -1;
                      } else if (b.content_status_id === 13 || b.content_status_id === 1) {
                        return 1;
                      } else {
                        return 0;
                      }
                    });
                    rslt.filter(p => !!p.permission_prepare_upload).forEach(channel => {
                      if (channel.channel && [1,2,3,4].includes(channel.channel.platform_id) && !!channel.channel.channel_account_id) {
                        if (resp.uniqueChannels.filter(ch => ch.channel_id == channel.channel_id).length == 0) {
                          resp.uniqueChannels.push(channel)
                        } else {
                          if (!([1, 13].includes(resp.uniqueChannels.find(ch => ch.channel_id == channel.channel_id).content_status_id) && !resp.uniqueChannels.find(ch => ch.channel_id == channel.channel_id).content_url)) {
                            resp.uniqueChannels.splice(resp.uniqueChannels.indexOf(resp.uniqueChannels.find(ch => ch.channel_id == channel.channel_id)), 1, channel)
                          }
                        }
                      }
                    });
                  }),
                  map(() => resp)
                )
              }),
              tap(resp => {
                this.data.task = resp;
                this.data.work = this.data.task.operations.filter(z => z.id == this.data.file.task_operation_id).length > 0 ? this.data.task.operations.find(z => z.id == this.data.file.task_operation_id) : undefined
              }),
              map((resp) => resp.channels.find(x => x.id == res.id))
            )
          })
        ).subscribe(resOutlet => {
  
          let _task = this.data.task || file.task;
          let _work;
          if (_task && _task.operations) {
            _work = _task.operations.find(z => z.id == this.data.file.task_operation_id) ? _task.operations.find(z => z.id == this.data.file.task_operation_id) : this.data.work;
          }
          const dialogRefDialog = this.dialog.open(PublishToOutletComponent, {
            disableClose: true,
            data: {
              host: this.host,
              imgRoute: this.imgRoute,
              activeLang: this.data.activeLang,
              user: this.data.user,
              company: this.company,
              company_id: this.company_id,
              target_company_id: outlet.company_id,
              profile: resOutlet,
              file: file,
              task: _task,
              work: _work,
            }
          });
      
          // this.attachSubscriptions(
          //   dialogRefDialog.afterClosed().subscribe(result => {
          //     console.log(result)
          //   })
          // )
        })
      )

    }

  }

  copyLink(type) {
    this.layoutService.showSnackBar({name: marker(type + " link")}, marker("Copied"), SnackBarItem)
  }

  download(file, type) {
    if (type == 'full') {
      if (this.data.is_public) {
        window.open(this.host + file.original);
      } else {
        window.open(this.host + file.original + '?company_id=' + this.data.company.id + `&filename=${file.filename}`, '_blank');
      }
    } else if (type == 'thum') {
      if (this.data.is_public) {
        window.open(this.host + file.thumbnail);
      } else {
        window.open(this.host + file.thumbnail + '?company_id=' + this.data.company.id + `&filename=${file.filename}`, '_blank');
      }
    } else if (type == 'conv') {
      if (this.data.is_public) {
        window.open(this.host + file.preview1080);
      } else {
        window.open(this.host + file.preview1080 + '?company_id=' + this.data.company.id + `&filename=${file.filename}`, '_blank');
      }
    } 
  }
  
  onPlaybackReady() {
    console.log("READY")
  }
  
  closeDialog() {
    this.dialogRef.close(false)
  }

  getChatStatus(id) {
    switch (+id) {
      case 1:
        return "Waiting";
        break;
      case 2:
        return "In process";
        break;
      case 3:
        return "To approve";
        break;
      case 4:
        return "Approved";
        break;
      case 5:
        return "To correct";
        break;
      case 6:
        return "Sources needed";
        break;
      case 97:
        return "On hold";
        break;
      case 98:
        return "Ready";
        break;
      case 99:
        return "Canceled";
        break;
    }
  }

  

  selectItem(e, file, ind) {
    console.log(e);
    if (!!e) {
      if (e.ctrlKey || e.metaKey) {
        this.fileCollection.toggle(file.id);
        console.log(this.fileCollection.isSelected(file.id));
        console.log(this.fileCollection.selected);
        return
      } else if (e.shiftKey) {
        if (this.fileCollection.selected.length > 1) {

          let target = this.data.playlist.find(x => x.id == this.fileCollection.selected[this.fileCollection.selected.length - 1]);
          let selectedIndex = this.data.playlist.indexOf(target);
          const startIndex = Math.min(ind, selectedIndex);
          const endIndex = Math.max(ind, selectedIndex);
          let intervalItems;
          if (ind < selectedIndex) {
            intervalItems = this.data.playlist.slice(startIndex, endIndex).map(x => x.id);
          } else {
            intervalItems = this.data.playlist.slice(startIndex + 1, endIndex + 1).map(x => x.id);
          }
          console.log(selectedIndex, startIndex, endIndex, intervalItems);
          intervalItems.forEach(id => {
            this.fileCollection.toggle(id);
          });
        } else if (this.fileCollection.selected.length == 1) {
          let target = this.data.playlist.find(x => x.id == this.fileCollection.selected[0]);
          let selectedIndex = this.data.playlist.indexOf(target);
          const startIndex = Math.min(ind, selectedIndex);
          const endIndex = Math.max(ind, selectedIndex);
          let intervalItems;
          if (ind < selectedIndex) {
            intervalItems = this.data.playlist.slice(startIndex, endIndex).map(x => x.id);
          } else {
            intervalItems = this.data.playlist.slice(startIndex + 1, endIndex + 1).map(x => x.id);
          }
          console.log(selectedIndex, startIndex, endIndex, intervalItems);
          intervalItems.forEach(id => {
            this.fileCollection.toggle(id);
          });
        } else {
          let target = this.data.playlist.find(x => x.id == this.data.file.id);
          let selectedIndex = this.data.playlist.indexOf(target);
          const startIndex = Math.min(ind, selectedIndex);
          const endIndex = Math.max(ind, selectedIndex);
          let intervalItems;
          if (ind < selectedIndex) {
            intervalItems = this.data.playlist.slice(startIndex, endIndex + 1).map(x => x.id);
          } else {
            intervalItems = this.data.playlist.slice(startIndex, endIndex + 1).map(x => x.id);
          }
          console.log(selectedIndex, startIndex, endIndex, intervalItems);
          intervalItems.forEach(id => {
            this.fileCollection.toggle(id);
          });
        }
        
  
        // this.fileCollection.toggle(...intervalItems);
        return
      }
      console.log("CTRL CLICK");
    }
    this.data.file = undefined;
    setTimeout(() => {
      this.data.file = file;
      this.fileCollection.clear()
      this.getCopies();
      this.setSrcs();
    }, 0)
    
    setTimeout(() => {
      if (this.data.work && document.getElementById('chats_h_wrp')) {
        document.getElementById('chats_h_wrp').style.maxHeight = this.videoPlayerParrent.nativeElement.offsetHeight + (this.data.playlist && this.data.playlist.length > 1 ? 52 : 0) + "px"
      }
      if (this.data.file.content_type.indexOf("video") != -1) {
        this.startVideo()
      }
    }, 300)
  }

  understandFileType(val) {
    if (!val) {
      return ""
    }
    
    if (val == 'application/pdf') {
      return 'pdf'
    } else if (val.indexOf("/") != -1) {
      return val.split('/')[0]
    } else {
      return ""
    }
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe()
    }
    this.clearSubscriptions()
  }

  navBefore() {
    let selectedIndex = this.data.playlist.findIndex(x => x.id == this.data.file.id)
    this.data.file = undefined;

    setTimeout(() => {
      if (selectedIndex - 1 >= 0) {
        this.data.file = this.data.playlist[selectedIndex - 1]
      } else {
        this.data.file = this.data.playlist[this.data.playlist.length - 1]
      }
      this.setSrcs();
    }, 0)

    setTimeout(() => {
      if (this.data.work) {
        document.getElementById('chats_h_wrp').style.maxHeight = this.videoPlayerParrent.nativeElement.offsetHeight + (this.data.playlist && this.data.playlist.length > 1 ? 52 : 0) + "px"
      }
      if (this.data.file.content_type.indexOf("video") != -1) {
        this.startVideo()
      }
    }, 300)
  }

  navNext() {
    let selectedIndex = this.data.playlist.findIndex(x => x.id == this.data.file.id)
    this.data.file = undefined;

    setTimeout(() => {
      if (selectedIndex + 1 <= this.data.playlist.length - 1) {
        this.data.file = this.data.playlist[selectedIndex + 1]
      } else {
        this.data.file = this.data.playlist[0]
      }
      this.setSrcs();

    }, 0)
    
    setTimeout(() => {
      if (this.data.work) {
        document.getElementById('chats_h_wrp').style.maxHeight = this.videoPlayerParrent.nativeElement.offsetHeight + (this.data.playlist && this.data.playlist.length > 1 ? 52 : 0) + "px"
      }
      if (this.data.file.content_type.indexOf("video") != -1) {
        this.startVideo()
      }
    }, 300)
  }


  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    console.log("KeyboardEvent", event);
    console.log((event.target as HTMLInputElement).classList.contains('no_nav'))
    if ((event.target as HTMLInputElement).classList.contains('no_nav')) {
      return
    }

    if (event.ctrlKey && event.shiftKey && event.keyCode === 78) {
      this.addChat();
    }

    if (event.shiftKey && (event.keyCode == 38 || event.key == 'ArrowUp') && this.videoplayer) {
      const targetElement = event.target as HTMLElement;
      if (
        targetElement.nodeName == 'INPUT' ||
        targetElement.nodeName == 'TEXTAREA' ||
        (targetElement.nodeName == 'DIV' && targetElement.isContentEditable)
      ) {
        // Если целью является поле ввода текста или contenteditable DIV, ничего не делаем
        return;
      }
      this.changeVideoTime('forward')
      event.preventDefault();
      event.stopPropagation();
    }

    if ((event.keyCode == 61 || event.key == '=') && this.videoPlayerParrent) {
      const targetElement = event.target as HTMLElement;
      if (
        targetElement.nodeName == 'INPUT' ||
        targetElement.nodeName == 'TEXTAREA' ||
        (targetElement.nodeName == 'DIV' && targetElement.isContentEditable)
      ) {
        // Если целью является поле ввода текста или contenteditable DIV, ничего не делаем
        return;
      }
      this.incrementZoom(0.1)
      event.preventDefault();
      event.stopPropagation();
    }
    if ((event.keyCode == 173 || event.key == '-') && this.videoPlayerParrent) {
      const targetElement = event.target as HTMLElement;
      if (
        targetElement.nodeName == 'INPUT' ||
        targetElement.nodeName == 'TEXTAREA' ||
        (targetElement.nodeName == 'DIV' && targetElement.isContentEditable)
      ) {
        // Если целью является поле ввода текста или contenteditable DIV, ничего не делаем
        return;
      }
      this.incrementZoom(-0.1)
      event.preventDefault();
      event.stopPropagation();
    }

    if (event.shiftKey && (event.keyCode == 40 || event.key == 'ArrowDown') && this.videoplayer) {
      const targetElement = event.target as HTMLElement;
      if (
        targetElement.nodeName == 'INPUT' ||
        targetElement.nodeName == 'TEXTAREA' ||
        (targetElement.nodeName == 'DIV' && targetElement.isContentEditable)
      ) {
        // Если целью является поле ввода текста или contenteditable DIV, ничего не делаем
        return;
      }
      this.changeVideoTime('replay');
      event.preventDefault();
      event.stopPropagation();
    }

    if (event.shiftKey && (event.keyCode == 61 || event.key == '+')) {
      const targetElement = event.target as HTMLElement;
      if (
        targetElement.nodeName == 'INPUT' ||
        targetElement.nodeName == 'TEXTAREA' ||
        (targetElement.nodeName == 'DIV' && targetElement.isContentEditable)
      ) {
        // Если целью является поле ввода текста или contenteditable DIV, ничего не делаем
        return;
      }
      this.incrementZoom(0.1)
    }
    if (event.shiftKey && (event.keyCode == 173 || event.key == '_')) {
      const targetElement = event.target as HTMLElement;
      if (
        targetElement.nodeName == 'INPUT' ||
        targetElement.nodeName == 'TEXTAREA' ||
        (targetElement.nodeName == 'DIV' && targetElement.isContentEditable)
      ) {
        // Если целью является поле ввода текста или contenteditable DIV, ничего не делаем
        return;
      }
      this.incrementZoom(-0.1)
    }

    if (event.key === 'Tab' && event.shiftKey) {
      event.preventDefault();
      event.stopPropagation();
      console.log('shift + tab нажаты!');
      if (this.videoplayer) {
        this.videoplayer.pause();
        if (document.getElementsByClassName('no_n_text_field') && document.getElementsByClassName('no_n_text_field')[0]) {
          const inputElement = document.getElementsByClassName('no_n_text_field')[0] as HTMLInputElement;

          if (inputElement) {
            console.log('inputElement', inputElement)
            // Установить фокус в конец поля ввода
            inputElement.focus();
            
            if (!!inputElement.innerText) {
              // Определить конечную позицию текста
              const endPosition = inputElement.innerText.length;

              // Создать Range и установить его в конечную позицию
              const range = document.createRange();
              const sel = window.getSelection();
              range.setStart(inputElement.childNodes[0], endPosition);
              range.collapse(true);

              // Очистить текущий выделенный диапазон и установить новый
              sel.removeAllRanges();
              sel.addRange(range);
            } else {
            // Определить конечную позицию текста
            const endPosition = inputElement.value.length;
            
          
            // Установить selectionStart и selectionEnd в конечную позицию
            inputElement.selectionStart = endPosition;
            inputElement.selectionEnd = endPosition;
            }

          }
        }
        // console.log('no_nav', document.getElementsByClassName('no_nav')[0]);
      }
    }

    if (event.key === ' ' || event.code === 'Space') {
      console.log('Пробел нажат!');
      if (this.videoplayer) {
        if (!this.videoplayer.paused) {
          this.videoplayer.pause();
          // if (document.getElementsByClassName('no_n_text_field') && document.getElementsByClassName('no_n_text_field')[0]) {
          //   const inputElement = document.getElementsByClassName('no_n_text_field')[0] as HTMLInputElement;
  
          //   if (inputElement) {
          //     console.log('inputElement', inputElement)
          //     // Установить фокус в конец поля ввода
          //     inputElement.focus();
              
          //     if (!!inputElement.innerText) {
          //       // Определить конечную позицию текста
          //       const endPosition = inputElement.innerText.length;
  
          //       // Создать Range и установить его в конечную позицию
          //       const range = document.createRange();
          //       const sel = window.getSelection();
          //       range.setStart(inputElement.childNodes[0], endPosition);
          //       range.collapse(true);
  
          //       // Очистить текущий выделенный диапазон и установить новый
          //       sel.removeAllRanges();
          //       sel.addRange(range);
          //     } else {
          //     // Определить конечную позицию текста
          //     const endPosition = inputElement.value.length;
              
            
          //     // Установить selectionStart и selectionEnd в конечную позицию
          //     inputElement.selectionStart = endPosition;
          //     inputElement.selectionEnd = endPosition;
          //     }
  
          //   }
          // }
        } else {
          this.videoplayer.play();
        }
        // console.log('no_nav', document.getElementsByClassName('no_nav')[0]);
      }
    }

   

    console.log("event.shiftKey", event.shiftKey)
    if (event.key === 'ArrowRight') {
      if (!!event.shiftKey && this.videoplayer) {
        event.preventDefault();
        event.stopPropagation();
        console.log("Shift + ArrowRight");
        let frameRate = 30;
        if (this.data.file && !!this.data.file.fileOriginalData && !!this.data.file.fileOriginalData.meta) {
          let parsedMeta;
          // Пытаемся разобрать метаданные
          try {
            parsedMeta = this.data.file.fileOriginalData.meta;
          } catch (error) {
            console.error("Error parsing metadata:", error);
            // return;
          }
        
          // Проверяем наличие частоты кадров в метаданных
          if (!!parsedMeta && !!parsedMeta.frame_rate && Number(parsedMeta.frame_rate) > 0) {
            frameRate = Number(parsedMeta.frame_rate);
          }
        }

        // console.log((1 / frameRate))
        // console.log(this.videoplayer.currentTime)
        // console.log(this.videoplayer.currentTime + (1 / frameRate))
        this.videoplayer.currentTime = +Math.min(this.videoplayer.duration, this.videoplayer.currentTime + (1 / frameRate)).toFixed(3);
        return;
      }
      if (!this.data.playlist || this.data.playlist.length <= 1) {
        return
      }
      this.navNext();
    }

    if (event.key === 'ArrowLeft') {
      if (!!event.shiftKey && this.videoplayer) {
        event.preventDefault();
        event.stopPropagation();
        console.log("Shift + ArrowLeft")
        let frameRate = 30;
        if (this.data.file && !!this.data.file.fileOriginalData && !!this.data.file.fileOriginalData.meta) {
          let parsedMeta;
          // Пытаемся разобрать метаданные
          try {
            parsedMeta = this.data.file.fileOriginalData.meta;
          } catch (error) {
            console.error("Error parsing metadata:", error);
            // return;
          }
        
          // Проверяем наличие частоты кадров в метаданных
          if (!!parsedMeta && !!parsedMeta.frame_rate && Number(parsedMeta.frame_rate) > 0) {
            frameRate = Number(parsedMeta.frame_rate);
          }
        }
        // console.log((1 / frameRate))
        // console.log(this.videoplayer.currentTime)
        // console.log(this.videoplayer.currentTime - (1 / frameRate))
        this.videoplayer.currentTime = +Math.max(0, this.videoplayer.currentTime - (1 / frameRate)).toFixed(3);
        return;
      }
      if (!this.data.playlist || this.data.playlist.length <= 1) {
        return
      }
      this.navBefore();
    }
  }

}
