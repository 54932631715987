<mat-dialog-content class="mat-typography">
    <div class="container form_wrapper">
        <mat-tab-group [selectedIndex]="activeIndex" (selectedIndexChange)="changeTab($event)">
            <mat-tab>
                <ng-template mat-tab-label>
                    <mat-icon class="example-tab-icon mr-1">badge</mat-icon>
                    {{ "Card jobs" | translate }}
                </ng-template>
                <div class="form_with_pads form_with_valids">
                    <mat-form-field appearance="standard" class="full_width_form" *ngIf="cardJobs && !!cardJobs.length">
                        <mat-label>{{ "Select job" | translate }}</mat-label>
                        <!-- (selectionChange)="selectValue($event)" -->
                        <mat-select [disableOptionCentering]="true" selectDir #consistContainer [(ngModel)]="selectedJob">
                            <mat-select-trigger>
                                <div class="job_item" *ngIf="selectedJob">
                                    <div class="item_header">
                                        <div class="primary_tag">
                                            <div class="text_one_line">{{ selectedJob?.operation?.name }}</div>
                                            <span *ngIf="getPrimaryTag(selectedJob)" class="text_one_line val">{{getPrimaryTag(selectedJob)?.parameterValue?.value}}</span>
                                        </div>
                                        <div class="id">{{ selectedJob?.id }}</div>
                                    </div>
                                    <div class="name text_one_line">{{ selectedJob?.name }}</div>
                                </div>
                            </mat-select-trigger>

                            <mat-option>
                                <ngx-mat-select-search 
                                placeholderLabel="{{ 'Search' | translate }}" 
                                noEntriesFoundLabel="{{ 'no matching card jobs found' | translate }}"
                                [formControl]="cardJobControl"></ngx-mat-select-search>
                            </mat-option>
            
                            <mat-option *ngFor="let job of cardJobs$ | async" class="job_option" [value]="job">
                                <div class="job_item">
                                    <div class="item_header">
                                        <div class="primary_tag">
                                            <div class="text_one_line">{{ job?.operation?.name }}</div>
                                            <span *ngIf="getPrimaryTag(job)" class="text_one_line val">{{getPrimaryTag(job)?.parameterValue?.value}}</span>
                                        </div>
                                        <div class="id">{{ job?.id }}</div>
                                    </div>
                                    <div class="name text_one_line">{{ job?.name }}</div>
                                </div>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end" style="margin-top: 5px;">
    <div class="f_w_p_btns">
        <button mat-raised-button (click)="close()">
            {{ "Close" | translate }}
        </button>
        <button class="ml-1 d-f-btn" (click)="onSubmit()" mat-raised-button cdkFocusInitial color="primary" [disabled]="!selectedJob">
            <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>  
            {{"Select job" | translate}}
        </button>
    </div>
</mat-dialog-actions>
