import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import * as moment from 'moment';
import { fromEvent } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { CreateTaskComponent } from 'src/app/components/atTasksDialog/create-task/create-task.component';
import { MobAddTaskComponent } from 'src/app/components/mob-task/mob-add-task/mob-add-task.component';
import { OpenTaskComponent } from 'src/app/components/workspace-pages/cases/dialogs/open-task/open-task.component';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { TimeFormatPipe } from 'src/app/shared/pipes/timeFormat';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { ConnectedAppsService } from 'src/app/shared/services/rest/connected-apps.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-profile-playlist',
  templateUrl: './profile-playlist.component.html',
  styleUrls: ['./profile-playlist.component.scss']
})

export class ProfilePlaylistComponent extends BaseClass implements OnInit, OnDestroy {
  public host: any = environment.host;
  public imgRoute: any = '';
  public user: any = {};
  public user_id: number;
  public is_api: number = 0;
  public page: number = 1;
  public pagination: any;
  public videos: any = [];
  public platforms: any;
  public imports: any;
  public isSubmit: boolean = false;
  public isLoad: boolean = false;
  public currMonthName: any;
  public videosCollection: any;
  public company_id: any;
  public activeLang: any;
  public importingPagination: any;
  public is_mobile: boolean = false;
  public is_importing: boolean = false;
  public activeStatus: any = '';
  public typeStatus: any = 'All';
  
  throttle = 300;
  scrollUpDistance = 3.5;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ProfilePlaylistComponent>,
    private layoutService: LayoutService,
    private sm: StorageManagerService,
    private auth: AuthService,
    private dialog: MatDialog,
    private languageService: LanguageService,
    private taskService: TaskService,
    private companyService: CompanyService,
    private connectedAppsService: ConnectedAppsService
  ) { super() }

  ngOnInit(): void {
    this.dialogRef.addPanelClass("create_task_by_manager_modal")
    console.log("ProfilePlaylistComponent", this.data)

    
    if (this.data.profile && this.data.profile.channelAccount && this.data.profile.channelAccount.is_active) {
      this.is_api = 1;
    }

    this.getImgRoute();
    this.getCsrf();
    this.getUser();
    this.getPlatforms();
    this.getLangData();
    this.getVideos(this.page);

    this.getCreateTasks()

    this.checkIsMobile()

    this.videosCollection = new SelectionModel(
      true,
      []
    )
  }

  setSwitch(val) {
    this.is_api = val;
    this.is_importing = false;
    this.videosCollection.clear()
    this.pagination = undefined
    this.page = 1;
    this.getVideos(this.page);
  }

  changeTypeStatus(val) {
    this.typeStatus = val;
    this.videosCollection.clear()
    this.pagination = undefined
    this.page = 1;
    this.getVideos(this.page);
  }

  getCreateTasks() {
    this.attachSubscriptions(
      this.taskService.getCreateTasks(this.data.company.id, {channel_id: this.data.profile.id}).pipe(
        tap(el => {
          this.importingPagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
        }),
        map(el => el.body)
      ).subscribe(res => {
        this.imports = res;
        console.log("this.imports", this.imports);
      })
    )
  }

  checkIsMobile() {
    if (window.innerWidth <= 769) {
      this.is_mobile = true;
    } else {
      this.is_mobile = false;
    }
    this.onResize();
  }

  onResize() {
    this.attachSubscriptions(
      fromEvent(window, "resize").pipe(
        map(() => window.innerWidth)
      ).subscribe((wWidth) => {
          if (wWidth <= 769) {
            this.is_mobile = true;
          } else {
            this.is_mobile = false;
          }
        }
      )
    )
  }

  checkboxVideoLabel(row?): string {
    if (!row) {
      return `${this.isAllVideoSelected() ? 'Deselect' : 'Select'} all`;
    }

    return `${this.videosCollection.isSelected(row) ? 'deselect' : 'select'} video ${row.name}`;
  }

  masterVideoToggle() {
    this.isAllVideoSelected() ?
        this.videosCollection.clear() :
        this.videos.forEach(row => this.videosCollection.select(row));
  }

  isAllVideoSelected() {
    const numSelected = this.videosCollection.selected.length;
    const numRows = this.videos.length;
    return numSelected === numRows;
  }

  toggleVideo(video) {
    this.videosCollection.toggle(video);
  }

  onDown(e) {
    console.log(e?.target?.scrollTop)
    if (this.page <= this.pagination['pageCount'] && !this.isLoad) {
      console.log("#########onDown")
      this.getVideos(this.page);
    }
  }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().pipe(
        tap(resp => {
          this.activeLang = resp.active;
          this.currMonthName = moment().format('MMMM');
        }),
      ).subscribe(resp => {
        console.log("getOperations", resp)
      })
    )
  }

  openTask(task) {
    let taskData:any = {
      task_id: task.id,
      company: this.data.company,
      initCompanyId: this.data.company.id,

    }
    if (task.hasOwnProperty("operations")) {
      taskData.task = task
    }

    const dialogRef = this.dialog.open(OpenTaskComponent, {
      backdropClass: 'backdrop_under_header',
      panelClass: !this.is_mobile ? ['open_task_dialog', 'show_header'] : 'open_task_dialog',
      autoFocus: false,
      data: taskData
    });
  }

  createTask() {
    if (!this.is_mobile) {
      const dialogRef = this.dialog.open(CreateTaskComponent, {
        disableClose: true,
        data: {
          company_id: this.data.company.id,
          company: this.data.company,
          imgRoute: this.imgRoute,
          host: this.host,
          videos: this.videosCollection.selected,
          basedVideos: true,
          isMulti: false,
          channel: this.data.profile,
          is_alt: this.is_api == 0 ? true : false
        }
      });
  
      this.attachSubscriptions(
        dialogRef.afterClosed().subscribe(result => {
          
          if (!!result && result.event == 'Add') {
            this.videosCollection.clear()
            this.page = 1;
            this.getVideos(this.page);
          }
        })
      )
    } else {
      const dialogRef = this.dialog.open(MobAddTaskComponent, {
        backdropClass: ['mob_interface_backdrop'],
        panelClass: ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'],
        disableClose: true,
        data: {
          company_id: this.data.company.id,
          company: this.data.company,
          imgRoute: this.imgRoute,
          host: this.host,
          videos: this.videosCollection.selected,
          basedVideos: true,
          isMulti: false,
          channel: this.data.profile,
          is_alt: this.is_api == 0 ? true : false
        }
      });

      this.attachSubscriptions(
        dialogRef.afterClosed().subscribe(result => {
          
          if (!!result && result.event == 'Add') {
            this.videosCollection.clear()
            this.page = 1;
            this.getVideos(this.page);
          }
        })
      )
    }
  }

  createTasksForAll() {
    if (!this.is_mobile) {
      const dialogRef = this.dialog.open(CreateTaskComponent, {
        disableClose: true,
        data: {
          company_id: this.data.company.id,
          company: this.data.company,
          imgRoute: this.imgRoute,
          host: this.host,
          videos: this.videosCollection.selected,
          basedVideos: true,
          is_not_assigned: true,
          typeStatus: this.typeStatus,
          isMulti: true,
          channel: this.data.profile,
          is_alt: this.is_api == 0 ? true : false
        }
      });
  
      this.attachSubscriptions(
        dialogRef.afterClosed().subscribe(result => {
          
          if (!!result && result.event == 'Add') {
            this.videosCollection.clear()
            this.page = 1;
            this.getVideos(this.page);
            this.getCreateTasks()
          }
        })
      )
    } else {
      const dialogRef = this.dialog.open(MobAddTaskComponent, {
        backdropClass: ['mob_interface_backdrop'],
        panelClass: ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'],
        disableClose: true,
        data: {
          company_id: this.data.company.id,
          company: this.data.company,
          imgRoute: this.imgRoute,
          host: this.host,
          videos: this.videosCollection.selected,
          basedVideos: true,
          is_not_assigned: true,
          isMulti: true,
          typeStatus: this.typeStatus,
          channel: this.data.profile,
          is_alt: this.is_api == 0 ? true : false
        }
      });
  
      this.attachSubscriptions(
        dialogRef.afterClosed().subscribe(result => {
          
          if (!!result && result.event == 'Add') {
            this.videosCollection.clear()
            this.page = 1;
            this.getVideos(this.page);
            this.getCreateTasks()
          }
        })
      )
    }
  }

  createTasks() {
    if (!this.is_mobile) {
      const dialogRef = this.dialog.open(CreateTaskComponent, {
        disableClose: true,
        data: {
          company_id: this.data.company.id,
          company: this.data.company,
          imgRoute: this.imgRoute,
          host: this.host,
          videos: this.videosCollection.selected,
          basedVideos: true,
          isMulti: true,
          channel: this.data.profile,
          is_alt: this.is_api == 0 ? true : false
        }
      });
  
      this.attachSubscriptions(
        dialogRef.afterClosed().subscribe(result => {
          
          if (!!result && result.event == 'Add') {
            this.videosCollection.clear()
            this.page = 1;
            this.getVideos(this.page);
          }
        })
      )
    } else {
      const dialogRef = this.dialog.open(MobAddTaskComponent, {
        backdropClass: ['mob_interface_backdrop'],
        panelClass: ['mob_interface_panel', 'animate__animated', 'animate__slideInUp'],
        disableClose: true,
        data: {
          company_id: this.data.company.id,
          company: this.data.company,
          imgRoute: this.imgRoute,
          host: this.host,
          videos: this.videosCollection.selected,
          basedVideos: true,
          isMulti: true,
          channel: this.data.profile,
          is_alt: this.is_api == 0 ? true : false
        }
      });

      this.attachSubscriptions(
        dialogRef.afterClosed().subscribe(result => {
          
          if (!!result && result.event == 'Add') {
            this.videosCollection.clear()
            this.page = 1;
            this.getVideos(this.page);
          }
        })
      )
    }
  }

  changeActiveStatus(val) {
    this.is_importing = false;
    this.activeStatus = val;
    this.videosCollection.clear()
    this.pagination = undefined
    this.page = 1;
    this.getVideos(this.page);
  }
  
  getVideos(page) {
    this.isLoad = true;
    let filterData:any = {
      channel_id: this.data.profile.id
    }
    if (this.activeStatus !== '') {
      filterData.is_task_assigned = this.activeStatus
    }
    if (!this.is_api) {
      filterData.type='channel' 
    }
    if (this.typeStatus == 'is_shorts') {
      filterData.is_shorts = 1; 
    } else if (this.typeStatus == 'is_regular') {
      filterData.is_regular = 1; 
    }

    this.attachSubscriptions(
      this.companyService.getVideos(page, this.data.company.id, filterData).pipe(
        tap(el => {
          this.pagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
        }),
        map(el => el.body)
      ).subscribe(resp => {
        this.isLoad = false;
        console.log("getVideos", resp)
        if (page == 1) {
          this.videos = resp
        } else {
          this.videos.push(...resp);
        }

        this.page = this.page + 1;
      }, error => {
        this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
      })
    )
  }

  updateVideosInChannelAccount(item) {
    if (item.is_active == 0) {
      this.layoutService.showSnackBar({name: ''}, marker("You need to authorize your profile. Click on the refresh icon."), SnackBarItem)
      return
    }
    console.log(item);
    this.attachSubscriptions(
      this.connectedAppsService.editChannelAccountComp(this.data.company.id, item.id, {is_update_data: 1}).subscribe(resp => {
        item.is_update_data = resp.is_update_data;
        console.log(resp)
      }, error => {
        let msg = error;
        if (error == "You can update data once per day, please wait.") {
          msg = msg + " Remaining: " + new TimeFormatPipe().transform(item.can_update_at)
        }
        this.layoutService.showSnackBar({name: ''}, msg, SnackBarItem)
      })
    )
  }

  updateVideos(profile) {
    this.attachSubscriptions(
      this.companyService.editCompanyGroupProfile(profile.id, {is_update_data: 1}).subscribe(resp => {
        profile.is_update_data = resp.is_update_data;
        console.log(resp)
      }, error => {
        let msg = error;
        if (error == "You can update data once per day, please wait.") {
          msg = msg + " Remaining: " + new TimeFormatPipe().transform(profile.can_update_at)
        }
        this.layoutService.showSnackBar({name: ''}, msg, SnackBarItem)
      })
    )
  }


  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  getCsrf() {
    this.attachSubscriptions(
      this.auth.$userToken.subscribe(resp => {
        this.user_id = resp.user_id;
        this.sm.localStorageSetItem("csrf_param", resp.csrf_param)
        this.sm.localStorageSetItem("csrf_token", resp.csrf_token)
      })
    )
  }

  getUser() {
    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        this.user = resp;
      })
    )
  }

  getPlatforms() {
    this.attachSubscriptions(
      this.companyService.getGroupPlatforms().subscribe(resp => {
        this.platforms = resp;
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
