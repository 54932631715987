import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { MembersService } from 'src/app/shared/services/rest/members.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';

@Component({
  selector: 'app-extra-ai-info',
  templateUrl: './extra-ai-info.component.html',
  styleUrls: ['./extra-ai-info.component.scss']
})
export class ExtraAiInfoComponent extends BaseClass implements OnInit, OnDestroy {
  public form: FormGroup;
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ExtraAiInfoComponent>,
    private fb: FormBuilder,
  ) { super() }

  ngOnInit(): void {

    this.form = this.fb.group({
      text: '100 symbols long title and put 2 hash tags in the end of title'
    })
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  onSubmit() {
    this.dialogRef.close({event: "update", data: this.form.value.text})
  }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
