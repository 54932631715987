import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { ReplaySubject, Subscription, forkJoin } from 'rxjs';
import { catchError, debounceTime, map, switchMap, tap } from 'rxjs/operators';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { ChatService } from 'src/app/shared/services/rest/chat.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { SnackBarItem } from '../snack-bar/snack-bar-item';
import { casesModel } from '../../functions/casesModel';

@Component({
  selector: 'app-select-job',
  templateUrl: './select-job.component.html',
  styleUrls: ['./select-job.component.scss']
})
export class SelectJobComponent extends BaseClass implements OnInit, OnDestroy {
  public isSubmit: boolean = false;

  public page: number = 1;
  public pagination: any;

  public cardJobsPage: number = 1;
  public cardJobsPagination: any;
  public task: any;

  public jobsSub: Subscription;

  // public jobControl: FormControl = new FormControl('');
  // public jobs$: ReplaySubject<any> = new ReplaySubject<any>(1);
  // public jobs: any[] = [];

  public selectedJob: any;

  public cardJobControl: FormControl = new FormControl('');
  public cardJobs$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public cardJobs: any[] = [];

  // public newJobControl: FormControl = new FormControl();
  // public newJobs$: ReplaySubject<any> = new ReplaySubject<any>(1);
  // public newJobs: any[] = [];
  // public newJobsPage: number = 1;
  // public newJobsPagination: any;

  public activeIndex = new FormControl(0);
  
  public is_getting: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<SelectJobComponent>,
    private fb: FormBuilder,
    private chatService: ChatService,
    private taskService: TaskService,
    private layoutService: LayoutService
  ) { super() }

  ngOnInit(): void {
    console.log("SelectJobComponent", this.data)
    this.dialogRef.addPanelClass("adding_executors_dialog")

    this.attachSubscriptions(
      this.cardJobControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onCardJobsSearch(resp))
    )
    this.getCardJobs();
  }

  neededData(task, company) {
    let arr = [
      this.chatService.getTasksChats(company.id, [task.id]).pipe(
        tap(res => {
          task['operations'].map(o_el => {
            o_el['chatsInfo'] = [];
            o_el['chatsInfo'].push(...res.filter(z => z.task_operation_id == o_el.id));
            o_el['unReadChats'] = res.filter(z => z.task_operation_id == o_el.id && z.is_read != '1').length;
            o_el['unClosedChats'] = res.filter(z => z.task_operation_id == o_el.id && z.status_id != '4').length;
          });
          task['chatsInfo'] = [];
          task['chatsInfo'].push(...res.filter(z => z.task_id == task.id));
          task['unReadChats'] = res.filter(z => z.is_read != '1').length;
          task['unClosedChats'] = res.filter(z => z.status_id != '4').length;
        })
      )
    ]
    if (task.company_id == company.id) {
      arr.push(
        this.taskService.getTaskClients(task.id, company.id).pipe(
          tap(res => {
            task.clients = res
          }),
          catchError(err => {
            return err
          })
        )
      )
    }
    return forkJoin(arr)
  }

  changeTab(val) {
    this.activeIndex.patchValue(val);
  }
  
  getCardJobs() {
    this.attachSubscriptions(
      this.taskService.getOneTaskExpand(this.data.company.id, this.data.project.task_id).pipe(
        map(x => x.body[0]),
        switchMap(x => this.neededData(x, this.data.company).pipe(map(() => casesModel([x], [], 'update')),map(x => x.arr[0]))),
      ).subscribe(resp => {
        this.task = resp;
        this.cardJobs = resp.operations.filter(k => k.id != this.data.project.task_operation_id);
        this.cardJobs$.next(this.cardJobs.slice());
      })
    )
  }

  selectJob(job) {
    this.selectedJob = JSON.parse(JSON.stringify(job));
    console.log("selectJob selectedJob", this.selectedJob);
  }

  onCardJobsSearch(resp) {
    if (!this.cardJobs) {
      return;
    }

    if (!resp) {
      this.cardJobs$.next(this.cardJobs.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.cardJobs$.next(
      this.cardJobs.filter(z => (!!z.operation && z.operation.name.toLowerCase().indexOf(resp) > -1) || z.id.toString().indexOf(resp) > -1 || (z.name && z.name.toLowerCase().indexOf(resp) > -1) || (z.comment && z.comment.toLowerCase().indexOf(resp) > -1))
    );
  }

  getCardJobById(id) {
    return this.cardJobs.find(x => x.id == id);
  }

  getPrimaryTag(job) {
    if (job.parameterValuesToTask && job.parameterValuesToTask.length) {
      return job.parameterValuesToTask.find(x => !!x.is_primary)
    } else {
      return undefined
    }
  }
  
  close() {
    this.dialogRef.close({event: "close", data: false})
  }
  
  onSubmit() {
    this.dialogRef.close({event: "select", data: {job: this.selectedJob, card: this.task}})
  }

  showError(error) {
    this.layoutService.showSnackBar({name: error}, marker("You do not have permission to do this"), SnackBarItem)
  }

  ngOnDestroy(): void {
    if (this.jobsSub) {
      this.jobsSub.unsubscribe()
    }
    this.clearSubscriptions()
  }
}
