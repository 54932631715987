<div class="companyes_page">
    <div class="cases_page" style="margin-bottom: 4px;">
        <div class="container">
          <div class="filter_out">  
            <div class="filter_values">
                <div class="d-f-c">

                </div>
              <!-- <mat-chip-list class="chips_wrp without_ordered">
    
    
                <mat-chip (removed)="removeValue('start_time_from')" *ngIf="filter.get('start_time_from').value">
                    {{ filter?.get('start_time_from').value | date:'MMM, y':"+0000":activeLang | yearPipe }}
                    <mat-icon class="remove_chip_btn" matChipRemove>cancel</mat-icon>
                </mat-chip>
    
                <mat-icon class="remove_all_chips_btn" (click)="removeValue('all')" *ngIf="!withoutFilter && (filter.get('start_time_from').value || filter.get('start_time_to').value)">cancel</mat-icon>
              </mat-chip-list> -->
      
              <div class="stats_wrp">
                <div class="tasks_counters">
                  <div class="counter_block" *ngIf="totalBalance">
                    <div class="counter_top">{{ "Balance" | translate }}</div>
                    <div class="counter_bottom">{{totalBalance}}</div>
                  </div>
                  <div class="counter_block" *ngIf="transactionsPagination && transactionsPagination['totalCount']">
                    <div class="counter_top">{{ "Transactions" | translate }}</div>
                    <div class="counter_bottom">{{transactionsPagination['totalCount']}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
    <div        
    class="root_users root_plans"  
    [appDeviceClass]="'custom-scroll custom-scroll-t'"      
    infinite-scroll
    [infiniteScrollDisabled]="isLoadTransactions"
    [scrollWindow]="false"
    [infiniteScrollUpDistance]="scrollUpDistance"
    [infiniteScrollThrottle]="throttle"
    (scrolled)="onDownTransactions($event)">
        <div class="server_item_out server_item_out_head">
            <form [formGroup]="transactionsFilter" class="server_item trans_item plan_item">
                <div class="d-f-c">
                    <div class="id">
                        {{ "ID" | translate }}
                    </div>
                    <div class="ex_id">
                        {{ "Period" | translate }}
                    </div>
                    <div class="msg">
                        {{ "Message" | translate }}
                    </div>
                    <!-- <div class="server">
                        <div>
                            {{ "Data" | translate }}
                        </div>
                        <input class="name_input" type="text" formControlName="q" (change)="filterData()">
                    </div>      -->
                    <div class="is_col is_col_price">
                        {{ "Price" | translate }}
                    </div>         
                    <div class="is_col is_col_price">
                        {{ "Discounted price" | translate }}
                    </div>         
                    <div class="is_col is_col_price">
                        {{ "Base price" | translate }}
                    </div>     
                    <div class="is_col is_col_price">
                        {{ "Wholesale discount" | translate }}
                    </div>     
                    <div class="is_col is_col_price">
                        {{ "Special discount" | translate }}
                    </div>     
                    <div class="is_col is_col_val">
                        {{ "Balance movement" | translate }}
                    </div>         
                    <div class="is_col is_col_val">
                        {{ "Balance" | translate }}
                    </div>         
                    <div class="role" style="flex-direction: column;">
                        <div>
                            {{ "Type" | translate }}
                        </div>
                        <mat-form-field appearance="outline" class="report_filter">
                            <!-- <mat-label>{{ 'Tags' | translate }}</mat-label> -->
                            <mat-select [disableOptionCentering]="true" selectDir formControlName="type" (selectionChange)="filterTransactionsData()">
                                <mat-option [value]="''">
                                    {{ 'All' | translate }}
                                </mat-option>
                                <mat-option [value]="'is_expense'">
                                    {{ 'Expense' | translate }}
                                </mat-option>
                                <mat-option [value]="'is_refill'">
                                    {{ 'Refill' | translate }}
                                </mat-option>
                                <mat-option [value]="'is_adjustment'">
                                    {{ 'Adjustment' | translate }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>   
                    <!-- <div class="role" style="flex-direction: column;">
                        <div>
                            {{ "State" | translate }}
                        </div>
                        <mat-form-field appearance="outline" class="report_filter">
                            <mat-select [disableOptionCentering]="true" selectDir formControlName="state" (selectionChange)="filterTransactionsData()">
                                <mat-option [value]="''">
                                    {{ 'All' | translate }}
                                </mat-option>
                                <mat-option [value]="'is_new'">
                                    {{ 'New' | translate }}
                                </mat-option>
                                <mat-option [value]="'is_done'">
                                    {{ 'Done' | translate }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>        -->
                    <div class="is_col" style="flex-direction: column;">
                        <div>
                            {{ "Hidden" | translate }}
                        </div>
                        <mat-form-field appearance="outline" class="report_filter">
                            <!-- <mat-label>{{ 'Tags' | translate }}</mat-label> -->
                            <mat-select [disableOptionCentering]="true" selectDir formControlName="is_hide" (selectionChange)="filterTransactionsData()">
                                <mat-option [value]="''">
                                    {{ 'All' | translate }}
                                </mat-option>
                                <mat-option [value]="1">
                                    {{ 'Visible' | translate }}
                                </mat-option>
                                <mat-option [value]="0">
                                    {{ 'Hidden' | translate }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="is_col" style="flex-direction: column;">
                        <div>
                            {{ "Is Applied" | translate }}
                        </div>
                        <mat-form-field appearance="outline" class="report_filter">
                            <!-- <mat-label>{{ 'Tags' | translate }}</mat-label> -->
                            <mat-select [disableOptionCentering]="true" selectDir formControlName="is_applied" (selectionChange)="filterTransactionsData()">
                                <mat-option [value]="''">
                                    {{ 'All' | translate }}
                                </mat-option>
                                <mat-option [value]="1">
                                    {{ 'Applied' | translate }}
                                </mat-option>
                                <mat-option [value]="0">
                                    {{ 'Not Applied' | translate }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div style="display: flex; align-items: center; justify-content: space-between !important; width: 80px;">
                    <div>
                        {{ "Log" | translate }}
                    </div>
                    <div style="padding-right: 10px;">
                        {{ "Action" | translate }}
                    </div>
                    <!-- <div class="check_wrp hidden_log">
                        <mat-checkbox color="primary" class="example-margin" (click)="$event.stopPropagation()"></mat-checkbox>
                    </div> -->
                </div>
            </form>
        </div>
        <ng-container *ngIf="transactions?.length">
            <div class="server_item_out" *ngFor="let item of transactions; let i = index;">
                <div class="server_item plan_item trans_item">
                    <div class="d-f-c">
                        <div class="id">
                            {{ item?.id }}
                        </div>
                        <div class="ex_id">
                            {{ item?.period_id }}
                        </div>
                        <div class="msg">
                            <p style="margin: 5px 0;">{{ item?.message }}</p>
                            <p style="font-size: 10px; color: #686868; margin: 5px 0;">{{ item?.message_root }}</p>
                        </div>
                        <!-- <div class="server">
                            <div [innerHTML]="item.data | json"></div>
                        </div> -->
                        <div class="is_col is_col_price">
                            {{item?.product_price}}
                        </div>         
                        <div class="is_col is_col_price">
                            {{item?.product_discounted_price}}
                        </div>         
                        <div class="is_col is_col_price">
                            {{item?.product_base_price}}
                        </div>     
                        <div class="is_col is_col_price">
                            {{wholeDisc(item)}}%
                        </div>     
                        <div class="is_col is_col_price">
                            {{specialDisc(item)}}%
                        </div>  
                        <div class="is_col is_col_val">
                            {{item?.balance_movement}}
                        </div>     
                        <div class="is_col is_col_val">
                            {{item?.balance}}
                        </div>     
                        <div class="role">
                            <ng-container *ngIf="item.is_expense">{{ 'Expense' | translate }}</ng-container>
                            <ng-container *ngIf="item.is_refill">{{ 'Refill' | translate }}</ng-container>
                            <ng-container *ngIf="item.is_adjustment">{{ 'Adjustment' | translate }}</ng-container>
                        </div>   
                        <!-- <div class="role">
                            <ng-container *ngIf="item.is_new">{{ 'New' | translate }}</ng-container>
                            <ng-container *ngIf="item.is_done">{{ 'Done' | translate }}</ng-container>
                        </div>        -->
                        <div class="is_col">
                            <ng-container *ngIf="item.is_hide == 1; else elseTemplateHideTransaction">
                                <mat-icon>visibility_off</mat-icon>
                            </ng-container>
                            <ng-template #elseTemplateHideTransaction>
                                <mat-icon>visibility</mat-icon>
                            </ng-template>
                        </div>       
                        <div class="is_col">
                            <ng-container *ngIf="item.is_applied == 1; else elseTemplateAppliedTransaction">
                                <mat-icon>done</mat-icon>
                            </ng-container>
                            <ng-template #elseTemplateAppliedTransaction>
                                <mat-icon>close</mat-icon>
                            </ng-template>
                        </div>       
                    </div>
                    <div style="display: flex; align-items: center; justify-content: flex-end !important;">
                        <button mat-icon-button style="color: #686868;" (click)="item.isOpen = !item.isOpen">
                            <mat-icon>
                                {{ item?.isOpen ? 'expand_less' : 'expand_more' }}
                            </mat-icon>
                        </button> 
                        <!-- <button mat-icon-button style="color: #686868;" [matMenuTriggerFor]="menuPlans">
                            <mat-icon>more_vert</mat-icon>
                        </button> 
                        <mat-menu #menuPlans="matMenu">
                            <button mat-menu-item (click)="addRootTransaction(item)">
                                <mat-icon>edit</mat-icon>
                                <span>{{"Edit"| translate}}</span>
                            </button>
                            <ng-container *ngIf="!item.is_applied">
                                <mat-divider></mat-divider>
                                <button mat-menu-item (click)="deleteTransaction(item, i)">
                                    <mat-icon>delete_forever</mat-icon>
                                    <span>{{"Delete"| translate}}</span>
                                </button>
                            </ng-container>
                        </mat-menu> -->
                        <!-- <div class="check_wrp">
                            <mat-checkbox color="primary" class="example-margin" (click)="$event.stopPropagation()" (change)="$event ? toggleServer(item) : null" [checked]='serversCollection.isSelected(item)'></mat-checkbox>
                        </div> -->
                    </div>
                </div>
                <div class="server_item_log" *ngIf="item.isOpen" [appDeviceClass]="'custom-scroll custom-scroll-t'">
                    <pre>
                        {{item|json}}
                    </pre>
                </div>
            </div>
        </ng-container>
    </div>
    <!-- <div style="padding: 15px 0;">
        <button class="add_btn" mat-raised-button color="primary" (click)="addRootTransaction()">
            <mat-icon>add</mat-icon>
            {{ "Transaction" | translate }}
        </button>
    </div> -->
</div>