import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ActivatedRoute, Router } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import * as moment from 'moment';
import { forkJoin, fromEvent, of, ReplaySubject } from 'rxjs';
import { last, map, switchMap, tap } from 'rxjs/operators';
import { prioritys } from 'src/app/shared/consts/prioritys';
import { BackJobToPrevStatusComponent } from 'src/app/shared/global_components/back-job-to-prev-status/back-job-to-prev-status.component';
import { BaseClass } from 'src/app/shared/models/base-class';
import { GlobalDataService } from 'src/app/shared/services/common/global-data.service';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { FileService } from 'src/app/shared/services/rest/file.service';
import { ParametersService } from 'src/app/shared/services/rest/parameters.service';
import { RefreshService } from 'src/app/shared/services/rest/refresh.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-files',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.scss']
})
export class FilesComponent extends BaseClass implements OnInit, OnDestroy {
  public company_id: any;
  public partners: any;
  public allValues: any;
  public valuesPage: number = 1;
  public filesID: any = this.generateRandomId();
  public valuesPagination: any;
  public company: any;
  public me: any;
  public user: any;
  public activeLang: any;
  public taskStatuses: any;
  public platforms: any;
  public host: any = environment.host;

  public groups_partner_id: any;

  // public groups: any;
  // public partnerGroups: any;

  public imgRoute: any = '';
  public profileStatuses: any;
  public allContentTypes: any;
  public profiles: any;
  public contentUrl = [
    {
      name: marker("Without content url"),
      id: 0
    },
    {
      name: marker("With content url"),
      id: 1
    }
  ]
  public employees: any;
  public operationsValues: any;
  public checklists: any;
  public bookmarks: any;
  public sortValue: any = 'filename';
  public prioritys = prioritys;
  public is_mobile: boolean = false;
  public filterCount: number = 1;
  public bookmarksControl: FormControl = new FormControl();
  public bookmarks$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public filter: FormGroup = this.fb.group({
    count: '',
    q: '',
    task_id: 0,
    task_operation_id: 0,
    task_status_id: '',
    group_id: [[]],
    content_type_id: '',
    operation_reminder_id: '',
    meta_device: '',
    related_count: '',
    part_of_count: '',
    consist_of_count: '',
    is_ready_if_approved: '',
    operation_status_id: [0],
    task_parameter_value_id: [[]],
    task_operation_parameter_value_id : [[]],
    discussion_parameter_value_id: [[]],
    file_parameter_value_id : [[]],
    parameter_value_id: [[]],
    operation_employee_id: [[]],
    operation_partner_company_id: [[]],
    client_employee_id : '',
    origin_company_id : '',
    channel_platform_id : '',
    group_by : 'original_file_id',
    channel_id: [[]],
    no_channel_id: [[]],
    priority: '',
    no_operation_id: '',
    task_operation_operation_id: '',
    operation_completed_at_from : '',
    operation_completed_at_to : '',
    is_content_url: '',
    task_custom_id: '',
    file_id: '',
    task_channel_file_content_status_id: '',
    content_type: '',
    order_by: 'updated_desc',
    content_status_id: '',
    id: '',
    internal_id: "",
    filesize_from: "",
    filesize_to: "",
    meta_duration_from: "",
    meta_duration_to: "",
    preset_id: "",
    task_company_id: '',
    discussion_id: "",
    // task_operation_id: "",
    q_task_name: "",
    q_task_comment: "",
    q_task_private_comment: "",
    q_task_operation_comment: "",
    q_task_operation_employee_comment: "",
    q_task_operation_private_comment: "",
    task_created_at_from: "",
    task_created_at_to: "",
    task_operation_created_at_from: "",
    task_operation_created_at_to: "",
    task_operation_completed_at_from: "",
    task_operation_completed_at_to: "",
    discussion_created_at_from: "",
    discussion_created_at_to: "",
    file_created_at_from: "",
    file_created_at_to: "",
    file_updated_at_from: "",
    file_updated_at_to: "",
    file_meta_creation_time_from: "",
    file_meta_creation_time_to: "",
    task_channel_published_at_from: "",
    task_channel_published_at_to: "",
    location_all: "/",
  });
  
  constructor(
    public activatedRoute: ActivatedRoute,
    public fb: FormBuilder,
    public languageService: LanguageService,
    public router: Router,
    public fileService: FileService,
    public taskService: TaskService,
    private auth: AuthService,
    public bottomSheet: MatBottomSheet,
    public globalDataService: GlobalDataService,
    private sm: StorageManagerService,
    public parametersService: ParametersService,
    public refreshService: RefreshService,
    public companyService: CompanyService,
  ) { super() }

  ngOnInit(): void {
    this.company_id = this.activatedRoute.snapshot.queryParamMap.get('company_id');
    if (window.innerWidth <= 769) {
      this.is_mobile = true;
    } else {
      this.is_mobile = false;
    }

    this.getUser()
    this.getImgRoute();
    this.onResize();
    this.getLangData();
    this.attachSubscriptions(
      this.refreshService.getRefreshBookmarks().subscribe(res => {
        if (res == "Files") {
          this.getBookmarks()
        }
      })
    )

    this.groups_partner_id = this.activatedRoute.snapshot.queryParamMap.get('groups_partner_id');
    // if (!!this.groups_partner_id) {
    //   this.getPartnerGroups()
    // }

    this.getPartners();
    this.getAllValues();
    // this.getGroupsCompany();
    this.getProfiles();
    this.getTaskStatus();
    this.getChecklists();
    this.getProfilesStatus();
    this.getEmployees();
    this.getPlatforms();
    this.getContentTypes();
    this.onRouteChange();
  }


  getPriority(id) {
    if (!this.prioritys) {
      return false;
    }
    return this.prioritys.find(el => el.id == id)
  } 

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  getLangData() {
    this.attachSubscriptions(
      this.languageService.getLangData().pipe(
        tap(resp => {
          this.activeLang = resp.active;
        }),
        switchMap(() => this.taskService.getOperations(this.company_id, this.activeLang).pipe(
          tap(x => {
            this.operationsValues = x;
          })
        ))
      ).subscribe(resp => {
        console.log("getOperations", resp)
      })
    )
  }
  
  removeValueFromMultiple(obj) {
    let key = obj.key;
    let value = obj.id;
    let arr = this.filter.value[key];

    console.log(key, value);
    console.log(arr);
    
    if (arr.includes(value)) {
      arr.splice(arr.indexOf(value), 1)
    }
    console.log(arr);
    console.log(this.filter.value[key]);
    // return 
    // this.filter.patchValue({
    //   [key]: arr
    // })
    this.filterFiles()
  }

  changeGroup(e) {
    console.log(e);

    this.filter.patchValue({
      group_by : e.checked ? 'original_file_id': 'id'
    })

    this.filterFiles()
  }

  getUser() {
    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        console.log("USER", resp)
        this.user = resp;
      })
    )
  }

  removeValue(val) {
    if (val == "all") {
      let params = {
        company_id: this.company_id,
        location_all: '/',
      }
      this.router.navigate(['/files'], {queryParams: params})
    } else {
      let params = {...this.activatedRoute.queryParamMap.source['_value']}
      delete params[val]

      this.router.navigate(['/files'], {queryParams: params})
    }
  }

  getPartners() {
    this.attachSubscriptions(
      this.companyService.getPartners({company_id: this.company_id}).subscribe(resp => {
        this.partners = resp.filter(x => x.partner_company_id != 0);
      })
    )
  }

  filterFiles() {
    let params = {
      company_id: this.company_id
    }

    let filterData = {...this.filter.value};

    console.log("filterData", filterData)
    
    Object.keys(filterData).filter(x => x != 'preset_id').forEach(element => {
      if (['is_content_url', 'part_of_count', 'consist_of_count', 'related_count'].includes(element) && filterData[element] == '0') {
        params[element] = filterData[element]
      } else if (["group_id", "channel_id", "no_channel_id", "operation_employee_id", "operation_partner_company_id", 'task_parameter_value_id', 'parameter_value_id', 'file_parameter_value_id'].includes(element) && filterData[element].length > 0) {
        params[element] = filterData[element].join(',')
      } else if (filterData[element] == '0' && filterData[element] == 0 && (element == 'operation_employee_id' || element == 'operation_partner_company_id')) {
        params[element] = filterData[element]
      } else if (filterData[element] != '0' && filterData[element] != '') {
        params[element] = filterData[element]
      }
    });


    // console.log("filterData.operation_completed_at_to", filterData.operation_completed_at_to)
    if (filterData.operation_completed_at_to === 0) {
      params['operation_completed_at_to'] = '0';
    }

    this.router.navigate(['/files'], {queryParams: params})
  }

  getAllValues() {
    this.attachSubscriptions(
      this.parametersService.getPaginationAllApiParameterValues(this.valuesPage, this.company_id).pipe(
        tap(el => {
          if (el.headers.get('x-pagination-current-page') == 1) {
            this.valuesPagination = {
              'pageCount': el.headers.get('x-pagination-page-count'),
              'perPage': el.headers.get('x-pagination-per-page'),
              'totalCount': el.headers.get('x-pagination-total-count'),
              'currentPage': el.headers.get('x-pagination-current-page'),
            }
            this.allValues = el.body;
            this.allValues.unshift({
              company_id: this.company_id,
              created_at: 0,
              created_user_id: 0,
              id: -2,
              is_not_automatically_assigned: 0,
              order: 0,
              original_company_id: this.company_id,
              original_id: -2,
              original_user_id: 0,
              parameter: null,
              parameter_id: 0,
              parent_value_id: 0,
              system_value_id: 0,
              task_acm: "",
              task_id: 0,
              tips_count: 0,
              updated_at: 0,
              updated_user_id: 0,
              value: "RePost Docs"
            })

            this.allValues.unshift({
              company_id: this.company_id,
              created_at: 0,
              created_user_id: 0,
              id: -3,
              is_not_automatically_assigned: 0,
              order: 0,
              original_company_id: this.company_id,
              original_id: -3,
              original_user_id: 0,
              parameter: null,
              parameter_id: 0,
              parent_value_id: 0,
              system_value_id: 0,
              task_acm: "",
              task_id: 0,
              tips_count: 0,
              updated_at: 0,
              updated_user_id: 0,
              value: "ClaRe docs"
            })

            this.allValues.unshift({
              company_id: this.company_id,
              created_at: 0,
              created_user_id: 0,
              id: -1,
              is_not_automatically_assigned: 0,
              order: 0,
              original_company_id: this.company_id,
              original_id: -1,
              original_user_id: 0,
              parameter: null,
              parameter_id: 0,
              parent_value_id: 0,
              system_value_id: 0,
              task_acm: "",
              task_id: 0,
              tips_count: 0,
              updated_at: 0,
              updated_user_id: 0,
              value: "Re docs"
            })

            if (this.user.is_root) {
              this.allValues.unshift({
                company_id: this.company_id,
                created_at: 0,
                created_user_id: 0,
                id: -4,
                is_not_automatically_assigned: 0,
                order: 0,
                original_company_id: this.company_id,
                original_id: -4,
                original_user_id: 0,
                parameter: null,
                parameter_id: 0,
                parent_value_id: 0,
                system_value_id: 0,
                task_acm: "",
                task_id: 0,
                tips_count: 0,
                updated_at: 0,
                updated_user_id: 0,
                value: "Live stream"
              })
            }
          }
          console.log("getAllApiParameterValues", this.allValues)
        }),
        switchMap(el => {
          let pages = [] 
          for (let index = 2; index <= +this.valuesPagination.pageCount; index++) {
            pages.push(index)
          }
  
          console.log("special pages", pages)
          if (pages.length > 0) {
            return forkJoin(pages.map(x => this.parametersService.getPaginationAllApiParameterValues(x, this.company_id))).pipe(
              tap(val => {
                val.forEach(p => {
                  this.allValues.push(...p.body)
                })
              })
            )
          } else {
            return of(el)
          }
  
        })
      ).subscribe(resp => {
          console.log("files VALUES", this.allValues)
      })
    )
  }

  generateRandomId(): string {
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    const length = 10;
    let randomId = '';
  
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomId += characters.charAt(randomIndex);
    }
  
    return randomId;
  }

  clearFolder() {
    let confirmAlert =  this.bottomSheet.open(BackJobToPrevStatusComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        msg: `Do you also want to ${this.isTrash(this.filter.get('location_all').value) ? "Delete" : "Move to trash"} copies of files?`,
        btn_no: "No",
        btn_yes: "Yes"
      }
    });

    confirmAlert.afterDismissed().subscribe(data => {
      if (data && data.message == 'no') {
        this.fileService.clearTrash$.next({id: this.filesID, is_delete: this.filter.get('location_all').value.indexOf('trash') != -1, is_delete_copies: false})
      } else if (data && data.message == 'yes') {
        this.fileService.clearTrash$.next({id: this.filesID, is_delete: this.filter.get('location_all').value.indexOf('trash') != -1, is_delete_copies: true})
      }
    })
    
  }

  isTrash(str) {
    return str.indexOf('trash') != -1
  }

  getValueById(id) {
    if (!this.allValues || this.allValues.length == 0) {
      return null
    }
    return this.allValues.find(x => x.id == id)
  }

  onResize() {
    this.attachSubscriptions(
      fromEvent(window, "resize").pipe(
        map(() => window.innerWidth)
      ).subscribe((wWidth) => {
          if (wWidth <= 769) {
            this.is_mobile = true;
          } else {
            this.is_mobile = false;
          }
        }
      )
    )
  }
  
  getBookmarks() {
    this.attachSubscriptions(
      this.companyService.getBookmarks(this.company_id, {employee_id: this.company.employees[0].id, section: "Files"}).subscribe(res => {
        this.bookmarks = res
        this.bookmarks$.next(this.bookmarks.slice())
        console.log("bookmarks", this.bookmarks)
      })
    )
  }

  getPartner(id) {
    if (!this.partners) {
      return false;
    }
    return this.partners.find(el => el.partner_company_id == id)
  }  

  indicator() {

    let paramsData = {...this.filter.value};
    if (paramsData.location_all == '/') {
      delete paramsData.location_all;
    }
    delete paramsData.count;
    delete paramsData.location;
    delete paramsData.operation_status_id;
    delete paramsData.task_id;
    delete paramsData.task_operation_id;
    delete paramsData.order_by;
    delete paramsData.group_by;

    // console.log("paramsData", paramsData);
    // console.log("Object.values(paramsData)", Object.values(paramsData));
    // console.log("Object.values(paramsData).filter(x => x != '' && !!x)", Object.values(paramsData).filter(x => x != "" && !!x));
    return Object.values(paramsData).filter(x => x != "" && !!x).length > 0
  }

  refreshPage() {
    this.filterFiles();
  }

  onRouteChange() {
    this.attachSubscriptions(
      this.activatedRoute.queryParams.pipe(
        tap((val) => {
          if (this.company_id != this.activatedRoute.snapshot.queryParamMap.get('company_id')) {
            this.getPartners();
            this.getAllValues();
            // this.getGroupsCompany();
            this.getProfiles();
            this.getTaskStatus();
            this.getProfilesStatus();
            this.getEmployees();
            this.getChecklists();
            this.getPlatforms();
            this.getContentTypes();
            this.getLangData();
          }
          this.company_id = this.activatedRoute.snapshot.queryParamMap.get('company_id');
          this.sortValue = this.activatedRoute.snapshot.queryParamMap.get('order_by') || 'filename';
          this.filter.patchValue({
            location_all: this.activatedRoute.snapshot.queryParamMap.get('location_all') ? this.activatedRoute.snapshot.queryParamMap.get('location_all') : '/',
            is_ready_if_approved: !!this.activatedRoute.snapshot.queryParamMap.get('is_ready_if_approved') ? this.activatedRoute.snapshot.queryParamMap.get('is_ready_if_approved') : '',
            related_count: !!this.activatedRoute.snapshot.queryParamMap.get('related_count') ? this.activatedRoute.snapshot.queryParamMap.get('related_count') : '',
            part_of_count: !!this.activatedRoute.snapshot.queryParamMap.get('part_of_count') ? this.activatedRoute.snapshot.queryParamMap.get('part_of_count') : '',
            consist_of_count: !!this.activatedRoute.snapshot.queryParamMap.get('consist_of_count') ? this.activatedRoute.snapshot.queryParamMap.get('consist_of_count') : '',
            meta_device: !!this.activatedRoute.snapshot.queryParamMap.get('meta_device') ? this.activatedRoute.snapshot.queryParamMap.get('meta_device') : '',
            operation_reminder_id: !!this.activatedRoute.snapshot.queryParamMap.get('operation_reminder_id') ? +this.activatedRoute.snapshot.queryParamMap.get('operation_reminder_id') : '',
            count: this.activatedRoute.snapshot.queryParamMap.get('count') ? +this.activatedRoute.snapshot.queryParamMap.get('count') : 1,
            id: this.activatedRoute.snapshot.queryParamMap.get('id') ? +this.activatedRoute.snapshot.queryParamMap.get('id') : '',
            q: !!this.activatedRoute.snapshot.queryParamMap.get('q') ? this.activatedRoute.snapshot.queryParamMap.get('q') : "",
            no_operation_id: !!this.activatedRoute.snapshot.queryParamMap.get('no_operation_id') ? +this.activatedRoute.snapshot.queryParamMap.get('no_operation_id') : "",
            no_channel_id: !!this.activatedRoute.snapshot.queryParamMap.get('no_channel_id') ? this.activatedRoute.snapshot.queryParamMap.get('no_channel_id').split(',').map(Number) : [],
            channel_id: !!this.activatedRoute.snapshot.queryParamMap.get('channel_id') ? this.activatedRoute.snapshot.queryParamMap.get('channel_id').split(',').map(Number) : [],
            priority: !!this.activatedRoute.snapshot.queryParamMap.get('priority') ? +this.activatedRoute.snapshot.queryParamMap.get('priority') : "",
            task_id: !!this.activatedRoute.snapshot.queryParamMap.get('task_id') ? +this.activatedRoute.snapshot.queryParamMap.get('task_id') : 0,
            group_by: !!this.activatedRoute.snapshot.queryParamMap.get('group_by') ? this.activatedRoute.snapshot.queryParamMap.get('group_by') : 'original_file_id',
            task_operation_id: !!this.activatedRoute.snapshot.queryParamMap.get('task_operation_id') ? +this.activatedRoute.snapshot.queryParamMap.get('task_operation_id') : 0,
            task_status_id: !!this.activatedRoute.snapshot.queryParamMap.get('task_status_id') ? +this.activatedRoute.snapshot.queryParamMap.get('task_status_id') : '',
            content_status_id: !!this.activatedRoute.snapshot.queryParamMap.get('content_status_id') ? +this.activatedRoute.snapshot.queryParamMap.get('content_status_id') : '',
            group_id: !!this.activatedRoute.snapshot.queryParamMap.get('group_id') ? this.activatedRoute.snapshot.queryParamMap.get('group_id').split(',').map(Number) : [],
            content_type_id: !!this.activatedRoute.snapshot.queryParamMap.get('content_type_id') ? +this.activatedRoute.snapshot.queryParamMap.get('content_type_id') : "",
            operation_status_id: !!this.activatedRoute.snapshot.queryParamMap.get('operation_status_id') ? this.activatedRoute.snapshot.queryParamMap.get('operation_status_id').split(',').map(Number) : [0],
            task_parameter_value_id: !!this.activatedRoute.snapshot.queryParamMap.get('task_parameter_value_id') ? this.activatedRoute.snapshot.queryParamMap.get('task_parameter_value_id').split(',').map(Number) : [],
            task_operation_parameter_value_id: !!this.activatedRoute.snapshot.queryParamMap.get('task_operation_parameter_value_id') ? this.activatedRoute.snapshot.queryParamMap.get('task_operation_parameter_value_id').split(',').map(Number) : [],
            discussion_parameter_value_id: !!this.activatedRoute.snapshot.queryParamMap.get('discussion_parameter_value_id') ? this.activatedRoute.snapshot.queryParamMap.get('discussion_parameter_value_id').split(',').map(Number) : [],
            file_parameter_value_id: !!this.activatedRoute.snapshot.queryParamMap.get('file_parameter_value_id') ? this.activatedRoute.snapshot.queryParamMap.get('file_parameter_value_id').split(',').map(Number) : [],
            parameter_value_id: !!this.activatedRoute.snapshot.queryParamMap.get('parameter_value_id') ? this.activatedRoute.snapshot.queryParamMap.get('parameter_value_id').split(',').map(Number) : [],
            operation_employee_id: !!this.activatedRoute.snapshot.queryParamMap.get('operation_employee_id') ? [+this.activatedRoute.snapshot.queryParamMap.get('operation_employee_id')] : [],
            operation_partner_company_id: !!this.activatedRoute.snapshot.queryParamMap.get('operation_partner_company_id') ? [+this.activatedRoute.snapshot.queryParamMap.get('operation_partner_company_id')] : [],
            client_employee_id: !!this.activatedRoute.snapshot.queryParamMap.get('client_employee_id') ? +this.activatedRoute.snapshot.queryParamMap.get('client_employee_id') : '',
            origin_company_id: !!this.activatedRoute.snapshot.queryParamMap.get('origin_company_id') ? +this.activatedRoute.snapshot.queryParamMap.get('origin_company_id') : '',
            channel_platform_id: !!this.activatedRoute.snapshot.queryParamMap.get('channel_platform_id') ? +this.activatedRoute.snapshot.queryParamMap.get('channel_platform_id') : '',
            task_operation_operation_id: !!this.activatedRoute.snapshot.queryParamMap.get('task_operation_operation_id') ? +this.activatedRoute.snapshot.queryParamMap.get('task_operation_operation_id').split(',')[0] : '',
            operation_completed_at_from: !!this.activatedRoute.snapshot.queryParamMap.get('operation_completed_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('operation_completed_at_from')*1000).utc() : "",
            operation_completed_at_to: this.activatedRoute.snapshot.queryParamMap.get('operation_completed_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('operation_completed_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('operation_completed_at_to')*1000).utc() : ""),
            order_by: !!this.activatedRoute.snapshot.queryParamMap.get('order_by') ? this.activatedRoute.snapshot.queryParamMap.get('order_by') : "updated_desc",
            is_content_url: (!!this.activatedRoute.snapshot.queryParamMap.get('is_content_url') || this.activatedRoute.snapshot.queryParamMap.get('is_content_url') == '0') ? +this.activatedRoute.snapshot.queryParamMap.get('is_content_url') : "", 
            task_custom_id: this.activatedRoute.snapshot.queryParamMap.get('task_custom_id') ? +this.activatedRoute.snapshot.queryParamMap.get('task_custom_id') : '',
            file_id: this.activatedRoute.snapshot.queryParamMap.get('file_id') ? +this.activatedRoute.snapshot.queryParamMap.get('file_id') : '',
            task_channel_file_content_status_id: this.activatedRoute.snapshot.queryParamMap.get('task_channel_file_content_status_id') ? this.activatedRoute.snapshot.queryParamMap.get('task_channel_file_content_status_id') : '',
            content_type: this.activatedRoute.snapshot.queryParamMap.get('content_type') ? this.activatedRoute.snapshot.queryParamMap.get('content_type') : '',
            internal_id: this.activatedRoute.snapshot.queryParamMap.get('internal_id') ? +this.activatedRoute.snapshot.queryParamMap.get('internal_id') : '',
            filesize_from: this.activatedRoute.snapshot.queryParamMap.get('filesize_from') ? +this.activatedRoute.snapshot.queryParamMap.get('filesize_from') : '',
            filesize_to: this.activatedRoute.snapshot.queryParamMap.get('filesize_to') ? +this.activatedRoute.snapshot.queryParamMap.get('filesize_to') : '',
            meta_duration_from: this.activatedRoute.snapshot.queryParamMap.get('meta_duration_from') ? +this.activatedRoute.snapshot.queryParamMap.get('meta_duration_from') : '',
            meta_duration_to: this.activatedRoute.snapshot.queryParamMap.get('meta_duration_to') ? +this.activatedRoute.snapshot.queryParamMap.get('meta_duration_to') : '',
            preset_id: this.activatedRoute.snapshot.queryParamMap.get('preset_id') ? +this.activatedRoute.snapshot.queryParamMap.get('preset_id') : '',
            task_company_id: this.activatedRoute.snapshot.queryParamMap.get('task_company_id') ? +this.activatedRoute.snapshot.queryParamMap.get('task_company_id') : '',
            discussion_id: this.activatedRoute.snapshot.queryParamMap.get('discussion_id') ? +this.activatedRoute.snapshot.queryParamMap.get('discussion_id') : '',
            // task_operation_id: this.activatedRoute.snapshot.queryParamMap.get('task_operation_id') ? +this.activatedRoute.snapshot.queryParamMap.get('task_operation_id') : '',
            q_task_name: this.activatedRoute.snapshot.queryParamMap.get('q_task_name') ? this.activatedRoute.snapshot.queryParamMap.get('q_task_name') : '',
            q_task_comment: this.activatedRoute.snapshot.queryParamMap.get('q_task_comment') ? this.activatedRoute.snapshot.queryParamMap.get('q_task_comment') : '',
            q_task_private_comment: this.activatedRoute.snapshot.queryParamMap.get('q_task_private_comment') ? this.activatedRoute.snapshot.queryParamMap.get('q_task_private_comment') : '',
            q_task_operation_comment: this.activatedRoute.snapshot.queryParamMap.get('q_task_operation_comment') ? this.activatedRoute.snapshot.queryParamMap.get('q_task_operation_comment') : '',
            q_task_operation_employee_comment: this.activatedRoute.snapshot.queryParamMap.get('q_task_operation_employee_comment') ? this.activatedRoute.snapshot.queryParamMap.get('q_task_operation_employee_comment') : '',
            q_task_operation_private_comment: this.activatedRoute.snapshot.queryParamMap.get('q_task_operation_private_comment') ? this.activatedRoute.snapshot.queryParamMap.get('q_task_operation_private_comment') : '',
            task_created_at_from: this.activatedRoute.snapshot.queryParamMap.get('task_created_at_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('task_created_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('task_created_at_from')*1000) : ""),
            task_created_at_to: this.activatedRoute.snapshot.queryParamMap.get('task_created_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('task_created_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('task_created_at_to')*1000) : ""),
            task_operation_created_at_from: this.activatedRoute.snapshot.queryParamMap.get('task_operation_created_at_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('task_operation_created_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('task_operation_created_at_from')*1000) : ""),
            task_operation_created_at_to: this.activatedRoute.snapshot.queryParamMap.get('task_operation_created_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('task_operation_created_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('task_operation_created_at_to')*1000) : ""),
            task_operation_completed_at_from: this.activatedRoute.snapshot.queryParamMap.get('task_operation_completed_at_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('task_operation_completed_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('task_operation_completed_at_from')*1000) : ""),
            task_operation_completed_at_to: this.activatedRoute.snapshot.queryParamMap.get('task_operation_completed_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('task_operation_completed_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('task_operation_completed_at_to')*1000) : ""),
            discussion_created_at_from: this.activatedRoute.snapshot.queryParamMap.get('discussion_created_at_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('discussion_created_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('discussion_created_at_from')*1000) : ""),
            discussion_created_at_to: this.activatedRoute.snapshot.queryParamMap.get('discussion_created_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('discussion_created_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('discussion_created_at_to')*1000) : ""),
            file_created_at_from: this.activatedRoute.snapshot.queryParamMap.get('file_created_at_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('file_created_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('file_created_at_from')*1000) : ""),
            file_created_at_to: this.activatedRoute.snapshot.queryParamMap.get('file_created_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('file_created_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('file_created_at_to')*1000) : ""),
            file_updated_at_from: this.activatedRoute.snapshot.queryParamMap.get('file_updated_at_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('file_updated_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('file_updated_at_from')*1000) : ""),
            file_updated_at_to: this.activatedRoute.snapshot.queryParamMap.get('file_updated_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('file_updated_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('file_updated_at_to')*1000) : ""),
            file_meta_creation_time_from: this.activatedRoute.snapshot.queryParamMap.get('file_meta_creation_time_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('file_meta_creation_time_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('file_meta_creation_time_from')*1000) : ""),
            file_meta_creation_time_to: this.activatedRoute.snapshot.queryParamMap.get('file_meta_creation_time_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('file_meta_creation_time_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('file_meta_creation_time_to')*1000) : ""),
            task_channel_published_at_from: this.activatedRoute.snapshot.queryParamMap.get('task_channel_published_at_from') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('task_channel_published_at_from') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('task_channel_published_at_from')*1000) : ""),
            task_channel_published_at_to: this.activatedRoute.snapshot.queryParamMap.get('task_channel_published_at_to') == '0' ? 0 : (!!this.activatedRoute.snapshot.queryParamMap.get('task_channel_published_at_to') ? moment(+this.activatedRoute.snapshot.queryParamMap.get('task_channel_published_at_to')*1000) : ""),
          })

          console.log("FILTER VALUE", this.filter.value)
        }),
        switchMap((val) => {
          console.log("switchMap", this.company)
          if (this.company == undefined || this.company.id != this.activatedRoute.snapshot.queryParamMap.get('company_id')) {
            return this.companyService.getCompany(this.activatedRoute.snapshot.queryParamMap.get('company_id')).pipe(
              map(x => x[0]),
              tap(x => {
                this.company = x;
                this.me = this.company.employees[0];
                this.companyService.company$.next(x);
              }),
              map(x => val)
            )
          } else {
            return of(val)
          }
        }),
      ).subscribe(params => {
        console.log("Files route STREAM", params);
        if (!!this.activatedRoute.snapshot.queryParamMap.get('groups_partner_id') && this.groups_partner_id != +this.activatedRoute.snapshot.queryParamMap.get('groups_partner_id')) {
          this.groups_partner_id = +this.activatedRoute.snapshot.queryParamMap.get('groups_partner_id');
          // this.getPartnerGroups()
        }
        this.getBookmarks();
      })
    )
  }

  // getPartnerGroups() {
  //   this.attachSubscriptions(
  //     this.companyService.getInfiniteGroupsPartnerCompany(this.company_id, this.groups_partner_id, '1', '1').pipe(
  //       switchMap(el => {
  //         let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
  //         let arr = []
  //         for (let index = 1; index <= pages; index++) {
  //           arr.push(index)
  //         }

  //         return forkJoin(arr.map(x => this.companyService.getInfiniteGroupsPartnerCompany(this.company_id, this.groups_partner_id, x, '200').pipe(map(u => u.body)))).pipe(
  //           last(),
  //         )
  //       }),
  //     ).subscribe(res => {
  //       this.partnerGroups = [].concat(...res);
  //       console.log("getPartnerGroups", this.partnerGroups);
  //     })
  //   )
  // }

  // getPartnerGroup(id) {
  //   if (!this.partnerGroups || !this.partnerGroups.find(el => el.id == id)) {
  //     return false;
  //   }
  //   return this.partnerGroups.find(el => el.id == id)
  // } 

  getContentTypes() {
    this.attachSubscriptions(
      this.companyService.getContentTypes().pipe(
        map(el => el.body)
      ).subscribe(resp => {
        this.allContentTypes = resp;
      })
    )
  }

  getEmployees() {
    this.attachSubscriptions(
      this.taskService.getEmployees(this.company_id).subscribe(resp => {
        this.employees = resp;
      })
    )
  }
  
  getColumnName(id) {
    if (!this.operationsValues || this.operationsValues.filter(el => el.id == id).length == 0) {
      return '';
    }
    return this.operationsValues.find(el => el.id == id)?.translation?.name ? this.operationsValues.find(el => el.id == id).translation.name : this.operationsValues.find(el => el.id == id).name
  }

  bookmarksChange(e) {
    this.router.navigate(['/files'], { queryParams: Object.assign({company_id: this.company_id, order_by: "updated_desc", preset_id: e.value}, this.getBookmarkById(e.value).settings)});
  }

  getBookmarkById(id) {
    if (!this.bookmarks || this.bookmarks.length == 0) {
      return null
    }
    return this.bookmarks.find(x => x.id == id)
  }

  getProfile(id) {
    if (!this.profiles || !this.profiles.find(el => el.id == id)) {
      return false;
    }
    return this.profiles.find(el => el.id == id)
  }  

  getContentById(id) {
    if (!this.allContentTypes || this.allContentTypes.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.allContentTypes.find(el => el.id == id)
  }

  getPlatforms() {
    this.attachSubscriptions(
      this.companyService.getGroupPlatforms().subscribe(resp => {
        resp.unshift({
          id:	0,
          name: "Without platform",
          url: '',
          created_at:	0,
          updated_at:	0,
        })
        this.platforms = resp;
        console.log("this.platforms", this.platforms)
      })
    )
  }

  getEmployee(id) {
    if (!this.employees) {
      return false;
    }
    return this.employees.find(el => el.id == id)
  }  

  getPlatformById(id) {
    if (!this.platforms || this.platforms.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.platforms.find(el => el.id == id)
  }

  getTaskStatus() {
    this.attachSubscriptions(
      this.taskService.getTaskStatuses(this.company_id).subscribe(resp => {
        this.taskStatuses = resp.slice()
        this.taskStatuses.unshift({
          id: 0,
          name: marker('All statuses')
        });
      })
    )
  }

  getProfilesStatus() {
    this.attachSubscriptions(
      this.globalDataService.getContentStatus().subscribe(resp => {
        console.log("getProfilesStatus", resp)
        this.profileStatuses = resp.slice();
      })
    )
  }

  getProfileStatus(id) {
    if (!this.profileStatuses) {
      return false;
    }
    return this.profileStatuses.find(el => el.id == id)
  }  

  getTaskStatusById(id) {
    if (!this.taskStatuses || this.taskStatuses.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.taskStatuses.find(el => el.id == id)
  }

  // getGroup(id) {
  //   if (!this.groups || !this.groups.find(el => el.id == id)) {
  //     return false;
  //   }
  //   return this.groups.find(el => el.id == id)
  // }  

  getChecklist(id) {
    if (!this.checklists || !this.checklists.find(el => el.id == id)) {
      return false;
    }
    return this.checklists.find(el => el.id == id)
  }  

  getProfiles() {
    this.attachSubscriptions(
      this.companyService.getProfiles(this.company_id).pipe(
        map(el => el.body)
      ).subscribe(resp => {
        console.log("getProfiles", resp)
        this.profiles = resp
      })
    )
  }

  getChecklists() {
    this.attachSubscriptions(
      this.taskService.getOperationReminder(this.company_id).subscribe(resp => {
        this.checklists = resp.body;
        console.log("getChecklists", this.checklists)
      })
    )
  }

  // getGroupsCompany() {
  //   this.attachSubscriptions(
  //     this.companyService.getInfiniteGroupsCompany(this.company_id, '1', '1').pipe(
  //       switchMap(el => {
  //         let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
  //         let arr = []
  //         for (let index = 1; index <= pages; index++) {
  //           arr.push(index)
  //         }

  //         return forkJoin(arr.map(x => this.companyService.getInfiniteGroupsCompany(this.company_id, x).pipe(map(u => u.body)))).pipe(
  //           last(),
  //         )
  //       }),
  //     ).subscribe(res => {
  //       this.groups = [].concat(...res);
  //     })
  //   )
  // }

  ngOnDestroy(): void {
    this.clearSubscriptions()
  }

}
