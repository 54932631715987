import { HttpParams, HttpUrlEncodingCodec } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject, Subject } from 'rxjs';
import { GlobalDataService } from '../common/global-data.service';
import { HttpRestService } from '../common/http-rest.service';

@Injectable({
  providedIn: 'root'
})
export class TaskService {
  codec = new HttpUrlEncodingCodec;
  public employeesData$: Subject<any> = new Subject();
  public restartLives$: Subject<any> = new Subject();
  public outletsDash$: Subject<any> = new Subject();
  public cardCloud$: Subject<any> = new Subject();
  public newCard$: Subject<any> = new Subject();
  public taskPosts$: Subject<any> = new Subject();
  public topChart$: Subject<any> = new Subject();
  public spikesChart$: Subject<any> = new Subject();
  public jobData$: Subject<any> = new Subject();
  public postData$: Subject<any> = new Subject();
  public taskData$: Subject<any> = new Subject();
  public assistantTaskData$: Subject<any> = new Subject();
  public assistantTaskDataScheme$: Subject<any> = new Subject();
  public cancelTaskData$: Subject<any> = new Subject();
  public userStats$: BehaviorSubject<any> = new BehaviorSubject({});
  public unreadNots$: Subject<any> = new Subject();
  constructor(
    private http: HttpRestService,
    private globalDataService: GlobalDataService,
  ) { }

  getRestartLivesStream() {
    return this.restartLives$.asObservable();
  }

  getEmployeesData() {
    return this.employeesData$.asObservable();
  }
  
  getCardCloud() {
    return this.cardCloud$.asObservable();
  }

  getPostDataStream() {
    return this.postData$.asObservable();
  }

  getJobDataStream() {
    return this.jobData$.asObservable();
  }

  getNewCard() {
    return this.newCard$.asObservable();
  }

  getOutletsDashStream() {
    return this.outletsDash$.asObservable();
  }

  getTopChartStream() {
    return this.topChart$.asObservable();
  }

  getSpikesChartStream() {
    return this.spikesChart$.asObservable();
  }

  getAssistantTaskDataStream() {
    return this.assistantTaskData$.asObservable();
  }
  getAssistantTaskDataSchemeStream() {
    return this.assistantTaskDataScheme$.asObservable();
  }
  
  getTaskDataStream() {
    return this.taskData$.asObservable();
  }

  getTaskPostsStream() {
    return this.taskPosts$.asObservable();
  }

  getCancelTaskData() {
    return this.cancelTaskData$.asObservable();
  }

  getUserStatsStream() {
    return this.userStats$.asObservable();
  }

  getUnreadNotsStream() {
    return this.unreadNots$.asObservable();
  }

  getLinkData(val, company_id, partner_company_id?, channel_id?) {
    let _params = new HttpParams().set("company_id", company_id)
    if (partner_company_id) {
      _params = _params.set('partner_company_id', partner_company_id);
    }
    if (channel_id) {
      _params = _params.set('channel_id', channel_id);
    }
    return this.http.executeGet<any>(`/api/get-url-data/?url=${encodeURIComponent(val)}`, true, {
      params:  _params
    })
  }

  getKeyFromYtUrl(val, company_id) {
    let _params = new HttpParams().set("company_id", company_id)

    return this.http.executeGet<any>(`/api/task-channel/get-youtube-external-stream-id/?url=${encodeURIComponent(val)}`, true, {
      params: _params
    })
  }

  getSmartLinkData(val, channel_id, company_id, partner_company_id?) {
    let _params = new HttpParams().set("company_id", company_id).set("channel_id", channel_id)
    if (partner_company_id) {
      _params = _params.set('partner_company_id', partner_company_id);
    }
    return this.http.executeGet<any>(`/api/get-url-data/?url=${encodeURIComponent(val)}`, true, {
      params: _params
    })
  }

  getYouTubeViews(id, data) {
    return this.http.executePost<any>(`/api/task-channel/update-youtube-api-views-count/${id}/`, data)
  }
  
  generateCompilations(data) {
    return this.http.executePost<any>(`/api/task-compilation/suggest/`, data)
  }

  getTikTokViews(id, data) {
    return this.http.executePost<any>(`/api/task-channel/update-tiktok-api-views-count/${id}/`, data)
  }

  getFacebookViews(id, data) {
    return this.http.executePost<any>(`/api/task-channel/update-facebook-api-views-count/${id}/`, data)
  }

  getActionsForAudio(company_id, per_page) {
    return this.http.executeGet<any>('/api/task-action/', true, {
      observe: 'response',
      params: new HttpParams().set("company_id", company_id).set("per-page", per_page)
    })
  }

  getPostExtra(page, company_id, filter?) {
    let extraParams = new HttpParams().set('company_id', company_id).set('page', page).set("per-page", '200')

    if (filter) {
      Object.keys(filter).forEach(element => {
        extraParams = extraParams.set(element, filter[element]);
      });
    }

    return this.http.executeGet<any>('/api/task-channel-live-stream/', true, {
      observe: 'response',
      params: extraParams
    })
  }

  getTaskChannelFile(company_id, filter?) {
    let taskChannelFileParams = new HttpParams().set('company_id', company_id).set("per-page", '200').set("expand", "taskChannel,taskChannel.channel,taskChannel.channel.platform,file,originalFile,liveStreamOriginalFile")

    if (filter) {
      Object.keys(filter).forEach(element => {
        taskChannelFileParams = taskChannelFileParams.set(element, filter[element]);
      });
    }

    return this.http.executeGet<any>('/api/task-channel-file/', true, {
      params: taskChannelFileParams
    })
  }

  getTaskChannelFileWithTags(company_id, filter?) {
    let taskChannelFileParams = new HttpParams().set('company_id', company_id).set("per-page", '200').set("expand", "taskChannel,taskChannel.channel,taskChannel.channel.platform,file,originalFile,liveStreamOriginalFile")

    if (filter) {
      Object.keys(filter).forEach(element => {
        taskChannelFileParams = taskChannelFileParams.set(element, filter[element]);
      });
    }

    return this.http.executeGet<any>('/api/task-channel-file/', true, {
      params: taskChannelFileParams
    })
  }

  getCreateTasks(company_id, filter?) {
    let _params = new HttpParams().set('company_id', company_id).set("per-page", '200')


    if (filter) {
      Object.keys(filter).forEach(element => {
        _params = _params.set(element, filter[element]);
      });
    }

    return this.http.executeGet<any>('/api/task-batch/', true, {
      observe: 'response',
      params: _params
    })
  }

  createTasks(data) {
    return this.http.executePost<any>('/api/task-batch/', data)
  }

  createFoldersPermission(data) {
    return this.http.executePost<any>('/api/employee-setting/', data)
  }

  editFoldersPermission(id, data) {
    return this.http.executePost<any>(`/api/employee-setting/${id}/`, data)
  }

  edtiCreateTasks(id, data) {
    return this.http.executePost<any>(`/api/task-batch/${id}/`, data)
  }

  setPrivacyStatusForYT(data) {
    return this.http.executePost<any>(`/api/youtube/set-privacy-status/`, data)
  }

  setChannelThumbnail(data) {
    return this.http.executePost<any>(`/api/youtube/set-thumbnail/`, data)
  }
  
  editBroadcast(id, data) {
    return this.http.executePost<any>(`/api/task-channel-live-stream/${id}/`, data)
  }

  deleteCreateTasks(id) {
    return this.http.executeDelete<any>(`/api/task-batch/${id}/`)
  }

  setTaskChannelFile(data, company_id) {
    return this.http.executePost<any>('/api/task-channel-file/', data, true, {
      params: new HttpParams().set('company_id', company_id)
    })
  }
  
  createImports(data, company_id) {
    return this.http.executePost<any>('/api/get-data-queue/', data, true, {
      params: new HttpParams().set('company_id', company_id)
    })
  }

  editTaskChannelFile(id, data, company_id) {
    return this.http.executePost<any>(`/api/task-channel-file/${id}/`, data, true, {
      params: new HttpParams().set('company_id', company_id)
    })
  }

  unsetTaskChannelFile(id, company_id) {
    return this.http.executeDelete<any>(`/api/task-channel-file/${id}/`, true, {
      params: new HttpParams().set('company_id', company_id)
    })
  }

  getActions(id, page, company_id = null, filter = null, task_operation_id = null) {

    let actions_params = new HttpParams().set('page', page).set("per-page", '100').set("expand", "document,parameterValue.parameter,data,file,createdEmployee,task,discussion,taskOperation,is_read,is_action_read,relationTask")
    if (id) {
      actions_params = actions_params.set('task_id', id);
    }

    if (company_id) {
      actions_params = actions_params.set('company_id', company_id);
    }

    console.log("getActions filter", filter)
    if (filter) {
      Object.keys(filter).forEach(element => {
        actions_params = actions_params.set(element, filter[element]);
      });
    }
    if (task_operation_id) {
      actions_params = actions_params.set('task_operation_id', task_operation_id);
    }

    return this.http.executeGet<any>('/api/task-action/', true, {
      observe: 'response',
      params: actions_params
    })
  }

  getNotReadActions(company_id, t) {
    let actions_params = new HttpParams().set("company_id", company_id).set("is_action_read", "1").set("t", t)
    return this.http.executeGet<any>('/api/task-action/actions-not-read/', true, {
      params: actions_params
    })
  }

  readActions(data) {
    return this.http.executePost<any>('/api/task-action/read-actions/', data)
  }

  moveWork(data) {
    return this.http.executePost<any>('/api/task-operation/move/', data)
  }

  getOperations(id, language?) {
    let _params = new HttpParams().set("company_id", id).set("expand", 'translation,operationToCompany').set("per-page", "200")
    if (!!language) {
      _params = _params.set('language', language);
    }
    return this.http.executeGet<any>('/api/operation/', true, {
      params: _params
    })
  }
  
  getPartnerOperations(company_id, partner_company_id, language?) {
    let _params = new HttpParams().set("company_id", company_id).set("partner_company_id", partner_company_id).set("expand", 'translation,operationToCompany').set("per-page", "200")
    if (!!language) {
      _params = _params.set('language', language);
    }
    return this.http.executeGet<any>('/api/operation/', true, {
      params: _params
    })
  }

  getOperationsToCompany(id, language?) {
    let _params = new HttpParams().set("operation_to_company_id", id).set("expand", 'translation,operationToCompany').set("per-page", "200")
    if (!!language) {
      _params = _params.set('language', language);
    }
    return this.http.executeGet<any>('/api/operation/', true, {
      params: _params
    })
  }

  getOperation(id) {
    return this.http.executeGet<any>(`/api/operation/${id}/`)
  }

  getOperationTranslation(id) {
    return this.http.executeGet<any>(`/api/operation-translation/`, true, {
      params: new HttpParams().set("operation_id", id)
    })
  }

  createOperation(data) {
    return this.http.executePost<any>('/api/operation/', data)
  }

  createOperationTranslation(data) {
    return this.http.executePost<any>('/api/operation-translation/', data)
  }

  updateOperationTranslation(id, data) {
    return this.http.executePost<any>(`/api/operation-translation/${id}/`, data)
  }

  editOperation(id, data) {
    return this.http.executePost<any>(`/api/operation/${id}/`, data)
  }

  deleteOperation(id) {
    return this.http.executeDelete<any>(`/api/operation/${id}/`)
  }

  getSelectedOperations(company_id, operation_id?) {
    let pars = new HttpParams().set("company_id", company_id)
    if (operation_id) {
      pars = pars.set("operation_id", operation_id)
    }
    return this.http.executeGet<any>('/api/operation-to-company/', true, {
      params: pars
    })
  }

  selectOperation(data) {
    return this.http.executePost<any>('/api/operation-to-company/', data)
  }

  editSelectedOperation(id, data) {
    return this.http.executePost<any>(`/api/operation-to-company/${id}/`, data)
  }

  unSelectOperation(id) {
    return this.http.executeDelete<any>(`/api/operation-to-company/${id}/`)
  }

  getTaskTypeTranslation(id) {
    return this.http.executeGet<any>(`/api/task-status-translation/`, true, {
      params: new HttpParams().set("task_status_id", id)
    })
  }

  createTaskType(data) {
    return this.http.executePost<any>('/api/task-status/', data)
  }

  createTaskTypeTranslation(data) {
    return this.http.executePost<any>('/api/task-status-translation/', data)
  }

  updateTaskTypeTranslation(id, data) {
    return this.http.executePost<any>(`/api/task-status-translation/${id}/`, data)
  }

  editTaskType(id, data) {
    return this.http.executePost<any>(`/api/task-status/${id}/`, data)
  }

  getTaskTypesToCompany(id, language?) {
    let _params = new HttpParams().set("task_status_to_company_id", id).set("expand", 'translation,taskStatusToCompany').set("per-page", "200")
    if (!!language) {
      _params = _params.set('language', language);
    }
    return this.http.executeGet<any>('/api/task-status/', true, {
      params: _params
    })
  }

  getSelectedTaskStatuses(company_id, task_status_id?) {
    let pars = new HttpParams().set("company_id", company_id)
    if (task_status_id) {
      pars = pars.set("task_status_id", task_status_id)
    }
    return this.http.executeGet<any>('/api/task-status-to-company/', true, {
      params: pars
    })
  }

  selectTaskStatus(data) {
    return this.http.executePost<any>('/api/task-status-to-company/', data)
  }

  editSelectedTaskStatus(id, data) {
    return this.http.executePost<any>(`/api/task-status-to-company/${id}/`, data)
  }

  unSelectTaskStatus(id) {
    return this.http.executeDelete<any>(`/api/task-status-to-company/${id}/`)
  }

  deleteTaskType(id) {
    return this.http.executeDelete<any>(`/api/task-status/${id}/`)
  }


  getOperationsStatus() {
    return this.globalDataService.getOperationStatus()
    // return this.http.executeGet<any>('/api/operation-status/', true, {
    //   params: new HttpParams().set("per-page", "200")
    // })
  }

  getTaskStatuses(id) {
    return this.http.executeGet<any>('/api/task-status/', true, {
      params: new HttpParams().set("company_id", id)
    })
  }

  getTaskPartnerStatuses(company_id, partner_company_id) {
    return this.http.executeGet<any>('/api/task-status/', true, {
      params: new HttpParams().set("company_id", company_id).set("partner_company_id", partner_company_id)
    })
  }

  getTasksArray(page, company_id, ids) {
    let filter_params = new HttpParams().set("company_id", company_id).set("id", ids.join(",")).set('page', page).set("per-page", "50").set("order_by", "updated_desc");
  
    return this.http.executeGet<any>('/api/task/', true, {
      observe: 'response',
      params: filter_params
    })
  }

  getTaskForItem(company_id, filter = null) {
    let filter_params = new HttpParams().set("company_id", company_id);

    if (filter) {
      Object.keys(filter).forEach(key => {
        if (!!filter[key]) {
          filter_params = filter_params.set(key, filter[key]);
        }
      })
    }
  
    return this.http.executeGet<any>('/api/task/', true, {
      params: filter_params
    })
  }
  

  getTasksFullSelect(page, company_id, filter = null) {
    let filter_params = new HttpParams().set("company_id", company_id).set('page', page).set("per-page", "50").set("order_by", "updated_desc").set("expand", 'status,operations.partnerEmployees.partnerEmployee,operations.partnerEmployeesStatuses,parameterValuesToTask.parameter,parameterValuesToTask.parameterValue,channels.file,channels.channel,channels.contentType,channels.contentStatus,channels.createdEmployee,channels.createdUrlEmployee,channels.statusUpdatedEmployee,channels.updatedUrlEmployee,avatarFile,group.managers,managers,createdEmployee.avatarFile,createdTaskTemplate,createdFile,createdOriginalFile,createdVideoProject,createdVideoProjectFile,operations.employees.employee,operations.employees.currency,operations.createdEmployee,operations.operation.translation,operations.status,operations.currency,operations.employeesStatuses.status,operations.employeeStatus.status,operations.partnerCompanies.partnerCompany.avatarFile,operations.partnerCompanies.currency,operations.partnerCompaniesStatuses,operations.automationScenarios.template,channels.taskChannelFiles.originalFile,channels.contentPlan,contentPlans.contentType,contentPlans.channel.platform,channels.channel.avatarFile');

    if (filter) {
      Object.keys(filter).forEach(key => {
        if (!!filter[key]) {
          filter_params = filter_params.set(key, filter[key]);
        }
      })
    }
  
    return this.http.executeGet<any>('/api/task/', true, {
      observe: 'response',
      params: filter_params
    })
  }

  getTasksSelect(page, company_id, filter = null) {

    let filter_params = new HttpParams().set("company_id", company_id).set('page', page).set("per-page", "50").set("order_by", "updated_desc").set("expand", "status,group,contentPlans.contentType,contentPlans.channel.platform");

    if (filter) {
      Object.keys(filter).forEach(key => {
        if (!!filter[key]) {
          filter_params = filter_params.set(key, filter[key]);
        }
      })
    }
  
    return this.http.executeGet<any>('/api/task/', true, {
      observe: 'response',
      params: filter_params
    })
  }
  
  getTasksSelectNoExp(page, company_id, filter = null) {

    let filter_params = new HttpParams().set("company_id", company_id).set('page', page).set("per-page", "200").set("order_by", "updated_desc");

    if (filter) {
      Object.keys(filter).forEach(key => {
        if (!!filter[key]) {
          filter_params = filter_params.set(key, filter[key]);
        }
      })
    }
  
    return this.http.executeGet<any>('/api/task/', true, {
      observe: 'response',
      params: filter_params
    })
  }

  getTasksSelectNoExpNew(page, company_id, filter, expand?) {
    let filter_params = new HttpParams().set("company_id", company_id).set('page', page).set("per-page", "200").set("order_by", "updated_desc");
    let url = '/api/task/';
    if (expand) {
      filter_params = filter_params.set('expand', expand);
    }
    console.log("getTasksSelectNoExpNew FILTER !!!", filter)
    Object.keys(filter).forEach(element => {
      if (element == 'task_custom_id' && !!filter[element] && filter[element].indexOf('-') != -1) {
        let start = +filter[element].trim().split('-')[0];
        let end = +filter[element].trim().split('-')[1];
        let val = []
        for (let index = start; index <= end; index++) {
          val.push(index);
        }
        filter_params = filter_params.set(element, val.join(","));
      } else if (['q_task_channel_content_filename', 'q', 'q_task_name', 'q_task_comment', 'q_task_private_comment', 'q_task_operation_comment', 'q_task_operation_employee_comment', 'q_task_operation_private_comment'].includes(element) && !!filter[element]) {
        url += `?${element}=${encodeURIComponent(filter[element])}`;
      } else if (['is_related_task', 'is_consist_of_task', 'is_part_of_task', 'task_content_status_id', 'content_type_id', 'group_id', 'is_content_url'].includes(element) && filter[element] == '0') {
        filter_params = filter_params.set(element, filter[element]);
      } else if (element == 'operation_status_id' && !(filter[element].length == 1 && filter[element].includes(0))) {
        if (filter[element].includes(0)) {
          filter[element].splice(filter.element.indexOf(0),1)
        }
        filter_params = filter_params.set(element, filter[element].join(","));
      } else if (filter[element] != '0' && filter[element] != '') {
          if (element.indexOf('at_from') != -1 || element.indexOf('at_to') != -1) {
            filter_params = filter_params.set(element, moment(filter[element]._d).format("X"));
          } else {
            filter_params = filter_params.set(element, filter[element]);
          }
      } else if (filter[element] == '0' && filter[element] == 0 && (element == 'operation_employee_id' || element == 'client_employee_id' || element == 'operation_partner_company_id' || element == 'operation_completed_at_to')) {
        filter_params = filter_params.set(element, filter[element])
      }
    });

    return this.http.executeGet<any>(url, true, {
      observe: 'response',
      params: filter_params
    })
  }

  getTasksSelectNoExpDyn(page, company_id, filter = null, per_page = "200") {

    let filter_params = new HttpParams().set("company_id", company_id).set('page', page).set("per-page", per_page).set("order_by", "updated_desc");

    if (filter) {
      Object.keys(filter).forEach(key => {
        if (!!filter[key]) {
          filter_params = filter_params.set(key, filter[key]);
        }
      })
    }
  
    return this.http.executeGet<any>('/api/task/', true, {
      observe: 'response',
      params: filter_params
    })
  }

  getPartnerTasksSelect(page, company_id, partner_company_id, filter = null) {
    let filter_params = new HttpParams().set("company_id", company_id).set("partner_company_id", partner_company_id).set('page', page).set("per-page", "50").set("order_by", "updated_desc");
    if (filter) {
      filter_params = filter_params.set('q', filter);
    }
  
    return this.http.executeGet<any>('/api/task/', true, {
      observe: 'response',
      params: filter_params
    })
  }
  
  getTaskForManager(company_id, id) {
    return this.http.executeGet<any>(`/api/task/`, true, {
      params: new HttpParams().set("company_id", company_id).set("id", id).set("expand", 'status,operations.partnerEmployees.partnerEmployee,operations.partnerEmployeesStatuses,parameterValuesToTask.parameter,parameterValuesToTask.parameterValue,channels.file,channels.channel,channels.channel.avatarFile,channels.contentType,channels.contentStatus,channels.createdEmployee,channels.createdUrlEmployee,channels.statusUpdatedEmployee,channels.updatedUrlEmployee,avatarFile,group.managers,managers,createdEmployee,operations.employees.employee,operations.employees.currency,operations.createdEmployee,operations.operation.translation,operations.status,operations.currency,operations.employeesStatuses.status,operations.employeeStatus.status,operations.partnerCompanies.partnerCompany.avatarFile,operations.partnerCompanies.currency,operations.partnerCompaniesStatuses,operations.automationScenarios.template,operations.parameterValuesToTask.parameter,operations.parameterValuesToTask.parameterValue')
    })
  }

  createTask(company_id, data) {
    return this.http.executePost<any>('/api/task/', data, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }

  createTaskByEmployeeUsedTemplate(id, data) {
    return this.http.executePost<any>(`/api/task-template/apply/${id}/`, data, true, {
      params: new HttpParams()
    })
  }

  // taskIsRead(id) {
  //   return this.http.executePost<any>('/api/task/read/', {id: id})
  // }

  getTask(id, company_id?) {
    return this.http.executeGet<any>(`/api/task/${id}/`, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }

  editTask(id, data, company_id?) {
    return this.http.executePost<any>(`/api/task/${id}/`, data, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }

  deleteTask(id) {
    return this.http.executeDelete<any>(`/api/task/${id}/`)
  }

  deleteWork(id) {
    return this.http.executeDelete<any>(`/api/task-operation/${id}/`)
  }

  deleteProfile(id) {
    return this.http.executeDelete<any>(`/api/task-channel/${id}/`)
  }

  addWork(company_id, data) {
    return this.http.executePost<any>('/api/task-operation/', data, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }

  getWorksSection(page, company_id, filter?, per_page?) {
    let filter_params = new HttpParams().set("page", page).set("company_id", company_id).set("expand", "partnerEmployees.partnerEmployee,partnerEmployeesStatuses,task,status,employees,employees.employee,employeesStatuses,partnerCompanies,partnerCompanies.partnerCompany,partnerCompanies.partnerCompany.avatarFile,partnerCompaniesStatuses,createdEmployee,operation,automationScenarios,automationScenarios.template,parameterValuesToTask.parameter,parameterValuesToTask.parameterValue")
    if (per_page) {
      filter_params = filter_params.set("per-page", per_page)
    } else {
      filter_params = filter_params.set("per-page", '50')
    }

    if (filter) {
      Object.keys(filter).forEach(key => {
        if (key == 'task_operation_other_employee_status_id') {
          return
        }
        if (key == 'task_custom_id' && !!filter[key] && filter[key].indexOf('-') != -1) {
          let start = +filter[key].trim().split('-')[0];
          let end = +filter[key].trim().split('-')[1];
          let val = []
          for (let index = start; index <= end; index++) {
            val.push(index);
          }
          filter_params = filter_params.set(key, val.join(","));
        } else if (key == 'task_operation_operation_id' && !!filter[key].length) {
          filter_params = filter_params.set(key, filter[key].join(","));
        } else if (filter[key] != '0' && filter[key] != '') {
          if (key == 'status_id' && !!filter.task_operation_other_employee_status_id) {
            filter_params = filter_params.set('task_operation_other_employee_status_id', filter[key]);
          } else {
            filter_params = filter_params.set(key, filter[key]);
          }
        } else if ((filter[key] === '0' || filter[key] === 0) && ['is_content_url', 'group_id', 'content_type_id', 'operation_employee_id', 'client_employee_id', 'operation_partner_company_id'].includes(key)) {
          filter_params = filter_params.set(key, filter[key]);
        }
      })
    }

    return this.http.executeGet<any>(`/api/task-operation/`, true, {
      observe: 'response',
      params: filter_params
    })
  }
  getWorks(page, company_id, filter?, per_page?) {
    let filter_params = new HttpParams().set("page", page).set("company_id", company_id).set("expand", "partnerEmployees.partnerEmployee,partnerEmployeesStatuses,task,status,employees,employees.employee,employeesStatuses,partnerCompanies,partnerCompanies.partnerCompany,partnerCompanies.partnerCompany.avatarFile,partnerCompaniesStatuses,createdEmployee,operation,automationScenarios,automationScenarios.template,parameterValuesToTask.parameter,parameterValuesToTask.parameterValue")
    if (per_page) {
      filter_params = filter_params.set("per-page", per_page)
    } else {
      filter_params = filter_params.set("per-page", '50')
    }

    if (filter) {
      Object.keys(filter).forEach(key => {
        if (key == 'task_operation_other_employee_status_id') {
          return
        }
        if (key == 'task_custom_id' && !!filter[key] && filter[key].indexOf('-') != -1) {
          let start = +filter[key].trim().split('-')[0];
          let end = +filter[key].trim().split('-')[1];
          let val = []
          for (let index = start; index <= end; index++) {
            val.push(index);
          }
          filter_params = filter_params.set(key, val.join(","));
        } else if (filter[key] != '0' && filter[key] != '') {
          if (key == 'status_id' && !!filter.task_operation_other_employee_status_id) {
            filter_params = filter_params.set('task_operation_other_employee_status_id', filter[key]);
          } else {
            filter_params = filter_params.set(key, filter[key]);
          }
        } else if ((filter[key] === '0' || filter[key] === 0) && ['is_content_url', 'group_id', 'content_type_id', 'operation_employee_id', 'client_employee_id', 'operation_partner_company_id'].includes(key)) {
          filter_params = filter_params.set(key, filter[key]);
        }
      })
    }

    return this.http.executeGet<any>(`/api/task-operation/`, true, {
      observe: 'response',
      params: filter_params
    })
  }

  getCardJobs(company_id, filter?) {
    let filter_params = new HttpParams().set("company_id", company_id)
    if (filter) {
      Object.keys(filter).forEach(key => {
        if (!!filter[key]) {
          filter_params = filter_params.set(key, filter[key]);
        }
      })
    }

    return this.http.executeGet<any>(`/api/task-operation/`, true, {
      params: filter_params
    })
  }

  getHourChannelAnalytics(company_id, filter?, page?) {
    let filter_params = new HttpParams().set("company_id", company_id).set("order_by", "start_time").set("per-page", "200").set("totals", "1")

    if (filter) {
      Object.keys(filter).forEach(key => {
        if (filter[key] != '0' && filter[key] != '') {
          filter_params = filter_params.set(key, filter[key]);
        }
      })
    }

    if (!!page) {
      filter_params = filter_params.set("page", page);
    }

    return this.http.executeGet<any>(`/api/channel-analytics-by-hour/`, true, {
      observe: 'response',
      params: filter_params
    })
  }

  getDaysOutletChart(company_id, filter?, page?) {
    let filter_params = new HttpParams().set("company_id", company_id).set('page', page).set('per-page', '200')
    if (filter) {
      Object.keys(filter).forEach(element => {
          filter_params = filter_params.set(element, filter[element]);
      });
    }

    return this.http.executeGet<any>('/api/channel-analytics-by-day/', true, {
      observe: 'response',
      params: filter_params
    })
  }

  getDayChannelAnalytics(company_id, filter?, page?) {
    let filter_params = new HttpParams().set("company_id", company_id).set("order_by", "start_time").set("per-page", "200").set("totals", "1")

    if (filter) {
      Object.keys(filter).forEach(key => {
        if (filter[key] != '0' && filter[key] != '') {
          filter_params = filter_params.set(key, filter[key]);
        }
      })
    }

    if (!!page) {
      filter_params = filter_params.set("page", page);
    }

    return this.http.executeGet<any>(`/api/channel-analytics-by-day/`, true, {
      observe: 'response',
      params: filter_params
    })
  }

  getContentAnlytics(company_id, filter?, page?) {
    let filter_params = new HttpParams().set("company_id", company_id).set("order_by", "start_time").set("per-page", "200").set("totals", "1")

    if (filter) {
      Object.keys(filter).forEach(key => {
        if (filter[key] != '0' && filter[key] != '') {
          filter_params = filter_params.set(key, filter[key]);
        }
      })
    }

    if (!!page) {
      filter_params = filter_params.set("page", page);
    }

    return this.http.executeGet<any>(`/api/content-analytics/`, true, {
      observe: 'response',
      params: filter_params
    })
  }

  getWorksIds(company_id, task_id) {
    let filter_params = new HttpParams().set("per-page", "200").set("company_id", company_id).set("task_id", task_id)
    
    return this.http.executeGet<any>(`/api/task-operation/`, true, {
      params: filter_params
    })
  }

  getWork(id) {
    return this.http.executeGet<any>(`/api/task-operation/${id}/`)
  }

  editWork(id, data, company_id) {
    return this.http.executePost<any>(`/api/task-operation/${id}/`, data, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }

  addProfile(data, company_id) {
    return this.http.executePost<any>('/api/task-channel/', data, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }

  editProfile(id, data, company_id) {
    return this.http.executePost<any>(`/api/task-channel/${id}/`, data, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }

  addVuser(data) {
    return this.http.executePost<any>('/api/employee/', data)
  }

  editVuser(id, data) {
    return this.http.executePost<any>(`/api/employee/${id}/`, data)
  }

  getTaskProfiles(id, company_id) {
    return this.http.executeGet<any>('/api/task-channel/', true, {
      params: new HttpParams().set("task_id", id).set("per-page", "200").set('company_id', company_id)
    })
  }

  getDashExpandUrls(page, company_id, filter, per_page) {
    let filter_params = new HttpParams().set("page", page).set("per-page", per_page).set("company_id", company_id).set("expand", "createdUrlEmployee,updatedUrlEmployee,file,task.group,channel.platform,contentType,createdEmployee,updatedEmployee")

    if (filter) {
      Object.keys(filter).forEach(key => {
        if (filter.content_status_id == 14 && key == 'is_live_stream_backup') {
          if (filter[key] === '0' || filter[key] === 0 || filter[key] === '1' || filter[key] === 1) {
            filter_params = filter_params.set(key, filter[key]);
          }
        } else if (filter[key] != '0' && filter[key] != '') {
          if (key.indexOf("_at_") != -1 || key.indexOf("_from") != -1 || key.indexOf("_to") != -1) {
            filter_params = filter_params.set(key, moment(filter[key]._d).format("X"));
          } else { 
            filter_params = filter_params.set(key, filter[key]);
          }
        } else if (filter[key] == '0' && (['content_published_at_to', 'content_type_id', 'is_content_views_count_update', 'is_bravourl_content_views_count_update', 'group_id', 'channel_id', 'platform_id'].includes(key))) {
          filter_params = filter_params.set(key, filter[key]);
        }
      })
    }

    return this.http.executeGet<any>('/api/task-channel/', true, {
      observe: 'response',
      params: filter_params
    })
  }

  

  getExpandPermUrls(page, company_id, filter, per_page) {
    let filter_params = new HttpParams().set("page", page).set("per-page", per_page).set("company_id", company_id).set("expand", "createdUrlEmployee,updatedUrlEmployee,file,task.group,channel.contentStatus,channel.avatarFile,channel.platform,contentType,createdEmployee,updatedEmployee,permission_prepare_upload,permission_upload")

    if (filter) {
      Object.keys(filter).forEach(key => {
        if (!!filter[key]) {
          filter_params = filter_params.set(key, filter[key]);
        }
      })
    }

    return this.http.executeGet<any>('/api/task-channel/', true, {
      params: filter_params
    })
  }

  getTikTokData(company_id, task_channel_id) {
    let filter_params = new HttpParams().set("task_channel_id", task_channel_id).set("company_id", company_id)

    return this.http.executeGet<any>('/api/tiktok/get-posting-info/', true, {
      params: filter_params
    })
  }

  getExpandPublishUrls(page, company_id, filter, per_page) {
    let filter_params = new HttpParams().set("page", page).set("per-page", per_page).set("company_id", company_id).set("totals", "1").set("expand", "createdUrlEmployee,updatedUrlEmployee,file,task.group,channel.contentStatus,channel.avatarFile,channel.platform,contentType,createdEmployee,updatedEmployee")

    if (filter) {
      Object.keys(filter).forEach(key => {
        if (filter[key] != '0' && filter[key] != '') {
          filter_params = filter_params.set(key, filter[key]);
        }
      })
    }

    return this.http.executeGet<any>('/api/task-channel/', true, {
      observe: 'response',
      params: filter_params
    })
  }

  getUrlsWithoutExp(page, company_id, filter, per_page) {
    let filter_params = new HttpParams().set("page", page).set("per-page", per_page).set("company_id", company_id).set("totals", "1")
    
    if (filter) {
      Object.keys(filter).forEach(key => {
        if (filter.content_status_id == 14 && key == 'is_live_stream_backup') {
          if (filter[key] === '0' || filter[key] === 0 || filter[key] === '1' || filter[key] === 1) {
            filter_params = filter_params.set(key, filter[key]);
          }
        } else if (key == 'task_custom_id' && !!filter[key] && filter[key].indexOf('-') != -1) {
          let start = +filter[key].trim().split('-')[0];
          let end = +filter[key].trim().split('-')[1];
          let val = []
          for (let index = start; index <= end; index++) {
            val.push(index);
          }
          filter_params = filter_params.set(key, val.join(","));
        } else if (filter[key] != '0' && filter[key] != '') {
          if ((key.indexOf("_at_") != -1 || key.indexOf("_from") != -1 || key.indexOf("_to") != -1) && key.indexOf("summary_views_count_") == -1) {
            filter_params = filter_params.set(key, moment(filter[key]._d).format("X"));
          } else { 
            filter_params = filter_params.set(key, filter[key]);
          }
        } else if ((filter[key] === '0' || filter[key] === 0) && (['content_published_at_to', 'content_type_id', 'is_content_views_count_update', 'is_bravourl_content_views_count_update', 'group_id', 'no_channel_id', 'channel_id', 'platform_id'].includes(key))) {
          filter_params = filter_params.set(key, filter[key]);
        } else if ((filter[key] === '0' || filter[key] === 0) && ['is_content_url', 'operation_employee_id', 'client_employee_id', 'operation_partner_company_id'].includes(key)) {
          filter_params = filter_params.set(key, filter[key]);
        }
      })
    }

    return this.http.executeGet<any>('/api/task-channel/', true, {
      observe: 'response',
      params: filter_params
    })
  }
  
  getLiteUrls(page, company_id, filter, per_page, addExp:string = '') {
    let filter_params = new HttpParams().set("page", page).set("per-page", per_page).set("company_id", company_id).set("totals", "1").set("expand", `channel.contentStatus,channel.avatarFile,channel.platform${addExp}`)

    if (filter) {
      Object.keys(filter).forEach(key => {
        if (filter.content_status_id == 14 && key == 'is_live_stream_backup') {
          if (filter[key] === '0' || filter[key] === 0 || filter[key] === '1' || filter[key] === 1) {
            filter_params = filter_params.set(key, filter[key]);
          }
        } else if (key == 'task_custom_id' && !!filter[key] && filter[key].indexOf('-') != -1) {
          let start = +filter[key].trim().split('-')[0];
          let end = +filter[key].trim().split('-')[1];
          let val = []
          for (let index = start; index <= end; index++) {
            val.push(index);
          }
          filter_params = filter_params.set(key, val.join(","));
        } else if (filter[key] != '0' && filter[key] != '') {
          if ((key.indexOf("_at_") != -1 || key.indexOf("_from") != -1 || key.indexOf("_to") != -1) && key.indexOf("summary_views_count_") == -1) {
            filter_params = filter_params.set(key, moment(filter[key]._d).format("X"));
          } else { 
            filter_params = filter_params.set(key, filter[key]);
          }
        } else if ((filter[key] === '0' || filter[key] === 0) && (['content_published_at_to', 'content_type_id', 'is_content_views_count_update', 'is_bravourl_content_views_count_update', 'group_id', 'no_channel_id', 'channel_id', 'platform_id'].includes(key))) {
          filter_params = filter_params.set(key, filter[key]);
        } else if ((filter[key] === '0' || filter[key] === 0) && ['is_content_url', 'operation_employee_id', 'client_employee_id', 'operation_partner_company_id'].includes(key)) {
          filter_params = filter_params.set(key, filter[key]);
        }
      })
    }

    return this.http.executeGet<any>('/api/task-channel/', true, {
      observe: 'response',
      params: filter_params
    })
  }

  getExpandUrls(page, company_id, filter, per_page) {
    let filter_params = new HttpParams().set("page", page).set("per-page", per_page).set("company_id", company_id).set("totals", "1").set("expand", "taskChannelFiles.originalFile,contentStatus,createdUrlEmployee,updatedUrlEmployee,file,task.group,channel.contentStatus,channel.avatarFile,channel.platform,contentType,createdEmployee,updatedEmployee,contentPlan")

    if (filter) {
      Object.keys(filter).forEach(key => {
        if (filter.content_status_id == 14 && key == 'is_live_stream_backup') {
          if (filter[key] === '0' || filter[key] === 0 || filter[key] === '1' || filter[key] === 1) {
            filter_params = filter_params.set(key, filter[key]);
          }
        } else if (key == 'task_custom_id' && !!filter[key] && filter[key].indexOf('-') != -1) {
          let start = +filter[key].trim().split('-')[0];
          let end = +filter[key].trim().split('-')[1];
          let val = []
          for (let index = start; index <= end; index++) {
            val.push(index);
          }
          filter_params = filter_params.set(key, val.join(","));
        } else if (filter[key] != '0' && filter[key] != '') {
          if ((key.indexOf("_at_") != -1 || key.indexOf("_from") != -1 || key.indexOf("_to") != -1) && key.indexOf("summary_views_count_") == -1) {
            filter_params = filter_params.set(key, moment(filter[key]._d).format("X"));
          } else { 
            filter_params = filter_params.set(key, filter[key]);
          }
        } else if ((filter[key] === '0' || filter[key] === 0) && (['content_published_at_to', 'task_id', 'content_type_id', 'is_content_views_count_update', 'is_bravourl_content_views_count_update', 'group_id', 'no_channel_id', 'channel_id', 'platform_id'].includes(key))) {
          filter_params = filter_params.set(key, filter[key]);
        } else if ((filter[key] === '0' || filter[key] === 0) && ['is_content_url', 'operation_employee_id', 'client_employee_id', 'operation_partner_company_id'].includes(key)) {
          filter_params = filter_params.set(key, filter[key]);
        }
      })
    }

    return this.http.executeGet<any>('/api/task-channel/', true, {
      observe: 'response',
      params: filter_params
    })
  }

  getLiteUrlsWithTags(page, company_id, filter, per_page) {
    let filter_params = new HttpParams().set("page", page).set("per-page", per_page).set("company_id", company_id).set("totals", "1").set("expand", "channel.contentStatus,channel.avatarFile,channel.platform,channel.parameterValuesToChannel.parameter,channel.parameterValuesToChannel.parameterValue")

    if (filter) {
      Object.keys(filter).forEach(key => {
        if (filter.content_status_id == 14 && key == 'is_live_stream_backup') {
          if (filter[key] === '0' || filter[key] === 0 || filter[key] === '1' || filter[key] === 1) {
            filter_params = filter_params.set(key, filter[key]);
          }
        } else if (filter[key] != '0' && filter[key] != '') {
          if ((key.indexOf("_at_") != -1 || key.indexOf("_from") != -1 || key.indexOf("_to") != -1) && key.indexOf("summary_views_count_") == -1) {
            filter_params = filter_params.set(key, moment(filter[key]._d).format("X"));
          } else { 
            filter_params = filter_params.set(key, filter[key]);
          }
        } else if ((filter[key] === '0' || filter[key] === 0) && (['content_published_at_to', 'content_type_id', 'is_content_views_count_update', 'is_bravourl_content_views_count_update', 'group_id', 'no_channel_id', 'channel_id', 'platform_id'].includes(key))) {
          filter_params = filter_params.set(key, filter[key]);
        } else if ((filter[key] === '0' || filter[key] === 0) && ['is_content_url', 'operation_employee_id', 'client_employee_id', 'operation_partner_company_id'].includes(key)) {
          filter_params = filter_params.set(key, filter[key]);
        }
      })
    }

    return this.http.executeGet<any>('/api/task-channel/', true, {
      observe: 'response',
      params: filter_params
    })
  }

  getExpandUrlsWithTags(page, company_id, filter, per_page) {
    let filter_params = new HttpParams().set("page", page).set("per-page", per_page).set("company_id", company_id).set("totals", "1").set("expand", "taskChannelFiles.originalFile,contentStatus,createdUrlEmployee,updatedUrlEmployee,file,task.group,channel.contentStatus,channel.avatarFile,channel.platform,contentType,createdEmployee,updatedEmployee,contentPlan,channel.parameterValuesToChannel.parameter,channel.parameterValuesToChannel.parameterValue")

    if (filter) {
      Object.keys(filter).forEach(key => {
        if (filter.content_status_id == 14 && key == 'is_live_stream_backup') {
          if (filter[key] === '0' || filter[key] === 0 || filter[key] === '1' || filter[key] === 1) {
            filter_params = filter_params.set(key, filter[key]);
          }
        } else if (filter[key] != '0' && filter[key] != '') {
          if ((key.indexOf("_at_") != -1 || key.indexOf("_from") != -1 || key.indexOf("_to") != -1) && key.indexOf("summary_views_count_") == -1) {
            filter_params = filter_params.set(key, moment(filter[key]._d).format("X"));
          } else { 
            filter_params = filter_params.set(key, filter[key]);
          }
        } else if ((filter[key] === '0' || filter[key] === 0) && (['content_published_at_to', 'content_type_id', 'is_content_views_count_update', 'is_bravourl_content_views_count_update', 'group_id', 'no_channel_id', 'channel_id', 'platform_id'].includes(key))) {
          filter_params = filter_params.set(key, filter[key]);
        } else if ((filter[key] === '0' || filter[key] === 0) && ['is_content_url', 'operation_employee_id', 'client_employee_id', 'operation_partner_company_id'].includes(key)) {
          filter_params = filter_params.set(key, filter[key]);
        }
      })
    }

    return this.http.executeGet<any>('/api/task-channel/', true, {
      observe: 'response',
      params: filter_params
    })
  }
  
  getTaskProfile(id) {
    return this.http.executeGet<any>(`/api/task-channel/${id}/`)
  }

  getCustomId(c_id, g_id) {
    return this.http.executeGet<any>('/api/task/next-custom-id/', true, {
      params: new HttpParams().set("company_id", c_id).set("group_id", g_id)
    })
  }

  getTasksWithoutExpand(page, id, filter, per_page) {
    let filter_params = new HttpParams().set("company_id", id).set('page', page).set("per-page", per_page).set("order_by", "updated_desc").set("expand", "status,group");
    console.log("getTasksExpand FILTER !!!", filter)

    Object.keys(filter).forEach(element => {
      if (element == 'is_content_url' && filter[element] == '0') {
        filter_params = filter_params.set(element, filter[element]);
      } else if (element == 'operation_status_id' && !(filter[element].length == 1 && filter[element].includes(0))) {
        if (filter[element].includes(0)) {
          filter[element].splice(filter.element.indexOf(0),1)
        }
        filter_params = filter_params.set(element, filter[element].join(","));
      } else if (filter[element] != '0' && filter[element] != '') {
          if (element == 'operation_completed_at_from' || element == 'operation_completed_at_to') {
            filter_params = filter_params.set(element, moment(filter[element]._d).format("X"));
          } else {
            filter_params = filter_params.set(element, filter[element]);
          }
      } else if (filter[element] == '0' && filter[element] == 0 && (element == 'operation_employee_id' || element == 'operation_partner_company_id' || element == 'operation_completed_at_to')) {
        filter_params = filter_params.set(element, filter[element])
      }
    });
    
    return this.http.executeGet<any>('/api/task/', true, {
      observe: 'response',
      params: filter_params
    })
  }

  getSearchCards(page, id, filter, per_page?) {
    let filter_params = new HttpParams()
    console.log("getCardsExpand FILTER !!!", filter)
    if (id) {
      filter_params = filter_params.set("company_id", id)
    }
    Object.keys(filter).forEach(element => {
      if (['is_related_task', 'is_consist_of_task', 'is_part_of_task', 'task_content_status_id', 'content_type_id', 'group_id', 'is_content_url'].includes(element) && filter[element] == '0') {
        filter_params = filter_params.set(element, filter[element]);
      } else if (element == 'operation_status_id' && !(filter[element].length == 1 && filter[element].includes(0))) {
        if (filter[element].includes(0)) {
          filter[element].splice(filter.element.indexOf(0),1)
        }
        filter_params = filter_params.set(element, filter[element].join(","));
      } else if (filter[element] != '0' && filter[element] != '') {
          if (element.indexOf('at_from') != -1 || element.indexOf('at_to') != -1) {
            filter_params = filter_params.set(element, moment(filter[element]._d).format("X"));
          } else {
            filter_params = filter_params.set(element, filter[element]);
          }
      } else if (filter[element] == '0' && filter[element] == 0 && (element == 'operation_employee_id' || element == 'client_employee_id' || element == 'operation_partner_company_id' || element == 'operation_completed_at_to')) {
        filter_params = filter_params.set(element, filter[element])
      }
    });

    // 0 not active
    // 1 active
    // 2 need delete
    // 3 need Maks
    let expand = [
      { value: "operations.partnerEmployees.partnerEmployee", is_active: 0 },
      { value: "operations.partnerEmployeesStatuses", is_active: 0 },
      { value: "parameterValuesToTask.parameter", is_active: 0 },
      { value: "parameterValuesToTask.parameterValue", is_active: 0 },
      { value: "consistOfTaskPartition.partOfTask", is_active: 0 },
      { value: "taskRelated.relatedTask", is_active: 0 },
      { value: "partOfTaskPartition.consistOfTask", is_active: 0 },
      { value: "partOfTaskPartition.consistOfTask.avatarFile", is_active: 0 },
      { value: "partOfTaskPartition.consistOfTask.channels.channel", is_active: 0 },
      { value: "partOfTaskPartition.consistOfTask.channels.file", is_active: 0 },
      { value: "consistOfTaskPartition.partOfTask.avatarFile", is_active: 0 },
      { value: "consistOfTaskPartition.partOfTask.channels.channel", is_active: 0 },
      { value: "consistOfTaskPartition.partOfTask.channels.file", is_active: 0 },
      { value: "taskRelated.relatedTask.avatarFile", is_active: 0 },
      { value: "taskRelated.relatedTask.channels.channel", is_active: 0 },
      { value: "taskRelated.relatedTask.channels.file", is_active: 0 },
      { value: "channels.file", is_active: 0 },
      { value: "channels.channel", is_active: 0 },
      { value: "channels.contentType", is_active: 0 },
      { value: "channels.contentStatus", is_active: 0 },
      { value: "channels.createdEmployee", is_active: 0 },
      { value: "channels.createdUrlEmployee", is_active: 0 },
      { value: "channels.statusUpdatedEmployee", is_active: 0 },
      { value: "channels.updatedUrlEmployee", is_active: 0 },
      { value: "avatarFile", is_active: 0 },
      { value: "group.managers", is_active: 0 },
      { value: "managers", is_active: 0 },
      { value: "createdEmployee", is_active: 0 },
      { value: "operations.employees.employee", is_active: 0 },
      { value: "operations.employees.currency", is_active: 0 },
      { value: "operations.createdEmployee", is_active: 0 },
      { value: "operations.operation.translation", is_active: 1 },
      { value: "operations.status", is_active: 1 },
      { value: "operations.currency", is_active: 0 },
      { value: "operations.employeesStatuses.status", is_active: 0 },
      { value: "operations.employeeStatus.status", is_active: 0 },
      { value: "operations.partnerCompanies.partnerCompany.avatarFile", is_active: 0 },
      { value: "operations.partnerCompanies.currency", is_active: 0 },
      { value: "operations.partnerCompaniesStatuses", is_active: 0 },
      { value: "operations.automationScenarios.template", is_active: 0 },
      { value: "status", is_active: 0 }
    ]
    
    filter_params = filter_params.set('page', page).set("per-page", per_page).set("pagination", "no").set("expand", expand.filter(x => x.is_active > 0).map(y => y.value).join(','));
    // filter_params = filter_params.set('page', page).set("per-page", per_page).set("pagination", "no").set("expand", "status,operations.partnerEmployees.partnerEmployee,operations.partnerEmployeesStatuses,consistOfTaskPartition.partOfTask,taskRelated.relatedTask,partOfTaskPartition.consistOfTask,channels.file,channels.channel,avatarFile,group.managers,managers,operations.employees.employee,operations.operation.translation,operations.status,operations.employeesStatuses.status,operations.partnerCompanies.partnerCompany.avatarFile,operations.partnerCompaniesStatuses");
    
    return this.http.executeGet<any>('/api/task/', true, {
      params: filter_params
    })
  }

  getCardsNoExpand(page, id, filter, per_page?, expand = '') {
    let filter_params = new HttpParams();
    let url = '/api/task/';
    console.log("getCardsExpand FILTER !!!", filter)
    if (id) {
      filter_params = filter_params.set("company_id", id)
    }
    Object.keys(filter).forEach(element => {
      if (element == 'operation_status_id' && filter[element] == 'ignore') {
        return
      }
      if (element == 'task_custom_id' && !!filter[element] && filter[element].indexOf('-') != -1) {
        let start = +filter[element].trim().split('-')[0];
        let end = +filter[element].trim().split('-')[1];
        let val = []
        for (let index = start; index <= end; index++) {
          val.push(index);
        }
        filter_params = filter_params.set(element, val.join(","));
      } else if (['q_task_channel_content_filename', 'q', 'q_task_name', 'q_task_comment', 'q_task_private_comment', 'q_task_operation_comment', 'q_task_operation_employee_comment', 'q_task_operation_private_comment'].includes(element) && !!filter[element]) {
        url += `?${element}=${encodeURIComponent(filter[element])}`;
      } else if (['is_related_task', 'is_consist_of_task', 'is_part_of_task', 'task_content_status_id', 'content_type_id', 'group_id', 'is_content_url'].includes(element) && filter[element] == '0') {
        filter_params = filter_params.set(element, filter[element]);
      } else if (element == 'operation_status_id' && !(filter[element].length == 1 && filter[element].includes(0))) {
        if (filter[element].includes(0)) {
          filter[element].splice(filter.element.indexOf(0),1)
        }
        filter_params = filter_params.set(element, filter[element].join(","));
      } else if (filter[element] != '0' && filter[element] != '') {
          if (element.indexOf('at_from') != -1 || element.indexOf('at_to') != -1) {
            filter_params = filter_params.set(element, moment(filter[element]._d).format("X"));
          } else {
            filter_params = filter_params.set(element, filter[element]);
          }
      } else if (filter[element] == '0' && filter[element] == 0 && (element == 'operation_employee_id' || element == 'client_employee_id' || element == 'operation_partner_company_id' || element == 'operation_completed_at_to')) {
        filter_params = filter_params.set(element, filter[element])
      }
    });

    
    filter_params = filter_params.set('page', page).set("per-page", per_page).set("pagination", "no").set("expand", 'operations'+expand);

    return this.http.executeGet<any>(url, true, {
      params: filter_params
    })
  }
  
  getCardsExpand(page, id, filter, per_page?, card_exp?, chan_exp = 1, cardExp?) {
    let filter_params = new HttpParams();
    let url = '/api/task/';
    console.log("getCardsExpand FILTER !!!", filter)
    if (id) {
      filter_params = filter_params.set("company_id", id)
    }
    Object.keys(filter).forEach(element => {
      if (element == 'task_custom_id' && !!filter[element] && filter[element].indexOf('-') != -1) {
        let start = +filter[element].trim().split('-')[0];
        let end = +filter[element].trim().split('-')[1];
        let val = []
        for (let index = start; index <= end; index++) {
          val.push(index);
        }
        filter_params = filter_params.set(element, val.join(","));
      } else if (['q_task_channel_content_filename', 'q', 'q_task_name', 'q_task_comment', 'q_task_private_comment', 'q_task_operation_comment', 'q_task_operation_employee_comment', 'q_task_operation_private_comment'].includes(element) && !!filter[element]) {
        url += `?${element}=${encodeURIComponent(filter[element])}`;
      } else if (['is_related_task', 'is_consist_of_task', 'is_part_of_task', 'task_content_status_id', 'content_type_id', 'group_id', 'is_content_url'].includes(element) && filter[element] == '0') {
        filter_params = filter_params.set(element, filter[element]);
      } else if (element == 'operation_status_id' && !(filter[element].length == 1 && filter[element].includes(0))) {
        if (filter[element].includes(0)) {
          filter[element].splice(filter.element.indexOf(0),1)
        }
        filter_params = filter_params.set(element, filter[element].join(","));
      } else if (filter[element] != '0' && filter[element] != '') {
          if (element.indexOf('at_from') != -1 || element.indexOf('at_to') != -1) {
            filter_params = filter_params.set(element, moment(filter[element]._d).format("X"));
          } else {
            filter_params = filter_params.set(element, filter[element]);
          }
      } else if (filter[element] == '0' && filter[element] == 0 && (element == 'operation_employee_id' || element == 'client_employee_id' || element == 'operation_partner_company_id' || element == 'operation_completed_at_to')) {
        filter_params = filter_params.set(element, filter[element])
      }
    });

    // 0 not active
    // 1 active
    // 2 need delete
    // 3 need Maks
    let expand = [
      { value: "parameterValuesToTask.parameter", is_active: 0 },
      { value: "parameterValuesToTask.parameterValue", is_active: 0 },
      { value: "consistOfTaskPartition", is_active: !!cardExp ? cardExp.relations : card_exp },
      { value: "partOfTaskPartition", is_active: !!cardExp ? cardExp.relations : card_exp },
      { value: "taskRelated", is_active: !!cardExp ? cardExp.relations : card_exp },
      { value: "consistOfTaskPartition.partOfTask", is_active: 0 },
      { value: "taskRelated.relatedTask", is_active: 0 },
      { value: "partOfTaskPartition.consistOfTask", is_active: 0 },
      { value: "partOfTaskPartition.consistOfTask.avatarFile", is_active: 0 },
      { value: "partOfTaskPartition.consistOfTask.channels.channel", is_active: 0 },
      { value: "partOfTaskPartition.consistOfTask.channels.file", is_active: 0 },
      { value: "consistOfTaskPartition.partOfTask.avatarFile", is_active: 0 },
      { value: "consistOfTaskPartition.partOfTask.channels.channel", is_active: 0 },
      { value: "consistOfTaskPartition.partOfTask.channels.file", is_active: 0 },
      { value: "taskRelated.relatedTask.avatarFile", is_active: 0 },
      { value: "taskRelated.relatedTask.channels.channel", is_active: 0 },
      { value: "taskRelated.relatedTask.channels.file", is_active: 0 },
      { value: "channels.file", is_active: !!cardExp ? cardExp.posts : chan_exp },
      { value: "channels.channel", is_active: 0 },
      { value: "channels.channel.avatarFile", is_active: !!cardExp ? cardExp.posts : chan_exp },
      { value: "channels.contentType", is_active: 0 },
      { value: "channels.contentStatus", is_active: 0 },
      { value: "channels.createdEmployee", is_active: 0 },
      { value: "channels.createdUrlEmployee", is_active: 0 },
      { value: "channels.statusUpdatedEmployee", is_active: 0 },
      { value: "channels.updatedUrlEmployee", is_active: 0 },
      { value: "avatarFile", is_active: !!cardExp ? cardExp.thumbnail : 1 },
      { value: "group.managers", is_active: 1 },
      { value: "managers", is_active: 1 },
      { value: "createdEmployee", is_active: 0 },
      { value: "operations.partnerEmployees.partnerEmployee", is_active: !!cardExp ? cardExp.jobs && cardExp.execs : 1 },
      { value: "operations.partnerEmployeesStatuses", is_active: !!cardExp ? cardExp.jobs && cardExp.execs : 1 },
      { value: "operations.employees.employee", is_active: !!cardExp ? cardExp.jobs && cardExp.execs : 1 },
      { value: "operations.employees.currency", is_active: 0 },
      { value: "operations.createdEmployee", is_active: 0 },
      { value: "operations.operation.translation", is_active: !!cardExp ? cardExp.jobs : 1 },
      { value: "operations.status", is_active: !!cardExp ? cardExp.jobs : 1 },
      { value: "operations.currency", is_active: 0 },
      { value: "operations.employeesStatuses.status", is_active: !!cardExp ? cardExp.jobs && cardExp.execs : 1 },
      { value: "operations.employeeStatus.status", is_active: 0 },
      { value: "operations.partnerCompanies.partnerCompany.avatarFile", is_active: !!cardExp ? cardExp.jobs && cardExp.execs : 1 },
      { value: "operations.partnerCompanies.currency", is_active: 0 },
      { value: "operations.partnerCompaniesStatuses", is_active: !!cardExp ? cardExp.jobs && cardExp.execs : 1 },
      { value: "operations.automationScenarios.template", is_active: 0 },
      { value: "status", is_active: 1 }
    ]
    
    filter_params = filter_params.set('page', page).set("per-page", per_page).set("pagination", "no").set("expand", expand.filter(x => x.is_active > 0).map(y => y.value).join(','));
    // filter_params = filter_params.set('page', page).set("per-page", per_page).set("pagination", "no").set("expand", "status,operations.partnerEmployees.partnerEmployee,operations.partnerEmployeesStatuses,consistOfTaskPartition.partOfTask,taskRelated.relatedTask,partOfTaskPartition.consistOfTask,channels.file,channels.channel,avatarFile,group.managers,managers,operations.employees.employee,operations.operation.translation,operations.status,operations.employeesStatuses.status,operations.partnerCompanies.partnerCompany.avatarFile,operations.partnerCompaniesStatuses");
    
    return this.http.executeGet<any>(url, true, {
      params: filter_params
    })
  }

  getCardsExpandForCard(page, id, filter, per_page?, card_exp?, chan_exp = 1, cardExp?) {
    let filter_params = new HttpParams();
    let url = '/api/task/';
    console.log("getCardsExpand FILTER !!!", filter)
    if (id) {
      filter_params = filter_params.set("company_id", id)
    }
    Object.keys(filter).forEach(element => {
      if (['q_task_channel_content_filename', 'q', 'q_task_name', 'q_task_comment', 'q_task_private_comment', 'q_task_operation_comment', 'q_task_operation_employee_comment', 'q_task_operation_private_comment'].includes(element) && !!filter[element]) {
        url += `?${element}=${encodeURIComponent(filter[element])}`;
      } else if (['is_related_task', 'is_consist_of_task', 'is_part_of_task', 'task_content_status_id', 'content_type_id', 'group_id', 'is_content_url'].includes(element) && filter[element] == '0') {
        filter_params = filter_params.set(element, filter[element]);
      } else if (element == 'operation_status_id' && !(filter[element].length == 1 && filter[element].includes(0))) {
        if (filter[element].includes(0)) {
          filter[element].splice(filter.element.indexOf(0),1)
        }
        filter_params = filter_params.set(element, filter[element].join(","));
      } else if (filter[element] != '0' && filter[element] != '') {
          if (element.indexOf('at_from') != -1 || element.indexOf('at_to') != -1) {
            filter_params = filter_params.set(element, moment(filter[element]._d).format("X"));
          } else {
            filter_params = filter_params.set(element, filter[element]);
          }
      } else if (filter[element] == '0' && filter[element] == 0 && (element == 'operation_employee_id' || element == 'client_employee_id' || element == 'operation_partner_company_id' || element == 'operation_completed_at_to')) {
        filter_params = filter_params.set(element, filter[element])
      }
    });

    // 0 not active
    // 1 active
    // 2 need delete
    // 3 need Maks
    let expand = [
      { value: "parameterValuesToTask.parameter", is_active: 0 },
      { value: "parameterValuesToTask.parameterValue", is_active: 0 },
      { value: "consistOfTaskPartition", is_active: !!cardExp ? cardExp.relations : card_exp },
      { value: "partOfTaskPartition", is_active: !!cardExp ? cardExp.relations : card_exp },
      { value: "taskRelated", is_active: !!cardExp ? cardExp.relations : card_exp },
      { value: "consistOfTaskPartition.partOfTask", is_active: 0 },
      { value: "taskRelated.relatedTask", is_active: 0 },
      { value: "partOfTaskPartition.consistOfTask", is_active: 0 },
      { value: "partOfTaskPartition.consistOfTask.avatarFile", is_active: 0 },
      { value: "partOfTaskPartition.consistOfTask.channels.channel", is_active: 0 },
      { value: "partOfTaskPartition.consistOfTask.channels.file", is_active: 0 },
      { value: "consistOfTaskPartition.partOfTask.avatarFile", is_active: 0 },
      { value: "consistOfTaskPartition.partOfTask.channels.channel", is_active: 0 },
      { value: "consistOfTaskPartition.partOfTask.channels.file", is_active: 0 },
      { value: "taskRelated.relatedTask.avatarFile", is_active: 0 },
      { value: "taskRelated.relatedTask.channels.channel", is_active: 0 },
      { value: "taskRelated.relatedTask.channels.file", is_active: 0 },
      { value: "channels.file", is_active: !!cardExp ? cardExp.posts : chan_exp },
      { value: "channels.channel", is_active: 0 },
      { value: "channels.channel.avatarFile", is_active: !!cardExp ? cardExp.posts : chan_exp },
      { value: "channels.contentType", is_active: 0 },
      { value: "channels.contentStatus", is_active: 0 },
      { value: "channels.createdEmployee", is_active: 0 },
      { value: "channels.createdUrlEmployee", is_active: 0 },
      { value: "channels.statusUpdatedEmployee", is_active: 0 },
      { value: "channels.updatedUrlEmployee", is_active: 0 },
      { value: "avatarFile", is_active: !!cardExp ? cardExp.thumbnail : 1 },
      { value: "group.managers", is_active: 1 },
      { value: "managers", is_active: 1 },
      { value: "createdEmployee", is_active: 0 },
      { value: "operations.partnerEmployees.partnerEmployee", is_active: !!cardExp ? cardExp.jobs && cardExp.execs : 1 },
      { value: "operations.partnerEmployeesStatuses", is_active: !!cardExp ? cardExp.jobs && cardExp.execs : 1 },
      { value: "operations.employees.employee", is_active: !!cardExp ? cardExp.jobs && cardExp.execs : 1 },
      { value: "operations.employees.currency", is_active: 0 },
      { value: "operations.createdEmployee", is_active: 0 },
      { value: "operations.operation.translation", is_active: !!cardExp ? cardExp.jobs : 1 },
      { value: "operations.status", is_active: !!cardExp ? cardExp.jobs : 1 },
      { value: "operations.currency", is_active: 0 },
      { value: "operations.employeesStatuses.status", is_active: !!cardExp ? cardExp.jobs && cardExp.execs : 1 },
      { value: "operations.employeeStatus.status", is_active: 0 },
      { value: "operations.partnerCompanies.partnerCompany.avatarFile", is_active: !!cardExp ? cardExp.jobs && cardExp.execs : 1 },
      { value: "operations.partnerCompanies.currency", is_active: 0 },
      { value: "operations.partnerCompaniesStatuses", is_active: !!cardExp ? cardExp.jobs && cardExp.execs : 1 },
      { value: "operations.automationScenarios.template", is_active: 0 },
      { value: "status", is_active: 1 }
    ]
    
    filter_params = filter_params.set('page', page).set("per-page", per_page).set("pagination", "no").set("expand", expand.filter(x => x.is_active > 0).map(y => y.value).join(','));
    // filter_params = filter_params.set('page', page).set("per-page", per_page).set("pagination", "no").set("expand", "status,operations.partnerEmployees.partnerEmployee,operations.partnerEmployeesStatuses,consistOfTaskPartition.partOfTask,taskRelated.relatedTask,partOfTaskPartition.consistOfTask,channels.file,channels.channel,avatarFile,group.managers,managers,operations.employees.employee,operations.operation.translation,operations.status,operations.employeesStatuses.status,operations.partnerCompanies.partnerCompany.avatarFile,operations.partnerCompaniesStatuses");
    
    return this.http.executeGet<any>(url, true, {
      observe: 'response',
      params: filter_params
    })
  }

  // .set("expand", 'operations.partnerEmployees.partnerEmployee,operations.partnerEmployeesStatuses,parameterValuesToTask.parameter,parameterValuesToTask.parameterValue,channels.file,channels.channel,channels.contentType,channels.contentStatus,channels.createdEmployee,channels.createdUrlEmployee,channels.statusUpdatedEmployee,channels.updatedUrlEmployee,avatarFile,group.managers,managers,createdEmployee,operations.employees.employee,operations.employees.currency,operations.createdEmployee,operations.operation.translation,operations.status,operations.currency,operations.employeesStatuses.status,operations.employeeStatus.status,operations.partnerCompanies.partnerCompany.avatarFile,operations.partnerCompanies.currency,operations.partnerCompaniesStatuses,operations.automationScenarios.template');
  // .set('task_id[]', '13').append('task_id[]', '14')
  // ,operations.partnerEmployees.partnerEmployee.user,operations.partnerEmployees.partnerEmployee.vuser,operations.partnerEmployeesStatuses,parameterValuesToTask.parameter,parameterValuesToTask.parameterValue,partOfTaskPartition.consistOfTask.avatarFile,partOfTaskPartition.consistOfTask.channels.channel,consistOfTaskPartition.partOfTask.avatarFile,consistOfTaskPartition.partOfTask.channels.channel,taskRelated.relatedTask.avatarFile,taskRelated.relatedTask.channels.channel
  getTasksExpand(page, id, filter, per_page?) {
    let filter_params = new HttpParams()
    console.log("getTasksExpand FILTER !!!", filter)
    if (id) {
      filter_params = filter_params.set("company_id", id)
    }
    Object.keys(filter).forEach(element => {
      if (['is_related_task', 'is_consist_of_task', 'is_part_of_task', 'task_content_status_id', 'content_type_id', 'group_id', 'is_content_url'].includes(element) && filter[element] == '0') {
        filter_params = filter_params.set(element, filter[element]);
      } else if (element == 'operation_status_id' && !(filter[element].length == 1 && filter[element].includes(0))) {
        if (filter[element].includes(0)) {
          filter[element].splice(filter.element.indexOf(0),1)
        }
        filter_params = filter_params.set(element, filter[element].join(","));
      } else if (filter[element] != '0' && filter[element] != '') {
          if (element.indexOf('at_from') != -1 || element.indexOf('at_to') != -1) {
            filter_params = filter_params.set(element, moment(filter[element]._d).format("X"));
          } else {
            filter_params = filter_params.set(element, filter[element]);
          }
      } else if (filter[element] == '0' && filter[element] == 0 && (element == 'operation_employee_id' || element == 'client_employee_id' || element == 'operation_partner_company_id' || element == 'operation_completed_at_to')) {
        filter_params = filter_params.set(element, filter[element])
      }
    });

    filter_params = filter_params.set('page', page).set("per-page", per_page).set("pagination", "no").set("expand", 'status,operations.partnerEmployees.partnerEmployee,operations.partnerEmployeesStatuses,parameterValuesToTask.parameter,parameterValuesToTask.parameterValue,channels.file,channels.channel,channels.contentType,channels.contentStatus,channels.createdEmployee,channels.createdUrlEmployee,channels.statusUpdatedEmployee,channels.updatedUrlEmployee,avatarFile,group.managers,managers,createdEmployee,operations.employees.employee,operations.employees.currency,operations.createdEmployee,operations.operation.translation,operations.status,operations.currency,operations.employeesStatuses.status,operations.employeeStatus.status,operations.partnerCompanies.partnerCompany.avatarFile,operations.partnerCompanies.currency,operations.partnerCompaniesStatuses,operations.automationScenarios.template');
    
    return this.http.executeGet<any>('/api/task/', true, {
      params: filter_params
    })
  }

  getTasksCount(id, filter) {
    let filter_params = new HttpParams();
    let url = '/api/task/';
    console.log("getTasksCount FILTER !!!", filter)
    if (id) {
      filter_params = filter_params.set("company_id", id)
    }
    Object.keys(filter).forEach(element => {
      if (element == 'operation_status_id' && filter[element] == 'ignore') {
        return
      }
      if (['q_task_channel_content_filename', 'q', 'q_task_name', 'q_task_comment', 'q_task_private_comment', 'q_task_operation_comment', 'q_task_operation_employee_comment', 'q_task_operation_private_comment'].includes(element) && !!filter[element]) {
        url += `?${element}=${encodeURIComponent(filter[element])}`;
      } else if (['is_related_task', 'is_consist_of_task', 'is_part_of_task', 'task_content_status_id', 'content_type_id', 'group_id', 'is_content_url'].includes(element) && filter[element] == '0') {
        filter_params = filter_params.set(element, filter[element]);
      } else if (element == 'operation_status_id' && !(filter[element].length == 1 && filter[element].includes(0))) {
        if (filter[element].includes(0)) {
          filter[element].splice(filter.element.indexOf(0),1)
        }
        filter_params = filter_params.set(element, filter[element].join(","));
      } else if (filter[element] != '0' && filter[element] != '') {
          if (element == 'operation_completed_at_from' || element == 'operation_completed_at_to') {
            filter_params = filter_params.set(element, moment(filter[element]._d).format("X"));
          } else {
            filter_params = filter_params.set(element, filter[element]);
          }
      } else if (filter[element] == '0' && filter[element] == 0 && (element == 'operation_employee_id' || element == 'operation_partner_company_id' || element == 'operation_completed_at_to')) {
        filter_params = filter_params.set(element, filter[element])
      }
    });

    filter_params = filter_params.set('page', '1').set('totals', '1').set("per-page", '1').set("pagination", "only")
    
    return this.http.executeGet<any>(url, true, {
      observe: 'response',
      params: filter_params
    })
  }

  getTasksExpandAll(page, id, filter, per_page?) {

    let filter_params = new HttpParams().set("expand", 'status,operations.partnerEmployees.partnerEmployee,operations.partnerEmployeesStatuses,parameterValuesToTask.parameter,parameterValuesToTask.parameterValue,channels.file,channels.channel,channels.contentType,channels.contentStatus,channels.createdEmployee,channels.createdUrlEmployee,channels.statusUpdatedEmployee,channels.updatedUrlEmployee,avatarFile,group.managers,managers,createdEmployee,operations.employees.employee,operations.employees.currency,operations.createdEmployee,operations.operation.translation,operations.status,operations.currency,operations.employeesStatuses.status,operations.employeeStatus.status,operations.partnerCompanies.partnerCompany.avatarFile,operations.partnerCompanies.currency,operations.partnerCompaniesStatuses,operations.automationScenarios.template').set('page', page).set("per-page", per_page).set("totals", "1").set("order_by", "updated_desc");
    // console.log("FILTER !!!", filter)
    if (id) {
      filter_params = filter_params.set("company_id", id)
    }
    Object.keys(filter).forEach(element => {
      if (element == 'is_content_url' && filter[element] == '0') {
        filter_params = filter_params.set(element, filter[element]);
      } else if (element == 'id' && filter[element].length) {
        filter_params = filter_params.set(element, filter[element].join(","));
      } else if (element == 'operation_status_id' && !(filter[element].length == 1 && filter[element].includes(0))) {
        if (filter[element].includes(0)) {
          filter[element].splice(filter.element.indexOf(0),1)
        }
        filter_params = filter_params.set(element, filter[element].join(","));
      } else if (element == 'operation_employee_id' && filter[element].length) {
        filter_params = filter_params.set(element, filter[element].join(","));
      } else if (filter[element] != '0' && filter[element] != '') {
          if (element == 'operation_completed_at_from' || element == 'operation_completed_at_to') {
            filter_params = filter_params.set(element, moment(filter[element]._d).format("X"));
          } else {
            filter_params = filter_params.set(element, filter[element]);
          }
      } else if (element == 'operation_completed_at_to' && filter[element] === 0) {
        filter_params = filter_params.set(element, '0');
      }
    });
    return this.http.executeGet<any>('/api/task/', true, {
      observe: 'response',
      params: filter_params
    })
  }

  getFacebookPlaces(q) {
    return this.http.executeGet<any>('/api/facebook-place/', true, {
      params: new HttpParams().set("q", q).set("per-page", "200")
    })
  }
  
  getFacebookPlacesDyn(page, per_page, q?) {
    let filter_params = new HttpParams().set("page", page).set("per-page", per_page);
    if (!!q) {
      filter_params = filter_params.set("q", q)
    }
    return this.http.executeGet<any>('/api/facebook-place/', true, {
      observe: 'response',
      params: filter_params
    })
  }

  getReminderTasksCount(id) {
    return this.http.executeGet<any>('/api/task/get-reminder-count/', true, {
      params: new HttpParams().set("company_id", id)
    })
  }

  getEmlpoyeeChecklistsTasks(company_id) {
    let filter_params = new HttpParams().set("company_id", company_id).set("operation_status_id", "4").set("per-page", "20").set("order_by", "updated_desc");
    return this.http.executeGet<any>('/api/task/', true, {
      observe: 'response',
      params: filter_params
    })
  }
  
  getOneTaskExpandNoChan(id, task_id, is_all:boolean = false, isCardV2:boolean = false) {
    let filter_params = new HttpParams().set('id', task_id)
    if (is_all) {
      filter_params = filter_params.set("v2", '1')
    } else {
      filter_params = filter_params.set("company_id", id)
    }
    if (isCardV2) {
      filter_params = filter_params.set("expand", 'status,parameterValuesToTask.parameter,parameterValuesToTask.parameterValue,avatarFile,group.managers,managers,createdEmployee.avatarFile,createdTaskTemplate,createdFile,createdOriginalFile,createdVideoProject,createdVideoProjectFile,contentPlans.contentType,contentPlans.channel.platform')
    } else {
      filter_params = filter_params.set("expand", 'status,operations.partnerEmployees.partnerEmployee,operations.partnerEmployeesStatuses,parameterValuesToTask.parameter,parameterValuesToTask.parameterValue,avatarFile,group.managers,managers,createdEmployee.avatarFile,createdTaskTemplate,createdFile,createdOriginalFile,createdVideoProject,createdVideoProjectFile,operations.employees.employee,operations.employees.currency,operations.createdEmployee,operations.operation.translation,operations.status,operations.currency,operations.employeesStatuses.status,operations.employeeStatus.status,operations.partnerCompanies.partnerCompany.avatarFile,operations.partnerCompanies.currency,operations.partnerCompaniesStatuses,operations.automationScenarios.template,contentPlans.contentType,contentPlans.channel.platform,operations.parameterValuesToTask.parameter,operations.parameterValuesToTask.parameterValue')
    }
    console.log("is_all", is_all)
    return this.http.executeGet<any>('/api/task/', true, {
      observe: 'response',
      params: filter_params
    })
  }

  getOneTaskExpandJobs(id, task_id, is_all:boolean = false) {
    let filter_params = new HttpParams().set("expand", 'operations.partnerEmployees.partnerEmployee,operations.partnerEmployeesStatuses,operations.employees.employee,operations.employees.currency,operations.createdEmployee,operations.operation.translation,operations.status,operations.currency,operations.employeesStatuses.status,operations.employeeStatus.status,operations.partnerCompanies.partnerCompany.avatarFile,operations.partnerCompanies.currency,operations.partnerCompaniesStatuses,operations.automationScenarios.template,operations.parameterValuesToTask.parameter,operations.parameterValuesToTask.parameterValue').set('id', task_id)
    if (is_all) {
      filter_params = filter_params.set("v2", '1')
    } else {
      filter_params = filter_params.set("company_id", id)
    }
    console.log("is_all", is_all)
    return this.http.executeGet<any>('/api/task/', true, {
      observe: 'response',
      params: filter_params
    })
  }

  getOneTaskExpand(id, task_id, is_all:boolean = false) {
    let filter_params = new HttpParams().set("expand", 'status,operations.partnerEmployees.partnerEmployee,operations.partnerEmployeesStatuses,parameterValuesToTask.parameter,parameterValuesToTask.parameterValue,channels.file,channels.channel,channels.contentType,channels.contentStatus,channels.createdEmployee,channels.createdUrlEmployee,channels.contentPlan,channels.statusUpdatedEmployee,channels.updatedUrlEmployee,avatarFile,group.managers,managers,createdEmployee.avatarFile,createdTaskTemplate,createdFile,createdOriginalFile,createdVideoProject,createdVideoProjectFile,operations.employees.employee,operations.employees.currency,operations.createdEmployee,operations.operation.translation,operations.status,operations.currency,operations.employeesStatuses.status,operations.employeeStatus.status,operations.partnerCompanies.partnerCompany.avatarFile,operations.partnerCompanies.currency,operations.partnerCompaniesStatuses,operations.automationScenarios.template,channels.taskChannelFiles.originalFile,contentPlans.contentType,contentPlans.channel.platform,channels.channel.avatarFile,operations.parameterValuesToTask.parameter,operations.parameterValuesToTask.parameterValue').set('id', task_id)
    if (is_all) {
      filter_params = filter_params.set("v2", '1')
    } else {
      filter_params = filter_params.set("company_id", id)
    }
    console.log("is_all", is_all)
    return this.http.executeGet<any>('/api/task/', true, {
      observe: 'response',
      params: filter_params
    })
  }

  getSmartOneTaskExpand(id, task_id, is_all:boolean = false, with_jobs:number = 0) {
    let filter_params = new HttpParams().set('id', task_id)
    if (is_all) {
      filter_params = filter_params.set("v2", '1')
    } else {
      filter_params = filter_params.set("company_id", id)
    }

    let expand = [
      { value: "parameterValuesToTask.parameter", is_active: 0 },
      { value: "parameterValuesToTask.parameterValue", is_active: 0 },
      { value: "consistOfTaskPartition", is_active: 0 },
      { value: "partOfTaskPartition", is_active: 0 },
      { value: "taskRelated", is_active: 0 },
      { value: "consistOfTaskPartition.partOfTask", is_active: 0 },
      { value: "taskRelated.relatedTask", is_active: 0 },
      { value: "partOfTaskPartition.consistOfTask", is_active: 0 },
      { value: "partOfTaskPartition.consistOfTask.avatarFile", is_active: 0 },
      { value: "partOfTaskPartition.consistOfTask.channels.channel", is_active: 0 },
      { value: "partOfTaskPartition.consistOfTask.channels.file", is_active: 0 },
      { value: "consistOfTaskPartition.partOfTask.avatarFile", is_active: 0 },
      { value: "consistOfTaskPartition.partOfTask.channels.channel", is_active: 0 },
      { value: "consistOfTaskPartition.partOfTask.channels.file", is_active: 0 },
      { value: "taskRelated.relatedTask.avatarFile", is_active: 0 },
      { value: "taskRelated.relatedTask.channels.channel", is_active: 0 },
      { value: "taskRelated.relatedTask.channels.file", is_active: 0 },
      { value: "channels.file", is_active: 0 },
      { value: "channels.channel", is_active: 0 },
      { value: "channels.channel.avatarFile", is_active: 0 },
      { value: "channels.contentType", is_active: 0 },
      { value: "channels.contentStatus", is_active: 0 },
      { value: "channels.createdEmployee", is_active: 0 },
      { value: "channels.createdUrlEmployee", is_active: 0 },
      { value: "channels.statusUpdatedEmployee", is_active: 0 },
      { value: "channels.updatedUrlEmployee", is_active: 0 },
      { value: "avatarFile", is_active: 1 },
      { value: "group.managers", is_active: 1 },
      { value: "managers", is_active: 1 },
      { value: "createdEmployee", is_active: 1 },
      { value: "operations.employees.employee", is_active: with_jobs },
      { value: "operations.employees.currency", is_active: with_jobs },
      { value: "operations.createdEmployee", is_active: with_jobs },
      { value: "operations.operation.translation", is_active: with_jobs },
      { value: "operations.status", is_active: with_jobs },
      { value: "operations.currency", is_active: with_jobs },
      { value: "operations.employeesStatuses.status", is_active: with_jobs },
      { value: "operations.employeeStatus.status", is_active: with_jobs },
      { value: "operations.partnerCompanies.partnerCompany.avatarFile", is_active: with_jobs },
      { value: "operations.partnerCompanies.currency", is_active: with_jobs },
      { value: "operations.partnerCompaniesStatuses", is_active: with_jobs },
      { value: "operations.automationScenarios.template", is_active: with_jobs },
      { value: "operations.partnerEmployees.partnerEmployee", is_active: with_jobs },
      { value: "operations.partnerEmployeesStatuses", is_active: with_jobs },
      { value: "operations.parameterValuesToTask.parameter", is_active: with_jobs },
      { value: "operations.parameterValuesToTask.parameterValue", is_active: with_jobs },
      { value: "status", is_active: 1 },
      { value: "createdEmployee.avatarFile", is_active: 1 },
      { value: "createdTaskTemplate", is_active: 1 },
      { value: "createdFile", is_active: 1 },
      { value: "createdOriginalFile", is_active: 1 },
      { value: "createdVideoProject", is_active: 1 },
      { value: "createdVideoProjectFile", is_active: 1 },
    ]
    
    filter_params = filter_params.set("expand", expand.filter(x => x.is_active > 0).map(y => y.value).join(','));
    
    return this.http.executeGet<any>('/api/task/', true, {
      observe: 'response',
      params: filter_params
    })
  }

  getOneTaskPartnerExpand(company_id, partner_company_id, task_id) {
    let filter_params = new HttpParams().set("company_id", company_id).set("partner_company_id", partner_company_id).set("expand", 'status,operations.partnerEmployees.partnerEmployee,operations.partnerEmployeesStatuses,parameterValuesToTask.parameter,parameterValuesToTask.parameterValue,channels.file,channels.channel,channels.contentType,channels.contentStatus,channels.createdEmployee,channels.createdUrlEmployee,channels.statusUpdatedEmployee,channels.updatedUrlEmployee,avatarFile,group.managers,managers,createdEmployee.avatarFile,createdTaskTemplate,createdFile,createdOriginalFile,createdVideoProject,createdVideoProjectFile,operations.employees.employee,operations.employees.currency,operations.createdEmployee,operations.operation.translation,operations.status,operations.currency,operations.employeesStatuses.status,operations.employeeStatus.status,operations.partnerCompanies.partnerCompany.avatarFile,operations.partnerCompanies.currency,operations.partnerCompaniesStatuses,operations.automationScenarios.template').set('id', task_id)
 
    return this.http.executeGet<any>('/api/task/', true, {
      observe: 'response',
      params: filter_params
    })
  }
  
  getMultiTasks(id, task_ids) {
    return this.http.executeGet<any>('/api/task/', true, {
      observe: 'response',
      params: new HttpParams().set("partner_id", id).set("expand", 'status,operations.partnerEmployees.partnerEmployee,operations.partnerEmployeesStatuses,parameterValuesToTask.parameter,parameterValuesToTask.parameterValue,channels.file,channels.channel,channels.contentType,channels.contentStatus,channels.createdEmployee,channels.createdUrlEmployee,channels.statusUpdatedEmployee,channels.updatedUrlEmployee,avatarFile,group.managers,managers,createdEmployee,operations.employees.employee,operations.employees.currency,operations.createdEmployee,operations.operation.translation,operations.status,operations.currency,operations.employeesStatuses.status,operations.employeeStatus.status,operations.partnerCompanies.partnerCompany.avatarFile,operations.partnerCompanies.currency,operations.partnerCompaniesStatuses,operations.automationScenarios.template').set('id', task_ids)
    })
  }

  getHeaderStats(id, operation, operation_completed_at_from, operation_completed_at_to) {
    return this.http.executeGet<any>('/api/task/', true, {
      observe: 'response',
      params: new HttpParams().set("company_id", id).set('operation_status_id', operation).set('totals', '1').set('per-page', '0').set('operation_completed_at_from', operation_completed_at_from).set('operation_completed_at_to', operation_completed_at_to)
    })
  }

  getAllRateStats(id,page) {
    return this.http.executeGet<any>('/api/employee-stat-by-month/', true, {
      observe: 'response',
      params: new HttpParams().set("company_id", id).set("expand", 'currency,employee').set('page',page).set('per-page', '50')
    })
  }

  refreshStats(id) {
    return this.http.executePost<any>('/api/employee-stat-by-month/update/', {
      company_id: id
    })
  }

  getProfileStatuses() {
    return this.http.executeGet<any>(`/api/content-status/`)
  }

  getRateStats(id, time_from, time_to) {
    return this.http.executeGet<any>('/api/employee-stat-by-month/', true, {
      params: new HttpParams().set("company_id", id).set("expand", 'currency,employee').set('time_from', time_from).set("time_to", time_to).set("currency_id", '0')
    })
  }

  getEmployeesDyn(page, company_id, filter?, per_page = '200') {
    let filter_params = new HttpParams().set('page', page).set("company_id", company_id).set('is_deleted', '0').set("expand", 'employeeSetting,user,vuser,currency').set('per-page', per_page)

    if (filter) {
      Object.keys(filter).forEach(element => {
          filter_params = filter_params.set(element, filter[element]);
      });
    }

    return this.http.executeGet<any>(`/api/employee/`, true, {
      observe: 'response',
      params: filter_params
    })
  }

  getEmployees(company_id, filter?) {
    let filter_params = new HttpParams().set("company_id", company_id).set('is_deleted', '0').set("expand", 'employeeSetting,user,vuser,currency').set('per-page', '200')

    if (filter) {
      Object.keys(filter).forEach(element => {
          filter_params = filter_params.set(element, filter[element]);
      });
    }

    return this.http.executeGet<any>(`/api/employee/`, true, {
      params: filter_params
    })
  }

  getEmployeesDataDyn(page, company_id, filter?, no_expand?) {
    let filter_params = new HttpParams().set('page', page).set("company_id", company_id).set('per-page', '200')

    if (!no_expand) {
      filter_params = filter_params.set("expand", 'employeeSetting,user,vuser,currency,employeeToDepartmentPositions.department,employeeToDepartmentPositions.departmentPosition')
    }

    if (filter) {
      Object.keys(filter).forEach(element => {
          filter_params = filter_params.set(element, filter[element]);
      });
    }

    return this.http.executeGet<any>(`/api/employee/`, true, {
      observe: 'response',
      params: filter_params
    })
  }

  getEmployeeToEmployee(company_id, employee_id) {
    let filter_params = new HttpParams().set("company_id", company_id).set("employee_id", employee_id).set("expand", 'viewEmployee, viewPartnerCompany').set('per-page', '200')

    return this.http.executeGet<any>(`/api/employee-to-employee/`, true, {
      params: filter_params
    })
  }

  addEmployeeToEmployee(data) {
    return this.http.executePost<any>(`/api/employee-to-employee/`, data)
  }

  deleteEmployeeToEmployee(id) {
    return this.http.executeDelete<any>(`/api/employee-to-employee/${id}/`)
  }

  getEmployeesPaging(id,page) {
    return this.http.executeGet<any>('/api/employee/', true, {
      observe: 'response',
      params: new HttpParams().set("company_id", id).set('is_deleted', '0').set("expand", 'employeeSetting,user,vuser,currency').set('per-page', '50').set('page',page)
    })
  }

  getPartnerRulesList() {
    return this.http.executeGet<any>("/api/employee-permission/partner-optional-permissions/")
  }

  getRules() {
    return this.http.executeGet<any>("/api/employee-permission/optional-permissions/")
  }

  getFileRules(filter?) {
    let filter_params = new HttpParams().set("per-page", "200")

    if (filter) {
      Object.keys(filter).forEach(element => {
          filter_params = filter_params.set(element, filter[element]);
      });
    }

    return this.http.executeGet<any>("/api/employee-permission-file/", true, {
      params: filter_params
    })
  }

  addFileRule(data, company_id) {
    return this.http.executePost<any>(`/api/employee-permission-file/`, data, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }

  getPartnerRules(partner_company_id, company_id) {
    return this.http.executeGet<any>("/api/employee-permission/", true, {
      params: new HttpParams().set("partner_company_id", partner_company_id).set("company_id", company_id)
    })
  }

  getEmployeeRules(id, company_id) {
    return this.http.executeGet<any>("/api/employee-permission/", true, {
      params: new HttpParams().set("employee_id", id).set("company_id", company_id)
    })
  }

  addEmployeeRule(data, company_id) {
    return this.http.executePost<any>(`/api/employee-permission/`, data, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }

  deleteFileRule(id, company_id) {
    return this.http.executeDelete<any>(`/api/employee-permission-file/${id}/`, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }

  deleteEmployeeRule(id) {
    return this.http.executeDelete<any>(`/api/employee-permission/${id}/`)
  }

  editEmployee(id, form) {
    return this.http.executePost<any>(`/api/employee/${id}/`, form)
  }

  getEmployeeStatuses() {
    return this.http.executeGet<any>(`/api/employee/optional-permissions/`)
  }

  deleteEmployee(id) {
    return this.http.executeDelete<any>(`/api/employee/${id}/`)
  }

  deleteClient(id) {
    return this.http.executeDelete<any>(`/api/task-client/${id}/`)
  }

  addClientToTask(data, company_id) {
    return this.http.executePost<any>(`/api/task-client/`, data, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }

  editClientInTask(id, data, company_id) {
    return this.http.executePost<any>(`/api/task-client/${id}/`, data, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }

  addManagerToGroup(data) {
    return this.http.executePost<any>(`/api/group-manager/`, data)
  }

  editManagerInGroup(id, data) {
    return this.http.executePost<any>(`/api/group-manager/${id}/`, data)
  }

  deleteManagerFromGroup(id) {
    return this.http.executeDelete<any>(`/api/group-manager/${id}/`)
  }

  getTaskClients(id, company_id) {
    return this.http.executeGet<any>('/api/task-client/', true, {
      params: new HttpParams().set("task_id", id).set("company_id", company_id).set('expand', 'employee,partnerCompany,partnerCompany.avatarFile').set("per-page", "200")
    })
  }

  getTaskClientsDyn(page, id, company_id, per_page:any = '200', filter?) {
    let filter_params = new HttpParams().set("task_id", id).set('page', page).set("company_id", company_id).set('expand', 'employee,partnerCompany,partnerCompany.avatarFile').set("per-page", per_page)
    
    if (filter) {
      Object.keys(filter).forEach(element => {
          filter_params = filter_params.set(element, filter[element]);
      });
    }
    
    return this.http.executeGet<any>('/api/task-client/', true, {
      observe: 'response',
      params: filter_params
    })
  }

  getGroupManagers(filter?) {
    let filter_params = new HttpParams().set('expand', 'group,employee,employee.user,employee.vuser').set("per-page", "200")
    if (filter) {
      Object.keys(filter).forEach(element => {
          filter_params = filter_params.set(element, filter[element]);
      });
    }
    return this.http.executeGet<any>('/api/group-manager/', true, {
      params: filter_params
    })
  }

  getOperationReminder(company_id, filter = {}, page = '1', per_page = '200') {
    let filter_params = new HttpParams().set("company_id", company_id).set('page', page).set("per-page", per_page).set('expand', 'group,operation,status,exceptParameterValues.parameterValue.parameter,toParameterValues.parameterValue.parameter,fileParameterValue')
    // console.log("getTaskClients FILTER !!!", filter)
    if (filter) {
      Object.keys(filter).forEach(element => {
          filter_params = filter_params.set(element, filter[element]);
      });
    }

    return this.http.executeGet<any>(`/api/operation-reminder/`, true, {
      observe: 'response',
      params: filter_params
    })
  }



  getUnReadNotsCount(company_id) {
    return this.http.executeGet<any>(`/api/task-action/actions-not-read/`, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }

  getAllActions() {
    return this.http.executeGet<any>(`/api/task-action/optional-actions/`)
  }

  getOperationReminderId(id) {
    return this.http.executeGet<any>(`/api/operation-reminder/${id}/`)
  }

  createOperationReminder(data) {
    return this.http.executePost<any>(`/api/operation-reminder/`, data)
  }

  editOperationReminder(id, data) {
    return this.http.executePost<any>(`/api/operation-reminder/${id}/`, data)
  }

  deleteOperationReminder(id) {
    return this.http.executeDelete<any>(`/api/operation-reminder/${id}/`)
  }

  getWorkChecklist(id) {
    return this.http.executeGet<any>('/api/operation-reminder-status/', true, {
      params: new HttpParams().set("task_operation_id", id)
    })
  }

  getHoursChart(company_id, task_channel_id, filter?) {
    let filter_params = new HttpParams().set("company_id", company_id).set('task_channel_id', task_channel_id).set('per-page', '200')
    if (filter) {
      Object.keys(filter).forEach(element => {
          filter_params = filter_params.set(element, filter[element]);
      });
    }

    return this.http.executeGet<any>('/api/task-channel-analytics/', true, {
      observe: 'response',
      params: filter_params
    })
  }

  getDaysChart(company_id, task_channel_id, filter?) {
    let filter_params = new HttpParams().set("company_id", company_id).set('task_channel_id', task_channel_id).set('per-page', '200')
    if (filter) {
      Object.keys(filter).forEach(element => {
          filter_params = filter_params.set(element, filter[element]);
      });
    }

    return this.http.executeGet<any>('/api/task-channel-analytics-by-day/', true, {
      observe: 'response',
      params: filter_params
    })
  }

  getContentHistory(company_id, task_channel_id, filter?) {
    let filter_params = new HttpParams().set("company_id", company_id).set('task_channel_id', task_channel_id).set('per-page', '200')
    if (filter) {
      Object.keys(filter).forEach(element => {
          filter_params = filter_params.set(element, filter[element]);
      });
    }

    return this.http.executeGet<any>('/api/task-channel-content-history/', true, {
      params: filter_params
    })
  }

  deleteContentHistoryElement(id) {
    return this.http.executeDelete<any>(`/api/task-channel-content-history/${id}/`)
  }

  createOperationReminderStatus(task_operation_id, operation_reminder_id, company_id) {
    let data = {
      task_operation_id: task_operation_id,
      operation_reminder_id: operation_reminder_id,
      is_done: 1
    }
    return this.http.executePost<any>(`/api/operation-reminder-status/`, data, true, {
      params: new HttpParams().set("company_id", company_id)
    })
  }

  deleteOperationReminderStatus(operation_reminder_status) {
    return this.http.executeDelete<any>(`/api/operation-reminder-status/${operation_reminder_status}/`)
  }

  // 
  multiRequest(data) {
    return this.http.executePost<any>(`/api/batch/`, data)
  }
}
