import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import * as moment from 'moment';
import { concat, forkJoin, fromEvent, of, ReplaySubject, Subject, Subscription, throwError } from 'rxjs';
import { concatMap, debounceTime, distinctUntilChanged, filter, flatMap, last, map, retry, switchMap, take, tap, toArray } from 'rxjs/operators';
import { DeleteAlertComponent } from 'src/app/shared/global_components/delete-alert/delete-alert.component';
import { NotificationsBar } from 'src/app/shared/global_components/notifications-bar/notifications-bar';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LanguageService } from 'src/app/shared/services/common/language.service';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { ChatService } from 'src/app/shared/services/rest/chat.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { TaskService } from 'src/app/shared/services/rest/task.service';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { DateRange, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { casesModel } from 'src/app/shared/functions/casesModel';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { LoadingService } from 'src/app/shared/services/rest/loading.service';
import { RefreshService } from 'src/app/shared/services/rest/refresh.service';
import { GlobalDataService } from 'src/app/shared/services/common/global-data.service';
import { MY_FORMATS } from '../../workspace-settings/finances/statements/statements.component';
import { ChatsComponent } from '../../chats/chats.component';
import { OperationChatComponent } from '../../atTasksDialog/operation-chat/operation-chat.component';
import { FileService } from 'src/app/shared/services/rest/file.service';
import { UploadService } from 'src/app/shared/services/rest/upload.service';
import { ParametersService } from 'src/app/shared/services/rest/parameters.service';
import { OpenJobComponent } from '../cases/dialogs/open-job/open-job.component';
import { OpenTaskComponent } from '../cases/dialogs/open-task/open-task.component';
import { EditPublishedAtComponent } from '../../atTasksDialog/profiles/edit-published-at/edit-published-at.component';
import { EditStatusComponent } from '../../atTasksDialog/profiles/edit-status/edit-status.component';
import { EditContentNameComponent } from '../../atTasksDialog/profiles/edit-content-name/edit-content-name.component';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView } from 'angular-calendar';
import { endOfDay, endOfMonth, endOfWeek, isSameDay, isSameMonth, startOfDay, startOfMonth, startOfWeek } from 'date-fns';
import { AddAndEditScheduledPostComponent } from '../../workspace-settings/add-and-edit-scheduled-post/add-and-edit-scheduled-post.component';
import { AddTaskProfileComponent } from '../cases/dialogs/open-task/dialogs/add-task-profile/add-task-profile.component';
import { Moment } from 'moment';
import { UrlAnalyticsComponent } from '../cases/dialogs/open-task/dialogs/url-analytics/url-analytics.component';
import { AddChannelToTaskComponent } from '../../atTasksDialog/add-channel-to-task/add-channel-to-task.component';
import { MobUrlAnalyticsComponent } from '../cases/dialogs/open-task/dialogs/mob-url-analytics/mob-url-analytics.component';
import { environment } from 'src/environments/environment';
import { SaveFilterToBookmarkComponent } from 'src/app/shared/global_components/navigation-bar/dialogs/save-filter-to-bookmark/save-filter-to-bookmark.component';
import { el } from 'date-fns/locale';

import { orderBy } from 'lodash';


const colors: Record<string, any> = {
  1: {
    type: "Waiting",
    primary: '#2F80ED',
    secondary: '#BCD7FD',
  },
  2: {
    type: "Draft",
    primary: '#DB0000',
    secondary: '#FFAEAE',
  },
  3: {
    type: "Scheduled",
    primary: '#BEC11B',
    secondary: '#FEFFB9',
  },
  4: {
    type: "Published",
    primary: '#219653',
    secondary: '#98F1BD',
  },
  5: {
    type: "Canceled",
    primary: '#686868',
    secondary: '#E0E0E0',
  },
  6: {
    type: "content",
    primary: '#DD5E89',
    secondary: '#F7BB97',
  }
};

@Component({
  selector: 'app-outlets-dashboard',
  templateUrl: './outlets-dashboard.component.html',
  styleUrls: ['./outlets-dashboard.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: false } },
  ]
})
export class OutletsDashboardComponent extends BaseClass implements OnInit, AfterViewInit, OnDestroy {
  public view: any[] = [197, 68];
  public colorTimeScheme = {
    domain: ['#2ea5cb']
  };
  public colorScheme = {
    domain: ['#5AA454']
  };
  public colorSchemeRev = {
    domain: ['#546CA4']
  };
  public colorSchemeAdRev = {
    domain: ['#6954A4']
  };
  public colorSchemeRedRev = {
    domain: ['#932D2D']
  };
  throttle = 100;
  scrollUpDistance = 3.5;
  public host: any = environment.host;
  public imgRoute: any = '';
  public user: any = {};
  public user_id: number;
  public company: any;
  public currMonthName: any;
  public company_id: any;
  public activeLang: any;
  public currencyes: any;

  public totalValues = {
    dayTotal: 0,
    hourTotal: 0,
    totalRevenue: 0,
    totalAdRevenue: 0,
    totalRedRevenue: 0
  }

  public page: number = 1;
  public pagination: object = {};

  public filter: FormGroup = this.fb.group({
    platform_id: 0,
    o__dayView: 1,
    o__hourView: 1,
    o__realtime: 2,
  });

  public timeZone = new Date().getTimezoneOffset();
  public is_mobile: boolean = false;

  public filterCount: number = 1;
  public isLoad: boolean = false;
  public curYear = (new Date()).getFullYear()
  public canAddNewTopPerforming: boolean = false;
  
  public platforms: any;
  public platforms$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public platformsMoreControl: FormControl = new FormControl();
  
  public count = 1;
  
  public outletDashSub: Subscription;
  public profiles: any[] = [];
  public profiles$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public hourViewsFilters = [
    {
      id: 0,
      name: "Last 24 hours",
      divider: false,
      from: moment().subtract(24, 'h'),
      to: 0
    },
    {
      id: 1,
      name: "Last 48 hours",
      divider: false,
      from: moment().subtract(48, 'h'),
      to: 0
    },
    {
      id: 2,
      name: "Last 72 hours",
      divider: false,
      from: moment().subtract(72, 'h'),
      to: 0
    }
  ]
  public realtimeFilters = [
    {
      id: 0,
      name: "Last 24 hours",
      divider: false,
      from: moment().subtract(24, 'h'),
      val: 24,
      to: 0
    },
    {
      id: 1,
      name: "Last 48 hours",
      divider: false,
      from: moment().subtract(48, 'h'),
      val: 48,
      to: 0
    },
    {
      id: 2,
      name: "Last 72 hours",
      divider: false,
      from: moment().subtract(72, 'h'),
      val: 72,
      to: 0
    }
  ]

  public dayFilters = [
    {
      id: 0,
      name: "Last 7 days",
      divider: false,
      from: moment().subtract(7, 'd'),
      to: 0
    },
    {
      id: 1,
      name: "Last 30 days",
      divider: false,
      from: moment().subtract(30, 'd'),
      to: 0
    },
    {
      id: 2,
      name: "Last 90 days",
      divider: true,
      from: moment().subtract(90, 'd'),
      to: 0
    },
    {
      id: 3,
      name: "This year",
      divider: false,
      from: moment().startOf('year'),
      to: moment().endOf('year')
    },
    {
      id: 4,
      name: "Last 365 days",
      divider: false,
      from: moment().subtract(365, 'd'),
      to: 0
    },
    {
      id: 5,
      name: "Last year",
      divider: false,
      from: moment().startOf("year").subtract(1, 'd').startOf("year"),
      to: moment().startOf("year").subtract(1, 'd').endOf("year")
    },

  ]


  
  public employees: any;
  public bookmarks: any;
  constructor(
    private sm: StorageManagerService,
    private auth: AuthService,
    private router: Router,
    private globalDataService: GlobalDataService,
    public activatedRoute: ActivatedRoute,
    private taskService: TaskService,
    private bottomSheet: MatBottomSheet,
    private companyService: CompanyService,
    private languageService: LanguageService,
    private layoutService: LayoutService,
    private uploadService: UploadService,
    private _adapter: DateAdapter<any>,
    private refreshService: RefreshService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    public viewContainerRef: ViewContainerRef,
    public overlay: Overlay,
    public ls: LoadingService,
    private cd: ChangeDetectorRef
  ) { super() }

  ngOnInit(): void {
    this.company_id = this.activatedRoute.snapshot.queryParamMap.get('company_id');

    this.attachSubscriptions(
      this.filter.valueChanges.subscribe(fils => {
        console.log("fils", fils)
        if (fils.o__dayView != 1 || fils.o__hourView != 1 || fils.o__realtime != 2 || fils.platform_id != 0) {
          this.canAddNewTopPerforming = true;
        } else {
          this.canAddNewTopPerforming = false;
        }
      })
    )

    this.attachSubscriptions(
      this.refreshService.getRefreshBookmarks().subscribe(res => {
        if (res == "Outlet Dashboards") {
          this.getBookmarks()
        }
      })
    )
    this.checkIsMobile();
    this.getLangData(this.company_id);
    this.getPlatforms();
    this.getImgRoute();
    this.getUser();
    this.getEmployees();
    this.onRouteChange();

    this.attachSubscriptions(
      this.platformsMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchPlatforms(resp))
    )
  }

  getEmployees() {
    this.attachSubscriptions(
      this.taskService.getEmployees(this.company_id).subscribe(resp => {
        this.employees = resp;
      })
    )
  }

  sortedProfiles(array) {
    return array && array.length > 1 ? orderBy(array, ['dayViews'], ['desc']) : array;
  }
    
  getBookmarks() {
    this.attachSubscriptions(
      this.companyService.getBookmarks(this.company_id, {employee_id: this.company.employees[0].id, section: "Outlet Dashboards"}).pipe(
        tap(arr => {
          arr.forEach(element => {
            element.is_load = false;
            element.page = 1;
            element.profiles = [];
            (element.pagination as any) = undefined;
            element.totalValues = {
              dayTotal: 0,
              hourTotal: 0
            }
          });
          this.bookmarks = arr;
        }),
        concatMap(arr => {
          return concat(...arr.map(bookmark => this.getBookmarkProfiles(bookmark))).pipe(last())
        }),
      ).subscribe(res => {
        console.log("bookmarks", this.bookmarks)
      })
    )
  }
  
  getBookmarkById(id) {
    if (!this.bookmarks || this.bookmarks.length == 0) {
      return null
    }
    return this.bookmarks.find(x => x.id == id)
  }
  
  addCustomDashboard(type) {
    let data = JSON.parse(JSON.stringify(this.filter.value))
    this.saveFilterToBookmarks(type, data)
  }

  saveFilterToBookmarks(type, values) {
    const dialogRef = this.dialog.open(SaveFilterToBookmarkComponent, {
      backdropClass: ['backdrop_under_header'],
      panelClass: !this.is_mobile ? ['show_header'] : '',
      hasBackdrop: true,
      data: {
        user: this.user,
        company: this.company,
        host: this.host,
        imgRoute: this.imgRoute,
        filter: this.filter,
        activeLang: this.activeLang,
        params: values,
        employees: this.employees,
        platforms: this.platforms,
        dayFilters: this.dayFilters,
        hourViewsFilters: this.hourViewsFilters,
        realtimeFilters: this.realtimeFilters,
        profiles: this.profiles,
        type: type
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        
        if (!!result) {
          console.log("result", result)
        }
      })
    )
  }

  getBookmarkProfiles(bookmark) {
    // let filterData:any = {}
    // if (!!bookmark.settings.platform_id) {
    //   filterData.platform_id = bookmark.settings.platform_id
    // }
    bookmark.isLoad = true;
    return this.companyService.getFilteredProfiles(this.company_id, bookmark.page, bookmark.settings).pipe(
      tap(el => {
        bookmark.pagination = {
          'pageCount': el.headers.get('x-pagination-page-count'),
          'perPage': el.headers.get('x-pagination-per-page'),
          'totalCount': el.headers.get('x-pagination-total-count'),
          'currentPage': el.headers.get('x-pagination-current-page'),
        }
      }),
      map(el => el.body.slice()),
      tap(arr => {
        arr.map(x => {
          x.bookmarkId = bookmark.id;
          x.hourViews = 0;
          x.dayViews = 0;
        });
      }),
      tap(arr => {
        this.taskService.outletsDash$.next(arr)
      }),
      tap(arr => {
        bookmark.profiles.push(...arr);
        bookmark.page = +bookmark.page + 1;
        bookmark.isLoad = false;
      })
    )
  }

  onSearchPlatforms(resp) {
    if (!this.platforms) {
      return;
    }

    if (!resp) {
      this.platforms$.next(this.platforms.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    // filter the banks
    this.platforms$.next(
      this.platforms.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  logProf(profile) {
    console.log("logProf", profile)
  }
  
  neededChartData(channel) {
    console.log(channel)
    let arr = [];
    let filterDayData:any;
    if (channel.hasOwnProperty('bookmarkId')) {
      filterDayData = {
        start_time_from: this.dayFilters[this.bookmarks.find(x => x.id == channel.bookmarkId).settings.o__dayView].from.clone().format("X"),
        totals: '1',
        order_by: 'start_time',
        'per-page': 400,
        channel_id: channel.id,
      }
  
      if (!!this.dayFilters[this.bookmarks.find(x => x.id == channel.bookmarkId).settings.o__dayView].to) {
        filterDayData.start_time_to = (this.dayFilters[this.bookmarks.find(x => x.id == channel.bookmarkId).settings.o__dayView].to as Moment).clone().format("X")
      }
    } else {
      filterDayData = {
        start_time_from: this.dayFilters[this.filter.value.o__dayView].from.clone().format("X"),
        totals: '1',
        order_by: 'start_time',
        'per-page': 400,
        channel_id: channel.id,
      }
  
      if (!!this.dayFilters[this.filter.value.o__dayView].to) {
        filterDayData.start_time_to = (this.dayFilters[this.filter.value.o__dayView].to as Moment).clone().format("X")
      }
    }


    arr.push(
      this.taskService.getDayChannelAnalytics(this.company_id, filterDayData, "1").pipe(
        tap(x => {
          if (channel.hasOwnProperty('bookmarkId')) {
            // console.log("test day channel.bookmarkId", channel.bookmarkId)
            // console.log("test day channel.id", channel.id)
            this.bookmarks.find(x => x.id == channel.bookmarkId).totalValues.dayTotal += +x.headers.get('x-summary-views')
          } else {
            this.totalValues.dayTotal = +this.totalValues.dayTotal + +x.headers.get('x-summary-views')
          }
          channel.dayData = x.body;
          channel.dayViews = +x.headers.get('x-summary-views');

          channel.totalRevenue = 0;
          channel.totalAdRevenue = 0;
          channel.totalRedRevenue = 0;
        }),
        map(x => x.body),
        tap(analytics => {
          for (let index = 0; index < analytics.length; index++) {
            let element = analytics[index];

            channel.totalRevenue += +element.estimated_revenue;
            channel.totalAdRevenue += +element.estimated_ad_revenue;
            channel.totalRedRevenue += +element.estimated_red_partner_revenue;

            this.totalValues.totalRevenue += +element.estimated_revenue;
            this.totalValues.totalAdRevenue += +element.estimated_ad_revenue;
            this.totalValues.totalRedRevenue += +element.estimated_red_partner_revenue;

            element.value = element.views_count;
            element.date = moment(element.start_time * 1000).clone()
            if (moment(element.start_time * 1000).clone().isAfter(moment().hours(0).minutes(0).seconds(0)) || moment(element.start_time * 1000).clone().isSame(moment().hours(0).minutes(0).seconds(0))) {
              element.name = moment(element.start_time * 1000).clone().format("MM/DD")
            } else if (moment(element.start_time * 1000).clone().year() == moment().year()) {
              element.name = moment(element.start_time * 1000).clone().format("MM/DD")
            } else {
              element.name = moment(element.start_time * 1000).clone().format("MM/DD/YYYY")
            }
          }
        }),
        tap(res => {
          channel.dayChart = [...[{
            name: channel.name,
            series: res
          }]]

          // revenueChart
          let k = JSON.parse(JSON.stringify(res));
          k.forEach(el => {
            el.value = +el.estimated_revenue
          });

          channel.revenueChart = [...[{
            name: channel.name,
            series: k
          }]]

          // adRevenueChart
          // let b = JSON.parse(JSON.stringify(res));
          // b.forEach(el => {
          //   el.value = +el.estimated_ad_revenue
          // });

          // channel.adRevenueChart = [...[{
          //   name: channel.name,
          //   series: b
          // }]]

          // // redRevenueChart
          // let r = JSON.parse(JSON.stringify(res));
          // r.forEach(el => {
          //   el.value = +el.estimated_red_partner_revenue
          // });

          // channel.redRevenueChart = [...[{
          //   name: channel.name,
          //   series: r
          // }]]
          // this.createAreaTicks(this.chartDataV2, 2);
          // console.log("chartDataV2", this.chartDataV2)
        })
      )
    )


    let filterHourData:any;

    if (channel.hasOwnProperty('bookmarkId')) {
      filterHourData = {
        start_time_from: this.hourViewsFilters[this.bookmarks.find(x => x.id == channel.bookmarkId).settings.o__hourView].from.clone().format("X"),
        totals: '1',
        order_by: 'start_time',
        'per-page': 1,
        channel_id: channel.id,
      }
    } else {
      filterHourData = {
        start_time_from: this.hourViewsFilters[this.filter.value.o__hourView].from.clone().format("X"),
        totals: '1',
        order_by: 'start_time',
        'per-page': 1,
        channel_id: channel.id,
      }
    }

    arr.push(
      this.taskService.getHourChannelAnalytics(this.company_id, filterHourData, "1").pipe(
        tap(x => {
          if (channel.hasOwnProperty('bookmarkId')) {
            // console.log("test hour channel.bookmarkId", channel.bookmarkId)
            // console.log("test hour channel.id", channel.id)
            this.bookmarks.find(x => x.id == channel.bookmarkId).totalValues.hourTotal = +this.bookmarks.find(x => x.id == channel.bookmarkId).totalValues.hourTotal + +x.headers.get('x-summary-views')
          } else {
            this.totalValues.hourTotal = +this.totalValues.hourTotal + +x.headers.get('x-summary-views')
          }
          channel.hourViews = +x.headers.get('x-summary-views');
        }),
      )
    )

    arr.push(
      this.taskService.getHourChannelAnalytics(this.company_id, {
        start_time_from: this.realtimeFilters[this.filter.value.o__realtime].from.clone().format("X"),
        channel_id: channel.id,
        order_by: 'start_time'
      }).pipe(
        tap(x => {
          channel.maxChartVal = 0;
          for (let index = 0; index < x.body.length; index++) {
            let element = x.body[index];
            element.value = element.views_count

            if (!!element.value && element.value > channel.maxChartVal) {
              channel.maxChartVal = element.value
            }
            element.date = moment(element.start_time * 1000).clone()
            if (moment(element.start_time * 1000).clone().isAfter(moment().hours(0).minutes(0).seconds(0)) || moment(element.start_time * 1000).clone().isSame(moment().hours(0).minutes(0).seconds(0))) {
              element.name = moment(element.start_time * 1000).clone().format("hh:mm a") + " \u2013 " + moment(element.end_time * 1000).clone().format("hh:mm a");
            } else if (moment(element.start_time * 1000).clone().year() == moment().year()) {
              element.name = moment(element.start_time * 1000).clone().format("MM/DD, hh:mm a") + " \u2013 " + moment(element.end_time * 1000).clone().format("hh:mm a");
            } else {
              element.name = moment(element.start_time * 1000).clone().format("MM/DD/YYYY, hh:mm a") + " \u2013 " + moment(element.end_time * 1000).clone().format("hh:mm a");
            }
          }

          let model = [];
  
          for (let i:number = 1; i <= this.realtimeFilters[this.filter.value.o__realtime].val; i++) {
            let date = moment().minutes(0).seconds(0).clone().subtract(this.realtimeFilters[this.filter.value.o__realtime].val-i, 'h')
            if (x.body.filter(u => date.isSameOrAfter(moment(u.start_time * 1000)) && date.clone().subtract(1, 's').isSameOrBefore(moment(u.end_time * 1000))).length == 0) {
              let name = '';
              if (date.clone().isSameOrAfter(moment().hours(0).minutes(0).seconds(0))) {
                name = date.clone().format("hh:mm a") + " \u2013 " + date.clone().add(1,'h').subtract(1, 's').format("hh:mm a");
              } else if (date.clone().year() == moment().year()) {
                name = date.clone().format("MM/DD, hh:mm a") + " \u2013 " + date.clone().add(1,'h').subtract(1, 's').format("hh:mm a");
              } else {
                name = date.clone().format("MM/DD/YYYY, hh:mm a") + " \u2013 " + date.clone().add(1,'h').subtract(1, 's').format("hh:mm a");
              }
              model.push({
                index: i,
                hour: i - this.realtimeFilters[this.filter.value.o__realtime].val,
                date: date,
                name: name,
                value: 0
              })
            } else {
              model.push(x.body.find(u => date.isSameOrAfter(moment(u.start_time * 1000)) && date.clone().subtract(1, 's').isSameOrBefore(moment(u.end_time * 1000))))
            }
          }
          channel.timeChart = [...model]
        }),
      )
    )

    return forkJoin(arr)
  }

  log() {
    console.log(this.sortedProfiles(this.profiles));
  }

  getSumm(arr) {
    return {
      dayViews: arr.map(n => n.dayViews).reduce((acc, num) => +acc + +num, 0),
      hourViews: arr.map(n => n.hourViews).reduce((acc, num) => +acc + +num, 0),
      totalRevenue: arr.map(n => n.totalRevenue).reduce((acc, num) => +acc + +num, 0),
      totalAdRevenue: arr.map(n => n.totalAdRevenue).reduce((acc, num) => +acc + +num, 0),
      totalRedRevenue: arr.map(n => n.totalRedRevenue).reduce((acc, num) => +acc + +num, 0),
    }
  }

  getPlatforms() {
    this.attachSubscriptions(
      this.companyService.getGroupPlatforms().subscribe(resp => {
        this.platforms = resp;
        this.platforms$.next(this.platforms.slice());
      })
    )
  }

  onRouteChange() {
    this.attachSubscriptions(
      this.activatedRoute.queryParams.pipe(
        tap((val) => {
          console.log("onRouteChange", val)
 
          this.getCurrencyList();
          if (this.company_id != this.activatedRoute.snapshot.queryParamMap.get('company_id')) {
            this.getLangData(this.activatedRoute.snapshot.queryParamMap.get('company_id'));
          }
          this.company_id = this.activatedRoute.snapshot.queryParamMap.get('company_id');
          this.filter.patchValue({
            o__dayView: !!this.activatedRoute.snapshot.queryParamMap.get('o__dayView') ? +this.activatedRoute.snapshot.queryParamMap.get('o__dayView') : 1,
            o__hourView: !!this.activatedRoute.snapshot.queryParamMap.get('o__hourView') ? +this.activatedRoute.snapshot.queryParamMap.get('o__hourView') : 1,
            o__realtime: !!this.activatedRoute.snapshot.queryParamMap.get('o__realtime') ? +this.activatedRoute.snapshot.queryParamMap.get('o__realtime') : 2,
            platform_id: !!this.activatedRoute.snapshot.queryParamMap.get('platform_id') ? +this.activatedRoute.snapshot.queryParamMap.get('platform_id') : 0,
          })
        }),
        switchMap((val) => {
          console.log("switchMap", this.company)
          if (this.company == undefined || this.company.id != this.activatedRoute.snapshot.queryParamMap.get('company_id')) {
            return this.companyService.getCompany(this.activatedRoute.snapshot.queryParamMap.get('company_id')).pipe(
              map(x => x[0]),
              tap(x => {
                this.company = x;
                this.companyService.company$.next(x);
                if (!!x && !!x.employees && !!x.employees[0]) {
                  this.company['rate_target'] = x.employees[0].rate_target
                }

              }),
              map(() => val)
            )
          } else {
            return of(val)
          }
        })
      ).subscribe(params => {
        console.log("route STREAM", params);

        this.profiles = [];
        this.page = 1;

        if (this.outletDashSub) {
          this.outletDashSub.unsubscribe()
        }
        this.totalValues = {
          dayTotal: 0,
          hourTotal: 0,
          totalRevenue: 0,
          totalAdRevenue: 0,
          totalRedRevenue: 0
        }
        this.outletDashSub = this.taskService.getOutletsDashStream().pipe(
          concatMap(topsInPage => {
            return concat(...topsInPage.map(topInPage => this.neededChartData(topInPage))).pipe(last(),map(x => topsInPage))
          }),
        ).subscribe(resp => console.log("-----getOutletsDashStream-----",resp));
        this.getProfiles(this.company_id, this.page);
        if (this.count == 1) {
          this.getBookmarks()
        }

        this.count++;
      })
    )
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  getUser() {
    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        this.user = resp;
      })
    )
  }

  getLangData(id) {
    this.attachSubscriptions(
      this.languageService.getLangData().pipe(
        tap(resp => {
          this.activeLang = resp.active;
          this._adapter.setLocale(resp.active);
          this.currMonthName = moment().format('MMMM');
        })
      ).subscribe(resp => {
        console.log("getOperations", resp)
      })
    )
  }

  checkIsMobile() {
    if (window.innerWidth <= 769) {
      this.is_mobile = true;
      this.view = [88,31]
    } else {
      this.is_mobile = false;
      this.view = [197, 68]
    }
    this.onResize();
  }

  onResize() {
    this.attachSubscriptions(
      fromEvent(window, "resize").pipe(
        map(() => window.innerWidth)
      ).subscribe((wWidth) => {
          if (wWidth <= 769) {
            this.is_mobile = true;
            this.view = [88,31]
          } else {
            this.is_mobile = false;
            this.view = [197, 68]
          }
        }
      )
    )
  }

  ngAfterViewInit(): void {
    this.cd.detectChanges();
  }

  getProfiles(company_id, page) {
    let filterData:any = {}
    if (!!this.filter.value.platform_id) {
      filterData.platform_id = this.filter.value.platform_id
    }
    this.isLoad = true;
    this.attachSubscriptions(
      this.companyService.getFilteredProfiles(company_id, page, filterData).pipe(
        tap(el => {
          this.pagination = {
            'pageCount': el.headers.get('x-pagination-page-count'),
            'perPage': el.headers.get('x-pagination-per-page'),
            'totalCount': el.headers.get('x-pagination-total-count'),
            'currentPage': el.headers.get('x-pagination-current-page'),
          }
        }),
        map(el => el.body),
        tap(x => x.map(b => {
          b.hourViews = 0;
          b.dayViews = 0;
        })),
        tap(arr => this.taskService.outletsDash$.next(arr))
      ).subscribe(resp => {
        console.log("getProfiles", resp)
        this.profiles.push(...resp)
        // this.profiles$.next(this.profiles.slice())
        this.page = this.page + 1;
        this.isLoad = false;
      })
    )
  }

  selectChange(e) {
    this.filterOutlets();
  }

  filterReset() {
    this.filter.patchValue({
      count: '',
      q: '',
      content_status_id: '',
      created_employee_id: '',
      content_type_id: '',
      is_content_views_count_update: '',
      is_bravourl_content_views_count_update: '',
      channel_id: '',
      group_id: '',
      platform_id: '',
      created_at_from : '',
      created_at_to : '',
      content_published_at_from : '',
      content_published_at_to : '',
      order_by: 'analytics_views_count_desc',
    })
    this.router.navigate(['/outlets-dashboard'], { queryParams: {company_id: this.company_id, order_by: "analytics_views_count_desc"}});
  }

  filterOutlets() {
    
    let filterData = {...this.filter.value};

    // if (!!filterData.content_published_at_from) {
    //   filterData.content_published_at_from = moment(filterData.content_published_at_from._d).format("X");
    // }
    // if (!!filterData.content_published_at_to) {
    //   filterData.content_published_at_to = moment(filterData.content_published_at_to._d).format("X");
    // }
    // if (!!filterData.analytics_views_count_from) {
    //   filterData.analytics_views_count_from = moment(filterData.analytics_views_count_from._d).format("X");
    // }
    // if (!!filterData.analytics_views_count_to) {
    //   filterData.analytics_views_count_to = moment(filterData.analytics_views_count_to._d).format("X");
    // }
    // if (!!filterData.created_at_from) {
    //   filterData.created_at_from = moment(filterData.created_at_from._d).format("X");
    // }
    // if (!!filterData.created_at_to) {
    //   filterData.created_at_to = moment(filterData.created_at_to._d).format("X");
    // }
    
    let params = {
      company_id: this.company_id
    };

    Object.keys(filterData).forEach(element => {
      if (String(filterData[element]) != '') {
        params[element] = filterData[element]
      }
    });

    this.router.navigate(['/outlets-dashboard'], { queryParams: params });
  }

  onDown(e) {
    console.log("SCROLL DOWN", e);
    if (this.pagination['pageCount'] >= this.page) {
      console.log("getNextProfiles")
      this.getProfiles(this.company_id, this.page);
    }
  }

  onDownBook(e, bookmark) {
    console.log("SCROLL DOWN", e);
    if (bookmark.pagination['pageCount'] >= bookmark.page) {
      console.log("getNextProfiles")
      this.getBookmarkProfiles(bookmark);
    }
  }

  deleteBookmark(bookmark, k) {
    let deleteAlert =  this.bottomSheet.open(DeleteAlertComponent, {
      hasBackdrop: true,
      backdropClass: 'bottom-sheed-backdrop',
      data: {
        targetVal: {},
        target: marker("bookmark")
      }
    });

    deleteAlert.afterDismissed().subscribe( data => {
      if (data && data.message == 'no') {
        console.log("no");
        return
      } else if (data && data.message == 'yes') {
        this.attachSubscriptions(
          this.companyService.deleteBookmark(bookmark.id).subscribe(resp => {
            this.bookmarks.splice(k, 1)
          })
        )
      }
    });

  }

  onPartnerImgError(event){
    event.target.src = this.imgRoute+'/assets/img/partner.png'
  }

  getPlatformById(id) {
    if (!this.platforms || this.platforms.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.platforms.find(el => el.id == id)
  }

  getChannelById(id) {
    if (!this.profiles || this.profiles.filter(el => el.id == id).length == 0) {
      return false;
    }
    return this.profiles.find(el => el.id == id)
  }

  getCurrencyList() {
    this.attachSubscriptions(
      this.globalDataService.getCurrencies().subscribe(resp => {
        console.log("getCurrencyList", resp)
        let currencyData = resp.slice()
        currencyData.map(x => x.summary = 0)
        this.currencyes = currencyData;

      })
    )
  }
  
  ngOnDestroy(): void {
    if (this.outletDashSub) {
      this.outletDashSub.unsubscribe()
      this.totalValues = {
        dayTotal: 0,
        hourTotal: 0,
        totalRevenue: 0,
        totalAdRevenue: 0,
        totalRedRevenue: 0
      }
    }
    this.clearSubscriptions()
  }

}
