<h2 mat-dialog-title class="dialog_title">
    <span (click)="log()">
        {{ "View scheme" | translate }}
    </span>
    <button mat-icon-button class="ml-1" (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography">
    <div class="container">
        <div class="box_wrp d-f-c" style="min-height: 40px;">
            <mat-checkbox color="primary" [(ngModel)]="result.is_one">
                {{ "Job" | translate }}

                <span class="select_span" (click)="$event.preventDefault(); jobSelect.open()">
                    {{!!result.jobType ? getTypeById(result.jobType)?.name : ("Job type" | translate)}}
                    <mat-form-field appearance="legacy" class="hide-form-field">
                        <!-- <mat-label>{{"Job type" | translate}}</mat-label> -->
                        <mat-select #jobSelect [disableOptionCentering]="true" selectDir [(ngModel)]="result.jobType">
                            <mat-option>
                                <ngx-mat-select-search 
                                placeholderLabel="{{ 'Search' | translate }}" 
                                noEntriesFoundLabel="{{ 'no matching job type found' | translate }}"
                                [formControl]="jobTypesControl"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let fil of jobTypes$ | async" [value]="fil.id">
                                {{ fil.name | translate }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </span>

                {{"statuses by tag category" | translate}}

                <span class="select_span" (click)="$event.preventDefault(); tagsCategorySelect.open()">
                    {{!!result.tagCategory ? getParamById(result.tagCategory)?.name : ("select tag category" | translate)}}
                    <mat-form-field appearance="standard" class="hide-form-field">
                        <mat-select #tagsCategorySelect [disableOptionCentering]="true" selectDir [multiple]="false" [(ngModel)]="result.tagCategory" (selectionChange)="changeTagCategory($event)">
                            <mat-option>
                                <ngx-mat-select-search 
                                placeholderLabel="{{ 'Search' | translate }}" 
                                noEntriesFoundLabel="{{ 'no matching tag category found' | translate }}"
                                [formControl]="paramControl"></ngx-mat-select-search>
                            </mat-option>
                            <ng-container *ngFor="let item of parameters$ | async">
                                <mat-option *ngIf="!!item.id" [value]="item.id">
                                    <span>{{ item.name }}</span>
                                </mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                </span>

                <span class="select_span tag_span" (click)="$event.preventDefault(); tagsSelect.open()" *ngIf="!!result.tagCategory">
                    ({{ result.tags.length + " " + ("tag(s)" | translate)}})
                    <mat-form-field appearance="standard" class="hide-form-field">
                        <mat-select #tagsSelect [disableOptionCentering]="true" selectDir [multiple]="true" [(ngModel)]="result.tags">
                            <!-- <mat-option>
                                <ngx-mat-select-search 
                                placeholderLabel="{{ 'Search' | translate }}" 
                                noEntriesFoundLabel="{{ 'no matching tag found' | translate }}"
                                [formControl]="allValuesControl"></ngx-mat-select-search>
                            </mat-option> -->
                            <ng-container *ngFor="let item of result.categoryTags">
                                <mat-option *ngIf="!!item.id" [value]="item.id">
                                    <span>{{ item.value }}</span>
                                </mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                </span>

                {{"columns" | translate}}
            </mat-checkbox>
        </div>
        <div class="box_wrp d-f-c" style="min-height: 40px;">
            <mat-checkbox color="primary" [(ngModel)]="result.is_two">
                {{ "Additional data in column: Views from channels with same tag from" | translate }}
                <span class="select_span" (click)="$event.preventDefault(); addTagsCategorySelect.open()">
                    {{!!result.tagCategory ? getParamById(result.tagCategory)?.name : ("select tag category" | translate)}}
                    <mat-form-field appearance="standard" class="hide-form-field">
                        <mat-select #addTagsCategorySelect [disableOptionCentering]="true" selectDir [multiple]="false" [(ngModel)]="result.tagCategory" (selectionChange)="changeTagCategory($event)">
                            <mat-option>
                                <ngx-mat-select-search 
                                placeholderLabel="{{ 'Search' | translate }}" 
                                noEntriesFoundLabel="{{ 'no matching tag category found' | translate }}"
                                [formControl]="paramControl"></ngx-mat-select-search>
                            </mat-option>
                            <ng-container *ngFor="let item of parameters$ | async">
                                <mat-option *ngIf="!!item.id" [value]="item.id">
                                    <span>{{ item.name }}</span>
                                </mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                </span>
                <span class="select_span tag_span" (click)="$event.preventDefault(); addTagsSelect.open()" *ngIf="!!result.tagCategory">
                    ({{ result.tags.length + " " + ("tag(s)" | translate)}})
                    <mat-form-field appearance="standard" class="hide-form-field">
                        <mat-select #addTagsSelect [disableOptionCentering]="true" selectDir [multiple]="true" [(ngModel)]="result.tags">
                            <!-- <mat-option>
                                <ngx-mat-select-search 
                                placeholderLabel="{{ 'Search' | translate }}" 
                                noEntriesFoundLabel="{{ 'no matching tag found' | translate }}"
                                [formControl]="allValuesControl"></ngx-mat-select-search>
                            </mat-option> -->
                            <ng-container *ngFor="let item of result.categoryTags">
                                <mat-option *ngIf="!!item.id" [value]="item.id">
                                    <span>{{ item.value }}</span>
                                </mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                </span>

                {{"and from" | translate}}
                <span class="select_span" (click)="$event.preventDefault(); platformSelect.open()">
                    {{!!result.platform_id ? getPlatformById(result.platform_id)?.name : ("select platform" | translate)}}
                    <mat-form-field appearance="legacy" class="hide-form-field">
                        <!-- <mat-label>{{"Job type" | translate}}</mat-label> -->
                        <mat-select #platformSelect [disableOptionCentering]="true" selectDir [(ngModel)]="result.platform_id">
                            <mat-option *ngFor="let fil of platforms" [value]="fil.id">
                                {{ fil.name | translate }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </span>
            </mat-checkbox>
        </div>
        
        <ng-container *ngIf="result.is_one">
            <div class="box_wrp d-f-c" style="min-height: 40px;">
                <mat-checkbox color="primary" [(ngModel)]="result.ccol1">
                    {{ "Consolidation column 1: list tags from jobs with" | translate }}

                    <span class="select_span" (click)="$event.preventDefault(); statusSelect.open()">
                        <ng-container *ngIf="!!result.jobStatus && result.jobStatus.length; else elseTemplateStatusFirst">
                            <ng-container *ngFor="let id of result.jobStatus; let last = last;">
                                {{getStatusById(id)?.name}}<ng-container *ngIf="!last">, </ng-container>
                            </ng-container>
                        </ng-container>
                        <ng-template #elseTemplateStatusFirst>
                            {{"Job status" | translate}}
                        </ng-template>
                        <mat-form-field appearance="legacy" class="hide-form-field">
                            <!-- <mat-label>{{"Job type" | translate}}</mat-label> -->
                            <mat-select [multiple]="true" #statusSelect [disableOptionCentering]="true" selectDir [(ngModel)]="result.jobStatus">
                                <mat-option *ngFor="let fil of operationStatuses" [value]="fil.id">
                                    {{ fil.name | translate }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </span>
                </mat-checkbox>
            </div>
            <div class="box_wrp d-f-c" style="min-height: 40px;">
                <mat-checkbox color="primary" [(ngModel)]="result.ccol2">
                    {{ "Consolidation column 2: total views from the row from" | translate }}
                    <span class="select_span" (click)="$event.preventDefault(); secondPlatformSelect.open()">
                        {{!!result.platform_id ? getPlatformById(result.platform_id)?.name : ("select platform" | translate)}}
                        <mat-form-field appearance="legacy" class="hide-form-field">
                            <!-- <mat-label>{{"Job type" | translate}}</mat-label> -->
                            <mat-select #secondPlatformSelect [disableOptionCentering]="true" selectDir [(ngModel)]="result.platform_id">
                                <mat-option *ngFor="let fil of platforms" [value]="fil.id">
                                    {{ fil.name | translate }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </span>
                </mat-checkbox>
            </div>
            <div class="box_wrp d-f-c" style="min-height: 40px;">
                <mat-checkbox color="primary" [(ngModel)]="result.ccol3">
                    {{ "Consolidation column 3: Reflect" | translate }}
                    
                    <input style="width: 50px;" type="text" [(ngModel)]="result.refl">
                    
                    {{ "if At least one job with" | translate }}

                    <span class="select_span" (click)="$event.preventDefault(); reflectStatusSelect.open()">
                        <ng-container *ngIf="!!result.reflectJobStatus && !!result.reflectJobStatus.length; else elseTemplateStatus">
                            <ng-container *ngFor="let id of result.reflectJobStatus; let last = last;">
                                {{getStatusById(id)?.name}}<ng-container *ngIf="!last">, </ng-container>
                            </ng-container>
                        </ng-container>
                        <ng-template #elseTemplateStatus>
                            {{"Job status" | translate}}
                        </ng-template>
                        <mat-form-field appearance="legacy" class="hide-form-field">
                            <!-- <mat-label>{{"Job type" | translate}}</mat-label> -->
                            <mat-select [multiple]="true" #reflectStatusSelect [disableOptionCentering]="true" selectDir [(ngModel)]="result.reflectJobStatus">
                                <mat-option *ngFor="let fil of operationStatuses" [value]="fil.id">
                                    {{ fil.name | translate }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </span>
                </mat-checkbox>
            </div>
            
        </ng-container>
        
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button (click)="close()">
        {{ "Close" | translate }}
    </button>
    <button class="ml-1" (click)="default()" mat-raised-button color="warn">
        {{ "Default" | translate }}
    </button>
    <button class="ml-1" (click)="submitForm()" mat-raised-button color="primary">
        {{ "Ok" | translate }}
    </button>
    <button class="ml-1" mat-icon-button [matMenuTriggerFor]="bookmarkMenu" #menuTrigger="matMenuTrigger">
        <mat-icon>bookmarks</mat-icon>
    </button>
    <mat-menu #bookmarkMenu="matMenu" xPosition="before" yPosition="above">
        <button class="scheme_item" *ngFor="let item of bookmarks" (click)="selectPreset(item)" mat-menu-item [ngClass]="{'active': selectedPreset && selectedPreset.id == item.id}">
            <span>{{ item.name }}</span>
            <div class="d-f-c show_scheme_hover" *ngIf="is_scheme_editing">
                <mat-icon class="small_scheme_icon green" (click)="editPreset($event, menuTrigger, item, task)">edit</mat-icon>
                <mat-icon class="small_scheme_icon red" (click)="deletePreset($event, menuTrigger, item)">delete</mat-icon>
            </div>
        </button>
        <button class="scheme_item scheme_item_create" mat-menu-item (click)="createNewPreset($event)">
            <mat-icon>add</mat-icon>
            <span>{{ "Create new preset" | translate }}</span>
            <mat-icon style="margin-left: 50px !important; margin-right: 0 !important;" *ngIf="bookmarks && bookmarks.length" [ngStyle]="{'color': is_scheme_editing ? '#3f51b5' : '#686868'}" (click)="toggleSchemeEditing($event)">settings</mat-icon>
        </button>
    </mat-menu>
</mat-dialog-actions>
