<h2 mat-dialog-title class="dialog_title">
    <span>
        {{ "AI extra info" | translate }}
    </span>
    <button mat-button (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
</h2>
<mat-dialog-content class="mat-typography extra_info" dirEnter (dirSubmit)="onSubmit()">
    <div class="container form_wrapper" *ngIf="form">
        <form [formGroup]="form">
            <mat-form-field appearance="outline" class="full_width_form">
                <mat-label>{{ 'AI info' | translate }}</mat-label>
                <textarea 
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="3"
                cdkAutosizeMaxRows="12" formControlName="text" matInput placeholder="{{ 'AI info' | translate }}"></textarea>
            </mat-form-field>
        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button (click)="close()">
        {{ "Back" | translate }}
    </button>
    <button class="ml-1" (click)="onSubmit()" mat-raised-button cdkFocusInitial color="primary">
        {{ "Save" | translate }}
    </button>
</mat-dialog-actions>
