<div class="assistant_page">
    <div class="container assistant">
        <div class="ass_nav">
            <div class="title">{{"Assistant" | translate}}</div>
            <div class="text">{{"We will guide you through every steps." | translate}}</div>

            <div class="steps_nav">
                <div (click)="goToIndex(1)" class="step_nav cp" [ngClass]="{'done': index > 1, 'active': index == 1}">
                    <svg *ngIf="index == 1" style="width: 18px; height: 18px;" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.9" y="0.9" width="16.2" height="16.2" rx="8.1" fill="#E5ECFA"/>
                        <rect x="0.9" y="0.9" width="16.2" height="16.2" rx="8.1" stroke="#467EE5" stroke-width="1.8"/>
                    </svg>
                    <svg *ngIf="index != 1" style="width: 18px; height: 18px;" width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="1.5" y="1.5" width="16" height="16" rx="8" fill="#467EE5"/>
                        <rect x="1.5" y="1.5" width="16" height="16" rx="8" stroke="#467EE5" stroke-width="2"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.23672 12.7195L13.9166 7.10356C14.0934 6.89143 14.0647 6.57614 13.8526 6.39936L13.0848 5.75952C12.8727 5.58274 12.5574 5.61139 12.3806 5.8235L8.40072 10.5985L6.52927 8.72617C6.33402 8.53083 6.01738 8.53079 5.82209 8.72609L5.11527 9.4329C4.92001 9.62816 4.92001 9.94474 5.11527 10.14L7.76172 12.7865L8.14861 13.1733C8.3565 13.3812 8.698 13.3658 8.88624 13.1399L9.23672 12.7195Z" fill="#FAFAFA"/>
                    </svg>               
                    <span>{{"Select Platform" | translate}}</span>
                </div>
                <div (click)="goToIndex(2)" class="step_nav" [ngClass]="{'done': index > 2, 'active': index == 2, 'cp': index >= 2}">         
                    <svg *ngIf="index == 2" style="width: 18px; height: 18px;" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.9" y="0.9" width="16.2" height="16.2" rx="8.1" fill="#E5ECFA"/>
                        <rect x="0.9" y="0.9" width="16.2" height="16.2" rx="8.1" stroke="#467EE5" stroke-width="1.8"/>
                    </svg>
                    <svg *ngIf="index > 2" style="width: 18px; height: 18px;" width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="1.5" y="1.5" width="16" height="16" rx="8" fill="#467EE5"/>
                        <rect x="1.5" y="1.5" width="16" height="16" rx="8" stroke="#467EE5" stroke-width="2"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.23672 12.7195L13.9166 7.10356C14.0934 6.89143 14.0647 6.57614 13.8526 6.39936L13.0848 5.75952C12.8727 5.58274 12.5574 5.61139 12.3806 5.8235L8.40072 10.5985L6.52927 8.72617C6.33402 8.53083 6.01738 8.53079 5.82209 8.72609L5.11527 9.4329C4.92001 9.62816 4.92001 9.94474 5.11527 10.14L7.76172 12.7865L8.14861 13.1733C8.3565 13.3812 8.698 13.3658 8.88624 13.1399L9.23672 12.7195Z" fill="#FAFAFA"/>
                    </svg>  
                    <svg *ngIf="index < 2" style="width: 18px; height: 18px;" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.9" y="0.9" width="16.2" height="16.2" rx="8.1" stroke="#BDBDBD" stroke-width="1.8"/>
                    </svg> 
                    <span>{{"Authorize" | translate}}</span>
                </div>
                <div (click)="goToIndex(3)" class="step_nav" [ngClass]="{'done': index > 3, 'active': index == 3, 'cp': index >= 3}">
                    <svg *ngIf="index == 3" style="width: 18px; height: 18px;" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.9" y="0.9" width="16.2" height="16.2" rx="8.1" fill="#E5ECFA"/>
                        <rect x="0.9" y="0.9" width="16.2" height="16.2" rx="8.1" stroke="#467EE5" stroke-width="1.8"/>
                    </svg>
                    <svg *ngIf="index > 3" style="width: 18px; height: 18px;" width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="1.5" y="1.5" width="16" height="16" rx="8" fill="#467EE5"/>
                        <rect x="1.5" y="1.5" width="16" height="16" rx="8" stroke="#467EE5" stroke-width="2"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.23672 12.7195L13.9166 7.10356C14.0934 6.89143 14.0647 6.57614 13.8526 6.39936L13.0848 5.75952C12.8727 5.58274 12.5574 5.61139 12.3806 5.8235L8.40072 10.5985L6.52927 8.72617C6.33402 8.53083 6.01738 8.53079 5.82209 8.72609L5.11527 9.4329C4.92001 9.62816 4.92001 9.94474 5.11527 10.14L7.76172 12.7865L8.14861 13.1733C8.3565 13.3812 8.698 13.3658 8.88624 13.1399L9.23672 12.7195Z" fill="#FAFAFA"/>
                    </svg>  
                    <svg *ngIf="index < 3" style="width: 18px; height: 18px;" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.9" y="0.9" width="16.2" height="16.2" rx="8.1" stroke="#BDBDBD" stroke-width="1.8"/>
                    </svg>
                    <span>{{(outlet_id == 'new' ? "Create outlet" : "Connect to Outlet") | translate}}</span>
                </div>
                <div (click)="goToIndex(4)" class="step_nav" [ngClass]="{'done': index > 4, 'active': index == 4, 'cp': index >= 4}">
                    <svg *ngIf="index == 4" style="width: 18px; height: 18px;" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.9" y="0.9" width="16.2" height="16.2" rx="8.1" fill="#E5ECFA"/>
                        <rect x="0.9" y="0.9" width="16.2" height="16.2" rx="8.1" stroke="#467EE5" stroke-width="1.8"/>
                    </svg>
                    <svg *ngIf="index > 4" style="width: 18px; height: 18px;" width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="1.5" y="1.5" width="16" height="16" rx="8" fill="#467EE5"/>
                        <rect x="1.5" y="1.5" width="16" height="16" rx="8" stroke="#467EE5" stroke-width="2"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.23672 12.7195L13.9166 7.10356C14.0934 6.89143 14.0647 6.57614 13.8526 6.39936L13.0848 5.75952C12.8727 5.58274 12.5574 5.61139 12.3806 5.8235L8.40072 10.5985L6.52927 8.72617C6.33402 8.53083 6.01738 8.53079 5.82209 8.72609L5.11527 9.4329C4.92001 9.62816 4.92001 9.94474 5.11527 10.14L7.76172 12.7865L8.14861 13.1733C8.3565 13.3812 8.698 13.3658 8.88624 13.1399L9.23672 12.7195Z" fill="#FAFAFA"/>
                    </svg>  
                    <svg *ngIf="index < 4" style="width: 18px; height: 18px;" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.9" y="0.9" width="16.2" height="16.2" rx="8.1" stroke="#BDBDBD" stroke-width="1.8"/>
                    </svg>
                    <span>{{"Connect to Group" | translate}}</span>
                </div>
                <div (click)="goToIndex(5)" class="step_nav" [ngClass]="{'done': index > 5, 'active': index == 5, 'cp': index >= 5}">
                    <svg *ngIf="index == 5" style="width: 18px; height: 18px;" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.9" y="0.9" width="16.2" height="16.2" rx="8.1" fill="#E5ECFA"/>
                        <rect x="0.9" y="0.9" width="16.2" height="16.2" rx="8.1" stroke="#467EE5" stroke-width="1.8"/>
                    </svg>
                    <svg *ngIf="index > 5" style="width: 18px; height: 18px;" width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="1.5" y="1.5" width="16" height="16" rx="8" fill="#467EE5"/>
                        <rect x="1.5" y="1.5" width="16" height="16" rx="8" stroke="#467EE5" stroke-width="2"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.23672 12.7195L13.9166 7.10356C14.0934 6.89143 14.0647 6.57614 13.8526 6.39936L13.0848 5.75952C12.8727 5.58274 12.5574 5.61139 12.3806 5.8235L8.40072 10.5985L6.52927 8.72617C6.33402 8.53083 6.01738 8.53079 5.82209 8.72609L5.11527 9.4329C4.92001 9.62816 4.92001 9.94474 5.11527 10.14L7.76172 12.7865L8.14861 13.1733C8.3565 13.3812 8.698 13.3658 8.88624 13.1399L9.23672 12.7195Z" fill="#FAFAFA"/>
                    </svg>  
                    <svg *ngIf="index < 5" style="width: 18px; height: 18px;" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.9" y="0.9" width="16.2" height="16.2" rx="8.1" stroke="#BDBDBD" stroke-width="1.8"/>
                    </svg>
                    <span>{{"Language version" | translate}}</span>
                </div>
                <div *ngIf="sftps && !!sftps?.length" (click)="goToIndex(6)" class="step_nav" [ngClass]="{'done': index > 6, 'active': index == 6, 'cp': index >= 6}">
                    <svg *ngIf="index == 6" style="width: 18px; height: 18px;" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.9" y="0.9" width="16.2" height="16.2" rx="8.1" fill="#E5ECFA"/>
                        <rect x="0.9" y="0.9" width="16.2" height="16.2" rx="8.1" stroke="#467EE5" stroke-width="1.8"/>
                    </svg>
                    <svg *ngIf="index > 6" style="width: 18px; height: 18px;" width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="1.5" y="1.5" width="16" height="16" rx="8" fill="#467EE5"/>
                        <rect x="1.5" y="1.5" width="16" height="16" rx="8" stroke="#467EE5" stroke-width="2"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.23672 12.7195L13.9166 7.10356C14.0934 6.89143 14.0647 6.57614 13.8526 6.39936L13.0848 5.75952C12.8727 5.58274 12.5574 5.61139 12.3806 5.8235L8.40072 10.5985L6.52927 8.72617C6.33402 8.53083 6.01738 8.53079 5.82209 8.72609L5.11527 9.4329C4.92001 9.62816 4.92001 9.94474 5.11527 10.14L7.76172 12.7865L8.14861 13.1733C8.3565 13.3812 8.698 13.3658 8.88624 13.1399L9.23672 12.7195Z" fill="#FAFAFA"/>
                    </svg>  
                    <svg *ngIf="index < 6" style="width: 18px; height: 18px;" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.9" y="0.9" width="16.2" height="16.2" rx="8.1" stroke="#BDBDBD" stroke-width="1.8"/>
                    </svg> 
                    <span>{{"SFTP" | translate}}</span>
                </div>
                <div (click)="goToIndex(7)" class="step_nav" [ngClass]="{'done': index > 7, 'active': index == 7}">
                    <svg *ngIf="index == 7" style="width: 18px; height: 18px;" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.9" y="0.9" width="16.2" height="16.2" rx="8.1" fill="#E5ECFA"/>
                        <rect x="0.9" y="0.9" width="16.2" height="16.2" rx="8.1" stroke="#467EE5" stroke-width="1.8"/>
                    </svg>
                    <svg *ngIf="index > 7" style="width: 18px; height: 18px;" width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="1.5" y="1.5" width="16" height="16" rx="8" fill="#467EE5"/>
                        <rect x="1.5" y="1.5" width="16" height="16" rx="8" stroke="#467EE5" stroke-width="2"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.23672 12.7195L13.9166 7.10356C14.0934 6.89143 14.0647 6.57614 13.8526 6.39936L13.0848 5.75952C12.8727 5.58274 12.5574 5.61139 12.3806 5.8235L8.40072 10.5985L6.52927 8.72617C6.33402 8.53083 6.01738 8.53079 5.82209 8.72609L5.11527 9.4329C4.92001 9.62816 4.92001 9.94474 5.11527 10.14L7.76172 12.7865L8.14861 13.1733C8.3565 13.3812 8.698 13.3658 8.88624 13.1399L9.23672 12.7195Z" fill="#FAFAFA"/>
                    </svg>  
                    <svg *ngIf="index < 7" style="width: 18px; height: 18px;" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.9" y="0.9" width="16.2" height="16.2" rx="8.1" stroke="#BDBDBD" stroke-width="1.8"/>
                    </svg>
                    <span>{{"Schedule" | translate}}</span>
                </div>
            </div>
        </div>
        <div class="ass_right" [ngClass]="{'not_scrolled': [3,4,5].includes(index)}" [appDeviceClass]="'custom-scroll custom-scroll-t'">
            <div class="ass_body">
                <ng-container [ngSwitch]="index">
                    <ng-container *ngSwitchCase="1">
                        <div class="title">{{"Choose a platform to get started." | translate}}</div>
                        <div class="items">
                            <ng-container *ngFor="let item of platforms">
                                <div class="item" (click)="platform_id = item.id" [ngClass]="{'active': platform_id == item.id}" *ngIf="item.id <= 4">
                                    <app-platform [platform_id]="item?.id"></app-platform>
                                    <span>
                                        {{item?.name}}
                                    </span>
                                </div>
                            </ng-container>
                        </div>
                        <div class="btns_line">
                            <div></div>
                            <div class="d-f-c">
                                <button *ngIf="!!platform_id" mat-raised-button (click)="selectPlatform(platform_id, false, true)">
                                    {{"Skip" | translate}}
                                </button>
                                <button class="ml-1 not_green" *ngIf="!!platform_id" color="primary" mat-raised-button (click)="selectPlatform(platform_id)">
                                    {{"Use existing authorization" | translate}}
                                </button>
                                <button class="ml-1" *ngIf="!!platform_id" mat-raised-button color="primary" (click)="selectPlatform(platform_id, true)">
                                    {{"Authorize new channel/account" | translate}}
                                </button>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="2">
                        <div class="title">{{"Your channel has been authorized." | translate}}</div>
                        <div class="items">
                            <ng-container *ngFor="let item of expChannels">
                                <!-- <div class="item" (click)="selectPlatform(item.id)" *ngIf="item.id <= 4">
                                    <app-platform [platform_id]="item?.id"></app-platform>
                                    <span>
                                        {{item?.name}}
                                    </span>
                                </div> -->
                                <div class="item" (click)="channel_id = item.id" [ngClass]="{'active': channel_id == item.id}" style="justify-content: space-between; width: 100%;">
                                    <app-outlet-line [channel]="item" [company_id]="company_id"></app-outlet-line>
                                    
                                    <a *ngIf="item.computedUrl && item.computedUrl != 'https://www.youtube.com/'" target="_blank" (click)="$event.stopPropagation()" [href]="item.computedUrl">
                                        <mat-icon>open_in_new</mat-icon>
                                    </a>
                                </div>
                            </ng-container>
                        </div>
                        <div class="btns_line">
                            <div></div>
                            <div class="d-f-c">
                                <button mat-icon-button (click)="getChannelAccountsChannels()">
                                    <mat-icon [ngClass]="{'rotateble': isRefreshedChannels}">refresh</mat-icon>
                                </button>
                                <button class="ml-1" *ngIf="!!channel_id" mat-raised-button color="primary" (click)="selectChannel(channel_id)">
                                    {{"Continue" | translate}}
                                </button>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="3">
                        <div class="title">{{(outlet_id == 'new' ? "Create outlet" : "Select outlet to connect with") | translate}} {{getExpChanById(this.channel_id)?.name}}</div>
                        <div class="items with_border" *ngIf="outlet_id != 'new'">
                            <div class="items_title">
                                <span>{{"Outlets" | translate}}</span>

                                <div class="create_btn" (click)="addProfile($event)">
                                    <svg style="width: 10px; height: 10px;" width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.95833 5.71695C9.19831 5.71695 9.39285 5.52241 9.39285 5.28244V4.71783C9.39285 4.47786 9.19831 4.28332 8.95833 4.28332H6.21695V1.54194C6.21695 1.30196 6.02241 1.10742 5.78244 1.10742H5.21783C4.97786 1.10742 4.78332 1.30196 4.78332 1.54194V4.28332H2.04194C1.80196 4.28332 1.60742 4.47786 1.60742 4.71783V5.28244C1.60742 5.52241 1.80196 5.71695 2.04194 5.71695H4.78332V8.45833C4.78332 8.69831 4.97786 8.89285 5.21783 8.89285H5.78244C6.02241 8.89285 6.21695 8.69831 6.21695 8.45833V5.71695H8.95833Z" fill="#467EE5" stroke="#467EE5" stroke-width="0.375"/>
                                    </svg>
                                    {{"Create New" | translate}}
                                </div>
                            </div>
                            <div class="search">
                                <mat-form-field appearance="legacy" class="full_width_form">
                                    <mat-label>{{ 'Search' | translate }}</mat-label>
                                    <input class="search_inp" [formControl]="profilesMoreControl" matInput>
                                    <mat-icon matSuffix>search</mat-icon>
                                </mat-form-field>
                            </div>
                            <div class="items_in" [appDeviceClass]="'custom-scroll custom-scroll-t'">
                                <ng-container *ngFor="let item of profiles$ | async">
                                    <div (click)="outlet_id = item.id" [ngClass]="{'active': outlet_id == item.id}" class="item" style="justify-content: space-between;">
                                        <app-outlet-line [channel]="item" [company_id]="company_id"></app-outlet-line>
                                        <svg [ngClass]="{'not_active_sync': !item?.channelAccount || (item?.channelAccount && !item?.channelAccount?.is_active)}" *ngIf="!!item?.channel_account_id" style="width: 14px; height: 10px;" width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.25 2.5L8.75 5H10.625C10.625 5.99456 10.2299 6.94839 9.52665 7.65165C8.82339 8.35491 7.86956 8.75 6.875 8.75C6.25 8.75 5.64375 8.59375 5.125 8.3125L4.2125 9.225C4.98125 9.7125 5.89375 10 6.875 10C8.20108 10 9.47285 9.47322 10.4105 8.53553C11.3482 7.59785 11.875 6.32608 11.875 5H13.75L11.25 2.5ZM3.125 5C3.125 4.00544 3.52009 3.05161 4.22335 2.34835C4.92661 1.64509 5.88044 1.25 6.875 1.25C7.5 1.25 8.10625 1.40625 8.625 1.6875L9.5375 0.775C8.76875 0.2875 7.85625 0 6.875 0C5.54892 0 4.27715 0.526784 3.33947 1.46447C2.40178 2.40215 1.875 3.67392 1.875 5H0L2.5 7.5L5 5" fill="#1877F2"></path></svg>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                        <form *ngIf="outlet_id == 'new'" [formGroup]="createOutlet" class="form_with_pads form_with_valids">
                            <mat-form-field appearance="standard" class="full_width_form">
                                <mat-label>{{ 'Name' | translate }}</mat-label>
                                <input formControlName="name" matInput placeholder="{{ 'Name' | translate }}">
                            </mat-form-field>

                            <mat-form-field appearance="standard" class="full_width_form">
                                <mat-label>{{ 'AI description' | translate }}</mat-label>
                                <textarea 
                                cdkTextareaAutosize
                                #autosize="cdkTextareaAutosize"
                                cdkAutosizeMinRows="2"
                                cdkAutosizeMaxRows="6" formControlName="ai_description" matInput></textarea>
                                <mat-hint class="char_counter_hint" *ngIf="createOutlet.get('ai_description').value" [ngClass]="{'red': createOutlet.get('ai_description').value.length > 999999999}" align="end">{{createOutlet.get('ai_description').value.length}}</mat-hint>
                            </mat-form-field>
                
                            <mat-form-field appearance="standard" class="full_width_form">
                                <mat-label>{{ 'Link to the platform' | translate }}</mat-label>
                                <input formControlName="url" matInput placeholder="{{ 'Link to the platform' | translate }}">
                            </mat-form-field>
                
                            <ng-container *ngIf="createOutlet.get('publishing_params')">
                                <ng-container formGroupName="publishing_params">
                                    <div class="box_wrp" style="height: 40px;">
                                        <mat-checkbox formControlName="made_for_kids" color="primary">{{"Made for kids" | translate}}</mat-checkbox>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </form>
                        <div class="btns_line">
                            <div></div>
                            <div class="d-f-c">
                                <button *ngIf="!!outlet_id && outlet_id != 'new'" mat-raised-button color="primary" (click)="selectOutlet(outlet_id)">
                                    {{"Continue" | translate}}
                                </button>
                                <button class="d-f-btn" *ngIf="!!outlet_id && outlet_id == 'new' && createOutlet.valid" mat-raised-button color="primary" (click)="submitOutlet()" [disabled]="isSubmitOutlet">
                                    <mat-spinner class="spin_16 mr-1" *ngIf="isSubmitOutlet"></mat-spinner>  
                                    {{"Create" | translate}}
                                </button>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="4">
                        <div class="title">{{"Select group to connect with" | translate}} {{getExpChanById(this.channel_id)?.name}}</div>
                        <div class="items with_border">
                            <div class="items_title">
                                <span>{{"Groups" | translate}}</span>

                                <div class="create_btn" (click)="addGroup($event)">
                                    <svg style="width: 10px; height: 10px;" width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.95833 5.71695C9.19831 5.71695 9.39285 5.52241 9.39285 5.28244V4.71783C9.39285 4.47786 9.19831 4.28332 8.95833 4.28332H6.21695V1.54194C6.21695 1.30196 6.02241 1.10742 5.78244 1.10742H5.21783C4.97786 1.10742 4.78332 1.30196 4.78332 1.54194V4.28332H2.04194C1.80196 4.28332 1.60742 4.47786 1.60742 4.71783V5.28244C1.60742 5.52241 1.80196 5.71695 2.04194 5.71695H4.78332V8.45833C4.78332 8.69831 4.97786 8.89285 5.21783 8.89285H5.78244C6.02241 8.89285 6.21695 8.69831 6.21695 8.45833V5.71695H8.95833Z" fill="#467EE5" stroke="#467EE5" stroke-width="0.375"/>
                                    </svg>
                                    {{"Create New" | translate}}
                                </div>
                            </div>
                            <div class="search">
                                <mat-form-field appearance="legacy" class="full_width_form">
                                    <mat-label>{{ 'Search' | translate }}</mat-label>
                                    <input class="search_inp" [formControl]="groupsControl" matInput>
                                    <mat-icon matSuffix>search</mat-icon>
                                </mat-form-field>
                            </div>
                            <div class="items_in" [appDeviceClass]="'custom-scroll custom-scroll-t'">
                                <ng-container *ngFor="let item of groups$ | async">
                                    <div (click)="setGroup(item.id)" [ngClass]="{'active': group_id.includes(item.id)}" class="item">
                                        <span style="margin: 0; width: 220px;" class="text_one_line">
                                            {{item?.name}}
                                        </span>
                                        <ng-container *ngFor="let ctype of contentTypes">
                                            <span class="type" (click)="setType($event, ctype.id, item.id)" [ngClass]="{'active': group_id.includes(item.id) && content_type_id == ctype.id, 'pulse': group_id.includes(item.id) && !content_type_id && alertTypes}" *ngIf="ctype.id != 203">{{ctype.name}}</span>
                                        </ng-container>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                        <div class="btns_line">
                            <div></div>
                            <div class="d-f-c">
                                <button *ngIf="!!group_id && !!group_id.length" mat-raised-button color="primary" (click)="selectGroup(group_id, content_type_id)">
                                    {{"Continue" | translate}}
                                </button>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="5">
                        <div class="title">{{"Select the language version for" | translate}} {{getExpChanById(this.channel_id)?.name}}</div>
                        <div class="items with_border">
                            <div class="items_title">
                                <span>{{"Languages" | translate}}</span>

                                <!-- <div class="create_btn" (click)="addGroup($event)">
                                    <svg style="width: 10px; height: 10px;" width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.95833 5.71695C9.19831 5.71695 9.39285 5.52241 9.39285 5.28244V4.71783C9.39285 4.47786 9.19831 4.28332 8.95833 4.28332H6.21695V1.54194C6.21695 1.30196 6.02241 1.10742 5.78244 1.10742H5.21783C4.97786 1.10742 4.78332 1.30196 4.78332 1.54194V4.28332H2.04194C1.80196 4.28332 1.60742 4.47786 1.60742 4.71783V5.28244C1.60742 5.52241 1.80196 5.71695 2.04194 5.71695H4.78332V8.45833C4.78332 8.69831 4.97786 8.89285 5.21783 8.89285H5.78244C6.02241 8.89285 6.21695 8.69831 6.21695 8.45833V5.71695H8.95833Z" fill="#467EE5" stroke="#467EE5" stroke-width="0.375"/>
                                    </svg>
                                    {{"Create New" | translate}}
                                </div> -->
                            </div>
                            <div class="search">
                                <mat-form-field appearance="legacy" class="full_width_form">
                                    <mat-label>{{ 'Search' | translate }}</mat-label>
                                    <input class="search_inp" [formControl]="langsControl" matInput>
                                    <mat-icon matSuffix>search</mat-icon>
                                </mat-form-field>
                            </div>
                            <div class="items_in" [appDeviceClass]="'custom-scroll custom-scroll-t'">
                                <ng-container *ngFor="let item of langs$ | async">
                                    <div (click)="lang_id = item.id" [ngClass]="{'active': lang_id == item.id}" class="item">
                                        <span style="margin: 0;" class="text_one_line">
                                            {{item?.value}}
                                        </span>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                        <div class="btns_line">
                            <div></div>
                            <!-- <div class="d-f-c">
                                <button *ngIf="!!lang_id" mat-raised-button color="primary" (click)="selectLang(lang_id)">
                                    {{"Continue" | translate}}
                                </button>
                            </div> -->
                            <div class="d-f-c">
                                <button class="d-f-btn" mat-raised-button color="primary" (click)="finish()" [disabled]="isSubmit">
                                    <mat-spinner class="spin_16 mr-1" *ngIf="isSubmit"></mat-spinner>  
                                    {{"Continue" | translate}}
                                </button>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="6">
                        <div class="title">{{"Select sftp to connect with" | translate}} {{getExpChanById(this.channel_id)?.name}}</div>
                        <div class="items">
                            <ng-container *ngFor="let item of sftps">
                                <div (click)="sftp_id = item.id" [ngClass]="{'active': sftp_id == item.id}" class="item">
                                    <span style="margin: 0;" class="text_one_line">
                                        {{item?.name}}
                                    </span>
                                </div>
                            </ng-container>
                        </div>
                        <div class="btns_line">
                            <div></div>
                            <div class="d-f-c">
                                <button mat-raised-button (click)="selectSftp(false)">
                                    {{"Skip" | translate}}
                                </button>
                                <button class="ml-1" *ngIf="!!sftp_id" mat-raised-button color="primary" (click)="selectSftp(sftp_id)">
                                    {{"Set sftp" | translate}}
                                </button>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="7">
                        <div class="title">{{"Add regular schedule for" | translate}} {{getExpChanById(this.channel_id)?.name}}</div>

           
                        <mat-form-field appearance="outline" class="full_width_form">
                            <mat-label>{{ 'Schedule title' | translate }}</mat-label>
                            <input class="search_inp" [(ngModel)]="schedule_title" matInput>
                        </mat-form-field>

                        <div class="field_title">
                            {{ 'Frequency' | translate }}
                        </div>
                        <div class="freqs">
                            <div class="freq" [ngClass]="{'active': regFormat == 'is_daily'}" (click)="setRegFormat('is_daily')">{{"Daily" | translate}}</div>
                            <div class="freq" [ngClass]="{'active': regFormat == 'is_weekly'}" (click)="setRegFormat('is_weekly')">{{"Weekly" | translate}}</div>
                            <div class="freq" [ngClass]="{'active': regFormat == 'is_monthly'}" (click)="setRegFormat('is_monthly')">{{"Monthly" | translate}}</div>
                        </div>

                        <div class="slots_wrp">
                            <div class="slots">
                                <div class="slot" [ngClass]="{'day_slot': regFormat == 'is_daily'}" *ngFor="let val of slots">
                                    <mat-form-field appearance="outline" class="reg_name">
                                        <mat-label>{{ "Regular publication name" | translate }}</mat-label>
                                        <input matInput placeholder="{{'Regular publication name' | translate}}" type="text" class="value_input" type="text" [(ngModel)]="val.name">
                                    </mat-form-field>

                                    <div class="vert_split"></div>
    
                                    <div class="ngx-timepicker-field-example">
                                        <ngx-timepicker-field [format]="24" [(ngModel)]="val.timeInFormat"></ngx-timepicker-field>
                                    </div>

                                    
                                    <div class="vert_split" *ngIf="regFormat != 'is_daily'"></div>
                        
                                    <mat-form-field appearance="outline" class="full_width_form ml-1" *ngIf="regFormat == 'is_weekly'">
                                        <mat-label>{{ "Day of week" | translate }}</mat-label>
                                        <mat-select #contentTypesContainer [(ngModel)]="val.day_number">
                                            <mat-option *ngFor="let day of weekDays; let m = index;" [value]="m+1">
                                                {{ m+1 }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
    
                                    <mat-form-field appearance="outline" class="full_width_form ml-1" *ngIf="regFormat == 'is_monthly'">
                                        <mat-label>{{ "Day of month" | translate }}</mat-label>
                                        <mat-select #contentTypesContainer [(ngModel)]="val.day_number">
                                            <mat-option *ngFor="let day of monthDays; let m = index;" [value]="m+1">
                                                {{ m+1 }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="create_btn" style="margin-top: 14px;" (click)="addSlot()">
                                <svg style="width: 10px; height: 10px;" width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.95833 5.71695C9.19831 5.71695 9.39285 5.52241 9.39285 5.28244V4.71783C9.39285 4.47786 9.19831 4.28332 8.95833 4.28332H6.21695V1.54194C6.21695 1.30196 6.02241 1.10742 5.78244 1.10742H5.21783C4.97786 1.10742 4.78332 1.30196 4.78332 1.54194V4.28332H2.04194C1.80196 4.28332 1.60742 4.47786 1.60742 4.71783V5.28244C1.60742 5.52241 1.80196 5.71695 2.04194 5.71695H4.78332V8.45833C4.78332 8.69831 4.97786 8.89285 5.21783 8.89285H5.78244C6.02241 8.89285 6.21695 8.69831 6.21695 8.45833V5.71695H8.95833Z" fill="#467EE5" stroke="#467EE5" stroke-width="0.375"/>
                                </svg>
                                {{"Add slot" | translate}}
                            </div>

                            <div class="date">
                                <div class="date_block">
                                    <div class="field_title">{{"Applies starting at" | translate}}</div>
                                    <mat-form-field class="full_width_form" appearance="outline">
                                        <!-- <mat-label>{{ "Applies starting at" | translate }}</mat-label> -->
                                        <input matInput [ngxMatDatetimePicker]="pickerFrom" placeholder="Choose a date" [(ngModel)]="apply_from_time" (dateChange)="addEvent($event)">
                                        <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
                                        <ngx-mat-datetime-picker #pickerFrom [showSpinners]="true" [touchUi]="false" [color]="'primary'">
                                          <ng-template>
                                            <span>OK</span>
                                          </ng-template>
                                        </ngx-mat-datetime-picker>
                                    </mat-form-field>
                                </div>
    
                                <div class="vert_split" style="margin-top: 20px;"></div>

                                <div class="period_block">
                                    <div class="field_title">{{"Effective period" | translate}}</div>
                                    <div class="d-f-c" style="height: 62.82px;">
                                        <div class="period_block_in">
                                            <input class="numberinp" id="period" [(ngModel)]="period" name="period" matInput type="number" (change)="onInput($event, 'period', 1, 1100)">
                                            <label for="period" class="cp">
                                                <b style="margin-right: 15px;">{{"days" | translate}}</b>
                                            </label>
                                        </div>
                                        <span>{{"thru" | translate}} {{ apply_to_time | date: 'MM/dd/yyyy, hh:mm:ss a':'':activeLang}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="btns_line">
                            <div></div>
                            <div class="d-f-c">
                                <button mat-raised-button (click)="goToOutlets()">
                                    {{"Skip" | translate}}
                                </button>
                                <!-- *ngIf="regFormat && schedule_title && !!slots.length" -->
                                <button class="ml-1 d-f-btn" mat-raised-button color="primary" (click)="saveRegular()" [disabled]="isSubmitRegs || !(regFormat && !!schedule_title && !!slots.length && !!apply_from_time && !!apply_to_time)">
                                    <mat-spinner class="spin_16 mr-1" *ngIf="isSubmitRegs"></mat-spinner>  
                                    {{"Save" | translate}}
                                </button>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
       
        </div>
    </div>
</div>