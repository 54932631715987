import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseClass } from 'src/app/shared/models/base-class';

@Component({
  selector: 'app-replacements-settings',
  templateUrl: './replacements-settings.component.html',
  styleUrls: ['./replacements-settings.component.scss']
})
export class ReplacementsSettingsComponent extends BaseClass implements OnInit, OnDestroy  {
  public page: number = 1;
  public pagination: any;
  public tasks: any[] = [];

  public folders: any[] = [
    {
      name: "Root",
      id: -1,
      location: "/"
    },
    {
      name: "Sources",
      id: 0,
      location: "/sources"
    },
    {
      name: "To Approve",
      id: 1,
      location: "/to_approve"
    },
    {
      name: "Ready Files",
      id: 2,
      location: "/ready_files"
    },
    {
      name: "Approved",
      id: 3,
      location: "/approved"
    },
    {
      name: "Project",
      id: 4,
      location: "/project"
    },
    {
      name: "Trash",
      id: 5,
      location: "/trash"
    }
  ]

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ReplacementsSettingsComponent>
  ) { super() }

  ngOnInit(): void {
    console.log("ReplacementsSettingsComponent", this.data)
  }

  find() {
    this.dialogRef.close({event: "find"});
  }
  findAlt() {
    this.dialogRef.close({event: "alt_find"});
  }

  close() {
    this.dialogRef.close({event: "close", data: false});
  }

  setFolder(folder) {
    this.data.replSettings.folder = folder.location
  }

  ngOnDestroy(): void {
    this.clearSubscriptions();
  }
}
