import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { forkJoin, of, ReplaySubject } from 'rxjs';
import { catchError, debounceTime, last, map, switchMap, tap } from 'rxjs/operators';
import { TargetParametersComponent } from 'src/app/components/atTasksDialog/target-parameters/target-parameters.component';
import { GroupCreateComponent } from 'src/app/components/workspace-settings/categorization/groups/group-create/group-create.component';
import { SnackBarItem } from 'src/app/shared/global_components/snack-bar/snack-bar-item';
import { BaseClass } from 'src/app/shared/models/base-class';
import { LayoutService } from 'src/app/shared/services/common/layout.service';
import { StorageManagerService } from 'src/app/shared/services/common/storage-manager.service';
import { AuthService } from 'src/app/shared/services/rest/auth.service';
import { CompanyService } from 'src/app/shared/services/rest/company.service';
import { ParametersService } from 'src/app/shared/services/rest/parameters.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-link-and-create-outlet',
  templateUrl: './link-and-create-outlet.component.html',
  styleUrls: ['./link-and-create-outlet.component.scss']
})
export class LinkAndCreateOutletComponent extends BaseClass implements OnInit, OnDestroy {
  public host: any = environment.host;
  public imgRoute: any = '';
  public user: any = {};
  public user_id: number;
  public platforms: any;
  public contentTypes: any;
  public form: FormGroup;
  public isSubmit: boolean = false;
  public isFormChange: boolean = false;

  public allValues: any;
  public parameters: any;

  public expChannels: any;
  public expChannels$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public expChannelsControl: FormControl = new FormControl();

  public groups: any;
  public groups$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public groupMoreControl: FormControl = new FormControl();
  
  public sftps: any;
  public sftps$: ReplaySubject<any> = new ReplaySubject<any>(1);
  public sftpsMoreControl: FormControl = new FormControl();

  public profilesMoreControl: FormControl = new FormControl();
  public platforms$: ReplaySubject<any> = new ReplaySubject<any>(1);
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<LinkAndCreateOutletComponent>,
    private layoutService: LayoutService,
    private dialog: MatDialog,
    private sm: StorageManagerService,
    private parametersService: ParametersService,
    private auth: AuthService,
    private companyService: CompanyService,
    private fb: FormBuilder,
  ) { super() }

  ngOnInit(): void {
    console.log("LinkAndCreateOutletComponent", this.data)
    this.getCompany();
    this.getImgRoute();
    this.getCsrf();
    this.getUser();
    this.getParameters()
    this.getGroupsCompany()
    this.getContentTypes(this.data.account.platform_id)

    this.expChannels = this.data.account.exChannels;
    this.expChannels.forEach(x => {
      x.avatarFile = x.imageFile
    })
    this.expChannels$.next(this.expChannels.slice())

    this.attachSubscriptions(
      this.profilesMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchProfiles(resp))
    )

    this.attachSubscriptions(
      this.groupMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchGroups(resp))
    )

    this.attachSubscriptions(
      this.sftpsMoreControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchSftp(resp))
    )

    this.attachSubscriptions(
      this.expChannelsControl.valueChanges.pipe(debounceTime(300)).subscribe((resp) => this.onSearchExpChannels(resp))
    )

    this.form = this.fb.group({
      company_id: this.data.company_id,
      external_id: ['', Validators.required],
      platform_id: [this.data.account.platform_id, Validators.required],
      name: ['', Validators.required],
      url: ['', Validators.required],
      group_id: [[]],
      ai_description: '',
      content_type_id: '',
      publishing_params: this.fb.group({
        made_for_kids: false
      })
    })

    this.attachSubscriptions(
      this.form.get('external_id').valueChanges.subscribe((ex_id) => {
        let expChannel = this.expChannels.find(p => p.external_id == ex_id)
        this.form.patchValue({
          name: expChannel.name,
          url: expChannel.computedUrl
        })
      })
    )

    if (this.expChannels.length == 1) {
      this.form.patchValue({
        external_id: this.expChannels[0].external_id,
      })
    } else {
      if (this.data.exChan) {        
        this.form.patchValue({
          external_id: this.data.exChan.external_id,
        })
      }
    }

    if (this.data.account.platform_id == 1) {
      this.form.addControl("youtube_sftp_dropbox_id", this.fb.control(''))
      this.form.get('youtube_sftp_dropbox_id').updateValueAndValidity();
      this.getSftps();
    }

    this.attachSubscriptions(
      this.form.get('group_id').valueChanges.subscribe(() => this.isFormChange = true)
    )

    this.attachSubscriptions(
      this.form.valueChanges.subscribe(() => this.isFormChange = true)
    )

    this.attachSubscriptions(
      this.dialogRef.backdropClick().subscribe(e => {
        e.preventDefault();
        if (this.isFormChange) {
          this.layoutService.openBottomSheet(this.dialogRef);
        } else {
          this.close();
        }
      })
    )

    this.getPlatforms();
  }

  addGroup(e) {
    e.preventDefault();
    e.stopPropagation();
    const dialogRef = this.dialog.open(GroupCreateComponent, {
      disableClose: true,
      data: {
        user: this.user,
        company: this.data.company,
        company_id: this.data.company_id,
      }
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result && result.event == "update") {
          if (result.data) {
            let arr = [...this.form.value.group_id]
            arr.push(result.data.id)
            this.form.patchValue({
              group_id: arr
            })
          }
          this.getGroupsCompany()
        }
      })
    )
  }

  getSftps() {
    this.attachSubscriptions(
      this.companyService.getSftps('1', this.data.company_id, '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.companyService.getSftps(x, this.data.company_id, '200').pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.sftps = [].concat(...res);
        this.sftps$.next(this.sftps.slice());
      })
    )
  }

  getContentTypes(platform_id) {
    this.attachSubscriptions(
      this.companyService.getContentTypes(platform_id).pipe(
        map(el => el.body)
      ).subscribe(resp => {
        this.contentTypes = resp;
      })
    )
  }

  selectType(type) {
    console.log("selectType", type)
    this.form.patchValue({
      content_type_id: type.id
    })
  }
  
  onSearchSftp(resp) {
    if (!this.sftps) {
      return;
    }

    if (!resp) {
      this.sftps$.next(this.sftps.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.sftps$.next(
      this.sftps.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  onSearchGroups(resp) {
    if (!this.groups) {
      return;
    }

    if (!resp) {
      this.groups$.next(this.groups.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }
    // filter the banks
    this.groups$.next(
      this.groups.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  onRemove(tagID, ind) {
    this.parameters[0].activeValues.splice(ind, 1)
  }

  getParamById(id) {
    return this.parameters && this.parameters.find(x => x.id == id)
  }

  getParameters() {
    this.attachSubscriptions(
      this.parametersService.getApiParameters(this.data.company.id).pipe(
        switchMap(res => {
          return this.parametersService.getAllValues('1', this.data.company.id, null, '1').pipe(
            switchMap(el => {
              let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
              let arr = []
              for (let index = 1; index <= pages; index++) {
                arr.push(index)
              }
    
              return forkJoin(arr.map(x => this.parametersService.getAllValues(x, this.data.company.id).pipe(map(u => u.body)))).pipe(
                last(),
                tap(values => {
                  this.allValues = [].concat(...values)
                  console.log("getAllApiParameterValues", this.allValues)
                }),
                map(() => res)
              )
            }),
            map(() => res)
          )
        }),
        tap(res => {
          res.unshift({
            company_id: this.data.company.id,
            created_at: 0,
            id: 0,
            is_new_value: 0,
            name: "All parameters",
            original_id: 0,
          })
          this.parameters = res;
        }),
      ).subscribe(resp => {
        console.log("this.parameters", this.parameters)
        this.parameters.forEach(element => {
          if (element.id == 0) {
            element.values = this.allValues;
          } else {
            element.values = this.allValues.filter(u => u.parameter_id = element.id);
          }
          element.activeValues = []
        });
      })
    )
  }

  openTargetValues() {
    let initData:any = {
      company: this.data.company,
      auto: true,
      no_primary: true,
      parameters: this.parameters
    }

    const dialogRef = this.dialog.open(TargetParametersComponent, {
      backdropClass: ['parameters_modal_backdrop'],
      panelClass: ['without_paddings_modal', 'parameters_modal'],
      data: initData
    });

    this.attachSubscriptions(
      dialogRef.afterClosed().subscribe(result => {
        if (!!result) {
          if (result.event == 'save') {
            this.parameters = result.data
          }
        }
      })
    )
  }

  log() {
    console.log("this.parameters", this.parameters)
    console.log("this.form", this.form.value)
  }

  onSearchExpChannels(resp) {
    console.log(resp, this.expChannels, this.form.value)
    if (!this.expChannels) {
      return;
    }

    if (!resp) {
      this.expChannels$.next(this.expChannels.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    this.expChannels$.next(
      this.expChannels.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  getExpChannel(id) {
    if (!this.expChannels) {
      return false;
    }
    return this.expChannels.find(el => el.external_id == id)
  }

  getCompany() {
    this.attachSubscriptions(
      this.companyService.getCompany(this.data.company_id).subscribe(resp => {
        this.companyService.company$.next(resp[0]);
      })
    )
  }

  getImgRoute() {
    this.attachSubscriptions(
      this.sm.getImgPath().subscribe(el => {
        this.imgRoute = el;
      })
    )
  }

  getCsrf() {
    this.attachSubscriptions(
      this.auth.$userToken.subscribe(resp => {
        this.user_id = resp.user_id;
        this.sm.localStorageSetItem("csrf_param", resp.csrf_param)
        this.sm.localStorageSetItem("csrf_token", resp.csrf_token)
      })
    )
  }

  getUser() {
    this.attachSubscriptions(
      this.auth.$user.subscribe(resp => {
        this.user = resp;
      })
    )
  }

  getPlatforms() {
    this.attachSubscriptions(
      this.companyService.getGroupPlatforms().subscribe(resp => {
        console.log("getPlatforms", resp)
        this.platforms = resp;
        this.platforms$.next(this.platforms.slice());
      })
    )
  }

  close() {
    this.dialogRef.close({event: "close", data: false})
  }

  onSearchProfiles(resp) {
    if (!this.platforms) {
      return;
    }

    if (!resp) {
      this.platforms$.next(this.platforms.slice());
      return;
    } else {
      resp = resp.toLowerCase();
    }

    // filter the banks
    this.platforms$.next(
      this.platforms.filter(b => b.name.toLowerCase().indexOf(resp) > -1)
    );
  }

  getGroupsCompany() {
    this.attachSubscriptions(
      this.companyService.getInfiniteGroupsCompany(this.data.company_id, '1', '1').pipe(
        switchMap(el => {
          let pages = Math.ceil(el.headers.get('x-pagination-total-count') / 200)
          let arr = []
          for (let index = 1; index <= pages; index++) {
            arr.push(index)
          }

          return forkJoin(arr.map(x => this.companyService.getInfiniteGroupsCompany(this.data.company_id, x).pipe(map(u => u.body)))).pipe(
            last(),
          )
        }),
      ).subscribe(res => {
        this.groups = [].concat(...res);
        this.groups$.next(this.groups.slice());
      })
    )
  }

  onSubmit() {
    if (!this.form.valid) {
      this.layoutService.showSnackBar({name: ''}, marker("You need to fill in all required fields"), SnackBarItem)
      return
    }

    if (!this.form.value.content_type_id && this.form.value.group_id && !!this.form.value.group_id.length) {
      this.layoutService.showSnackBar({name: ''}, marker("You want to link to a group, but you did not specify the type (specify the type otherwise many functions will not work correctly)"), SnackBarItem)
      return
    }

    this.isSubmit = true;
    let createData = {...this.form.value}
    delete createData.external_id;
    delete createData.group_id;
    delete createData.content_type_id;
    this.attachSubscriptions(
      this.companyService.createCompanyGroupProfile(createData).pipe(
        switchMap(profile => {
          let channelData = {
            company_id: this.data.company_id,
            channel_account_id: this.data.account.id,
            external_id: this.form.value.external_id
          }
          return this.companyService.editCompanyGroupProfile(profile.id, channelData).pipe(
            map(() => profile),
          )
        }),
        switchMap(profile => {
          console.log("Test1")
          if (this.parameters && this.parameters[0]) {
            let activeVals = [];
            this.parameters[0].activeValues.forEach(val => {
              activeVals.push({
                channel_id: profile.id,
                company_id:	this.data.company_id,
                parameter_value_id: val.id,
                parameter_id: val.parameter_id
              })
            })
            if (activeVals.length) {
              return forkJoin([...activeVals.map(p => this.parametersService.addTargetChannelParameter(p).pipe(
                catchError(error => {
                  console.log(error);
                  return of(error)
                })
              ))]).pipe(map(() => profile))
            } else {
              return of(profile)
            }
          } else {
            return of(profile)
          }
        }),
        switchMap(profile => {
          console.log("Test2")
          let chToGroups = [];
          
          this.form.value.group_id.forEach(gID => {
            chToGroups.push({
              company_id: this.data.company.id,
              channel_id: profile.id,
              content_type_id: this.form.value.content_type_id,
              channel_group_id: gID
            })
          })

          if (chToGroups && chToGroups.length) {
            return forkJoin([...chToGroups.map(p => this.companyService.addChannelToGroup(p, this.data.company.id).pipe(
              catchError(error => {
                console.log(error);
                return of(error)
              })
            ))]).pipe(
              map(() => profile),
            )
          } else {
            return of(profile)
          }
        }),
        switchMap(profile => {
          console.log("Test3")
          if (this.form.value.youtube_sftp_dropbox_id) {
            let sftpData:any = {
              company_id: this.data.company.id,
              channel_id: profile.id,
              youtube_sftp_dropbox_id: this.form.value.youtube_sftp_dropbox_id
            }
            return this.companyService.addSftpChannel(sftpData).pipe(
              catchError(error => {
                console.log(error);
                sftpData.is_replace = 1;
                return this.companyService.addSftpChannel(sftpData)
              }),
              map(() => profile)
            )
          } else {
            return of(profile)
          }
        })
      ).subscribe(resp => {
        console.log("Test4")
        this.isSubmit = false;
        this.dialogRef.close({event: "add", data: resp});
      }, error => {
        this.isSubmit = false;
        this.layoutService.showSnackBar({name: ''}, error, SnackBarItem)
      })
    )
  }
  
  ngOnDestroy(): void {
    this.clearSubscriptions()
  }
}
