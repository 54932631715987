import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {
  transform(
    value: string,
    maxLength: number = 30,
    startLength: number = 15,
    endLength: number = 12
  ): string {
    if (!value || value.length <= maxLength) {
      return value;
    }
    const start = value.slice(0, startLength); // Начало строки
    const end = value.slice(-endLength); // Конец строки
    return `${start}...${end}`;
  }
}


// {{ longText | truncate:30:10:8 }}